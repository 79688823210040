import React, { Component } from 'react'
import { workStationColumns } from '@/Public/PubTable/pageColumns'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { dossierUrl, departmentUrl, workStation } from '@/Utils/Urls'
import { treeUtil } from '@/Utils'
import Enum, { dossierEnum } from '@/Utils/Enum'
import _ from 'lodash'
import ButtonList from '@/Public/ButtonList'

const markName = 'Workstation'
const searchType = new Enum(['案卷', '文件', '全部']) // 档案搜索类型
const statusType = new Enum({
	0: '著录中',
	1: '预归档',
	2: '已归档',
	3: '已过期',
	/* 4: '回收站',  */ 5: '已销毁',
	6: '全部',
	12: '审核中',
}) // 档案搜索类型
const docSearchType = new Enum({ 1: '多媒体', 2: '文书', 3: '全部' }) // 文件搜索类型

@WrappedTable.create(markName)
class WorkStation extends Component {
	componentDidUpdate(preProps) {
		// 若路由发生变更重新根据workbenchId获取数据
		const {
			location: { pathname },
			pub_getAllData,
		} = this.props
		if (!_.isEqual(pathname, preProps.location.pathname)) {
			pub_getAllData()
		}
	}

	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 */
	changeSearchLimit = data => {
		const {
			location: { pathname },
		} = this.props
		let workbenchId = pathname.split('/').slice(-1)[0]
		data.workbenchId = Number(workbenchId)
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = res => {
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}

	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{
				type: 'Select',
				name: 'type',
				label: '类型',
				options: searchType.toOptions(),
				initialValue: searchType['全部'],
			},
			{
				type: 'AsyncTreeSelect',
				name: 'categoryCodeId',
				label: '分类',
				minWidth: '155px',
				ajaxConfig: {
					url: dossierUrl.getDossierType,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys(data.children, {
						typeName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'DatePicker',
				name: 'yearNumber',
				label: '年度',
				width: '150px',
				picker: 'year',
			},
			{
				type: 'ParticiplesSelect',
				name: 'topic',
				label: '题名',
			},
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId',
				label: '部门',
				minWidth: '160px',
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'Select',
				name: 'retentionPeriod',
				label: '保管期限',
				minWidth: '155px',
				options: dossierEnum.retentionPeriod.toOptions(), // 档案保管期限
			},
			{
				type: 'Select',
				name: 'status',
				label: '状态',
				options: statusType.toOptions(),
				initialValue: statusType['全部'],
			},
			{
				type: 'Select',
				name: 'archiveType',
				label: '载体',
				options: docSearchType.toOptions(),
				initialValue: docSearchType['全部'],
			},
			{
				type: 'ParticiplesSelect',
				name: 'metadata',
				label: '元数据',
				inputWidth: '135px',
			},
			{
				type: 'Extra',
				name: 'buttonList',
				render: () => <ButtonList refresh={this.props.pub_getAllData} />,
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'post',
			url: workStation.getWorkbenchContentList,
			ContentType: 'application/json',
		},
	}

	render() {
		return (
			<WrappedTable>
				<SearchForm
					name='workstation-search-form' // 组件名称
					formItems={this.searchFormConfig}
					getInstance={formRef => {
						this.searchFormRef = formRef
					}}
					markName={markName}
					changeSearchLimit={this.changeSearchLimit}
					changeTableData={this.changeTableData}
				/>
				<BaseTable
					tableConfig={workStationColumns} // 表格表头设置
					changeSearchLimit={this.changeSearchLimit}
					changeTableData={this.changeTableData} // 把拿到的表格数据改造成符合公共组件所需数据的格式
					markName={markName}
					stylePointer={true}
					onRowEvent={true}
				/>
			</WrappedTable>
		)
	}
}

export default WorkStation
