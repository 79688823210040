import React, { Component, Fragment } from 'react'
import { Upload, message, Button, Spin, Modal } from 'antd'
import { connect } from 'react-redux'
import queryString from 'query-string'
import _ from 'lodash'
import {
	FilePptOutlined,
	FileExcelOutlined,
	FileWordOutlined,
	FilePdfOutlined,
	FileUnknownOutlined,
	FileTextOutlined,
	PlayCircleTwoTone,
	MailOutlined,
	UploadOutlined,
} from '@ant-design/icons'
import {
	addTemporaryArchiveThunk,
	selectTemporaryArchiveThunk,
	deleteTemporaryArchiveThunk,
	createFileChunk,
	getFileMd5,
	uploadFile,
	pdfConvert,
} from './ActionCreators'
import FileItem from './FileItem'
import { multimediaExtensions, multiMediaFileTypes } from './multimediaType'
import { injectUnmount } from '@/Utils'
import './index.less'
import moment from 'moment'
import 'moment/locale/zh-cn'

const { Dragger } = Upload
const mapStateToProps = state => ({
	selectedKey: state.slideBar.selectedKey,
})
const mapDispatchToProps = dispatch => ({
	pdfConvert: (...rest) => dispatch(pdfConvert(...rest)), //转换并下载pdf
	addTemporaryArchive: (...rest) => dispatch(addTemporaryArchiveThunk(...rest)), // 新建临时档案
	selectTemporaryArchive: (...rest) => dispatch(selectTemporaryArchiveThunk(...rest)), // 查询临时档案
	deleteTemporaryArchive: (...rest) => dispatch(deleteTemporaryArchiveThunk(...rest)), // 删除临时档案
})

@connect(mapStateToProps, mapDispatchToProps)
@injectUnmount
class UploadFiles extends Component {
	state = {
		fileList: [], // 上传文件
		multiMediaFileList: [], // 多媒体类型文件
		documentFileList: [], // 文书类型文件
		fileIds: [], // 返回的fileId数组
		originLength: 0, // 初始已上传长度
		success: true, // 是否上传成功
		checkedArr: [], // 选中的数组
		selectAllOrAnti: '全选',
		deleting: false, // 是否正在删除
		spinning: true, // 是否处于请求中
		uploadComponentKey: Math.random(),
		archiveType: queryString.parse(window.location.search.slice(1)).archiveType * 1, // 根据archiveType的值判断上传文件类型
		isUpload: true, // 多媒体界面禁止上传文书类型文件 同理
	}

	// 设置文件列表的边距
	setMargin = _.debounce(() => {
		const ele = document.querySelector('.upload-files')
		const width = ele.offsetWidth - 4
		const n = Math.floor(width / 250)
		const margin = Math.floor(Math.max((width - n * 250) / (n * 2) - 1, 0))

		ele.style.setProperty('--margin', `5px ${margin}px`)
	}, 200)

	componentWillUnmount() {
		window.removeEventListener('resize', this.setMargin)
	}

	componentDidMount() {
		this.setMargin()
		window.addEventListener('resize', this.setMargin)
		// 初始化数据
		this.getTemporaryArchive(() => {
			// 保存上次选中的内容
			const { lastCheckedArr } = this.props
			if (lastCheckedArr.length > 0) {
				const itemsNode = document.querySelector(
					'.upload-files .ant-upload-list-picture'
				).childNodes
				let TemporaryArchive = JSON.parse(
					localStorage.getItem('TemporaryArchive')
				)
				TemporaryArchive = this.filterTemporaryArchive(TemporaryArchive)
				for (let i = 0, len = itemsNode.length; i < len; i++) {
					const id = TemporaryArchive[i].id
					if (lastCheckedArr.includes(id)) this.setChecked(itemsNode[i])
				}
			}
			this.setState({ checkedArr: lastCheckedArr })
		})

		// 添加选择事件
		document.querySelector('.upload-files').addEventListener('click', e => {
			// 这里不直接选择 .upload-files .ant-upload-list 是因为当外部 key 值改变之后，这个就没了
			// 所以直接在组件最外级添加点击事件
			const uploadList = document.querySelector('.upload-files .ant-upload-list')
			// 如果点击的对象在 uploadList 之外或者就是 uploadList，就不继续进行
			if (uploadList.contains(e.target) === false || e.target === uploadList) {
				return
			}
			// 如果点击的对象是删除图标，也不继续进行
			if (e.target.getAttribute('data-icon') === 'delete') {
				return
			}

			// 阻止默认事件
			e.stopPropagation()
			e.preventDefault()

			// 如果还有文件没上传完成，则不继续进行
			if (!this.state.success) {
				message.destroy()
				message.info('请等待全部文件上传成功')
				return
			}

			// 获取到最外层的容器
			let ele = e.target
			while (ele.className !== 'ant-upload-list-picture-container') {
				ele = ele.parentNode
			}

			// 获取节点名
			const nodeName = e.target.nodeName.toLowerCase()

			if (nodeName === 'a') {
				// 如果点击的是标题
				if (e.target.className === 'ant-upload-list-item-name') {
					const index = [].indexOf.call(ele.parentNode.children, ele)
					const { setParentState } = this.props
					const { checkedArr, archiveType } = this.state
					let TemporaryArchive = JSON.parse(
						localStorage.getItem('TemporaryArchive')
					)
					TemporaryArchive = this.filterTemporaryArchive(TemporaryArchive)
					if (TemporaryArchive[index] === undefined) {
						return
					}
					const id = TemporaryArchive[index].id
					if (checkedArr.includes(id)) {
						// 如果该项已经被选中，则进入信息填写页面
						setParentState({ current: 1, checkedArr, archiveType })
					} else {
						// 否则则选中
						this.setChecked(ele)
					}
				} else {
					// 点击的不是标题则正常操作
					this.setChecked(ele)
				}
				return
			}

			this.setChecked(ele)
		})
	}

	//上传前：只上传判定文件格式是否正确
	beforeUpload = file => {
		const fileType = file.name.split('.').pop()
		//console.log('files:', file)

		//海珠区：屏蔽除了邮件之外的其他格式上传
		if (
			this.state.archiveType !== 1 &&
			this.state.documentFileList &&
			fileType !== 'eml'
		) {
			message.destroy()
			message.error('仅支持邮件格式上传')
			return Upload.LIST_IGNORE
		}
	}
	// 获取临时档案
	getTemporaryArchive = callback => {
		const { selectTemporaryArchive } = this.props
		let multiMediaFileList = []
		let documentFileList = []
		selectTemporaryArchive()
			.then(res => {
				localStorage.setItem('TemporaryArchive', JSON.stringify(res.data))
				let thumbnailFiles = []
				if (Array.isArray(res.data)) {
					res.data.forEach((value, index) => {
						thumbnailFiles.push({
							uid: value.id,
							name: value.fileName,
							url: `/api/file/download/${value.thumbnailFileToken}`,
							type: value.fileType,
						})
					})
					thumbnailFiles.forEach(file => {
						if (file.type) {
							if (multiMediaFileTypes.includes(file.type.split('/')[0])) {
								multiMediaFileList.push(file)
							} else {
								documentFileList.push(file)
							}
						} else {
							if (
								multimediaExtensions.includes(
									file.name.slice(file.name.lastIndexOf('.'))
								)
							) {
								multiMediaFileList.push(file)
							} else {
								documentFileList.push(file)
							}
						}
					})
					setTimeout(() => {
						this.setState(
							{
								fileList: thumbnailFiles,
								originLength: thumbnailFiles.length,
								multiMediaFileList,
								documentFileList,
								fileIds: [],
								success: true,
								spinning: false,
							},
							() => {
								callback && callback()
							}
						)
					}, 500)
				}
			})
			.catch(res => {
				this.setState({ spinning: false })
			})
	}

	// 设置选择图标
	setChecked = ele => {
		const { checkedArr, success, deleting } = this.state
		if (!success) {
			message.destroy()
			message.info('请等待全部文件上传成功')
			return
		}
		if (deleting) {
			message.info('请等待文件删除成功')
			return
		}

		let selectAllOrAnti = ''
		// 获取当前点中的索引
		let index = [].indexOf.call(ele.parentNode.children, ele)
		// 获取当前点中的文件的id
		let TemporaryArchive = JSON.parse(localStorage.getItem('TemporaryArchive'))
		TemporaryArchive = this.filterTemporaryArchive(TemporaryArchive)
		if (TemporaryArchive[index] === undefined) {
			return
		}
		const id = TemporaryArchive[index].id
		// 判断当前id是否在已选中的数组中
		const indexInCheckedArr = checkedArr.indexOf(id)
		// 如果在选中的数组中 则从数组中移除该项 并且去掉选择的标志
		if (indexInCheckedArr > -1) {
			// 删除id和标志
			const checkedNode = ele.querySelector('.upload-checked')
			checkedNode?.parentNode.removeChild(checkedNode)
			checkedArr.splice(indexInCheckedArr, 1)
			selectAllOrAnti = '全选'
		} else {
			// 如果不在 则往选中数组里添加id 并且加上选择的标志
			// 创建选择标志
			const div = document.createElement('div')
			div.className = 'upload-checked'
			div.innerHTML = `<i>✓</i>`
			// 添加id和标志
			ele.children[0].children[0].appendChild(div)
			checkedArr.push(id)
			selectAllOrAnti =
				checkedArr.length === TemporaryArchive.length ? '取消全选' : '全选'
		}
		// 把选择数组添加到state里面
		this.setState({ checkedArr, selectAllOrAnti })
	}

	judgeUploadFileType = (fileList, judgeMethod, originLength) => {
		return fileList.slice(0, fileList.length - originLength)[judgeMethod](file => {
			return file.type
				? multiMediaFileTypes.includes(file.type.split('/')[0])
				: multimediaExtensions.includes(
						file.name.slice(file.name.lastIndexOf('.'))
					)
		})
	}

	// dataURItoBlob = (dataURI) => {
	//   var byteString = atob(dataURI.split(',')[1]);
	//   var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	//   var ab = new ArrayBuffer(byteString.length);
	//   var ia = new Uint8Array(ab);
	//   for (var i = 0; i < byteString.length; i++) {
	//     ia[i] = byteString.charCodeAt(i);
	//   }
	//   return new Blob([ab], { type: mimeString });
	// }

	// 上传文件状态发生改变时触发
	handleChange = ({ fileList, file }) => {
		if (file.size === 0) {
			return
		}
		let fileLength = fileList.length
		let times = 0
		// while 中的条件表示该文件正处于上传状态，此时需要移动到数组的最前面
		while (
			typeof fileList[fileList.length - 1]?.uid !== 'number' &&
			times < fileLength
		) {
			fileList.unshift(fileList.pop())
			times += 1
		}
		const { archiveType, multiMediaFileList, documentFileList } = this.state
		if (
			archiveType === 1 &&
			fileList.length > multiMediaFileList.length &&
			!this.judgeUploadFileType(fileList, 'every', multiMediaFileList.length)
		) {
			this.setState({
				isUpload: false,
			})
			message.destroy()
			message.info('仅支持上传多媒体类型文件')
			return
		} else if (
			archiveType === 2 &&
			fileList.length > documentFileList.length &&
			this.judgeUploadFileType(fileList, 'some', documentFileList.length)
		) {
			this.setState({
				isUpload: false,
			})
			message.destroy()
			message.info('仅支持上传文书类型文件')
			return
		}
		this.setState(state => ({
			isUpload: true,
			fileList:
				archiveType === 1
					? [
							...state.fileList,
							...fileList.slice(
								0,
								fileList.length - multiMediaFileList.length
							),
						]
					: [
							...state.fileList,
							...fileList.slice(
								0,
								fileList.length - documentFileList.length
							),
						],
			multiMediaFileList: archiveType === 1 ? fileList : multiMediaFileList,
			documentFileList: archiveType === 2 ? fileList : documentFileList,
		}))
	}

	uploadFiles = async files => {
		//console.log('files:', files)
		if (files.file.size === 0) {
			message.info('不支持上传空文件')
			return
		}
		const fileName = files.file.name
		const fileType = files.file.type
		const fileChunkList = createFileChunk(files.file)
		const fileMd5Value = await getFileMd5(fileChunkList)
		this.setState({ success: false })
		const { addTemporaryArchive } = this.props
		uploadFile(fileMd5Value, fileName, fileChunkList, fileType, files)
			.then(res => {
				let data = [
					{
						fileId: res.data.fileId,
						thumbnailFileId: res.data.thumbnailFileId
							? res.data.thumbnailFileId
							: null,
						zippedFileId: res.data.zippedImageFileId,
						archiveType: this.state.archiveType,
						fileMetadata: [
							{
								metadataId: 75,
								metadataValue: moment(files.file.lastModifiedDate)
									.format('YYYY-MM-DDTHH:mm:ss')
									.replace(/\.(.*)/, ''),
							},
						],
					},
				]
				// 形成临时档案
				addTemporaryArchive(data).then(res => {
					const { fileIds } = this.state
					fileIds.push(data[0].fileId) // 每次成功都将fileId放入数组
					this.setState({ fileIds }, () => {
						const { originLength, fileList } = this.state
						// 长度相等说明已经全部完成上传了
						if (originLength + fileIds.length === fileList.length) {
							this.getTemporaryArchive(() => {
								message.success('上传成功')
								this.setState({
									success: true,
									selectAllOrAnti: '全选',
									uploadComponentKey: Math.random(),
								})
							})
						}
					})
				})
				// 成功标志
				files.onSuccess(res, files.file)
			})
			.catch(err => {
				message.error('上传失败！')
				files.onError(err, files.file)
				const { fileList, archiveType, multiMediaFileList, documentFileList } =
					this.state
				fileList.splice(fileList.length - 1, 1)
				if (archiveType === 1) {
					multiMediaFileList.splice(0, 1)
				} else {
					documentFileList.splice(0, 1)
				}
				this.setState({
					fileList,
					multiMediaFileList,
					documentFileList,
				})
			})
	}

	/**
	 * @function 确认删除文件的对话框
	 * @param {Array} arrToBeDeteled // 待删除文件的数组
	 * @param {boolean} isDeleteItems // 是否为多选删除选中的项
	 */
	confirmDeleteItems = (arrToBeDeteled, isDeleteItems) => {
		const { deleteTemporaryArchive } = this.props
		const { checkedArr } = this.state
		Modal.confirm({
			maskClosable: true,
			title: '文件删除',
			content: '您正在对文件进行删除操作，是否继续？',
			okType: 'primary',
			okText: '确定',
			cancelText: '取消',
			onOk: () => {
				this.setState({ deleting: true })
				deleteTemporaryArchive(arrToBeDeteled)
					.then(res => {
						if (isDeleteItems) {
							this.setState({ checkedArr: [], deleting: false })
						} else {
							// 如果通过垃圾桶删除的文件之前已被勾选，那么在删除后将其从 checkedArr 中移除，以防残留
							if (checkedArr.indexOf(arrToBeDeteled[0]) !== -1) {
								checkedArr.splice(
									checkedArr.indexOf(arrToBeDeteled[0]),
									1
								)
							}
							this.setState({ checkedArr: checkedArr, deleting: false })
						}
						setTimeout(() => {
							message.success('删除成功')
						}, 2000)
						this.getTemporaryArchive()
					})
					.catch(err => {
						message.destroy()
					})
			},
		})
	}

	// 多选删除选中的项
	deleteItems = () => {
		const { checkedArr, success } = this.state
		if (checkedArr?.length === 0) {
			message.info('请至少选择一项')
			return false
		}
		if (!success) {
			message.destroy()
			message.info('请等待全部文件上传成功')
			return false
		}
		const isDeleteItems = true // 是否为多选删除选中的项
		this.confirmDeleteItems(checkedArr, isDeleteItems) // 确认删除的操作
	}

	// 通过垃圾桶删除
	deleteCurrentItem = e => {
		const { deleting, success } = this.state
		if (deleting) {
			message.info('请等待之前的文件删除成功')
			return false
		}
		if (!success) {
			message.destroy()
			message.info('请等待全部文件上传成功')
			return false
		}
		if (e.percent === 0) {
			message.success('删除成功')
			return
		}
		const current = e.uid
		const isDeleteItems = false // 是否为多选删除选中的项
		this.confirmDeleteItems([current], isDeleteItems) // 确认删除的操作

		return false // 默认返回false，这样<Dragger></Dragger>，即每个文件 都不会因为鼠标一点垃圾桶就立即移除，而是在确认删除后通过调用 getTemporaryArchive，展示当前未删除的文件
	}

	// 过滤多媒体和文书类型临时案卷
	filterTemporaryArchive = TemporaryArchive => {
		const { archiveType } = this.state
		return archiveType === 1
			? TemporaryArchive.filter(file => {
					return file.fileType
						? multiMediaFileTypes.includes(file.fileType.split('/')[0])
						: multimediaExtensions.includes(
								file.fileName.slice(file.fileName.lastIndexOf('.'))
							)
				})
			: TemporaryArchive.filter(file => {
					return file.fileType
						? !multiMediaFileTypes.includes(file.fileType.split('/')[0])
						: !multimediaExtensions.includes(
								file.fileName.slice(file.fileName.lastIndexOf('.'))
							)
				})
	}

	// 全选或反全选
	selectAllOrAnti = action => {
		const { checkedArr, success } = this.state
		if (!success) {
			message.destroy()
			message.info('请等待全部文件上传成功')
			return
		}
		const isSelecteAll = action === '全选' ? true : false
		let TemporaryArchive = JSON.parse(localStorage.getItem('TemporaryArchive'))
		TemporaryArchive = this.filterTemporaryArchive(TemporaryArchive)
		const ids = []
		TemporaryArchive.forEach(item => {
			ids.push(item.id)
		})
		const nodeList = document.querySelectorAll('.ant-upload-list-item-info')
		if (isSelecteAll) {
			// 全选时
			for (let i = 0, len = nodeList.length; i < len; i++) {
				if (checkedArr.includes(ids[i])) continue
				const div = document.createElement('div')
				div.className = 'upload-checked'
				div.innerHTML = `<i>✓</i>`
				nodeList[i].appendChild(div)
			}
		} else {
			// 反全选时
			for (let i = 0, len = nodeList.length; i < len; i++) {
				if (!checkedArr.includes(ids[i])) continue
				nodeList[i].removeChild(nodeList[i].lastChild)
			}
		}
		this.setState({
			checkedArr: isSelecteAll ? ids : [],
			selectAllOrAnti: action === '全选' ? '取消全选' : '全选',
		})
	}

	renderFileItem = (fileType, fileSubtype, file) => {
		switch (fileType) {
			// 邮箱
			case 'message': {
				return <MailOutlined className='icon'></MailOutlined>
			}
			// 图片
			case 'image':
				return (
					<img
						className='ant-upload-list-item-image'
						src={file.url}
						alt={file.name}
					/>
				)
			// 海珠区不支持其他文件类型的上传
			// 视频
			case 'video':
				return (
					<Fragment>
						<img
							className='ant-upload-list-item-image'
							src={file.url}
							alt={file.name}
						/>
						<PlayCircleTwoTone
							style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%) scale(1.5)',
							}}
						/>
					</Fragment>
				)

			// 音频
			case 'audio':
				return (
					<svg
						t='1618036672028'
						className='icon'
						viewBox='0 0 1024 1024'
						version='1.1'
						xmlns='http://www.w3.org/2000/svg'
						p-id='3847'
						width='1em'
						height='1em'>
						<path
							d='M983.38 306.44l-442.628 81.458a17.454 17.454 0 0 0-14.302 17.162v375.822c-31.38-18.59-72.608-24.724-113.914-13.666-74.146 19.85-120.418 87.528-103.352 151.164s91.006 99.13 165.152 79.28c65.884-17.638 109.718-73.042 106.704-129.814h0.188V469.482l367.992-67.74v289.742c-31.38-18.59-72.61-24.724-113.914-13.666-74.146 19.85-120.418 87.528-103.352 151.164 17.066 63.636 91.006 99.13 165.152 79.28 64.794-17.348 108.296-71.218 106.852-126.998H1004l0.008-457.66c0.002-10.906-9.896-19.14-20.628-17.164z'
							fill='#707AFA'
							p-id='3848'></path>
						<path
							d='M474.336 977.676c-74.146 19.85-148.086-15.644-165.152-79.28a103.588 103.588 0 0 1-3.038-16.91c-1.166 12.202-0.254 24.62 3.038 36.894 17.066 63.636 91.006 99.13 165.152 79.28 65.884-17.638 109.718-73.042 106.704-129.814h0.188v-19.984h-0.188c3.014 56.774-40.82 112.176-106.704 129.814zM1004 761.28h-0.042c1.444 55.78-42.058 109.652-106.852 126.998-74.146 19.85-148.086-15.644-165.152-79.28a103.588 103.588 0 0 1-3.038-16.91c-1.166 12.202-0.254 24.62 3.038 36.894 17.066 63.636 91.006 99.13 165.152 79.28 64.794-17.348 108.296-71.218 106.852-126.998H1004l0.008-457.66v-0.006L1004 761.28z'
							fill='#6770E6'
							p-id='3849'></path>
						<path
							d='M487.55 167.318c-30.86-99.616-129.14-131.888-191.96-142.34-15.806-2.62-31.02-3.864-41.386-4.454a12.668 12.668 0 0 0-13.394 12.65v378.478c-31.38-18.59-72.61-24.726-113.916-13.668-74.146 19.85-120.418 87.528-103.352 151.164 17.066 63.636 91.006 99.13 165.152 79.28 65.884-17.638 109.716-73.042 106.704-129.814h0.192V150.392c35.96 2.658 84.72 19.644 130.94 79.312 67.62 87.246 154.7 51.036 154.7 51.036-39.7 0.002-75.24-53.872-93.68-113.422z'
							fill='#FF8354'
							p-id='3850'></path>
						<path
							d='M188.692 607.944c-74.146 19.85-148.086-15.644-165.152-79.28a103.55 103.55 0 0 1-3.008-16.66c-1.204 12.282-0.306 24.79 3.008 37.146 17.066 63.636 91.006 99.13 165.152 79.28 65.884-17.638 109.716-73.042 106.704-129.814h0.192v-20.486h-0.192c3.014 56.774-40.82 112.176-106.704 129.814z'
							fill='#E0734A'
							p-id='3851'></path>
						<path
							d='M126.894 437.954c41.306-11.058 82.536-4.924 113.916 13.668v-39.966c-31.38-18.59-72.61-24.726-113.916-13.668-71.43 19.122-116.964 82.634-104.95 144.156 9.03-47.094 49.064-89.228 104.95-104.19z'
							fill='#FFAC8C'
							p-id='3852'></path>
						<path
							d='M540.752 427.864l442.628-81.456c10.728-1.974 20.62 6.252 20.628 17.148v-39.952c0-10.904-9.896-19.138-20.628-17.162l-442.628 81.456a17.456 17.456 0 0 0-14.302 17.162v39.966a17.454 17.454 0 0 1 14.302-17.162zM835.308 717.784c41.306-11.058 82.534-4.924 113.914 13.666v-39.966c-31.38-18.59-72.61-24.724-113.914-13.666-71.43 19.124-116.964 82.634-104.95 144.156 9.03-47.094 49.064-89.228 104.95-104.19zM412.536 807.182c41.304-11.058 82.532-4.924 113.914 13.666v-39.966c-31.38-18.59-72.608-24.724-113.914-13.666-71.43 19.124-116.964 82.634-104.948 144.156 9.03-47.092 49.064-89.228 104.948-104.19z'
							fill='#8F95E6'
							p-id='3853'></path>
						<path
							d='M244.204 54.496c10.366 0.59 25.582 1.834 41.386 4.454 62.82 10.452 161.1 42.724 191.96 142.34 10.292 33.238 25.916 64.698 44.612 86.018 33.838 3.914 59.066-6.566 59.066-6.566-39.7 0-75.24-53.874-93.68-113.424-30.86-99.616-129.14-131.888-191.96-142.34-15.806-2.618-31.02-3.864-41.386-4.454a12.668 12.668 0 0 0-13.394 12.65v21.598a12.806 12.806 0 0 1 3.396-0.276z'
							fill='#FFAC8C'
							p-id='3854'></path>
						<path
							d='M865.658 118.83c-9.74 0-17.636-7.89-17.636-17.62 0-9.378-7.082-17.566-16.448-18.172-10.264-0.664-18.796 7.456-18.796 17.568v0.604c0 9.732-7.896 17.62-17.636 17.62-9.386 0-17.58 7.076-18.186 16.434-0.664 10.254 7.462 18.78 17.582 18.78h0.604c9.74 0 17.636 7.89 17.636 17.62 0 9.378 7.082 17.566 16.448 18.172 10.264 0.664 18.796-7.456 18.796-17.568v-0.604c0-9.732 7.896-17.62 17.636-17.62h0.604c10.12 0 18.248-8.524 17.584-18.78-0.608-9.358-8.804-16.434-18.188-16.434zM188.01 811.662c-13.588 0-24.602-11.006-24.602-24.582 0-13.082-9.88-24.504-22.946-25.35-14.318-0.926-26.22 10.402-26.22 24.508v0.842c0 13.576-11.014 24.582-24.602 24.582-13.092 0-24.524 9.872-25.37 22.926-0.928 14.306 10.41 26.198 24.528 26.198h0.844c13.588 0 24.602 11.006 24.602 24.582 0 13.082 9.88 24.504 22.946 25.35 14.318 0.926 26.22-10.402 26.22-24.508v-0.842c0-13.576 11.014-24.582 24.602-24.582h0.844c14.118 0 25.456-11.892 24.53-26.198-0.85-13.054-12.282-22.926-25.376-22.926z'
							fill='#69EBFC'
							p-id='3855'></path>
					</svg>
				)
			// 文本类
			case 'text':
				return <FileTextOutlined className='icon' />
			// office
			case 'application':
				switch (fileSubtype) {
					case 'pdf':
						return <FilePdfOutlined className='icon' />
					case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
						return <FileWordOutlined className='icon' />
					case 'msword':
						return <FileWordOutlined className='icon' />
					// excel
					case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
						return <FileExcelOutlined className='icon' />
					// ppt
					case 'vnd.openxmlformats-officedocument.presentationml.presentation':
						return <FilePptOutlined className='icon' />
					default:
						return <FileUnknownOutlined className='icon' />
				}
			default:
				return <FileUnknownOutlined className='icon' />
		}
	}

	render() {
		const {
			selectAllOrAnti,
			spinning,
			uploadComponentKey,
			archiveType,
			isUpload,
			multiMediaFileList,
			documentFileList,
		} = this.state
		return (
			<Spin tip='加载中...' spinning={spinning}>
				<div className='upload-files'>
					<div>
						<Button
							className='ant-btn-danger iconfont-buttons'
							title='删除'
							style={{
								marginRight: ' 10px',
								width: '65px',
								textAlign: 'center',
								padding: '0',
							}}
							onClick={() => {
								this.deleteItems()
							}}>
							&#xe816; <span> &nbsp;删除</span>
						</Button>

						{/* 邮件是不支持同时著录多个的，增加全选按钮只是为了批量删除 */}
						{/* {archiveType === 1 && ( */}

						<Button
							className='ant-btn-primary iconfont-buttons'
							title='全选'
							style={{
								textAlign: 'center',
								padding: '0 5px',
							}}
							onClick={() => {
								this.selectAllOrAnti(selectAllOrAnti)
								// }}>{selectAllOrAnti}</Button>
							}}>
							&#xee33; {selectAllOrAnti}
						</Button>
						{/* )} */}
						<Button
							className='btn-success iconfont-buttons'
							title='著录'
							style={{
								marginLeft: '10px',
								width: '65px',
								textAlign: 'center',
								padding: '0',
							}}
							onClick={() => {
								const { checkedArr } = this.state
								if (checkedArr.length > 1 && archiveType != 1) {
									message.info('暂不支持邮箱批量著录')
									return
								}
								if (checkedArr.length === 0) {
									message.info('请至少选择一项')
									return
								}
								const { setParentState } = this.props
								setParentState({ current: 1, checkedArr, archiveType })
							}}>
							&#xe622; 著录
						</Button>
						<Button
							style={{
								marginLeft: '10px',
								border: '1px solid black',
								borderRadius: '4px',
							}}>
							<Upload
								showUploadList={false}
								customRequest={files => {
									pdfConvert(files?.file)
										.then(res => {
											//下载pdf
											//base64转换为二进制数据
											const base64String = res.data.data
											const binaryString = atob(base64String)
											const binary = new Uint8Array(
												binaryString.length
											)
											for (
												let i = 0;
												i < binaryString.length;
												i++
											) {
												binary[i] = binaryString.charCodeAt(i)
											}
											const link = document.createElement('a')
											const blob = new Blob([binary], {
												type: 'application/pdf',
											})
											//下载pdf
											const url = window.URL.createObjectURL(blob)
											link.href = url
											const newFileName =
												files.file.name.split('.')[0] + '.pdf'
											link.setAttribute('download', newFileName)
											document.body.appendChild(link)
											link.click()
											document.body.removeChild(link)
										})
										.catch(err => {
											message.error(err.message)
										})
								}}>
								<UploadOutlined></UploadOutlined>转换文件为PDF
							</Upload>
						</Button>
					</div>

					{/* 上传文件 */}
					<Dragger
						beforeUpload={this.beforeUpload}
						multiple={true} // 支持多选择
						fileList={
							archiveType === 1 ? multiMediaFileList : documentFileList
						} // 文件列表
						listType='picture' // 展示样式
						onChange={this.handleChange}
						style={{ marginTop: '20px' }}
						customRequest={files => {
							isUpload && this.uploadFiles(files)
						}}
						key={uploadComponentKey}
						onRemove={e => this.deleteCurrentItem(e)}
						className='dragger'
						itemRender={(originNode, file) => {
							const [fileType, fileSubtype] = file?.type.split('/')
							if (file.percent != null) {
								return originNode
							}
							return (
								<FileItem
									originNode={originNode}
									file={file}
									fileType={fileType}
									fileSubtype={fileSubtype}
									renderFileItem={this.renderFileItem}
								/>
							)
						}}
						// 解决网页崩溃的问题，不能删掉
						previewFile={false}>
						<div className='dragger-inner'>
							<span></span>
							<p style={{ whiteSpace: 'nowrap' }}>
								{'单击 或 拖动文件到此区域以上传 (仅支持上传' +
									(archiveType === 1
										? '多媒体类型文件(JPG、JPEG、PNG、GIF等))'
										: 'EML文件)')}
							</p>
						</div>
					</Dragger>
				</div>
			</Spin>
		)
	}
}

export default UploadFiles
