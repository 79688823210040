import {
	NEWDETAILS_ADD,
	OLDDETAILS_ADD,
	APPLYCONTENT_ADD,
	APPLYFIX_FINISH,
} from '@/Redux/ActionTypes/Public/PubForm'
import { INITIAL_PUBARCHIVE } from '@/Redux/ActionTypes/PubArchive'
import { dossierEnum } from '@/Utils/Enum'
import { archiveUrl, fixApply, filesUrl, dossierUrl, mapUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
import { message } from 'antd'
import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
// 更新红点函数
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

export const addOldDetailsAction = (data, current) => {
	return {
		type: OLDDETAILS_ADD,
		data: data,
		current: current,
	}
}

export const addNewDetailsAction = (data, current) => {
	return {
		type: NEWDETAILS_ADD,
		data: data,
		current: current,
	}
}

export const addApplyContentAction = (data, current) => {
	return {
		type: APPLYCONTENT_ADD,
		data: data,
		current: current,
	}
}

export const finishApplyFixAction = data => {
	return {
		type: APPLYFIX_FINISH,
	}
}

// 初始化pubArchive[markName].old
export const inititalPubArchiveAction = (markName, pubArchiveData) => {
	return {
		type: INITIAL_PUBARCHIVE,
		filter: {
			markName,
			...pubArchiveData,
		},
	}
}

/**
 * 获取元数据结构树
 * @param {Number} id 申请表id
 */
export const getMetaStructTreeThunk = formItems => () =>
	new Promise(resolve => {
		_fetch({
			url: filesUrl.getFileMetadataStructTree,
			type: 'get',
		})
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data.data)
				}
			})
			.catch(err => {
				//console.log('获取元数据结构树失败' + err)
			})
	})

/**
 * 获取案卷或档案（文件）详情
 * @param {Object} record 案卷或档案（文件）的信息
 */
export const getDetailThunk = record => () =>
	new Promise(async (resolve, reject) => {
		const { id, type, archiveType } = record
		let url = null
		switch (dossierEnum.type[type]) {
			case '案卷':
				url = dossierUrl.getDossierDetail
				break
			case '文件':
				archiveType === 1
					? (url = archiveUrl.getArchiveDetail)
					: (url = archiveUrl.getDocDetail)
				break
			default:
				return
		}
		let res = await _fetch({
			url,
			data: {
				id,
			},
		})
		if (res && res.data.code === 200) {
			if (type) {
				resolve({
					...res.data.data,
					type: record.type,
				})
			} else {
				// 案卷顺带请求其下的列表
				_fetch({
					url: archiveUrl.getArchiveList,
					type: 'get',
					data: {
						id,
						size: 999,
					},
					isJson: true,
				}).then(data => {
					if (data && data.data.code === 200) {
						res.data.data['archiveDetail'] = data.data.data.records || []
						resolve({
							...res.data.data,
							type: record.type,
						})
					} else {
						reject()
					}
				})
			}
		} else {
			reject()
		}
	})

/**
 * 修正申请
 * @param {Object[]} record 案卷或档案（文件）的内容
 */
export const fixApplyThunk = record => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: fixApply.fixApply,
			type: 'post',
			data: record,
			isJson: true,
		}).then(res => {
			if (res.data.code === 200) {
				updataRedDots()
				message.success('新增成功')
				resolve()
			} else {
				reject()
			}
		})
	})

/**
 * 上传文件
 * @param {Object[]} record 案卷或档案（文件）的内容
 */
export const uploadFileThunk = formData => () =>
	new Promise((resolve, reject) => {
		_fetch({
			type: 'post',
			url: filesUrl.upload,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('上传成功')
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

// 重新人脸识别
export const resetFaceRecognitionThunk = fileId => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.faceRecognitionByArchive,
			type: 'get',
			data: {
				fileId,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})
