import { collectedFileUrl, departmentUrl } from '@/Utils/Urls'
import axios from 'axios'

export const getCollectedFileTypeThunk = data => () =>
	new Promise((resolve, reject) => {
		axios
			.get(collectedFileUrl.getCollectedFileType, data)
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res.data)
				}
			})
			.catch(err => {
				reject(err.response.data.message)
			})
	})

export const getAllDepartmentNameTreeThunk = () => () =>
	new Promise((resolve, reject) => {
		axios
			.get(departmentUrl.getAllDepartmentNameTree)
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res.data)
				}
			})
			.catch(err => {
				reject(err.response.data.message)
			})
	})
