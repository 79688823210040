import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { departmentUrl, fondsManagerUrl, userUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
// import { message } from 'antd'
// import { wordLimit } from './ActionCreators'
const formLayout = {
	labelCol: { span: 5 },
	wrapperCol: { span: 19 },
}
let timer = null

// 新增或编辑部门的模态框
export default class DepartmentModal extends Component {
	/**
	 *
	 * 获取业务负责人的下列数据
	 * @param {*} name: input框输入的值
	 * @param {*} ajaxConfig 请求的配置
	 * @param {*} setOptions 设置自动补全的下拉列表
	 * @memberof DepartmentModal
	 */
	fetchData = async (value, ajaxConfig, setOptions, callback) => {
		ajaxConfig.data.queryData = value || ''
		let res = await _fetch(ajaxConfig)
		if (res.data.data?.length) {
			setOptions(
				res.data.data.map(item => ({
					value: item.name + `——${item.workId}`,
					lable: item.name,
					id: item.id,
				}))
			)
		} else {
			setOptions([])
		}
		// ajaxConfig.data.queryData = value || '';
		// let res = await _fetch(ajaxConfig);
		// if (res.data.code === 200) {
		//   let tmpOptions = res.data.data.map(item => {
		//     return {
		//       value: item.name + `——${item.workId}`,
		//       lable: item.name,
		//       id: item.id
		//     }
		//   });
		//   tmpOptions.length === 0 && message.info('找不到该负责人');
		//   await setOptions(tmpOptions);
		// }
	}

	/**
	 * 遵循 antd 自定义组件的要求，
	 * 需要将修改后的 value 传入 antd 的 FormItem 所赋予的 onChange 事件中，得以被管理
	 *
	 */
	triggerChange = (value, options) => {
		let isInclude = false
		for (let i = 0; i < options.length; i++) {
			if (options[i].value === value) {
				// onChange(options[i].value);
				this.props.modalRef.current.formRef.setFieldsValue({
					managerId: options[i].id,
				})
				isInclude = true
				break
			}
		}
		if (!isInclude) {
			this.props.modalRef.current.formRef.setFieldsValue({ managerId: value })
		}
	}
	// triggerChange() {
	//   const { value, options } = this.state;
	//   for (let i = 0; i < options.length; i++) {
	//     if (options[i].value === value) {
	//       onChange(options[i].value);
	//       isInclude = true;
	//       break;
	//     }
	//   }
	// }

	// lodash的防抖不好用，得自己封装一个
	debounce(fn, delay = 300) {
		return function () {
			if (timer) clearTimeout(timer)
			// 获取this和argument
			var _this = this
			var _arguments = arguments
			timer = setTimeout(function () {
				// 在执行时，通过apply来使用_this和_arguments
				fn.apply(_this, _arguments)
			}, delay)
		}
	}

	// 每次输入数值进行搜索
	onSearch = (value, ajaxConfig, setOptions, callback) => {
		this.debounce(this.fetchData)(value, ajaxConfig, setOptions)
		callback && callback(value)
	}

	// 表单配置项
	formItems = [
		[
			{
				type: 'Input',
				name: 'departmentNumber',
				label: '部门编号',
				placeholder: '请输入部门编号',
				// placeholder: `请输入${wordLimit['d'] !== null ? wordLimit['d'] + '位' : ''}纯数字或纯大写字母`,
				// rules: [{
				//   required: true,
				//   type: 'string',
				//   pattern: wordLimit['d'] !== null ? new RegExp(`^[A-Z0-9]{${wordLimit['d']}}$`) : null,
				//   message: `请输入${wordLimit['d'] !== null ? wordLimit['d'] + '位' : ''}纯数字或纯大写字母`
				// }]
			},
		],
		[
			{
				type: 'Input',
				name: 'departmentName',
				label: '部门名称',
				placeholder: '请输入部门名称',
			},
		],
		[
			{
				type: 'AsyncTreeSelect',
				name: 'parentId',
				label: '业务主管部门',
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: (res, changeKeys) => {
					return changeKeys(
						[res],
						{
							departmentName: 'title',
							id: 'value',
						},
						'children',
						node => {
							if (node['value'] === -1) {
								node['title'] = '广东工业大学'
							}
						}
					)
				},
				placeholder: '请选择业务主管部门',
			},
		],
		[
			{
				type: 'AsyncSelect',
				label: '全宗',
				name: 'fondsIdentifierId',
				placeholder: '请选择全宗',
				ajaxConfig: {
					url: fondsManagerUrl.getFondsIdentifier,
					type: 'post',
					isJson: true,
				},
				rules: [
					{
						required: false,
					},
				],
				formatDataFunc: data => {
					return data.records.map(item => ({
						label: item.fondsName,
						value: item.id,
					}))
				},
			},
		],
		[
			{
				type: 'AsyncInput',
				label: '部门负责人',
				name: 'managerId',
				placeholder: '请输入负责人名称或者用户名进行搜索,并选择一项填入',
				ajaxConfig: {
					url: userUrl.searchPartialUser,
					data: {
						total: 5,
					},
				},
				rules: [
					{
						required: false,
						type: 'number',
						message: '请选择一位已存在的用户',
					},
				],
				onSearch: this.onSearch,
				// onSelect: this.selectManager,
				onSelect: (value, option, setValue) => {
					setValue(value)
					this.props.modalRef.current.formRef.setFieldsValue({
						managerId: option.id,
					})
				},
				triggerChange: this.triggerChange,
			},
		],
		[
			{
				type: 'TextArea',
				name: 'description',
				label: '部门简介',
				rules: [{ ruquired: false }],
				placeholder: '请输入部门的简介',
				showCount: true,
				maxLength: 250,
				resize: 'noResize',
			},
		],
	]

	render() {
		const { modalRef, onOk = () => { }, title, okText, hideCallback } = this.props
		return (
			<PubModalForm
				title={title}
				ref={modalRef}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				width={600}
				okText={okText}
				onOk={onOk}
				formProps={{
					// 表单的配置项
					name: 'DepartmentModalForm',
					formItems: this.formItems,
					layout: formLayout,
				}}
				hideCallback={hideCallback}
			/>
		)
	}
}
