import { ROUTE_CHANGE, TAB_ADD, TAB_REMOVE } from '@/Redux/ActionTypes/Route'

export function routeChangeAction(routeData) {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

export function tabRemoveAction(targetKey, history) {
	return {
		type: TAB_REMOVE,
		filter: targetKey,
		history,
	}
}

export function tabAddAction(targetKey, history) {
	return {
		type: TAB_ADD,
		filter: targetKey,
		// history
	}
}
