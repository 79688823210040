import React, { Component } from 'react'
import { Upload, message, Button, Modal } from 'antd'
import { PlusOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { filterYellow } from '@/Utils'
import CollectedFileEditor from '@/Public/CollectedFileEditor'
import { createFileChunk, getFileMd5, uploadFile } from './ActionCreators'
import { connect } from 'react-redux'
import { addCollectedFileThunk, downloadCertificateThunk } from './ActionCreators'

const mapDispatchToProps = dispatch => ({
	addCollectedFile: (...rest) => dispatch(addCollectedFileThunk(...rest)),
	downloadCertificate: (...rest) => dispatch(downloadCertificateThunk(...rest)),
})

@connect(null, mapDispatchToProps)
class MobileUpload extends Component {
	state = {
		fileList: [],
	}

	// 上传文件前先鉴黄
	handleBeforeUpload = async file => {
		const testResult = await filterYellow(file)
		return new Promise((resolve, reject) => {
			// 如果鉴黄结果不通过，则不允许上传
			if (testResult.isPorn || testResult.isSexy || testResult.isHentai) {
				file.status = 'error'
				file.response = '图片包含敏感信息，不允许上传'
				reject()
			} else {
				resolve()
			}
		})
	}

	uploadFiles = async files => {
		if (files.file.size === 0) {
			message.info('不支持上传空文件')
			return
		} else if (files.file.size >= 5120 * 1024 * 1024) {
			message.info('仅限上传5G以内的文件')
			return
		}

		const fileName = files.file.name
		const fileType = files.file.type
		const fileChunkList = createFileChunk(files.file)
		const fileMd5Value = await getFileMd5(fileChunkList)
		uploadFile(fileMd5Value, fileName, fileChunkList, fileType, files)
			.then(res => {
				// 成功标志
				files.onSuccess(res, files.file)
				message.success('上传成功')
			})
			.catch(err => {
				files.onError(err, files.file)
			})
	}

	handleChange = ({ file, fileList }) => {
		if (file.response) {
			if (file.response.code === 400) {
				message.error(file.response.message)
				return
			}
		}
		this.setState({
			fileList,
		})
	}

	// 获取表单 ref
	getRef = ref => {
		this.formRef = ref
	}

	collectedFileEditorBtn = [
		{
			text: '返回',
			onClick: this.props.goBack,
		},
		{
			text: '提交资料',
			type: 'primary',
			htmlType: 'submit',
		},
	]

	// 设置请求数据
	setAjaxData = (values, index) => {
		const { fileList } = this.state
		const { themeId } = this.props
		const { fileId, thumbnailFileId, zippedImageFileId } =
			fileList[index].response.data

		Object.keys(values).forEach(key => {
			if (values[key] == null) {
				delete values[key]
			}
		})

		return {
			...values,
			fileId,
			themeId,
			thumbnailFileId,
			zippedFileId: zippedImageFileId,
		}
	}

	onFinish = value => {
		const { fileList } = this.state
		const { addCollectedFile, goBack, downloadCertificate } = this.props
		let title

		//如果fileList为undefinded
		if (!(fileList && fileList.length)) {
			message.error('请先上传文件')
			return
		}

		const data = this.setAjaxData(value, 0)
		addCollectedFile(data)
			.then(res => {
				downloadCertificate([res.data])
				if (data.email) {
					title = '捐赠证书已发往邮箱，'
				}
				Modal.confirm({
					title: `资料已经提交完毕，${title ? title : ''}是否继续上传文件？`,
					icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
					okText: '退出',
					cancelText: '继续上传',
					onOk() {
						goBack()
						message.success('资料提交完毕，自动为您返回首页')
					},
					onCancel: () => {
						this.setState({
							fileList: [],
						})
					},
				})
			})
			.catch(err => {
				message.error(`资料提交失败, ${err.message}`)
			})
	}

	render() {
		const { fileList } = this.state
		const { themeTopic } = this.props
		return (
			<>
				<Upload
					listType='picture-card'
					multiple={false}
					customRequest={this.uploadFiles}
					beforeUpload={this.handleBeforeUpload}
					onChange={this.handleChange}
					fileList={fileList}
					style={{ marginTop: '20px' }}>
					{fileList.length ? null : (
						<Button title='上传文件'>
							<PlusOutlined />
							上传
						</Button>
					)}
				</Upload>
				<CollectedFileEditor
					uniqueName='collected-file-details' // 表单唯一表示, 必须要
					onFinish={this.onFinish} // 表单提交函数
					buttons={this.collectedFileEditorBtn}
					themeTopic={themeTopic}
					getRef={this.getRef}
					heigh='100%'
					width='100%'
				/>
			</>
		)
	}
}

export default MobileUpload
