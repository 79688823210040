import React, { PureComponent } from 'react'
import { PubForm } from '@/Public/PubForm'
import { Modal, Button, Spin } from 'antd'
import './index.less'
/**
 * 该高阶组件用于强化PubForm，提供modal相关方法
 * @param {Component}} OldComponent
 */
const PubModalFormHOC = OldComponent => {
	return class PubModalForm extends PureComponent {
		constructor(props) {
			super(props)
			this.state = {
				loading: '', // 按钮列表的 loading 状态
				visible: false,
				spinning: false, // 模态框加载状态
			}
		}

		// 修改模态框的加载状态
		setSpinning = (spinning, callback) => {
			this.setState({ spinning }, () => {
				callback &&
					setTimeout(() => {
						callback(this.formRef)
					}, 0)
			})
		}

		/**
		 * 模态框打开方法
		 * @param {Function} callback 打开后的回调
		 */
		show = callback => {
			this.setState(
				{
					visible: true,
				},
				() => {
					/*
					 * 此处使用定时器是为了让 callback 能够正常获取到 formRef
					 * 原因是将 visible 设置为 true 的时候form 组件可能还没渲染
					 * 此时 ref 还未连接上, 会使得传入的 this.formRef 为 undefined
					 * 因为定时器是宏任务，渲染代码为同步代码，依据 js 的执行机制，会先跑同步代码再跑微任务、宏任务
					 * 此处 hack 可以确保 callback 能够拿到 form 实例的方法
					 */
					callback &&
						setTimeout(() => {
							callback(this.formRef)
						}, 0)
				}
			)
		}

		// 模态框隐藏方法
		hide = () => {
			const { hideCallback } = this.props
			this.setState(
				{
					visible: false,
				},
				() => {
					hideCallback && hideCallback() // 关闭回调
					// modal隐藏时关闭所有loading
					this.finish()
				}
			)
		}
		// 异步完成后，外部组件可以调用该钩子来恢复按钮的 loading 状态
		finish = () => this.setState({ loading: '' })

		/**
		 * 该方法用于根据buttonList配置生成按钮列表
		 * @param {Array} buttonList 按钮配置列表
		 */
		createButtonList = () => {
			const { buttonList, okText = '提交', onOk } = this.props
			// 可供外部按钮调用的 modal 钩子函数
			const modalFuncs = {
				hide: this.hide,
				finish: this.finish,
				setSpinning: this.setSpinning,
				// setDisabled: this.setDisabled
			}
			// 最终按钮列表
			let btnList = []
			if (buttonList) {
				// 如果传入 buttonList 那么就渲染 buttonList
				btnList = [...buttonList]
			} else if (onOk) {
				// 如果没有传入 buttonList 但是传入了 onOk 那么就渲染确定按钮
				btnList = [
					{
						text: okText,
						buttonProps: {
							type: 'primary',
						},
						onClick: onOk,
					},
				]
			}
			let { loading } = this.state
			// 默认会在所有按钮之前添加一个取消按钮，相当于关闭 Modal
			// 如果footer={[]}，则什么按钮都没有
			if (btnList.length !== 0 && btnList[0].text !== '取消') {
				btnList.unshift({
					text: '取消',
					onClick: (_, { hide }) => {
						hide()
					},
				})
			}
			return btnList.map(btn => {
				const {
					text,
					buttonProps,
					onClick,
					needLoading = true,
					needValidations = true,
				} = btn
				// needValidations表示是否需要进行校验， needLoading表示是否需要有加载状态
				// 按钮的 key，如果传入 Icon 等 React 组件就拿其 displayName 作为按钮的 key，否则就是内容作为 key
				let btnKey = React.isValidElement(text)
					? text.type.render.displayName
					: text
				if (!onClick) {
					throw new Error(
						`小可爱记得为“${btnKey}”按钮添加点击回调函数 onClick 噢~😂`
					)
				}
				return (
					<Button
						key={btnKey} // 按钮的 key 以 btnKey 即可，因为一般不会更换顺序，姑且唯一
						// 按钮的 loading 状态根据 btnKey 来区分即可，如果是取消按钮是不会有 loading 的
						loading={loading === btnKey && btnKey !== '取消'}
						{...buttonProps} // 按钮的属性配置项，参考 antd
						onClick={async (...parameters) => {
							// 如果是取消按钮或者校验失败则 formData 拿到 undefined，这时都不出发按钮加载状态
							let formData = undefined
							needValidations &&
								(formData =
									btnKey !== '取消'
										? await this.formRef.getFormData()
										: undefined)
							if (needLoading) {
								this.setState({ loading: formData ? btnKey : '' }, () => {
									// 如果是取消按钮或者拿到数据了才调用
									// buttonList item的配置的onClick函数
									if (formData || btnKey === '取消')
										onClick(formData, modalFuncs)
								})
							} else {
								onClick(...parameters)
							}
						}}>
						{text}
					</Button>
				)
			})
		}

		render() {
			const {
				title,
				destroyOnClose = false,
				width = 900,
				buttonList,
				formProps,
				zIndex = 1000,
				formDisabled = false,
				// 模态框左边放置诸如文件浏览器之类的东西，传入React组件
				leftContent = null,
				forceRender = false,
				mask = false,
				maskClosable = true,
				getInstance,
				className,
			} = this.props
			const { visible, spinning } = this.state
			const btnList = this.createButtonList(buttonList)
			return (
				<Modal
					width={width}
					title={title}
					visible={visible}
					footer={btnList} // 底部按钮列表
					onCancel={this.hide} // 关闭时触发
					destroyOnClose={destroyOnClose} // 关闭时是否销毁数据
					zIndex={zIndex}
					className='pub-modal-form'
					mask={mask}
					maskClosable={maskClosable} //点遮罩层是否关闭
					forceRender={forceRender}
					wrapClassName={className}>
					<Spin spinning={spinning}>
						{leftContent ? (
							<div className='left-content'>{leftContent}</div>
						) : null}
						<div className='pub-form'>
							<OldComponent
								{...formProps}
								getInstance={async formRef => {
									this.formRef = formRef
									getInstance && (await getInstance(formRef)) // 外面有在props传getInstance的话，就调用一下
								}}
								formDisabled={formDisabled}
							/>
						</div>
					</Spin>
				</Modal>
			)
		}
	}
}
export default PubModalFormHOC(PubForm)
