import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import FilePreviewer from '@/Public/FilePreviewer'
import PubArchive from '@/Public/PubArchive'
import moment from 'moment'
import {
	finishApplyFixAction,
	addNewDetailsAction,
	uploadFileThunk,
	resetFaceRecognitionThunk,
} from './ActionCreator'
import {
	routeChangeAction,
	pushRoutersAction,
	getDetailThunk,
	addPubFileAction,
	upgradePubArchiveAction,
} from '../DossierDetails/ActionCreator' // 从案卷详情页拿到函数
import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload, Space } from 'antd'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import { pub_getAllTableData } from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator.js'
import PubFileDetails from '@/Public/FileDetails'

const markName = 'FixDossierDetails' // redux的标识

const mapStateToProps = state => {
	return {
		pubArchive: state.pubArchive,
		pub_OldDetail: state.pubForm.pub_fixApplyDetails.old_details, // 获取修正前数据
		pub_NewDetails: state.pubForm.pub_fixApplyDetails.new_details, // 获取修正后数据
		showTableList: state.pubTable.pub_allTableData.FixDossierDetails, // 获取表格数据
		route: state.route.item, // 获取当前路由
		pub_fileDetails: state.pubForm.pub_fileDetails, // 获取表单数据
	}
}
const mapDispatchToProps = dispatch => {
	return {
		uploadFile: (...rest) => dispatch(uploadFileThunk(...rest)), // 修正文件文件
		// 以下为案卷详情页所需要的请求
		getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey.key))
			} else {
				dispatch(routeChangeAction(activeKey.key))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
		},
		pushRouters(link) {
			return dispatch(pushRoutersAction(link))
		},
		finishApplyFix() {
			dispatch(finishApplyFixAction())
		},
		addNewDetails(data, current) {
			dispatch(addNewDetailsAction(data, current))
		},
		addPubFileData(data) {
			return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		// 表格
		getAllTableData(...rest) {
			return dispatch(pub_getAllTableData(...rest)) // 把获取到的表格所有数据发送到store
		},
		// 更新案卷信息
		upgradePubArchive: (markName, pubArchive) =>
			dispatch(upgradePubArchiveAction(markName, pubArchive)),
		resetFaceRecognition: (...rest) => dispatch(resetFaceRecognitionThunk(...rest)), // 重新识别人脸
	}
}
@connect(mapStateToProps, mapDispatchToProps)
class OldDetails extends Component {
	state = {
		fileList: [], // 上传的文件信息
		uploading: false, // 是否在上传状态
		fileId: null, // 文件id
		fileToken: null, // 新文件token
		fileType: null, // 新文件Type
		thumbnailFileToken: null, // 缩略图token
		thumbnailFileType: null, // 缩略图type
		images: [],
		ids: {}, //缩略图和图片id
		faces: null, // 当前人脸信息
	}

	componentDidMount() {
		if (this.props.pub_OldDetail.type) {
			const { pub_NewDetails, pub_OldDetail } = this.props // 拿到redux上的详情
			// * 提了新需求要求不立马识别
			//// 如果编辑过来识别人脸
			////this.getImageFace(this.props.pub_NewDetails.fileId)
			this.setImages() // 设置图片
			this.setFormData() // 设置表单值
			this.setState({
				ids: {
					fileId: pub_NewDetails.fileId,
					thumbnailFileId: pub_NewDetails.thumbnailFileId,
				},
				// 设置文件参数便于传参
				fileToken: pub_NewDetails.fileToken || pub_OldDetail.fileToken, // 新文件token
				fileType: pub_NewDetails.contentType || pub_OldDetail.fileType, // 新文件Type
				thumbnailFileToken:
					pub_NewDetails.thumbnailFileToken || pub_OldDetail.thumbnailFileToken, // 缩略图token
				thumbnailFileType:
					pub_NewDetails.thumbnailContentType ||
					pub_OldDetail.thumbnailFileType, // 缩略图type
			})
		}
	}

	// 设置images的值
	setImages = () => {
		this.setState({ images: [] }, () => {
			const { pub_NewDetails } = this.props // 拿到redux上的详情
			let images = []

			// 没有archiveList显示
			if (!images?.length) {
				let imgData = {
					original: `/api/file/download/${pub_NewDetails.fileToken}`,
					thumbnail: `/api/file/download/${pub_NewDetails.thumbnailFileToken || pub_NewDetails.fileToken}`,
					fileData: pub_NewDetails.fileData || {
						fileToken: pub_NewDetails.fileToken,
						fileType: pub_NewDetails.fileType,
					}, // 放在这里利于传参
					renderItem: this.renderFilePreviewer.bind(this), // 设置显示图标、视频、图片
				}

				if (
					!pub_NewDetails.thumbnailFileToken &&
					pub_NewDetails.fileType !== 'image/jpeg'
				) {
					// 如果没有缩略图且没有图片显示则下方缩略图片显示FilePreviewer
					imgData = {
						...imgData,
						renderThumbInner: this.renderFilePreviewer.bind(this),
					}
				}

				// 如果是音频，显示小图标
				if (pub_NewDetails.fileType?.includes('audio')) {
					imgData.renderThumbInner = this.renderThumbFilePreviewer.bind()
				}

				images.push(imgData)
			}
			this.setState({ images })
		})
	}

	// 设置表单的值
	setFormData = () => {
		// 设置表单的值
		let formData = this.props.pub_NewDetails // 拿到redux上的详情
		// 点击下一步后metaData就会被保存在redux上
		if (formData.metaData) {
			for (const id in formData.metaData) {
				if (Object.hasOwnProperty.call(formData.metaData, id)) {
					formData[id] = formData.metaData[id]
				}
			}
		} else {
			// 第一次进入文件编辑页
			formData.metadataStructTreeBoList?.forEach(current => {
				formData[`${current.id}`] = current.metadataValue
			})
		}

		if (formData.personList) {
			// 过滤值为null的人名
			let filterNameArray = formData?.personList?.filter(value => {
				return value.name != null
			})
			// 取出人名
			let nameArray = filterNameArray
				.map(value => {
					return value.name
				})
				.toString()

			nameArray += formData.people ? `,${formData.people}` : ''

			formData['74'] = nameArray
		}

		formData['75'] && (formData['75'] = moment(formData['75']))
		formData['18'] && (formData['18'] = moment(formData['18']))
		formData['86'] && (formData['86'] = moment(formData['86']))
		formData['20'] && (formData['20'] = moment(formData['20']))

		this.formRef.current.setFieldsValue(formData)
	}

	// 修改文件，发请求，拿数据
	handleUpload = file => {
		const formData = new FormData() // 构造一个formData实例对象，用于存储formData数据
		formData.append('multipartFile', file) // 将后台指定的字段名作为属性，文件数据作为属性值

		this.setState({
			// 设置为正在上传的状态
			uploading: true,
		})
		this.props.uploadFile(formData).then(data => {
			this.setState({
				images: [],
			})
			let images = []
			let imgData = {
				original: `/api/file/download/${data.fileToken}`,
				thumbnail: `/api/file/download/${data.thumbnailFileToken || data.fileToken}`,
				fileData: data.fileData || {
					fileToken: data.fileToken,
					fileType: data.contentType,
				}, // 放在这里利于传参
				renderItem: this.renderFilePreviewer.bind(this), // 设置显示图标、视频、图片
			}

			if (!data.thumbnailFileToken && data.thumbnailContentType !== 'image/jpeg') {
				// 如果没有缩略图且没有图片显示则下方缩略图片显示FilePreviewer
				imgData = {
					...imgData,
					renderThumbInner: this.renderFilePreviewer.bind(this),
				}
			}
			images.push(imgData)
			this.setState({
				images,
				ids: {
					fileId: data.fileId,
					thumbnailFileId: data.thumbnailFileId,
					zippedImageFileId: data.zippedImageFileId,
				},
				fileToken: data.fileToken, // 新文件token
				fileType: data.contentType, // 新文件Type
				thumbnailFileToken: data.thumbnailFileToken, // 缩略图token
				thumbnailFileType: data.thumbnailContentType, // 缩略图type
				faces: ['beforeFetch'], // 转圈圈
			})
			// 上传后识别人脸
			this.getImageFace(data.fileId, true)
		})
	}

	// 识别人脸识别数据
	getImageFace = async (fileId, allowEdit = false) => {
		const { pub_NewDetails, faceRecognition } = this.props // 拿到redux上的详情
		const fileType = pub_NewDetails.fileType?.split('/')[0]
		if (fileType !== 'image') {
			if (!allowEdit) {
				this.setState({
					faces: [],
				})
				return
			}
		}
		try {
			const faceData = (await faceRecognition(fileId)).data
			const names = faceData
				.map(item => item.name)
				.filter(item => item !== null)
				.join(',')

			this.setState({
				faces: faceData,
			})

			if (!pub_NewDetails['74'] || allowEdit) {
				this.formRef.current.setFields([
					{
						name: 74,
						value: names,
					},
				])
			}
		} catch (err) {}
	}

	// 重新进行人脸识别并填写数据
	resetImageFace = (fileId, id) =>
		new Promise((resolve, reject) => {
			const { resetFaceRecognition } = this.props
			resetFaceRecognition(fileId, id)
				.then(res => {
					const faceData = res.data
					const names = faceData
						.map(item => item.name)
						.filter(item => item !== null)
						.join(',')
					this.formRef.current.setFields([
						{
							name: 74,
							value: names,
						},
					])
					this.setState({
						faces: faceData,
					})
					resolve()
				})
				.catch(() => reject())
		})

	// 返回图标或者视频
	renderFilePreviewer = data => {
		return <FilePreviewer fileData={data.fileData} />
	}

	renderThumbFilePreviewer = data => {
		return <FilePreviewer fileData={data.fileData} isThumbnail={true} />
	}

	// 处理本地文件上传，转化为数据
	handleFileChange = info => {
		//处理文件change，保证用户选择的文件只有一个
		if (info.fileList.length === 0) {
			// 取最后一个文件（即只取一个文件）
			this.setState({ fileList: [] })
		} else {
			this.setState({ fileList: [info.fileList[info.fileList.length - 1]] })
		}
	}

	renderButtonList = () => {
		const { fileList } = this.state

		// 上传文件的相关操作
		const props = {
			// 这里的props是设置上传文件的按钮
			onRemove: file => {
				// 移除文件
				this.setState(state => {
					const index = state.fileList.indexOf(file)
					const newFileList = state.fileList.slice()
					newFileList.splice(index, 1)
					return {
						fileList: newFileList,
					}
				})
			},
			beforeUpload: file => {
				// 设置新文件
				this.setState(state => ({
					fileList: [...state.fileList, file],
				}))
				// 传参发请求
				this.handleUpload(file)
				return false
			},
			fileList,
		}
		return (
			<Space className='fixFile-buttonBox'>
				{/* 文件按钮组 */}
				<Space className='upload-box'>
					<Upload
						className='fix-upload'
						{...props}
						onChange={this.handleFileChange}
						showUploadList={false}>
						<Button className='btn-warning' icon={<UploadOutlined />}>
							替换
						</Button>
					</Upload>
				</Space>
				<Button type='primary' onClick={this.nextStep} htmlType='submit'>
					下一步
				</Button>
			</Space>
		)
	}

	// 上一步
	lastStep = () => {
		this.props.finishApplyFix()
	}

	// 下一步
	nextStep = () => {
		const { addNewDetails, pub_OldDetail } = this.props
		if (pub_OldDetail.type) {
			const { fileToken, thumbnailFileToken, fileType, thumbnailFileType, ids } =
				this.state
			this.formRef.current.validateFields().then(() => {
				let fixContent = this.formRef.current.getFieldValue()
				const momentTime = [75, 18, 86, 20] // 存是时间的元数据
				const fileMetadata = {}
				const data = {}
				// 遍历要格式化的数据
				for (let i in fixContent) {
					// i 为数字，则证明是元数据内容
					if (!isNaN(Number(i))) {
						if (fixContent[i]) {
							fileMetadata[`${i}`] = momentTime.includes(Number(i))
								? moment(fixContent[i])
										.format('YYYY-MM-DDTHH:mm:ss')
										.replace(/\.(.*)/, '')
								: fixContent[i]
						}
					}
					// 不是元数据的内容就直接放进请求数组里
					else {
						data[i] = fixContent[i]
					}
				}
				// 设置放在redux上的数据
				fixContent = {
					id: pub_OldDetail.id,
					...data,
					metaData: fileMetadata,
					fileToken,
					thumbnailFileToken,
					fileType,
					thumbnailFileType,
					fileId: ids?.fileId,
					thumbnailFileId: ids?.thumbnailFileId,
					zippedFileId: ids?.zippedImageFileId,
				}
				addNewDetails(fixContent, 2)
			})
		} else {
			const oldData = this.props.pubArchive[markName]?.old // 旧版信息
			const filesData = this.props.pubArchive[markName]?.new.filesData // 新信息文件列表

			this.formRef.validateFields().then(() => {
				let fixContent = this.formRef.getFieldValue()

				// 整理请求数据
				const requestData = {
					...fixContent,
					id: oldData.archiveData.id,
					archiveDetail: filesData,
				}
				addNewDetails(requestData, 2)
			})
		}
	}

	// 案卷上一步下一步分开
	renderLeftButtons = () => {
		return (
			<Button
				style={{ color: 'white', marginRight: '10px' }}
				onClick={this.lastStep}>
				上一步
			</Button>
		)
	}
	//上一行为onClick={this.lastStep}
	// lastStepp = () => {

	// }

	renderRightButtons = () => {
		return (
			<Button type='primary' onClick={this.nextStep}>
				下一步
			</Button>
		)
	}

	// table栏按钮操作
	returnButton = (text, record, index) => {
		const dossierData = this.props.pubArchive[markName].new
		const link = {
			title: '文件详情',
			key: '/workarea/commonComponents/fileDetails',
		}
		return (
			<div className='fix-table-buttonList'>
				<Button
					size='small'
					style={{ margin: '5px' }}
					onClick={() => {
						this.props.getDetail(record).then(data => {
							// 请求详情数据
							data['archiveList'] = dossierData?.filesData
							data['route'] = this.props.route // 由此可以知道从哪打开详情，并判断是否添加按钮
							data['startIndex'] = index
							this.props.addPubFileData(data) // 文件详情传到redux
							this.props.onChange(link, history) // 修改路由
							this.props.pushRouters(link) //添加分页栏
						})
					}}>
					查看
				</Button>
				{/* 删除按钮对table行进行删除功能 */}
				<Button
					size='small'
					className='btn-danger'
					style={{ margin: '5px' }}
					onClick={() => {
						const filesLength = dossierData.filesData.length
						let tmpFilesData = dossierData.filesData.filter(item => {
							return item.id !== record.id
						})
						let tmpPubArchive = {
							archiveData: dossierData.archiveData,
							filesData: tmpFilesData,
						}
						tmpFilesData.length !== filesLength &&
							this.props.upgradePubArchive(markName, tmpPubArchive)
					}}>
					删除
				</Button>
			</div>
		)
	}

	// 获取表单的实例
	getInstance = async formRef => {
		this.formRef = formRef
	}

	// 获取到文件编辑器的ref
	getRef = ref => {
		this.formRef = ref
	}

	//获取不常用数据的ref，并赋值，每次显示隐藏都是组件挂载销毁，所以要重复获取form
	getInfrequentFormRef = ref => {
		this.infrequentFormRef = ref
		this.setInfrequentFormData()
	}

	//设置不常用表单值
	setInfrequentFormData() {
		if (this.infrequentFormRef?.current) {
			this.infrequentFormRef.current.resetFields()
			const { pub_OldDetail } = this.props // 拿到redux上的详情
			// 设置表单的值
			let formData = pub_OldDetail
			pub_OldDetail.metadataStructTreeBoList?.forEach(current => {
				formData[`${current.id}`] = current.metadataValue
			})
			this.infrequentFormRef.current.setFieldsValue(formData)
		}
	}

	render() {
		const { images, faces, ids, fileList } = this.state
		const { pub_fileDetails } = this.props
		const fileType = fileList[0]?.type || this.props.pub_OldDetail.fileType

		if (this.props.pub_OldDetail.type) {
			const faceData = faces || ['beforeFetch']
			return (
				<Fragment>
					{/* <Space style={{ marginBottom: 20, width: '100%' }} className='fixFile-buttonBox' >
            {this.renderButtonList()}
          </Space> */}
					<PubFileDetails
						className='pubFileDetail'
						style={{ marginTop: '10px' }}
						uniqueName='fixFileDetails'
						items={images}
						disabled={!true}
						isFormat={true}
						onFinish={values => this.onSubmit(values)}
						getRef={this.getRef}
						fileType={fileType}
						personList={pub_fileDetails.personList}
						// 传人脸数据
						isFaceDetails={faceData}
						resetImageFace={() =>
							this.resetImageFace(
								ids.fileId || this.props.pub_NewDetails.fileId,
								ids.id || this.props.pub_NewDetails.id
							)
						}
						//文书部分
						isPaperDocument={this.props.pub_OldDetail.archiveType === 2}
						getInfrequentFormRef={this.getInfrequentFormRef}
						isFileFixApply={true}
						renderButtonList={this.renderButtonList}
					/>
				</Fragment>
			)
		} else {
			return (
				<React.Fragment>
					<PubArchive
						renderBottomButtons={this.renderBottomButtons}
						getInstance={this.getInstance}
						renderOperation={this.returnButton}
						markName={markName}
						formDisabled={!true}
						contentTile={'修正案卷'}
						appendFiles={true}
						renderLeftButtons={this.renderLeftButtons}
						renderRightButtons={this.renderRightButtons}
					/>
				</React.Fragment>
			)
		}
	}
}

export default OldDetails
