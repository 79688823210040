export const multimediaExtensions = [
	'.webm',
	'.mkv',
	'.flv',
	'.vob',
	'.ogg',
	'.ogv',
	'.drc',
	'.gif',
	'.gifv',
	'.mng',
	'.avi',
	'.m2ts',
	'.mts',
	'.qt',
	'.mov',
	'.wmv',
	'.yuv',
	'.rm',
	'.rmvb',
	'.viv',
	'.asf',
	'.amv',
	'.mp4',
	'.m4p',
	'.m4v',
	'.mpg',
	'.mp2',
	'.mpeg',
	'.mpe',
	'.mpv',
	'.m2v',
	'.svi',
	'.3gp',
	'.3g2',
	'.mxf',
	'.roq',
	'.nsv',
	'.f4v',
	'.f4p',
	'.f4a',
	'.f4b',
	'.aa',
	'.aac',
	'.aax',
	'.act',
	'.aiff',
	'.alac',
	'.amr',
	'.ape',
	'.au',
	'.awb',
	'.dss',
	'.dvf',
	'.flac',
	'.gsm',
	'.iklax',
	'.ivs',
	'.mmf',
	'.m4a',
	'.m4b',
	'.mp3',
	'.mpc',
	'.msv',
	'.nmf',
	'.oga',
	'.mogg',
	'.opus',
	'.ra',
	'.raw',
	'.rf64',
	'.sln',
	'.tta',
	'.voc',
	'.vox',
	'.wav',
	'.wma',
	'.wv',
	'.8svx',
	'.cda',
	'.webp',
	'.bmp',
	'.pcx',
	'.tif',
	'.jpeg',
	'.tga',
	'.exif',
	'.fpx',
	'.svg',
	'.psd',
	'.cdr',
	'.pcd',
	'.dxf',
	'.ufo',
	'.eps',
	'.ai',
	'.png',
	'.hdri',
	'.wmf',
	'.flic',
	'.emf',
]
export const multiMediaFileTypes = ['image', 'video', 'audio']
