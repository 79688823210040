import React, { Component } from 'react'
import {
	Input,
	Button,
	Checkbox,
	Form,
	message,
	Upload,
	Modal,
	Alert,
	Image,
	Spin,
	Carousel,
} from 'antd'
import { connect } from 'react-redux'
import QRCode from 'qrcode'
import {
	generateCaptchaActionThunk,
	loginActionThunk,
	SetUserTypeAction,
	SetPermissionAction,
	SetRoleListAction,
	setUserAction,
	getFondsIdentifierThunk,
	getDossierTypeThunk,
	getDepartmentTreeThunk,
	getFileMetadataStructTreeThunk,
	getSidebarTreeActionThunk,
	getCollectedFileTypeThunk,
	getMyThemeThunk,
	getAllThemeThunk,
	getSerialNumberConfigThunk,
} from './ActionCreator'
import { linkTo } from '@/Routes/ManageRouter'
import { history } from '@/index'
import { CookieUtil } from '@/Utils/index'
import { planeTree } from '@/Utils'
import {
	UserOutlined,
	LockOutlined,
	FileSearchOutlined,
	VerifiedOutlined,
	FileAddOutlined,
} from '@ant-design/icons'
import './index.less'
import { newSlideBar } from './handleSlideBar'
import { filesUrl } from '@/Utils/Urls'
import FilePreviewer from '@/Public/FilePreviewer'
import { getConfig } from '@/Utils/ModeTranslate'

// 首页的路由名
export let homePath = ''

function setHomePath(roleList, firstRoute) {
	if (!firstRoute) {
		firstRoute = JSON.parse(localStorage.getItem('firstRoute'))
	}
	// 存放所有可能是首页路由的列表
	let homePathList = {}
	firstRoute?.forEach(item => {
		homePathList[item.id] = {
			url: item.firstRouteLink,
			name: item.firstRouteLinkTitle,
		}
	})
	// 如果有 统计 的用户，首页为统计
	if (homePathList['3']) {
		homePath = homePathList['3']
		return
	}
	// 角色对应的 首页路由 的 侧边栏id
	let serverConfig = getConfig()
	if (typeof serverConfig == undefined) {
		throw Error('首页路由不存在')
	}
	let roleMapHomePathId = new Map(serverConfig.roleMapHomePathId)
	// 规定角色优先级，决定其对应首页路由
	// 将来新增角色的首页路由，设定为侧边栏第一个
	let rolePriority = [
		'fullPermissionsRole',
		'archiveManager',
		'archiveDutyManager',
		'departmentManager',
		'systemManager',
		'auditor',
		'user',
	]
	// 获取角色列表
	let roleArr = JSON.parse(localStorage.getItem('userMessage'))
	if (roleList) {
		roleArr = roleList
	} else if (roleArr) {
		roleArr = roleArr.roleList.map(item => item.name)
	}
	if (roleArr) {
		let checkRole = ''
		for (let i = 0; i < rolePriority.length; i++) {
			if (roleArr.includes(rolePriority[i])) {
				checkRole = rolePriority[i]
				break
			}
		}
		// 如果存在该角色，并且拿得到该角色对应的侧边栏id，那么赋予对应的homePath
		if (roleMapHomePathId.has(checkRole)) {
			let id = roleMapHomePathId.get(checkRole)
			// 审计员特殊处理一下
			if (checkRole === 'auditor') {
				homePath = {
					url: '/workarea/myApproval/correct',
					name: '修正审批',
				}
			} else {
				homePath = homePathList[id]
			}
		} else {
			// 由于新增用户，后台处理后，至少是user，所以基本不会到这里来
			let keys = Object.keys(homePathList)
			homePath = homePathList[keys[0]]
		}
	}
}
// 页面刷新，重新设置homePath
setHomePath()

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	loginAction: (...rest) => dispatch(loginActionThunk(...rest)), // 登录函数
	SetUserType: (...rest) => dispatch(SetUserTypeAction(...rest)),
	SetPermission: (...rest) => dispatch(SetPermissionAction(...rest)),
	SetRoleList: (...rest) => dispatch(SetRoleListAction(...rest)),
	setUser: (...rest) => dispatch(setUserAction(...rest)),
	getFondsIdentifier: (...rest) => dispatch(getFondsIdentifierThunk(...rest)),
	getDossierType: (...rest) => dispatch(getDossierTypeThunk(...rest)),
	getDepartmentTree: (...rest) => dispatch(getDepartmentTreeThunk(...rest)),
	getFileMetadataStructTree: (...rest) =>
		dispatch(getFileMetadataStructTreeThunk(...rest)),
	getSidebarTree: () => dispatch(getSidebarTreeActionThunk()),
	getCollectedFileType: (...rest) => dispatch(getCollectedFileTypeThunk(...rest)),
	getMyTheme: (...rest) => dispatch(getMyThemeThunk(...rest)),
	getSerialNumberConfig: (...rest) => dispatch(getSerialNumberConfigThunk(...rest)),
})
const formRef = React.createRef(null)
@connect(mapStateToProps, mapDispatchToProps)
class LoginForm extends Component {
	state = {
		loading: false,
		uploading: false, // 是否在上传状态
		previewVisible: false, // 鉴定模态框
		preview: {}, // 鉴定文件
		previewTitle: '', // 鉴定结果
		previewType: false,
		theme: [],
		codeData: {},
		codeLoading: false,
		Config: null,
	}

	componentDidMount() {
		this.updateCode()
		const formData = JSON.parse(localStorage.getItem('remember')) || {}
		formRef.current.setFieldsValue(formData)
		getAllThemeThunk().then(res => {
			let newThemeArr = Promise.all(
				res.data.map(theme => {
					QRCode.toDataURL(
						`${window.location.href}${
							window.location.href.endsWith('/') ? '' : '/'
						}collectFiles?${theme.themeId}`
					)
						.then(url => {
							theme.QRcodeUrl = url
						})
						.catch(err => {
							message.error(err)
						})
					return theme
				})
			)

			newThemeArr.then(result => {
				localStorage.setItem('theme', JSON.stringify(result))
				this.setState({
					theme: result,
				})
			})
		})

		getConfig().then(Config => {
			this.setState({
				Config,
			})
		})
	}

	// 登录函数
	login = values => {
		// localStorage.clear();

		const {
			loginAction,
			SetUserType,
			SetPermission,
			SetRoleList,
			setUser,
			getFondsIdentifier,
			getDossierType,
			getDepartmentTree,
			getFileMetadataStructTree,
			getSidebarTree,
			getCollectedFileType,
			getMyTheme,
			getSerialNumberConfig,
		} = this.props

		this.setState({ loading: true }, () => {
			loginAction({ ...values, codeData: this.state.codeData })
				.then(
					res => {
						// 如果勾选了记住我，把数据存到localStorage里面, 反之则删除
						if (values.remember) {
							localStorage.setItem(
								'remember',
								JSON.stringify({
									username: values.username,
									password: values.password,
								})
							)
						} else {
							localStorage.removeItem('remember')
						}

						message.success('登录成功！')
						const data = res.data
						const expire = res.data.expireAt
						// 将用户的信息存到cookies里面
						CookieUtil.set('userMessage', JSON.stringify(data), expire)
						window.id = data.user.id
						window.workId = data.user.workId
						window.userName = data.roleList.map(item => item?.name)

						//设置用户类型，拿到本地权限内容
						SetUserType((data.roleList || [{}]).map(item => item?.name)) // 这里设置到redux上，是哪些角色
						SetPermission(data.permissionList)
						SetRoleList(data.roleList)
						setUser(data.user)

						return Promise.all([
							// 获取全宗号
							getFondsIdentifier().then(res => {
								localStorage.setItem(
									'fonds',
									JSON.stringify(
										res.data.map((value, idnex) => ({
											name: value.fondsName,
											id: value.id,
										}))
									)
								)
							}),

							// 获取类别号
							getDossierType().then(res => {
								let content = res.data.children
								if (Array.isArray(content) === false) {
									content = [content]
								}
								const dossierTypeflatTree = planeTree(
									content,
									{},
									'children',
									'typeName',
									'needVerify'
								)
								const needVerifyDossierType = {}
								for (let key in dossierTypeflatTree) {
									if (dossierTypeflatTree[key]) {
										needVerifyDossierType[key] =
											dossierTypeflatTree[key]
									}
								}
								localStorage.setItem(
									'dossierType',
									JSON.stringify(content)
								)
								localStorage.setItem(
									'needVerifyDossierType',
									JSON.stringify(needVerifyDossierType)
								)
							}),

							// 获取部门树
							getDepartmentTree().then(res => {
								let content = res.data
								if (Array.isArray(content) === false) {
									content = [content]
								}
								localStorage.setItem(
									'department',
									JSON.stringify(content)
								)
							}),

							// 获取元数据
							getFileMetadataStructTree().then(res => {
								localStorage.setItem('metadata', JSON.stringify(res.data))
							}),

							// 获取资料类别号
							getCollectedFileType().then(res => {
								localStorage.setItem(
									'collectedFileType',
									JSON.stringify(res.data.children)
								)
							}),

							// 获取个人主题
							getMyTheme().then(res => {
								localStorage.setItem('MyTheme', JSON.stringify(res.data))
							}),

							getSerialNumberConfig().then(res => {
								localStorage.setItem(
									'serialNumberConfig',
									JSON.stringify(res)
								)
							}),
						])
					},
					err => {
						throw err
					}
				)
				.then(async () => {
					// 获取该用户侧边栏
					const siderBarRes = await getSidebarTree()
					const firstRoute = newSlideBar(siderBarRes.data?.sidebarTreeBo)

					// 设置首页地址
					setHomePath(window.userName, firstRoute)
					// 跳转页面
					const route = `${linkTo.topRoute}${homePath.url}`
					history.push(route)
				})
				.catch(err => {
					this.updateCode()
					formRef.current.resetFields(['verityCode'])
					message.destroy()
					message.error(err)
					this.setState({ loading: false })
				})
		})
	}

	// 文件转码
	getBase64 = file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = error => reject(error)
		})
	}

	// 显示文件
	handlePreview = async (file, boolean) => {
		const filePreview = await this.getBase64(file.originFileObj)
		this.setState({
			preview: {
				fileType: file.type,
				fileToken: filePreview,
			},
			previewVisible: true,
			previewTitle: boolean
				? `经验证，${file.name} \t文件为广东工业大学档案馆馆藏电子文件，特此证明`
				: `经验证，${file.name} \t文件非广东工业大学档案馆馆藏电子文件`,
			previewType: boolean,
		})
	}

	//处理文件change
	handleFileChange = info => {
		if (info.file.status === 'uploading') {
			this.setState({ uploading: true })
		} else {
			this.setState({ uploading: false })
		}
		if (info.file.status === 'done') {
			if (info.file.response?.code === 200) {
				this.handlePreview(info.file, true)
			} else if (info.file.response?.code === 400) {
				this.handlePreview(info.file, false)
			} else {
				message.warning(info.file.response.message)
			}
		} else if (info.file.status === 'error') {
			message.error(`${info.file.name} \t上传失败`)
		}
	}

	// 关闭模态框清楚state
	handleCancel = () =>
		this.setState({
			previewVisible: false,
			preview: {},
			previewTitle: '',
		})

	updateCode = () => {
		this.setState({
			codeLoading: true,
		})
		generateCaptchaActionThunk().then(res => {
			this.setState({
				codeLoading: false,
				codeData: res.data,
			})
		})
	}

	render() {
		const {
			loading,
			uploading,
			previewVisible,
			preview,
			previewTitle,
			previewType,
			codeData,
			codeLoading,
			Config,
			theme,
		} = this.state
		// 上传文件的相关操作
		return (
			<div className='login-box'>
				<Form
					{...layout}
					name='basic'
					initialValues={{
						remember: true,
					}}
					onFinish={this.login}
					layout='vertical'
					hideRequiredMark
					ref={formRef}>
					<div
						className='box-decorate'
						style={{ backgroundImage: `url(${Config?.logo})` }}></div>
					<h2 className='login-title'>{Config?.systemNameCN}</h2>
					<span className='login-title login-title-en'>
						{Config?.systemNameEN}
					</span>
					<Form.Item
						name='username'
						rules={[
							{
								required: true,
								message: '请输入用户名',
							},
						]}>
						<Input
							className='login-input'
							prefix={<UserOutlined />}
							placeholder='输入用户名'
						/>
					</Form.Item>

					<Form.Item
						name='password'
						rules={[
							{
								required: true,
								message: '请输入密码',
							},
						]}>
						<Input.Password
							className='login-input'
							prefix={<LockOutlined />}
							placeholder='输入密码'
						/>
					</Form.Item>

					<Form.Item
						name='verityCode'
						rules={[
							{
								required: true,
								message: '请输入验证码',
							},
						]}>
						<Input
							className='login-input'
							prefix={<VerifiedOutlined />}
							suffix={
								<Spin spinning={codeLoading}>
									<Image
										height={35}
										src={codeData.base64}
										preview={false}
										onClick={this.updateCode}
										title='看不清楚,换一张'
									/>
								</Spin>
							}
							placeholder='输入验证码'
						/>
					</Form.Item>

					<Form.Item
						name='remember'
						valuePropName='checked'
						className='remember-me'>
						<Checkbox className='login-check'>记住我</Checkbox>
					</Form.Item>

					<Form.Item>
						<Button id='login-button' htmlType='submit' loading={loading}>
							登录
						</Button>
					</Form.Item>

					<div className='extra'>
						<Form.Item>
							<Upload
								name='multipartFile'
								action={filesUrl.checkIsUploaded}
								onChange={this.handleFileChange}
								showUploadList={false}
								maxCount={1}>
								<Button
									type='link'
									loading={uploading}
									title='验证是否为本系统文件'
									className='upload-button'>
									<FileSearchOutlined />
									馆藏验证
								</Button>
							</Upload>
						</Form.Item>

						<Form.Item>
							<Carousel
								style={{ width: '230px', height: '60px' }}
								autoplay={true}>
								{theme?.map(item => {
									return (
										<Button
											type='link'
											title={item.topic}
											target='_blank'
											className='upload-button'
											href={`/collectFiles?${item.themeId}`}
											key={item.themeId}>
											<div className='QRcode-container'>
												<img src={item.QRcodeUrl} alt='' />
											</div>
											<FileAddOutlined />
											{item.topic}
										</Button>
									)
								})}
							</Carousel>
						</Form.Item>
					</div>
				</Form>

				<Modal
					visible={previewVisible}
					footer={null}
					title='鉴定结果'
					onCancel={this.handleCancel}
					maskClosable={false}
					width='800px'
					style={{ top: 20 }}
					destroyOnClose={true}>
					{previewType ? (
						<Alert message={previewTitle} type='success' showIcon />
					) : (
						<Alert message={previewTitle} type='error' showIcon />
					)}
					{previewType && (
						<FilePreviewer
							style={{
								height: '600px',
								fontSize: '200px',
							}}
							fileData={preview}
							wrapperClassName={'watermark-filebox'}
							apiString=''
							watermark={true}
						/>
					)}
				</Modal>
			</div>
		)
	}
}
// labelCol 是label所占的百分比，wrapperCol 是label后面的内容所占的百分比。
// 24格栅格系统，label所占为 a,label后面内容所占为 24-a
const layout = {
	labelCol: {
		span: 0,
		offset: 0,
	},
	wrapperCol: {
		span: 20,
		offset: 2,
	},
}

export default LoginForm
