import React, { Component } from 'react'
import { connect } from 'react-redux'
import './index.less'
import FilePreviewer from '@/Public/FilePreviewer'
import ButtonList from '@/Public/ButtonList'
import { message } from 'antd'
import {
	getDetailThunk,
	addPubFileAction,
	uploadFileThunk,
	updateRecordThunk,
	faceRecognitionThunk,
	updateAttachmentThunk,
	replaceArchiveFacesThunk,
} from './ActionCreator'
import { inititalPubArchiveAction } from '@/Public/PubTable/baseTable/ActionCreator'
import {
	getArchiveListThunk,
	upgradePubArchiveAction,
} from '../DossierDetails/ActionCreator'
import PubFileDetails from '@/Public/FileDetails'
import moment from 'moment'
import Enum from '@/Utils/Enum'
import _ from 'lodash'

const statusEnum = new Enum([
	'著录中',
	'预归档',
	'已归档',
	'著录中',
	'回收站',
	'已销毁',
	'已过期',
	'收藏',
])

const mapStateToProps = state => {
	return {
		pub_fileDetails: state.pubForm.pub_fileDetails, // 获取表单数据
		pubArchive: state.pubArchive,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		uploadFile: (...rest) => dispatch(uploadFileThunk(...rest)), // 上传文件
		inititalPubArchive: (markName, pubArchive) =>
			dispatch(inititalPubArchiveAction(markName, pubArchive)),
		getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
		addPubFileData(data) {
			return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		// 更新案卷信息
		upgradePubArchive: (markName, pubArchive) =>
			dispatch(upgradePubArchiveAction(markName, pubArchive)),
		updateRecord: (...rest) => dispatch(updateRecordThunk(...rest)), //著录编辑
		getArchiveList: (...rest) => dispatch(getArchiveListThunk(...rest)),
		faceRecognition: (...rest) => dispatch(faceRecognitionThunk(...rest)), // 识别人脸
		updateAttachment: (...rest) => dispatch(updateAttachmentThunk(...rest)),
		replaceArchiveFaces: (...rest) => dispatch(replaceArchiveFacesThunk(...rest)), //建立案卷和人脸关系
	}
}
@connect(mapStateToProps, mapDispatchToProps)
class FileDetails extends Component {
	state = {
		images: [],
		disabled: true,
		fileList: [], // 上传的文件信息
		uploading: false, // 是否在上传状态
		ids: {}, //缩略图和图片id
		faces: null, // 当前人脸信息
		faceIdList: [], // 人脸id集合
		recognitionUserIdList: [], //人脸信息集合
		doSliderCallback: true, // 是否执行滑动回调
	}

	// 判断一个值是否存在
	isExist = value => {
		if (value == null || value === '') return false
		return true
	}

	// 返回图标或者视频
	renderFilePreviewer = data => {
		return (
			<FilePreviewer
				fileData={data.fileData}
				isShowBtn={false}
				archiveId={data.archiveId}
				categoryCode={data.categoryCode}
				fatherDossierFileToken={data.fatherDossierFileToken}
			/>
		)
	}

	// 返回图标或者视频
	renderThumbFilePreviewer = data => {
		return <FilePreviewer fileData={data.fileData} isThumbnail={true} />
	}

	componentDidMount() {
		this.setImages() // 设置图片
		this.setFormData() // 设置表单值
		if (this.state.recognitionUserIdList?.length === 0) {
			this.setPersonList() //设置人脸信息
		}
		if (
			this.props.pub_fileDetails.route ===
			'/Manager/newConstruction/UrlToArchives' &&
			this.state.disabled
		) {
			// * 提了新需求要求不立马识别
			//// 如果编辑过来识别人脸
			////this.getImageFace(this.props.pub_fileDetails.fileId)
			this.setState({
				disabled: false,
			})
		}
		if (!this.state.faceIdList[0]) {
			let faceIdList = this.props.pub_fileDetails.personList?.map(function (data) {
				return data.recognitionUserId
			})
			this.setState({ faceIdList })
		}
	}

	// 设置images的值
	setImages = () => {
		this.setState({ images: [] }, () => {
			const { pub_fileDetails } = this.props // 拿到redux上的详情
			let images = []
			pub_fileDetails.archiveList?.forEach((image, index) => {
				// 设置轮播图组
				let imgData = {
					archiveId: pub_fileDetails.id,
					categoryCode: pub_fileDetails.categoryCodeId,
					original: `/api/file/download/${image.fileToken}`,
					thumbnail: `/api/file/download/${image.thumbnailFileToken || image.fileToken}`,
					fileData: image.fileData || {
						fileToken: image.fileToken,
						fileType: image.fileType,
						fileId: image.fileId,
						fileName: image.fileName,
						topic: image.topic,
					}, // 放在这里利于传参
					fatherDossierFileToken: pub_fileDetails.fatherDossierFileToken,
					renderItem: this.renderFilePreviewer.bind(this), // 设置显示图标、视频、图片
				}

				// if (!image.thumbnailFileToken && image.fileType !== 'image/jpeg') {   // 如果没有缩略图且没有图片显示则下方缩略图片显示FilePreviewer
				//   imgData = {
				//     ...imgData,
				//     renderThumbInner: this.renderThumbFilePreviewer.bind(this)
				//   }
				// }

				imgData = {
					...imgData,
					renderThumbInner: this.renderThumbFilePreviewer.bind(this),
				}

				// 如果是音频，显示小图标
				// if (image?.fileType?.includes('audio')) {
				//   imgData.renderThumbInner = this.renderThumbFilePreviewer.bind()
				// }
				images.push(imgData)
			})

			// 没有archiveList显示
			if (!images?.length) {
				let imgData = {
					archiveId: pub_fileDetails.id,
					categoryCode: pub_fileDetails.categoryCodeId,
					original: `/api/file/download/${pub_fileDetails.fileToken}`,
					thumbnail: `/api/file/download/${pub_fileDetails.thumbnailFileToken || pub_fileDetails.fileToken}`,
					fileData: pub_fileDetails.fileData || {
						fileToken: pub_fileDetails.fileToken,
						fileType: pub_fileDetails.fileType,
						fileName: pub_fileDetails.fileName,
					}, // 放在这里利于传参
					renderItem: this.renderFilePreviewer.bind(this), // 设置显示图标、视频、图片
				}

				if (
					!pub_fileDetails.thumbnailFileToken &&
					pub_fileDetails.fileType !== 'image/jpeg'
				) {
					// 如果没有缩略图且没有图片显示则下方缩略图片显示FilePreviewer
					imgData = {
						...imgData,
						renderThumbInner: this.renderThumbFilePreviewer.bind(this),
					}
				}

				// 如果是音频，显示小图标
				if (pub_fileDetails.fileType?.includes('audio')) {
					imgData.renderThumbInner = this.renderThumbFilePreviewer.bind()
				}

				images.push(imgData)
			}
			this.setState({ images })
		})
	}

	//设置人脸信息
	setPersonList = () => {
		this.setState({ recognitionUserIdList: [] }, () => {
			const { pub_fileDetails } = this.props
			let recognitionUserIdList = []
			pub_fileDetails.personList?.forEach((person, index) => {
				recognitionUserIdList.push(person.recognitionUserId)
			})
			this.setState({ recognitionUserIdList })
		})
	}

	// 设置表单的值
	setFormData = values => {
		const { pub_fileDetails } = this.props // 拿到redux上的详情
		// 设置表单的值
		let formData = pub_fileDetails
		const momentTime = [75, 18, 86, 20] // 存是时间的元数据
		pub_fileDetails.metadataStructTreeBoList?.forEach(current => {
			if (momentTime.includes(current.id)) {
				// 如果存在这样的数据的话
				formData[`${current.id}`] = moment(current.metadataValue) //转换成momnet格式
			} else {
				formData[`${current.id}`] = current.metadataValue
			}
		})
		if (values) formData.keyWord = values.keyWord
		if (pub_fileDetails.personList) {
			// 过滤值为null的人名
			let filterNameArray = pub_fileDetails.personList
				?.filter(value => {
					return value.name != null
				})
				.map(value => value.name)

			if (pub_fileDetails.people) {
				//人脸识别出来额外的名字
				let facePeopleName = filterNameArray
					.filter(item => !pub_fileDetails.people.includes(item))
					.toString()
				formData['74'] = pub_fileDetails.people += facePeopleName
					? `,${facePeopleName}`
					: ''
			} else {
				formData['74'] = filterNameArray.toString()
			}
		}

		this.formRef?.current.setFieldsValue(formData)
	}

	// 获取到文件编辑器的ref
	getRef = ref => {
		this.formRef = ref
	}

	getImageRef = ref => {
		this.imageRef = ref
	}

	//获取不常用数据的ref，并赋值，每次显示隐藏都是组件挂载销毁，所以要重复获取form
	getInfrequentFormRef = ref => {
		this.infrequentFormRef = ref
		this.setInfrequentFormData()
	}

	//设置不常用表单值
	setInfrequentFormData() {
		if (this.infrequentFormRef?.current) {
			this.infrequentFormRef.current.resetFields()
			const { pub_fileDetails } = this.props // 拿到redux上的详情
			// 设置表单的值
			let formData = pub_fileDetails
			pub_fileDetails.metadataStructTreeBoList?.forEach(current => {
				formData[`${current.id}`] = current.metadataValue
			})
			this.infrequentFormRef.current.setFieldsValue(formData)
		}
	}

	// 修改文件，发请求，拿数据
	handleUpload = file => {
		const formData = new FormData() // 构造一个formData实例对象，用于存储formData数据
		formData.append('multipartFile', file) // 将后台指定的字段名作为属性，文件数据作为属性值

		this.setState({
			// 设置为正在上传的状态
			uploading: true,
		})
		this.props.uploadFile(formData).then(data => {
			this.setState({
				images: [],
			})
			let images = []
			let imgData = {
				original: `/api/file/download/${data.fileToken}`,
				thumbnail: `/api/file/download/${data.thumbnailFileToken || data.fileToken}`,
				fileData: data.fileData || {
					fileToken: data.fileToken,
					fileType: data.contentType,
				}, // 放在这里利于传参
				renderItem: this.renderFilePreviewer.bind(this), // 设置显示图标、视频、图片
			}

			if (!data.thumbnailFileToken && data.thumbnailContentType !== 'image/jpeg') {
				// 如果没有缩略图且没有图片显示则下方缩略图片显示FilePreviewer
				imgData = {
					...imgData,
					renderThumbInner: this.renderThumbFilePreviewer.bind(this),
				}
			}

			images.push(imgData)
			this.setState(
				{
					images,
					ids: {
						fileId: data.fileId,
						thumbnailFileId: data.thumbnailFileId,
						zippedImageFileId: data.zippedImageFileId,
					},
					faces: ['beforeFetch'], // 转圈圈
					doSliderCallback: false,
				},
				() => {
					// 解决更换图片之后不显示，其实是轮播图库的原因，图片被移到左边了
					this.imageRef.current.slideToIndex(0)
				}
			)
			// 上传后识别人脸
			this.getImageFace(data.fileId, true)
		})
	}

	// 处理本地文件上传，转化为数据
	handleFileChange = info => {
		//处理文件change，保证用户选择的文件只有一个
		if (info.fileList.length === 0) {
			// 取最后一个文件（即只取一个文件）
			this.setState({ fileList: [] })
		} else {
			this.setState({ fileList: [info.fileList[info.fileList.length - 1]] })
		}
	}

	/**
	 * 获取案卷里面的文件列表
	 */
	getArchiveList = archiveData => {
		return this.props.getArchiveList(archiveData).then(archiveList => {
			archiveList.forEach(archiveData => {
				const { fileToken, fileType } = archiveData
				// 设置预览图
				archiveData['preview'] = {
					type: 1,
					fileData: {
						fileToken,
						fileType,
					},
				}
				// 每个来个key
				archiveData['key'] = archiveData.id
			})
			this.props.upgradePubArchive('dossierDetails', {
				filesData: [],
				archiveData: this.props.pubArchive.dossierDetails.new.archiveData,
			})
			// 重新载入数据
			this.props.upgradePubArchive('dossierDetails', {
				filesData: archiveList || [],
				archiveData: this.props.pubArchive.dossierDetails.new.archiveData,
			})
			return archiveList
		})
	}

	/**
	 * 将文件详情数据送到redux，并执行后续操作，这些操作都依赖最新的pub_fileDetails，所以要按顺序来
	 * @param {*} data
	 */
	sendDataToRedux = (data, values, disabled) => {
		this.props.addPubFileData(data) // 文件详情传到redux
		// 刷新
		this.setState({ ids: {} })
		// 清空表单
		this.formRef.current.resetFields()
		this.setImages()
		this.setFormData(values)
		this.setInfrequentFormData()
		this.setState({ disabled: !disabled })
	}

	/**
	 * 表单提交函数
	 * @param {*} values 提交的表单数据
	 */
	onSubmit = _.throttle(async (values, next = false) => {
		const { ids, disabled } = this.state
		const { updateRecord, getDetail, pub_fileDetails, replaceArchiveFaces } =
			this.props

		// 如果更换文件则添加文件id
		if (ids?.fileId) {
			values.fileId = ids.fileId
			values.thumbnailFileId = ids?.thumbnailFileId
			values.zippedFileId = ids?.zippedImageFileId
		}

		// 档案id
		values['id'] = pub_fileDetails.id

		if (this.isExist(this.state.recognitionUserIdList)) {
			// replaceArchiveFaces(this.state.recognitionUserIdList, pub_fileDetails.id)
		}

		//转参数名
		values.categoryCode = values.categoryCodeId
		delete values.categoryCodeId

		// 更新信息
		updateRecord(values).then(() => {
			// 如果没有next参数则不重新渲染
			if (!next) {
				getDetail(pub_fileDetails).then(data => {
					// 请求详情数据
					data['route'] = pub_fileDetails.route
					//如果没有fatherDossierId这个属性，就是从文件进入的文件详情页
					if (!pub_fileDetails.fatherDossierId) {
						this.sendDataToRedux(data, values, disabled)
					}

					//如果有，就是从案卷进来的文件详情页  且没更换过文件
					if (
						pub_fileDetails.fatherDossierId &&
						typeof ids?.fileId === undefined
					) {
						data['archiveList'] = pub_fileDetails?.archiveList
						data['startIndex'] = pub_fileDetails?.startIndex
						this.sendDataToRedux(data, values, disabled)
					}

					//如果有，就是从案卷进来的文件详情页  且更换过文件
					if (pub_fileDetails.fatherDossierId && ids?.fileId) {
						this.getArchiveList({
							id: pub_fileDetails?.fatherDossierId,
						}).then(archiveList => {
							data['archiveList'] = archiveList
							data['startIndex'] = pub_fileDetails?.startIndex
							this.sendDataToRedux(data, values, disabled)
						})
					}
				})
			}

			// url组卷的修改后更新dossier
			if (pub_fileDetails.route === '/Manager/newConstruction/UrlToArchives') {
				this.getArchiveList(this.props.pubArchive.dossierDetails.old?.archiveData)
			}
		})
		this.setState({ disabled: !disabled, doSliderCallback: true })
	}, 2000)

	// 识别人脸识别数据
	getImageFace = async (fileId, allowEdit = false) => {
		const { pub_fileDetails, faceRecognition } = this.props // 拿到redux上的详情
		const fileType = pub_fileDetails.fileType?.split('/')[0]
		if (fileType !== 'image') {
			if (!allowEdit) {
				this.setState({
					faces: [],
				})
				return
			}
		}
		try {
			const faceData = (await faceRecognition(fileId)).data

			const names = faceData
				.map(item => item.name)
				.filter(item => item !== null)
				.join(',')
			if (this.isExist(faceData[0])) {
				this.faceIds = []
				faceData.forEach(item => {
					this.faceIds.push(item.uid)
				})
			}

			this.setState({
				faces: faceData,
				recognitionUserIdList: this.faceIds,
			})

			if (!pub_fileDetails['74'] || allowEdit) {
				this.formRef.current.setFields([
					{
						name: 74,
						value: names,
					},
				])
			}
		} catch (err) { }
	}

	// 表单提交函数
	setData = values => {
		const fileMetadata = []
		const data = {}
		// 遍历要格式化的数据
		for (let i in values) {
			// i 为数字，则证明是元数据内容
			if (!isNaN(Number(i))) {
				if (values[i]) {
					fileMetadata.push({
						metadataId: Number(i),
						metadataValue: [75, 18, 20].includes(Number(i))
							? moment(values[i])
								.format('YYYY-MM-DDTHH:mm:ss')
								.replace(/\.(.*)/, '')
							: values[i],
					})
				}
			}
			// 不是元数据的内容就直接放进请求数组里
			else {
				data[i] = values[i]
			}
		}
		// 返回格式化完成的数据
		data.metadata = fileMetadata
		return data
	}

	// 更新附件
	changeAttachments = (attachmentList, documentId, callback) => {
		const { updateAttachment, pub_fileDetails } = this.props
		const archive = {
			id: pub_fileDetails.id,
			type: pub_fileDetails.type,
			archiveType: pub_fileDetails.archiveType,
		}
		updateAttachment({
			fileIds: attachmentList,
			type: 1, // 1 为档案更新附件的 type
			documentId,
		})
			.then(() => {
				message.destroy()
				message.success('保存成功')
				this.changeItem(archive)
			})
			.catch(() => {
				message.destroy()
				message.error('保存失败')
			})
			.finally(() => {
				callback && callback()
			})
	}

	// 改变页面中的项时发送数据到redux
	changeItem = archive => {
		const { getDetail, addPubFileData, getArchiveList, inititalPubArchive } =
			this.props
		getDetail(archive).then(data => {
			// 请求详情数据
			if (archive.type) {
				addPubFileData(data) // 文件详情传到redux
			} else {
				getArchiveList(archive).then(archiveList => {
					archiveList.forEach(archiveData => {
						const { fileToken, fileType, fileName } = archiveData
						archiveData['preview'] = {
							type: 1,
							fileData: {
								fileToken,
								fileType,
								fileName,
							},
						}
						archiveData['key'] = archiveData.id
					})
					inititalPubArchive('dossierDetails', {
						filesData: archiveList || [],
						archiveData: this.setFormRecord(data),
					})
				})
			}
		})
	}

	setFormRecord = pub_dossierDetails => {
		const {
			recordTime,
			recordUser,
			archiveTime,
			archiveUser,
			storageTime,
			storageUser,
		} = pub_dossierDetails
		// 著录人和时间
		pub_dossierDetails.recordUserAndTime = { recordUser, recordTime }
		// 归档人和时间
		pub_dossierDetails.archiveUserAndTime = { archiveUser, archiveTime }
		// 入库人和时间
		pub_dossierDetails.storageUserAndTime = { storageUser, storageTime }
		return pub_dossierDetails
	}

	render() {
		const { disabled, images, fileList, faces, ids, faceIdList } = this.state
		const { pub_fileDetails, height = '75vh' } = this.props

		const defaultFileList = pub_fileDetails.attachmentList?.map((item, index) => ({
			uid: item.fileId,
			name: item.fileName,
			status: 'done',
			url: `/api/file/download/${item.fileToken}`,
		}))
		const currentId = pub_fileDetails?.id
		const buttons = []
		// 是否添加编辑按钮
		if (pub_fileDetails.status === 0 || pub_fileDetails.status === 3) {
			if (
				pub_fileDetails.route === '/newConstruction/newArchives' || // 新建档案
				pub_fileDetails.route === '/workarea/commonComponents/approvalDetails' || // 借阅审批
				pub_fileDetails.route === '/workarea/commonComponents/fileFixApply' // 修正申请
			) {
				statusEnum[pub_fileDetails.status] = '案卷'
			} else {
				if (disabled) {
					buttons.push({
						text: <>&#xeabd; 编辑</>,
						title: '编辑',
						style: { marginRight: '10px' },
						className: 'iconfont-buttons',
						type: 'primary',
						onClick: () => {
							// * 提了新需求要求不立马识别
							//// 编辑后识别人脸
							////this.getImageFace(pub_fileDetails.fileId)
							// 这个操作是为了刷新 textarea 的高度
							const temp = this.formRef.current.getFieldsValue()
							this.formRef.current.resetFields()
							this.formRef.current.setFieldsValue(temp)
							this.setInfrequentFormData()
							this.setState({ disabled: !disabled })
							const previwButton = document.querySelectorAll(
								'.file-previewer-rotate'
							)
							previwButton?.forEach(button => {
								button.style.setProperty('display', 'block', 'important')
							})
						},
					})
				} else {
					buttons.push({
						text: <>&#xe619; 取消</>,
						title: '取消',
						style: { marginRight: '10px' },
						className: 'iconfont-buttons',
						onClick: () => {
							// 刷新
							this.setState({ ids: {}, doSliderCallback: true })
							this.formRef.current.resetFields()
							this.setImages()
							this.setFormData()
							this.setInfrequentFormData()
							this.setState({ disabled: !disabled })
							const previwButton = document.querySelectorAll(
								'.file-previewer-rotate'
							)
							previwButton?.forEach(button => {
								button.style.setProperty('display', 'none', 'important')
							})
						},
					})
				}
			}
		}

		// 编辑或者非编辑状态的按钮添加
		if (!disabled) {
			buttons.push({
				text: '保存',
				type: 'primary',
				htmlType: 'submit',
				loading: this.state.loading,
			})
			buttons.push({
				isAssembly: true,
				text: <ButtonList isEdit={true} />,
			})
		} else {
			if (pub_fileDetails.route !== '/Manager/newConstruction/UrlToArchives') {
				buttons.push({
					isAssembly: true,
					text: <ButtonList />,
				})
			}
		}

		// 上传文件的相关操作
		const props = {
			// 这里的props是设置上传文件的按钮
			onRemove: file => {
				// 移除文件
				this.setState(state => {
					const index = state.fileList.indexOf(file)
					const newFileList = state.fileList.slice()
					newFileList.splice(index, 1)
					return {
						fileList: newFileList,
					}
				})
			},
			beforeUpload: file => {
				// 设置新文件
				this.setState(state => ({
					fileList: [...state.fileList, file],
				}))
				// 传参发请求
				this.handleUpload(file)
				return false
			},
			fileList,
		}
		const faceData = faces || ['beforeFetch']
		return (
			<div>
				<PubFileDetails
					style={{ marginTop: '10px' }}
					uniqueName='fileDetails'
					items={images}
					height={height}
					buttons={buttons}
					disabled={disabled}
					isFormat={true}
					defaultFileList={defaultFileList}
					archiveId={pub_fileDetails.id}
					fileType={pub_fileDetails.fileType}
					personList={pub_fileDetails.personList}
					onSlide={currentIndex => {
						if (!this.state.doSliderCallback) return
						// 如果是编辑状态就直接保存文件并隐藏旋转按钮
						if (!disabled) {
							let fieldsValue = Object.assign(
								this.formRef?.current.getFieldsValue(),
								this.infrequentFormRef?.current.getFieldsValue()
							)
							this.onSubmit(this.setData(fieldsValue), true)
							const previwButton = document.querySelectorAll(
								'.file-previewer-rotate'
							)
							previwButton?.forEach(button => {
								button.style.setProperty('display', 'none', 'important')
							})
						}
						this.props
							.getDetail(pub_fileDetails.archiveList[currentIndex])
							.then(data => {
								// 请求详情数据
								data['archiveList'] = pub_fileDetails.archiveList
								data['route'] = pub_fileDetails.route // 由此可以知道从哪打开详情，并判断是否添加按钮
								data['startIndex'] = pub_fileDetails.startIndex
								this.props.addPubFileData(data) // 文件详情传到redux
								this.formRef.current?.resetFields() // 清空数据
								this.setFormData()
								this.setInfrequentFormData()
							})
					}}
					changeAttachments={(ids, callback) => {
						this.changeAttachments(ids, currentId, callback)
					}}
					startIndex={pub_fileDetails.startIndex || 0}
					onFinish={values => {
						let peopleArray
						values.metadata.map(item => {
							if (item.metadataId === 74) {
								peopleArray = item.metadataValue?.split(',')
							}
							return ''
						})

						if (peopleArray) {
							pub_fileDetails.personList?.map(value => {
								if (
									peopleArray.findIndex(res => res === value.name) !==
									-1
								) {
									peopleArray.splice(
										peopleArray.findIndex(res => res === value.name),
										1
									)
								}
								return ''
							})

							values.metadata.map(item => {
								if (item.metadataId === 74) {
									item.metadataValue = peopleArray.toString()
								}
								return ''
							})
						}

						this.onSubmit(values)
					}}
					getRef={this.getRef}
					getImageRef={this.getImageRef}
					getInfrequentFormRef={this.getInfrequentFormRef}
					// 传人脸数据
					isFaceDetails={disabled ? faceIdList : faceData}
					resetImageFace={() =>
						this.getImageFace(ids.fileId || pub_fileDetails.fileId)
					}
					// 判断是否显示文书
					isPaperDocument={pub_fileDetails.archiveType === 2}
					//上传文件的相关操作
					uploadFile={props}
					handleFileChange={this.handleFileChange}
					addToWorkbenchData={[{ contentId: pub_fileDetails.id, type: 1 }]}
				/>
			</div>
		)
	}
}

export default FileDetails
