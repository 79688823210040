import { ePhotoAlbum } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'

export const getUserByIdThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: ePhotoAlbum.getUserById,
			type: 'get',
			data: {
				id,
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})
