const stringSort = (a, b) => {
	if (a.timeMeta < b.timeMeta) {
		return -1
	}
	if (a.timeMeta > b.timeMeta) {
		return 1
	}
	return 0
}

export const workStationColumns = [
	{ topic: '题名', width: 200 },
	{ departmentName: '部门', width: 80 },
	{ categoryName: '分类', width: 80 },
	{ retentionPeriod: '保管期限', width: 60 },
	{ confidentialLevel: '密级', width: 60 },
	{ nameList: '人物', width: 100 },
	{ recordUser: '著录人', width: 70 },
	{ keyWord: '关键词', width: 80 },
	{
		timeMeta: '时间',
		width: 80,
		sorter: {
			compare: stringSort,
			multiple: 1,
		},
	},
	{ recordTime: '著录时间', width: 90 },
	{ status: '状态', statusType: 'recordStatus', width: 50 },
]

export const expiredFilesColumns = [
	{ topic: '题名', width: 200 },
	{ departmentName: '部门', width: 80 },
	{ categoryCode: '分类', width: 80 },
	{ retentionPeriod: '保管期限', width: 60 },
	{ confidentialLevel: '密级', width: 60 },
	{ nameList: '人物', width: 100 },
	{ recordUser: '著录人', width: 70 },
	{ keyWord: '关键词', width: 80 },
	{
		timeMeta: '时间',
		width: 80,
		sorter: {
			compare: stringSort,
			multiple: 1,
		},
	},
	{ recordTime: '著录时间', width: 90 },
	{ deadLine: '过期时间', width: 100 },
]

export const archivedColumns = [
	{ topic: '题名', width: 200 },
	{ code: '档号' },
	{ departmentName: '部门', width: 80 },
	{ categoryCode: '分类', width: 80 },
	{ retentionPeriod: '保管期限', width: 60 },
	{ confidentialLevel: '密级', width: 60 },
	{ nameList: '人物', width: 100 },
	{ recordUser: '著录人', width: 70 },
	{ keyWord: '关键词', width: 80 },
	{
		timeMeta: '时间',
		width: 80,
		sorter: {
			compare: stringSort,
			multiple: 1,
		},
	},
	{ recordTime: '著录时间', width: 90 },
]

export const destroyFilesColumns = [
	{ topic: '题名', width: 200 },
	{ departmentName: '部门', width: 80 },
	{ categoryCode: '分类', width: 80 },
	{ retentionPeriod: '保管期限', width: 60 },
	{ confidentialLevel: '密级', width: 60 },
	{ nameList: '人物', width: 100 },
	{ recordUser: '著录人', width: 70 },
	{ keyWord: '关键词', width: 80 },
	{
		timeMeta: '时间',
		width: 80,
		sorter: {
			compare: stringSort,
			multiple: 1,
		},
	},
	{ recordTime: '著录时间', width: 90 },
]

export const recycleBinColumns = [
	{ topic: '题名', width: 200 },
	{ departmentName: '部门', width: 80 },
	{ categoryCode: '分类', width: 80 },
	{ retentionPeriod: '保管期限', width: 60 },
	{ confidentialLevel: '密级', width: 60 },
	{ nameList: '人物', width: 100 },
	{ recordUser: '著录人', width: 70 },
	{ keyWord: '关键词', width: 80 },
	{
		timeMeta: '时间',
		width: 80,
		sorter: {
			compare: stringSort,
			multiple: 1,
		},
	},
	{ recordTime: '著录时间', width: 90 },
]

export const prearchiveColumns = [
	{
		topic: '题名',
		width: 200,
		render: (text, record) => {
			const { pdfMissed } = record

			return <span style={{ color: `${pdfMissed ? 'red' : 'black'}` }}>{text}</span>
		},
	},
	{ departmentName: '部门', width: 80 },
	{ categoryCode: '分类', width: 80 },
	{ retentionPeriod: '保管期限', width: 60 },
	{ confidentialLevel: '密级', width: 60 },
	{ nameList: '人物', width: 100 },
	{ recordUser: '著录人', width: 70 },
	{ keyWord: '关键词', width: 80 },
	{
		timeMeta: '时间',
		width: 80,
		sorter: {
			compare: stringSort,
			multiple: 1,
		},
	},
	{ recordTime: '著录时间', width: 90 },
]

export const applicationBorrowColumns = [
	{ applyCode: '申请编号' },
	{ applyReason: '申请原因', width: 100 },
	{ applyContent: '申请内容', width: 100 },
	{ departmentName: '部门' },
	{ applyTime: '申请时间' },
	{ deadline: '期限' },
	{ status: '处理状态', statusType: 'borrowStatus' },
]

// 修正申请表单项
export const applicationCorrectColumns = [
	{ applyCode: '申请编号' },
	{ fileCode: '档号' },
	{ categoryName: '类型' },
	{ topic: '题名' },
	{ applyReason: '申请原因' },
	{ status: '处理状态', statusType: 'correctStatus' },
	{ createTime: '申请时间', width: 150 },
	{ applyUser: '申请人' },
]

export const newArchiveColumns = [
	{ type: '类型' },
	{ status: '状态' },
	{ topic: '题名' },
	{ categoryCode: '分类' },
	{ retentionPeriod: '保管期限' },
	{ departmentName: '部门' },
	{ confidentialLevel: '密级' },
	{ recordUser: '著录人', width: 70 },
	{ keyWord: '关键词', width: 80 },
	{ recordTime: '著录时间', width: 90 },
]

export const appraisalColumns = [
	// 普通用户
	{ applyCode: '申请编号' },
	{ applyReason: '申请原因' },
	{ createTime: '申请时间' },
	{ status: '处理状态', statusType: 'applyStatus' },
]

export const appraisalSuperColumns = [
	// 超级用户
	{ applyCode: '申请编号' },
	{ applyReason: '申请原因' },
	{ applyPerson: '申请人' },
	{ createTime: '申请时间' },
	{ verifyPerson: '鉴定人' },
	{ status: '处理状态', statusType: 'applyStatus' },
]

export const systemAllColumns = [
	{
		fondsIdentifier: '全宗号',
		sorter: {
			compare: (a, b) => {
				if (a.fondsIdentifier < b.fondsIdentifier) {
					return -1
				}
				if (a.fondsIdentifier > b.fondsIdentifier) {
					return 1
				}
				return 0
			},
			multiple: 1,
		},
	},
	{ fondsName: '全宗名称' },
	{ remarks: '全宗描述' },
]

export const HealthColumns = [
	{ name: '姓名' },
	{
		isHealthtag: '健康状态',
		render: (text, record) => (
			<span style={{ color: record.isHealth ? '#7ee47e' : '#fc5d5d' }}>{text}</span>
		),
	},
]

export const SensitiveWordsColumns = [{ keyword: '敏感词' }, { group: '组别' }]
export const DataTableManagementColumns = [
	{ columnName: '列名' },
	{ dataType: '数据类型' },
]
export const MetaDataManagementColumns = [
	{ metadataName: '元数据' },
	{ metadataType: '元数据类型' },
]
export const ParamManagementColumns = [{ paramName: '参数名' }, { type: '参数类型' }]

export const ExceptionLogManagementColumns = [
	{ name: '错误类型' },
	{ message: '错误提示信息' },
	{ logTime: '错误发生时间' },
]

export const userManagerColumns = [
	{
		workId: '用户名',
		sorter: {
			compare: (a, b) => {
				if (a.workId < b.workId) {
					return -1
				}
				if (a.workId > b.workId) {
					return 1
				}
				return 0
			},
			multiple: 1,
		},
	},
	{ name: '姓名' },
	{ departmentName: '部门' },
	{ phone: '手机号' },
	{ email: '邮箱' },
	{ validTime: '有效时间' },
	{ status: '状态', statusType: 'userStatus' },
]

export const roleManagerColumns = [
	{
		name: '角色名',
		sorter: {
			compare: (a, b) => {
				if (a.name < b.name) {
					return -1
				}
				if (a.name > b.name) {
					return 1
				}
				return 0
			},
			multiple: 1,
		},
	},
	{ description: '描述' },
]

export const approvalBorrowColumns = [
	{ applyCode: '申请编号' },
	{ applyReason: '申请原因' },
	{ applyContent: '申请内容' },
	{ departmentName: '部门' },
	{ applyTime: '申请时间' },
	{ deadline: '利用期限' },
	{ status: '处理状态', statusType: 'approvalStatus' },
]

export const EPhotoAlbumTypeColumns = [{ topic: '主题名称' }, { parentTopic: '父节点' }]

export const ArchiveTypeColumns = [
	{
		categoryCode: '分类代码',
		sorter: {
			compare: (a, b) => {
				if (a.categoryCode < b.categoryCode) {
					return -1
				}
				if (a.categoryCode > b.categoryCode) {
					return 1
				}
				return 0
			},
			multiple: 1,
		},
	},
	{ typeName: '分类名称' },
	{ parentTypeName: '父节点' },
	//{ needVerify: '审核' },
	{ allowedRole: '主管角色' },
	{ role: '审核角色' },
	{ retentionPeriod: '保管期限' },
]

export const packArchiveColumns = [
	{ type: '类型' },
	{ status: '状态' },
	{ topic: '题名' },
	{ categoryCode: '分类' },
	{ retentionPeriod: '保管期限' },
	{ departmentName: '部门' },
	{ confidentialLevel: '密级' },
	{ recordUser: '著录人', width: 70 },
	{ keyWord: '关键词', width: 80 },
	{ recordTime: '著录时间', width: 90 },
]
export const CompositeSearchColumns = [
	{ topic: '题名' },
	{ departmentName: '所属部门名' },
	{ retentionPeriod: '保管期限' },
	{ confidentialLevel: '密级' },
	{ recordUser: '著录人', width: 70 },
	{ keyWord: '关键词', width: 80 },
	{ recordTime: '著录时间', width: 90 },
	{ type: '类型' },
	{ categoryCode: '分类' },
]

export const BorrowResultColumns = [
	{ type: '类型' },
	{ categoryCode: '类别名' },
	{ topic: '题名' },
	{ departmentName: '部门' },
	{ recordUser: '著录人' },
	{ deadLine: '截止时间' },
]

export const DepartmentManagementColumns = [
	{
		departmentNumber: '部门编号',
		sorter: {
			compare: (a, b) => {
				if (a.departmentNumber < b.departmentNumber) {
					return -1
				}
				if (a.departmentNumber > b.departmentNumber) {
					return 1
				}
				return 0
			},
			multiple: 1,
		},
	},
	{ departmentName: '部门名称' },
	{ parentName: '业务主管部门' },
	{ description: '部门描述' },
	{ fondsName: '默认全宗' },
]

export const appraisalDetailColumns = [
	{ code: '档案号' },
	{ topic: '题名' },
	{ categoryCode: '类别号' },
	{ preRetentionPeriod: '前有效期' },
	{ result: '鉴定结果' },
	{ explanation: '说明' },
]

export const collectedFileColumns = [
	{ topic: '名称' },
	{ theme: '主题', width: 150 },
	{ people: '人物' },
	{ time: '时间' },
	{ place: '地点' },
	{ categoryId: '类别号' },
	{ departmentId: '部门' },
]

export const themeManageColumns = [
	{ topic: '主题题名', width: 160 },
	{ count: '资料数量', width: 60 },
	{ createdTime: '发布时间', width: 80 },
	{ department: '部门' },
	{ collectedObject: '征集对象' },
	{ begin: '征集开始时间', width: 80 },
	{ end: '征集结束时间', width: 80 },
	{ status: '状态' },
]

export const myFileColumns = [
	{ topic: '题名' },
	{ departmentId: '部门' },
	// 从元数据中获取信息，属性名都为metadataList
	{ metadataList: '人物' },
	{ metadataList: '时间' },
	{ metadataList: '事件' },
]

export const claimApprovalColumns = [
	{ applyUser: '申请人' },
	{ createTime: '申请时间' },
	{ applyReason: '申请原因' },
	{ status: '处理状态', statusType: 'approvalStatus' },
]

export const ePhotoAlbumColumns = [
	{ typeName: '类型', width: 70 },
	{ topic: '题名', width: 150 },
	{ keyWord: '关键字', width: 80 },
	{ brief: '简介', width: 80 },
	{ submitTime: '时间', width: 90 },
	{ departmentId: '部门', width: 80 },
	{ status: '状态', width: 50 },
]

export const urlToArchiveManagementColumns = [
	{ jobName: '频道名称' },
	{ source: '类型' },
	{ departmentId: '部门' },
	{ categoryCode: '类别' },
	{ userName: '责任人' },
	// { url: '目标网址', width: 200 },
	{ startTime: '开始时间' },
	{ dateFromTime: '始于时间' },
	{
		dayTotal: '本日',
		sorter: {
			compare: (a, b) => {
				if (a.dayTotal < b.dayTotal) {
					return -1
				}
				if (a.dayTotal > b.dayTotal) {
					return 1
				}
				return 0
			},
			multiple: 1,
		},
	},
	{
		monthTotal: '本月',
		sorter: {
			compare: (a, b) => {
				if (a.monthTotal < b.monthTotal) {
					return -1
				}
				if (a.monthTotal > b.monthTotal) {
					return 1
				}
				return 0
			},
			multiple: 1,
		},
	},
	{
		yearTotal: '本年',
		sorter: {
			compare: (a, b) => {
				if (a.yearTotal < b.yearTotal) {
					return -1
				}
				if (a.yearTotal > b.yearTotal) {
					return 1
				}
				return 0
			},
			multiple: 1,
		},
	},
	{
		total: '总量',
		sorter: {
			compare: (a, b) => {
				if (a.total < b.total) {
					return -1
				}
				if (a.total > b.total) {
					return 1
				}
				return 0
			},
			multiple: 1,
		},
	},
	{ statusVo: '状态' },
]

// 审核要求
export const underReviewColumns = [
	{ topic: '题名', width: 200 },
	{ departmentName: '部门', width: 80 },
	{
		timeMeta: '时间',
		width: 80,
		sorter: {
			compare: stringSort,
			multiple: 1,
		},
	},
	{ request: '著录要求', width: 90 },
]
