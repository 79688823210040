import React, { Component } from 'react'
import { message } from 'antd'
import { PubModalForm } from '@/Public/PubForm'
import { handleApproval } from './ActionCreators'

const formLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 10 },
}
// 表单项的单独配置，优先级高于表单项的统一配置
const formItemLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}

// PubForm 表单项配置数组
let formItems = [
	[
		{
			type: 'Input',
			name: 'applyUser',
			label: '申请人',
			layout: formItemLayout,
		},
	],
	[
		{
			type: 'TextArea',
			name: 'applyReason',
			label: '认领原因:',
			layout: formItemLayout,
			rules: [{ required: true }],
			rows: 5,
		},
	],
]

export default class modal extends Component {
	state = {
		visible: false,
		title: '认领审批',
	}

	setButton = () => {
		//配置按钮项
		let buttonList = [
			{
				text: '拒绝',
				buttonProps: {
					type: 'danger',
				},
				onClick: async () => {
					// 点击新增事件
					this.hadleApproval(3)
					this.props.modalRef.current.hide()
				},
			},
		]
		if (this.props.record?.status === 1) {
			buttonList.push({
				text: '同意',
				buttonProps: {
					type: 'primary',
				},
				onClick: async () => {
					// 点击新增事件
					this.hadleApproval(2)
					this.props.modalRef.current.hide()
				},
			})
		}
		return buttonList
	}

	async hadleApproval(status) {
		const id = this.props.record.id
		const data = {
			id,
			status,
		}
		const res = await handleApproval(data)
		if (res.data.success) {
			message.success('操作成功')
			this.props.pub_getAllData()
		}
	}

	render() {
		const { title } = this.state
		const { modalRef } = this.props
		return (
			<PubModalForm
				ref={modalRef}
				title={title}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				buttonList={this.setButton()} // Modal 底部按钮配置列表,
				width={500} // 默认值900
				formProps={{
					// 表单的配置项
					name: 'claimModal',
					formItems,
					layout: formLayout,
				}}
				formDisabled
			/>
		)
	}
}
