import React, { Component } from 'react'
import { message, Modal, Badge, Spin } from 'antd'
import LinkModal from './linkModal'
import ImageGallery from '@/Public/ImageGallery'
import FileEditor from '@/Public/FileEditor'
import ArchiveLog from '@/Public/ArchiveLog'
import FaceDetails from '@/Public/FaceDetails'
import Attachment from '@/Public/Attachment'
import InfrequentData from '@/Public/InfrequentData'
import CollectedFileEditor from '@/Public/CollectedFileEditor'
import PaperDocumentEditor from '@/Public/PaperDocumentEditor'
import './index.less'
import _, { forEach } from 'lodash'
import { Space, Button, Upload } from 'antd'
import {
	EyeOutlined,
	FieldTimeOutlined,
	DatabaseOutlined,
	LinkOutlined,
	LeftOutlined,
	RightOutlined,
	MailOutlined,
} from '@ant-design/icons'
import { history } from '@/index'
import returnSvg from '@/Static/svg/return.svg'
import ButtonList from '@/Public/ButtonList'
import { connect } from 'react-redux'
import { Base64 } from 'js-base64'
import EmlPreview from './EmlPreview'
const linkModalRef = React.createRef(null)

const mapStateToProps = state => ({
	islinked: state.pubForm.pub_fileDetails.link,
	route: state.pubForm.pub_fileDetails.route,
	attachmentList: state.pubForm.pub_fileDetails?.attachmentList,
})

@connect(mapStateToProps)
class FileDetails extends Component {
	state = {
		isShowPreview: true, //是否显示查看
		isShowArchiveLog: false, // 是否显示日志
		isShowFaceDetails: false, // 是否显示人脸
		isShowAttachment: false, // 是否显示附件
		isShowInfrequentData: false, // 是否显示不常用数据
		isAttachmentUploading: false, // 是否有附件在上传
		stateIndex: null,
		lastValues: null,
	}

	closeOtherAndOpenthis = str => {
		const arr = [
			'isShowPreview',
			'isShowArchiveLog',
			'isShowFaceDetails',
			'isShowAttachment',
			'isShowInfrequentData',
		]
		const obj = {}
		arr.forEach(item => {
			obj[item] = item === str ? true : false
		})
		this.setState({
			...this.state,
			...obj,
		})
	}

	// 切换中心页时调用
	onSwitchCenterPage = fn => {
		if (this.state.isAttachmentUploading) {
			Modal.confirm({
				title: <p className='file-details-confirm-title'>是否切换页面</p>,
				content: (
					<p className='file-details-confirm-content'>
						附件正在上传，切换页面会取消附件上传
					</p>
				),
				onOk: fn,
			})
		} else {
			fn()
		}
	}

	//修改state，切换中心页
	openView = () => {
		this.setState({
			isShowPreview: true,
			isShowInfrequentData: false,
			isShowAttachment: false,
			isShowFaceDetails: false,
			isShowArchiveLog: false,
		})
	}

	openInfrequentData = () => {
		this.setState({
			isShowInfrequentData: !this.state.isShowInfrequentData,
			isShowPreview: this.state.isShowInfrequentData,
			isShowAttachment: false,
			isShowFaceDetails: false,
			isShowArchiveLog: false,
		})
	}

	openAttachment = () => {
		this.setState({
			isShowAttachment: !this.state.isShowAttachment,
			isShowPreview: this.state.isShowAttachment,
			isShowFaceDetails: false,
			isShowArchiveLog: false,
			isShowInfrequentData: false,
		})
	}

	openLog = () => {
		this.setState({
			isShowArchiveLog: !this.state.isShowArchiveLog,
			isShowPreview: this.state.isShowArchiveLog,
			isShowFaceDetails: false,
			isShowAttachment: false,
			isShowInfrequentData: false,
		})
	}

	//⬇键切换页面
	arrowDownKeyUp = () => {
		const arr = [
			'isShowPreview',
			'isShowInfrequentData',
			'isShowAttachment',
			'isShowArchiveLog',
		]
		//如果不是文书就不显示不常用数据
		this.props.isPaperDocument || arr.splice(1, 1)
		//如果是资料，再把isShowArchiveLog删除(到这已经删除isShowInfrequentData了)
		this.props.uniqueName === 'collected-file-details' && arr.splice(2, 1)
		const newState = {}
		//遍历数组，让下一项显示
		arr.forEach((item, index) => {
			if (this.state[item]) {
				newState[item] = false
				newState[arr[(index + 1) % arr.length]] = true
				return
			}
		})
		this.setState(newState)
	}

	// 我的档案认领请求
	claimArchive() {
		if (this.props.islinked === 0) {
			linkModalRef.current.show()
		} else {
			message.error('您已申请认领此档案')
		}
	}

	// 传给子组件一个函数获取附件上传状态
	getAttachmentUploading = uploading => {
		this.setState({
			isAttachmentUploading: uploading,
		})
	}

	componentDidMount() {
		//监听键盘，↓换页
		if (this.props.route !== '/mine/mine') {
			window.onkeydown = event => {
				if (event.code === 'ArrowDown') {
					//在修正文件不用这个功能
					//比如当前的路径是http://localhost:3001/collectFiles lastHref就是collectFiles
					const lastHref = window.location.href.split('/').pop()
					if (lastHref === 'fileFixApply') return
					//节流，防止疯狂点击
					_.throttle(() => {
						this.onSwitchCenterPage(this.arrowDownKeyUp)
					}, 1000)()
				}
			}
		}
	}

	componentWillUnmount() {
		window.onkeydown = null
	}

	//判断文件是否带有附件
	isArchivedWithAttachment = () => {
		return typeof this.props.attachmentList !== 'undefined'
	}

	/**
	 * @Desperate
	 * @params 的item的index，用来上下切换轮播图
	 * 通过本地域名和文件地址，编码生成并拼接 生成传给iframe的最终url
	 * @returns 返回传给iframe的url
	 */
	getPreviewUrl = index => {
		const { items } = this.props

		//url是要访问文件的地址
		// 流程：（ kkFileView所在域名  + 参数：解析文件地址url,注意区分服务器） 传给iframe

		//获取当前域名，如果是开发服务器就转发到 对应 测试服，如果不是就使用当前域名
		//获取点击的页数
		const domain = window.location.host
			.split(':')
			.some(item => item === 'localhost' || item === '8080')
			? 'test-hzdag.topviewclub.cn'
			: window.location.host

		const url =
			'https://' + domain + '/api/file/download/' + items[index]?.fileData.fileToken
		//previewURL 是传给iframe的url

		const previewURL =
			'https://preview.topviewclub.cn/onlinePreview?url=' +
			encodeURIComponent(Base64.encode(url))

		return previewURL
	}

	// /**
	//  * @Desperate
	//  * 得到ifram JSX 不写再JSX里面是因为首次渲染的时候items为空，没有获取到url，重新渲染时iframe不会重新加载
	//  * @params 的item的index，用来上下切换轮播图
	//  * @returns 再得到数据的时候返回iframe的JSX否则返回加载的界面
	//  */
	// previewIframRender = index => {
	// 	const { items } = this.props
	// 	//console.log('items:', items)
	// 	if (items.length) {
	// 		const url = this.getPreviewUrl(index)
	// 		return (
	// 			<>
	// 				<div className='preveiw-wrapper'>
	// 					{items.length > 1 && index > 0 && (
	// 						<LeftOutlined
	// 							className='left-outlined'
	// 							onClick={() => {
	// 								index > 0 && this.setState({ stateIndex: index - 1 })
	// 								index > 0 && this.setState({ showMailPreview: false })
	// 							}}></LeftOutlined>
	// 					)}
	// 					{items.length > 1 && index < items.length - 1 && (
	// 						<RightOutlined
	// 							className='right-outlined'
	// 							onClick={() => {
	// 								index < items.length - 1 &&
	// 									this.setState({ stateIndex: index + 1 })
	// 								index < items.length - 1 &&
	// 									this.setState({ showMailPreview: false })
	// 							}}></RightOutlined>
	// 					)}
	// 					{!this.state.showMailPreview && (
	// 						<div className='preveiw-wrapper'>
	// 							<MailOutlined
	// 								style={{
	// 									height: '60vh',
	// 									lineHeight: '74vh',
	// 									width: '100%',
	// 									fontSize: '200px',
	// 									testAlign: 'center',
	// 								}}
	// 								onClick={() => {
	// 									this.setState({ showMailPreview: true })
	// 									//console.log(
	// 										this.state.showMailPreview,
	// 										'showMailPreview'
	// 									)
	// 								}}></MailOutlined>
	// 							<div style={{ textAlign: 'center' }}>
	// 								<p>点击图标进入邮件预览</p>
	// 								<p>复杂格式邮件暂不支持预览</p>
	// 							</div>
	// 						</div>
	// 					)}
	// 					<iframe
	// 						style={{
	// 							visibility: this.state.showMailPreview
	// 								? 'visible'
	// 								: 'hidden',
	// 							height: this.state.showMailPreview ? '100%' : '0',
	// 						}}
	// 						className='preview-iframe'
	// 						width='100%'
	// 						title='previewIframe'
	// 						allowFullScreen
	// 						src={url}></iframe>
	// 				</div>
	// 			</>
	// 		)
	// 	}
	// }
	render() {
		//从各个地方获取props
		const {
			uniqueName,
			height = '75vh',
			showThumbnails,
			getImageRef,
			imageGalleryWidth = 'calc(100% - 350px)',
			fileEditorWidth = '350px',
			getRef,
			getInfrequentFormRef,
			style,
			defaultFormValue = [],
		} = this.props
		const {
			buttons,
			disabled,
			onFinish,
			onValuesChange,
			isVisitor,
			isFormat = false,
			isArchived = true,
			isFaceDetails = false,
			resetImageFace,
			getAttachmentIds,
			onlyShow = false,
			// attachmentList,
		} = this.props
		const {
			items,
			onSlide,
			startIndex = 0,
			archiveId,
			getAttachmentFileList,
			setFillInformationState,
			defaultFileList,
			personList,
			fileType,
			changeAttachments,
			uploadFile,
			handleFileChange,
			isCollectedFile = false,
			isPaperDocument = false,
			isFileFixApply = false,
			isNewConstruction = false,
			addToWorkbenchData = false,
			moreButtons = false, //渲染按钮，传一个函数
			themeTopic,
			returnCallback = undefined, // 返回按钮回调函数
			notChangefile = false, //是否可以替换文件
		} = this.props

		//判断必要props是否都有
		if (!uniqueName) {
			throw Error('FileDetails中，uniqueName是必传参数')
		}

		if (!getRef) {
			throw Error('FileDetails中，getRef是必传参数')
		}

		if (!items) {
			throw Error('FileDetails中，items是必传参数')
		}

		//控制打开的页面以及页数的state
		const {
			isShowPreview,
			isShowArchiveLog,
			isShowFaceDetails,
			stateIndex,
			isShowAttachment,
			isShowInfrequentData,
		} = this.state

		const isMail = this.props.items[0]?.fileData?.fileType === 'message/rfc822'
		const index = stateIndex === null ? startIndex : stateIndex
		//console.log('render', items)
		return (
			<>
				{/* 配置和渲染按钮 */}
				<Space className='btn-container' size={'middle'}>
					{/* 返回按钮 */}
					{!isVisitor && !this.props.renderButtonList && (
						<img
							style={{ width: 35, height: 35, cursor: 'pointer' }}
							src={returnSvg}
							alt={'返回'}
							onClick={() => {
								returnCallback ? returnCallback() : history.goBack()
								window.sessionStorage.removeItem('infrequentData')
							}}
						/>
					)}
					{this.props.renderButtonList && (
						<Button
							type='primary'
							onClick={() => {
								returnCallback ? returnCallback() : history.goBack()
								window.sessionStorage.removeItem('infrequentData')
							}}>
							上一步
						</Button>
					)}
					{/* 更改文件框 */}
					{!disabled &&
						!isFileFixApply &&
						!isNewConstruction &&
						!isVisitor &&
						!notChangefile && (
							<Space className='upload-box' style={{ padding: 0 }}>
								<Upload
									className='fix-upload'
									{...uploadFile}
									onChange={handleFileChange}
									showUploadList={false}>
									<Button
										className='btn-warning switch-button'
										icon={
											<svg
												t='1638241392944'
												className='icon'
												viewBox='0 0 1024 1024'
												version='1.1'
												xmlns='http://www.w3.org/2000/svg'
												p-id='1775'
												width='17'
												height='17'
												style={{
													position: 'relative',
													top: 3,
													marginRight: 10,
												}}>
												<path
													d='M620.544 137.6c103.936 10.432 187.328 72.96 205.12 180.224h-60.16l97.088 144.448 97.152-144.448h-67.008c-17.792-144.448-127.168-238.336-265.344-251.712-19.136-1.536-36.864 14.848-36.864 35.712 1.28 17.92 13.568 34.24 30.016 35.776z m-150.4-73.024H132.416c-19.136 0-34.176 16.384-34.176 37.248v321.728c0 20.864 15.04 37.248 34.176 37.248h337.728c19.136 0 34.176-16.384 34.176-37.248V101.824c0-20.864-15.04-37.248-34.176-37.248z m-32.832 324.736H165.248V136.064h272.128v253.248h-0.064zM404.48 883.84c-116.224-10.432-205.12-87.872-209.216-216h64.256L162.496 523.392l-97.088 144.448h64.256c2.688 165.376 118.912 272.576 268.032 287.488 19.136 1.472 36.928-14.912 36.928-35.776a35.648 35.648 0 0 0-30.144-35.712z m489.6-323.264H556.288c-19.2 0-34.176 16.448-34.176 37.248v323.264c0 20.8 14.976 37.184 34.176 37.184h337.728c19.136 0 34.112-16.384 34.112-37.184V597.824c0.064-20.8-16.32-37.248-34.048-37.248z m-32.896 324.736H589.12V633.536h272.064v251.776z'
													p-id='1776'
													fill='#ffffff'></path>
											</svg>
										}>
										替换
									</Button>
								</Upload>
							</Space>
						)}

					{/* 查看按钮 */}
					{!isVisitor && (
						<Button
							className={
								isShowPreview
									? 'pre-active preview-button'
									: 'preview-button'
							}
							onClick={() => {
								this.onSwitchCenterPage(this.openView)
							}}
							type='text'
							title='查看'>
							<EyeOutlined /> 查看
						</Button>
					)}
					{/* 认领按钮 */}
					{this.props.route === '/mine/mine' && this.props.islinked !== 1 && (
						<>
							<Button
								className={
									this.props.islinked === 0
										? 'pre-active preview-button'
										: 'preview-button'
								}
								onClick={() => {
									this.claimArchive()
								}}
								type='text'
								title='认领'>
								{this.props.islinked === 0 ? '认领' : '已申请认领'}
							</Button>
							<LinkModal modalRef={linkModalRef} archiveId={archiveId} />
						</>
					)}
					{/* 不常用数据 */}
					{isPaperDocument && (
						<Button
							className={
								isShowInfrequentData
									? 'unusual-active unusual-button'
									: 'unusual-button'
							}
							onClick={() => {
								this.openInfrequentData &&
									this.onSwitchCenterPage(this.openInfrequentData)
							}}
							type='text'
							title='不常用数据'>
							<DatabaseOutlined />
							更多
						</Button>
					)}

					{/* 附件按钮 */}
					{!isVisitor && this.props.route !== '/mine/mine' && (
						<Badge
							count={defaultFileList ? defaultFileList.length : 0}
							size={'small'}>
							<Button
								className={
									isShowAttachment
										? 'attachment-active attachment-button'
										: 'attachment-button'
								}
								onClick={() => {
									this.onSwitchCenterPage(this.openAttachment)
								}}
								type='text'
								title='附件'>
								<LinkOutlined className='journal-icons' /> 附件
							</Button>
						</Badge>
					)}

					{/* 日志按钮 */}
					{!isNewConstruction &&
						!isVisitor &&
						!isCollectedFile &&
						this.props.route !== '/mine/mine' && (
							<Button
								className={
									isShowArchiveLog
										? 'log-active log-button'
										: 'log-button'
								}
								onClick={() => {
									this.onSwitchCenterPage(this.openLog)
								}}
								type='text'
								title='日志'>
								<FieldTimeOutlined className='journal-icons' /> 日志
							</Button>
						)}

					{/* 收藏 */}
					{!isFileFixApply &&
						!isNewConstruction &&
						!isVisitor &&
						this.props.route !== '/mine/mine' && (
							<ButtonList
								renderFavorites={true}
								addToWorkbenchData={addToWorkbenchData}
							/>
						)}
					{this.props.renderButtonList && this.props.renderButtonList()}

					{/* 额外的按钮 */}
					{moreButtons && moreButtons()}
				</Space>
				{/* 渲染按钮下面的具体内容 */}
				<div className={`file-details ${uniqueName}`} style={style}>
					{/* 渲染左侧预览模块*/}
					{
						// 如果不是显示其他 比如附件|日志等页面，就显示iframe内嵌预览模块
						!(
							(
								isShowAttachment ||
								isShowArchiveLog ||
								isShowFaceDetails ||
								isShowInfrequentData ||
								isMail
							)
							// || !useGallery
						) && (
							<ImageGallery
								uniqueName={`${uniqueName}-image-gallery`}
								width={
									this.props.route !== '/mine/mine'
										? imageGalleryWidth
										: '100%'
								}
								showThumbnails={showThumbnails}
								height={height}
								items={items}
								getImageRef={getImageRef}
								startIndex={index}
								onSlide={currentIndex => {
									this.setState({ stateIndex: currentIndex })
									if (onSlide) {
										onSlide(currentIndex)
									}
								}}
							/>
						)
					}
					{
						//TODO gallery不支持的类型使用kkFileView来预览使用iframe嵌入预览页面，这里使用函数的原因是让iframe在有真实数据之后再渲染
						!(
							(
								isShowAttachment ||
								isShowArchiveLog ||
								isShowFaceDetails ||
								isShowInfrequentData ||
								!isMail
							)
							// || !useGallery
						) && (
							<EmlPreview
								style={{ width: imageGalleryWidth, height: height }}
								mailData={this.props.items[0]?.fileData}></EmlPreview>
						)
					}
					{/* 渲染日志 */}
					{isShowArchiveLog && (
						<ArchiveLog
							setStyle={{
								containerWidth: imageGalleryWidth,
								containerHeight: height,
							}}
							id={archiveId}
							onClose={() => {
								this.setState({ isShowArchiveLog: false })
							}}
						/>
					)}
					{/* 人脸编辑页 */}
					{isShowFaceDetails && (
						<FaceDetails
							width={imageGalleryWidth}
							faceData={isFaceDetails}
							height={height}
							onClose={() => {
								this.setState({ isShowFaceDetails: false })
								setFillInformationState &&
									setFillInformationState({ showHeadBtn: true })
							}}
							resetImageFace={resetImageFace}
						/>
					)}
					{/* 附件 */}
					{isShowAttachment && (
						<Attachment
							style={{
								width: imageGalleryWidth,
								maxHeight: height,
								border: '1px solid #ccc',
							}}
							getAttachmentIds={getAttachmentIds}
							getAttachmentFileList={getAttachmentFileList}
							defaultFileList={defaultFileList}
							renderUploadBtn={true}
							renderCloseBtn={true}
							disabled={disabled}
							isVisitor={isVisitor}
							onlyShow={onlyShow}
							close={() => {
								this.setState({
									isShowAttachment: !isShowAttachment,
									isShowFaceDetails: false,
									isShowArchiveLog: false,
								})
							}}
							changeAttachments={changeAttachments}
							getAttachmentUploading={this.getAttachmentUploading}
						/>
					)}
					{isShowInfrequentData && (
						<InfrequentData
							disabled={disabled}
							style={{
								width: imageGalleryWidth,
								maxHeight: height,
								border: '1px solid #ccc',
							}}
							onClose={() => {
								this.setState({ isShowInfrequentData: false })
							}}
							getValue={allValues => {
								this.setState({
									lastValues: allValues,
								})
							}}
							getRef={ref => {
								getInfrequentFormRef(ref)
							}}
						/>
					)}
					{/* 右侧编辑元数据部分 */}
					{!isCollectedFile &&
						!isPaperDocument &&
						this.props.route !== '/mine/mine' && (
							<FileEditor
								uniqueName={`${uniqueName}-collected-file-editor`}
								height={height}
								width={fileEditorWidth}
								buttons={buttons}
								disabled={disabled}
								defaultFormValue={defaultFormValue}
								personList={personList}
								isFormat={isFormat}
								isArchived={isArchived}
								isFaceDetails={isFaceDetails}
								fileType={fileType}
								onFinish={values => {
									if (onFinish) {
										onFinish(values)
									}
								}}
								onValuesChange={onValuesChange}
								openFaceDetails={() => {
									this.closeOtherAndOpenthis('isShowFaceDetails')
									setFillInformationState &&
										setFillInformationState({ showHeadBtn: false })
								}}
								closeFaceDetails={() => {
									this.setState({ isShowFaceDetails: false })
									setFillInformationState &&
										setFillInformationState({ showHeadBtn: true })
								}}
								getRef={getRef}
								resetImageFace={resetImageFace}
							/>
						)}
					{isCollectedFile && (
						<CollectedFileEditor
							uniqueName={`${uniqueName}-file-editor`}
							height={height}
							width={fileEditorWidth}
							buttons={buttons}
							defaultFormValue={defaultFormValue}
							disabled={disabled}
							isFormat={isFormat}
							isArchived={isArchived}
							isFaceDetails={isFaceDetails}
							fileType={fileType}
							themeTopic={themeTopic}
							onFinish={values => {
								if (onFinish) {
									onFinish(values)
								}
							}}
							onValuesChange={onValuesChange}
							openFaceDetails={() => {
								this.closeOtherAndOpenthis('isShowFaceDetails')
								setFillInformationState &&
									setFillInformationState({ showHeadBtn: false })
							}}
							attachmentBtnClick={() => {
								this.setState({
									isShowAttachment: !isShowAttachment,
									isShowFaceDetails: false,
									isShowArchiveLog: false,
								})
							}}
							getRef={getRef}
						/>
					)}
					{isPaperDocument && (
						<PaperDocumentEditor
							uniqueName={`${uniqueName}-collected-file-editor`}
							height={height}
							width={fileEditorWidth}
							buttons={buttons}
							disabled={disabled}
							isFormat={isFormat}
							isArchived={isArchived}
							fileType={fileType}
							onFinish={values => {
								if (onFinish) {
									let infrequentData = JSON.parse(
										window.sessionStorage.getItem('infrequentData')
									)
									for (let key in infrequentData) {
										values.metadata.push({
											metadataId: Number(key),
											metadataValue: infrequentData[key],
										})
									}
									onFinish(values)
									window.sessionStorage.removeItem('infrequentData')
								}
							}}
							onValuesChange={onValuesChange}
							getRef={getRef}
						/>
					)}
				</div>
			</>
		)
	}
}

export default FileDetails
