import { refreshToken, serialNumberUrl } from '@/Utils/Urls'
import axios from 'axios'
import _fetch from '@/Utils/Fetch'

// 获取新token
export const getNewToken = () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: refreshToken,
			type: 'get',
		})
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})

export const getServerConfig = () =>
	new Promise((resolve, reject) => {
		axios
			.get(serialNumberUrl.getServerConfig)
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})

export const updateServerConfig = data =>
	new Promise((resolve, reject) => {
		_fetch({
			url: serialNumberUrl.updateServerConfig,
			type: 'post',
			data,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})
