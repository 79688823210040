import _fetch from '@/Utils/Fetch'
import { borrowUrl } from '@/Utils/Urls'
import Enum from '@/Utils/Enum'

export const statusEnum = new Enum({
	1: '待审批',
	2: '同意',
	3: '拒绝',
})

export const handleApproval = data => {
	return _fetch({
		url: borrowUrl.handleApproval,
		type: 'post',
		isJson: true,
		data,
	})
}
