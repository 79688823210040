import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import { Upload, Spin, message, Typography, Divider } from 'antd'
import {
	uploadThunk,
	faceRecognitionThunk,
	addFaceDataAction,
	faceInformationEntryThunk,
} from '../ActionCreators'
import FaceData from '../FaceData'
import './index.less'

const mapStateToProps = state => {
	return {
		face_data: state.addFaceData, // 获取人脸
	}
}
const mapDispatchToProps = dispatch => ({
	faceInformationEntry: (...rest) => dispatch(faceInformationEntryThunk(...rest)), // 录入人脸信息
	uploadFile: (...rest) => dispatch(uploadThunk(...rest)), // 上传
	faceRecognition: (...rest) => dispatch(faceRecognitionThunk(...rest)), // 识别人脸信息
	addFaceData(data, current) {
		dispatch(addFaceDataAction(data, current))
	},
})
@connect(mapStateToProps, mapDispatchToProps)
class AddFace extends Component {
	state = {
		spinning: false, // 加载状态
		currentIndex: 0,
	}

	// 当进行上传文件时
	handleChange = info => {
		// 先把加载状态弄成true
		this.setState({ spinning: true }, async () => {
			const { uploadFile, faceRecognition } = this.props
			const fileInfo = await uploadFile({ multipartFile: info.file })
			// 图片格式错误直接终止
			if (!fileInfo) {
				this.setState({ spinning: false })
				return
			}

			const faceInfo = await faceRecognition(fileInfo.data.fileId)

			try {
				// 只有上传和人脸识别成功时，才能进入录入页面
				if (!faceInfo.data.faceRecognition.length) {
					this.setState({ spinning: false }, () => {
						if (faceInfo.data.async) {
							message.warning('人脸数过多，请重新上传照片')
						} else {
							message.warning('识别不出人脸')
						}
					})
				} else {
					this.props.addFaceData(faceInfo.data.faceRecognition, true)
					this.setState({ spinning: false })
				}
			} catch (err) {
				this.setState({ spinning: false }, () => {
					message.destroy()
					message.error('上传失败，请稍后重试')
				})
			}
		})
	}

	/**
	 * 表单提交函数
	 * @param {*} values 提交的表单数据
	 */
	onSubmit = async values => {
		const { face_data, faceInformationEntry, addFaceData } = this.props
		const { currentIndex } = this.state
		// 请求数据
		const fetchData = {
			id: face_data.faceData[currentIndex]?.uid,
			...values,
		}

		// 录入人脸数据
		faceInformationEntry(fetchData).then(() => {
			message.success('确认成功！')
			if (face_data.faceData.length === 1) {
				addFaceData([], false)
			} else {
				// 删除提交的数据
				const data = face_data.faceData
				data.splice(currentIndex, 1)
				// 更新数据
				addFaceData(data, true)
				// 设置值
				this.formRef.current.setFieldsValue(face_data.faceData[0])
				this.setState({ currentIndex: 0 })
				this.forceUpdate()
			}
		})
	}

	// 轮播图
	onSlide = currentIndex => {
		this.setState({ currentIndex })
		this.formRef.current.setFieldsValue(this.props.face_data.faceData[currentIndex])
	}

	// 返回函数
	onBack = () => {
		this.props.addFaceData([], false)
	}

	// 获取到文件编辑器的ref
	getRef = ref => {
		this.formRef = ref
	}

	render() {
		const { spinning, currentIndex } = this.state
		const { face_data } = this.props

		return (
			<Fragment>
				{/* 未上传之前显示上传页面 */}
				{face_data.current === false && (
					<Spin spinning={spinning} tip='上传中...'>
						<div>
							<Typography.Title level={3}>
								请选择需要进行人脸录入的照片：
							</Typography.Title>
							<Divider style={{ marginTop: '10px' }}></Divider>
							<Upload.Dragger
								beforeUpload={() => false}
								onChange={this.handleChange}
								showUploadList={false}>
								<p className='ant-upload-drag-icon'>
									<UploadOutlined />
								</p>
								<p className='ant-upload-text'>点击或者拖拽一张图片</p>
								<p className='ant-upload-hint'>
									仅支持一次上传一张照片，建议为清晰的人脸照片
								</p>
							</Upload.Dragger>
						</div>
					</Spin>
				)}

				{/* 已经上传文件后，开始进行人脸录入操作 */}
				{face_data.current === true && (
					<FaceData
						uniqueName={'addFace'}
						onSubmit={this.onSubmit} // 提交函数
						onSlide={this.onSlide} // 滚动函数
						onBack={this.onBack} // 返回函数
						faceData={face_data.faceData} // 数据
						startIndex={currentIndex} // 起始index
						getRef={this.getRef} // 暴露ref
						buttons={null} // 底部按钮
					/>
				)}
			</Fragment>
		)
	}
}

export default AddFace
