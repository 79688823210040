import { PieChartOutlined } from '@ant-design/icons'
import WorkStation from '@/Components/ContentIn/Workarea/WorkStation'
//用于存储不同用户类型对应的SideBar
export const menuConfig = [
	{
		key: '/workarea/commonComponents',
		title: '公共组件',
		icon: PieChartOutlined,
		path: '/workarea/commonComponents',
		children: [
			{
				key: '/workarea/commonComponents/fileDetails',
				icon: PieChartOutlined,
				title: '文件详情',
				path: '/workarea/commonComponents/fileDetails',
				component: ' ',
			},
			{
				key: '/workarea/commonComponents/dossierDetails',
				icon: PieChartOutlined,
				title: '案卷详情',
				path: '/workarea/commonComponents/dossierDetails',
				component: ' ',
			},
			{
				key: '/workarea/commonComponents/details',
				icon: PieChartOutlined,
				title: '详情',
				path: '/workarea/commonComponents/details',
				component: ' ',
			},
			{
				key: '/workarea/commonComponents/collectedFileDetails',
				icon: PieChartOutlined,
				title: '资料详情',
				path: '/workarea/commonComponents/collectedFileDetails',
				component: ' ',
			},
			{
				key: '/workarea/commonComponents/ePhotoAlbumDetails',
				icon: PieChartOutlined,
				title: '主题详情',
				path: '/workarea/commonComponents/ePhotoAlbumDetails',
				component: ' ',
			},
			{
				key: '/workarea/commonComponents/fileFixApply',
				title: '修正文件',
				path: '/workarea/commonComponents/fileFixApply',
				component: ' ',
				icon: PieChartOutlined,
			},
			{
				key: '/workarea/commonComponents/borrowDetails',
				title: '借阅申请表',
				path: '/workarea/commonComponents/borrowDetails',
				icon: PieChartOutlined,
				component: ' ',
			},
			{
				key: '/workarea/commonComponents/AppraisalDetails',
				title: '档案鉴定',
				path: '/workarea/commonComponents/AppraisalDetails',
			},
			{
				key: '/workarea/commonComponents/fixDetails',
				title: '修正详情',
				path: '/workarea/commonComponents/fixDetails',
				icon: PieChartOutlined,
				component: ' ',
			},
			{
				key: '/workarea/commonComponents/approvalDetails',
				title: '借阅审批详情',
				path: '/workarea/commonComponents/approvalDetails',
				icon: PieChartOutlined,
				component: ' ',
			},
		],
	},
	{
		key: '/workarea/myFiles',
		icon: PieChartOutlined,
		title: '我的档案',
		path: '/workarea/myFiles',
		children: [
			{
				key: '/workarea/myFiles/recordFiles',
				icon: PieChartOutlined,
				title: '著录中',
				path: '/workarea/myFiles/recordFiles',
				component: ' ',
			},
			//{
			//	key: '/workarea/myFiles/underReview',
			//	icon: PieChartOutlined,
			//	title: '审核中',
			//	path: '/workarea/myFiles/underReview',
			//	component: ' ',
			//},
			{
				key: '/workarea/myFiles/prearchive',
				icon: PieChartOutlined,
				title: '预归档',
				path: '/workarea/myFiles/prearchive',
				component: ' ',
			},
			/* {
        key: "/workarea/myFiles/archived",
        icon: PieChartOutlined,
        title: "已归档",
        path: '/workarea/myFiles/archived',

        component: ' '
      },  */ {
				key: '/workarea/myFiles/expiredFiles',
				icon: PieChartOutlined,
				title: '已过期',
				path: '/workarea/myFiles/expiredFiles',
				component: ' ',
			},
			{
				key: '/workarea/myFiles/destroyFiles',
				icon: PieChartOutlined,
				title: '已销毁',
				path: '/workarea/myFiles/destroyFiles',
				component: ' ',
			},
			{
				key: '/workarea/myFiles/recycleBin',
				icon: PieChartOutlined,
				title: '回收站',
				path: '/workarea/myFiles/recycleBin',
				component: ' ',
			},
		],
	},
	{
		key: '/workarea/workStation',
		icon: PieChartOutlined,
		title: '收藏',
		path: '/workarea/workStation',
		children: [
			{
				key: '/workarea/workStation/:id',
				icon: PieChartOutlined,
				title: '收藏夹',
				path: '/workarea/workStation/:id',
				component: WorkStation,
			},
		],
	},
	{
		key: '/workarea/myApplication',
		icon: PieChartOutlined,
		title: '我的申请',
		path: '/workarea/myApplication',
		children: [
			{
				key: '/workarea/myApplication/borrow',
				icon: PieChartOutlined,
				title: '借阅申请',
				path: '/workarea/myApplication/borrow',
				component: ' ',
			},
			{
				key: '/workarea/myApplication/correct',
				icon: PieChartOutlined,
				title: '修正申请',
				path: '/workarea/myApplication/correct',
				component: ' ',
			},
		],
	},
	{
		key: '/workarea/myApproval',
		icon: PieChartOutlined,
		title: '我的审批',
		path: '/workarea/myApproval',
		children: [
			{
				key: '/workarea/myApproval/borrow',
				icon: PieChartOutlined,
				title: '借阅审批',
				path: '/workarea/myApproval/borrow',
				component: ' ',
			},
			{
				key: '/workarea/myApproval/correct',
				icon: PieChartOutlined,
				title: '修正审批',
				path: '/workarea/myApproval/correct',
				component: ' ',
			},
			{
				key: '/workarea/myApproval/claim',
				icon: PieChartOutlined,
				title: '认领审批',
				path: '/workarea/myApproval/claim',
				component: '',
			},
		],
	},
	{
		key: '/workarea/appraisal',
		icon: PieChartOutlined,
		title: '档案鉴定',
		path: '/workarea/appraisal',
		component: ' ',
	},
]
// 新建的侧边栏配置
export const newConstructionMenuConfig = [
	{
		key: '/newConstruction/newFiles-multiMedia',
		title: '多媒体',
		path: '/newConstruction/newFiles-multiMedia',
		icon: PieChartOutlined,
		component: ' ',
	},
	{
		key: '/newConstruction/newArchives',
		title: '案卷',
		path: '/newConstruction/newArchives',
		icon: PieChartOutlined,
		component: ' ',
	},
	{
		key: '/newConstruction/UrlToArchives',
		title: '新媒体',
		path: '/newConstruction/UrlToArchives',
		icon: PieChartOutlined,
		component: ' ',
	},
	{
		key: '/newConstruction/newFiles-document',
		title: '文书',
		path: '/newConstruction/newFiles-document',
		icon: PieChartOutlined,
		component: ' ',
	},
	{
		key: '/newConstruction/automaticDescription',
		title: '自动著录',
		path: '/newConstruction/automaticDescription',
		icon: PieChartOutlined,
		component: ' ',
	},
	{
		key: '/newConstruction/urlToArchiveManagement',
		title: '新媒体管理',
		path: '/newConstruction/urlToArchiveManagement',
		icon: PieChartOutlined,
		component: ' ',
	},
]

// 档案(已归档)的侧边栏
export const archivedMenuConfig = [
	{
		key: '/archived',
		title: '档案',
		path: '/archived',
		icon: PieChartOutlined,
		component: ' ',
	},
]

// 系统侧边栏菜单配置
export const settingsMenuConfig = [
	{
		key: '/settings/system',
		icon: PieChartOutlined,
		title: '系统管理',
		path: '/settings/system',
		children: [
			{
				key: '/settings/system/all',
				icon: PieChartOutlined,
				title: '全宗管理',
				path: '/settings/system/all',
				component: ' ',
			},
			{
				key: '/settings/system/userManager',
				icon: PieChartOutlined,
				title: '用户管理',
				path: '/settings/system/userManager',
				component: ' ',
			},
			{
				key: '/settings/system/roleManager',
				icon: PieChartOutlined,
				title: '角色管理',
				path: '/settings/system/roleManager',
				component: ' ',
			},
			{
				key: '/settings/system/departmentManagement',
				icon: PieChartOutlined,
				title: '部门管理',
				path: '/settings/system/departmentManagement',
				component: ' ',
			},
			{
				key: '/settings/system/archiveTypeManagement',
				icon: PieChartOutlined,
				title: '档案类型管理',
				path: '/settings/system/archiveTypeManagement',
				component: ' ',
			},
			{
				key: '/settings/system/fileTypeManagement',
				icon: PieChartOutlined,
				title: '数据字典',
				path: '/settings/system/fileTypeManagement',
				component: ' ',
			},
			{
				key: '/settings/system/urlToArchiveManagement',
				icon: PieChartOutlined,
				title: '新媒体管理',
				path: '/settings/system/urlToArchiveManagement',
				component: ' ',
			},
			{
				key: '/settings/system/systemConfigure',
				icon: PieChartOutlined,
				title: '档案号配置',
				path: '/settings/system/systemConfigure',
				component: ' ',
			},
			{
				key: '/settings/system/ePhotoAlbumManagement',
				icon: PieChartOutlined,
				title: '编研主题管理',
				path: '/settings/system/ePhotoAlbumManagement',
				component: ' ',
			},
			{
				key: '/settings/system/Health',
				icon: PieChartOutlined,
				title: '健康检查',
				path: '/settings/system/Health',
				component: ' ',
			},
			{
				key: '/settings/system/sensitiveWordsManagement',
				icon: PieChartOutlined,
				title: '敏感词管理',
				path: '/settings/system/sensitiveWordsManagement',
				component: ' ',
			},
			{
				key: '/settings/system/dataTableManagement',
				icon: PieChartOutlined,
				title: '数据表管理',
				path: '/settings/system/dataTableManagement',
				component: ' ',
			},
			{
				key: '/settings/system/metaDataManagement',
				icon: PieChartOutlined,
				title: '元数据管理',
				path: '/settings/system/metaDataManagement',
				component: ' ',
			},
			{
				key: '/settings/system/paramsManagement',
				icon: PieChartOutlined,
				title: '参数管理',
				path: '/settings/system/paramsManagement',
				component: ' ',
			},
			{
				key: '/settings/system/exceptionLogManagement',
				icon: PieChartOutlined,
				title: '出错记录',
				path: '/settings/system/exceptionLogManagement',
				component: ' ',
			},
		],
	},
]

// 编研管理侧边栏菜单配置
export let editingResearchMenuConfig = [
	{
		key: '/editingResearch/ePhotoAlbum',
		title: '编研管理',
		path: '/editingResearch/ePhotoAlbum',
		icon: PieChartOutlined,
		component: ' ',
	},
]

export const statisticsMenuConfig = [
	{
		key: '/statistics',
		title: '统计',
		path: '/statistics',
		icon: PieChartOutlined,
		component: ' ',
	},
]

export const collectedFilesMenuConfig = [
	{
		key: '/collectedFiles/collectedFiles',
		title: '资料',
		path: '/collectedFiles/collectedFiles',
		component: ' ',
	},
	{
		key: '/collectedFiles/themeManage',
		title: '主题管理',
		path: '/collectedFiles/themeManage',
		component: ' ',
	},
]

export const faceMenuConfig = [
	{
		key: '/face/addFace',
		title: '录入人脸',
		path: '/face/addFace',
		icon: PieChartOutlined,
		component: ' ',
	},
	{
		key: '/face/searchFace',
		title: '人脸管理',
		path: '/face/searchFace',
		icon: PieChartOutlined,
		component: ' ',
	},
]

// 我的侧边栏配置
export const mineMenuConfig = [
	{
		key: '/Mine',
		icon: PieChartOutlined,
		title: '我的',
		path: '/Mine',
		component: ' ',
	},
]
