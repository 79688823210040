import React, { Component, Fragment } from 'react'
import { Form, Input, TreeSelect, DatePicker, Button, Typography } from 'antd'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { connect } from 'react-redux'
import {
	getCollectedFileTypeThunk,
	getAllDepartmentNameTreeThunk,
} from './ActionCreators'
import './index.less'

moment.locale('zh-cn')

const formRef = React.createRef(null) // 表单ref

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	getCollectedFileType: (...rest) => dispatch(getCollectedFileTypeThunk(...rest)),
	getAllDepartmentNameTree: (...rest) =>
		dispatch(getAllDepartmentNameTreeThunk(...rest)),
})

@connect(mapStateToProps, mapDispatchToProps)
class CollectedFileEditor extends Component {
	// 添加自定义样式
	setStyle = () => {
		let { height = 580, width = 350, uniqueName, buttons } = this.props
		height = height.toString()
		width = width.toString()
		if (width.replace(/[0-9]/g, '') === '') {
			width = `${width}px`
		}
		if (height.replace(/[0-9]/g, '') === '') {
			height = `${height}px`
		}
		const property = {
			'--height': height,
			'--width': width,
			'--form-height': `calc(${height} - 60px)`,
			'--content-height': buttons ? '88%' : '95%',
			'--buttons-height': buttons ? '12%' : '0',
		}
		const fileEditor = document.querySelector(`.${uniqueName}`)
		for (let i in property) {
			fileEditor.style.setProperty(i, property[i])
		}
	}

	state = {
		categoryCodeData: [],
	}

	setTreeData = (data, titleName, valueName, onlyChildren = false) => {
		let treeData = []
		const recursionToTree = data => {
			let tempObj = {
				title: data[titleName],
				value: data[valueName],
			}

			if (data.children != null) {
				if (onlyChildren) {
					tempObj.disabled = true
				}
				tempObj.children = [...data.children.map(item => recursionToTree(item))]
			}
			return tempObj
		}
		data?.forEach((value, index) => {
			treeData.push(recursionToTree(value))
		})
		if (titleName === 'typeName') treeData.forEach(item => (item.disabled = true))
		return treeData
	}

	componentDidMount() {
		const { getRef, defaultFormValue = [] } = this.props
		formRef.current.setFields(defaultFormValue)

		const categoryCodeData = this.setTreeData(
			JSON.parse(localStorage.getItem('collectedFileType')),
			'typeName',
			'id'
		) //类别号
		const department = this.setTreeData(
			JSON.parse(localStorage.getItem('department')),
			'departmentName',
			'id'
		) // 部门树
		const theme = this.setTreeData(
			JSON.parse(localStorage.getItem('theme')),
			'topic',
			'themeId'
		) // 主题
		this.setState({
			categoryCodeData,
			department,
			theme,
		})

		this.setStyle()

		getRef && getRef(formRef)
	}

	componentDidUpdate() {
		this.setStyle()
	}

	render() {
		const {
			disabled = false, // 是否禁用表单，默认为false
			uniqueName, // 表单唯一表示, 必须要
			onFinish, // 表单提交函数
			onValuesChange, // 表单value改变触发的函数
			buttons,
			themeTopic,
		} = this.props

		const { categoryCodeData, department } = this.state

		if (!uniqueName) {
			throw Error('CollectedFileEditor中，uniqueName是必传参数')
		}
		return (
			<Fragment>
				<div className={`collected-file-editor ${uniqueName}`}>
					<div className='information-form'>
						<h3 className='form-title'>资料详情</h3>
						<Typography.Title level={4} className='theme-topic'>
							{themeTopic}
						</Typography.Title>
						<Form
							onFinish={values => {
								if (onFinish) {
									const momentTime = values.time
									if (momentTime != null) {
										values.time = moment(momentTime)
											.format('YYYY-MM-DDTHH:mm:ss')
											.replace(/\.(.*)/, '')
									}
									onFinish(values)
								}
							}}
							onValuesChange={changedFields => {
								if (onValuesChange) {
									onValuesChange(changedFields)
								}
							}}
							ref={formRef}>
							<div className='information-content'>
								{/* 题名 */}
								<Form.Item
									label='资料名称'
									name='topic'
									rules={[{ required: true, message: '请填写名称' }]}>
									<Input.TextArea
										disabled={disabled}
										placeholder='无'
										bordered={!disabled}
										autoSize={true}
										maxLength={300}
										showCount={!disabled}
									/>
								</Form.Item>

								<Form.Item
									label='捐赠者'
									name='providerName'
									rules={[
										{ required: true, message: '捐赠者不能为空' },
									]}>
									<Input.TextArea
										disabled={disabled}
										placeholder='捐赠者需唯一'
										bordered={!disabled}
										autoSize={true}
										maxLength={150}
										showCount={!disabled}
									/>
								</Form.Item>

								<Form.Item
									label='联系方式'
									name='providerPhone'
									rules={[
										{
											required: true,
											pattern: new RegExp(
												/^1(3|4|5|6|7|8|9)\d{9}$/,
												'g'
											),
											message: '请输入正确手机号码',
										},
									]}>
									<Input.TextArea
										disabled={disabled}
										placeholder='手机号码/微信'
										bordered={!disabled}
										autoSize={true}
									/>
								</Form.Item>

								<Form.Item
									label='邮箱'
									name='email'
									rules={[
										{
											required: false,
											pattern: new RegExp(
												'^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$'
											),
											message: '请输入正确邮箱地址',
										},
									]}>
									<Input.TextArea
										disabled={disabled}
										placeholder='用于接收捐赠证书'
										bordered={!disabled}
										autoSize={true}
									/>
								</Form.Item>

								{/* 事件 */}
								<Form.Item label='事件' name='event'>
									<Input.TextArea
										disabled={disabled}
										placeholder='无'
										bordered={!disabled}
										autoSize={true}
									/>
								</Form.Item>

								{/* 荣休感言 */}
								<Form.Item label='荣休感言' name='comment'>
									<Input.TextArea
										disabled={disabled}
										placeholder='无'
										bordered={!disabled}
										autoSize={true}
										maxLength={500}
										showCount={!disabled}
									/>
								</Form.Item>

								{/* 作者 */}
								<Form.Item label='作者' name='author'>
									<Input.TextArea
										disabled={disabled}
										placeholder='无'
										bordered={!disabled}
										autoSize={true}
										maxLength={150}
										showCount={!disabled}
									/>
								</Form.Item>

								{/* 类别 */}
								<Form.Item label='类别' name='categoryId'>
									<TreeSelect
										disabled={disabled}
										bordered={!disabled}
										placeholder='无'
										treeData={categoryCodeData}
										treeDefaultExpandAll
										virtual={false}
									/>
								</Form.Item>

								{/* 人物 */}
								<Form.Item label='人物' name='people'>
									<Input.TextArea
										disabled={disabled}
										placeholder='无'
										bordered={!disabled}
										autoSize={true}
										maxLength={300}
										showCount={!disabled}
									/>
								</Form.Item>

								{/* 时间 */}
								<Form.Item label='拍摄时间' name='time'>
									<DatePicker
										disabled={disabled}
										placeholder='无'
										bordered={!disabled}
									/>
								</Form.Item>

								{/* 地点 */}
								<Form.Item label='拍摄地点' name='place'>
									<Input.TextArea
										disabled={disabled}
										placeholder='无'
										bordered={!disabled}
										autoSize={true}
										maxLength={300}
										showCount={!disabled}
									/>
								</Form.Item>

								{/* 部门 */}
								<Form.Item label='部门' name='departmentId'>
									<TreeSelect
										disabled={disabled}
										bordered={!disabled}
										placeholder='无'
										treeData={department}
										treeDefaultExpandAll
										virtual={false}
									/>
								</Form.Item>
							</div>

							{/* 按钮 */}
							<div className='fill-information-btns'>
								<Form.Item>
									{buttons &&
										buttons.map((btn, index) => {
											const { text, ...props } = btn
											return (
												<Button key={index} {...props}>
													{text}
												</Button>
											)
										})}
								</Form.Item>
							</div>
						</Form>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default CollectedFileEditor
