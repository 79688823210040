// 简单模拟枚举
export default class Enum {
	constructor(originData) {
		this.enum = originData
	}
	set enum(originData) {
		// 如果传入的是数组，则以下标作为 key 值
		let originObj = Array.isArray(originData)
			? {
					...originData,
				}
			: originData
		this.originObj = originObj
		// 遍历值双向映射到 Enum 实例中
		for (let key in originObj) {
			this[key] = originObj[key]
			this[originObj[key]] = Number(key)
		}
	}
	// 将 Enum 传入的对象转换成 antd 表单要的 options 形式
	toOptions() {
		return Object.entries(this.originObj).map(([value, label]) => ({
			label,
			value: +value, // 字符串转为数字
		}))
	}
}
// 档案/案卷的相关枚举
export const dossierEnum = {
	// 档案/案卷的状态
	status: new Enum({
		0: '著录中',
		1: '预归档',
		2: '已归档',
		3: '已过期',
		4: '回收站',
		5: '已销毁',
		12: '审核中',
	}),

	// 档案/案卷密级
	confidentialLevel: new Enum(['公开', '内部', '绝密', '机密', '秘密']),
	// 档案/案卷保管期限
	retentionPeriod: new Enum({
		1: '永久',
		2: '30年',
		3: '10年',
	}),
	// 新建案卷的文件状态
	newArchiveStatus: new Enum(['著录中', '预归档', '已归档']),
	packArchiveStatus: new Enum(['著录中', '预归档', '已归档', '已过期']),
	// 档案/案卷类型
	type: new Enum({
		0: '案卷',
		1: '文件',
		3: '资料',
	}),
}

// 申请的相关枚举
export const applicationEnum = {
	status: new Enum(['申请', '待审批', '同意', '拒绝', '回退']),
}
// 借阅的相关枚举
export const borrowEnum = {
	status: new Enum({
		0: '申请',
		1: '待审批',
		2: '同意',
		4: '完成',
		5: '延期',
	}),
	//审批状态
	verifyStatus: new Enum(['同意', '拒绝', '转交']),
}

// 综合查询的相关枚举
export const CompositeSearchEnum = {
	status: new Enum(['案卷', '文件', '全部']),
}

// 用户管理页面
export const UserManagerEnum = {
	status: new Enum(['不允许登陆', '允许登陆', '有效期内允许登陆']),
}
