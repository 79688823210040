import _fetch from '@/Utils/Fetch'
import { sensitiveController, dataTableManagerUrl } from '@/Utils/Urls'
import { Modal, message } from 'antd'
export const tips = {
	CHOOSE_AT_LEAST_ONE: '请选择至少一项后再进行操作',
	UPDATE_SUCCESS: '保存成功',
	DELETE_SUCCESS: '删除成功',
}

/**
 * 删除敏感词
 * @param {string} id 要删除的id | id数组
 * @returns redux-Promise-middleware
 */
export const deleteSensitiveWordThunk = ids => () =>
	new Promise((resolve, reject) => {
		Modal.confirm({
			maskClosable: true,
			title: '删除敏感词',
			content: '您正在对当前敏感词进行删除操作，是否继续？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: sensitiveController.deleteSensitiveWord,
					data: { ids },
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success('删除成功！')
						resolve(res)
					}
				})
			},
		})
	})

/**
 * 新增一个敏感词
 * @param {Object} data 包含group | key 字段的data
 * @returns redux-Promise-middleware
 */
export const addSensitiveWordThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: sensitiveController.addSensitiveWord,
			type: 'post',
			data: data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('添加成功！')
				resolve(res.data)
			} else {
				reject(res)
			}
		})
	})

/**
 * 修改敏感词
 * @param {object} data 包含:group | id | key 的data对象
 * @return redux-Promise-middleware
 */
export const changeSensitiveWordThunk = data => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '修改敏感词',
			content: '确定保存？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: sensitiveController.changeSensitiveWord,
					type: 'post',
					isJson: true,
					data,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.UPDATE_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

//获取所有表
export const getAlltableThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dataTableManagerUrl.getAllTable,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

//删除列
export const deleteTableColumnThunk = data => () =>
	new Promise((resolve, reject) => {
		Modal.confirm({
			title: '删除节点',
			content: `删除之后将不可恢复，请问是否继续删除`,
			okText: '确认',
			cancelText: '取消',
			okType: 'danger',
			maskClosable: true,
			onOk: () => {
				_fetch({
					url: dataTableManagerUrl.deleteTableColumn,
					data,
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.DELETE_SUCCESS)
						resolve()
					}
				})
			},
		})
	})

//新建表
export const createTableThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dataTableManagerUrl.createTable,
			type: 'post',
			isJson: true,

			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

//修改列名
export const renameTableColumnThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dataTableManagerUrl.renameTableColumn,
			type: 'post',
			isJson: true,

			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

//修改列的数据结构
export const changeColumnDataTypeThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dataTableManagerUrl.changeColumnDataType,
			type: 'post',
			isJson: true,

			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

//添加列
export const addColumnThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dataTableManagerUrl.addColumn,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})
