import React, { PureComponent } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import EventBus from '@/Utils/pubsub'

export const createRich = (itemConfig, formRefs) => {
	const {
		getRichValue,
		label,
		rules = [
			{
				required: true,
				message: '该项不能为空',
			},
		],
	} = itemConfig
	class Rich extends PureComponent {
		state = {
			contentState: null,
			editorState: EditorState.createEmpty(),
		}

		onEditorStateChange = editorState => {
			this.setState({
				editorState,
			})
		}

		componentDidUpdate() {
			getRichValue(
				draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
			)
		}

		componentDidMount() {
			EventBus.addEventListener('setRichValue', this.htmlToDraft)
		}

		componentWillUnmount() {
			EventBus.removeEventListener('setRichValue', this.htmlToDraft)
		}

		//把html转draft
		htmlToDraft = e => {
			const blocksFromHtml = htmlToDraft(e.detail.htmlContent)
			const { contentBlocks, entityMap } = blocksFromHtml
			const contentState = ContentState.createFromBlockArray(
				contentBlocks,
				entityMap
			)
			const editorState = EditorState.createWithContent(contentState)
			this.setState({
				editorState,
			})
		}

		render() {
			const { editorState, contentState } = this.state
			const { required } = rules[0]
			return (
				<div className='rich'>
					<div className={required ? 'label' : null}>
						<i>*</i>
						<>{label}</>
					</div>
					<Editor
						editorState={editorState}
						contentState={contentState}
						wrapperClassName='demo-wrapper'
						editorClassName='demo-editor'
						onEditorStateChange={this.onEditorStateChange}
						editorRef={ref => {
							this.editorReferece = ref
						}}
					/>
				</div>
			)
		}
	}
	return <Rich />
}
