import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import { UPGRADE_PUBARCHIVE, INITIAL_PUBARCHIVE } from '@/Redux/ActionTypes/PubArchive'
import { PUBFILE_ADD, PUBDOSSIER_ADD } from '@/Redux/ActionTypes/Public/PubForm'
import _fetch from '@/Utils/Fetch'
import { ePhotoAlbum } from '@/Utils/Urls'
import { message } from 'antd'

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

export const addPubFileAction = data => {
	return {
		type: PUBFILE_ADD,
		data: data,
	}
}

export const addPubDossierAction = data => {
	return {
		type: PUBDOSSIER_ADD,
		data: data,
	}
}

// 添加或更新pubArchive[markName].new
export const upgradePubArchiveAction = (markName, pubArchiveData) => {
	return {
		type: UPGRADE_PUBARCHIVE,
		filter: {
			markName,
			...pubArchiveData,
		},
	}
}

// 初始化pubArchive[markName].old
export const inititalPubArchiveAction = (markName, pubArchiveData) => {
	return {
		type: INITIAL_PUBARCHIVE,
		filter: {
			markName,
			...pubArchiveData,
		},
	}
}

/**
 * 修改编研主题
 * @param {object} data 申请数据
 */
export const updateThemeThunk = data => () =>
	new Promise(resolve => {
		// 对档案请求编辑
		_fetch({
			url: ePhotoAlbum.updateTheme,
			isJson: true,
			data,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('修改成功')
				resolve()
			}
		})
	})

/**
 * 修改编研文件在主题中的顺序
 * @param {object} data
 */
export const changeFilePriorityThunk = data => () =>
	new Promise(resolve => {
		// 对档案请求编辑
		_fetch({
			url: ePhotoAlbum.changeFilePriority,
			isJson: true,
			data,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('文件排序修改成功')
				resolve()
			}
		})
	})

/**
 * 获取编研主题
 * @param {object} id 主题id
 */
export const searchThemeThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: ePhotoAlbum.searchTheme,
			isJson: true,
			data,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

/**
 * 获取编研主题信息
 * @param {object} id 主题id
 */
export const getThemeThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: ePhotoAlbum.getTheme,
			isJson: true,
			data,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

/**
 * 添加编研文件
 * @param {object} data 主题信息
 */
export const addFileThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: ePhotoAlbum.addFile,
			isJson: true,
			data,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

/**
 * 修改编研文件
 * @param {object} data 主题信息
 */
export const updateFileThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: ePhotoAlbum.updateFile,
			isJson: true,
			data,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('编辑成功')
				resolve(res.data)
			}
		})
	})

/**
 * 删除编研文件
 * @param {object} data 主题信息
 */
export const deleteFileThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: ePhotoAlbum.deleteFile,
			isJson: true,
			data,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('删除成功')
				resolve(res.data)
			}
		})
	})

/**
 * 申请分享编研文件
 * @param {object} data
 */
export const applyShareStudyingFilesThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: ePhotoAlbum.applyShareStudyingFiles,
			isJson: true,
			data,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

/**
 * 外网获取分享的编研文件
 * @param {object} data 主题信息
 */
export const getShareStudyingFilesThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: ePhotoAlbum.getShareStudyingFiles,
			isJson: true,
			data,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

/**
 * 删除编研主题
 * @param {Number} id 申请id
 */
export const deleteTypeThunk = record => () =>
	new Promise(resolve => {
		_fetch({
			url: ePhotoAlbum.deleteTheme,
			type: 'post',
			data: { ids: record },
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('删除成功')
				resolve(res.data)
			}
		})
	})
