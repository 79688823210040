import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import _fetch from '@/Utils/Fetch'
import { mapUrl } from '@/Utils/Urls'
import {
	getFaceListThunk,
	faceInformationEntryThunk,
	deleteFaceThunk,
	mergeFaceThunk,
	compareToExistedUserThunk,
	selectFaceByNameThunk,
	downloadFocusNameExcelThunk,
} from '../ActionCreators'
import { UploadOutlined } from '@ant-design/icons'
import {
	Form,
	Input,
	Button,
	message,
	Empty,
	Image,
	Pagination,
	Spin,
	Switch,
	Modal,
	Upload,
	Space,
} from 'antd'
import FaceData from '../FaceData'
import PictureRecognition from './PictureRecognition/index'
import deleteImg from '@/Static/delete.png'
import searchImg from '@/Static/search.png'
import './index.less'

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	selectFaceByName: (...rest) => dispatch(selectFaceByNameThunk(...rest)), // 根据姓名精确查询人脸信息
	getFaceList: (...rest) => dispatch(getFaceListThunk(...rest)), // 查询人脸信息
	faceInformationEntry: (...rest) => dispatch(faceInformationEntryThunk(...rest)), // 录入人脸信息
	deleteFace: (...rest) => dispatch(deleteFaceThunk(...rest)), // 删除人脸信息
	mergeFace: (...rest) => dispatch(mergeFaceThunk(...rest)), // 合并人脸信息
	downloadExcel: (...rest) => dispatch(downloadFocusNameExcelThunk(...rest)), //导出excel
	compareToExistedUser: (...rest) => dispatch(compareToExistedUserThunk(...rest)), // 校验人脸信息
})

@connect(mapStateToProps, mapDispatchToProps)
class SearchFace extends Component {
	formRef = React.createRef()
	searchformRef = React.createRef()

	state = {
		faceDatas: [], // 人脸数据
		propIndex: null, // 从搜索页面打开模态框的索引
		total: 0, // 人脸总数
		spinning: false, // 是否正在搜索
		size: 32, // 默认每页32条
		searchCriteria: {}, // 搜索条件
		editable: false, // 是否跳转到录入信息页面
		current: 1,
		pageKey: new Date(),
		mark: true, // 是否标记,ture为已标记
		sameNameFaceList: [], // 同名人脸列表
		thisFaceData: {
			// 刷新时此人脸的数据
			name: null,
			id: null,
		},
		isPicSearch: false, //是否为图片识别的搜索
		visible: false, // 是否显示上传文件的模态框
		fileList: [], //文件列表，用于控制upload组件
		uploading: false, //上传按钮loading显示控制
		exportFaceLoading: false, //导出按钮loading显示控制
	}

	componentDidMount() {
		document.querySelector('.search-face').addEventListener('click', e => {
			const ele = e.target.parentNode.parentNode.parentNode.parentNode
			const index = [].indexOf.call(ele.parentNode.children, ele)

			if (e.target.className === 'ant-image-img ant-image-img-search-face') {
				const { faceDatas, mark } = this.state
				this.setState({ propIndex: index }, () => {
					const { selectFaceByName } = this.props
					// 查看的时候请求同名数据(只有标记才执行)
					mark
						? selectFaceByName({ name: faceDatas[index].name }).then(res => {
								let faceImageList = []
								// 将数据组合放至props
								if (res.data) {
									res.data.forEach(item => {
										if (item.id !== faceDatas[index].id) {
											item.faceImageList[0].id = item.id
											faceImageList.push(item.faceImageList[0])
										}
									})
								}
								this.setState({
									editable: true,
									sameNameFaceList: faceImageList,
									thisFaceData: {
										name: faceDatas[index].name,
										id: faceDatas[index].id,
									},
								})
							})
						: this.setState({
								editable: true,
								sameNameFaceList: [],
								thisFaceData: {
									name: faceDatas[index].name,
									id: faceDatas[index].id,
								},
							})
				})
			}
			if (e.target.className === 'deleteImg') {
				const { faceDatas, size, current, searchCriteria, mark } = this.state
				this.props.deleteFace({ uidList: faceDatas[index].id }, () =>
					this.onSearch({ size, current, mark, ...searchCriteria })
				)
			}
		})
		this.onSearch({ size: 32 })
	}

	componentDidUpdate() {
		const { editable, searchCriteria } = this.state
		if (editable === false) {
			this.searchformRef.current.setFieldsValue(searchCriteria)
		}
	}

	// 设置请求数据
	setAjaxData = (values = {}) => {
		const { size, mark } = this.state
		let data = { mark, size, ...values }
		// 如果表单项没有值，则查询不转换
		if (!mark && (values.name || values.department || values.position)) {
			this.setState({ mark: true })
			data.mark = true
		}
		// 删除无用数据
		for (let i in data) {
			if (data[i] === undefined) {
				delete data[i]
			}
		}
		return data
	}

	// 搜索人脸
	onSearch = (values = {}) => {
		this.setState({ spinning: true }, () => {
			const { getFaceList } = this.props
			const data = this.setAjaxData(values)
			getFaceList(data)
				.then(res => {
					// if (res.data.total === 0) {
					//   message.error('没有查询结果')
					// }
					this.setState({
						faceDatas: res.data.records,
						total: res.data.total,
						spinning: false,
						isPicSearch: false,
					})
				})
				.catch(err => {
					this.setState({ spinning: false })
				})
		})
	}

	editFaceData = () => {
		this.setState({ editable: true })
	}
	/**
	 * 表单提交函数(人脸详情页编辑提交)
	 * @param {*} values 提交的表单数据
	 */
	onSubmit = async values => {
		const { faceDatas, propIndex, size, current, searchCriteria, mark } = this.state
		const { faceInformationEntry } = this.props
		// 请求数据
		const fetchData = {
			id: faceDatas[propIndex].id,
			...values,
		}
		Modal.confirm({
			maskClosable: true,
			title: '更改信息',
			content: '您正在更改当前人脸的信息，是否继续？',
			okText: '确定',
			cancelText: '取消',
			onOk: () => {
				// 录入人脸数据
				faceInformationEntry(fetchData).then(() => {
					message.success('确认成功！')
					this.setState({ editable: false })
					this.onSearch({ size, current, mark, ...searchCriteria })
				})
			},
		})
	}

	// 返回函数
	onBack = () => {
		const { size, current, mark, searchCriteria } = this.state
		this.setState({ editable: false })
		this.onSearch({ size, current, mark, ...searchCriteria })
	}

	// 获取到文件编辑器的ref
	getRef = ref => {
		this.formRef = ref
	}

	// 刷新函数
	toRefresh = () => {
		const { thisFaceData, faceDatas, propIndex, mark } = this.state
		this.setState({ spinning: true, isPicSearch: false }, () => {
			const { selectFaceByName } = this.props
			// 查看的时候请求同名数据
			selectFaceByName({ name: thisFaceData?.name, mark }).then(res => {
				let refreshFace = faceDatas
				let faceImageList = []
				// 将数据组合放至props
				if (res.data) {
					res.data.forEach(item => {
						if (item.id !== thisFaceData?.id) {
							item.faceImageList[0].id = item.id
							faceImageList.push(item.faceImageList[0])
						} else {
							refreshFace[propIndex] = item
						}
					})
				}
				this.setState({
					spinning: false,
					sameNameFaceList: mark ? faceImageList : [],
					faceDatas: refreshFace,
				})
			})
		})
	}

	// 获取图片识别出来的人脸信息
	setPictureFaces = faceDataList => {
		this.setState({
			faceDatas: faceDataList,
			isPicSearch: true,
			total: faceDataList.length,
		})
	}

	// 显示模态框
	showModal = () => {
		this.setState({
			visible: true,
		})
	}

	// 处理本地文件上传，转化为数据
	handleFileChange = info => {
		//处理文件change，保证用户选择的文件只有一个
		let fileList = [...info.fileList]
		fileList = fileList.slice(-2)

		// 2. Read from response and show file link
		fileList = fileList.map(file => {
			if (file.response) {
				// Component will show file.url as link
				file.url = file.response.url
			}
			return file
		})

		this.setState({ fileList })
	}

	// 处理文件上传到服务器
	handleUpload = () => {
		// 如果不存在本地上传的文件，则无法上传文件到服务器
		if (!this.state.fileList.length) {
			message.warning('请选择要上传的文件')
			return false
		}
		const formData = new FormData() // 构造一个formData实例对象，用于存储formData数据
		formData.append('multipartFile', this.state.fileList[0].originFileObj) // 将后台指定的字段名作为属性，文件数据作为属性值
		this.setState({
			uploading: true, // 上传成功后取消加载状态
		})
		// 调用上传接口
		_fetch({
			type: 'post',
			url: mapUrl.uploadFocusNameExcel,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
			.then(res => {
				if (res.data.success) message.success('导入成功')
				// 成功后关闭模态框
				this.setState({
					visible: false,
				})
				this.formRef.setFieldsValue({
					fileId: res.data.data,
				})
				// this.props.reloadTable()
			})
			.catch(err => {})
			.finally(() => {
				this.setState({
					fileList: [],
					uploading: false,
				})
			})
	}

	// 处理模态框关闭
	handleCancel = e => {
		this.setState({
			visible: false,
		})
	}

	handledownloadExcelModel = callback => {
		Modal.confirm({
			title: '提示',
			content: '您确定要导出重点人脸数据吗？',
			onOk: close => {
				this.handledownloadExcel(callback)
				close()
			},
			onCancel: close => {
				close()
			},
		})
	}

	handledownloadExcel = callback => {
		const { downloadExcel } = this.props
		callback() //修改loading状态
		downloadExcel()
			.then(res => {
				callback()
			})
			.catch(err => {
				//console.log(err)
				callback()
			})
	}

	//修改按钮loading状态
	changeLoading = () => {
		this.setState({ exportFaceLoading: !this.state.exportFaceLoading })
	}

	render() {
		const {
			total,
			spinning,
			propIndex,
			size,
			editable,
			current,
			mark,
			sameNameFaceList,
			faceDatas,
			pageKey,
			isPicSearch,
		} = this.state
		const dataLength = faceDatas.length
		const currentData = faceDatas[propIndex]

		return (
			<Fragment>
				<div className='search-face'>
					{editable === false && (
						<Spin tip='搜索中...' spinning={spinning}>
							<div className='search-face-from'>
								<Form
									className='search-face-condition'
									onFinish={values => {
										this.setState({ searchCriteria: values }, () => {
											this.onSearch(values)
										})
									}}
									ref={this.searchformRef}
									layout='inline'>
									<Form.Item>
										<Switch
											checkedChildren='已标识'
											unCheckedChildren='未标识'
											checked={mark}
											// change后回调
											onChange={() => {
												if (mark) {
													// 如果转未标识则清空表单项
													this.onSearch({ size, current: 1 })
													this.searchformRef.current.resetFields()
												} else {
													this.onSearch({ size, current: 1 })
												}
												this.setState({
													mark: !mark,
													searchCriteria: {},
													current: 1,
													pageKey: new Date(),
												})
											}}
										/>
									</Form.Item>
									{/* 姓名 */}
									<Form.Item label='姓名' name='name'>
										<Input />
									</Form.Item>
									{/* 部门 */}
									<Form.Item label='部门' name='department'>
										<Input />
									</Form.Item>
									{/* 职务 */}
									<Form.Item label='职务' name='position'>
										<Input />
									</Form.Item>
									{/* 查询 */}
									<Button
										className='searchImgWrapper submit-btn'
										htmlType='submit'>
										<img
											src={searchImg}
											className='searchImg'
											alt='搜索图标'
											title='查询'
										/>
									</Button>
								</Form>
								<PictureRecognition
									setPictureFaces={this.setPictureFaces}
								/>
							</div>
							<Space>
								<Button
									className='upload-file iconfont-buttons'
									title='导入'
									type='primary'
									onClick={this.showModal}>
									&#xe8c7; 导入
								</Button>
								<Modal
									title='导入'
									visible={this.state.visible}
									onOk={this.handleUpload}
									onCancel={this.handleCancel}>
									<Upload
										accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
										fileList={this.state.fileList}
										beforeUpload={(f, fList) => false}
										onChange={this.handleFileChange}>
										<Button>
											<UploadOutlined /> 选择文件（仅限Excel表格）
										</Button>
									</Upload>
								</Modal>
								<Button
									className='iconfont-buttons'
									title='导出'
									type='primary'
									onClick={() =>
										this.handledownloadExcelModel(this.changeLoading)
									}
									loading={this.state.exportFaceLoading}>
									&#xe8c6; 导出
								</Button>
							</Space>
							{dataLength === 0 && (
								<Empty
									style={{
										padding: '50px 0',
										border: '1px solid #f0f0f0',
									}}
									image={Empty.PRESENTED_IMAGE_SIMPLE}
								/>
							)}
							{dataLength !== 0 && (
								<Fragment>
									<div className='search-face-faces clearfix'>
										{faceDatas.map((item, index) => {
											return (
												<div key={index} className='face-item'>
													<div className='face-content'>
														<div>
															<Image
																className='ant-image-img-search-face'
																src={
																	isPicSearch
																		? item
																				.faceImageUrl[0]
																		: item
																				.faceImageList[0]
																				.faceImageUrl
																}
																preview={false}
															/>
														</div>
														<div
															className='detail'
															title={item.name || '无'}>
															<span className='face-name'>
																姓名：
																{(isPicSearch
																	? item.name
																	: item.name) || ''}
															</span>
															<Button
																type='link'
																className='deleteButton'
																title={
																	!item?.deleteApprove
																		? '该人脸不可删除'
																		: ''
																}
																disabled={
																	!item?.deleteApprove
																}>
																<img
																	className='deleteImg'
																	src={deleteImg}
																	alt='删除图标'
																/>
															</Button>
														</div>
													</div>
												</div>
											)
										})}
									</div>
									<Pagination
										style={{
											margin: '10px 0 20px',
											textAlign: 'center',
										}}
										defaultCurrent={current}
										total={total}
										pageSize={size}
										key={pageKey}
										pageSizeOptions={[32, 40]}
										onChange={(page, pageSize) => {
											this.setState(
												{ current: page, size: pageSize },
												() => {
													if (mark) {
														this.onSearch({
															size: pageSize,
															current: page,
															...this.state.searchCriteria,
														})
													} else {
														this.onSearch({
															size: pageSize,
															current: page,
															mark,
														})
													}
												}
											)
										}}
									/>
								</Fragment>
							)}
						</Spin>
					)}
					{editable === true && (
						<Spin tip='操作中...' spinning={spinning}>
							<FaceData
								uniqueName={'SearchFace'}
								onSubmit={this.onSubmit} // 提交函数
								onBack={this.onBack} // 返回函数
								faceData={currentData} // 数据
								startIndex={propIndex} // 起始index
								sameNameFaceList={sameNameFaceList} // 同名人脸
								getRef={this.getRef} // 暴露ref
								buttons={null} // 底部按钮
								toRefresh={this.toRefresh} // 刷新
							/>
						</Spin>
					)}
				</div>
			</Fragment>
		)
	}
}

export default SearchFace
