import React, { Component } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

export let playerList = []

// 视频播放器
export default class VideoPlayer extends Component {
	componentDidMount() {
		// 实例化 Video.js
		this.player = videojs(this.videoNode, this.props, () => {
			playerList.push(this.player)
		})
	}

	// 卸载时摧毁播放器组件
	componentWillUnmount() {
		if (this.player) {
			this.player.dispose()
		}

		playerList = []
	}

	// 用`data-vjs-player`属性将播放器包装在div中
	// 因此videojs不会在DOM中创建其他包装器
	// 参见https://github.com/videojs/video.js/pull/3856
	render() {
		return (
			<div className='video-container'>
				<div data-vjs-player>
					<video
						ref={node => (this.videoNode = node)}
						className='video-js'
						controlsList='nodownload'></video>
				</div>
			</div>
		)
	}
}
