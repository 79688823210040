import { filesUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'

export const uploadThunk = (multipartFile, options) => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.upload,
			type: 'post',
			data: {
				multipartFile,
			},
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			options,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res) // 此处是故意写成 res 的，因为需要拿到请求时发送的数据
			} else {
				reject(res)
			}
		})
	})

// 下载附件
export const downloadAttachmentThunk = (url, name) => () => {
	_fetch({
		url,
		type: 'get',
		responseType: 'blob',
	}).then(res => {
		const blob = res.data
		const urlObject = window.URL || window.webkitURL || window
		const export_blob = new Blob([blob])
		const a = document.createElement('a') // 利用a标签特性下载
		a.style.display = 'none'
		const url = urlObject.createObjectURL(export_blob)
		a.href = url
		a.setAttribute('download', name)
		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)
	})
}

//附件数据去重
export const removeRepeat = (defaultFileList_before) => {
	const arr_res = []//储存没有重复的函数
	defaultFileList_before.forEach((item) => {
		let isRepeat = false
		for (let i = 0; i < arr_res.length; i++) {
			if (item.uid == arr_res[i].uid) {
				isRepeat = true
			}
		}
		if (item.name.split('.')[1] == "pdf") {
			isRepeat = true
		}
		if (isRepeat == false) {
			arr_res.push(item)
		}
	})
	return arr_res
}