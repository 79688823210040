import React, { Component } from 'react'
import { Menu } from 'antd'

class EPhotoAlbumTheme extends Component {
	state = {
		theme: [],
	}

	render() {
		return (
			<>
				<h3
					style={{ fontWeight: 'bolder' }}
					onClick={this.themeTitleClick}
					title='主题管理'>
					主题管理
				</h3>
				<Menu
				// onClick={this.clickMenuItem}
				// defaultSelectedKeys={this.state.key}
				>
					<Menu.Item key={'0'}>全部</Menu.Item>
					{
						// this.state.theme.map((theme) => {
						//   return (
						//     <Menu.Item
						//       key={theme.themeId}
						//     >
						//       {theme.topic}
						//       <span style={{ fontWeight: 'bold' }}> ({theme.count})</span>
						//     </Menu.Item>
						//   )
						// })
					}
					<Menu.Item key={1}>
						{'今日广工'}
						{/* <span style={{ fontWeight: 'bold' }}> ({theme.count})</span> */}
					</Menu.Item>
					<Menu.Item key={2}>
						{'慈善活动'}
						{/* <span style={{ fontWeight: 'bold' }}> ({theme.count})</span> */}
					</Menu.Item>
				</Menu>
			</>
		)
	}
}

export default EPhotoAlbumTheme
