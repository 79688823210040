import React, { Component } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { approvalBorrowColumns } from '@/Public/PubTable/pageColumns'
import { myApprovalBorrowUrl } from '@/Utils/Urls'
import TableLogModal from './TableLogModal' // 申请日志模态框
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import { connect } from 'react-redux'
import './index.less'
import {
	addBorrowApprovalAction,
	routeChangeAction,
	pushRoutersAction,
	statusEnum,
	delayEnum,
	approvalLogThunk,
	getUseReviewDetailThunk,
} from './ActionCreator'
import { Button } from 'antd'
import { pub_getAllTableData } from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator.js'

const approvalLink = {
	title: '借阅审批详情',
	key: '/workarea/commonComponents/approvalDetails',
}
const tableLogModal = React.createRef(null) // 申请日志详情模态框实例
const mapStateToProps = state => {
	return {
		SelectBorrowFile: state.pubTable.pub_allTableData.SelectBorrowFile, // 获取表单数据
	}
}
const mapDispatchToProps = dispatch => {
	return {
		approvalLog: (...rest) => dispatch(approvalLogThunk(...rest)),
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey.key))
			} else {
				dispatch(routeChangeAction(activeKey.key))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
		},
		pushRouters(link) {
			return dispatch(pushRoutersAction(link))
		},
		addBorrowApproval: data => dispatch(addBorrowApprovalAction(data)), // 添加
		getUseReviewDetail: (...rest) => dispatch(getUseReviewDetailThunk(...rest)), // 查看借阅申请的审批详情
		getAllTableData(...rest) {
			return dispatch(pub_getAllTableData(...rest)) // 把获取到的表格所有数据发送到store
		},
	}
}
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create()
class ApprovalBorrow extends Component {
	formRef = React.createRef()

	searchFormConfig = {
		formItems: [
			{
				type: 'Input',
				name: 'applyUser',
				label: '申请人',
				placeholder: '请输入申请人',
				width: '200px',
			},
			{
				type: 'DatePicker',
				name: 'applyTime',
				label: '申请时间',
				placeholder: '请选择申请时间',
				width: '200px',
				picker: 'year',
			},
			{
				type: 'Select',
				name: 'status',
				label: '处理状态',
				options: statusEnum.toOptions(),
				minWidth: '200px',
				initialValue: -1,
			},
			{
				type: 'Extra',
				name: 'Extra',
				render: formRef => {
					return (
						<Button
							style={{ marginTop: '3px' }}
							className='btn-success iconfont-buttons'
							title='审批日志'
							onClick={this.handleLog}>
							&#xe611; 审批日志
						</Button>
					)
				},
			},
		],
		ajaxConfig: {
			ajaxType: 'post',
			url: myApprovalBorrowUrl.getDepartmentUseApplyList,
		},
	}

	// 完成状态有延期
	searchFormFinishConfig = {
		formItems: [
			{
				type: 'Input',
				name: 'applyUser',
				label: '申请人',
				placeholder: '请输入申请人',
				width: '200px',
			},
			{
				type: 'DatePicker',
				name: 'applyTime',
				label: '申请时间',
				placeholder: '请选择申请时间',
				width: '200px',
				picker: 'year',
			},
			{
				type: 'Select',
				name: 'status',
				label: '处理状态',
				options: statusEnum.toOptions(),
				minWidth: '165px',
				initialValue: 4,
			},
			{
				type: 'Select',
				name: 'delay',
				label: '延期状态',
				options: delayEnum.toOptions(),
				width: '200px',
			},
			{
				type: 'Extra',
				name: 'Extra',
				render: formRef => {
					return (
						<Button className='btn-success' onClick={this.handleLog}>
							审批日志
						</Button>
					)
				},
			},
		],
		ajaxConfig: {
			ajaxType: 'post',
			url: myApprovalBorrowUrl.getDepartmentUseApplyList,
		},
	}

	state = {
		bottomCenter: 'bottomCenter',
		applyUser: '',
		formData: {
			status: '',
		},
		state: 1, // 审批状态
	}
	changeSearchLimit = data => {
		this.setState({ state: data.status })
		if (data.applyUser === undefined) {
			delete data.applyUser
		} else {
			this.setState({
				applyUser: data.applyUser,
			})
		}
		if (data.applyTime === undefined) delete data.applyTime
		return data
	}
	changeTableData = data => {
		data = {
			data: data.records,
			pageSize: data.size,
			current: data.current,
			total: data.total,
		}
		return data
	}
	// 查看审批日志
	handleLog = () => {
		tableLogModal.current.show(formRef => {})
	}

	returnButton = record => {
		return (
			<Button
				onClick={() => {
					this.props.getUseReviewDetail(record.id).then(res => {
						res['id'] = record.id
						this.props.addBorrowApproval(res) // 文件详情传到redux
						this.props.onChange(approvalLink, history) // 更改路由
						if (
							this.props.SelectBorrowFile &&
							this.props.SelectBorrowFile.data?.length
						) {
							// 如果有数据则清空
							let data = {}
							data['data'] = []
							data.pageData = {
								// 配置表格的页码器
								pageSize: data.size || 10,
								current: data.page || 1,
								total: data.total,
							}
							this.props.getAllTableData(data, 'SelectBorrowFile') // 把表格数据发送到store
						}
						this.props.pushRouters(approvalLink) // 添加分页
					})
				}}>
				查看
			</Button>
		)
	}

	render() {
		return (
			<div className='subject-management-subjectsetting'>
				<WrappedTable>
					<SearchForm
						name={'myAppBorrow'}
						formItems={
							this.state.state === 4
								? this.searchFormFinishConfig
								: this.searchFormConfig
						} // 完成状态更改搜索栏
						getInstance={formRef => {
							this.searchFormRef = formRef
						}}
						searchButtonText='查询'
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
					/>
					<BaseTable
						tableConfig={approvalBorrowColumns}
						changeTableData={this.changeTableData}
						operationButton={this.returnButton} // 自定义操作按钮
						changeSearchLimit={this.changeSearchLimit}
						withPreview={false}
					/>
				</WrappedTable>
				<TableLogModal ref={tableLogModal} approvalLog={this.props.approvalLog} />
			</div>
		)
	}
}
export default ApprovalBorrow
