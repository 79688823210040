import React, { Component, Fragment } from 'react'
import {
	Form,
	Input,
	Select,
	TreeSelect,
	Radio,
	Collapse,
	DatePicker,
	Anchor,
	Button,
	Modal,
} from 'antd'
import {
	AimOutlined,
	EditOutlined,
	LoadingOutlined,
	FileImageOutlined,
	VideoCameraOutlined,
} from '@ant-design/icons'
import './index.less'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { connect } from 'react-redux'
import {
	getFondsIdentifierThunk,
	getDossierTypeThunk,
	getDepartmentTreeThunk,
} from './ActionCreators'
import { FileSearchOutlined } from '@ant-design/icons'
import { getConfig } from '@/Utils/ModeTranslate'

moment.locale('zh-cn')

const { Option } = Select
const { Panel } = Collapse
const { Link } = Anchor
const formRef = React.createRef(null) // 表单ref

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	getFondsIdentifier: (...rest) => dispatch(getFondsIdentifierThunk(...rest)), // 获取全宗号
	getDossierType: (...rest) => dispatch(getDossierTypeThunk(...rest)), // 获取类别号
	getDepartmentTree: (...rest) => dispatch(getDepartmentTreeThunk(...rest)), // 获取部门
})

@connect(mapStateToProps, mapDispatchToProps)
class FileEditor extends Component {
	state = {
		recognition: false,
		changeNullValues: {}, //表单被修改为空的字段集合
		changeHasValues: {}, //表单从空修改为有值的字段集合
		sensitiveDetected: {},
	}
	// 添加自定义样式
	setStyle = () => {
		let { height = 580, width = 350, uniqueName, buttons } = this.props
		height = height.toString()
		width = width.toString()
		if (width.replace(/[0-9]/g, '') === '') {
			width = `${width}px`
		}
		if (height.replace(/[0-9]/g, '') === '') {
			height = `${height}px`
		}
		const property = {
			'--height': height,
			'--width': width,
			'--form-height': `calc(${height} - 60px)`,
			'--content-height': buttons ? '88%' : '95%',
			'--buttons-height': buttons ? '12%' : '0',
		}
		const fileEditor = document.querySelector(`.${uniqueName}`)
		for (let i in property) {
			fileEditor.style.setProperty(i, property[i])
		}
	}

	//敏感词探测 在选定的探测字段中，如果有被识别的敏感词那给表单组件增加样式，通过状态sensitiveDetected对象传递检测结果
	sensitiveDetect = () => {
		//敏感词提示
		const detectedDataList = ['topic', 'keyWord'] //探测的字段
		let sensitiveDetected = { topic: false, keyword: false } //默认false
		detectedDataList?.forEach(item => {
			const string = formRef.current.getFieldValue(item)
			//探测条件
			if (string?.match(/\*\*/g)) {
				sensitiveDetected[item] = true
			}
		})
		//同步到状态
		this.setState({ sensitiveDetected })
	}

	async componentDidMount() {
		if (!this.props.getRef) {
			throw Error('FileEditor中，getRef是必传参数')
		}

		await this.props.getRef(formRef, this.stopRecognition)
		this.setStyle()
		this.getModeConfig()
		this.sensitiveDetect()
		/**
		 * 将数据转化为符合antd规范的解构
		 * [{
		 *    title: **,
		 *    value: **,
		 *    chilrden : [{
		 *      title: **,
		 *      value: **,
		 *    }]
		 * }]
		 */
		const { defaultFormValue = [] } = this.props

		formRef.current.setFields(defaultFormValue)
	}

	componentDidUpdate() {
		this.setStyle()
	}

	// 表单提交函数
	setData = values => {
		const fileMetadata = []
		const data = {}
		const { changeNullValues } = this.state

		// 遍历要格式化的数据
		for (let i in values) {
			// i 为数字，则证明是元数据内容
			if (!isNaN(Number(i))) {
				if (values[i]) {
					fileMetadata.push({
						metadataId: Number(i),
						metadataValue:
							Number(i) === 75
								? moment(values[i])
										.format('YYYY-MM-DDTHH:mm:ss')
										.replace(/\.(.*)/, '')
								: values[i],
					})
				}
			}
			// 不是元数据的内容就直接放进请求数组里
			else {
				data[i] = values[i]
			}
		}

		//把修改为空的字段也添加进去
		for (let key in changeNullValues) {
			if (!isNaN(Number(key))) {
				fileMetadata.push({
					metadataId: Number(key),
					metadataValue: changeNullValues[key],
				})
			} else {
				data[key] = changeNullValues[key]
			}
		}

		// 返回格式化完成的数据
		data.metadata = fileMetadata
		return data
	}

	handleCancel = () => {
		this.setState({
			visible: false,
		})
	}

	handleRepeat = e => {
		const { personList } = this.props
		const value = e.target.value

		// 输入框中的人物信息
		let personValues = value.replace(/[\uff0c]/g, ',')
		personValues = personValues.split(',')
		// 人脸识别出的人物信息
		let faceDataList
		if (personList) {
			faceDataList = personList.map(value => {
				return value.name
			})
		} else {
			faceDataList = []
		}
		// 判断是否有重复的人物信息，有的话就提示用户
		let date = personValues.filter(item => faceDataList.indexOf(item) > -1)
		if (date.length > 0) {
			this.setState({
				validateStatus: 'warning',
				help: '人物信息存在重复',
			})
		} else {
			this.setState({
				validateStatus: '',
				help: '',
			})
		}
	}

	isTooLong = e => {
		const target = e.target
		const nodeName = target.nodeName.toLowerCase()
		if (nodeName === 'textarea') {
			if (target.disabled) {
				const inputValue = target.value
				if (inputValue.length > 52) {
					return true
				}
			}
		}
		return false
	}

	stopRecognition = () => {
		this.setState({ recognition: false })
	}

	setTreeData = (data, titleName, valueName, onlyChildren = false) => {
		let treeData = []
		const level = 1 // 层级
		const recursionToTree = (data, level) => {
			let tempObj = {
				title: data[titleName],
				value: data[valueName],
			}

			// 一级类别不允许使用
			if (level === 1) {
				tempObj.disabled = true
			}

			if (data.children != null) {
				if (onlyChildren) {
					tempObj.disabled = true
				}
				tempObj.children = [
					...data.children.map(item => recursionToTree(item, level + 1)),
				]
			}
			return tempObj
		}
		data?.forEach((value, index) => {
			treeData.push(recursionToTree(value, level))
		})
		return treeData
	}

	disabledDate = current => {
		return current && current > moment().endOf('day')
	}

	//收集被清空字段
	onValuesChange = changedValues => {
		if (Object.values(changedValues)[0] === '') {
			this.setState({
				changeNullValues: { ...this.state.changeNullValues, ...changedValues },
			})
		} else {
			this.setState(prev => {
				delete prev.changeNullValues[Object.keys(changedValues)[0]]
				return prev
			})
		}
	}

	//获取提交模式配置
	getModeConfig = () => {
		getConfig().then(Config => {
			this.setState({
				modeConfig: Config.modeConfig,
			})
		})
	}

	render() {
		const {
			disabled = false, // 是否禁用表单，默认为false
			uniqueName, // 表单唯一表示, 必须要
			buttons, // 按钮组
			onFinish, // 表单提交函数
			onValuesChange, // 表单value改变触发的函数
			isFormat = false, // 是否在提交前格式化数据
			others,
			isFaceDetails, // 是否需要人脸编辑
			openFaceDetails, // 人脸框
			closeFaceDetails,
			resetImageFace, //点击人脸识别
			fileType, // 文件类型
			title,
			style = {},
		} = this.props

		const {
			recognition,
			modalTitle,
			visible,
			content,
			validateStatus,
			help,
			modeConfig = {},
			sensitiveDetected = {}, //是否有被探测到敏感词
		} = this.state
		if (!uniqueName) {
			throw Error('FileEditor中，uniqueName是必传参数')
		}

		const fonds = JSON.parse(localStorage.getItem('fonds')),
			dossierType = this.setTreeData(
				JSON.parse(localStorage.getItem('dossierType')),
				'typeName',
				'id',
				true
			),
			department = this.setTreeData(
				JSON.parse(localStorage.getItem('department')),
				'departmentName',
				'id'
			),
			metadata = JSON.parse(localStorage.getItem('metadata'))

		// 生成当前文件类型的元数据
		const thisTypeMetadata = metadata[`${fileType?.split('/')[0]}MetadataStruct`]
		const publicMetadata = metadata.publicMetadataStruct
		let thisNeededMetadata
		if (thisTypeMetadata && publicMetadata) {
			thisNeededMetadata = [...publicMetadata, ...thisTypeMetadata]
		}

		// 判断是否所有人都有姓名
		let isAllHasName = true

		if (isFaceDetails) {
			// 若没有识别则显示蓝色
			if (!typeof isFaceDetails[0] !== 'number') {
				for (let i of isFaceDetails) {
					if (i?.name === null) {
						isAllHasName = false
						break
					}
				}
			}
		}

		// 判断文件是否为视频
		let isVideo = false
		if (fileType?.split('/')[0] === 'video') {
			isVideo = true
		}

		//判断是否在新建著录填写信息页面
		let isNewFiles = window.location.href.split('/').pop().includes('archiveType')
		//在新建著录填写信息页面  && 按卷情况下  不显示 ，其他情况都显示
		let formItemShow = !(isNewFiles && modeConfig['commit_mode_duomeiti'] === 2)

		return (
			<Fragment>
				<Modal
					title={modalTitle}
					visible={visible}
					onCancel={this.handleCancel}
					footer={null}>
					<p>{content}</p>
				</Modal>
				<div
					className={`file-editor ${uniqueName}`}
					onMouseMove={e => {
						if (this.lastTarget !== e.target) {
							if (this.isTooLong(e)) {
								e.target.style.cursor = 'pointer'
							} else {
								if (
									this.lastTarget &&
									this.lastTarget.nodeName.toLowerCase() === 'textarea'
								) {
									this.lastTarget.style.cursor = 'auto'
								}
							}
							this.lastTarget = e.target
						}
					}}
					onClick={e => {
						if (this.isTooLong(e)) {
							this.setState({
								visible: true,
								modalTitle: e.target.dataset.label,
								content: e.target.value,
							})
						}
					}}>
					<div className='information-form' style={{ ...style }}>
						<h3>
							{(() =>
								fileType?.split('/')[0] === 'image' ? (
									<FileImageOutlined className='file-type-icon' />
								) : (
									<VideoCameraOutlined className='file-type-icon' />
								))()}
							{(() => title || '文件信息')()}
						</h3>
						<Form
							onFinish={values => {
								if (onFinish) {
									if (isFormat) {
										values = this.setData(values)
									}
									this.setState({
										help: '',
										validateStatus: '',
									})
									onFinish(values)
								}
							}}
							onValuesChange={changedFields => {
								if (onValuesChange) {
									onValuesChange(changedFields)
								} else {
									this.onValuesChange(changedFields)
								}
							}}
							ref={formRef}
							initialValues={{
								fondsIdentifierId: 1,
								departmentId: 22,
								confidentialLevel: 0,
								retentionPeriod: 1,
							}}>
							<div className='infomation-content'>
								{/* 题名 */}
								<Form.Item
									label='题名'
									name='topic'
									rules={[{ required: true, message: '请输入题名' }]}>
									<Input.TextArea
										style={{ WebkitLineClamp: disabled ? 4 : 'auto' }}
										className={
											sensitiveDetected.topic ? 'detected' : ''
										}
										disabled={disabled}
										placeholder='无'
										bordered={!disabled}
										autoSize={disabled ? { maxRows: 4 } : true}
										data-label='题名'
										maxLength={300}
										showCount={true}
									/>
								</Form.Item>

								{/* 时地人事 */}
								{metadata?.specialMetadataStruct[0]?.child.map(value => {
									switch (value.metadataName) {
										case '时间': {
											return (
												<Form.Item
													label={'摄录时间'}
													name={value.id}
													key={value.id}
													rules={[
														{
															required: true,
															message: '请输入摄录时间',
														},
													]}>
													<DatePicker
														disabled={disabled}
														placeholder='无'
														bordered={!disabled}
														name={value.id}
														disabledDate={this.disabledDate}
														getPopupContainer={triggerNode =>
															triggerNode.parentElement
														}
													/>
												</Form.Item>
											)
										}

										case '人物': {
											return (
												<div className='people' key={value.id}>
													<Form.Item
														label={value.metadataName}
														name={value.id}
														validateStatus={validateStatus}
														help={help}>
														<Input.TextArea
															style={
																isFaceDetails
																	? {
																			paddingRight:
																				'38px',
																			WebkitLineClamp:
																				disabled
																					? 4
																					: 'auto',
																		}
																	: null
															}
															autoSize={
																disabled
																	? { maxRows: 4 }
																	: true
															}
															data-label={
																value.metadataName
															}
															disabled={disabled}
															placeholder='无'
															bordered={!disabled}
															maxLength={1500}
															showCount={true}
															onChange={this.handleRepeat}
														/>
													</Form.Item>

													{isFaceDetails && (
														<div
															className='editor'
															style={{ width: '38px' }}>
															{
																// 如果第一项为beforeFetch，说明还处于请求状态
																isFaceDetails[0] ===
																'beforeFetch' ? (
																	<Button
																		size='small'
																		type='link'
																		disabled={
																			recognition ||
																			isVideo
																		}
																		title={
																			recognition
																				? '人脸识别中'
																				: '人脸识别'
																		}
																		// 点击后人脸识别
																		onClick={() => {
																			closeFaceDetails &&
																				closeFaceDetails()
																			this.setState(
																				{
																					recognition: true,
																				}
																			)
																			resetImageFace()
																				.then(
																					() => {}
																				)
																				.catch(
																					() => {}
																				)
																		}}>
																		{recognition ? (
																			<LoadingOutlined />
																		) : (
																			<FileSearchOutlined />
																		)}
																	</Button>
																) : (
																	<Button
																		size='small'
																		type='link'
																		disabled={
																			!isFaceDetails.length
																		}
																		title={
																			isFaceDetails.length
																				? '查看人脸'
																				: '暂无人脸'
																		}
																		onClick={() => {
																			openFaceDetails &&
																				openFaceDetails()
																		}}>
																		<EditOutlined
																			style={{
																				color:
																					isAllHasName ||
																					'#ff4d4f',
																			}}
																		/>
																	</Button>
																)
															}
														</div>
													)}
												</div>
											)
										}

										default: {
											return (
												<Form.Item
													label={value.metadataName}
													name={value.id}
													key={value.id}>
													<Input.TextArea
														style={{
															WebkitLineClamp: disabled
																? 4
																: 'auto',
														}}
														placeholder='无'
														disabled={disabled}
														bordered={!disabled}
														autoSize={
															disabled
																? { maxRows: 4 }
																: true
														}
														data-label={value.metadataName}
														name={value.id}
														maxLength={1500}
														showCount={true}
													/>
												</Form.Item>
											)
										}
									}
								})}

								{formItemShow && (
									<>
										<Form.Item
											label='全宗'
											name='fondsIdentifierId'
											rules={[
												{
													required: true,
													message: '请输入全宗号',
												},
											]}>
											<Select
												disabled={disabled}
												bordered={!disabled}
												placeholder='无'
												getPopupContainer={triggerNode =>
													triggerNode.parentElement
												}>
												{fonds &&
													fonds.map(value => (
														<Option
															key={value.id}
															value={value.id}>
															{value.name}
														</Option>
													))}
											</Select>
										</Form.Item>

										<Form.Item
											label='类别'
											name='categoryCodeId'
											rules={[
												{
													required: true,
													message: '请选择类别号',
												},
											]}>
											<TreeSelect
												disabled={disabled}
												bordered={!disabled}
												placeholder='无'
												treeData={dossierType}
												treeDefaultExpandAll
												virtual={false}
												getPopupContainer={triggerNode =>
													triggerNode.parentElement
												}
											/>
										</Form.Item>

										<Form.Item
											label='部门'
											name='departmentId'
											rules={[
												{ required: true, message: '请选择部门' },
											]}>
											<TreeSelect
												showSearch
												disabled={disabled}
												bordered={!disabled}
												placeholder='无'
												treeData={department}
												treeDefaultExpandAll
												virtual={false}
												getPopupContainer={triggerNode =>
													triggerNode.parentElement
												}
											/>
										</Form.Item>

										<Form.Item
											label='密级'
											name='confidentialLevel'
											rules={[
												{ required: true, message: '请选择密级' },
											]}>
											<Select
												disabled={disabled}
												bordered={!disabled}
												placeholder='无'
												getPopupContainer={triggerNode =>
													triggerNode.parentElement
												}>
												<Option key={0} value={0}>
													公开
												</Option>
												<Option key={1} value={1}>
													内部
												</Option>
												<Option key={2} value={2}>
													绝密
												</Option>
												<Option key={3} value={3}>
													机密
												</Option>
												<Option key={4} value={4}>
													秘密
												</Option>
											</Select>
										</Form.Item>

										<Form.Item
											label='保管期限'
											name='retentionPeriod'
											rules={[
												{
													required: true,
													message: '请选择保管期限',
												},
											]}
											style={{ marginLeft: '-7px' }}>
											<Radio.Group disabled={disabled}>
												<Radio value={1}>永久</Radio>
												<Radio value={2}>30年</Radio>
												<Radio value={3}>10年</Radio>
											</Radio.Group>
										</Form.Item>
									</>
								)}

								{/* 关键字 */}
								<Form.Item label='关键字' name='keyWord'>
									<Input.TextArea
										style={{ WebkitLineClamp: disabled ? 4 : 'auto' }}
										className={
											sensitiveDetected.keyWord ? 'detected' : ''
										}
										placeholder='无'
										disabled={disabled}
										bordered={!disabled}
										autoSize={disabled ? { maxRows: 4 } : true}
										data-label='关键字'
										name='keyWord'
										maxLength={1500}
										showCount={true}
									/>
								</Form.Item>

								{others?.map((props, index) => {
									const [inputKey, DataKey] = props.keys
									return (
										<div key={index} className='input-with-picker'>
											<Form.Item
												label={props.label}
												name={inputKey}>
												<Input
													bordered={false}
													placeholder='无'
													disabled={true}
												/>
											</Form.Item>

											<Form.Item name={DataKey}>
												<DatePicker
													placeholder='无'
													disabled={true}
													disabledDate={this.disabledDate}
													getPopupContainer={triggerNode =>
														triggerNode.parentElement
													}
												/>
											</Form.Item>
										</div>
									)
								})}

								{/* 元数据 */}
								<Collapse ghost defaultActiveKey={['0']}>
									<Panel header='元数据'>
										<div className='metadata-form'>
											{/* 元数据 */}
											<Form.Item>
												<div id='newfiles-metedata-container'>
													{thisNeededMetadata?.map(value => (
														<div id={value.id} key={value.id}>
															<div className='metadata-name'>
																{value.metadataName}
															</div>
															{value.child.length === 0 ? (
																<Form.Item
																	label={
																		value.metadataName
																	}
																	name={value.id}>
																	<Input.TextArea
																		style={{
																			WebkitLineClamp:
																				disabled
																					? 4
																					: 'auto',
																		}}
																		disabled={
																			disabled
																		}
																		placeholder='无'
																		bordered={
																			!disabled
																		}
																		autoSize={
																			disabled
																				? {
																						maxRows: 4,
																					}
																				: true
																		}
																		data-label={
																			value.metadataName
																		}
																		maxLength={1500}
																		showCount={true}
																	/>
																</Form.Item>
															) : (
																value.child.map(value => (
																	<Form.Item
																		label={
																			value.metadataName
																		}
																		name={value.id}
																		key={value.id}>
																		{
																			// 以时间结尾就使用 DatePicker
																			value.metadataName.indexOf(
																				'时间'
																			) ===
																			Math.max(
																				value
																					.metadataName
																					.length -
																					2,
																				0
																			) ? (
																				<DatePicker
																					disabled={
																						disabled
																					}
																					placeholder='无'
																					bordered={
																						!disabled
																					}
																					name={
																						value.id
																					}
																					disabledDate={
																						this
																							.disabledDate
																					}
																					getPopupContainer={triggerNode =>
																						triggerNode.parentElement
																					}
																				/>
																			) : (
																				<Input.TextArea
																					style={{
																						WebkitLineClamp:
																							disabled
																								? 4
																								: 'auto',
																					}}
																					disabled={
																						disabled
																					}
																					placeholder='无'
																					bordered={
																						!disabled
																					}
																					autoSize={
																						disabled
																							? {
																									maxRows: 4,
																								}
																							: true
																					}
																					data-label={
																						value.metadataName
																					}
																					maxLength={
																						1500
																					}
																					showCount={
																						true
																					}
																				/>
																			)
																		}
																	</Form.Item>
																))
															)}
														</div>
													))}
												</div>
											</Form.Item>
										</div>
									</Panel>
								</Collapse>
							</div>

							{/* 按钮 */}
							<div className='fill-information-btns'>
								<Form.Item>
									{buttons &&
										buttons.map((btn, index) => {
											const { text, ...props } = btn
											if (
												props.isAssembly &&
												props.isAssembly === true
											) {
												return <div key={index}>{text}</div>
											}
											delete props.isAssembly
											return (
												<Button key={index} {...props}>
													{text}
												</Button>
											)
										})}
								</Form.Item>
							</div>
						</Form>
					</div>

					{/* 定位锚点 */}
					<div className='positioning-metadata'>
						<AimOutlined />
						<div className='metadata-content'>
							<Anchor affix={false}>
								{thisNeededMetadata?.map(item => {
									return (
										<Link
											href={`#${item.id}`}
											key={`#${item.id}`}
											title={item.metadataName}
										/>
									)
								})}
							</Anchor>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default FileEditor
