import React, { Component } from 'react'
import APlayer from 'aplayer'
import Wave from './Wave'
import 'aplayer/dist/APlayer.min.css'

export let ap = null
export default class AudioPlayer extends Component {
	state = {
		status: null,
	}

	componentDidMount() {
		const { url, uniqueName } = this.props

		this.options = {
			container: document.getElementById(`aplayer-${uniqueName}`),
			autoplay: false,
			theme: '#FADFA3',
			loop: 'all',
			order: 'random',
			preload: 'auto',
			volume: 0.7,
			mutex: true,
			listFolded: false,
			mini: false,
			audio: [
				{
					url: url,
					name: '',
					artist: '',
					theme: '#ebd0c2',
				},
			],
		}

		this.ap = new APlayer(this.options)

		this.ap.on('play', () => {
			this.setState({ status: 'play' })
		})

		this.ap.on('pause', () => {
			this.setState({ status: 'pause' })
		})
	}

	// 卸载时摧毁播放器组件
	componentWillUnmount() {
		this.ap.pause()
		this.ap = null
	}

	componentDidUpdate() {
		ap = this.ap
	}

	render() {
		const { isThumbnail, uniqueName } = this.props
		const { status } = this.state
		return (
			<div className='audio-player'>
				{!isThumbnail && <Wave status={status} uniqueName={uniqueName} />}
				<div id={`aplayer-${uniqueName}`} className='aplayer'></div>
			</div>
		)
	}
}
