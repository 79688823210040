import _fetch from '@/Utils/Fetch'
import { dossierUrl } from '@/Utils/Urls'

// 新开tap页展示通过fileId请求回来的文件
function openWarcUrl(fileId) {
	return _fetch({
		url: dossierUrl.archivePreview,
		type: 'post',
		headers: {
			'Content-Type': 'application/json',
		},
		data: { fileId },
	}).then(res => {
		if (res && res.data.code === 200) {
			const url = URL.createObjectURL(
				new Blob([res.data.data.html], { type: 'text/html' })
			)
			const a = document.createElement('a')

			a.href = url
			a.target = '_blank'

			a.click()
		}
	})
}

export { openWarcUrl }
