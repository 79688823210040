import React, { PureComponent } from 'react'
import { Table, Input, Popconfirm, Form, Button, Space } from 'antd'
import { injectUnmount } from '@/Utils'
import _fetch from '@/Utils/Fetch'
// 编辑时触发的函数
const EditableCell = ({
	editing, // 是否编辑中
	dataIndex, // 字段名
	title, // 表头名称
	// inputType,  // 输入类型
	record,
	index,
	children,
	...restProps
}) => {
	// 编辑内容节点，如果是数字则使用 InputNumber 组件
	// const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
	return (
		<td {...restProps}>
			{
				// 判断是否处于编辑状态，如果处于编辑状态则显示可输入组件，否则显示原来的内容
				editing ? (
					<Form.Item
						name={dataIndex}
						style={{ margin: 0 }}
						rules={[
							{
								required: true,
								message: `请输入${title}!`,
							},
						]}>
						<Input />
					</Form.Item>
				) : (
					children
				)
			}
		</td>
	)
}

// 创建一个表格项
export const createTable = (config, formRef) => {
	const {
		recordKeyName = 'id', // 表格数据中要作为 key 的字段，必须是每行数据唯一，默认为 id
		deleteCallback, // 删除表格行时的回调
		saveCallback, // 保存编辑后表格行时的回调
		noRowSelection, // 是否保留checkbox
	} = config
	if (!config.columns) throw new Error('Table 组件的 colums 属性是必传的')
	class FormTable extends PureComponent {
		formTableRef = React.createRef()
		state = {
			editingKey: '', // 当前正在编辑的行的key
		}

		// 多选表格项触发的函数
		onSelectChange = (selectedRowKeys = []) => {
			// //console.log('selectedRowKeys changed: ', selectedRowKeys);
			this.triggerChange({ selectedRowKeys })
		}

		// 表格分页、排序、筛选变化时触发
		onDataChange = (pagination, filters, sorter, extra) => {
			// 获取表格现在所有页的数据源总和
			const { currentDataSource: dataSource } = extra
			this.triggerChange({ dataSource })
		}

		/**
		 * 遵循 antd 自定义组件的要求，
		 * 需要将修改后的 value 传入 antd 的 FormItem 所赋予的 onChange 事件中，得以被管理
		 * @param {any} changedValue 修改后的表单项的值
		 */
		triggerChange = changedValue => {
			let { onChange, value } = this.props
			if (onChange) {
				onChange({
					...value,
					...changedValue,
				})
			}
		}

		// 将传入的列表数据做处理后返回给 Table
		getMergedColumns = () => {
			// 是否需要内置操作列，默认为 true
			const { isNeedInternalOperation = true } = config
			const { disabled } = this.props // 是否是禁用状态
			const { editingKey } = this.state // 正在编辑的行数据标识
			// 暴露给外部可用的方法
			let tableFuncs = {
				delete: this.delete,
			}
			let columns = [...config.columns] // 保护源数据
			// 记录操作列在 columns 的下标值，如果没有则是 -1
			let operationIndex = columns.findIndex(item => item.dataIndex === 'operation')
			// 对非操作列添加可编辑选项，并滤除自定义操作列
			let filterColumns = columns.filter((col, index) => {
				index !== operationIndex && (col.editable = true)
				return index !== operationIndex
			})
			/**
			 * 操作列的渲染有四种情况
			 * isNeedInternalOperation  operationIndex   操作
			 * true                     >   -1           在内置的基础上合并传入的 operation 内容
			 * true                     === -1           使用内置的操作列
			 * false                    >   -1           使用传入的操作列
			 * false                    === -1           无操作列
			 */
			if (!isNeedInternalOperation) {
				if (operationIndex === -1) return config.columns // 无操作列
				// 为外部 render 方法传入多两个参数，并使用外部的操作列
				filterColumns.push({
					title: columns[operationIndex].title,
					dataIndex: 'operation',
					render: (text, record, index) => {
						return columns[operationIndex].render(
							text,
							record,
							index,
							formRef,
							tableFuncs
						)
					},
				})
			} else {
				// 将外部操作列与内置操作列合并起来
				filterColumns.push({
					title: operationIndex > -1 ? columns[operationIndex].title : '操作',
					dataIndex: 'operation',
					render: (text, record, index) => {
						// 判断当行是否编辑中
						const editable = this.isEditing(record)
						// 如果正在编辑中则渲染保存和取消按钮，否则显示编辑按钮
						return editable ? (
							<Space>
								<Button
									className='iconfont-buttons'
									title='保存'
									onClick={() => this.save(record.key)}>
									&#xec09; 保存
								</Button>
								<Popconfirm
									className='iconfont-buttons'
									title='确定取消吗?'
									onConfirm={this.cancel}>
									<Button>&#xe619; 取消</Button>
								</Popconfirm>
							</Space>
						) : (
							<Space>
								<Button
									className='iconfont-buttons'
									title='编辑'
									onClick={() => this.edit(record)}
									disabled={disabled || editingKey !== ''}>
									&#xeabd; 编辑
								</Button>
								<Popconfirm
									okButtonProps={{
										danger: true,
										type: 'primary',
									}}
									disabled={disabled}
									title='确定删除吗?'
									onConfirm={() => {
										this.delete(record)
									}}>
									<Button
										className='iconfont-buttons'
										title='删除'
										disabled={disabled || editingKey !== ''}>
										删除
									</Button>
								</Popconfirm>
								{
									// 如果传入操作列
									operationIndex !== -1
										? React.cloneElement(
												columns[operationIndex].render(
													text,
													record,
													index,
													formRef,
													tableFuncs
												),
												{
													disabled,
												}
											)
										: null
								}
							</Space>
						)
					},
				})
			}

			return filterColumns.map(col => {
				// 如果非编辑则正常显示
				if (!col.editable) {
					return col
				}

				return {
					...col,
					// 为每个单元格设置属性
					onCell: record => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						editing: this.isEditing(record),
					}),
				}
			})
		}

		/**
		 * 判断某一行是否正在编辑
		 * @param {Object} record 当前行的数据源
		 * @returns {Boolean} 该行是否正在编辑
		 */
		isEditing = record => record.key === this.state.editingKey

		/**
		 * 点击编辑按钮触发的函数
		 * @param {Object} record 当前行的数据源
		 */
		edit = record => {
			// //console.log(record)
			// 编辑表单项的初始数据对象
			let initValues = {}
			// 初始化表单数据对象，根据表头循环添加
			;[...config.columns].forEach(col => {
				initValues[col.dataIndex] = ''
			})
			this.formTableRef.current.setFieldsValue({
				...initValues,
				...record,
			})
			// 更新正在编辑的行的 key
			this.setState({ editingKey: record.key })
		}

		/**
		 * 点击删除按钮时触发
		 * @param {Object} record 当前行的数据源
		 */
		delete = record => {
			const { value } = this.props
			// //console.log(record)
			deleteCallback && deleteCallback(record)
			const newData = value.dataSource.filter(val => val.key !== record.key)
			this.triggerChange({ dataSource: newData })
		}

		// 取消编辑则清空正在编辑的 key
		cancel = () => this.setState({ editingKey: '' })

		/**
		 * 对于正在编辑的行点击保存按钮触发
		 * @param {any} key 该行的 key 值
		 */
		save = async key => {
			const { value } = this.props
			try {
				// 校验成功则继续，否则报错被 catch
				const row = await this.formTableRef.current.validateFields()
				// 浅复制
				const newData = [...(value ? value.dataSource : [])]
				// 找到原数据中被编辑的行
				const index = newData.findIndex(item => key === item.key)
				// 如果存在该行
				if (index > -1) {
					// 获取对应那一行的数据
					const item = newData[index]
					// 删除后插入,即更新该行数据
					// item: { key: xxx,  name : xxx, age: xxx, address: xxx }
					// row: { name : xxx, age: xxx, address: xxx }
					const newItem = { ...item, ...row }
					saveCallback && saveCallback(newItem)
					// 将修改后数据覆盖掉原数据
					newData.splice(index, 1, newItem)
				} else {
					// 如果不存在则添加
					newData.push(row)
				}
				// 撤销编辑状态
				this.setState({ editingKey: '' })
				// 编辑成功后更新数据源
				this.triggerChange({ dataSource: newData })
			} catch (errInfo) {
				//console.log('表单验证失败:', errInfo)
			}
		}

		render() {
			// const { selectedRowKeys } = this.state;
			const { value = {} } = this.props
			if (!value.selectedRowKeys) value.selectedRowKeys = []
			if (!value.dataSource) value.dataSource = []
			const { selectedRowKeys, dataSource } = value
			// 表格行的配置项
			const rowSelection = noRowSelection
				? false
				: {
						selectedRowKeys, // 选中项的 key 数组
						onChange: this.onSelectChange, // 选中项发生变化时的回调
					}

			return (
				<Form ref={this.formTableRef} component={false}>
					<Table
						rowSelection={rowSelection}
						// 覆盖默认的 table 单元格
						components={{
							body: {
								cell: EditableCell,
							},
						}}
						bordered
						dataSource={dataSource}
						columns={this.getMergedColumns()}
						rowClassName={record => {
							let className = 'editable-row'
							// 此处用于用户管理的授权模态框
							if (record['status'] === true) {
								className = 'editable-row own-role'
							}
							return className
						}}
						pagination={{
							onChange: this.cancel,
							showSizeChanger: false,
						}}
						// 行组件的key，一样根据指定的唯一字段标志设置即可
						rowKey={record => record[recordKeyName]}
						onRow={record => {
							// 根据指定的唯一字段标志为每一行的数据添加一个 key 字段
							record.key = record[recordKeyName]
						}}
						onChange={this.onDataChange}
					/>
				</Form>
			)
		}
	}
	return <FormTable />
}

// 创建一个异步表格
export const createAsyncTable = (config, formRef) => {
	const {
		columns,
		recordKeyName = 'id', // 表格数据中要作为 key 的字段，必须是每行数据唯一，默认为 id
		ajaxConfig, // 请求配置
		changeSearchLimit, // 修改发送请求的数据
		formatDataFunc, // 格式化获取到的表格数据
		asyncTableRef, // 用于操控该异步表格的 Ref
	} = config
	@injectUnmount
	class AsyncTable extends PureComponent {
		state = {
			dataSource: [], // 表格源数据
			pagination: {
				// 分页器配置
				current: 1,
				pageSize: 10,
			},
			loading: false, // 表格加载状态
		}

		componentDidMount() {
			const { pagination } = this.state
			this.fetch(pagination)
		}

		// 换页触发
		handleTableChange = pagination => {
			this.fetch(pagination)
		}

		// 发送请求的主函数
		fetch = async (
			pagination = {
				current: 1,
				pageSize: 10,
			}
		) => {
			this.setState({ loading: true })
			// 复制一份不污染源数据，根据后台约定格式化 pageSize → size
			let fetchData = {
				current: pagination['current'],
				size: pagination['pageSize'],
			}
			// 如果有 changeSearchLimit 则先改变请求数据后再发送
			ajaxConfig['data'] = changeSearchLimit
				? await changeSearchLimit(fetchData)
				: fetchData
			try {
				const res = await _fetch(ajaxConfig)
				if (res && res.data.code === 200) {
					let data = res.data.data
					let records = data['records']
					// 只暴露外部 records 的数据进行格式化，size/current/total 固定没必要
					data['records'] = formatDataFunc
						? await formatDataFunc(records)
						: records
					this.setState({
						loading: false,
						dataSource: data['records'],
						pagination: {
							...pagination,
							total: data['total'],
						},
					})
				}
			} catch {
				//console.log('错误')
				this.setState({
					loading: false,
				})
			}
		}

		render() {
			const { loading, dataSource, pagination } = this.state
			return (
				<Table
					bordered
					columns={columns}
					loading={loading}
					dataSource={dataSource}
					pagination={{
						...pagination,
						showSizeChanger: false, // 不显示 pageSize 切换器
					}}
					onChange={this.handleTableChange}
					// 行组件的key，一样根据指定的唯一字段标志设置即可
					rowKey={record => record[recordKeyName]}
					onRow={record => {
						// 根据指定的唯一字段标志为每一行的数据添加一个 key 字段
						record.key = record[recordKeyName]
					}}
				/>
			)
		}
	}
	return <AsyncTable ref={asyncTableRef} />
}

/* export default {
  createTable,
  createAsyncTable
}
 */
