import initialState from '../../InitialState'
import { ROUTE_CHANGE, TAB_ADD, TAB_ALLCLEAR, TAB_REMOVE } from '../../ActionTypes/Route'
import { homePath } from '@/Components/Login/LoginForm'

// TAB_ADD的items数组增添去重函数
function TabAddFilter(items, filter) {
	let copyItems = [...items]
	if (!copyItems[0]) {
		copyItems.push(filter)
	} else {
		copyItems.every(item => {
			return item.key !== filter.key
		}) && copyItems.push(filter)
	}
	return copyItems
}

// TAB_REMOVE剔除targetKey操作的函数
function TabRemoveFilter(items, filter) {
	let newItems = items.filter(item => {
		return item.key !== filter
	})
	!newItems[0] && newItems.push({ title: homePath.name, key: homePath.url })
	return newItems
}
// tab栏改变的时候
function TabChangeFilter(items, filter) {
	if (typeof filter === 'string') {
		return filter
	} else {
		return filter.url
	}
}

// 清空tab栏
function TabAllClear(items, filter) {
	if (filter) {
		let newItems = items.filter(item => {
			return item.key === filter
		})
		return newItems
	} else {
		return []
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState.route, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case ROUTE_CHANGE:
			return {
				...state,
				item: TabChangeFilter(state.item, action.filter),
			}
		case TAB_REMOVE:
			return {
				...state,
				items: TabRemoveFilter(state.items, action.filter),
			}
		case TAB_ADD:
			return {
				...state,
				items: TabAddFilter(state.items, action.filter),
			}
		case TAB_ALLCLEAR:
			return {
				...state,
				items: TabAllClear(state.items, action.filter),
			}
		default:
			return state
	}
}
