import { PUBFILE_ADD } from '@/Redux/ActionTypes/Public/PubForm'
import _fetch from '@/Utils/Fetch'
import { archiveUrl, filesUrl, recordFileUrl, mapUrl } from '@/Utils/Urls'

import { message } from 'antd'

export const addPubFileAction = data => {
	return {
		type: PUBFILE_ADD,
		data: data,
	}
}

/**
 * 获取案卷或档案（文件）详情
 * @param {Object} record 案卷或档案（文件）的信息
 */
export const getDetailThunk = record => () =>
	new Promise(async (resolve, reject) => {
		const { id, type, archiveType } = record

		let url =
			type === 1 && archiveType === 1
				? archiveUrl.getArchiveDetail
				: archiveUrl.getDocDetail
		let res = await _fetch({
			url,
			data: {
				id: id,
			},
		})
		if (res && res.data.code === 200) {
			resolve({
				...res.data.data,
				type: record.type,
			})
		} else {
			reject()
		}
	})

/**
 * 上传文件
 * @param {Object[]} record 案卷或档案（文件）的内容
 */
export const uploadFileThunk = formData => () =>
	new Promise((resolve, reject) => {
		_fetch({
			type: 'post',
			url: filesUrl.upload,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('上传成功')
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 著录编辑
 * @param {object} data 申请数据
 */
export const updateRecordThunk = data => () =>
	new Promise(resolve => {
		// 对档案请求编辑
		_fetch({
			url: recordFileUrl.updateArchiveDetail,
			isJson: true,
			data,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('编辑成功')
				resolve()
			}
		})
	})

// 人脸识别
export const faceRecognitionThunk = fileId => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.faceRecognition,
			type: 'get',
			data: {
				fileId,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 更新附件
export const updateAttachmentThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.updateAttachment,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 建立人脸信息和档案的关系
export const replaceArchiveFacesThunk = (recognitionUserIdList, archiveId) => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.replaceArchiveFaces,
			type: 'post',
			data: {
				recognitionUserIdList,
				archiveId,
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})
