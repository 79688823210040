import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	queryDayCountThunk,
	queryCountByTypeThunk,
	queryIncreaseThunk,
	queryCountThunk,
	queryUseCountThunk,
	getIncreaseCapacityThunk,
} from './ActionCreators'
import './index.less'
import * as echarts from 'echarts/core'
import { Statistic, Switch } from 'antd'
import moment from 'moment'
import {
	TooltipComponent,
	LegendComponent,
	GridComponent,
	TitleComponent,
	ToolboxComponent,
	DataZoomComponent,
} from 'echarts/components'
import { BarChart, PieChart } from 'echarts/charts'
import { SVGRenderer } from 'echarts/renderers'

// 图表基础配置
let chart = {
	// 图表1，类型饼图
	typeChart: {
		// 标题
		title: [
			{
				text: '各类型案卷、文件数量',
				left: 'center',
			},
			{
				subtext: '案卷',
				left: '25%',
				bottom: '5%',
				textAlign: 'center',
				subtextStyle: {
					fontSize: 20,
				},
			},
			{
				subtext: '文件',
				left: '75%',
				bottom: '5%',
				textAlign: 'center',
				subtextStyle: {
					fontSize: 20,
				},
			},
		],
		// 鼠标放上去渲染
		tooltip: {
			trigger: 'item',
			formatter: '{a} <br/>{b} ({d}%)',
		},
		// 数据
		series: [
			// 第二个饼图
			{
				name: '第一级类型文件',
				type: 'pie',
				radius: [0, '40%'],
				label: {
					position: 'inner',
					fontSize: 14,
					color: '#fff',
				},
				itemStyle: {
					normal: {
						borderColor: '#fff',
						borderWidth: 1,
					},
				},
				center: ['75%', '50%'],
				data: [],
			},
			{
				name: '第二级类型文件',
				type: 'pie',
				radius: ['50%', '60%'],
				labelLine: {
					position: 'inner',
					length: 20,
				},
				center: ['75%', '50%'],
				data: [],
			},
			// 第一个饼图
			{
				name: '第一级类型案卷',
				type: 'pie',
				radius: [0, '40%'],
				label: {
					position: 'inner',
					color: '#fff',
					fontSize: 14,
				},
				center: ['25%', '50%'],
				itemStyle: {
					normal: {
						borderColor: '#fff',
						borderWidth: 1,
					},
				},
				data: [],
			},
			{
				name: '第二级类型案卷',
				type: 'pie',
				radius: ['50%', '60%'],
				labelLine: {
					position: 'inner',
					length: 20,
				},
				center: ['25%', '50%'],
				data: [],
			},
		],
		animationDuration: 500,
	},
	// 图表二，横向柱状图
	ybarChart: {
		title: {
			text: '近一年新增案卷、文件数量',
			left: 'center',
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
			},
		},
		legend: {
			data: ['案卷', '文件'],
			right: 40,
			top: 20,
		},
		xAxis: {
			type: 'value',
		},
		yAxis: {
			type: 'category',
			data: [],
		},
		series: [
			{
				name: '案卷',
				type: 'bar',
				stack: 'total',
				label: {
					show: true,
				},
				emphasis: {
					focus: 'series',
				},
				data: [],
			},
			{
				name: '文件',
				type: 'bar',
				stack: 'total',
				label: {
					show: true,
				},
				emphasis: {
					focus: 'series',
				},
				data: [],
			},
		],
		animationDuration: 500,
	},
	// 图表三 并列柱状图
	barChart: {
		title: {
			text: '近一年案卷、文件数量',
			left: 'center',
		},
		tooltip: {
			trigger: 'axis',
		},
		legend: {
			data: ['案卷', '文件'],
			right: 40,
			top: 20,
		},
		dataZoom: [
			{
				type: 'inside',
			},
		],
		xAxis: {
			type: 'category',
			boundaryGap: false,
			data: [],
		},
		yAxis: {
			type: 'value',
		},
		series: [
			{
				name: '案卷',
				type: 'bar',
				data: [],
				progressive: 40000,
			},
			{
				name: '文件',
				type: 'bar',
				data: [],
				progressive: 40000,
			},
		],
		animationDuration: 500,
	},
	// 图表四，柱状图
	xbarChart: {
		title: {
			text: '近一年借阅案卷、文件数量',
			left: 'center',
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
			},
		},
		legend: {
			right: 40,
			top: 20,
			data: ['案卷', '文件'],
		},
		xAxis: {
			type: 'category',
			data: [],
		},
		yAxis: {
			type: 'value',
		},
		dataZoom: [
			{
				type: 'inside',
			},
		],
		series: [
			{
				name: '案卷',
				type: 'bar',
				stack: 'total',
				emphasis: {
					focus: 'series',
				},
				barCategoryGap: '0.5%' /*多个并排柱子设置柱子之间的间距*/,
				data: [],
				progressive: 400,
			},
			{
				name: '文件',
				type: 'bar',
				stack: 'total',
				emphasis: {
					focus: 'series',
				},
				data: [],
				progressive: 400,
			},
		],
		animationDuration: 500,
	},
	// 图表二-二，横向柱状图
	ybarCapacitChart: {
		title: {
			text: '最近一年每月使用容量',
			left: 'center',
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
			},
		},
		legend: {
			data: ['使用容量'],
			right: 40,
			top: 20,
		},
		xAxis: {
			type: 'value',
		},
		yAxis: {
			type: 'category',
			data: [],
		},
		series: [
			{
				name: '使用容量',
				type: 'bar',
				stack: 'total',
				label: {
					show: true,
				},
				emphasis: {
					focus: 'series',
				},
				data: [],
			},
			{ data: [] },
		],
		animationDuration: 500,
	},
}

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	queryDayCount: () => dispatch(queryDayCountThunk()), //获取当日文件，案卷信息[顶部]
	queryCountByType: () => dispatch(queryCountByTypeThunk()), // 获取各个类型的文件的数据 [图表一]
	queryIncrease: () => dispatch(queryIncreaseThunk()), // 获取最近12个月新增文件和案卷数据 [图标二]
	queryCount: () => dispatch(queryCountThunk()), // 获取过去一年文件、案卷（折线图）[图表三]
	queryUseCount: () => dispatch(queryUseCountThunk()), // 获取过去一年借阅案卷、文件数量 [图表四]
	getIncreaseCapacity: () => dispatch(getIncreaseCapacityThunk()), // 获取过去一年新增容量 [图表二-二]
})
@connect(mapStateToProps, mapDispatchToProps)
class Statistics extends Component {
	state = {
		total: false,
		switchState: true,
		switchDisabled: false,
	}

	/**
	 * @param {Object} options 图标数据
	 * @param {String} id 图表id名
	 * @param {Array} arr 图表配置数组
	 */
	setChart = (option, id, arr) => {
		echarts?.use(arr)
		let chartDom = document.getElementById(id)
		let myChart = echarts?.init(chartDom)
		option && myChart.setOption(option)
		// 随浏览器大小调整
		window.addEventListener('resize', function () {
			myChart.resize()
		})
	}

	// 处理类型状态数据
	setTypeData = res => {
		if (!res[0]) return []
		const obj = chart.typeChart

		const typedata = {
			dossierCount: {
				firstArr: [],
				secondArr: [],
			},
			archiveCount: {
				firstArr: [],
				secondArr: [],
			},
		}
		// 两层遍历数据处理
		res.forEach(item => {
			// 都为零跳出循环
			if (item.dossierCount === 0 && item.archiveCount === 0) return true
			// 不为零才添加数据
			if (item.dossierCount !== 0) {
				typedata.dossierCount.firstArr.push({
					value: item.dossierCount,
					name: item.typeName + '：' + item.dossierCount,
				})
			}
			if (item.archiveCount !== 0) {
				typedata.archiveCount.firstArr.push({
					value: item.archiveCount,
					name: item.typeName + '：' + item.archiveCount,
				})
			}
			item?.children?.forEach(childrenItem => {
				if (childrenItem.dossierCount !== 0) {
					typedata.dossierCount.secondArr.push({
						value: childrenItem.dossierCount,
						name: childrenItem.typeName + '：' + childrenItem.dossierCount,
					})
				}
				if (childrenItem.archiveCount !== 0) {
					typedata.archiveCount.secondArr.push({
						value: childrenItem.archiveCount,
						name: childrenItem.typeName + '：' + childrenItem.archiveCount,
					})
				}
			})
		})

		// 第一个饼图
		obj.series[2].data = typedata.dossierCount.firstArr
		obj.series[3].data = typedata.dossierCount.secondArr
		// 第二个饼图
		obj.series[0].data = typedata.archiveCount.firstArr
		obj.series[1].data = typedata.archiveCount.secondArr
	}

	// 处理请求月数数据
	setMonthData = (res, obj) => {
		if (!res[0]?.time) return []
		const monthData = {
			firstArr: [],
			secondArr: [],
			monthArr: [],
		}
		// 遍历处理数据
		res.forEach(item => {
			const arr = item.time.split('-')
			monthData.firstArr.push(item.dossierCount)
			monthData.secondArr.push(item.archiveCount)
			monthData.monthArr.push(arr[0] + '/' + arr[1])
		})

		obj.yAxis.data = monthData.monthArr
		obj.series[0].data = monthData.firstArr
		obj.series[1].data = monthData.secondArr
	}

	// 处理周数据
	setWeekData = (res, obj) => {
		if (!res[0]?.time) return []
		const weekData = {
			firstArr: [],
			secondArr: [],
			weekArr: [],
		}

		let day = new Date(moment(res[0].time).toDate() - 24 * 3600 * 1000 * 7)

		// 遍历处理数据
		res.forEach(item => {
			let now = moment(item.time).toDate()
			let last = day
			day = now
			weekData.firstArr.push(item.dossierCount)
			weekData.secondArr.push(item.archiveCount)
			weekData.weekArr.push(
				[last.getMonth() + 1, last.getDate()].join('/') +
					'~' +
					[now.getMonth() + 1, now.getDate()].join('/')
			)
		})

		obj.xAxis.data = weekData.weekArr
		obj.series[0].data = weekData.firstArr
		obj.series[1].data = weekData.secondArr
	}

	componentDidMount() {
		// 图表初始化
		this.setChart(chart.typeChart, 'chart-typeChart', [
			TitleComponent,
			TooltipComponent,
			LegendComponent,
			PieChart,
			SVGRenderer,
		])

		this.setChart(chart.ybarChart, 'chart-ybarChart', [
			TooltipComponent,
			TitleComponent,
			LegendComponent,
			GridComponent,
			BarChart,
			SVGRenderer,
		])
		this.setChart(chart.barChart, 'chart-barChart', [
			TitleComponent,
			ToolboxComponent,
			TooltipComponent,
			BarChart,
			GridComponent,
			DataZoomComponent,
			LegendComponent,
			SVGRenderer,
		])

		this.setChart(chart.xbarChart, 'chart-xbarChart', [
			TooltipComponent,
			LegendComponent,
			TitleComponent,
			GridComponent,
			DataZoomComponent,
			BarChart,
			SVGRenderer,
		])

		// 总数数据
		this.props
			.queryDayCount()
			.then(res => {
				this.setState({ total: { ...res } })
			})
			.catch(() => {
				this.setState({
					total: {
						archiveCount: '网',
						dossierCount: '络',
						archiveIncrease: '错',
						dossierIncrease: '误',
					},
				})
			})
	}

	componentWillUpdate() {
		if (!this.state.total) {
			// 请求更新数据
			const {
				queryCountByType,
				queryIncrease,
				queryCount,
				queryUseCount,
				getIncreaseCapacity,
			} = this.props

			// 请求处理类型数据
			queryCountByType().then(res => {
				this.setTypeData(res)
				document.getElementById('chart-typeChart') &&
					echarts
						?.init(document.getElementById('chart-typeChart'))
						.setOption(chart.typeChart)
			})

			// 请求处理月份数据
			queryIncrease().then(res => {
				this.setMonthData(res, chart.ybarChart)
				document.getElementById('chart-ybarChart') &&
					echarts
						?.init(document.getElementById('chart-ybarChart'))
						.setOption(chart.ybarChart)
			})

			// 请求处理借阅统计数据
			queryCount().then(res => {
				this.setWeekData(res, chart.barChart)
				document.getElementById('chart-barChart') &&
					echarts
						?.init(document.getElementById('chart-barChart'))
						.setOption(chart.barChart)
			})

			// 请求处理借阅统计数据
			queryUseCount().then(res => {
				this.setWeekData(res, chart.xbarChart)
				document.getElementById('chart-xbarChart') &&
					echarts
						?.init(document.getElementById('chart-xbarChart'))
						.setOption(chart.xbarChart)
			})

			// 请求处理容量数据
			getIncreaseCapacity().then(res => {
				if (!res[0]?.time) return []
				const data = {
					yArr: [],
					xArr: [],
				}
				// 遍历处理数据
				res.forEach(item => {
					const arr = item.time.split('-')
					data.xArr.push(item.IncreaseCapacity)
					data.yArr.push(arr[0] + '/' + arr[1])
				})

				chart.ybarCapacitChart.yAxis.data = data.yArr
				chart.ybarCapacitChart.series[0].data = data.xArr
			})
		}
	}

	getCapacity = () => {
		if (!this.state.switchState) {
			// 设置，请求，再设置
			echarts
				?.init(document.getElementById('chart-ybarChart'))
				.setOption(chart.ybarCapacitChart)
			this.props.getIncreaseCapacity().then(res => {
				if (!res[0]?.time) return []
				const data = {
					yArr: [],
					xArr: [],
				}
				// 遍历处理数据
				res.forEach(item => {
					const arr = item.time.split('-')
					// b转mb
					data.xArr.push(parseInt(item.increaseCapacity / 1024 / 1024))
					data.yArr.push(arr[0] + '/' + arr[1])
				})
				chart.ybarCapacitChart.yAxis.data = data.yArr
				chart.ybarCapacitChart.series[0].data = data.xArr
				echarts
					?.init(document.getElementById('chart-ybarChart'))
					.setOption(chart.ybarCapacitChart)
			})
		} else {
			// 设置，请求，再设置
			echarts
				?.init(document.getElementById('chart-ybarChart'))
				.setOption(chart.ybarChart)
			// 请求处理月份数据
			this.props.queryIncrease().then(res => {
				this.setMonthData(res, chart.ybarChart)
				echarts
					?.init(document.getElementById('chart-ybarChart'))
					.setOption(chart.ybarChart)
			})
		}
	}

	render() {
		const {
			// 档案数量
			archiveTotal = 0,
			archiveMonthCount = 0,
			archiveDayCount = 0,
			// 案卷数量
			dossierTotal = 0,
			dossierMonthCount = 0,
			dossierDayCount = 0,
			// 容量
			capacityTotal = 0,
			capacityMonth = 0,
			capacityDay = 0,
			// 借阅量
			archiveUseApplyTotal = 0,
			archiveUseApplyMonthCount = 0,
			archiveUseApplyDayCount = 0,
		} = this.state.total

		return (
			<div className='chart-box'>
				<div className='title-box'>
					<div
						title={`今日：${dossierDayCount}；本月：${dossierMonthCount}；总计：${dossierTotal}`}>
						<Statistic
							title=''
							value={
								'案卷：' +
								'今日' +
								dossierDayCount +
								'/' +
								'本月' +
								dossierMonthCount +
								'/' +
								'总计' +
								dossierTotal
							}
						/>
					</div>
					<div
						title={`今日：${archiveDayCount}；本月：${archiveMonthCount}；总计：${archiveTotal}`}>
						<Statistic
							title=''
							value={
								'文件：' +
								'今日' +
								archiveDayCount +
								'/' +
								'本月' +
								archiveMonthCount +
								'/' +
								'总计' +
								archiveTotal
							}
						/>
					</div>
					<div
						title={`今日：${capacityDay}；本月：${capacityMonth}；总计：${capacityTotal}`}>
						<Statistic
							title=''
							value={
								'容量：' +
								'今日' +
								capacityDay +
								'/' +
								'本月' +
								capacityMonth +
								'/' +
								'总计' +
								capacityTotal
							}
						/>
					</div>
					<div
						title={`今日：${archiveUseApplyDayCount}；本月：${archiveUseApplyMonthCount}；总计：${archiveUseApplyTotal}`}>
						<Statistic
							title=''
							value={
								'借阅：' +
								'今日' +
								archiveUseApplyDayCount +
								'/' +
								'本月' +
								archiveUseApplyMonthCount +
								'/' +
								'总计' +
								archiveUseApplyTotal
							}
						/>
					</div>
				</div>
				<Switch
					className='chart-switch'
					checkedChildren='数量'
					unCheckedChildren='容量'
					disabled={this.state.switchDisabled}
					onClick={() =>
						this.setState(
							{ switchDisabled: true },
							//  小防抖
							() =>
								setTimeout(() => {
									this.setState({ switchDisabled: false })
								}, 800)
						)
					}
					// change后回调
					onChange={() =>
						this.setState(
							{ switchState: !this.state.switchState },
							// 更新图表
							() => this.getCapacity()
						)
					}
					defaultChecked
				/>
				<div id='chart-typeChart' className='chart'></div>
				<div id='chart-ybarChart' className='chart'></div>
				<div id='chart-barChart' className='chart'></div>
				<div id='chart-xbarChart' className='chart'></div>
			</div>
		)
	}
}

export default Statistics
