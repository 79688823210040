import Enum from '@/Utils/Enum'
import {
	ADD_BORROW_APPROVAL, // 添加修正表详情
} from '@/Redux/ActionTypes/BorrowApproval'
import { INITIAL_PUBARCHIVE } from '@/Redux/ActionTypes/PubArchive'
import _fetch from '@/Utils/Fetch'
import { archiveUrl, borrowUrl, myApprovalBorrowUrl } from '@/Utils/Urls'
import { message } from 'antd'
// 更新红点函数
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
export const statusEnum = new Enum({
	0: '申请',
	1: '待审批',
	2: '同意',
	3: '拒绝',
	4: '完成',
	5: '延期',
})
export const finishStatusEnum = new Enum({ 0: '同意', 1: '拒绝', 2: '转交' })
export const compositeSearchEnum = new Enum({ 2: '已归档' })

// 给申请表添加详情
export const addBorrowApprovalAction = data => {
	return {
		type: ADD_BORROW_APPROVAL,
		data: data,
	}
}

// 初始化pubArchive[markName].old
export const inititalPubArchiveAction = (markName, pubArchiveData) => {
	return {
		type: INITIAL_PUBARCHIVE,
		filter: {
			markName,
			...pubArchiveData,
		},
	}
}

/**
 * 获取借阅申请审批的详情列表
 * @param {Number} id 申请表id
 */
export const getUseReviewDetailThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: borrowUrl.getUseReviewDetail,
			data: {
				id,
			},
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 提交借阅申请审批
 * @param {Number} id 申请表id
 */
export const useReviewThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: myApprovalBorrowUrl.useReview,
			data: data,
			type: 'post',
			headers: { 'Content-Type': 'application/json' },
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('提交成功')
				updataRedDots()
				resolve()
			} else {
				reject()
			}
		})
	})

/**
 * 提交借阅审批生产文件
 * @param {Number} id 申请表id
 */
export const distributeFileThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: myApprovalBorrowUrl.distributeFile,
			data: data,
			type: 'post',
			headers: { 'Content-Type': 'application/json' },
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('生产成功')
				updataRedDots()
				resolve()
			} else {
				reject()
			}
		})
	})

/**
 * 状态为完成时可获取申请结果详情
 */
export const getMyUseResultThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: borrowUrl.getMyUseResult,
			type: 'get',
			data: {
				id,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 提交借阅审批生产文件
 * @param {Number} id 申请表id
 */
export const delayReviewThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: myApprovalBorrowUrl.delayReview,
			data: data,
			type: 'post',
			headers: { 'Content-Type': 'application/json' },
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('延期成功')
				updataRedDots()
				resolve()
			} else {
				reject()
			}
		})
	})

// 获取表格数据
export const getArchiveListThunk = record => () =>
	new Promise(async (resolve, reject) => {
		// 案卷顺带请求其下的列表
		_fetch({
			url: archiveUrl.getArchiveList,
			type: 'get',
			data: {
				id: record.id,
				size: 999,
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data.records)
			} else {
				reject()
			}
		})
	})
