import React, { Component, Fragment } from 'react'
import './index.less'
import { PubForm } from '@/Public/PubForm'
import { authUtil } from '@/Utils'
import { myApprovalBorrowUrl, departmentUrl, dossierUrl } from '@/Utils/Urls'
import { dossierEnum } from '@/Utils/Enum'
import {
	statusEnum,
	finishStatusEnum,
	compositeSearchEnum,
	addBorrowApprovalAction,
	getUseReviewDetailThunk,
	useReviewThunk,
	distributeFileThunk,
	getMyUseResultThunk,
	getArchiveListThunk,
	delayReviewThunk,
} from './ActionCreator'
import {
	routeChangeAction,
	pushRoutersAction,
	getDetailThunk,
	inititalPubArchiveAction,
	addPubFileAction,
	addPubDossierAction,
} from '@/Public/PubTable/baseTable/ActionCreator.js' // 为了可以查看文件
import { connect } from 'react-redux'
import { Button, message, Modal, Space, Divider } from 'antd'
import moment from 'moment'
import CompositeSearchButton from '@/Public/CompositeSearch/CompositeSearchButton.jsx'
import { treeUtil } from '@/Utils'
import BaseTable from '@/Public/PubTable/baseTable'
import { expiredFilesColumns } from '@/Public/PubTable/pageColumns'
import { pub_getAllTableData } from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator.js'
import _ from 'lodash'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import { homePath } from '@/Components/Login/LoginForm'
import { PlusOutlined } from '@ant-design/icons'

/**
 * 功能:删除tab栏时，计算出下一次激活的tab
 * 删除激活的tab，若它是第一个，激活最后一个。若不是，激活前一个
 * 删除非激活的tab，那没事了
 * @param {Array} routes
 * @param {string} route
 * @param {string} targetKey
 * @return {string}
 */
function getNextActiveKey(routes, route, targetKey) {
	let nextActiveKey = ''
	if (routes.length === 1) {
		return homePath
	}
	if (targetKey === route) {
		if (routes[0].key === targetKey) {
			return routes[routes.length - 1].key
		} else {
			routes.every(item => {
				item.key !== targetKey && (nextActiveKey = item.key)
				return item.key !== targetKey
			})
		}
		return nextActiveKey
	}
	return route
}

const otherFormItem = [
	{
		type: 'Select',
		name: 'status', // 状态
		label: '状态',
		placeholder: '请选择状态',
		options: dossierEnum.status.toOptions(),
		initialValue: dossierEnum.status.toOptions()[2].value,
		width: '150px',
		allowClear: false,
		disabled: true,
	},
]

// 时间函数，传入参数为之后多少天，虽然返回多少天后的年月日
function GetDateStr(AddDayCount) {
	let dd = new Date()
	dd.setDate(dd.getDate() + AddDayCount) //获取AddDayCount天后的日期
	let y = dd.getFullYear()
	let m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1 //获取当前月份的日期，不足10补0
	let d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate() //获取当前几号，不足10补0
	let h = dd.getHours() < 10 ? '0' + dd.getHours() : dd.getHours() //获取系统时，
	let min = dd.getMinutes() < 10 ? '0' + dd.getMinutes() : dd.getMinutes() //分
	let s = dd.getSeconds() < 10 ? '0' + dd.getSeconds() : dd.getSeconds() //秒
	return y + '-' + m + '-' + d + ' ' + h + ':' + min + ':' + s // 年月日 时分秒
}

const markName = 'SelectBorrowFile' // redux的标识
const mapStateToProps = state => {
	return {
		approvalData: state.borrowApprovalData.approvalData, // 获取表单数据
		showTableList: state.pubTable.pub_allTableData.SelectBorrowFile, // 获取表格数据
		route: state.route.item, // 获取当前路由
		routes: state.route.items,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		addBorrowApproval: data => dispatch(addBorrowApprovalAction(data)), // 添加
		getUseReviewDetail: (...rest) => dispatch(getUseReviewDetailThunk(...rest)), // 查看借阅申请的审批详情
		distributeFile: (...rest) => dispatch(distributeFileThunk(...rest)), // 生产案卷
		useReview: (...rest) => dispatch(useReviewThunk(...rest)), // 查看借阅申请的审批详情
		getAllTableData(...rest) {
			return dispatch(pub_getAllTableData(...rest)) // 把获取到的表格所有数据发送到store
		},
		getMyUseResult: (...rest) => dispatch(getMyUseResultThunk(...rest)), //查看结果
		delayReviewT: (...rest) => dispatch(delayReviewThunk(...rest)), //延期
		// 以下为查看文件操作
		getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey.key))
			} else {
				dispatch(routeChangeAction(activeKey.key))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
		},
		pushRouters(link) {
			return dispatch(pushRoutersAction(link))
		},
		addPubFileData(data) {
			return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		addPubDossierData(data) {
			return dispatch(addPubDossierAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		getArchiveList: (...rest) => dispatch(getArchiveListThunk(...rest)),
		inititalPubArchive: (markName, pubArchive) =>
			dispatch(inititalPubArchiveAction(markName, pubArchive)),
		// 以下为删除路由
		removeTab(targetKey, history, route, routes) {
			let nextActiveKey = getNextActiveKey(routes, route, targetKey)
			dispatch({ type: 'ROUTE_CHANGE', filter: nextActiveKey })
			dispatch({ type: 'TAB_REMOVE', filter: targetKey })
			if (history) {
				history.push(linkTo.to(nextActiveKey))
			} else {
				dispatch(routeChangeAction(nextActiveKey))
			}
		},
	}
}
@connect(mapStateToProps, mapDispatchToProps)
class ApprovalDetails extends Component {
	state = {
		haveFile: 0,
	}

	//设置表单值
	componentDidMount() {
		this.props.getMyUseResult(this.props.approvalData.id).then(res => {
			if (res.length !== 0) {
				this.setState({ haveFile: res.length })
				this.setTableData(res) // 设置到table
			} else {
				if (this.props.showTableList === undefined) {
					this.setState({ haveFile: res.length })
					this.setTableData(res) // 设置
				} else {
					this.setTableData(this.props.showTableList.data)
				}
			}
		})
	}

	setFormItems = () => {
		let approvalData = this.props.approvalData
		// PubForm 表单项配置数组
		let formItems = [
			// 基本表单内容
			[
				{
					type: 'Extra',
					name: 'Title',
					render: formRef => {
						return <h1>借阅审批详情</h1>
					},
					span: 23,
				},
			],
			[
				{
					type: 'Input',
					name: 'applyCode',
					label: '申请编号',
					rules: [{ required: false }],
					disabled: true,
					bordered: false,
					span: 6,
				},
				{
					type: 'Input',
					name: 'applyName',
					label: '申请人',
					bordered: false,
					disabled: true,
					rules: [{ required: false }],
					span: 6,
				},
				{
					type: 'Input',
					name: 'departmentName',
					label: '部门',
					bordered: false,
					disabled: true,
					rules: [{ required: false }],
					span: 6,
				},
				{
					type: 'Select',
					name: 'status',
					label: '状态',
					bordered: false,
					options: statusEnum.toOptions(), // 申请的处理状态
					disabled: true,
					rules: [{ required: false }],
					span: 6,
				},
			],
			[
				{
					type: 'TextArea',
					name: 'applyReason',
					label: '申请原因',
					colclassname: 'end-table',
					disabled: true,
					maxLength: 1500,
					showCount: true,
				},
			],
			[
				{
					type: 'TextArea',
					name: 'applyContent',
					label: '申请内容',
					disabled: true,
					maxLength: 1500,
					showCount: true,
				},
			],
		]

		const { distributeFile } = myApprovalBorrowUrl
		// 显示历代审批详情
		approvalData.useReviewListBoList &&
			approvalData.useReviewListBoList.forEach((record, n) => {
				let reviewUserAndTime = {
					userName: record.name,
					reviewTime: record.createTime,
				}
				formItems.push(
					[
						{
							type: 'TextArea',
							name: `useReview_${n}`,
							label: '审批意见',
							disabled: true,
							initialValue: record.result,
							maxLength: 1500,
							showCount: true,
						},
					],
					[
						{
							type: 'InputWithPicker',
							name: `reviewUserAndTime_${n}`, // 审批人和审批时间
							keys: ['userName', 'reviewTime'],
							label: `${record.department}`,
							rules: [{ required: false }],
							span: 22, // span:22 代表把该表单组件放在上个表格的右下角
							initialValue: reviewUserAndTime,
							disabled: true,
						},
					],
					[
						{
							type: 'Radio',
							name: `status_${n}`,
							label: '审核结果',
							options: finishStatusEnum.toOptions(),
							rules: [{ required: false }],
							disabled: true,
							span: 21, // span：21 代表把表单组件放在上个表格的左下角
							initialValue: record.status,
						},
					]
				)
			})
		// 审批状态才有审批意见
		if (approvalData.status === 1 && approvalData.permission === 1) {
			formItems.push(
				[
					{
						type: 'TextArea',
						name: `result`,
						label: '审批意见',
						rules: [{ required: true }],
					},
				],
				[
					{
						type: 'InputWithPicker',
						name: `reviewUserAndTime`, // 审批人和审批时间
						keys: ['userName', 'reviewTime'],
						label: `审批人`,
						rules: [{ required: false }],
						span: 22, // span:22 代表把该表单组件放在上个表格的右下角
						disabled: true,
					},
				],
				[
					{
						type: 'Radio',
						name: `finishstatus`,
						label: '审核结果',
						options: finishStatusEnum.toOptions(),
						rules: [{ required: false }],
						span: 21, // span：21 代表把表单组件放在上个表格的左下角
					},
				],
				[
					{
						type: 'AsyncTreeSelect',
						name: 'departmentId',
						label: '转交部门',
						ajaxConfig: {
							url: departmentUrl.getAllDepartmentTree,
						},
						formatDataFunc: data => {
							return treeUtil.changeKeys(data.children, {
								departmentName: 'title',
								id: 'value',
							})
						},
						rules: [{ required: false }],
						span: 19,
						propsClass: 'BorrowApprovalDetails-transfer-department',
					},
				],
				[
					{
						type: 'Extra',
						name: 'ButtonFix',
						render: formRef => {
							return (
								<>
									<Button
										type='primary'
										onClick={() => {
											formRef.validateFields().then(() => {
												let data = formRef.getFieldValue()
												let applydata = {
													result: data.result,
													status: data.finishstatus,
													useApplyId: data.id,
													departmentId: JSON.parse(
														localStorage.getItem(
															'userMessage'
														)
													)?.user?.departmentId,
												}
												if (
													typeof applydata.status ===
													'undefined'
												) {
													message.error(
														'您没有选择借阅审核结果'
													)
												} else {
													if (applydata.status === 2) {
														// 转交
														if (data.departmentId) {
															applydata[
																'forwardDepartmentId'
															] = data.departmentId // 转交部门id
															this.props
																.useReview(applydata)
																.then(() => {
																	const {
																		removeTab,
																		route,
																		routes,
																	} = this.props
																	removeTab(
																		route,
																		history,
																		route,
																		routes
																	) // 删除
																	// 跳路由
																	let borrowLink = {
																		title: '借阅审批',
																		key: '/workarea/myApproval/borrow',
																	}
																	this.props.onChange(
																		borrowLink,
																		history
																	) // 修改路由
																	this.props.pushRouters(
																		borrowLink
																	) //添加分页栏
																})
														} else {
															message.error(
																'您没有选择转交部门'
															)
														}
													} else {
														this.props
															.useReview(applydata)
															.then(() => {
																if (
																	applydata.status === 1
																) {
																	// 拒绝
																	const {
																		removeTab,
																		route,
																		routes,
																	} = this.props
																	removeTab(
																		route,
																		history,
																		route,
																		routes
																	) // 删除
																	// 跳路由
																	let borrowLink = {
																		title: '借阅审批',
																		key: '/workarea/myApproval/borrow',
																	}
																	this.props.onChange(
																		borrowLink,
																		history
																	) // 修改路由
																	this.props.pushRouters(
																		borrowLink
																	) //添加分页栏
																} else {
																	// 同意
																	this.toRefresh()
																	// const { removeTab, route, routes } = this.props;
																	// removeTab(route, history, route, routes);   // 删除
																	// //console.log(removeTab);
																	// //console.log(route);
																	// //console.log(history);
																	// //console.log(route);
																	// //console.log(routes);
																	// // 跳路由
																	// let borrowLink = {
																	//   title: '借阅审批',
																	//   key: '/workarea/myApproval/borrow'
																	// }
																	// this.props.onChange(borrowLink, history);   // 修改路由
																	// this.props.pushRouters(borrowLink)    //添加分页栏
																}
																// setTimeout(() => {
																//   const { removeTab, route, routes } = this.props;
																//   removeTab(route, history, route, routes);   // 删除
																//   // 跳路由
																//   let borrowLink = {
																//     title: '借阅审批详情',
																//     key: '/workarea/commonComponents/approvalDetails'
																//   }
																//   this.props.onChange(borrowLink, history);   // 修改路由
																//   this.props.pushRouters(borrowLink)    //添加分页栏
																// }, 1000);
															})
													}
												}
											})
										}}
										style={{ marginLeft: '85%' }}>
										提交借阅审批
									</Button>
									{this.state.haveFile !== 0 && (
										<BaseTable
											tableConfig={expiredFilesColumns}
											actions={this.actions}
											changeSearchLimit={this.changeSearchLimit}
											changeTableData={this.changeTableData}
											markName={markName}
											notRowSelection // 取消多选
											operationButton={this.returnButton} // 自定义操作按钮
										/>
									)}
								</>
							)
						},
						span: 18, // span:22 代表把该表单组件放在上个表格的右下角
					},
				]
			)
		} else if (approvalData.status === 2 && authUtil.hasAuth(distributeFile)) {
			// 同意状态显示文件选入框
			formItems.push(
				[
					{
						type: 'Extra',
						name: 'divider',
						render: () => (
							<Divider
								className='approvalDivider'
								style={{
									borderWidth: 1.4,
									borderColor: '#5b80d6',
								}}></Divider>
						),
						span: 23,
					},
				],
				[
					{
						type: 'DatePicker',
						name: 'deadLine',
						label: '案卷/档案过期时间',
						disabledDate: current => {
							return current && current < moment().endOf('day')
						},
						propsClass: 'select-time',
						placeholder: '选择时间',
						picker: 'all',
						span: 17,
						initialValue: GetDateStr(15), // 默认设置15天后的日期
					},
					{
						type: 'Radio',
						name: 'download',
						label: '是否可供下载',
						options: [
							{ label: '否', value: 0 },
							{ label: '是', value: 1 },
						],
						initialValue: 1,
						span: 15,
					},
				],
				[
					{
						type: 'Extra',
						name: 'BorrowTableData',
						render: formRef => {
							return (
								<div className='borrow-approval-table'>
									<Space
										className='borrow-approval-table-bottonList'
										style={{ margin: '0px 20px 20px 20px' }}>
										<CompositeSearchButton
											btnTitle={<PlusOutlined />}
											enumStatus={compositeSearchEnum}
											hoverTitle='添加可借阅的档案或案卷'
											modalBtnTitle='确认'
											title='添加可借阅的档案或案卷'
											handleCompositeRecords={
												this.handleCompositeRecords
											}
											compositeSearchUrl={
												dossierUrl.getPartDossierList
											}
											withStatus={true}
											otherFormItem={otherFormItem}
										/>
										<Button
											type='primary'
											onClick={() => {
												formRef.validateFields().then(() => {
													const { download, id, deadLine } =
														formRef.getFieldValue()
													if (
														this.props.showTableList?.data
															?.length
													) {
														Modal.confirm({
															maskClosable: true,
															title: '提交可供其借阅文件',
															content:
																'提交后将不能再添加文件，是否继续？',
															okType: 'danger',
															onOk: () => {
																let dossierList = []
																let archiveList = []
																this.props.showTableList.data.forEach(
																	(record, n) => {
																		if (
																			record.type ===
																			0
																		) {
																			dossierList.push(
																				record.id
																			)
																		} else {
																			archiveList.push(
																				record.id
																			)
																		}
																	}
																)

																let data = {
																	useApplyId: id,
																	downloaded: download,
																	deadLine,
																	archiveList,
																	dossierList,
																}
																this.props
																	.distributeFile(data)
																	.then(() => {
																		// 发请求成功后刷新
																		this.toRefresh()
																	})
															},
														})
													} else {
														message.error(
															'您没有选择可供借阅文件'
														)
													}
												})
											}}>
											提交
										</Button>
									</Space>
									<BaseTable
										tableConfig={expiredFilesColumns}
										actions={this.actions}
										changeSearchLimit={this.changeSearchLimit}
										changeTableData={this.changeTableData}
										markName={markName}
										notRowSelection // 取消多选
										operationButton={this.returnButton} // 自定义操作按钮
									/>
								</div>
							)
						},
						span: 18, // span:18 设置表格样式
					},
				]
			)
			////console.log(formItems);
		} else if (approvalData.status === 4 || approvalData.status === 5) {
			// 完成状态  或者 待审批延期状态
			if (approvalData.delay === 1) {
				formItems.push(
					[
						{
							type: 'Extra',
							name: 'divider',
							render: () => (
								<Divider
									style={{
										borderWidth: 1.4,
										borderColor: '#5b80d6',
									}}></Divider>
							),
							span: 23,
						},
					],
					[
						{
							type: 'Radio',
							name: 'delay',
							label: '是否同意延期',
							options: [
								{ label: '否', value: -1 },
								{ label: '是', value: 0 },
							],
							span: 14,
						},
						{
							type: 'DatePicker',
							name: 'deadLine',
							label: '案卷/档案过期时间',
							placeholder: '选择时间',
							disabledDate: current => {
								return current && current < moment().endOf('day')
							},
							picker: 'all',
							disabled: false,
							rules: [{ required: false }],
							colclassname: 'BorrowApprovalDetails-select-deadLine-wrapper',
							initialValue: GetDateStr(15), // 默认设置15天后的日期
							span: 13,
						},
					]
				)
			}
			// 完成状态显示申请表下的文件栏
			formItems.push([
				{
					type: 'Extra',
					name: 'BorrowTableData',
					render: formRef => {
						return (
							<div className='borrow-approval-table'>
								{approvalData.delay === 1 && (
									<Space
										className='borrow-approval-table-bottonList'
										style={{ margin: ' 5px 20px 10px' }}>
										<Button
											type='primary'
											onClick={() => {
												formRef.validateFields().then(() => {
													const { id, deadLine, delay } =
														formRef.getFieldValue()

													if (!delay && !deadLine) {
														message.error('请选择过期时间')
													} else {
														let data = {
															useApplyId: id,
															deadLine,
															status: `${delay}`,
														}
														this.props
															.delayReviewT(data)
															.then(() => {
																// 发请求成功后刷新
																this.toRefresh()
															})
													}
												})
											}}>
											提交
										</Button>
									</Space>
								)}
								<BaseTable
									tableConfig={expiredFilesColumns}
									// actions={this.actions}
									// changeSearchLimit={this.changeSearchLimit}
									// changeTableData={this.changeTableData}
									markName={markName}
									notRowSelection // 取消多选
									operationButton={this.returnButton} // 自定义操作按钮
								/>
							</div>
						)
					},
					span: 18, // span:18 设置表格样式
				},
			])
		}

		return formItems
	}

	// 刷新审批表
	toRefresh = () => {
		let approvalData = this.props.approvalData

		this.props.getUseReviewDetail(approvalData.id).then(res => {
			res['id'] = approvalData.id
			this.props.addBorrowApproval(res) // 文件详情传到redux
		})
		this.props.getMyUseResult(this.props.approvalData.id).then(res => {
			this.setTableData(res) // 设置到table
		})
	}

	// 查询按钮进行后对数据的处理
	handleCompositeRecords = records =>
		new Promise((resolve, reject) => {
			let showTableList = this.props.showTableList
				? this.props.showTableList.data
				: [] // 有则获取原来的列表
			showTableList.push(...records) // 合并
			let newArr = []

			// 用于判断有没有id相同的案卷与档案
			showTableList.forEach((arr, n) => {
				for (let i = n; n < showTableList.length; n++) {
					if (
						arr.id === showTableList[i].id &&
						arr.type !== showTableList[i].type
					) {
						newArr.push(arr)
					}
				}
			})

			showTableList = _.uniqBy(showTableList, 'id') // 根据id去重
			newArr?.length && showTableList.push(newArr) // 添加相同id
			this.setTableData(showTableList) // 设置到table
			resolve()
		})

	// 设置表单值
	setTableData = showTableList => {
		let data = {}
		data['data'] = showTableList
		data.data.forEach(item => {
			// 让每一个表格数据都获得key
			item.key = item.id
		})

		data.pageData = {
			// 配置表格的页码器
			pageSize: data.size || 10,
			current: data.page || 1,
			total: data.total,
		}
		this.props.getAllTableData(data, markName) // 把表格数据发送到store
	}

	returnButton = record => {
		////console.log(record);
		return (
			<div>
				{/* 查看按钮对页面进行跳转 */}
				<Button
					onClick={() => {
						const link =
							record.type === 1
								? {
										title: '文件详情',
										key: '/workarea/commonComponents/fileDetails',
									}
								: {
										title: '案卷详情',
										key: '/workarea/commonComponents/dossierDetails',
									}
						// 文件&&案卷详情的查看按钮
						this.props.getDetail(record).then(data => {
							// 请求详情数据
							data['route'] = this.props.route // 由此可以知道从哪打开详情，并判断是否添加按钮
							if (record.type) {
								this.props.addPubFileData(data) // 文件详情传到redux
								this.props.onChange(link, history) // 修改路由
								this.props.pushRouters(link) //添加分页栏
							} else {
								this.props.getArchiveList(data).then(res => {
									if (res.length !== 0) {
										res.forEach(archiveData => {
											const { fileToken, fileType, fileName } =
												archiveData
											// 设置预览图
											archiveData['preview'] = {
												type: 1,
												fileData: {
													fileToken,
													fileType,
													fileName,
												},
											}
											// 每个来个key
											archiveData['key'] = archiveData.id
										})
										this.props.inititalPubArchive('dossierDetails', {
											filesData: res,
											archiveData: data,
										})
									} else {
										;[data].forEach(archiveData => {
											const { fileToken, fileType, fileName } =
												archiveData
											// 设置预览图
											archiveData['preview'] = {
												type: 1,
												fileData: {
													fileToken,
													fileType,
													fileName,
												},
											}
											// 每个来个key
											archiveData['key'] = archiveData.id
										})
										this.props.inititalPubArchive('dossierDetails', {
											filesData: [data] || [],
											archiveData: data,
										})
									}
								})

								this.props.onChange(link, history) // 修改路由(如果异步，案卷下的异步表单发请求也是异步会报错)
								this.props.pushRouters(link) //添加分页栏
							}
						})
					}}>
					查看
				</Button>
				{/* 删除按钮对table行进行删除功能 */}
				{this.props.approvalData.delay === null && (
					<Button
						className='table-btn-danger'
						style={{ marginTop: '5px' }}
						onClick={() => {
							let TableList = this.props.showTableList.data
							let newArr = _.differenceWith(TableList, [record], _.isEqual) // 删除其中的数组对象
							this.setTableData(newArr) // 设置到table
						}}>
						删除
					</Button>
				)}
			</div>
		)
	}

	render() {
		return (
			<Fragment>
				<PubForm
					ref={this.formRef}
					formItems={this.setFormItems()}
					getInstance={formRef => {
						this.formRef = formRef

						this.formRef.setFieldsValue(this.props.approvalData)
						if (
							document.querySelector(
								'.BorrowApprovalDetails-transfer-department'
							)
						) {
							// 开始没有状态选择先隐藏(转交部门)
							document.querySelector(
								'.BorrowApprovalDetails-transfer-department'
							).parentNode.parentNode.parentNode.parentNode.style.opacity =
								'0'
							document.querySelector(
								'.BorrowApprovalDetails-transfer-department'
							).style.display = 'none'
						}

						// 添加监听事件
						document
							.querySelector('#BorrowApprovalDetails_finishstatus')
							?.addEventListener('click', e => {
								const targetName = e.target.nodeName.toLowerCase()
								if (targetName === 'input') {
									// 如果为转交状态的显示转交部门，不是则隐藏
									if (Number(e.target.getAttribute('value')) === 2) {
										document.querySelector(
											'.BorrowApprovalDetails-transfer-department'
										).parentNode.parentNode.parentNode.parentNode.style.opacity =
											'1'
										document.querySelector(
											'.BorrowApprovalDetails-transfer-department'
										).style.display = 'block'
									} else {
										document.querySelector(
											'.BorrowApprovalDetails-transfer-department'
										).parentNode.parentNode.parentNode.parentNode.style.opacity =
											'0'
										document.querySelector(
											'.BorrowApprovalDetails-transfer-department'
										).style.display = 'none'
									}
								}
							})

						// 添加监听事件
						document
							.querySelector('#BorrowApprovalDetails_delay')
							?.addEventListener('click', e => {
								const targetName = e.target.nodeName.toLowerCase()
								if (targetName === 'input') {
									// 如果为同意则显示，不是则隐藏
									if (Number(e.target.getAttribute('value')) === 0) {
										document.querySelector(
											'.BorrowApprovalDetails-select-deadLine-wrapper'
										).style.display = 'block'
									} else {
										document.querySelector(
											'.BorrowApprovalDetails-select-deadLine-wrapper'
										).style.display = 'none'
									}
								}
							})
					}}
					name={'BorrowApprovalDetails'}
					title='借阅审批详情'
				/>
			</Fragment>
		)
	}
}

export default ApprovalDetails
