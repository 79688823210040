import React, { Component } from 'react'
import { Input, DatePicker, AutoComplete, Empty } from 'antd'
import moment from 'moment'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

const { TextArea } = Input

/**
 * @param {Object} 表单子组件的配置对象
 * @return 返回值一个普通的 Iuput 输入框
 */
export const createInput = config => {
	return (
		<Input
			onChange={
				config.onChange
					? valueObj => {
							config.onChange(valueObj)
						}
					: null
			}
			bordered={config.bordered}
			maxLength={config.maxLength || 300}
			style={config.style}
			placeholder={config.placeholder || ''}
		/>
	)
}

/**
 * @param {Object} 表单子组件的配置对象
 * @return 返回值一个密码输入框
 */
export const createPasswordInput = config => {
	return (
		<Input.Password
			placeholder='input password'
			autoComplete={config.autocomplete && 'new-password'}
			iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
		/>
	)
}

/**
 * @param {Object} 表单子组件的配置对象
 * @return 返回值是一个带有 DatePicker 的 Input
 */
export const createInputWithPicker = (config, formRef) => {
	const { placeholder = [] } = config
	// placeholder 配置
	const [inputPlaceholder, pickerPlaceholder] = placeholder
	// input 和 picker 的键名
	const [inputName, pickerName] = config.keys
	// input 和 picker 的样式，input 有默认宽度 200px
	config.style = config.style || {}
	const { inputStyle = { width: 120 }, pickerStyle = {} } = config.style
	class InputWithPicker extends Component {
		/**
		 * 遵循 antd 自定义组件的要求，
		 * 需要将修改后的 value 传入 antd 的 FormItem 所赋予的 onChange 事件中，得以被管理
		 * @param {any} changedValue 修改后的表单项的值
		 */
		triggerChange = changedValue => {
			let { onChange, value } = this.props
			if (onChange) {
				onChange({
					...value,
					...changedValue,
				})
			}
		}

		// Input 组件变动时触发的函数
		onInputChange = e => {
			this.triggerChange({ [inputName]: e.target.value })
		}

		// DatePicker 组件变动时触发的函数
		onPickerChange = (moment, dateString = undefined) => {
			// 返回一个格式化后的字符串作为表单值 YYYY-DD-MM
			this.triggerChange({ [pickerName]: dateString })
		}

		disabledDate = current => {
			return current && current > moment().endOf('day')
		}

		render() {
			const { value = {}, disabled } = this.props
			if (!value[inputName]) value[inputName] = ''
			if (!value[pickerName]) value[pickerName] = undefined
			return (
				<Input.Group compact>
					<Input
						bordered={config.bordered}
						value={value[inputName]}
						onChange={this.onInputChange}
						placeholder={inputPlaceholder}
						style={inputStyle}
						disabled={disabled}
					/>
					<DatePicker // 时间选择框
						bordered={!disabled}
						value={value[pickerName] ? moment(value[pickerName]) : null}
						onChange={this.onPickerChange}
						placeholder={pickerPlaceholder}
						style={pickerStyle}
						disabled={disabled}
						disabledDate={this.disabledDate}
					/>
				</Input.Group>
			)
		}
	}
	return <InputWithPicker />
}

/**
 * @param {Object} 表单子组件的配置对象
 * @return 返回值一个普通的 文本域 输入框
 */
export const createTextArea = config => {
	const { rows, placeholder, resize, autoSize, maxLength, style, ...rest } = config
	//console.log(config)
	return (
		<TextArea
			rows={rows || 4}
			allowClear // 允许有清除按钮
			placeholder={placeholder || ''}
			className={resize || ''}
			autoSize={autoSize || false}
			maxLength={maxLength || 2000}
			{...rest}
		/>
	)
}

/**
 *
 * @param {Object} config 表单子组件的配置对象
 * @param {*} formRef
 * @return 返回一个异步的 输入框，并出现下拉选项，可用于模糊搜索
 */
export const createAsyncInput = (config, formRef) => {
	const {
		ajaxConfig,
		placeholder = '',
		onSearch,
		onSelect = () => {},
		triggerChange,
	} = config
	class AsyncInput extends Component {
		state = {
			options: [],
			value: null,
		}
		firstShow = true
		// 设置下拉可选项
		setOptions = async options => {
			await this.setState({ options })
		}
		/**
		 * 遵循 antd 自定义组件的要求，
		 * 需要将修改后的 value 传入 antd 的 FormItem 所赋予的 onChange 事件中，得以被管理
		 *
		 */
		__triggerChange = () => {
			this.props.onChange(this.state.value)
		}
		// 当数值有改变时，调用该函数
		onChange = value => {
			this.setState({ value: value }, async () => {
				;(await triggerChange)
					? triggerChange.call(this, value, this.state.options)
					: this.__triggerChange()
			})
		}

		componentDidUpdate(prevProps) {
			if (prevProps.value !== this.props.value) {
				this.initialValue()
			}
		}

		// 设置初始值
		initialValue = async () => {
			// 如果第一次有外面传来的值，就设置进去
			if (!this.state.value && this.firstShow && this.props.value) {
				this.setState({ value: this.props.value }, () => {
					onSearch(this.state.value, ajaxConfig, this.setOptions)
				})
				this.firstShow = false
			} else {
				if (this.props.value === '') {
					this.setState({ value: '' }, () => {
						onSearch(this.state.value, ajaxConfig, this.setOptions)
					})
					this.onChange('')
				}
			}
		}

		render() {
			return (
				<AutoComplete
					placeholder={placeholder}
					options={this.state.options}
					onChange={this.onChange}
					// onChange={(value) => { this.onChange(value, this.state.options) }}
					value={this.state.value}
					onFocus={() => {
						onSearch(this.state.value, ajaxConfig, this.setOptions)
					}}
					onSelect={(value, option) => {
						onSelect(value, option, this.onChange)
					}}
					onSearch={value => {
						onSearch(value, ajaxConfig, this.setOptions)
					}}
					notFoundContent={
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description='没找到合适的数据'
						/>
					}
				/>
			)
		}
	}
	return <AsyncInput />
}
