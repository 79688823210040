import React, { Component, Fragment } from 'react'
import { Form, Input, TreeSelect, Button, Typography, Select } from 'antd'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { connect } from 'react-redux'
import { getUserByIdThunk } from './ActionCreators'
import './index.less'

moment.locale('zh-cn')

const formRef = React.createRef(null) // 表单ref

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	getUserById: (...rest) => dispatch(getUserByIdThunk(...rest)),
})

@connect(mapStateToProps, mapDispatchToProps)
class EPhotoAlbumEditor extends Component {
	// 添加自定义样式
	setStyle = () => {
		let { height = 580, width = 350, uniqueName, buttons } = this.props
		height = height.toString()
		width = width.toString()
		if (width.replace(/[0-9]/g, '') === '') {
			width = `${width}px`
		}
		if (height.replace(/[0-9]/g, '') === '') {
			height = `${height}px`
		}
		const property = {
			'--height': height,
			'--width': width,
			'--form-height': `calc(${height} - 60px)`,
			'--content-height': buttons ? '88%' : '95%',
			'--buttons-height': buttons ? '12%' : '0',
		}
		const fileEditor = document.querySelector(`.${uniqueName}`)
		for (let i in property) {
			fileEditor.style.setProperty(i, property[i])
		}
	}

	state = { sensitiveDetected: {} }

	sensitiveDetect = () => {
		//敏感词提示
		const detectedDataList = ['topic', 'keyWord', 'author'] //探测的字段
		let sensitiveDetected = { topic: false, keyword: false, author: false } //默认false
		detectedDataList?.forEach(item => {
			const string = formRef.current.getFieldValue(item)
			//探测条件
			if (string?.match(/\*\*/g)) {
				sensitiveDetected[item] = true
			}
		})
		//同步到状态
		this.setState({ sensitiveDetected })
	}
	setTreeData = (data, titleName, valueName, onlyChildren = false) => {
		let treeData = []
		const recursionToTree = data => {
			let tempObj = {
				title: data[titleName],
				value: data[valueName],
			}

			if (data.children != null) {
				if (onlyChildren) {
					tempObj.disabled = true
				}
				tempObj.children = [...data.children.map(item => recursionToTree(item))]
			}
			return tempObj
		}
		data?.forEach((value, index) => {
			treeData.push(recursionToTree(value))
		})
		return treeData
	}

	async componentDidMount() {
		const { getInstance, defaultFormValue = [] } = this.props
		// defaultFormValue.forEach(async formItem => {
		//   if (formItem.name === 'userId') {
		//     let userMessage = await getUserById(formItem.value)
		//     formItem.value = userMessage.name
		//   }
		// })

		await formRef.current.setFields(defaultFormValue)

		const department = this.setTreeData(
			JSON.parse(localStorage.getItem('department')),
			'departmentName',
			'id'
		) // 部门树
		this.setState({
			department,
		})

		this.setStyle()
		;(await getInstance) && getInstance(formRef)
		this.sensitiveDetect()
	}

	componentDidUpdate() {
		const { defaultFormValue = [] } = this.props
		formRef.current.setFields(defaultFormValue)
		this.setStyle()
	}

	render() {
		const {
			formDisabled = true, // 是否禁用表单，默认为false
			uniqueName, // 表单唯一表示, 必须要
			onFinish, // 表单提交函数
			onValuesChange, // 表单value改变触发的函数
			buttons,
			themeTopic,
		} = this.props

		const { department, sensitiveDetected } = this.state

		if (!uniqueName) {
			throw Error('CollectedFileEditor中，uniqueName是必传参数')
		}
		return (
			<Fragment>
				<div className={`collected-file-editor ${uniqueName}`}>
					<div className='information-form'>
						<Typography.Title level={4} className='theme-topic'>
							{themeTopic}
						</Typography.Title>
						<Form
							onFinish={values => {
								if (onFinish) {
									const momentTime = values.time
									if (momentTime != null) {
										values.time = moment(momentTime)
											.format('YYYY-MM-DDTHH:mm:ss')
											.replace(/\.(.*)/, '')
									}
									onFinish(values)
								}
							}}
							onValuesChange={changedFields => {
								if (onValuesChange) {
									onValuesChange(changedFields)
								}
							}}
							ref={formRef}>
							<div className='information-content'>
								{/* 题名 */}
								<Form.Item
									label='题名'
									name='topic'
									className={sensitiveDetected.topic ? 'detected' : ''}
									rules={[{ required: true, message: '请填写名称' }]}>
									<Input.TextArea
										disabled={formDisabled}
										placeholder='无'
										bordered={!formDisabled}
										autoSize={true}
									/>
								</Form.Item>

								{/* 关键字 */}
								<Form.Item
									label='关键字'
									name='keyWord'
									className={
										sensitiveDetected.keyWord ? 'detected' : ''
									}>
									<Input.TextArea
										disabled={formDisabled}
										placeholder='无'
										bordered={!formDisabled}
										autoSize={true}
									/>
								</Form.Item>

								{/* 简介 */}
								<Form.Item
									label='简介'
									name='author'
									className={
										sensitiveDetected.author ? 'detected' : ''
									}>
									<Input.TextArea
										disabled={formDisabled}
										placeholder='无'
										bordered={!formDisabled}
										autoSize={true}
									/>
								</Form.Item>

								{/* 作者 */}
								<Form.Item label='作者' name='userId'>
									<Input.TextArea
										disabled={formDisabled}
										placeholder='无'
										bordered={!formDisabled}
										autoSize={true}
									/>
								</Form.Item>

								{/* 时间 */}
								<Form.Item label='时间' name='submitTime'>
									<Input.TextArea
										disabled={formDisabled}
										placeholder='无'
										bordered={!formDisabled}
										autoSize={true}
									/>
								</Form.Item>

								{/* 部门 */}
								<Form.Item label='部门' name='departmentId'>
									<TreeSelect
										disabled={formDisabled}
										bordered={!formDisabled}
										placeholder='无'
										treeData={department}
										treeDefaultExpandAll
										virtual={false}
									/>
								</Form.Item>

								{/* 状态 */}
								<Form.Item label='状态' name='status'>
									<Select
										disabled={formDisabled}
										bordered={!formDisabled}
										placeholder='无'
										virtual={false}>
										<Select.Option value={0}>编辑</Select.Option>
										<Select.Option value={1}>完成</Select.Option>
										<Select.Option value={2}>封存</Select.Option>
									</Select>
								</Form.Item>
							</div>

							{/* 按钮 */}
							<div className='fill-information-btns'>
								<Form.Item>
									{buttons &&
										buttons.map((btn, index) => {
											const { text, ...props } = btn
											return (
												<Button key={index} {...props}>
													{text}
												</Button>
											)
										})}
								</Form.Item>
							</div>
						</Form>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default EPhotoAlbumEditor
