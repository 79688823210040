import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import { connect } from 'react-redux'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { systemAllColumns } from '@/Public/PubTable/pageColumns'
import { fondsManagerUrl } from '@/Utils/Urls.js'
import AddModal from './AddModal'
import DetailModal from './DetailModal' // 查看案卷或档案（文件）详情的模态框
import { Space, Button, Modal } from 'antd'
import {
	deleteFondsIdentifierThunk,
	updateFixApplyThunk,
	addFondsIdentifierThunk,
	tips,
} from './ActionCreators'

const detailModalRef = React.createRef(null) // 获取查看模态框表单实例
let addmodalRef = React.createRef(null)
const markName = 'SystemAll' // redux的标识
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	deleteFonds: (...rest) => dispatch(deleteFondsIdentifierThunk(...rest)), // 删除全宗
	updateFixApply: (...rest) => dispatch(updateFixApplyThunk(...rest)), // 编辑全宗
	addFonds: (...rest) => dispatch(addFondsIdentifierThunk(...rest)), // 新增全宗
})
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class ApplicationBorrow extends Component {
	state = {
		record: null, // 当前操作（查看后入库、回退、加入收藏夹）的行数据
		recordIndex: -1, // 当前操作行数据在当前页数据的 index 值
	}

	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{ type: 'Input', name: 'fondsIdentifier', label: '全宗号：' },
			{ type: 'Input', name: 'fondsName', label: '全宗名称：' },
			{
				type: 'Extra',
				name: 'SystemAllButtonList',
				render: formRef => {
					return (
						<Space>
							<Button
								className='btn-success iconfont-buttons'
								title='新增'
								onClick={() => this.viewAddModal(null, -1)}>
								&#xe634;
							</Button>
							<Button
								className='btn-danger iconfont-buttons'
								title='删除'
								onClick={() => this.handleDeleteApply()}>
								&#xe816; 删除
							</Button>
						</Space>
					)
				},
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'post',
			url: fondsManagerUrl.getFondsIdentifier,
			ContentType: 'application/json',
		},
	}

	changeSearchLimit = data => {
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = res => {
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}

	// 检查表格选中数量是否大于一项，如果不满足条件则弹窗提示
	checkRowSelection = () => {
		return new Promise((resolve, reject) => {
			const { pub_rowSelection } = this.props
			const rowSelection = pub_rowSelection[markName] || []
			// 还未选中则不能操作
			if (!rowSelection.length) {
				reject(
					Modal.error({
						content: tips.CHOOSE_AT_LEAST_ONE,
						maskClosable: true,
					})
				)
			}
			// 返回所有选中项
			resolve(rowSelection)
		})
	}

	// 删除按钮
	handleDeleteApply = async () => {
		const {
			deleteFonds, // 删除接口
			pub_getAllData,
		} = this.props
		const { record } = this.state
		try {
			let applyIds = []
			// 如果 record 有内容那么通过模态框点击删除按钮
			if (record) {
				applyIds = [record.id]
			} else {
				// 否则是表格多项选中点击删除按钮
				const rowSelection = await this.checkRowSelection()
				applyIds = rowSelection.map(({ id }) => id)
			}
			deleteFonds(applyIds).then(res => {
				pub_getAllData()
				detailModalRef.current.hide()
			})
		} catch (err) {}
	}

	// 新增操作
	viewAddModal = (record, recordIndex) => {
		this.setState({ record, recordIndex }, addmodalRef.current.show)
	}

	// 查看详情模态框
	viewDetailModal = (record, recordIndex) => {
		this.setState({ record, recordIndex }, () => {
			detailModalRef.current.show(formRef => {
				formRef.setFieldsValue(record)
			})
		})
	}

	// 编辑申请
	handleUpdateApply = () => {
		const { updateFixApply, pub_getAllData } = this.props
		let updateData = detailModalRef.current.formRef.getFieldsValue() // 获取文本框内的值
		if (updateData) {
			// 通过点击模态框内部的编辑按钮
			updateFixApply(updateData).then(() => {
				pub_getAllData()
				detailModalRef.current.hide()
			})
		}
	}

	// 查看详情
	returnButton = (record, index) => {
		return (
			<Space>
				<Button onClick={() => this.viewDetailModal(record, index)} size='small'>
					查看
				</Button>
			</Space>
		)
	}

	// 关闭模态框后一些数据进行初始化
	hideCallback = () => this.setState({ record: null, recordIndex: -1 })

	render() {
		const { addFonds, pub_getAllData } = this.props
		const { record } = this.state
		return (
			<Fragment>
				<WrappedTable>
					<SearchForm
						name='expiredFiles-search-form'
						formItems={this.searchFormConfig}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
					/>
					<BaseTable
						tableConfig={systemAllColumns}
						actions={this.actions}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
						operationButton={this.returnButton} // 自定义操作按钮
						withPreview={false}
					/>
					{/* 新增宗卷 */}
					<AddModal
						modalRef={addmodalRef}
						addFonds={addFonds}
						pub_getAllData={pub_getAllData} // 刷新
						record={record} // 表格详情信息
					/>
					{/* 详情宗卷 */}
					<DetailModal
						modalRef={detailModalRef}
						record={record} // 表格详情信息
						handleUpdateUseApply={this.handleUpdateApply}
						handleDeleteApply={this.handleDeleteApply}
					/>
				</WrappedTable>
			</Fragment>
		)
	}
}

export default ApplicationBorrow
