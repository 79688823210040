import React, { PureComponent, Fragment } from 'react'
import { Button, Empty, Image, Spin, Switch } from 'antd'
import { connect } from 'react-redux'
import './index.less'
import { CloseOutlined } from '@ant-design/icons'
import { authUtil } from '@/Utils'
import FaceModal from './FaceModal'
import { faceInformationEntryThunk, getFaceDetailListThunk } from './ActionCreators'
import { mapUrl } from '@/Utils/Urls'
const { withAuth } = authUtil
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	faceInformationEntry: (...rest) => dispatch(faceInformationEntryThunk(...rest)), // 录入人脸信息
	getFaceDetailList: (...rest) => dispatch(getFaceDetailListThunk(...rest)), // 根据id搜人脸
})
@connect(mapStateToProps, mapDispatchToProps)
class FaceDetails extends PureComponent {
	state = {
		spinning: false, // 是否处于加载状态
		propIndex: null, // 从搜索页面打开模态框的索引
		showData: [], // 人脸数据
		visible: false, // 是否显示模态框
		isRecognized: false,
		unrecognizedUseData: [], // 能识别的数据
		recognizedUseData: [], // 不能识别的数据
		checked: true,
	}

	// 关闭模态框
	handleHide = callback => {
		this.setState({ visible: false }, callback && callback())
	}

	// 开头如果没有数据则设置
	componentDidMount() {
		const { unrecognizedUseData, recognizedUseData } = this.state
		const { faceData, getFaceDetailList, height } = this.props
		document.querySelector('.file-edit-face').style.setProperty('--height', height)
		if (typeof faceData[0] === 'number') {
			this.setState({ spinning: true }, () => {
				getFaceDetailList(faceData).then(res => {
					// 设置渲染数据
					const useData = res.data.map(item => {
						return {
							url: item.faceImageUrl[0],
							name: item.name,
							noMessage: true,
						}
					})
					this.separateUseData(unrecognizedUseData, recognizedUseData, useData)
					this.setState({
						unrecognizedUseData: [...unrecognizedUseData],
						recognizedUseData: [...recognizedUseData],
						spinning: false,
					})
				})
			})
		} else {
			this.separateUseData(unrecognizedUseData, recognizedUseData, faceData)
			this.setState({
				unrecognizedUseData: [...unrecognizedUseData],
				recognizedUseData: [...recognizedUseData],
			})
		}
	}

	// 分离数据
	separateUseData = (unrecognizedUseData, recognizedUseData, useData) => {
		useData.forEach(data => {
			if (data.name) {
				recognizedUseData.push(data)
			} else {
				unrecognizedUseData.push(data)
			}
		})
	}

	// 渲染人脸数据
	renderFaceDatas = (faceDatas, isRecognized) => {
		return faceDatas.map((item, index) => {
			return (
				<div key={index} className='face-item'>
					<div className='face-content'>
						<Image src={item.url} preview={false} />
						<div className='detail' title={item.name || '无'}>
							<div className='detail-span'>姓名：{item.name || '无'}</div>
							{!item?.noMessage &&
								withAuth(
									<Button
										size='small'
										style={{ float: 'right' }}
										onClick={() => {
											// 设置值并打开模态框
											this.setState({
												propIndex: index,
												isRecognized,
												showData: faceDatas[index],
												visible: true,
											})
										}}>
										编辑
									</Button>,
									mapUrl.faceInformationEntry
								)}
						</div>
					</div>
				</div>
			)
		})
	}

	// 编辑后重新设置值
	resetData = (newData, index, resolve) => {
		const { isRecognized, recognizedUseData, unrecognizedUseData } = this.state

		if (isRecognized) {
			recognizedUseData[index] = newData
		} else {
			recognizedUseData.push(newData)
			unrecognizedUseData.splice(index, 1)
		}

		this.setState(
			{
				unrecognizedUseData: [...unrecognizedUseData],
				recognizedUseData: [...recognizedUseData],
			},
			resolve
		)
	}

	// 人脸详细信息模态框切换
	switchFaceModal = (type, formRef, isRecognized) => {
		let { propIndex, recognizedUseData, unrecognizedUseData } = this.state
		if (type === 'next') {
			propIndex =
				++propIndex >
				(isRecognized ? recognizedUseData : unrecognizedUseData).length - 1
					? 0
					: propIndex
		} else {
			propIndex =
				--propIndex < 0
					? (isRecognized ? recognizedUseData : unrecognizedUseData).length - 1
					: propIndex
		}

		this.setState(
			{
				propIndex,
				showData: isRecognized
					? recognizedUseData[propIndex]
					: unrecognizedUseData[propIndex],
			},
			() => {
				formRef.current.setFieldsValue(this.state.showData)
			}
		)
	}

	render() {
		const { faceData, onClose, resetImageFace, faceInformationEntry, width } =
			this.props
		const {
			spinning,
			visible,
			propIndex,
			isRecognized,
			showData,
			recognizedUseData,
			unrecognizedUseData,
			checked,
		} = this.state
		const dataLength = faceData.length
		return (
			<div className='search-face file-edit-face' style={{ width }}>
				{/* 关闭按钮 */}
				<div
					className='archive-log-close'
					onClick={() => {
						onClose && onClose()
					}}>
					<CloseOutlined title='关闭' />
				</div>

				<Spin tip='请稍等...' spinning={spinning}>
					<div className='search-face-button-wrap'>
						<div className='search-face-button'>
							<Switch
								checkedChildren='已标识'
								unCheckedChildren='未标识'
								defaultChecked
								onChange={checked => {
									this.setState({
										checked,
									})
								}}
							/>
							{typeof faceData[0] === 'object' &&
								withAuth(
									<Button
										type='primary'
										onClick={() => {
											this.setState({ spinning: true }, () => {
												// 重新识别并填入输入框
												resetImageFace()
													.then(() => {
														this.setState({ spinning: false })
														onClose()
													})
													.catch(() => {
														this.setState({ spinning: false })
													})
											})
										}}>
										完成
									</Button>,
									mapUrl.faceInformationEntry
								)}
						</div>
					</div>
					{
						// 如果没有数据则显示空
						dataLength === 0 && (
							<Empty
								style={{
									padding: '50px 0',
									border: '1px solid #f0f0f0',
								}}
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description={<span>未识别出人脸</span>}
							/>
						)
					}
					{dataLength !== 0 && (
						<Fragment>
							<div className='search-face-faces clearfix file-show-faces'>
								{this.renderFaceDatas(
									checked ? recognizedUseData : unrecognizedUseData,
									checked
								)}
							</div>
						</Fragment>
					)}
				</Spin>

				<FaceModal
					visible={visible}
					handleHide={this.handleHide} // 关闭模态框
					showData={showData} // 显示数据
					propIndex={propIndex} // 数据的index
					isRecognized={isRecognized}
					resetData={this.resetData} // 重新设置人脸值
					faceInformationEntry={faceInformationEntry} // 录入人脸信息
					switchFaceModal={this.switchFaceModal}
				/>
			</div>
		)
	}
}

export default FaceDetails
