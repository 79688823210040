import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { departmentUrl } from '@/Utils/Urls'
import { UserManagerEnum } from '@/Utils/Enum'
import { connect } from 'react-redux'
import { addUserThunk } from '../ActionCreators'
import { treeUtil } from '@/Utils'
const formLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 10 },
}
// 表单项的单独配置，优先级高于表单项的统一配置
const formItemLayout = {
	labelCol: { span: 7 },
	wrapperCol: { span: 17 },
}
// PubForm 表单项配置数组
let formItems = [
	[
		{
			type: 'AsyncTreeSelect',
			name: 'departmentId',
			label: '所属部门',
			layout: formItemLayout,
			placeholder: '请选择所属部门',
			ajaxConfig: {
				url: departmentUrl.getDepartmentTree,
			},
			formatDataFunc: data => {
				return treeUtil.changeKeys([data], {
					id: 'value',
					departmentName: 'title',
				})
			},
			span: 12,
		},
		{
			type: 'Input',
			name: 'workId',
			label: '用户名',
			layout: formItemLayout,
			placeholder: '请输入用户名',
			rules: [
				{
					required: true,
					message: '用户名为必填项',
				},
				{
					type: 'number',
					message: '请输入正确的用户名',
					transform: value => {
						return Number(value)
					},
				},
			],
			span: 12,
		},
	],
	[
		{
			type: 'Input',
			name: 'name',
			label: '姓名',
			layout: formItemLayout,
			placeholder: '请输入名字',
			span: 12,
		},
		{
			type: 'Input',
			name: 'phone',
			label: '手机号码',
			placeholder: '请输入手机号码',
			layout: formItemLayout,
			rules: [
				{
					required: false,
				},
				{
					pattern: /^1[3456789]\d{9}$/,
					message: '输入正确的手机号码',
				},
			],
			span: 12,
		},
	],
	[
		{
			type: 'Input',
			name: 'email',
			label: '邮箱',
			placeholder: '请输入邮箱',
			layout: formItemLayout,
			rules: [
				{
					required: false,
				},
				{
					type: 'email',
					message: '请输入正确的邮箱',
				},
			],
			span: 12,
		},
		{
			type: 'PasswordInput',
			name: 'password',
			label: '密码',
			autocomplete: true,
			placeholder: '请输入密码',
			layout: formItemLayout,
			span: 12,
		},
	],
	[
		{
			type: 'Select',
			name: 'status',
			label: '状态',
			layout: formItemLayout,
			placeholder: '请输选择账号状态',
			options: UserManagerEnum.status.toOptions(),
			initialValue: 2,
			span: 12,
		},
		{
			type: 'DatePicker',
			name: 'validTime',
			label: '有效期',
			layout: formItemLayout,
			placeholder: '请输入有效期',
			initialValue: '2099-12-31T00:00:00.000+00:00',
			picker: 'all',
			span: 12,
			disabledDate: () => false,
		},
	],
]

// buttonList
let buttonList = [
	{
		text: '保存',
		buttonProps: {
			type: 'primary',
		},
		onClick: (formData, { finish, hide }) => {
			setTimeout(() => {
				hide()
			}, 1000)
		},
	},
]

const mapDispatchToProps = dispatch => ({
	addUser: (params, reflashTable) => dispatch(addUserThunk(params, reflashTable)), // 新增用户
})
@connect(null, mapDispatchToProps)
class modal extends Component {
	state = {
		visible: false,
		title: '新增用户',
		buttonList,
	}
	addNewUser = (formData, { finish, hide }) => {
		const { addUser, reflashTable } = this.props
		if (formData.phone === undefined) {
			formData.phone = ''
		}
		if (formData.email === undefined) {
			formData.email = ''
		}
		addUser(formData, { finish, hide, reflashTable })
	}
	render() {
		const { title } = this.state
		return (
			<PubModalForm
				title={title}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				onOk={this.addNewUser}
				formDisabled={false}
				okText='保存' // 若没有buttonList时选传， 默认值为提交
				ref={this.props.modalRef} // 若隔层由外层通过props传递
				width={700} // 默认值900
				formProps={{
					// 表单的配置项
					name: 'AddUserModal',
					formItems,
					layout: formLayout,
				}}
			/>
		)
	}
}

export default modal
