import React, { Component } from 'react'
import { Form, message, Button, Upload } from 'antd'
import { injectUnmount } from '@/Utils'
import FaceRecognitionCrop from './FaceRecognitionCrop'
const Item = Form.Item

/**
 * 以图识图按钮
 */
@injectUnmount
class RecognitionButton extends Component {
	state = {
		file: null,
		imgFileList: [],
		imgUrl: null,
	}

	/**
	 *
	 * 将修改后的 currency 传入 antd 的 FormItem 所赋予的 onChange 事件中，使数据得以被管理
	 * @param {any} currency 修改后的表单项的值
	 */
	triggerChange = currency => {
		const { onChange } = this.props
		if (onChange) {
			onChange(currency)
		}
	}

	handleBeforeUpload = file => {
		const { type } = file
		if (type.startsWith('image')) {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				this.setState({
					file,
					imgUrl: reader.result,
				})
			})
			reader.readAsDataURL(file)
			return true
		}
		message.info('请上传图片格式的文件')
		return false
	}

	// 关闭裁剪框的回调
	closeCropper = () => {
		this.setState({
			imgUrl: null,
		})
	}

	getMarkId = () => {
		const { setMarkId } = this.props
		setMarkId()
	}

	render() {
		const {
			value,
			changeSearchLimit,
			returnAllTableData,
			handleRecognitionSearchData,
		} = this.props
		const { imgUrl, file } = this.state
		return (
			<Item>
				{
					<Upload
						beforeUpload={this.handleBeforeUpload}
						customRequest={() => {}}
						fileList={[]}>
						<Button className='iconfont-buttons' title='图搜' type='primary'>
							&#xe85f; 图搜
						</Button>
					</Upload>
				}
				{
					<FaceRecognitionCrop
						returnAllTableData={returnAllTableData}
						handleRecognitionSearchData={handleRecognitionSearchData}
						file={file}
						imgUrl={imgUrl}
						value={value}
						closeCropper={this.closeCropper}
						setFormFields={this.setFormFields}
						changeSearchLimit={changeSearchLimit}
					/>
				}
			</Item>
		)
	}
}

export default RecognitionButton
