import React, { PureComponent, Fragment } from 'react'
/**
 * 返回自定义组件的函数
 * @param {Object} config formItem 的配置对象
 */
export const createExtra = (config, formRef) => {
	return config.render(formRef)
}
/**
 * 用于返回拥有一组状态的自定义组件
 * @param {Object} config 组件配置
 * @param {Object} formRef 表单实例方法
 */
export const createDynamicExtra = (config, formRef) => {
	class DynamicExtra extends PureComponent {
		constructor(props) {
			super(props)
			// 组件内state的key
			const { initialState } = config
			// 动态state对象
			let dynamicState = {}
			if (initialState) {
				for (const key in initialState) {
					if (initialState.hasOwnProperty(key)) {
						dynamicState[key] = initialState[key]
					}
				}
			}
			this.state = dynamicState
		}
		changeDynamicState = (newDynamicState, callback) => {
			this.setState(
				{
					...newDynamicState,
				},
				() => {
					callback && callback(this)
				}
			)
		}
		render() {
			return (
				<Fragment>
					{config.render(formRef, this.state, this.changeDynamicState)}
				</Fragment>
			)
		}
	}
	return <DynamicExtra />
}

/* export default {
  createExtra,
  createDynamicExtra
} */
