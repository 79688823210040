import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { ExceptionLogManagementColumns } from '@/Public/PubTable/pageColumns'
import { exceptionLogManagementUrl } from '@/Utils/Urls.js'
// import DetailModal from './DetailModal'
import { Space, Button, Modal } from 'antd'

let searchName = ''
// const detailModalRef = React.createRef(null) // 获取查看模态框表单实例
const tips = {
	CHOOSE_AT_LEAST_ONE: '请选择至少一项后再进行操作',
}
const markName = 'exceptionLogManagement' // redux的标识
@WrappedTable.create(markName)
class ExceptionLogManagement extends Component {
	state = {
		record: null, // 当前操作（查看后入库、回退、加入收藏夹）的行数据
		recordIndex: -1, // 当前操作行数据在当前页数据的 index 值
	}

	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [{ type: 'Input', name: 'name', label: '错误类型：', width: '300px' }],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'get',
			url: exceptionLogManagementUrl.getExceptionLog,
			ContentType: 'application/json',
		},
	}

	changeSearchLimit = data => {
		searchName = data.name
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = _res => {
		let res = new Object()
		res.records = _res
		//console.log('res:', res.records)
		if (typeof searchName !== 'undefined' && searchName.length != 0) {
			res.records.records = res.records.records.filter(
				item => item.name.indexOf(searchName) != -1
			)
		}
		return {
			data: res.records.records,
			pageSize: res.records.size,
			current: res.records.current,
			total: res.records.total,
		}
	}

	// 检查表格选中数量是否大于一项，如果不满足条件则弹窗提示
	checkRowSelection = () => {
		return new Promise((resolve, reject) => {
			const { pub_rowSelection } = this.props
			const rowSelection = pub_rowSelection[markName] || []
			// 还未选中则不能操作
			if (!rowSelection.length) {
				reject(
					Modal.error({
						content: tips.CHOOSE_AT_LEAST_ONE,
						maskClosable: true,
					})
				)
			}
			// 返回所有选中项
			resolve(rowSelection)
		})
	}

	// 查看详情模态框
	// viewDetailModal = (record, recordIndex) => {
	// 	this.setState({ record, recordIndex }, () => {
	// 		detailModalRef.current.show(formRef => {
	// 			formRef.setFieldsValue(record)
	// 		})
	// 	})
	// }

	// 查看详情
	// returnButton = (record, index) => {
	// 	return (
	// 		<Space>
	// 			<Button onClick={() => this.viewDetailModal(record, index)} size='small'>
	// 				查看
	// 			</Button>
	// 		</Space>
	// 	)
	// }

	hideCallback = () => this.setState({ record: null, recordIndex: -1 })

	render() {
		const { record } = this.state
		return (
			<Fragment>
				<WrappedTable>
					<SearchForm
						name='expiredFiles-search-form'
						formItems={this.searchFormConfig}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
					/>
					<BaseTable
						//table的columns配置
						//actions不知道有啥用
						//搜索限制（无）
						//格式baseTable中返回的数据
						//table唯一标识
						//是否带有前面的预览行
						tableConfig={ExceptionLogManagementColumns}
						//自定义行尾操作按钮
						noOperationButton
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
						withPreview={false}
						notRowSelection
					/>
					{/* <DetailModal
						modalRef={detailModalRef}
						record={record} // 表格详情信息
					/> */}
				</WrappedTable>
			</Fragment>
		)
	}
}

export default ExceptionLogManagement
