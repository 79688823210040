import { myArchiveUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'

export const getMyInfo = () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: myArchiveUrl.getMyFaceInformation,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

export const getMyArchive = data =>
	new Promise((resolve, reject) => {
		_fetch({
			url: myArchiveUrl.getMyArchiveDetail,
			type: 'get',
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})
