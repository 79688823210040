import React, { Component, createRef } from 'react'
import { Table, Button, Space, message, Modal, Checkbox, Badge, Spin } from 'antd'
import { connect } from 'react-redux'
import FilePreviewer from '@/Public/FilePreviewer'
import { dossierEnum } from '@/Utils/Enum'
import { PubForm } from '@/Public/PubForm'
import EPhotoAlbumEditor from '@/Public/EPhotoAlbumEditor'
import { treeUtil, planeTree } from '@/Utils'
import AddFilesToArchive from './AddFilesToArchive'
import AddNewFileToArchive from './AddNewFileToArchive' //新建按钮
import { history } from '@/index'
import { debounce, cloneDeep } from 'lodash'
import _ from 'lodash'
import {
	MenuOutlined,
	FieldTimeOutlined,
	LinkOutlined,
	SplitCellsOutlined,
	FolderOpenOutlined,
	EyeOutlined,
} from '@ant-design/icons'
import Attachment from '@/Public/Attachment'
import ArchiveLog from '@/Public/ArchiveLog'
import {
	inititalPubArchiveAction,
	upgradePubArchiveAction,
	removePubArchiveAction,
	pushArchiveSelectedAction,
	separateDossierAction,
	getDossierDetailAction,
	exportDossier,
	packArchiveThunk,
	insertDossierThunk,
	addPubFileAction,
	getDetailThunk,
	routeChangeAction,
	pushRoutersAction,
	removeImage,
} from './ActionCreators'
import { addArchivesUrl, archiveTypeUrl, departmentUrl, dossierUrl } from '@/Utils/Urls'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import './index.less'
import ButtonList from '@/Public/ButtonList'
import returnSvg from '@/Static/svg/return.svg'
import { linkTo } from '@/Routes/ManageRouter'
import { openWarcUrl } from '@/Public/FilePreviewer/Warc'
import axios from 'axios'
import { cosineWindow } from '@tensorflow/tfjs'
import _fetch from '@/Utils/Fetch'

// 类别号的一维树
const dossierTypeflatTree = planeTree(
	JSON.parse(localStorage.getItem('dossierType')),
	{},
	'children',
	'id',
	'typeName'
)
// 部门的一维树
const departmentFlatTree = planeTree(
	JSON.parse(localStorage.getItem('department')),
	{},
	'children',
	'id',
	'departmentName'
)

const getDossierMetadata = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(addArchivesUrl.getDossierMetadataStructTree, {
				headers: {
					Authorization:
						JSON.parse(localStorage.getItem('userMessage')).token || '',
				},
			})
			.then(response => {
				if (response.data.code === 200) {
					//console.log(response.data)
					resolve(response.data) // 解析并返回请求成功的结果
				} else {
					console.error(response.data)
					resolve(null) // 解析并返回请求失败的结果
				}
			})
			.catch(error => {
				console.error(error)
				console.error(addArchivesUrl.getDossierMetadataStructTree)
				reject(error) // 返回请求失败的结果
			})
	})
}

// 案卷详情表单配置
let archiveFormItems = [
	[
		{
			type: 'TextArea',
			name: 'topic', // 题名
			label: '题名',
			placeholder: '无',
			maxLength: 300,
			autoSize: true,
			showCount: true,
			style: { color: 'red' },
		},
	],
	[
		{
			type: 'Select',
			name: 'confidentialLevel', // 密级
			label: '密级',
			options: dossierEnum.confidentialLevel.toOptions(),
			defaultValue: 0,
		},
	],
	[
		{
			type: 'AsyncTreeSelect',
			name: 'departmentId', // 所属部门id
			label: '部门',
			defaultValue: JSON.parse(localStorage.getItem('userMessage')).user
				.departmentId,
			ajaxConfig: {
				url: departmentUrl.getDepartmentTree,
			},
			treeDefaultExpandAll: false,
			formatDataFunc: (data, changeKeys) => {
				return treeUtil.changeKeys([data], {
					departmentName: 'title',
					id: 'value',
				})
			},
		},
	],
	[
		{
			type: 'AsyncSelect',
			name: 'fondsIdentifierId', // 全宗号id
			label: '全宗',
			ajaxConfig: {
				url: addArchivesUrl.getFondsIdentifier,
			},
			formatDataFunc: data => {
				return data.map(item => ({
					label: item.fondsName,
					value: item.id,
				}))
			},
			placeholder: '无',
		},
	],
	[
		{
			type: 'AsyncTreeSelect',
			name: 'categoryCodeId',
			label: '类别',
			treeDefaultExpandAll: true,
			ajaxConfig: {
				url: dossierUrl.getDossierType,
			},
			formatDataFunc: (data, changeKeys) => {
				data.children.forEach(item => {
					item.disabled = true
				})
				return treeUtil.changeKeys(data.children, {
					typeName: 'title',
					id: 'value',
				})
			},
			placeholder: '无',
		},
	],
	[
		{
			type: 'Radio',
			name: 'retentionPeriod', // 保管期限
			label: '保管期限',
			width: 200,
			options: dossierEnum.retentionPeriod.toOptions(),
			initialValue: 1,
		},
	],

	[
		{
			type: 'TextArea',
			name: 'platform', // 发布平台
			label: '发布平台',
			rules: [{ required: false }],
			disabled: true, // 设置为禁用状态,因为后台没有修改接口
			placeholder: '无',
			autoSize: true,
			maxLength: 300,
			showCount: true,
		},
	],
	[
		{
			type: 'DatePicker',
			name: 'timeMeta',
			label: '时间',
			rules: [{ required: true }],
			placeholder: '请选择日期',
			autoSize: true,
		},
	],
	[
		{
			type: 'TextArea',
			name: 'keyWord', // 关键词
			label: '关键字',
			rules: [{ required: false }],
			placeholder: '无',
			autoSize: true,
			maxLength: 255,
			showCount: true,
		},
	],
	[
		{
			type: 'TextArea',
			name: 'catalogueNumber', // 目录号（预留）
			label: '目录号',
			rules: [
				{
					required: false,
					pattern: new RegExp(/^[1-9]\d*$/, 'g'),
					message: '目录号为数字类型',
				},
			],
			placeholder: '无',
			autoSize: true,
			maxLength: 300,
			showCount: true,
		},
	],
	[
		{
			type: 'TextArea',
			name: 'seeNumber', // 参见号
			label: '参见号',
			rules: [
				{
					required: false,
					pattern: new RegExp(/^[1-9]\d*$/, 'g'),
					message: '参见号为数字类型',
				},
			],
			placeholder: '无',
			autoSize: true,
			maxLength: 300,
			showCount: true,
		},
	],
	[
		{
			type: 'TextArea',
			name: 'introduction', // 简介
			label: '简介',
			rules: [{ required: false }],
			placeholder: '无',
			span: 24,
			rows: 8,
			resize: 'noResize',
			autoSize: true,
			maxLength: 1500,
			showCount: true,
		},
	],
]

// let archiveFormItem;
// let publicMetadataList = [];

// 获取该角色所在的部门
const getRoleDepartment = () => {
	const userMessage = JSON.parse(localStorage.getItem('userMessage'))
	return userMessage?.user.departmentId
}
// 案卷的默认格式——每个属性名与archiveFormItems中的name对应
const defaultFormValue = {
	catalogueNumber: undefined,
	categoryCodeId: undefined,
	confidentialLevel: 0,
	departmentId: getRoleDepartment(),
	fondsIdentifierId: undefined,
	introduction: undefined,
	keyWord: undefined,
	recordTime: undefined,
	recordUser: undefined,
	recordAddress: undefined,
	retentionPeriod: 1,
	seeNumber: undefined,
	topic: undefined,
}

// 创建附件attach的ref
const attachRef = createRef(null)
const SortableItem = sortableElement(props => <tr {...props} />)
const SortableContainer = sortableContainer(props => <tbody {...props} />)
const DragHandle = sortableHandle(() => (
	<MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
))
const mapStateToProps = state => {
	return {
		pubArchive: state.pubArchive,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		inititalPubArchive: (markName, pubArchive) =>
			dispatch(inititalPubArchiveAction(markName, pubArchive)),
		upgradePubArchive: (markName, pubArchive) =>
			dispatch(upgradePubArchiveAction(markName, pubArchive)),
		removePubArchive: markName => dispatch(removePubArchiveAction(markName)),
		pushArchiveSelected: rowSelectedData =>
			dispatch(pushArchiveSelectedAction(rowSelectedData)),
		insertDossier: (...rest) => dispatch(insertDossierThunk(...rest)), // 新增案卷
		packArchive: (...rest) => dispatch(packArchiveThunk(...rest)), // 案卷组卷
		getDossierMetadataAction: (...rest) => dispatch(getDossierMetadata(...rest)),
		getDossierDetail: (...rest) => dispatch(getDossierDetailAction(...rest)), //获取案卷详情
		addPubFileData(data) {
			return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey.key))
			} else {
				dispatch(routeChangeAction(activeKey.key))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
		},
		pushRouters(link) {
			return dispatch(pushRoutersAction(link))
		},
	}
}

@connect(mapStateToProps, mapDispatchToProps)
class PubArchive extends Component {
	fileName = [{ 1: '封面' }, { 2: '卷内考备表' }, { 3: '卷内文件目录' }]

	state = {
		scrollHeight: 0, // Table表格的table-body高度
		isShowPreview: true, //是否显示查看
		isShowAttachment: false, // 是否显示附件
		isShowArchiveLog: false, // 是否显示日志
		isShowModal: false, // 是否显示模态框
		isAttachmentUploading: false, // 是否有附件在上传
		value: [], //选择的导出内容
		isUploading: false, //是否上次新建文件
		publicMetadataList: [], // 初始化为空数组，或者根据实际情况初始化为其他值
		departmentTree: [], //部门信息 ，用于自动选择全宗
		dossierType: [], //类别信息，用于自动选择保管年限
	}
	markName = this.props.markName || history.location.pathname

	// 表格头中默认的操作的函数
	renderOperation = (text, record, formRef) => {
		return <React.Fragment></React.Fragment>
	}

	// 点击查看文件详情
	jumpToFileDetail = async (record, index, route) => {
		const markName = 'dossierDetails'
		let pageCurrent = 1 // 目前只有warc的标题点进会跳转，而有warc的都在第一页，故先写死
		const { addPubFileData, getDetail, onChange, pushRouters } = this.props
		const dossierData = this.props.pubArchive[markName].new
		const link = {
			title: '文件详情',
			key: '/workarea/commonComponents/fileDetails',
		}
		let data = await getDetail(record) // 请求详情数据
		data['archiveList'] = dossierData?.filesData
		data['route'] = route || this.props.route // 由此可以知道从哪打开详情，并判断是否添加按钮
		data['startIndex'] = (pageCurrent - 1) * 10 + index // 跳转文件详情第几张图片
		data['fatherDossierId'] = dossierData?.archiveData.id //由此可以知道父级的案卷是哪一个
		dossierData?.archiveData.attachmentList.forEach(item => {
			if (item.fileToken.includes('pdf')) {
				data['fatherDossierFileToken'] = item.fileToken
			}
		}) // 由于后台做了更改，在此获取附录中的pdf路径，用于warc类型pdf的展示
		addPubFileData(data) // 文件详情传到redux
		onChange(link, history) // 修改路由
		pushRouters(link) // 添加分页栏
	}

	// 左边表格的表格头设置
	tableColumns = [
		{
			title: '序号',
			dataIndex: 'sequantial',
			width: 35,
			render: (text, record, formRef) => {
				const { formDisabled, hasDragger } = this.props
				let child =
					hasDragger && !formDisabled ? <DragHandle /> : record.sequantial
				return child
			},
		},
		{
			title: '预览',
			dataIndex: 'fileData',
			render: (text, record, index, tableFuncs) => {
				return record.fileType === null ? null : (
					<FilePreviewer
						// 点击图片时进入图片的选择器
						click={() => {
							this.props?.clickPhoto?.(record, index)
						}}
						fileData={record.preview.fileData}
						isThumbnail={true}
						archiveType={record.archiveType}
					/>
				)
			},
			width: 50,
		},
		{
			title: '题名',
			dataIndex: 'topic',
			width: 180,
			ellipsis: true,
			render: (text, record, index) => {
				return (
					<div
						className={record.fileType === 'text/html' ? 'warcTopic' : null}
						onClick={() => {
							if (record.fileType === 'text/html') {
								const markName = 'dossierDetails'
								const dossierData = this.props.pubArchive[markName].new
								let toFileDetail = false
								dossierData?.archiveData.attachmentList.forEach(item => {
									if (item.fileToken.includes('pdf')) {
										toFileDetail = true
										this.jumpToFileDetail(record, index) // 点击标题时，若warc文件有pdf则跳转到文件详情，否则直接打开warc
									}
								}) // 由于后台做了更改，在此获取附录中的pdf路径，用于warc类型pdf的展示
								!toFileDetail && openWarcUrl(record.fileId)
							}
						}}>
						{record.topic}
					</div>
				)
			},
		},
		{ title: '类别号', dataIndex: 'categoryCode', width: 60 },
		{ title: '部门', dataIndex: 'departmentName', width: 60 },
		{ title: '著录人', dataIndex: 'recordUser', ellipsis: true, width: 60 },
		{
			title: '时间',
			dataIndex: 'time',
			ellipsis: true,
			width: 60,
			render: text => {
				if (text) {
					return text.includes('T')
						? text.replace(/T(.*)/, '')
						: text.replace(/\s(.*)/, '')
				}
			},
		},
		{
			title: '著录时间',
			dataIndex: 'recordTime',
			ellipsis: true,
			width: 60,
			render: text => {
				if (text) {
					return text.includes('T')
						? text.replace(/T(.*)/, '')
						: text.replace(/\s(.*)/, '')
				}
			},
		},
		{
			title: '操作',
			dataIndex: 'operation',
			render: this.props.renderOperation || this.renderOperation,
			width: 60,
		},
	]

	// 添加自定义样式
	setStyle = () => {
		/**
		 * setStyle = {
		 *  headerHeight,
		 *  contentHeight,
		 *  tableWidth,
		 *  formWidth
		 * }
		 */
		let { setStyle } = this.props // setStyle是一个对象
		setStyle = setStyle && cloneDeep(setStyle) // 深拷贝一份setStyle
		for (let i in setStyle) {
			setStyle[i] = setStyle[i].toString()
			if (setStyle[i].replace(/[0-9]/g, '') === '') {
				setStyle[i] = `${setStyle[i]}px`
			}
		}
		const property = {
			'--header-height': setStyle?.headerHeight || '6vh',
			'--content-height': setStyle?.contentHeight || '75vh',
			'--table-width': setStyle?.tableWidth || 'calc(100% - 350px)',
			'--form-width': setStyle?.formWidth || '350px',
		}
		const pubArchiveElement = document.querySelector(`.pubArchive-container`)
		for (let i in property) {
			pubArchiveElement.style.setProperty(i, property[i])
		}
	}

	componentWillMount() {
		this.fetchMetadata()
	}

	async fetchMetadata() {
		let {
			pubArchive,
			inititalPubArchive,
			filesWillWithIn,
			formItemsValue,
			formatFormValue = (a, b) => b,
		} = this.props
		const markName = this.markName
		//console.log(pubArchive)
		try {
			//获取全总信息保存到state
			if (!this.state.departmentTree.length) {
				_fetch({
					url: departmentUrl.getDepartmentTree,
					type: 'get',
				}).then(res => {
					if (res && res.data.code === 200) {
						this.setState({ ...this.state, departmentTree: res.data.data })
					} else {
						message.destroy()
						message.error('获取部门信息失败')
					}
				})
			}
			//保管部门信息到state
			if (!this.state.dossierType.length) {
				_fetch({
					url: archiveTypeUrl.getDossierType,
					type: 'get',
				}).then(res => {
					if (res && res.data.code === 200) {
						this.setState({ ...this.state, dossierType: res.data.data })
					} else {
						message.destroy()
						message.error('获取类别号信息失败')
					}
				})
			}
			const data = await getDossierMetadata()
			const publicMetadata = data.data.publicMetadataStruct?.[0]?.child
			let i = 0
			// 处理publicMetadata并生成publicMetadataList
			let publicMetadataLists = publicMetadata
				? publicMetadata.map(item => {
					// 处理每个item并返回新的结构
					const currentIndex = i++ // 获取当前索引并递增 i 的值
					return [
						{
							type: 'TextArea',
							name: item.id,
							label: item.metadataName,
							rules: [{ required: false }],
							pathId: 166,
							defaultValue:
								pubArchive.dossierDetails.new.archiveData
									.metadataStructTreeBoList.length !== 0
									? pubArchive.dossierDetails.new.archiveData.metadataStructTreeBoList.find(
										obj => obj.id === item.id
									)?.metadataValue || ''
									: '',
							placeholder: '无',
							maxLength: 300,
							autoSize: true,
							showCount: true,
							pdfMissed: item.pdfMissed,
							style: { backgoundColor: 'red' },
						},
					]
				})
				: []
			this.setState({ publicMetadataList: publicMetadataLists })
			// this.forceUpdate(); // 强制重新渲染
		} catch (error) {
			console.error(error)
		}
		// 初次渲染，把有传进来的文件和表单值，放到redux上
		if (!pubArchive[markName] || filesWillWithIn || formItemsValue) {
			const tmpPubArchive = {
				filesData: filesWillWithIn || [], // 可用于传入案卷自带的文件
				archiveData:
					formItemsValue || formatFormValue(filesWillWithIn, defaultFormValue),
			}
			inititalPubArchive(markName, tmpPubArchive)
		}
		//获取全总和保管年限的数据保存到State
	}

	componentDidMount() {
		//判断是否新建文件，是则点击刷新按钮
		if (sessionStorage && sessionStorage.getItem('lastRoute')) {
			this.props.renderLeftButtons().props.onClick()
			sessionStorage.removeItem('lastRoute')
		}
		this.setStyle()
		this.setTableScroll()
		// 监听键盘
		window.onkeydown = event => {
			if (event.code === 'ArrowDown') {
				_.throttle(() => {
					this.onSwitchCenterPage(this.arrowDownKeyUp)
				}, 1000)()
			}
		}
	}

	// 关闭模态框
	handleCancel = () => {
		this.setState({
			isShowModal: false,
			value: [],
		})
	}

	// 改变模态框选项
	onChange = checkedValues => {
		this.setState({
			value: [...checkedValues],
		})
	}

	// 模态框点击确定
	onOk = () => {
		const { value } = this.state
		if (value.length === 0) {
			Modal.error({
				content: '请选择至少一项后再进行操作',
				maskClosable: true,
			})
			return
		}
		const { pubArchive } = this.props
		const dossierIds = [pubArchive.dossierDetails.new.archiveData.id]
		const fileName = value.length > 1 ? '案卷信息' : this.fileName[value - 1][value]
		exportDossier(dossierIds, value, fileName)
		this.setState({
			isShowModal: false,
			value: [],
		})
	}

	setTableScroll = () => {
		if (this.state.scrollHeight === 0) {
			const tableContainer = document.querySelector('.pubArchive-content')
			this.setState({
				// scrollHeight: tableContainer.offsetHeight - 42
				scrollHeight: tableContainer.offsetHeight - 68, // 68为 ant-table-header 高度
			})
		}
	}

	// PubForm的getInstance的函数
	getInstance = async formRef => {
		const {
			pubArchive,
			formItemsValue,
			upgradePubArchive,
			removePubArchive,
			inititalPubArchive,
		} = this.props
		//console.log(pubArchive)
		const markName = this.markName
		const formValues =
			pubArchive[markName]?.new.archiveData || formItemsValue || defaultFormValue
		this.formRef = formRef
		formRef.getPubArchiveData = this.getPubArchiveData // 提供一个获取pubArchive[markName]上的所有信息的函数
		formRef.upgradePubArchive = data => {
			upgradePubArchive(markName, data)
		} // 提供一个更新pubArchive[markName]上的信息的函数
		formRef.removePubArchive = () => {
			removePubArchive(markName)
		} // 提供一个删除pubArchive[markName]上的信息的函数
		formRef.inititalPubArchive = (...rest) => {
			inititalPubArchive(markName, ...rest)
		}
		await formRef.setFieldsValue(formValues)
		this.props.getInstance && (await this.props.getInstance(formRef))
	}

	getPubArchiveData = () => {
		const { pubArchive } = this.props
		return pubArchive[this.markName]
	}

	// 加入已有文件的按钮
	renderAppendFilesButton = () => {
		const { compositeSearchUrl, handleAlbumRecords, isEPhotoAlbum } = this.props
		return (
			<AddFilesToArchive
				compositeSearchUrl={compositeSearchUrl}
				markName={this.markName}
				statusDisabled={this.props.statusDisabled === false ? false : true}
				handleAlbumRecords={handleAlbumRecords}
				isEPhotoAlbum={isEPhotoAlbum}
			/>
		)
	}

	// 加入新建文件的按钮
	renderNewFilesButton = () => {
		return (
			<AddNewFileToArchive
				markName={this.markName}
				archiveId={this.props.dossierId}
				pubArchive={this.props.pubArchive}
				isUploading={this.getNewFileUploading}
			/>
		)
	}

	// PubForm每次表单的值有变化，触发的函数
	onValuesChange = (changedValues, allValues) => {
		const { pubArchive, upgradePubArchive } = this.props
		const markName = this.markName
		const filesData = pubArchive[markName]?.new.filesData || []
		const tmpPubArchive = {
			filesData,
			archiveData: allValues,
		}
		//选择部门自动修改全宗
		if (changedValues.departmentId) {
			//通过changeValue的departmentId找到对应的fondsIdentifierId
			const findFindsIdentifierId = (id, treeData) => {
				if (treeData.id === id) {
					return treeData.fondsIdentifierId
				} else {
					treeData.children?.forEach(item => {
						if (item.id === id) {
							return item.fondsIdentifierId
						}
						if (item.children) {
							findFindsIdentifierId(id, item.children)
						}
					})
				}
			}

			allValues.fondsIdentifierId =
				findFindsIdentifierId(
					changedValues.departmentId,
					this.state.departmentTree
				) ?? allValues.fondsIdentifierId
		}
		//选择部门自动修改保管年限
		if (changedValues.categoryCodeId) {
			const findRententPeriod = (id, treeData) => {
				if (treeData.id === id) {
					return treeData.defaultRetentionPeriod
				} else {
					treeData.children?.forEach(item => {
						if (item.id === id) {
							return item.defaultRetentionPeriod
						}
						if (item.children) {
							findRententPeriod(id, item.children)
						}
					})
				}
			}
			allValues.retentionPeriod =
				findRententPeriod(changedValues.categoryCodeId, this.state.dossierType) ??
				allValues.retentionPeriod
			console.log(allValues.retentionPeriod)
		}
		upgradePubArchive(markName, tmpPubArchive)
	}

	returnFilesSource = () => {
		const { pubArchive } = this.props
		//删除查看页照片和音频
		pubArchive.dossierDetails.new.filesData = removeImage(pubArchive.dossierDetails.new.filesData)
		let filesData = pubArchive[this.markName]?.new.filesData || []
		if (filesData.length !== 0) {
			filesData.forEach((item, index) => {
				// 类别号转中文
				if (typeof item.categoryCodeId === 'number' && !item['categoryCode']) {
					item['categoryCode'] = dossierTypeflatTree[item.categoryCodeId]
				}
				// table的每一栏都必须有个key
				if (!item['key']) {
					item['key'] = item.id
				}
				// 部门转中文
				if (typeof item.departmentId === 'number' && !item['departmentName']) {
					item['departmentName'] = departmentFlatTree[item.departmentId]
				}
				item.sequantial = index + 1
			})
		}
		return filesData
	}

	// 切换中心页时调用
	onSwitchCenterPage = fn => {
		if (this.state.isAttachmentUploading) {
			Modal.confirm({
				title: <p className='file-details-confirm-title'>是否切换页面</p>,
				content: (
					<p className='file-details-confirm-content'>
						附件正在上传，切换页面会取消附件上传
					</p>
				),
				onOk: fn,
			})
		} else {
			fn()
		}
	}

	// 打开附件展示列表
	openAttachment = () => {
		this.setState({
			isShowAttachment: !this.state.isShowAttachment,
			isShowPreview: this.state.isShowAttachment,
			isShowArchiveLog: false,
		})
	}

	// 打开日志
	openLog = () => {
		this.setState({
			isShowArchiveLog: !this.state.isShowArchiveLog,
			isShowPreview: this.state.isShowArchiveLog,
			isShowAttachment: false,
		})
	}

	openView = () => {
		this.setState({
			isShowPreview: true,
			isShowArchiveLog: false,
			isShowAttachment: false,
		})
	}

	// 拖拽结束时的回调
	onSortEnd = async ({ oldIndex, newIndex }) => {
		let { upgradePubArchive } = this.props
		let filesSource = cloneDeep(this.returnFilesSource())
		if (oldIndex !== newIndex) {
			let tmp = filesSource[oldIndex]
			filesSource[oldIndex] = filesSource[newIndex]
			filesSource[newIndex] = tmp
		}
		let data = this.getPubArchiveData()
		let newData = {
			archiveData: data.new.archiveData,
			filesData: filesSource,
		}
		upgradePubArchive(this.markName, newData)
	}

	// 拖拽容器
	DraggableContainer = props => (
		<SortableContainer
			useDragHandle
			disableAutoscroll
			helperClass='row-dragging'
			onSortEnd={this.onSortEnd}
			{...props}
		/>
	)

	// 拖拽行
	DraggableBodyRow = ({ className, style, ...restProps }) => {
		let filesSource = this.returnFilesSource()
		if (filesSource.length === 0) return null
		// function findIndex base on Table rowKey props and should always be a right array index
		// 找到拖拽的那一行
		const index = filesSource.findIndex(x => x.id === restProps['data-row-key'])
		return <SortableItem index={index} {...restProps} />
	}

	returnRowSelection(record) {
		//返回表格的行配置
		const { pubArchive, pushArchiveSelected } = this.props
		const selectedData = pubArchive.rowselection || []

		if (record) {
			// 如果触发行触
			if (_.find(selectedData, record)) {
				//原先数据如果存在
				_.remove(selectedData, record) // 删掉
				pushArchiveSelected(selectedData) //更新store
			} else {
				pushArchiveSelected([record, ...selectedData]) //否则加入数据
			}
		}

		const onSelect = (record, selected, selectedRows, event) => {
			//判断是选中还是取消，选中直接添加，取消就从store里面删掉，下面同理
			if (selected) {
				pushArchiveSelected([record, ...selectedData])
			} else {
				let newSeletedData = selectedData.filter(item => {
					return item.id !== record.id
				})
				pushArchiveSelected(newSeletedData)
			}
		}

		const onSelectAll = (selected, selectedRows, changeRows) => {
			// 全选对数据的处理
			if (selected) {
				pushArchiveSelected([...changeRows, ...selectedData])
			} else {
				let newSeletedData = [...selectedData] //拷贝一份
				changeRows.forEach(item => {
					_.remove(newSeletedData, item) //删除取消全选的所有项
				})
				pushArchiveSelected(newSeletedData)
			}
		}
		const rowSelection = {
			type: 'checkbox',
			onSelect,
			onSelectAll,
			selectedRowKeys: pubArchive.rowselection
				? pubArchive.rowselection.map(item => item.id)
				: [],
		}
		return rowSelection
	}

	separateDossierAfterSaveAction = async () => {
		const {
			inititalPubArchive,
			upgradePubArchive,
			pushArchiveSelected,
			packArchive,
			insertDossier,
			getDossierDetail,
			pubArchive,
		} = this.props
		const { dossierDetails } = pubArchive

		const archiveIds = dossierDetails.new.filesData.map(item => item.id)

		let { id, attachmentList } = dossierDetails.old.archiveData
		//表单验证，获取表单值
		await this.formRef.validateFields()
		let archiveData = this.formRef.getFieldValue()
		//附件从父案卷复制过来
		if (attachmentList) {
			archiveData.attachmentList = attachmentList.map(item => {
				return item.fileId
			})
		}

		//用表单数据新建案卷
		insertDossier(archiveData).then(res => {
			let data = {
				ids: archiveIds,
				dossierId: res.data,
			}
			//获取新案卷数据id，把选出来的文件添加到新案卷
			Promise.all([getDossierDetail(data.dossierId), packArchive(data)])
				.then(values => {
					//更新数据，重新渲染
					inititalPubArchive(this.markName, {
						archiveData: values[0].data,
						filesData: dossierDetails.new.filesData,
					})
					upgradePubArchive(this.markName, {
						archiveData: values[0].data,
						filesData: dossierDetails.new.filesData,
					})
					//清空redux上rowselection数据
					pushArchiveSelected(null)
					//拆卷，将文件从原来的案卷拆出来
					separateDossierAction(archiveIds, id)
					message.destroy()
					message.success('拆卷成功')
				})
				.catch(() => {
					message.error('拆卷失败')
				})
		})
	}

	//拆卷
	separateDossier() {
		const { pubArchive, upgradePubArchive, separateDossier } = this.props
		//获取到redux上案卷中的文件和被选中的数据
		const { rowselection, dossierDetails } = pubArchive

		if (rowselection.length !== 0) {
			//如果有数据
			message.info('进入拆卷状态，可编辑新案卷信息')
			//将选出来的文件渲染
			upgradePubArchive(this.markName, {
				archiveData: dossierDetails.new.archiveData,
				filesData: rowselection,
			})
			//把保存后的操作挂到formref
			this.formRef.separateDossierAfterSaveAction = () => {
				this.separateDossierAfterSaveAction()
			}
			//改为编辑状态
			separateDossier()
		} else {
			message.destroy()
			message.info('请至少选择一项')
			return
		}
	}

	// 显示导出案卷模态框
	showModal() {
		this.setState({
			isShowModal: true,
		})
	}

	//⬇键切换页面
	arrowDownKeyUp = () => {
		const arr = ['isShowPreview', 'isShowAttachment', 'isShowArchiveLog']
		const newState = {}
		arr.forEach((item, index) => {
			if (this.state[item] === true) {
				newState[item] = false
				newState[arr[(index + 1) % arr.length]] = true
				return
			}
		})
		this.setState(newState)
	}

	// 传给子组件一个函数获取附件上传状态
	getAttachmentUploading = uploading => {
		this.setState({
			isAttachmentUploading: uploading,
		})
	}
	//当上传文件时改变状态
	getNewFileUploading = uploading => {
		this.setState({
			isUploading: uploading,
		})
	}

	componentWillUnmount() {
		window.onkeydown = null
		this.props.pushArchiveSelected([]) //卸载时清空redux
	}

	render() {
		const {
			isShowPreview,
			isShowAttachment,
			isShowArchiveLog,
			isShowModal,
			value,
			isUploading,
			publicMetadataList,
		} = this.state
		let {
			renderLeftButtons,
			renderRightButtons,
			dossierId,
			returnButtonCallback = undefined, // 返回按钮的回调
			// 左边文件表格
			tableColumns,
			appendFiles,
			isrowSelection = false, //是否可以行选
			hasDragger = false, // 是否配拖拽手柄
			// 右边案卷详情表单
			contentTitle,
			formDisabled, // 是否可编辑案卷信息，以及其文件
			renderBottomButtons,
			clickHandler, // 点击案卷信息的触发函数
			formItems = null,
			otherFormItems = [], // 额外的表单项
			isEPhotoAlbum,
			buttons,
			formValue = [],
			onFinish,
			// 日志
			isArchived = false, // 是否为已新建的案卷。是则显示日志
			logWidth = '100%',
			logheight = '100%',
			// 附件
			getAttachmentIds,
			changeAttachments,
			renderUploadBtn, // 附件添加后是否保存的按钮
			defaultFileList, // 附件默认的文件
			//redux
			pubArchive,
		} = this.props

		const { pdfMissed } = pubArchive.dossierDetails.new.archiveData

		let filesSource = this.returnFilesSource()
		let returnRowSelection = isrowSelection
			? this.returnRowSelection()
			: isrowSelection
		let attachmentList = {
			details: pubArchive.dossierDetails?.new.archiveData?.attachmentList,
			newArchives: pubArchive.newArchives?.new.archiveData?.attachmentList,
		}[window.location.href.split('/').pop()]

		return (
			<Spin tip='上传中...' spinning={isUploading}>
				<div className='pubArchive-container'>
					<div className='pubArchive-header'>
						<div className='button-left'>
							<Space size={'middle'}>
								<img
									style={{ width: 35, height: 35, cursor: 'pointer' }}
									src={returnSvg}
									alt={'返回'}
									onClick={() => {
										returnButtonCallback
											? returnButtonCallback()
											: history.goBack()
									}}
								/>
								{/* 查看按钮 */}
								<Button
									className={
										isShowPreview
											? 'pre-active preview-button'
											: 'preview-button'
									}
									onClick={() => {
										this.onSwitchCenterPage(this.openView)
									}}
									type='text'
									title='查看'>
									<EyeOutlined /> 查看
								</Button>
								{isShowPreview && isrowSelection && (
									<Button
										className='split-button'
										onClick={() => {
											this.separateDossier()
										}}
										type='text'
										title='拆卷'>
										<SplitCellsOutlined /> 拆卷
									</Button>
								)}
								{/* 添加文件 */}
								{!formDisabled &&
									appendFiles &&
									this.renderAppendFilesButton()}
								{/* 新增文件 */}
								{!formDisabled &&
									appendFiles &&
									this.renderNewFilesButton()}
								{!isEPhotoAlbum && (
									<Badge
										count={attachmentList ? attachmentList.length : 0}
										size={'small'}>
										<Button
											className={
												isShowAttachment
													? 'attachment-active attachment-button'
													: 'attachment-button'
											}
											onClick={() => {
												this.onSwitchCenterPage(
													this.openAttachment
												)
											}}
											type='text'
											title='附件'>
											<LinkOutlined className='journal-icons' />{' '}
											附件
										</Button>
									</Badge>
								)}
								{isArchived && (
									<Button
										className={
											isShowArchiveLog
												? 'log-active log-button'
												: 'log-button'
										}
										onClick={() => {
											this.onSwitchCenterPage(this.openLog)
										}}
										type='text'
										title='日志'>
										<FieldTimeOutlined className='journal-icons' />{' '}
										日志
									</Button>
								)}
								{/* 收藏 */}
								{this.markName !== 'newArchive' && !isEPhotoAlbum && (
									<ButtonList
										renderFavorites={true}
										addToWorkbenchData={false}
									/>
								)}
								{this.markName !== 'newArchive' && !isEPhotoAlbum && (
									<Button // 新建案卷不展示导出按钮
										className='export-button iconfont-buttons'
										onClick={() => {
											this.showModal()
										}}
										type='text'
										title='导出'>
										{/* <DownloadOutlined /> */}
										&#xe8c6; 导出
									</Button>
								)}
								{renderLeftButtons && renderLeftButtons()}
							</Space>
						</div>
						<div className='button-right'>
							{renderRightButtons && renderRightButtons()}
						</div>
						{/* 导出的模态框 */}
						<Modal
							title='请选择导出内容'
							visible={isShowModal}
							onOk={() => {
								this.onOk()
							}}
							onCancel={this.handleCancel}>
							<Checkbox.Group onChange={this.onChange} value={value}>
								<Space direction='vertical'>
									{this.fileName.map((item, index) => {
										return (
											<Checkbox key={item} value={index + 1}>
												{item[index + 1]}
											</Checkbox>
										)
									})}
								</Space>
							</Checkbox.Group>
						</Modal>
					</div>
					<div className='pubArchive-content'>
						<div className='content-left'>
							{!(isShowAttachment || isShowArchiveLog) && (
								<Table
									bordered={true}
									rowSelection={returnRowSelection}
									columns={tableColumns || this.tableColumns} // 表格表头设置
									dataSource={filesSource}//数据
									pagination={false}
									scroll={
										filesSource.length
											? { y: this.state.scrollHeight || 400 }
											: false
									}
									components={{
										body: {
											wrapper: hasDragger
												? this.DraggableContainer
												: undefined,
											row: hasDragger
												? this.DraggableBodyRow
												: undefined,
										},
									}}
									onRow={
										// 行触事件
										record => ({
											onClick: () => {
												this.returnRowSelection(record)
											},
										})
									}
								/>
							)}
							{isShowAttachment && (
								<Attachment
									style={{
										border: '1px solid #ccc',
									}}
									attachRef={attachRef}
									getAttachmentIds={getAttachmentIds}
									defaultFileList={defaultFileList}
									renderUploadBtn={renderUploadBtn}
									renderCloseBtn={true}
									onlyShow={false}
									formDisabled={formDisabled}
									changeAttachments={changeAttachments}
									getAttachmentUploading={this.getAttachmentUploading}
									close={() => {
										this.setState({
											isShowAttachment: !isShowAttachment,
											isShowArchiveLog: false,
										})
									}}
								/>
							)}
							{isShowArchiveLog && (
								<ArchiveLog
									setStyle={{
										containerWidth: logWidth,
										containerHeight: logheight,
									}}
									dossierId={dossierId}
									onClose={() => {
										this.setState({ isShowArchiveLog: false })
									}}
								/>
							)}
						</div>
						<div className='content-right'>
							<div className='content-header'>
								<h3
									className={`${pdfMissed ? 'dossier_Detail_header' : ' '}`}>
									<div>
										{' '}
										{(() => (
											<FolderOpenOutlined className='archive-icon' />
										))()}
										{(() => contentTitle || '案卷信息')()}
									</div>

									{pdfMissed ? (
										<div className='pdfMissed'>缺失PDF</div>
									) : null}
								</h3>
							</div>
							<div className='content-article'>
								{isEPhotoAlbum ? (
									<EPhotoAlbumEditor
										uniqueName='ePhotoAlbum-details'
										buttons={buttons}
										defaultFormValue={formValue}
										onFinish={onFinish}
										getInstance={this.props.getInstance}
										formDisabled={formDisabled}
									/>
								) : (
									<PubForm
										getInstance={this.getInstance}
										formItems={
											formItems || [
												...archiveFormItems,
												...otherFormItems,
												// ...archiveFormItem,
												...publicMetadataList,
											]
										}
										onValuesChange={debounce(
											this.onValuesChange,
											2000
										)}
										formDisabled={formDisabled}
										clickHandler={clickHandler}
									/>
								)}
							</div>
							<div className='content-footer'>
								{renderBottomButtons && renderBottomButtons()}
							</div>
						</div>
					</div>
				</div>
			</Spin>
		)
	}
}

export default PubArchive
