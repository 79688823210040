import React, { Component } from 'react'
import { Upload, Button, message, Progress, notification } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { filterYellow } from '@/Utils'
import { createFileChunk, getFileMd5, uploadFile } from './ActionCreators'

class UploadFile extends Component {
	state = {
		fileList: [],
	}

	componentDidMount() {
		this.props.getUploadFile(this)
	}
	componentDidUpdate() {
		this.props.getUploadFile(this)
	}

	// 上传文件前先鉴黄
	handleBeforeUpload = async file => {
		const testResult = await filterYellow(file)
		return new Promise((resolve, reject) => {
			// 如果鉴黄结果不通过，则不允许上传
			if (testResult.isPorn || testResult.isSexy || testResult.isHentai) {
				file.status = 'error'
				file.response = '图片包含敏感信息，不允许上传'
				reject()
			} else {
				resolve()
			}
		})
	}

	// 上传文件状态发生改变时触发
	handleChange = ({ file, fileList }) => {
		if (file.response) {
			if (file.response.code === 400) {
				message.error(file.response.message)
				return
			}
		}

		this.setState({
			fileList,
		})

		const { setImage, getFileList } = this.props
		//如果所有上传文件都为done返回true，否则false
		const doneFileList = fileList.filter(file => {
			if (file.status === 'done') {
				return true
			} else {
				return false
			}
		})
		//展示已完成图片
		if (doneFileList.length !== this.props.fileList.length) {
			setImage(doneFileList, Math.random())
			getFileList(doneFileList)
		}
	}

	progressBar = (file, percent) => {
		notification.open({
			key: file.uid,
			message: percent === 100 ? `${file.name}上传成功` : `${file.name}上传中...`,
			description: <Progress percent={percent} />,
			placement: 'topRight',
			duration: null,
		})
	}

	uploadFiles = async files => {
		if (files.file.size === 0) {
			message.info('不支持上传空文件')
			return
		} else if (files.file.size >= 5120 * 1024 * 1024) {
			message.info('仅限上传5G以内的文件')
			return
		}

		this.progressBar(files.file, 0)

		const fileName = files.file.name
		const fileType = files.file.type
		const fileChunkList = createFileChunk(files.file)
		const fileMd5Value = await getFileMd5(fileChunkList)
		uploadFile(
			fileMd5Value,
			fileName,
			fileChunkList,
			fileType,
			files,
			this.progressBar
		)
			.then(res => {
				// 成功标志
				files.onSuccess(res, files.file)
				setTimeout(() => {
					notification.close(files.file.uid)
				}, 4500)
			})
			.catch(err => {
				notification.close(files.file.uid)
				message.error(`上传失败！${err.message}`)
				files.onError(err, files.file)
			})
	}

	deleteFile = index => {
		const { fileList } = this.state
		fileList.splice(index, 1)
		this.setState({
			fileList: fileList,
		})
	}

	//提交时清空所有
	finishClear = () => {
		this.setState({
			fileList: [],
		})
	}

	render() {
		const { fileList } = this.state
		return (
			<div className='upload-collected-files'>
				<Upload
					name='multipartFile'
					multiple={true}
					customRequest={this.uploadFiles} //接受文件信息
					onChange={this.handleChange} //文件状态发生改变时执行
					style={{ marginTop: '20px' }}
					beforeUpload={this.handleBeforeUpload} //审核
					fileList={fileList} //保存文件的变量
					showUploadList={false} //不展示文件列表
					// 解决网页崩溃的问题，不能删掉
					previewFile={false}>
					<Button title='上传文件' className='btn-success'>
						<PlusOutlined />
						上传
					</Button>
				</Upload>
			</div>
		)
	}
}

export default UploadFile
