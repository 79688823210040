import axios from 'axios'
import { collectedFileUrl, departmentUrl, filesUrl } from '@/Utils/Urls'
import asyncPool from '@/Utils/AsyncPool'
import SparkMD5 from 'spark-md5'

const spark = new SparkMD5.ArrayBuffer()

export const addCollectedFileThunk = data => () =>
	new Promise((resolve, reject) => {
		axios
			.post(collectedFileUrl.addCollectedFile, data)
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res.data)
				}
			})
			.catch(err => {
				reject(err.response.data.message)
			})
	})

export const downloadCertificateThunk = id => () =>
	new Promise((resolve, reject) => {
		axios({
			url: collectedFileUrl.downloadCertificate,
			method: 'get',
			params: {
				id: id.join(','),
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			responseType: 'blob',
		}).then(res => {
			if (res.headers['content-type'] === 'application/json') {
				return
			}
			if (res.success) {
				const blob = res.data
				const urlObject = window.URL || window.webkitURL || window
				const export_blob = new Blob([blob], { type: 'application/pdf' })
				const a = document.createElement('a') // 利用a标签特性下载
				a.style.display = 'none'
				const url = urlObject.createObjectURL(export_blob)
				a.href = url
				a.setAttribute('download', '捐赠证书.pdf')
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
			}
		})
	})

// 获取类别号
export const getCollectedFileTypeThunk = () => () =>
	new Promise((resolve, reject) => {
		axios
			.get(collectedFileUrl.getCollectedFileType)
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res.data)
				}
			})
			.catch(err => {
				reject(err.response.data.message)
			})
	})

// 获取部门树
export const getDepartmentTreeThunk = () => () =>
	new Promise((resolve, reject) => {
		axios
			.get(departmentUrl.getAllDepartmentNameTree)
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res.data)
				}
			})
			.catch(err => {
				reject(err.response.data.message)
			})
	})

// 获取主题
export const getAllThemeThunk = () => () =>
	new Promise((resolve, reject) => {
		axios
			.post(collectedFileUrl.getAllTheme)
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res.data)
				}
			})
			.catch(err => {
				reject(err.response.data.message)
			})
	})

//获取主题二维码
export const getQrCoderThunk = themeId => () =>
	new Promise((resolve, reject) => {
		axios({
			url: collectedFileUrl.getQrCoder,
			method: 'get',
			params: {
				themeId,
			},
			responseType: 'json',
		}).then(res => {
			resolve(res)
		})
	})

//获取主题详情
export const getThemeDetailThunk = themeId => () =>
	new Promise((resolve, reject) => {
		axios({
			url: collectedFileUrl.getThemeDetail,
			method: 'get',
			params: {
				themeId,
			},
		}).then(res => {
			resolve(res.data.data)
		})
	})

/**
 * 文件切片
 * @param {file} file
 */
export const createFileChunk = file => {
	const fileChunkList = []
	const CHUNK_SIZE = 2097152 // 切片大小为 2M

	for (let start = 0; start < file.size; start += CHUNK_SIZE) {
		fileChunkList.push({
			file: file.slice(start, start + CHUNK_SIZE),
		})
	}

	return fileChunkList
}

/**
 * 创建文件的 md5 标识
 * @param {array} fileChunkList
 */
export const getFileMd5 = fileChunkList => {
	let count = 0
	const totalCount = fileChunkList.length
	const fileReader = new FileReader()
	return new Promise((resolve, reject) => {
		fileReader.onload = e => {
			if (e.target && e.target.result) {
				count += 1
				spark.append(e.target.result)
			}
			if (count < totalCount) {
				loadNext()
			} else {
				resolve(spark.end())
			}
		}

		fileReader.onerror = function () {
			reject({
				message: '文件读取失败',
			})
		}

		const loadNext = () => {
			fileReader.readAsArrayBuffer(fileChunkList[count]?.file)
		}

		loadNext()
	})
}

const getChunk = wholeMd5 => {
	return axios({
		url: filesUrl.getChunk,
		method: 'get',
		params: {
			wholeMd5,
		},
		isJson: true,
	})
}

/**
 * 上传文件（断点续传）
 * @param {*} fileMd5Value
 * @param {*} fileName
 * @param {*} fileChunkList
 */
export const uploadFile = async (
	fileMd5Value,
	fileName,
	fileChunkList,
	fileType,
	files,
	progressBar
) => {
	const total = fileChunkList.length
	let fileCurrent = (await getChunk(fileMd5Value))?.data?.data + 1
	let progressCurrent = fileCurrent

	fileChunkList = fileChunkList.map(fileChunk => {
		return new File([fileChunk.file], fileName, {
			type: fileType,
			lastModified: Date.now(),
		})
	})

	// eslint-disable-next-line
	for await (const _ of asyncPool(
		5,
		fileChunkList.slice(fileCurrent - 1, total - 1),
		file => {
			const formData = new FormData()
			formData.append('file', file)
			formData.append('wholeMd5', fileMd5Value)
			formData.append('chunks', total)
			formData.append('chunk', fileCurrent)

			fileCurrent++
			return axios({
				url: filesUrl.visitorFileUpload,
				method: 'post',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}).then(() => {
				let percent = ((progressCurrent / fileChunkList.length) * 100) | 0
				progressBar && progressBar(files.file, percent)
				progressCurrent++
			})
		}
	)) {
	}

	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const file = fileChunkList[total - 1]

		formData.append('file', file)
		formData.append('wholeMd5', fileMd5Value)
		formData.append('chunks', total)
		formData.append('chunk', total)

		axios({
			url: filesUrl.visitorFileUpload,
			method: 'post',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				progressBar && progressBar(files.file, 100)
				resolve(res.data)
			} else {
				reject(res.data)
			}
		})
	})
}
