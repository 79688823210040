/*
 * baseTable表格公共组件
 */
import React, { Component, forwardRef } from 'react'
import { Table, Button } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import _ from 'lodash'
import _fetch from '@/Utils/Fetch'
import Enum from '@/Utils/Enum'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import {
	pub_rowSelectionToStore,
	pub_getAllTableData,
	pub_isLoading,
	pub_currntPage,
	pub_clearSelectedData,
	pub_setSelectedStatus,
} from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator'
import FilePreviewer from '@/Public/FilePreviewer'
import { injectUnmount, planeTree } from '@/Utils'
import {
	routeChangeAction,
	pushRoutersAction,
	getDetailThunk,
	addPubFileAction,
	addPubDossierAction,
	inititalPubArchiveAction,
	getArchiveListThunk,
	addPubCollectedFileAction,
	addPubAlbumAction,
} from './ActionCreator'
import './baseTable.less'

//dnd-kit + antd 实现拖拽排序
import { MenuOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { dossierUrl } from '@/Utils/Urls'

const retentionPeriod = new Enum(['', '永久', '30年', '10年'])
const confidentialLevel = new Enum(['公开', '内部', '绝密', '机密', '秘密'])
const recordType = new Enum(['案卷', '文件'])
const status = {
	recordStatus: new Enum({
		'-1': '已过期',
		0: '著录中',
		1: '预归档',
		2: '已归档',
		3: '著录中',
		4: '回收站',
		5: '已销毁',
	}), // 文件案卷状态
	borrowStatus: new Enum({
		0: '申请',
		1: '待审批',
		2: '同意',
		4: '完成',
		5: '延期',
	}), // 借阅申请状态
	applyStatus: new Enum(['待鉴定', '完成', '暂存']), // 鉴定申请状态
	userStatus: new Enum(['不允许登陆', '允许登陆', '有效时间内允许登陆']), //用户管理
	correctStatus: new Enum(['申请', '待审批', '同意', '拒绝', '回退']), // 修正申请状态
	approvalStatus: new Enum({ 0: '申请', 1: '待审批', 2: '同意', 4: '完成', 5: '延期' }), // 借阅审批状态
}
// 全宗号的一维树
const fondsFlatTree = planeTree(
	JSON.parse(localStorage.getItem('fonds')),
	{},
	'children',
	'id',
	'name'
)
const collectedFileType = planeTree(
	JSON.parse(localStorage.getItem('collectedFileType')),
	{},
	'children',
	'id',
	'typeName'
)
const department = planeTree(
	JSON.parse(localStorage.getItem('department')),
	{},
	'children',
	'id',
	'departmentName'
)

// 把页面需要用到的数据和方法映射到props里面
const mapStateToProps = state => {
	return {
		pub_allTableData: state.pubTable.pub_allTableData, // 获取表格所有数据
		pub_isLoading: state.pubTable.pub_isLoading, // 是否拿到表格数据的标识
		fixedConf: state.pubTable.fixedConf, // 当前是第几页
		allSearchCondition: state.pubTable.allSearchCondition, // 所有搜索条件
		pub_rowSelection: state.pubTable.pub_rowSelection,
		pub_selectedStatus: state.pubTable.selectedStatus,
		routes: state.route.items,
		route: state.route.item,
	}
}

const mapDispatchToProps = dispatch => ({
	getRowSelection(...rest) {
		return dispatch(pub_rowSelectionToStore(...rest)) // 把选中的当行数据发送到store
	},
	getAllTableData(...rest) {
		return dispatch(pub_getAllTableData(...rest)) // 把获取到的表格所有数据发送到store
	},
	pub_changeisLoading(...rest) {
		return dispatch(pub_isLoading(...rest)) // 把标志是否获取表格数据的isLoading标识修改
	},
	pub_changeCurrentPage(...rest) {
		return dispatch(pub_currntPage(...rest)) // 把改变后的页数发送到store
	},
	pub_clearSelectedData(...rest) {
		return dispatch(pub_clearSelectedData(...rest)) // 清除选中数据
	},
	pub_setSelectedStatus(...rest) {
		return dispatch(pub_setSelectedStatus(...rest))
	},
	addPubCollectedFile(...rest) {
		return dispatch(addPubCollectedFileAction(...rest))
	},
	addPubAlbum(...rest) {
		return dispatch(addPubAlbumAction(...rest))
	},
	getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
	getArchiveList: (...rest) => dispatch(getArchiveListThunk(...rest)),
	onChange(activeKey, history) {
		if (history) {
			history.push(linkTo.to(activeKey.key))
		} else {
			dispatch(routeChangeAction(activeKey.key))
		}
		dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
	},
	pushRouters(link) {
		return dispatch(pushRoutersAction(link))
	},
	addPubFileData(data) {
		return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
	},
	addPubDossierData(data) {
		return dispatch(addPubDossierAction(data)) // 添加Action方法，用于添加文件详情分栏
	},
	inititalPubArchive: (markName, pubArchive) =>
		dispatch(inititalPubArchiveAction(markName, pubArchive)),
})

class BaseTable extends Component {
	constructor(props) {
		super(props)
		const {
			location: { pathname },
			markName,
		} = this.props // 写在这里的目的是为了将来一个页面可能会有多个表单。 将来在这里改一下标识即pathname就可以
		this.markId = markName ? markName : pathname // 如果是模态框用到了公共表格组件，就传name
	}

	state = {
		// 设置被选中的文件状态
		selectStatus: null,
		sortedInfo: {},
	}

	componentDidUpdate(preProps, preState) {
		if (
			preProps.location.pathname !== this.props.location.pathname &&
			preState.selectStatus !== null
		) {
			this.setState({ selectStatus: null })
		}

		// 如果状态为请求刷新后的，则清空所选数据
		if (this.props.pub_selectedStatus === -2) {
			const { pub_setSelectedStatus, getRowSelection } = this.props
			pub_setSelectedStatus(null)
			this.setState({ selectStatus: null })
			getRowSelection([], this.markId) // 把选中的当行数据发送给store
		}
	}

	static defaultProps = {
		withPreview: true,
	}
	// 设置表格头
	returnColumns = () => {
		const { tableConfig, withPreview, operationButton } = this.props
		let columns = []

		//添加拖拽手柄行
		this.props.dragSortable &&
			columns.push({
				title: '',
				key: 'sortHandle',
				width: 50,
				fixed: 'left',
			})

		if (withPreview) {
			columns.push({
				title: '预览',
				width: 50,
				dataIndex: 'preview',
				key: 'preview',
				fixed: 'left',
				render: (text, record) => (
					<FilePreviewer // 给预览图添加点击事件和鼠标
						click={
							this.props.stylePointer
								? () => {
										this.clickEvent(record)
									}
								: null
						}
						style={this.props.stylePointer && { cursor: 'pointer' }}
						fileData={text.fileData}
						isThumbnail={true}
					/>
				),
			})
		}

		tableConfig.forEach((value, index) => {
			// 取出属性名
			let keys = Object.keys(value)
			let title = keys[0]
			let width = keys.includes('width') ? 'width' : 0
			let statusType = value['statusType'] || null
			// 根据title设置渲染规则
			let change = null
			switch (title) {
				case 'retentionPeriod':
					change = retentionPeriod
					break
				case 'confidentialLevel':
					change = confidentialLevel
					break
				case 'type':
					change = recordType
					break
				case 'status':
					change = status[statusType]
					break
				case 'categoryId':
					change = collectedFileType
					break
				case 'departmentId':
					change = department
					break
				default:
					break
			}

			let columnsConfig = {
				title: value[title],
				dataIndex: title,
				key: title,
				width: width ? value[width] : 100,
				ellipsis: true,
				render: value.render,
				// 排序
				sorter: value.sorter,
				sortOrder: value.sorter ? this.state.sortedInfo.order : null,
			}
			// 如果有需要特殊渲染的则加上渲染规则
			if (columnsConfig.render !== undefined) {
				//如果有渲染函数,就什么也不干
			} else if (change) {
				columnsConfig.render = text => change[text] || '无'
			} else if (
				[
					'recordTime',
					'applyTime',
					'createTime',
					'deadline',
					'deadLine',
					'validTime',
					'time',
					'createdTime',
					'begin',
					'end',
					'timeMeta',
				].includes(columnsConfig.key)
			) {
				columnsConfig.render = text => {
					if (text) {
						return text.includes('T')
							? text.replace(/T(.*)/, '')
							: text.replace(/\s(.*)/, '')
					} else {
						if (columnsConfig.key === 'validTime') {
							return '长期'
						} else {
							return '无'
						}
					}
				}
			} else if (columnsConfig.key === 'metadataList') {
				// 使用元数据获得的时间地点等信息
				// 在配置中全写成metadataList
				const target = columnsConfig.title
				columnsConfig.render = text => {
					const metaDataObj = text.find(t => t.metadataName === target)
					let value = metaDataObj?.metadataValue
					if (metaDataObj === undefined) {
						return '无'
					} else if (metaDataObj.metadataName === '时间') {
						// 时间需要特殊处理一下
						value = value.slice(0, 10)
						return value
					} else {
						return value
					}
				}
			} else if (columnsConfig.key === 'nameList') {
				columnsConfig.render = text => {
					if (text?.length) {
						return text.join('; ')
					} else {
						return '无'
					}
				}
			} else if (columnsConfig.key === 'request') {
				columnsConfig.render = (text, record, index) => {
					for (var i = 0; i <= index; i++) {
						return `${record.recordUser}-->${record.verifyUser}:${record.message}`
					}
				}
			} else if (columnsConfig.key === 'dateFromTime') {
				//新媒体管理里的
				columnsConfig.render = text => {
					return text.replace('T', ' ')
				}
			} else if (columnsConfig.key === 'statusVo') {
				//新媒体管理里的
				columnsConfig.render = text => {
					if (text.includes('正常')) {
						return <span style={{ color: 'green' }}>正常</span>
					} else if (text.includes('已暂停')) {
						return <span style={{ color: 'gray' }}>已暂停</span>
					} else {
						return <span style={{ color: 'red' }}>{text}</span>
					}
				}
			} else {
				columnsConfig.render = text => {
					if (text === null || text === undefined) return '无'
					return text
				}
			}
			columns.push(columnsConfig)
		})
		const { noOperationButton } = this.props
		if (!noOperationButton) {
			columns.push({
				title: '操作',
				key: 'viewdetails',
				fixed: 'right',
				width: 125,
				render: (text, record) =>
					operationButton ? (
						operationButton(record, this.clickEvent)
					) : (
						<Button
							onClick={() => {
								this.clickEvent(record)
							}}>
							查看
						</Button>
					), // 多层判断决定是否自定义按钮
			})
		}

		return columns
	}

	// 设置案卷详情的表单值
	setFormRecord = pub_dossierDetails => {
		const {
			recordTime,
			recordUser,
			archiveTime,
			archiveUser,
			storageTime,
			storageUser,
			fondsName,
		} = pub_dossierDetails
		// 著录人和时间
		pub_dossierDetails['recordUserAndTime'] = { recordUser, recordTime }
		// 归档人和时间
		pub_dossierDetails['archiveUserAndTime'] = { archiveUser, archiveTime }
		// 入库人和时间
		pub_dossierDetails['storageUserAndTime'] = { storageUser, storageTime }
		if (fondsName?.match(/^\d+$/)) {
			pub_dossierDetails['fondsName'] = fondsFlatTree[fondsName * 1]
		}
		return pub_dossierDetails
	}

	// 查看详情事件执行函数
	clickEvent = record => {
		// 比如当前的路径是 http://localhost:3000/Manager/workarea/myFiles/recordFiles， lastHref 就是 RecordFiles
		let lastHref = window.location.href.split('/').pop()
		lastHref = lastHref.charAt(0).toUpperCase() + lastHref.slice(1)
		if (
			[
				'RecordFiles',
				'Prearchive',
				'ExpiredFiles',
				'DestroyFiles',
				'RecycleBin',
				'Record',
				'Archived',
				'Mine',
				'Claim',
			].includes(lastHref) === false
		) {
			lastHref = window.location.pathname
		}

		if (window.location.pathname.split('/')[3] === 'workStation') {
			lastHref = 'Workstation'
		}
		const detailsNeeded = {
			// lastHref, 考虑到一个页面可能会有多个表格，lastHref不能判断是哪个表格，因此用markName
			type: record.type,
			markName: this.markId,
		}

		sessionStorage.setItem('detailsNeeded', JSON.stringify(detailsNeeded))
		const { isCollectedFile = false, isEPhotoAlbum = false } = this.props
		record.isCollectedFile = isCollectedFile
		record.isEPhotoAlbum = isEPhotoAlbum
		const link = isCollectedFile
			? {
					title: isEPhotoAlbum ? '资料详情' : '详情',
					key: `/workarea/commonComponents/${
						isCollectedFile ? 'collectedFileDetails' : 'details'
					}`,
				}
			: {
					title: isEPhotoAlbum ? '主题详情' : '详情',
					key: `/workarea/commonComponents/${
						isEPhotoAlbum ? 'ePhotoAlbumDetails' : 'details'
					}`,
				}
		// 文件&&案卷详情的查看按钮
		this.props.getDetail(record).then(data => {
			// 请求详情数据
			data['route'] = this.props.route // 由此可以知道从哪打开详情，并判断是否添加按钮
			data['waitForVerifyUser'] = record.waitForVerifyUser // 添加文件&&案卷的被指派者id
			if (isCollectedFile) {
				this.props.addPubCollectedFile(data)
				this.props.onChange(link, history) // 修改路由
				this.props.pushRouters(link) //添加分页栏
			} else if (isEPhotoAlbum) {
				this.props.inititalPubArchive('ePhotoAlbumDetails', {
					filesData: data.files,
					archiveData: this.setFormRecord(data),
				})
				this.props.onChange(link, history) // 修改路由(如果异步，案卷下的异步表单发请求也是异步会报错)
				this.props.pushRouters(link) //添加分页栏
			} else if (record.type) {
				this.props.pushRouters(link) //添加分页栏
				this.props.addPubFileData(data) // 文件详情传到redux
				this.props.onChange(link, history) // 修改路由
			} else {
				// this.props.addPubDossierData(data)   // 档案详情传到redux
				this.props
					.getArchiveList(record)
					.then(archiveList => {
						archiveList.forEach(archiveData => {
							const { fileToken, thumbnailFileToken, fileType } =
								archiveData
							// 设置预览图
							archiveData['preview'] = {
								type: 1,
								fileData: {
									fileToken: thumbnailFileToken || fileToken,
									fileType,
								},
							}
							// 每个来个key
							archiveData['key'] = archiveData.id
						})
						this.props.inititalPubArchive('dossierDetails', {
							filesData: archiveList || [],
							archiveData: this.setFormRecord(data),
						})
					})
					.then(() => {
						this.props.onChange(link, history) // 修改路由(如果异步，案卷下的异步表单发请求也是异步会报错)
						this.props.pushRouters(link) //添加分页栏
					})
			}
		})
	}

	// 分页改变时可以调用
	// 返回获取到的表格数据，搜索时可用
	returnAllTableData = async (page, pageSize, labelId) => {
		const {
			getAllTableData,
			changeTableData,
			pub_changeisLoading,
			pub_changeCurrentPage,
			allSearchCondition,
		} = this.props
		const searchData = _.cloneDeep(allSearchCondition[this.markId])
		searchData.current = page // 增加分页器条件,从state里面拿current，因为分页器onChange会改变这个值
		searchData.size = pageSize
		// 特殊情况，我的档案有个接口与普通的不同，需要多一个labelId
		if (labelId) {
			searchData.labelId = labelId
		}
		const ajaxConfig = searchData.ajaxConfig
		delete searchData.ajaxConfig // 记得删掉
		pub_changeisLoading(true, this.markId) // 先置为true
		pub_changeCurrentPage(searchData.current, searchData.size, this.markId) // 传当前页码和每页显示条数到redux
		//ajax
		let result = await this.searchFunc(ajaxConfig, searchData) // 如果是搜索的话，就传搜索条件data
		let tableData = {}
		if (result && result.data.data) {
			tableData = changeTableData(result.data.data) // 调用父组件传来的changeTableData，修改获得的表格的数据成符和格式的数据
			let Data = _.cloneDeep(tableData) // 深拷贝一份数据
			Data.data.forEach(item => {
				// 让每一个表格数据都获得key
				item.key = item.id
			})
			pub_changeisLoading(false, this.markId) // 获取到数据就置为false
			getAllTableData(Data, this.markId) // 把表格数据发送到store
		}
	}

	searchFunc = (ajaxConfig, data) => {
		// 请求后台表格数据，发送请求时应该先请求第一页数据
		const {
			ajaxType,
			url,
			ContentType = 'application/json',
			beforeFetch,
		} = ajaxConfig
		let option = {
			type: ajaxType,
			url,
			data,
			headers: {
				'Content-Type': ContentType, // 默认是'application/json'
			},
			beforeFetch,
		}
		return _fetch(option)
	}

	returnRowSelection = record => {
		// 返回表格的行配置
		const { getRowSelection, pub_rowSelection, location, notRowSelection } =
			this.props
		if (notRowSelection) return null // 如果配置有需要不用多选框则返回null
		const selectedData = pub_rowSelection[this.markId] || []
		if (record) {
			// 如果触发行触
			//触发行触有三种情况
			//1.不是收藏的table 可以触发行触
			//2.是收藏的table 而且此文件的状态等于已选中文件的状态
			//3.是收藏的table 而且是第一个要被选中的文件
			if (
				!location.pathname.includes('workStation') ||
				record.status === this.state.selectStatus ||
				this.state.selectStatus === null
			) {
				if (_.find(selectedData, record)) {
					//原先数据如果存在 取消选中
					_.remove(selectedData, record) // 删掉
					getRowSelection(selectedData, this.markId) // 更新store
				} else {
					getRowSelection([record, ...selectedData], this.markId) // 把选中的当行数据发送给store
					//如果是收藏,而且第一个被选中,设置状态
					if (
						location.pathname.includes('workStation') &&
						this.state.selectStatus === null
					) {
						this.setState({ selectStatus: record.status })
						this.props.pub_setSelectedStatus(record.status)
					}
				}
			}
		}

		const onSelect = (record, selected, selectedRows, event) => {
			//判断是选中还是取消，选中直接添加，取消就从store里面删掉，下面同理
			if (selected) {
				getRowSelection([record, ...selectedData], this.markId)
			} else {
				let newSeletedData = selectedData.filter(item => {
					return item.id !== record.id
				})
				getRowSelection(newSeletedData, this.markId)
			}
		}

		const onSelectAll = (selected, selectedRows, changeRows) => {
			// 全选对数据的处理
			if (selected) {
				getRowSelection([...changeRows, ...selectedData], this.markId)
			} else {
				let newSeletedData = [...selectedData] //拷贝一份
				changeRows.forEach(item => {
					_.remove(newSeletedData, item) //删除取消全选的所有项
				})
				getRowSelection(newSeletedData, this.markId)
			}
		}

		const rowSelection = {
			type: 'checkbox',
			onSelect,
			onSelectAll,
			selectedRowKeys: pub_rowSelection[this.markId]
				? pub_rowSelection[this.markId].map(item => item.id)
				: [],
		}
		if (location.pathname.includes('workStation')) {
			const { selectStatus } = this.state
			const { pub_setSelectedStatus } = this.props

			// 配置只有status等于当前选中的项的status才可以被选中
			rowSelection.getCheckboxProps = record =>
				selectStatus === null
					? {
							disabled: false,
						}
					: {
							disabled: record.status !== selectStatus, // 配置无法勾选的列
						}
			// 只有当前有选中项才可以全选
			rowSelection.hideSelectAll = selectStatus === null ? true : false
			// 设置当前选中项的status到state里面
			// 把当前选中的项的状态存进redux
			rowSelection.onSelect = (record, selected, selectedRows) => {
				pub_setSelectedStatus(record.status)
				if (selected) {
					if (record.status !== this.state.selectStatus) {
						this.setState({ selectStatus: record.status })
						pub_setSelectedStatus(record.status)
					}
					getRowSelection([record, ...selectedData], this.markId)
				} else {
					if (selectedRows.length === 0) {
						this.setState({ selectStatus: null })
						pub_setSelectedStatus(null)
					}
					let newSeletedData = selectedData.filter(item => {
						return item.id !== record.id
					})
					getRowSelection(newSeletedData, this.markId)
				}
			}
			// 当取消全选时清空state里的selectStatus
			rowSelection.onSelectAll = (selected, selectedRows, changeRows) => {
				if (!selected) {
					let newSeletedData = [...selectedData] //拷贝一份
					changeRows.forEach(item => {
						_.remove(newSeletedData, item) //删除取消全选的所有项
					})
					getRowSelection(newSeletedData, this.markId)
					this.setState({ selectStatus: null })
					pub_setSelectedStatus(null)
				}
			}
		}
		return rowSelection
	}
	isLoading = () => {
		// 返回loading，首次加载和搜索时,后台数据还未返回就会先出现loading标志
		return this.props.pub_isLoading[this.markId]
	}

	// 生成分页显示器配置
	returnPagination = () => {
		const { pub_allTableData, noShowQuickJumper, noshowpagination } = this.props
		if (noshowpagination === true) return false
		let temp = pub_allTableData[this.markId]
		if (temp && temp.total !== undefined && temp.current !== undefined) {
			// 确保temp存在并且有total字段和current字段，我的档案里面的接口需要labelId
			const { total, pageSize, current, labelId } = temp // 从props里面拿分页器设置，根据后台返回数据
			// 改变 页数 或者 pageSize切换器大小 时触发
			let onChange = (page, pageSize) => {
				labelId
					? this.returnAllTableData(page, pageSize, labelId)
					: this.returnAllTableData(page, pageSize)
			}
			// 显示目前位置
			let showTotal = () => {
				let start = (current - 1) * pageSize + 1,
					tempEnd = current * pageSize,
					end = tempEnd > total ? total : tempEnd
				return `显示 第 ${
					end === 1 ? '1 ' : `${start} - ${end}`
				} 条 ， 共  ${total} 条`
			}
			// 分页显示器配置
			let pagination = {
				position: ['bottomCenter'], // 分页器位置统一设置在bottomCenter
				total, // 数据总数
				current, // 当前页数，后台会默认第一页
				pageSize, // 每页条数
				showSizeChanger: true, // 是否展示 pageSize 切换器
				onChange: onChange, // 页码改变的回调，参数是改变后的页码及每页条数
				showTotal: showTotal, // 用于显示数据总量和当前数据顺序
				showQuickJumper: noShowQuickJumper === false ? noShowQuickJumper : true, // 直接跳转到某一页
			}
			return pagination
		}
		return {}
	}

	returnData = () => {
		// 返回对应的tableData
		const { pub_isLoading, pub_allTableData, withPreview } = this.props
		let temp = pub_allTableData[this.markId]
		let data = []
		if (temp !== undefined && !pub_isLoading[this.markId]) {
			temp?.data?.forEach((value, index) => {
				if (withPreview) {
					const {
						type,
						fileToken,
						fileType,
						thumbnailFileToken,
						thumbnailFileType,
					} = value
					const fileData = {
						fileToken: fileToken || thumbnailFileToken,
						fileType: fileType || thumbnailFileType,
					}
					value.preview = { type, fileData }
				}

				//前端校验静态错误数据，比如数组[null,'name']
				if (value.nameList) {
					for (let i = 0; i < value.nameList.length; i++) {
						if (
							value.nameList[i] === null ||
							value.nameList[i] === undefined
						) {
							value.nameList.splice(i, 1)
						}
					}
				}
				data.push(value)
			})
		}
		return data
	}
	// 排序用的 改变顺序后重新设置状态
	handleChange = (pagination, filters, sorter) => {
		this.setState({
			sortedInfo: sorter,
		})
	}

	//dnd-kit拖拽排序 拖拽完成回调
	/**
	 *
	 * @param {Object} object acitve:被触发的那个元素的idover:被移动的最后一个元素的id
	 */
	onDragEnd = async ({ active, over }) => {
		const { pub_allTableData, getAllTableData } = this.props

		//获取的是当前markid下的全部的数据
		let tableData = this.returnData()

		if (active && over && active.id !== over.id) {
			//状态数据排序
			tableData = arrayMove(
				tableData,
				tableData?.findIndex(i => i.id === active.id),
				tableData?.findIndex(i => i.id === over?.id)
			)

			//无需同步到状态，直接同步给后端，实现非乐观更新
			pub_allTableData[this.markId].data = tableData
			//同步到状态,从原理上来来说无需同步到redux但是为了防止拖动结束的一瞬间元素有向下归位的动作，添加同步到状态
			getAllTableData(pub_allTableData[this.markId], this.markId)

			//需要参数 active prevId postId prevSortId postSortId updateType prevType postType
			//id
			const activeIndex = tableData?.findIndex(item => item.id === active.id)
			const prevId = tableData[activeIndex - 1]?.id
			const postId = tableData[activeIndex + 1]?.id
			//sortID
			const prevSortId = tableData[activeIndex - 1]?.sortId
			const postSortId = tableData[activeIndex + 1]?.sortId
			//type
			//TODO
			//console.log(tableData)
			const updateType = tableData[activeIndex]?.type
			const prevType = tableData[activeIndex - 1]?.type
			const postType = tableData[activeIndex + 1]?.type
			//console.log('type:', updateType, prevType, postType)
			this.handleDragSort(
				active.id,
				prevId ? prevId : null,
				postId ? postId : null,
				prevSortId ? prevSortId : null,
				postSortId ? postSortId : null,
				//type有0，不要转null
				updateType,
				prevType,
				postType
			) //同步给后端
		}
	}

	//拖拽排序同步到后端函数
	/**
	 * 将排序的参数传给后端排序
	 * @param {number|null} updateId 目标元素的id
	 * @param {number|null} prevId 目标元素排序后前一个元素的id
	 * @param {number|null} postId 目标元素排序后后一个元素的id
	 * @param {number|null} prevSortId 目标元素排序后前一个元素的SortedId （SortedId会在每次排序后发生改变，即必须使用非乐观）
	 * @param {number|null} postSortId 目标元素排序后后一个元素的SortedId
	 * @param {number|null} updateType 目标元素排序元素的Type
	 * @param {number|null} prevType 目标元素排序后前一个元素的Type
	 * @param {number|null} postType 目标元素排序后后一个元素的Type
	 */
	handleDragSort = (
		updateId,
		prevId,
		postId,
		prevSortId,
		postSortId,
		updateType,
		prevType,
		postType
	) => {
		const { pub_allTableData } = this.props
		_fetch({
			url: dossierUrl.dragSortting,
			type: 'post',
			data: {
				updateId,
				prevId,
				postId,
				prevSortId,
				postSortId,
				updateType,
				prevType,
				postType,
			},
			isJson: true,
		}).then(() => {
			const { current: page, pageSize, labelId } = pub_allTableData[this.markId]
			labelId
				? this.returnAllTableData(page, pageSize, labelId)
				: this.returnAllTableData(page, pageSize)
		})
	}

	render() {
		const columns = this.returnColumns(),
			dataSource = this.returnData(),
			loading = this.isLoading(),
			pagination = this.returnPagination(),
			rowSelection = this.props.rowSelection || this.returnRowSelection(),
			onRow = this.props.onRow || this.returnRowSelection

		//手动开启拖拽排序
		if (this.props.dragSortable) {
			return (
				<DndContext
					modifiers={[restrictToVerticalAxis]}
					onDragEnd={this.onDragEnd}>
					<SortableContext
						// 使排序的元素能访问到整个数组的id和sortId
						items={dataSource.map(i => i.id)}
						strategy={verticalListSortingStrategy}>
						<Table
							//使用Row函数组件覆盖默认的table元素
							components={{
								body: {
									row: Row,
								},
							}}
							bordered
							scroll={{
								x: 1000,
								y: Number(sessionStorage.getItem('tableHeight')),
							}}
							className='base-table'
							columns={columns} // 表格列的配置描述，更多配置可以查看antd的表格columns配置
							dataSource={dataSource} // 数据数组
							loading={loading}
							pagination={pagination} // 分页的配置
							rowSelection={rowSelection} // 多选框配置
							onRow={
								// 行触事件
								this.props.onRowEvent
									? record => ({
											onClick: () => {
												onRow(record)
											},
										})
									: null
							}
							onChange={this.handleChange}
						/>
					</SortableContext>
				</DndContext>
			)
		}
		return (
			<Table
				bordered
				scroll={{ x: 1000, y: Number(sessionStorage.getItem('tableHeight')) }}
				className='base-table'
				columns={columns} // 表格列的配置描述，更多配置可以查看antd的表格columns配置
				dataSource={dataSource} // 数据数组
				loading={loading}
				pagination={pagination} // 分页的配置
				rowSelection={rowSelection} // 多选框配置
				onRow={
					// 行触事件
					this.props.onRowEvent
						? record => ({
								onClick: () => {
									onRow(record)
								},
							})
						: null
				}
				onChange={this.handleChange}
			/>
		)
	}
}

//拖拽排序 使用row覆盖表格列 作为一个函数组件row传入给table
const Row = ({ children, ...props }) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({
		//使用data-row-key作为sortable的id
		id: props['data-row-key'],
	})

	//在dnd-kit提供的 style上修改
	const style = {
		...props.style,
		transform: CSS.Transform.toString(
			transform && {
				...transform,
				scaleY: 1,
			}
		),
		transition,
		...(isDragging
			? {
					position: 'relative',
					//保证显示在上层
					zIndex: 9999,
				}
			: {}),
	}

	return (
		<tr {...props} ref={setNodeRef} style={style} {...attributes}>
			{React.Children.map(children, child => {
				// 在sortHandle行添加可拖拽手柄
				if (child.key === 'sortHandle') {
					return React.cloneElement(child, {
						children: (
							<MenuOutlined
								ref={setActivatorNodeRef}
								style={{
									touchAction: 'none',
									cursor: 'move',
								}}
								{...listeners}
							/>
						),
					})
				}
				return child
			})}
		</tr>
	)
}

// 对传入的属性进行类型检查
BaseTable.propTypes = {
	changeSearchLimit: PropTypes.func, // 增加修改限制
	changeTableData: PropTypes.func, // 修改表格数据
}

@connect(mapStateToProps, mapDispatchToProps) // 装饰器
@withRouter
@injectUnmount
class TableRef extends Component {
	render() {
		return <BaseTable {...this.props} ref={this.props.myForwardedRef} /> // 转发ref，不然拿到的是connect高阶组件的ref
	}
}

export default forwardRef((props, ref) => <TableRef {...props} myForwardedRef={ref} />)
