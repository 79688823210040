import {
	INITIAL_PUBARCHIVE,
	UPGRADE_PUBARCHIVE,
	REMOVE_PUBARCHIVE,
	ROWSELECTION_PUBARCHIVE,
} from '@/Redux/ActionTypes/PubArchive'
import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import {
	addArchivesUrl,
	dossierUrl,
	archiveUrl,
	filesUrl,
	archiveTypeUrl,
} from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
import { message } from 'antd'
import { PUBFILE_ADD } from '@/Redux/ActionTypes/Public/PubForm'
import { dossierEnum } from '@/Utils/Enum'
import SparkMD5 from 'spark-md5'
import asyncPool from '@/Utils/AsyncPool'

const spark = new SparkMD5.ArrayBuffer()

// 初始化pubArchive[markName].old
export const inititalPubArchiveAction = (markName, pubArchiveData) => {
	return {
		type: INITIAL_PUBARCHIVE,
		filter: {
			markName,
			...pubArchiveData,
		},
	}
}
// 添加或更新pubArchive[markName].new
export const upgradePubArchiveAction = (markName, pubArchiveData) => {
	return {
		type: UPGRADE_PUBARCHIVE,
		filter: {
			markName,
			...pubArchiveData,
		},
	}
}
// 删除pubArchive[markName]
export const removePubArchiveAction = markName => {
	return {
		type: REMOVE_PUBARCHIVE,
		filter: { markName },
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

// 新增案卷
export const insertDossierThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: addArchivesUrl.insertDossier,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 档案组卷
export const packArchiveThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: addArchivesUrl.packArchive,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

//拆卷
export const separateDossierAction = (archiveIds, dossierId) =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dossierUrl.separateDossier,
			type: 'post',
			data: {
				ids: archiveIds,
				dossierId,
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

//获取案卷详情
export const getDossierDetailAction = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dossierUrl.getDossierDetail,
			data: {
				id,
			},
		}).then(res => {
			if (res.data.success) {
				resolve(res.data)
			} else {
				reject(res)
			}
		})
	})

//将案卷详情页被选择项推到redux
export const pushArchiveSelectedAction = rowSelectedData => {
	return {
		type: ROWSELECTION_PUBARCHIVE,
		filter: rowSelectedData,
	}
}

// 导出案卷信息

export const exportDossier = (dossierIds, templateType, fileName) => {
	_fetch({
		url: dossierUrl.generateDossierPdf,
		type: 'post',
		data: {
			templateType,
			dossierIds,
		},
		headers: {
			'Content-Type': 'application/json',
		},
		responseType: 'blob',
	}).then(res => {
		if (res.headers['content-type'] === 'application/json') {
			message.error('该案卷还未入库')
			return
		}
		const blob = res.data
		const urlObject = window.URL || window.webkitURL || window
		const export_blob = new Blob([blob], { type: 'application/octet-stream' })
		const a = document.createElement('a') // 利用a标签特性下载
		a.style.display = 'none'
		const url = urlObject.createObjectURL(export_blob)
		a.href = url
		a.setAttribute('download', `${fileName}.pdf`)
		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)
		message.success('成功导出！')
	})
}
export const exportExcelZip = (dossierIds, fileName, fileType) => {
	_fetch({
		url: dossierUrl.exportExcelZip,
		headers: { 'Content-Type': 'application/json' },
		type: 'post',
		data: {
			dossierIds,
			type: fileType,
		},
		responseType: 'blob',
	})
		.then(res => {
			const blob = res.data
			const urlObject = window.URL || window.webkitURL || window
			const exportBlob = new Blob([blob], { type: 'application/zip' })
			const a = document.createElement('a')
			a.style.display = 'none'
			const url = urlObject.createObjectURL(exportBlob)
			a.href = url
			a.setAttribute('download', `${fileName}.zip`)
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
			message.success('成功导出！')
		})
		.catch(err => {
			message.error('档案导出失败')
		})
	message.success('档案开始导出')
}

export const addPubFileAction = data => {
	return {
		type: PUBFILE_ADD,
		data: data,
	}
}

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 获取案卷或档案（文件）详情
 * @param {Object} record 案卷或档案（文件）的信息
 */
export const getDetailThunk = record => () =>
	new Promise(async (resolve, reject) => {
		const { id, type, archiveType } = record
		let url = null
		switch (dossierEnum.type[type]) {
			case '案卷':
				url = dossierUrl.getDossierDetail
				break
			case '文件':
				archiveType === 1
					? (url = archiveUrl.getArchiveDetail)
					: (url = archiveUrl.getDocDetail)
				break
			default:
				return
		}
		let res = await _fetch({
			url,
			data: {
				id,
			},
		})
		//console.log(res)
		if (res && res.data.code === 200) {
			if (type) {
				resolve({
					...res.data.data,
					type: record.type,
				})
			} else {
				// 案卷顺带请求其下的列表
				_fetch({
					url: archiveUrl.getArchiveList,
					type: 'get',
					data: {
						id,
						size: 999,
					},
					isJson: true,
				}).then(data => {
					if (data && data.data.code === 200) {
						res.data.data['archiveDetail'] = data.data.data.records || []
						resolve({
							...res.data.data,
							type: record.type,
						})
					} else {
						reject()
					}
				})
			}
		} else {
			reject()
		}
	})

/**
 * 文件切片
 * @param {file} file
 */
export const createFileChunk = file => {
	const fileChunkList = []
	const CHUNK_SIZE = 2097152 // 切片大小为 2M

	for (let start = 0; start < file.size; start += CHUNK_SIZE) {
		fileChunkList.push({
			file: file.slice(start, start + CHUNK_SIZE),
		})
	}

	return fileChunkList
}

/**
 * 创建文件的 md5 标识
 * @param {array} fileChunkList
 */
export const getFileMd5 = fileChunkList => {
	let count = 0
	const totalCount = fileChunkList.length
	const fileReader = new FileReader()
	return new Promise((resolve, reject) => {
		fileReader.onload = e => {
			if (e.target && e.target.result) {
				count += 1
				spark.append(e.target.result)
			}
			if (count < totalCount) {
				loadNext()
			} else {
				resolve(spark.end())
			}
		}

		fileReader.onerror = function () {
			reject({
				message: '文件读取失败',
			})
		}

		const loadNext = () => {
			fileReader.readAsArrayBuffer(fileChunkList[count]?.file)
		}

		loadNext()
	})
}

const getChunk = wholeMd5 => {
	return _fetch({
		url: filesUrl.getChunk,
		type: 'get',
		data: {
			wholeMd5,
		},
		isJson: true,
	})
}
/**
 * 上传文件（断点续传）
 * @param {*} fileMd5Value
 * @param {*} fileName
 * @param {*} fileChunkList
 */
export const uploadFile = async (
	fileMd5Value,
	fileName,
	fileChunkList,
	fileType,
	files
) => {
	const total = fileChunkList.length
	let fileCurrent = (await getChunk(fileMd5Value))?.data?.data + 1
	let progressCurrent = fileCurrent

	fileChunkList = fileChunkList.map(fileChunk => {
		return new File([fileChunk.file], fileName, {
			type: fileType,
			lastModified: Date.now(),
		})
	})

	// eslint-disable-next-line
	for await (const _ of asyncPool(
		2,
		fileChunkList.slice(fileCurrent - 1, total - 1),
		file => {
			const formData = new FormData()
			formData.append('file', file)
			formData.append('wholeMd5', fileMd5Value)
			formData.append('chunks', total)
			formData.append('chunk', fileCurrent)

			fileCurrent++
			return _fetch({
				url: filesUrl.fileUpload,
				type: 'post',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}).then(() => {
				files.onProgress(
					{
						percent: ((progressCurrent / fileChunkList.length) * 100) | 0,
					},
					files.file
				)
				progressCurrent++
			})
		}
	)) {
	}

	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const file = fileChunkList[total - 1]

		formData.append('file', file)
		formData.append('wholeMd5', fileMd5Value)
		formData.append('chunks', total)
		formData.append('chunk', total)

		_fetch({
			url: filesUrl.fileUpload,
			type: 'post',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				files.onProgress(
					{
						percent: 100,
					},
					files.file
				)
				resolve(res.data)
			} else {
				reject(res.data)
			}
		})
	})
}

// 新建临时档案
export const addTemporaryArchiveThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.addTemporaryArchive,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 查询临时文件
export const selectTemporaryArchiveThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.selectTemporaryArchive,
			type: 'get',
			isJson: true,
			headers: {
				'Cache-control': 'no-store',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取类别号
export const getDossierTypeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: archiveTypeUrl.getDossierType,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})


//删除查看页照片和音频的函数
export const removeImage = (filesData) => {
	for (let index = 0; index < filesData.length; index++) {
		const pubArchive_removeImage_filesData = []//储存数据
		filesData.forEach(item => {
			if (item.fileType != "image/jpg" &&  item.fileType != "audio/mp3") {
				pubArchive_removeImage_filesData.push(item)
			}
		})
		return pubArchive_removeImage_filesData
	}
}