import React from 'react'
import { Select } from 'antd'

const options = [
	{
		label: '全宗号',
		value: 'q',
		key: 'q',
	},
	{
		label: '卷/件标识',
		value: 'j',
		key: 'j',
	},
]

function SelectNumber(props) {
	const { setFormat, formatIsNumber, hadConfig } = props
	return (
		<div className='select-number'>
			全宗号、卷/件标识中哪个作为数字格式：
			<Select
				value={formatIsNumber}
				disabled={hadConfig}
				onChange={(value, option) => {
					setFormat(value)
				}}
				style={{ width: 120 }}
				options={options}
			/>
		</div>
	)
}

export default React.memo(SelectNumber)
