import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { userManagerColumns } from '@/Public/PubTable/pageColumns'
import { userManagerUrl } from '@/Utils/Urls'
import { connect } from 'react-redux'
import AddUserModal from './AddUserModal'
import EditUserModal from './EditUserModal'
import AddRolesModal from './AddRolesModal'
import DepartmentTree from './DepartmentTree'
import Buttons from './Buttons'
import { Button, Space, message, Modal } from 'antd'
import {
	deleteUserThunk,
	downloadModelThunk,
	downloadExcelThunk,
	getAllRoleThunk,
	getUserRoleThunk,
} from './ActionCreators'
import './index.less'

const markName = 'UserManager'
const AddUserModalRef = React.createRef()
const EditUserModalRef = React.createRef()
const AddRolesModalRef = React.createRef()

const mapStateToProps = state => {
	return {}
}
const mapDispatchToProps = dispatch => {
	return {
		deleteUser: (params, func) => dispatch(deleteUserThunk(params, func)),
		downloadModel: (...rest) => dispatch(downloadModelThunk(...rest)),
		downloadExcel: (...rest) => dispatch(downloadExcelThunk(...rest)),
		getAllRole: (...rest) => dispatch(getAllRoleThunk(...rest)),
		getUserRole: (...rest) => dispatch(getUserRoleThunk(...rest)),
	}
}
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class UserManager extends Component {
	state = {
		editFormRef: {},
		selectedNodeMsg: {}, // 选中的树节点的信息
	}

	reloadTable() {
		this.props.pub_getAllData()
	}
	componentDidMount() {
		this.props.getAllRole().then(res => {
			sessionStorage.setItem('AddRolesList', JSON.stringify(res.data.records))
		})
	}

	// 表单配置项
	searchFormConfig = {
		formItems: [
			{
				type: 'Extra',
				name: '操作按钮',
				span: 7,
				render: formRef => {
					return (
						<Buttons
							formRef={formRef}
							reloadTable={this.reloadTable}
							showAddUserManagerModal={this.showAddUserManagerModal}
							deleteUser={this.deleteUser}
							showEditUserManagerModal={this.showEditUserManagerModal}
							handledownloadExcel={this.handledownloadExcelModel}
							handledownloadModel={this.handledownloadModelTips}
						/>
					)
				},
			},
			{
				type: 'Input',
				name: 'workId',
				label: '用户名',
				width: '150px',
			},
			{
				type: 'Input',
				name: 'userName',
				label: '姓名',
				width: '150px',
			},
			{
				type: 'AsyncSelect',
				name: 'roleId',
				label: '角色',
				placeholder: '请选择角色',
				ajaxConfig: {
					url: userManagerUrl.getRole,
					type: 'get',
				},
				formatDataFunc: data => {
					return data.map(item => ({
						label: item.description,
						value: item.id,
					}))
				},
				width: '200px',
			},
			// {
			//   type: "DatePicker",
			//   name: "validTime",
			//   label: "有效时间",
			//   placeholder: "请输入有效时间",
			//   width: "250px",
			//   disabledDate: () => false,
			// },
			{
				type: 'DatePicker',
				name: 'validTime',
				label: '有效时间',
				width: '320px',
				maxWidth: '320px',
				disabledDate: () => false,
				isRange: true,
			},
		],
		ajaxConfig: {
			ajaxType: 'post',
			url: userManagerUrl.getUserList,
			ContentType: 'application/json',
			beforeFetch: data => {
				// 发送请求前对请求数据做的一些处理
				if (data.validTime === null) {
					delete data.validTime
				}
				if (Array.isArray(data.validTime)) {
					data.validTime = [
						data.validTime[0].format('YYYY-MM-DD'),
						data.validTime[1].format('YYYY-MM-DD'),
					]
					data.validTime = `[${data.validTime.join(',')}]`
				}
			},
		},
	}
	/**
	 * 用于修改表格的请求参数
	 * @param {请求参数} data
	 */
	changeSearchLimit = data => {
		const { selectedNodeMsg } = this.state
		if (JSON.stringify(selectedNodeMsg) !== '{}' && selectedNodeMsg['id'] !== -1) {
			data['departmentId'] = selectedNodeMsg['id']
		}
		data.input = 'input'
		return data
	}
	// 把拿到的表格数据格式改造成符合公共组件格式的数据
	// 这里的data为从后台接收的数据，必须将data转为以下格式返回（data.files为后台返回的示例），即将对应的表格数据作为data.data返回
	changeTableData = data => {
		data = {
			data: data.records,
			pageSize: data.size,
			current: data.current, // 后台给的数据默认是第一页
			total: data.total,
		}
		return data
	}

	// 显示新增用户的模态框
	showAddUserManagerModal = () => {
		// 如果左边树形图有选中的节点，则默认其为部门
		setTimeout(() => {
			AddUserModalRef.current.formRef.setFieldsValue({
				departmentId: this.state.selectedNodeMsg?.id,
			})
		}, 600)
		AddUserModalRef.current.show(formRef => {})
	}
	// 显示编辑用户的模态框
	showEditUserManagerModal = () => {
		const {
			pub_rowSelection,
			location: { pathname },
		} = this.props
		let selectedData = pub_rowSelection[pathname]
		if (!selectedData || selectedData.length === 0) {
			message.info('请选择要编辑的用户')
			return
		} else if (selectedData.length > 1) {
			message.info('不允许同时编辑多个用户')
		} else {
			sessionStorage.setItem('editUserId', JSON.stringify(selectedData[0]['id']))
			EditUserModalRef.current.show(formRef => {
				formRef.setFieldsValue(selectedData[0])
			})
		}
	}

	showAddRolesModal = record => {
		sessionStorage.setItem('userId', JSON.stringify(record.id))
		let dataSource = JSON.parse(sessionStorage.getItem('AddRolesList'))
		const selectedRowKeys = [] // 已选中项key
		let ownIds = []
		this.props.getUserRole(record.id).then(res => {
			res.data.forEach(item => {
				ownIds.push(item.id)
			})
			for (var i = 0; i < dataSource.length; i++) {
				if (ownIds.indexOf(dataSource[i]['id']) > -1) {
					dataSource[i]['status'] = true
					selectedRowKeys.push(dataSource[i].id)
				} else {
					dataSource[i]['status'] = false
				}
			}
			dataSource[0]['status'] = true //默认都是普通用户
			AddRolesModalRef.current.show(formRef => {
				sessionStorage.setItem('beforeIds', JSON.stringify(selectedRowKeys))
				formRef.setFieldsValue({
					AddRoleTable: { dataSource, selectedRowKeys },
				})
			})
		})
	}

	// 删除用户
	deleteUser = () => {
		const {
			deleteUser,
			pub_rowSelection,
			location: { pathname },
			pub_getAllData,
		} = this.props
		let selectedData = pub_rowSelection[pathname]
		if (!selectedData || selectedData.length === 0) {
			message.info('请选择要删除的用户')
			return
		} else {
			Modal.confirm({
				title: '提示',
				content: '您确定要删除该用户吗？',
				onOk: close => {
					Promise.all(
						selectedData.map(item => {
							return deleteUser({ id: item.id })
						})
					)
						.then(res => {
							pub_getAllData() //刷新表格
							message.success('删除成功')
						})
						.catch(err => {
							message.error('删除失败')
						})
					close()
				},
				onCancel: close => {
					close()
				},
			})
		}
	}
	// 查看详情
	returnButton = (record, index) => {
		return (
			<Space>
				<Button
					size='small'
					onClick={() => this.showAddRolesModal(record, index)}>
					授权
				</Button>
			</Space>
		)
	}

	handledownloadModelTips = (formRef, callback) => {
		Modal.confirm({
			title: '提示',
			content: '您确定要导出用户数据模板吗？',
			onOk: close => {
				this.handledownloadModel(formRef, callback)
				close()
			},
			onCancel: close => {
				close()
			},
		})
	}

	handledownloadModel = async callback => {
		callback.call(Buttons, 'exportTemplateLoading') //子组件传入的函数，修改子组件的state
		const { downloadModel } = this.props
		await downloadModel()
		callback.call(Buttons, 'exportTemplateLoading')
	}

	handledownloadExcelModel = (formRef, callback) => {
		Modal.confirm({
			title: '提示',
			content: '您确定要导出用户数据吗？',
			onOk: close => {
				this.handledownloadExcel(formRef, callback)
				close()
			},
			onCancel: close => {
				close()
			},
		})
	}

	handledownloadExcel = async (formRef, callback) => {
		callback.call(Buttons, 'exportUserLoading') //子组件传入的函数，修改子组件的state
		let condition = formRef.getFieldsValue()
		const { selectedNodeMsg } = this.state
		let filterCondition = {}
		filterCondition['workId'] =
			condition['workId'] !== undefined ? condition['workId'] : ''
		filterCondition['userName'] =
			condition['userName'] !== undefined ? condition['userName'] : ''
		filterCondition['roleId'] =
			condition['roleId'] !== undefined ? condition['roleId'] : ''
		filterCondition['validTime'] =
			condition['validTime'] !== undefined ? condition['validTime'] : ''
		if (JSON.stringify(selectedNodeMsg) !== '{}' && selectedNodeMsg['id'] !== -1) {
			filterCondition['departmentId'] = selectedNodeMsg['id']
		}
		const { downloadExcel } = this.props
		await downloadExcel(filterCondition)
		callback.call(Buttons, 'exportUserLoading')
	}

	// 选中节点触发并重新获取表格信息
	updateSelectNode = selectedNodeMsg => {
		const { pub_getAllData } = this.props
		this.setState({ selectedNodeMsg }, pub_getAllData)
	}

	render() {
		const { pub_getAllData } = this.props
		const { selectedNodeMsg } = this.state

		return (
			<Fragment>
				<div className='userManagementWrapper'>
					{/* 左边部门树组件 */}
					<div id='depart-left'>
						<DepartmentTree
							updateSelectNode={this.updateSelectNode} // 更新选中信息的方法
							selectedNodeMsg={selectedNodeMsg} // 当前树选中节点的信息
							openAddDepartmentModal={this.openAddDepartmentModal}
							openEditDepartmentModal={this.openEditDepartmentModal}
							handleDeleteDepartment={this.handleDeleteDepartment} // 删除部门的方法
							getInstance={ref => {}} // 让页面获取树组件实例的方法，由 WithRef 高阶去调用
						/>
					</div>
					{/* 右边表格部分 */}
					<div id='depart-right'>
						<WrappedTable>
							<SearchForm
								name={'searchFormDemo'}
								formItems={this.searchFormConfig}
								getInstance={formRef => {
									this.searchFormRef = formRef
								}}
								searchButtonText='查询'
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData}
							/>
							<BaseTable
								tableConfig={userManagerColumns}
								changeTableData={this.changeTableData} // 把拿到的表格数据改造成符合公共组件所需数据的格式
								changeSearchLimit={this.changeSearchLimit} // 这里也传一下，后续开发公共组件可能会用到
								actions={this.actions}
								operationButton={this.returnButton} // 自定义操作按钮
								withPreview={false}
							/>
						</WrappedTable>
						<AddUserModal
							modalRef={AddUserModalRef}
							reflashTable={pub_getAllData}
						/>
						<EditUserModal
							modalRef={EditUserModalRef}
							reflashTable={pub_getAllData}
						/>
						<AddRolesModal
							modalRef={AddRolesModalRef}
							reflashTable={pub_getAllData}
						/>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default UserManager
