import {
	INITIAL_PUBARCHIVE,
	UPGRADE_PUBARCHIVE,
	REMOVE_PUBARCHIVE,
	ROWSELECTION_PUBARCHIVE,
} from '@/Redux/ActionTypes/PubArchive'
import initialState from '../../InitialState'
import { cloneDeep } from 'lodash'
// 初始化pubArchive
const initialPubArchive = (state, action) => {
	let pubArchive = cloneDeep(state)
	const { markName, filesData, archiveData } = action
	pubArchive[markName] = {
		old: {
			filesData,
			archiveData,
		},
		new: {
			filesData,
			archiveData,
		},
	}

	return pubArchive
}
// 更新pubArchive[markName].new
const upgradePubArchive = (state, action) => {
	let pubArchive = cloneDeep(state)
	const { markName, filesData, archiveData } = action
	if (filesData) {
		pubArchive[markName].new.filesData = filesData
	}
	if (archiveData) {
		pubArchive[markName].new.archiveData = archiveData
	}
	return pubArchive
}
// 删除某个pubArchive
const removePubArchive = (state, action) => {
	let pubArchive = cloneDeep(state)
	const { markName } = action
	delete pubArchive[markName]
	return pubArchive
}

//更新案卷详情页被选择项
const pushDossierDetail = (state, rowSelectedData) => {
	let pubArchive = cloneDeep(state)
	pubArchive.rowselection = rowSelectedData
	return pubArchive
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState.pubArchive, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case INITIAL_PUBARCHIVE:
			return initialPubArchive(state, action.filter)
		case UPGRADE_PUBARCHIVE:
			return upgradePubArchive(state, action.filter)
		case REMOVE_PUBARCHIVE:
			return removePubArchive(state, action.filter)
		case ROWSELECTION_PUBARCHIVE:
			return pushDossierDetail(state, action.filter)
		default:
			return state
	}
}
