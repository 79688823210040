import { TAB_ADD } from '@/Redux/ActionTypes/Route'
import { addArchivesUrl, recordFileUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

// 案卷归档
export const archiveDossierThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: recordFileUrl.archiveDossier,
			type: 'post',
			data,
			isJson: true,
		})
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})

// 文件组卷
export const packArchiveThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: addArchivesUrl.packArchive,
			type: 'post',
			data,
			isJson: true,
		})
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})

// 新增案卷
export const insertDossierThunk = (data, callback) => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: addArchivesUrl.insertDossier,
			type: 'post',
			data,
			isJson: true,
		})
			.then(res => {
				callback()
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})
