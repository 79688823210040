import {
	ADD_BORROW_APPROVAL, // 添加修正表详情
} from '@/Redux/ActionTypes/BorrowApproval'
import InitialState from '@/Redux/InitialState'
import _ from 'lodash'
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = InitialState.borrowApprovalData, action) => {
	switch (action.type) {
		case ADD_BORROW_APPROVAL: {
			// 添加文件详情
			let formDate = _.cloneDeep(state)
			const { data } = action
			formDate.approvalData = data
			return formDate
		}
		default: {
			return state
		}
	}
}
