import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import Enum from '@/Utils/Enum'
const verifyStatus = new Enum(['拒绝', '同意', '回退'])
// 表单项
const formItems = [
	[
		{
			type: 'Select',
			label: '审核结果',
			name: 'status',
			options: verifyStatus.toOptions(),
			placeholder: '请选择审核结果',
		},
	],
	[
		{
			type: 'TextArea',
			label: '审核意见',
			name: 'reviewResult',
			placeholder: '请填写审核意见',
		},
	],
]
const formLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
}
// 修正申请的审核的模态框
export default class ApplyApproveModal extends Component {
	render() {
		const { modalRef, onOk } = this.props
		return (
			<PubModalForm
				title='档案修正申请审核'
				ref={modalRef}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				okText='确认'
				onOk={onOk}
				width={700}
				formProps={{
					// 表单的配置项
					name: 'ApplyApproveModalForm',
					formItems,
					layout: formLayout,
				}}
				zIndex={1001}
			/>
		)
	}
}
