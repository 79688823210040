import { Modal } from 'antd'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { dossierEnum } from '@/Utils/Enum'
import CompositeSearchButton from '@/Public/CompositeSearch/CompositeSearchButton'
import { inititalPubArchiveAction, upgradePubArchiveAction } from './ActionCreators'

import _ from 'lodash'

const tips = {
	CHOOSE_AT_LEAST_ONE: '请选择至少一项后再进行操作',
}

const mapStateToProps = state => {
	return {
		pubArchive: state.pubArchive,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		inititalPubArchive: (markName, pubArchive) =>
			dispatch(inititalPubArchiveAction(markName, pubArchive)),
		upgradePubArchive: (markName, pubArchive) =>
			dispatch(upgradePubArchiveAction(markName, pubArchive)),
	}
}

@connect(mapStateToProps, mapDispatchToProps)
class AddFilesToArchive extends PureComponent {
	state = {
		tmpSelectedFilesData: [], // 放置暂时选中的已有文件
	}

	markName = this.props.markName

	// 传入CompositeSearchButton，设置<BaseTable>的rowSelection
	returnRowSelection = record => {
		const { tmpSelectedFilesData } = this.state
		const { pubArchive } = this.props
		const filesData = pubArchive[this.markName]?.new.filesData || [] // 拿出来Table栏中的文件

		if (record) {
			// 如果触发行触
			let tmpFilesData = [...tmpSelectedFilesData] // 拷贝一份，不能对tmpSelectedFilesData进行修改
			if (_.find(tmpFilesData, record)) {
				// 如果原来数据存在
				_.remove(tmpFilesData, record) // 删除
			} else {
				tmpFilesData = [...tmpFilesData, record]
			}
			this.setState({
				tmpSelectedFilesData: tmpFilesData,
			})
		}

		const onSelect = (record, selected, selectedRows, event) => {
			if (selected) {
				this.setState({
					tmpSelectedFilesData: [...tmpSelectedFilesData, record],
				})
			} else {
				let tmpFilesData = tmpSelectedFilesData.filter(item => {
					return item.id !== record.id
				})
				this.setState({
					tmpSelectedFilesData: tmpFilesData,
				})
			}
		}

		// 全选的函数
		const onSelectAll = (selected, allSelectedRows, willSelectedRows) => {
			if (selected) {
				this.setState({
					tmpSelectedFilesData: [...tmpSelectedFilesData, ...willSelectedRows],
				})
			} else {
				const tmpFilesData = tmpSelectedFilesData.filter(item => {
					let checkWillWithIn = true
					for (let i = 0; i < willSelectedRows.length; i++) {
						if (willSelectedRows[i].id === item.id) {
							checkWillWithIn = false
							break
						}
					}
					return checkWillWithIn
				})
				this.setState({
					tmpSelectedFilesData: tmpFilesData,
				})
			}
		}

		let selectedRowKeys = [...tmpSelectedFilesData, ...filesData] // 把此次选中的选中，和Table栏的文件并到一起，设置为选中的文件

		// 禁选已经在Table栏中的文件
		const getCheckboxProps = item => {
			let checkWithin = false
			for (let i = 0; i < filesData.length; i++) {
				if (filesData[i].id === item.id) {
					checkWithin = true
					break
				}
			}
			return { disabled: checkWithin }
		}
		// 按着官方文档要求配置，然后放回
		const rowSelection = {
			type: 'checkbox',
			onSelect: onSelect,
			onSelectAll: onSelectAll,
			getCheckboxProps: getCheckboxProps,
			selectedRowKeys: selectedRowKeys ? selectedRowKeys.map(item => item.id) : [],
		}

		return rowSelection
	}

	// 点击加入按钮后触发的函数
	handleCompositeRecords = records => {
		const { upgradePubArchive, pubArchive } = this.props
		const filesData = pubArchive[this.markName].new.filesData || [] // 获取已经在Table表格里的文件
		const tmpFilesData = [...filesData, ...records]
		const tmpPubArchive = {
			filesData: tmpFilesData,
			archiveData: pubArchive[this.markName].new.archiveData,
		}
		upgradePubArchive(this.markName, tmpPubArchive)
		this.setState({
			tmpSelectedFilesData: [],
		})
	}

	// 判断加入已有文件的模态框中，选中是否为空
	checkRowSelection = () => {
		const { tmpSelectedFilesData } = this.state
		return new Promise((resolve, reject) => {
			// 还未选中则不能操作
			if (!tmpSelectedFilesData.length) {
				reject(
					Modal.error({
						content: tips.CHOOSE_AT_LEAST_ONE,
						maskClosable: true,
					})
				)
			}
			// 返回所有选中项
			resolve(tmpSelectedFilesData)
		})
	}

	render() {
		return (
			<React.Fragment>
				<CompositeSearchButton
					title={'加入已有文件'}
					btnTitle={<>&#xe634; </>}
					modalBtnTitle={'加入'}
					compositeSearchUrl={this.props.compositeSearchUrl}
					enumStatus={dossierEnum.packArchiveStatus}
					initialType={1}
					typeDisabled={true}
					rowSelection={this.returnRowSelection}
					checkRowSelection={this.checkRowSelection}
					handleCompositeRecords={this.handleCompositeRecords}
					noOperationButton={true}
					statusDisabled={this.props.statusDisabled}
					successMsg={
						this.props.isEPhotoAlbum ? '图片文件加入成功' : '加入成功'
					}
					markName={this.markName}
					handleAlbumRecords={this.props.handleAlbumRecords}
					isEPhotoAlbum={this.props.isEPhotoAlbum}
				/>
			</React.Fragment>
		)
	}
}

export default AddFilesToArchive
