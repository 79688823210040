import React, { Component } from 'react'
import axios from 'axios'
import { Upload, Button, message, Modal } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { uploadThunk, downloadAttachmentThunk, removeRepeat } from './ActionCreators'
import { injectUnmount } from '@/Utils'
import { filterYellow } from '@/Utils'
import visitorUpload from '@/Utils/visitorUpload'
import './index.less'

const mapDispatchToProps = dispatch => ({
	upload: (...rest) => dispatch(uploadThunk(...rest)), // 上传文件
	downloadAttachment: (...rest) => dispatch(downloadAttachmentThunk(...rest)), // 下载附件
})

@connect(null, mapDispatchToProps)
@injectUnmount
class Attachment extends Component {
	componentDidMount() {
		const {
			direction = 'horizon',
			onlyShow = false,
			renderUploadBtn = true,
			style = {},
			defaultFileList: defaultFileList_before = [],
		} = this.props
		const { width = '100%', maxHeight = '100%', border = '1px solid #ccc' } = style
		const eleStyle = document.querySelector('.attachment').style
		let listMaxheight = maxHeight
		//附件数据去重
		const defaultFileList = removeRepeat(defaultFileList_before)

		eleStyle.setProperty('--width', width)
		eleStyle.setProperty('--max-height', maxHeight)
		eleStyle.setProperty('--border', border)
		eleStyle.setProperty('--display', direction === 'horizon' ? 'flex' : 'block')
		eleStyle.setProperty('--done-border', '1px solid #5B80D5')

		if (!onlyShow && renderUploadBtn) {
			listMaxheight = `calc(${maxHeight} - 95px)`
		} else if (!onlyShow && !renderUploadBtn) {
			listMaxheight = `calc(${listMaxheight} - 52px)`
		} else {
			listMaxheight = `calc(${maxHeight})`
		}

		eleStyle.setProperty('--list-max-height', listMaxheight)

		const attachmentList = []
		for (const fileObj of defaultFileList) {
			attachmentList.push(fileObj.uid)
		}
		this.setState({
			fileList: defaultFileList,
			attachmentList,
		})
		//图片数据获取处

		defaultFileList?.forEach(item => {
			if (item.name.split('.')[1] === 'html') {
				//html附件图标
				item.thumbUrl = '/warcLogo.png'
			}
			if (item.name.split('.')[1] === 'mp4') {
				//视频
				const video_url = `http://10.21.32.130:8889${item.url}`
				console.log(video_url);
			}
			if (item.name.split('.')[1] === 'png') {
				//照片
				item.thumbUrl = item.url
			}
			if (item.name.split('.')[1] === 'mp3') {
				//音频
				item.thumbUrl = 'https://pic.pngsucai.com/01/02/59/60eae927f70f1104.webp'
			}
		})
		this.hasHandledStyle()
		this.uploadingAttachmentNum = 0 // 上传中的附件数量
	}

	state = {
		fileList: [],
		attachmentList: [],
		isClear: false,
		hasUpload: [],
		hasUploadMap: {},
		isUploading: false,
		loading: false,
	}

	// 组件销毁的时候终止上传
	componentWillUnmount() {
		const XHRAbort = axios.CancelToken.source()
		this.state.isUploading && XHRAbort.cancel()
		if (this.props.getAttachmentUploading) {
			this.props.getAttachmentUploading(false)
		}
	}

	handleChange = ({ file, fileList }) => {
		const { getAttachmentFileList } = this.props
		const { isUploading } = this.state
		this.setState(
			{
				fileList: [...fileList],
			},
			() => {
				file.status === 'removed' && !isUploading && this.deleteAttachment(file)
				file.status === 'done' &&
					getAttachmentFileList &&
					getAttachmentFileList(fileList)
			}
		)
	}

	showDeleteConfirm = (title = '是否删除该附件？') =>
		new Promise((resolve, reject) => {
			Modal.confirm({
				title,
				icon: <ExclamationCircleOutlined />,
				okText: '是',
				okType: 'danger',
				cancelText: '否',
				onOk: () => {
					resolve(true)
				},
				onCancel: () => {
					resolve(false)
				},
			})
		})

	deleteAttachment = deleteFile => {
		const { getAttachmentFileList } = this.props
		const { fileList, hasUpload, hasUploadMap } = this.state
		getAttachmentFileList && getAttachmentFileList([])
		if (fileList.length === 0) {
			this.setState(
				{
					fileList: [],
					attachmentList: [],
					hasUpload: [],
					hasUploadMap: {},
					isClear: true,
				},
				this.handleUpload
			)
			return
		}
		this.setState(state => {
			let fileListuid = []
			fileList.forEach(file => {
				if (hasUpload.includes(file.uid)) {
					fileListuid.push(hasUploadMap[file.uid])
				} else {
					fileListuid.push(file.uid)
				}
			})
			state.attachmentList = fileListuid
			return {
				attachmentList: state.attachmentList,
				hasUpload: state.hasUpload,
				hasUploadMap: state.hasUploadMap,
			}
		}, this.handleUpload)
	}

	getAttachmentIds = async options => {
		this.uploadingAttachmentNum++ // 每上传一个附件，增加上传中的附件数量

		const { fileList, hasUpload, hasUploadMap } = this.state
		const { getAttachmentFileList, isVisitor = false } = this.props
		const { upload } = this.props

		let res

		if (fileList.length === 0) {
			getAttachmentFileList && getAttachmentFileList([])
			this.setState(
				{
					hasUpload: [],
					hasUploadMap: {},
					fileList: [],
					attachmentList: [],
					isClear: true,
				},
				this.handleUpload
			)
			return []
		}

		this.setState({ isUploading: true }, () => {
			this.props.getAttachmentUploading(this.state.isUploading)
		})
		try {
			// 将待上传的添加到 promiseList
			if (isVisitor) {
				// 游客上传附件需要鉴黄
				const testResult = await filterYellow(options.file)
				if (
					testResult.isPorn === true ||
					testResult.isSexy === true ||
					testResult.isHentai === true
				) {
					message.info('图片包含敏感信息，不允许上传')
					this.setState({ hasUpload: [], hasUploadMap: {}, fileList: [] })
					getAttachmentFileList && getAttachmentFileList([])
					return false
				}
				const formData = new FormData()
				formData.append('multipartFile', options.file)
				res = await visitorUpload({ formData, options })
			} else {
				res = await upload(options.file, options)
			}
			hasUpload.push(options.file.uid)
			options.onSuccess()
			hasUploadMap[res.config.data.get('multipartFile').uid] = res.data.data.fileId
			// 这里不要把这个attachmentList放到上面解构
			// 原因是这个函数是异步函数 当上传多个文件时调用多次这个函数 attachmentList解构出来永远为[]
			// 会导致只能保存一个附件
			this.setState(
				{
					attachmentList: [
						...new Set([res.data.data.fileId, ...this.state.attachmentList]),
					],
					isClear: false,
					hasUpload,
					hasUploadMap,
				},
				() => {
					this.handleUpload()
				}
			)
		} catch (err) {
			//console.log(err)
		} finally {
			this.uploadingAttachmentNum-- // 每完成一个附件的上传（成功或失败），减少上传中的附件数量

			// 当所有上传中的附件都完成（成功或失败）
			if (this.uploadingAttachmentNum === 0) {
				const { hasUpload, fileList } = this.state
				const hasUploadSet = new Set(hasUpload)
				const fulfilledFileList = fileList.filter(file =>
					hasUploadSet.has(file.uid)
				) // 过滤掉未上传成功的附件
				// （第一个上传的附件未上传成功 或者 有成功上传的附件）则更新已上传成功的附件
				if (hasUpload.length === 0 || fulfilledFileList.length !== 0) {
					this.setState({ fileList: fulfilledFileList })
				}
			}

			this.state.fileList.every(file => {
				return file.status !== 'uploading'
			}) &&
				this.setState(
					{
						isUploading: false,
					},
					() => {
						this.props.getAttachmentUploading(this.state.isUploading)
					}
				)
		}
	}
	/**
	 * 区分保存前和保存后的文件，更改保存后的样式
	 *
	 */
	hasHandledStyle = () => {
		const handledList = document.querySelectorAll(
			'.attachment .ant-upload-list-item-done'
		)
		const listLength = handledList.length
		if (listLength) {
			for (let i = 0; i < listLength; i++) {
				handledList[i].classList.add('uploaded-item-style')
				handledList[i].style.color = '#5B80D5'
			}
		}
	}
	/**
	 * 上传前校验文件类型
	 */
	beforeUpload = file => {
		const fileType = file?.name.split('.')[1]
		let allowList = [
			'doc',
			'docx',
			'xls',
			'xlsx',
			'ppt',
			'pptx',
			'pdf',
			'txt',
			'eml',
			'jpg',
			'png',
			'jpeg',
			'gif',
			'mp4',
			'mp3',
			'png',
		]
		if (!allowList.includes(fileType)) {
			message.error('不支持上传此类文件')
			return Upload.LIST_IGNORE
		}
	}
	/**
	 * 上传完自动保存
	 */
	handleUpload = () => {
		this.hasHandledStyle()
		const { changeAttachments } = this.props
		const { attachmentList, isClear } = this.state

		if (changeAttachments) {
			changeAttachments(isClear ? [] : attachmentList)
		}
	}

	// 点击下载文件按钮回调
	handleOnDownload = file => {
		if (file.originFileObj) {
			const reader = new FileReader()
			reader.onload = e => {
				this.props.downloadAttachment(e.target.result, file.name)
			}
			reader.readAsDataURL(file.originFileObj)
			return
		}

		this.props.downloadAttachment(file.url, file.name)
	}

	render() {
		const {
			onlyShow = false,
			defaultFileList = [],
			showEmpty = true,
			style,
			disabled = true,
			formDisabled = true,
		} = this.props
		const { fileList } = this.state
		const ComponentStyle = { ...style }
		delete ComponentStyle.width
		delete ComponentStyle.border
		delete ComponentStyle.maxHeight

		return (
			<div className='attachment' style={{ ...ComponentStyle }}>
				{/* 上传按钮 */}
				{!onlyShow && (
					<>
						<Upload
							multiple={true}
							listType='picture'
							fileList={fileList}
							showUploadList={{
								showRemoveIcon: (!disabled || !formDisabled) && !onlyShow,
								showDownloadIcon: true,
							}}
							// className="upload"
							beforeUpload={this.beforeUpload}
							onChange={this.handleChange}
							customRequest={this.getAttachmentIds}
							onDownload={this.handleOnDownload}
							onRemove={file => this.showDeleteConfirm()}>
							<Button
								disabled={disabled && formDisabled}
								style={{ color: '#5B80D6', width: '63.88px' }}
								title={
									disabled && formDisabled
										? '禁止非编辑或非修正状态上传附件'
										: ''
								}>
								<svg
									t='1628649273900'
									viewBox='0 0 1024 1024'
									version='1.1'
									xmlns='http://www.w3.org/2000/svg'
									p-id='2822'
									width='20'
									height='20'>
									<path
										d='M435.2 435.2 0 435.2 0 588.8 435.2 588.8 435.2 1024 588.8 1024 588.8 588.8 665.6 588.8 1024 588.8 1024 435.2 588.8 435.2 588.8 358.4 588.8 0 435.2 0 435.2 435.2Z'
										p-id='2823'
										fill='#5b80d5'>
									</path>
								</svg>
							</Button>
						</Upload>
						<Button
							className='delet-all-btn btn-danger'
							disabled={disabled && formDisabled}
							onClick={() => {
								if (fileList.length === 0) return
								this.showDeleteConfirm('是否删除全部附件？').then(
									isDelete => {
										isDelete &&
											this.setState(
												{
													fileList: [],
													attachmentList: [],
													hasUpload: [],
													hasUploadMap: {},
													isClear: true,
												},
												this.handleUpload
											)
									}
								)
							}}>
							删除全部	 <DeleteOutlined />
						</Button>
					</>
				)}

				{/* 没附件时的文字 */}
				{showEmpty && !defaultFileList.length && !fileList.length && (
					<div className='no-attachment'>
						{typeof showEmpty === 'boolean'
							? '该文件暂时没有附件'
							: showEmpty}
					</div>
				)}
			</div>
		)
	}
}

export default Attachment
