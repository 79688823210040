import {
	PUBFORM_SAVE,
	PUBFILE_ADD,
	PUBDOSSIER_ADD,
	NEWDETAILS_ADD,
	OLDDETAILS_ADD,
	APPLYCONTENT_ADD,
	APPLYFIX_FINISH,
	PUB_COLLECTEDFILE_ADD,
	PUB_Album_ADD,
} from '../../../ActionTypes/Public/PubForm/index'
import InitialState from '@/Redux/InitialState'
import _ from 'lodash'
let searchCondition = (state, action) => {
	return state
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = InitialState.pubForm, action) => {
	switch (action.type) {
		case PUBFORM_SAVE: {
			return searchCondition(state, action)
		}
		case PUBFILE_ADD: {
			// 添加文件详情
			let formDate = _.cloneDeep(state)
			const { data } = action
			formDate.pub_fileDetails = data
			return formDate
		}
		// 添加资料详情
		case PUB_COLLECTEDFILE_ADD: {
			let formDate = _.cloneDeep(state)
			const { data } = action
			formDate.pub_collectedDetails = data
			return formDate
		}
		// 添加主题详情
		case PUB_Album_ADD: {
			let formDate = _.cloneDeep(state)
			const { data } = action
			formDate.pub_albumDetails = data
			return formDate
		}
		case PUBDOSSIER_ADD: {
			// 添加案卷详情
			let formDate = _.cloneDeep(state)
			const { data } = action
			formDate.pub_dossierDetails = data
			return formDate
		}
		case OLDDETAILS_ADD: {
			// 添加修正前详情
			let formDate = _.cloneDeep(state)
			const { data, current } = action
			formDate.pub_fixApplyDetails.old_details = data
			formDate.pub_fixApplyDetails.new_details = data
			formDate.pub_fixApplyDetails.current = current
			return formDate
		}
		case NEWDETAILS_ADD: {
			// 添加修正后详情
			let formDate = _.cloneDeep(state)
			const { data, current } = action
			formDate.pub_fixApplyDetails.new_details = data
			formDate.pub_fixApplyDetails.current = current
			return formDate
		}
		case APPLYCONTENT_ADD: {
			// 添加修正申请内容
			let formDate = _.cloneDeep(state)
			const { data, current } = action
			formDate.pub_fixApplyDetails.fix_apply = data
			formDate.pub_fixApplyDetails.current = current
			return formDate
		}
		case APPLYFIX_FINISH: {
			// 申请完成，清空内容
			let formDate = _.cloneDeep(state)
			formDate.pub_fixApplyDetails = {
				current: 0,
				old_details: {}, // 修正前
				new_details: {}, // 修正后
				fix_apply: {}, //  申请理由
			}
			return formDate
		}
		default: {
			return state
		}
	}
}
