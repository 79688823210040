import React, { useContext, useState, Fragment } from 'react'
import { Button, Input, Form, Checkbox } from 'antd'
import { EditOutlined, CheckCircleOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { DefaultDataContext } from '../index'

const { Group } = Checkbox
/**
 * 穿梭框右侧可编辑的组的 行
 * @param {*} props
 * @returns
 */
function EditInput(props) {
	const [editing, setEditing] = useState(false)
	const { data, setData, targetKeys, setTargetKeys, hadConfig } = props
	const defaultData = useContext(DefaultDataContext)
	// 修改可编辑的数据
	const changeData = values => {
		const newData = defaultData.map(item => {
			if (item.key === data.key) {
				item.number = values[data.key]
			}
			return item
		})
		setEditing(!editing)
		setData(newData)
	}

	// 向上排一步
	const queueUp = () => {
		const copyKeys = [...targetKeys]
		const index = copyKeys.indexOf(data.key)
		if (index !== 0) {
			let tmp = copyKeys[index - 1]
			copyKeys[index - 1] = copyKeys[index]
			copyKeys[index] = tmp
			setTargetKeys(copyKeys)
		}
	}

	return (
		<div className='edit-line order-group-p'>
			<span>{data.label}</span>
			{
				// 配置过，则不可再设置
				!hadConfig && (
					<span className='edit-queue-up'>
						<Button className='no-btn up-icon' onClick={queueUp}>
							<ArrowUpOutlined />
						</Button>
					</span>
				)
			}
			{data.editable && (
				<Form className='edit-form' onFinish={changeData}>
					{editing ? (
						<Form.Item name={data.key}>
							<Input
								autoFocus
								size='small'
								type='number'
								initialvalue={data.number || 0}
								style={{ width: 100 }}
							/>
						</Form.Item>
					) : (
						<span>({data.number || 0}位)</span>
					)}
					{
						// 配置过，则不可再设置
						!hadConfig && (
							<Form.Item className='edit-icon'>
								<Button className='no-btn' htmlType='submit'>
									{!editing ? (
										<EditOutlined />
									) : (
										<CheckCircleOutlined />
									)}
								</Button>
							</Form.Item>
						)
					}
				</Form>
			)}
		</div>
	)
}

/**
 * 穿梭框右侧可选中的组
 * @param {*} props
 * @returns
 */
function CheckGroup(props) {
	const { selectedKeys, hadConfig } = props
	return (
		<Group
			name='order-right'
			className='order-group'
			disabled={hadConfig}
			value={selectedKeys} // 控制多选框组，哪些被选中
		>
			{<EditGroup {...props} />}
		</Group>
	)
}

/**
 * 穿梭框右侧的可编辑组
 * @param {*} props
 * @returns
 */
function EditGroup({ dataSource, onItemSelect, ...restProps }) {
	const boxChange = event => {
		const target = event.target
		onItemSelect(target.value, target.checked)
	}

	return (
		<Fragment>
			{dataSource.map(item => (
				<div className='order-line' key={item.key}>
					<Checkbox value={item.value} onChange={boxChange}>
						<EditInput key={item.key} data={item} {...restProps} />
					</Checkbox>
				</div>
			))}
		</Fragment>
	)
}

export default React.memo(CheckGroup)
