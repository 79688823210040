import React, { PureComponent } from 'react'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { Spin, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { getArchiveLogThunk, getDossierLogThunk, getHistoryThunk } from './ActionCreators'
import Enum from '@/Utils/Enum'
import './index.less'

// 用以匹配logType
const logTypes = new Map([
	[0, '编辑'],
	[1, '预归档'],
	[2, '入库'],
	[3, '回退'],
	[4, '删除'],
	[5, '销毁'],
	[10, '档案新增'],
	[11, '过期销毁'],
	[12, '审核'],
	[15, '恢复'],
	[16, '延期'],
	[17, '归档修正'],
	[18, '提交审核'],
])

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	getArchiveLog: (...rest) => dispatch(getArchiveLogThunk(...rest)),
	getDossierLog: (...rest) => dispatch(getDossierLogThunk(...rest)),
	getHistory: (...rest) => dispatch(getHistoryThunk(...rest)),
})

@connect(mapStateToProps, mapDispatchToProps)
class ArchiveLog extends PureComponent {
	state = {
		logs: [], // 日志内容
		loading: true, // 是否处于加载状态
		logHistoryList: new Map(), // 日志历史列表
	}

	// 添加自定义样式
	setStyle = () => {
		/**
		 * setStyle = {
		 *  containerWidth,
		 *  contentHeight,
		 *  maxWidth
		 * }
		 */
		let { setStyle } = this.props // setStyle是一个对象
		setStyle = setStyle && cloneDeep(setStyle) // 深拷贝一份setStyle
		for (let i in setStyle) {
			setStyle[i] = setStyle[i].toString()
			if (setStyle[i].replace(/[0-9]/g, '') === '') {
				setStyle[i] = `${setStyle[i]}px`
			}
		}
		const property = {
			'--log-container-width': setStyle?.containerWidth || '70%',
			'--log-container-height': setStyle?.containerHeight || '78vh',
			'--item-max-width': setStyle?.maxWidth || '170px',
			'--item-min-height': setStyle?.minHeight || '300px',
			'--item-min-width': setStyle?.minWidth || '150px',
		}

		const archiveLogElement = document.querySelector(`.log-container`)
		for (let i in property) {
			archiveLogElement.style.setProperty(i, property[i])
		}
	}

	// 挂载之前请求日志信息，并设置到state里面
	componentWillMount() {
		const { getArchiveLog, id, dossierId, getDossierLog, getHistory } = this.props
		let getLog = null
		if (id) getLog = getArchiveLog
		if (dossierId) getLog = getDossierLog

		if (getLog) {
			const getLogPromise = getLog(id || dossierId)
			const getHistoryPromise = getHistory(id || dossierId)

			const getNeededData = [getLogPromise, getHistoryPromise]

			// 实现getNeededData数组每一项与其索引的映射
			const GETLOG = 'getLog'
			const GETHISTORY = 'getHistory'
			const getDataType = new Enum([GETLOG, GETHISTORY])

			// 分配请求结果去处理
			const handleRes = (res, index) => {
				switch (index) {
					case getDataType[GETLOG]:
						handleGetLogRes(res)
						break
					case getDataType[GETHISTORY]:
						handleGetHistoryRes(res)
						break
					default:
						break
				}
			}

			// 处理 获取日志接口 的请求结果
			const handleGetLogRes = res => {
				// 如果有数据的话，放进state里面
				if (res.data.length > 0) {
					const logs = res.data.map(item => {
						item.logTypeName = logTypes.get(item.logType)
						item.logTime = moment(item.logTime).format('ll')
						return item
					})
					this.setState({ logs })
				}
			}

			// 处理 获取档案历史接口 的请求结果
			const handleGetHistoryRes = res => {
				// 如果有数据的话，放进state里面
				if (res.logHistoryList.length > 0) {
					const logHistoryList = new Map()
					res.logHistoryList.forEach(logHistory => {
						logHistoryList.set(logHistory.logId, logHistory)
					})
					this.setState({ logHistoryList: logHistoryList })
				}
			}

			// 并发请求
			Promise.allSettled(getNeededData)
				.then(res => {
					res.forEach((eachRes, index) => {
						if (eachRes.status === 'fulfilled') {
							handleRes(eachRes.value, index)
						}
					})
				})
				.finally(_ => {
					// 把加载状态设置为false
					this.setState({ loading: false })
				})
		} else {
			this.setState({ loading: false })
		}
	}

	// 组件render出来之后设置样式
	componentDidMount() {
		this.setStyle()
	}

	// 加载日志
	renderTimeline = () => {
		let { logs, logHistoryList } = this.state

		return (
			<>
				{/* 日志信息 */}
				<div className='archive-log-container'>
					{logs.length === 0 ? (
						<div className='content-empty'>该档案暂无日志</div>
					) : (
						logs.map((log, index) => {
							const check = index === logs.length - 1 // 判断是否是最后一个
							const odd = index % 2 === 0
							const topOrDown = odd ? 'down' : 'top'
							const logHistory = logHistoryList.get(log.id) // 上链的档案历史
							const { hash, logType, message, operateTime } = logHistory // 上链的档案历史各项详细数据
							const tooltipTitle = (
								<div className='tooltipTitle'>
									<div className='tooltipTitle-item'>
										<span className='tooltipTitle-item-title'>
											链上交易哈希：
										</span>
										<span>{hash}</span>
									</div>
									<div className='tooltipTitle-item'>
										<span className='tooltipTitle-item-title'>
											日志类型：
										</span>
										<span>{logTypes.get(logType)}</span>
									</div>
									<div className='tooltipTitle-item'>
										<span className='tooltipTitle-item-title'>
											日志描述：
										</span>
										<span>{message}</span>
									</div>
									<div className='tooltipTitle-item'>
										<span className='tooltipTitle-item-title'>
											操作时间：
										</span>
										<span>{operateTime}</span>
									</div>
								</div>
							)
							return (
								<div className={'log-item ' + topOrDown} key={log.id}>
									<div className='log-content'>
										{odd ? (
											<>
												<p className='content'>
													{log.logTypeName}
												</p>
												<p className='user'>{log.username}</p>
												<p className='time'>{log.logTime}</p>
											</>
										) : (
											<>
												<p className='time'>{log.logTime}</p>
												<p className='user'>{log.username}</p>
												<p className='content'>
													{log.logTypeName}
												</p>
											</>
										)}
									</div>
									<div className='log-footer'>
										<Tooltip
											placement={odd ? 'top' : 'bottom'}
											title={tooltipTitle}>
											<div
												className={`log-head ant-timeline-item-head ant-timeline-item-head-${log.message ? 'green' : 'blue'}`}></div>
										</Tooltip>
										<div
											className={`log-tail ${check && 'dash-line'}`}></div>
									</div>
								</div>
							)
						})
					)}
				</div>
			</>
		)
	}

	// 加载显示条
	renderLoading = () => (
		<div className='log-spin'>
			<Spin tip='加载中...'></Spin>
		</div>
	)

	render() {
		const { loading } = this.state
		return (
			<div className='log-container'>
				{
					// 处于加载状态则不显示内容，显示加载图标
					loading === true ? this.renderLoading() : this.renderTimeline()
				}
			</div>
		)
	}
}

export default ArchiveLog
