import React, { Component } from 'react'
import createFormItem from './FormItem'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { message } from 'antd'
import { Provider } from './Context'
import { typeUtil } from '@/Utils'
import WithRef from '../WithRef'

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({})
// 高阶函数，为了增强 PubForm 组件，统一在这边添加方法，方便管理
// eslint-disable-next-line import/no-anonymous-default-export
export default OldComponent => {
	@connect(mapStateToProps, mapDispatchToProps)
	@withRouter
	@WithRef
	class Utils extends Component {
		static pubFormRef = null // 获取 pubForm 组件的实例
		/**
		 * 更改表单字段 disabled 值
		 * @param {Object | Boolean} formDisabled
		 * 如果是 Boolean，则对所有表单项都设置
		 * 如果是 Object，传入
		 * {
		 *   [name]: [value],
		 * }
		 * 的格式，则对包含的表单项做相应的修改
		 */
		setDisabled = formDisabled => {
			let disabledObj = {}
			// 如果是一个对象
			if (typeUtil.isObject(formDisabled)) {
				disabledObj = formDisabled
			} else if (typeUtil.isBoolean(formDisabled)) {
				const { formItems = [] } = this.props
				formItems
					.flat(Infinity) // 扁平化
					// 非Extra, DynamicExtra, AsyncTable 组件才收集
					.filter(
						({ type }) =>
							!['EXTRA', 'DYNAMICEXTRA', 'ASYNCTABLE'].includes(
								type.toUpperCase()
							)
					)
					.forEach(({ name }) => (disabledObj[name] = formDisabled))
			} else {
				throw new Error('使用 setDisabled 方法请传入对象或布尔值')
			}
			this.setState({
				disabledObj,
			})
		}

		// 控制表单某项显示隐藏
		/**
		 * 更改表单字段 disabled 值
		 * @param {Object | Boolean} formDisabled
		 * 默认为true
		 * 如果是 Object，传入
		 * {
		 *   [name]: [value],
		 * }
		 * 的格式，则对包含的表单项做相应的修改
		 */
		setItemShow = whichShow => {
			let whichShowObj = {}
			// 如果是一个对象
			if (typeUtil.isObject(whichShow)) {
				whichShowObj = whichShow
			} else {
				//默认为全部显示
				const { formItems = [] } = this.props
				formItems
					.flat(Infinity) // 扁平化
					// 非Extra, DynamicExtra, AsyncTable 组件才收集
					.filter(
						({ type }) =>
							!['EXTRA', 'DYNAMICEXTRA', 'ASYNCTABLE'].includes(
								type.toUpperCase()
							)
					)
					.forEach(({ name }) => (whichShowObj[name] = true))
			}
			this.setState({ whichShowObj: { ...whichShowObj } })
		}

		componentDidMount() {
			this.setDisabled(this.props.formDisabled || false)
			this.setItemShow(this.props.whichShow)
			// 将 Form 组件实例的所有方法注入到Utils类中以便外部获取
			const formInstance = Utils.pubFormRef.formRef.current
			for (let formFuncKey in formInstance) {
				this[formFuncKey] = formInstance[formFuncKey]
			}
		}

		/**
		 * 该方法用于根据config中的formItems配置生成对应的表单子组件数组
		 * @param {array<array>} formItems form表单项配置
		 * @param {any} formRef form 表单实例
		 * @param {object} 被探测到有敏感词的表单的真值对象
		 */
		static renderFormItems = (formItems, formRef, sensitiveDetected) => {
			return formItems.map((rowItems, index) => {
				return createFormItem(rowItems, index, formRef, sensitiveDetected)
			})
		}

		// 在 antd 的 getFieldsValue 方法进行强化，对表格的数据进行筛选
		getFormData = async () => {
			// 先校验再获取数据
			try {
				const { formItems } = this.props
				let formData = await this.validateFields() // 校验成功则返回表单数据
				// 如果获取的数据是 {}
				if (JSON.stringify(formData) === '{}') return
				// 通过 formItems 配置项获取 type 是 Table 的 name 值
				let tableNames = formItems
					.flat(Infinity)
					.filter(item => item.type.toUpperCase() === 'TABLE')
					.map(item => item.name)
				// 修改获取到的表单数据中，筛选出被选中的表格行数据并返回
				tableNames.forEach(name => {
					if (formData[name]) {
						const { dataSource = [], selectedRowKeys = [] } = formData[name]
						formData[name].value = dataSource.filter(item =>
							selectedRowKeys.includes(item.id)
						)
					}
				})
				return formData
			} catch (errInfo) {
				message.error('请完整填写表单', 1)
				return undefined
			}
		}

		/**
		 * 给某个表格表单项添加数据
		 * @param {string} name 表格表单字段名
		 * @param {Array | Object} newData 要加入的数据，可以是一个对象（一个数据），也可以是一个数组（多行）
		 */
		addTableRows = (name, newData) => {
			const { formItems, formDisabled = false } = this.props
			// 如果表单不能编辑的状态下，那么也不能添加表格数据
			if (formDisabled) {
				return message.error('当前状态下不能添加表格数据')
			}
			// 获取该字段的表单项的配置，目的是获取它的 recordKeyName
			let tableConfig = formItems
				.flat(Infinity)
				.filter(item => item.type.toUpperCase() === 'TABLE' && item.name === name)
			if (!tableConfig.length) {
				throw new Error(
					`没有找到字段名为 ${name} 的表格表单项，请检查是否配置出错`
				)
			}
			const { recordKeyName = 'id' } = tableConfig
			// 获取旧的字段值对象赋值给新对象
			let newValue = Object.assign(
				{
					dataSource: [],
					selectedRowKeys: [],
					value: [],
				},
				this.getFieldValue(name)
			)
			let oldDataSource = newValue.dataSource // 获取旧的数据源
			let newDataArr = []
			if (Array.isArray(newData)) {
				// 是一个数组，则添加多项
				newDataArr = [...newData]
			} else if (typeof newData === 'object' && newData !== null) {
				// 是一个对象，那么则添加一行数据
				newDataArr.push(newData)
			}
			// 给添加进来的数据添加key字段，用于保证表格正常渲染
			newDataArr.forEach(data => {
				data.key = data[recordKeyName]
			})
			// 将新数据和原有的数据合并成一个新的数组
			newValue.dataSource = oldDataSource.concat(newDataArr)
			// 将新的数组添加到表单表格字段
			this.setFieldsValue({
				[name]: newValue,
			})
		}

		render() {
			return (
				<Provider value={{ ...this.state }}>
					<OldComponent
						{...this.props}
						getFormData={this.getFormData}
						addTableRows={this.addTableRows}
						setDisabled={this.setDisabled}
						setItemShow={this.setItemShow}
						renderFormItems={Utils.renderFormItems}
						getInstance={pubFormRef => {
							Utils.pubFormRef = pubFormRef
						}} // 该方法会在子组件调用将this.pubFormRef， 指向子组件
					/>
				</Provider>
			)
		}
	}
	return Utils
}
