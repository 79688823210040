import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { departmentUrl } from '@/Utils/Urls'
import { UserManagerEnum } from '@/Utils/Enum'
import { connect } from 'react-redux'
import { editUserThunk } from '../ActionCreators'
import { treeUtil } from '@/Utils'
const formLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 10 },
}
// 表单项的单独配置，优先级高于表单项的统一配置
const formItemLayout = {
	labelCol: { span: 7 },
	wrapperCol: { span: 17 },
}
// buttonList
let buttonList = [
	{
		text: '保存',
		buttonProps: {
			type: 'primary',
		},
		onClick: (formData, { finish, hide }) => {
			setTimeout(() => {
				hide()
			}, 1000)
		},
	},
]
const mapStateToProps = state => {
	return {}
}
const mapDispatchToProps = dispatch => ({
	editRole: (params, reflashTable) => dispatch(editUserThunk(params, reflashTable)), // 修改角色
})
@connect(mapStateToProps, mapDispatchToProps)
class modal extends Component {
	formItems = [
		[
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId',
				label: '所属部门',
				layout: formItemLayout,
				placeholder: '请选择所属部门',
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
				span: 12,
			},
			{
				type: 'Input',
				name: 'workId',
				label: '用户名',
				layout: formItemLayout,
				placeholder: '请输入用户名',
				disabled: true,
				rules: [
					{
						required: true,
						message: '用户名为必填项',
					},
					{
						type: 'number',
						message: '请输入正确的用户名',
						transform: value => {
							return Number(value)
						},
					},
				],
				span: 12,
			},
		],
		[
			{
				type: 'Input',
				name: 'name',
				label: '姓名',
				layout: formItemLayout,
				placeholder: '请输入名字',
				span: 12,
			},
			{
				type: 'Input',
				name: 'phone',
				label: '电话号码',
				placeholder: '请输入电话号码',
				layout: formItemLayout,
				rules: [
					{
						required: false,
					},
					{
						pattern: /^1[3456789]\d{9}$/,
						message: '输入正确的电话号码',
					},
				],
				span: 12,
			},
		],
		[
			{
				type: 'Input',
				name: 'email',
				label: '邮箱',
				placeholder: '请输入邮箱',
				layout: formItemLayout,
				rules: [
					{
						required: false,
					},
					{
						type: 'email',
						message: '请输入正确的邮箱',
					},
				],
				span: 12,
			},
			{
				type: 'PasswordInput',
				name: 'password',
				label: '密码',
				placeholder: '请输入密码',
				disabled: true,
				layout: formItemLayout,
				rules: [
					{
						required: false,
					},
				],
				span: 12,
			},
		],
		[
			{
				type: 'Select',
				name: 'status',
				label: '状态',
				layout: formItemLayout,
				placeholder: '请输选择账号状态',
				options: UserManagerEnum.status.toOptions(),
				span: 12,
			},
			{
				type: 'DatePicker',
				name: 'validTime',
				label: '有效期',
				layout: formItemLayout,
				placeholder: '请输入有效期',
				picker: 'all',
				span: 12,
				disabledDate: () => false,
			},
		],
	]
	state = {
		visible: false,
		title: '编辑用户',
		buttonList,
	}

	editNewUser = (formData, { finish, hide }) => {
		formData['id'] = sessionStorage.getItem('editUserId')
		const { editRole, reflashTable } = this.props
		editRole(formData, { finish, hide, reflashTable })
	}

	render() {
		let { title } = this.state
		return (
			<PubModalForm
				title={title}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				// buttonList={buttonList}       // Modal 底部按钮配置列表,
				onOk={this.editNewUser}
				formDisabled={false}
				okText='确定' // 若没有buttonList时选传， 默认值为提交
				ref={this.props.modalRef} // 若隔层由外层通过props传递
				width={700} // 默认值900
				formProps={{
					// 表单的配置项
					name: 'EditUserModal',
					formItems: this.formItems,
					layout: formLayout,
				}}
			/>
		)
	}
}

export default modal
