import defaultlogo from '@/Static/logo/defaultlogo.png'
import GDUTlogo from '@/Static/logo/GDUTlogo.png'
import GDUFlogo from '@/Static/logo/GDUFlogo.png'
import SZUlogo from '@/Static/logo/SZUlogo.png'

import { getServerConfig, updateServerConfig } from './ActionCreator'

// * 设置角色对应的 首页路由 的 侧边栏id
let GDUTroleMapHomePathId = [
	['archiveManager', 57],
	['archiveDutyManager', 57],
	['fullPermissionsRole', 57],
	['departmentManager', 57],
	['auditor', 57],
	['user', 57],
	['systemManager', 5],
]

let defaultRoleMapHomePathId = [
	['archiveManager', 19],
	['archiveDutyManager', 52],
	['fullPermissionsRole', 52],
	['departmentManager', 52],
	['auditor', 52],
	['user', 2],
	['systemManager', 5],
]

//* modeConfig
//* 此数组存对应文件类型的模式，第一项案卷，第二项多媒体，第三项文书
//* [0, 2, 2]表示案卷为默认模式 多媒体为按卷模式 文书为按卷模式 0默认 1按件 2按卷
/**
 * * systemNameCN 系统中文名
 * * systemNameEN 系统英文名
 * * icon tab栏ico图标 放在 pubilc 文件夹 这里填名字就行
 * * logo 放在 @/Static/logo 用import引进来
 * * roleMapHomePathId  设置不同服的角色对应的 首页路由 的 侧边栏id
 */
// const Server = {
//   defaultServer: {
//     modeConfig: [0, 2, 1],
//     systemNameCN: '智慧新媒体档案管理系统',
//     systemNameEN: 'School History Data Management System',
//     icon: 'favicon.ico',
//     logo: defaultlogo,
//     roleMapHomePathId: defaultRoleMapHomePathId,
//   },
//   GuangGong: {
//     modeConfig: [0, 2, 2],
//     systemNameCN: '校史资料征集及管理系统',
//     systemNameEN: 'School History Data Collection And Management System',
//     icon: 'GGfavicon.ico',
//     logo: GDUTlogo,
//     roleMapHomePathId: GDUTroleMapHomePathId,
//   },
//   GuangWai: {
//     modeConfig: [0, 1, 2],
//     systemNameCN: '校史资料征集及管理系统',
//     systemNameEN: 'School History Data Collection And Management System',
//     icon: 'GWfavicon.ico',
//     logo: GDUFlogo,
//     roleMapHomePathId: defaultRoleMapHomePathId,
//   },
//   ShenDa: {
//     modeConfig: [0, 1, 2],
//     systemNameCN: '校史资料征集及管理系统',
//     systemNameEN: 'School History Data Collection And Management System',
//     icon: 'SDfavicon.ico',
//     logo: SZUlogo,
//     roleMapHomePathId: defaultRoleMapHomePathId,
//   }
// }

const logoList = {
	testServer: defaultlogo,
	GuangGong: GDUTlogo,
	GuangWai: GDUFlogo,
	ShenDa: SZUlogo,
}

const roleMapHomePathIdList = {
	testServer: defaultRoleMapHomePathId,
	GuangGong: GDUTroleMapHomePathId,
	GuangWai: defaultRoleMapHomePathId,
	ShenDa: defaultRoleMapHomePathId,
}

let singleSign = true
export const getConfig = () => {
	const localCacheConfig = localStorage.getItem('serverConfig')
	if (localCacheConfig) {
		return new Promise((resolve, reject) => {
			resolve(JSON.parse(localCacheConfig))
		})
	} else {
		return new Promise((resolve, reject) => {
			getServerConfig().then(res => {
				let Config = res.data
				if (logoList[Config.serverName]) {
					Config.logo = logoList[Config.serverName]
					Config.roleMapHomePathId = roleMapHomePathIdList[Config.serverName]
				} else {
					Config.logo = defaultlogo
					Config.roleMapHomePathId = defaultRoleMapHomePathId
				}

				// 老版配置
				// Config.modeConfig = Config.modeConfig?.map((item) => {
				//   return Number(item)
				// })

				// 新版配置
				Config.modeConfig = {}
				Config.commitModeList?.forEach(item => {
					Config.modeConfig[item.key] = item.mode
				})

				//* 更换ico title
				if (singleSign) {
					document.querySelector('link[type="image/x-icon"]').href =
						`/${Config.icon}`
					document.querySelector('title').innerText = Config.systemNameCN
					singleSign = false
				}

				localStorage.setItem('serverConfig', JSON.stringify(Config))
				resolve(Config)
			})
		})
	}
}

export const updateConfig = Config => {
	//把key和mode对应起来，方便使用
	let modeConfig = {}
	Config.commitModeList.forEach(item => {
		modeConfig[item.key] = item.mode
	})
	Config.modeConfig = modeConfig

	localStorage.setItem('serverConfig', JSON.stringify(Config))
	updateServerConfig({ commitModeList: Config.commitModeList })
}
