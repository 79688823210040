import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import {
	menuConfig,
	newConstructionMenuConfig,
	settingsMenuConfig,
	editingResearchMenuConfig,
	archivedMenuConfig,
	statisticsMenuConfig,
	collectedFilesMenuConfig,
	faceMenuConfig,
	mineMenuConfig,
} from '@/Components/SlideBar/menuConfig'
import { injectUnmount } from '@/Utils'
import ErrorBoundary from '@/Public/ErrorBoundary'

import { linkTo, RouteMethod } from './ManageRouter'

let prePathName = ''
let manageRouterList

class Routes extends Component {
	constructor(props) {
		super(props)
		this.state = {
			// router: [],
			userType: props.userType,
		}
	}

	/*
  getRoute()
  用于 处理数组，给每一个页面渲染对应的组件

  */
	getRoute(ManageRouterList, router = []) {
		let routerNow = []
		for (let n in ManageRouterList) {
			let manageRouterListSet
			if (ManageRouterList[n].children) {
				manageRouterListSet = ManageRouterList[n].children
			} else {
				manageRouterListSet = [ManageRouterList[n]]
			}
			while (1) {
				//循环
				routerNow = []
				// eslint-disable-next-line no-loop-func
				manageRouterListSet.forEach(item => {
					//三级菜单
					if (!item.hide && item.component) {
						//item.hide为false

						router.push(
							//空数组router添加路由
							<Route
								path={linkTo.topRoute + item.path}
								key={item.key}
								exact={item.exact}
								render={() => {
									const AComponent = item.component
									// 能够防止被包裹的组件在卸载后使用 setState (异步结果返回的时候组件已经卸载)而报错
									injectUnmount(
										AComponent.WrappedComponent || AComponent
									)
									return (
										// 加上错误边界
										<ErrorBoundary>
											<AComponent />
										</ErrorBoundary>
									)
								}}
							/>
						)
					}
					item.children && routerNow.push(...item.children)
				})
				if (routerNow.length === 0) break
				manageRouterListSet = routerNow
			}
		}
		return router
	}

	render() {
		/**
		 * RouteMethod原型定义了一个requireComponents()方法
		 * 遍历数组，设置对应的Component组件
		 *
		 */
		let nowPathName = this.props.location.pathname.split('/').slice(2, 3)[0]
		if (prePathName !== nowPathName) {
			// 根据一级路由，获取文件
			switch (nowPathName) {
				case 'workarea':
					manageRouterList = RouteMethod.requireComponents(menuConfig)
					break
				case 'newConstruction':
					manageRouterList = RouteMethod.requireComponents(
						newConstructionMenuConfig
					)
					break
				case 'settings':
					manageRouterList = RouteMethod.requireComponents(settingsMenuConfig)
					break
				case 'editingResearch':
					manageRouterList = RouteMethod.requireComponents(
						editingResearchMenuConfig
					)
					break
				case 'archived':
					manageRouterList = RouteMethod.requireComponents(archivedMenuConfig)
					break
				case 'statistics':
					manageRouterList = RouteMethod.requireComponents(statisticsMenuConfig)
					break
				case 'collectedFiles':
					manageRouterList = RouteMethod.requireComponents(
						collectedFilesMenuConfig
					)
					break
				case 'mine':
					manageRouterList = RouteMethod.requireComponents(mineMenuConfig)
					break
				case 'face':
					manageRouterList = RouteMethod.requireComponents(faceMenuConfig)
					break
				default:
					manageRouterList = RouteMethod.requireComponents(menuConfig)
			}
			prePathName = nowPathName
			manageRouterList = this.getRoute(manageRouterList)
		}
		//TODO delete
		//console.log('manageRouterList', manageRouterList)
		return <Switch>{manageRouterList}</Switch>
	}
}
export default Routes
