import _fetch from '@/Utils/Fetch'
import { archiveUrl } from '@/Utils/Urls'

export const claimArchive = data => {
	return _fetch({
		url: archiveUrl.claimArchive,
		type: 'post',
		isJson: true,
		data,
	})
}
