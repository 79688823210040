import { filesUrl, mapUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'

// 上传文件
export const uploadThunk = file => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.uploadFace,
			type: 'post',
			data: file,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 人脸识别
export const faceRecognitionThunk = fileId => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.getExistedFace,
			type: 'get',
			data: {
				fileId,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 根据人脸信息id来查询该人脸信息的详细信息
export const getFaceDetailListThunk = ids => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.getFaceDetailList,
			type: 'post',
			data: {
				ids,
			},
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})
