import { statisticsUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'

//获取当日档案，案卷数量信息[顶部]
export const queryDayCountThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: statisticsUrl.queryDayCount,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

// 获取各个类型的档案的数据 [图表一]
export const queryCountByTypeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: statisticsUrl.queryCountByType,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

// 获取最近12个月新增档案和案卷数据 [图标二]
export const queryIncreaseThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: statisticsUrl.queryIncrease,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

// 获取过去一年档案、案卷数量（折线图）[图表三]
export const queryCountThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: statisticsUrl.queryCount,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

// 获取过去一年借阅案卷、档案数量 [图表四]
export const queryUseCountThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: statisticsUrl.queryUseCount,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

// 获取过去一年新增容量 [图表二-二]
export const getIncreaseCapacityThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: statisticsUrl.getIncreaseCapacity,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})
