import React from 'react'
import { Checkbox } from 'antd'

/**
 * 最基本的单选框
 * @param {Object} config formItem的配置对象
 */
export const createCheckbox = config => <Checkbox.Group options={config.options} />

/* export default {
  createCheckbox
} */
