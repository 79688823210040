import { urlToArchiveManagement } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'

export const tips = {
	CHOOSE_AT_LEAST_ONE: '请选择至少一项后再进行操作',
}

//新增新媒体任务
export const addMediaJob = async data => {
	return _fetch({
		url: urlToArchiveManagement.addMediaJob,
		data,
		type: 'post',
		isJson: true,
	})
}

// 更新新媒体任务
export const updateMediaJob = data => {
	return _fetch({
		url: urlToArchiveManagement.updateMediaJob,
		data,
		type: 'post',
		isJson: true,
	})
}

// 删除新媒体任务
export const deleteMediaJob = data => {
	return _fetch({
		url: urlToArchiveManagement.deleteMediaJob,
		data: {
			ids: data,
		},
		type: 'post',
		isJson: true,
	})
}

// 测试url合不合理
export const testMediaUrl = data => {
	return _fetch({
		url: urlToArchiveManagement.testMediaUrl,
		type: 'post',
		isJson: true,
		data,
	})
}

// 修改爬取状态
export const cancelOrStartJob = data => {
	return _fetch({
		url: urlToArchiveManagement.cancelOrStartJob,
		type: 'post',
		isJson: true,
		data,
	})
}

// 客户端注册
export const registryClient = data => {
	return _fetch({
		url: urlToArchiveManagement.registryClient,
		type: 'post',
		isJson: true,
		data,
	})
}

// 客户端检查频道是否可用
export const checkMediaJob = params => {
	return _fetch({
		url: urlToArchiveManagement.checkMediaJob,
		type: 'get',
		isJson: true,
		params,
	})
}

export const urlToArchiveManagementOptions = [
	{
		label: '网页',
		value: 2,
	},
	{
		label: '公众号',
		value: 3,
	},

	{
		label: '抖音',
		value: 1,
	},

	{
		label: '微博',
		value: 6,
	},
	{
		label: '邮箱',
		value: 7,
	},
]

export const urlToArchiveManagementOptionsHZQ = [
	{
		label: '网页',
		value: 2,
	},
	{
		label: '公众号',
		value: 3,
	},
	{
		label: '抖音',
		value: 1,
	},
	{
		label: '微博',
		value: 6,
	},
	{
		label: '邮箱',
		value: 7,
	},
]
