import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import { Button, message, Upload, notification } from 'antd'
import './index.less'
import { filterYellow } from '@/Utils'
import { multimediaExtensions, multiMediaFileTypes } from './multimediaType'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'

import {
	createFileChunk,
	getFileMd5,
	uploadFile,
	addTemporaryArchiveThunk,
	pushRoutersAction,
	selectTemporaryArchiveThunk,
	getDossierTypeThunk,
} from './ActionCreators'

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	addTemporaryArchive: (...rest) => dispatch(addTemporaryArchiveThunk(...rest)), // 新增（保存临时档案）
	pushRouters: route => dispatch(pushRoutersAction(route)),
	selectTemporaryArchive: (...rest) => dispatch(selectTemporaryArchiveThunk(...rest)), // 查询临时档案
	getDossierType: (...rest) => dispatch(getDossierTypeThunk(...rest)), // 获取类别号
})

@connect(mapStateToProps, mapDispatchToProps)
class AddNewFileToArchive extends PureComponent {
	state = {
		checkedArr: [], //选中的数组
		fileList: [],
		multiMediaFileList: [], // 多媒体类型文件
		documentFileList: [], // 文书类型文件
		fileIds: [], // 返回的fileId数组
		success: true, // 是否上传成功
		archiveType: 1,
		originLength: 0, // 初始已上传长度
		current: 1,
	}

	judgeUploadFileType = (fileList, judgeMethod, originLength) => {
		return fileList.slice(0, fileList.length - originLength)[judgeMethod](file => {
			return file.type
				? multiMediaFileTypes.includes(file.type.split('/')[0])
				: multimediaExtensions.includes(
						file.name.slice(file.name.lastIndexOf('.'))
					)
		})
	}
	// 上传文件前先鉴黄
	handleBeforeUpload = async file => {
		this.props.isUploading(true)
		const testResult = await filterYellow(file)
		return new Promise((resolve, reject) => {
			// 如果鉴黄结果不通过，则不允许上传
			if (testResult.isPorn || testResult.isSexy || testResult.isHentai) {
				file.status = 'error'
				file.response = '图片包含敏感信息，不允许上传'
				reject()
			} else {
				resolve()
			}
		})
	}

	// 上传文件状态发生改变时触发
	handleChange = ({ file, fileList }) => {
		const { archiveType, multiMediaFileList, documentFileList } = this.state
		if (
			fileList.length > multiMediaFileList.length &&
			this.judgeUploadFileType(fileList, 'every', multiMediaFileList.length)
		) {
			this.setState({
				archiveType: 1,
			})
		} else if (
			fileList.length > documentFileList.length &&
			!this.judgeUploadFileType(fileList, 'some', documentFileList.length)
		) {
			this.setState({
				archiveType: 2,
			})
		}
		this.setState(state => ({
			fileList:
				archiveType === 1
					? [
							...state.fileList,
							...fileList.slice(
								0,
								fileList.length - multiMediaFileList.length
							),
						]
					: [
							...state.fileList,
							...fileList.slice(
								0,
								fileList.length - documentFileList.length
							),
						],
			multiMediaFileList: archiveType === 1 ? fileList : multiMediaFileList,
			documentFileList: archiveType === 2 ? fileList : documentFileList,
		}))
	}
	uploadFiles = async files => {
		if (files.file.size === 0) {
			message.info('不支持上传空文件')
			return
		} else if (files.file.size >= 5120 * 1024 * 1024) {
			message.info('仅限上传5G以内的文件')
			return
		}
		const fileName = files.file.name
		const fileType = files.file.type
		const fileChunkList = createFileChunk(files.file)
		const fileMd5Value = await getFileMd5(fileChunkList)
		const { addTemporaryArchive, selectTemporaryArchive } = this.props
		const { archiveType } = this.state
		uploadFile(
			fileMd5Value,
			fileName,
			fileChunkList,
			fileType,
			files,
			this.progressBar
		)
			.then(res => {
				let data = [
					{
						...res.data,
						archiveType: this.state.archiveType,
					},
				]
				// 形成临时档案
				addTemporaryArchive(data)
					.then(res => {
						this.props.isUploading(false)
						const { fileIds, checkedArr } = this.state
						fileIds.push(data[0].fileId) // 每次成功都将fileId放入数组
						selectTemporaryArchive().then(res => {
							localStorage.setItem(
								'TemporaryArchive',
								JSON.stringify(res.data)
							)
							checkedArr.push(res.data[0].id)
							sessionStorage.setItem(
								'temporaryArchive',
								JSON.stringify({
									archiveType,
									checkedId: res.data[0].id,
									archiveId: this.props.archiveId,
									markName: this.props.markName,
								})
							)
							history.push(
								linkTo.to(
									`/newConstruction/newFiles-multiMedia?archiveType=${archiveType}&current=1`
								)
							)
						})
					})
					.catch(err => {
						this.props.isUploading(false)
					})
				// 成功标志
				files.onSuccess(res, files.file)
			})
			.catch(err => {
				this.props.isUploading(false)
				notification.close(files.file.uid)
				message.error(`上传失败！${err.message}`)
				files.onError(err, files.file)
			})
	}
	//提交时清空所有
	finishClear = () => {
		this.setState({
			fileList: [],
		})
	}

	render() {
		const { fileList } = this.state
		return (
			<React.Fragment>
				<Upload
					name='multipartFile'
					customRequest={this.uploadFiles}
					onChange={this.handleChange}
					beforeUpload={this.handleBeforeUpload}
					showUploadList={false}
					fileList={fileList}
					previewFile={false}>
					<Button className='btn-success' icon={<UploadOutlined />}>
						新建
					</Button>
				</Upload>
			</React.Fragment>
		)
	}
}
export default AddNewFileToArchive
