import _fetch from '@/Utils/Fetch'
import { archiveTypeUrl, roleManagerUrl } from '@/Utils/Urls'
import { Modal, message } from 'antd'
// 消息提示
export const tips = {
	NO_PRE_RECORD: '没有上一项了',
	NO_NEXT_RECORD: '没有下一项了',
	CHOOSE_AT_LEAST_ONE: '请选择一项后再进行操作',
	RECALL_SUCCESS: '撤回成功',
	DELETE_SUCCESS: '删除成功',
	SUBMIT_SUCCESS: '提交成功',
	UPDATE_SUCCESS: '保存成功',
	ONLY_APPLICATION_STATUS: '只能对“非申请状态”下的借阅申请进行撤回',
}

/**
 * 删除分类
 * @param {Number} id 申请id
 */
export const deleteTypeThunk = record => () =>
	new Promise(resolve => {
		const { typeName, id } = record
		Modal.confirm({
			maskClosable: true,
			title: '删除分类',
			content: `您正在对当前"${typeName}"分类进行删除操作，是否继续？`,
			okText: '确认',
			cancelText: '取消',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: archiveTypeUrl.deleteDossierType,
					type: 'get',
					data: {
						id,
					},
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.DELETE_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 更新分类
 * @param {object} data 申请数据
 */
export const updateTypeThunk = data => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '编辑分类',
			content: '确定保存？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: archiveTypeUrl.updateDossierType,
					type: 'post',
					isJson: true,
					data,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.UPDATE_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

/*
 *新增案卷
 *
 */
export const addDossierTypeThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: _fetch({
				url: archiveTypeUrl.addDossierType,
				type: 'post',
				data: data,
				isJson: true,
			}).then(res => {
				if (res && res.data.code === 200) {
					message.success('添加成功！')
					resolve(res.data)
				}
			}).addDossierType,
			type: 'post',
			data: data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('添加成功！')
				resolve(res.data)
			}
		})
	})

/**
 * 获取分类树
 * @param {Number} id 分类名id（不填查询所有的分类）
 */
export const getDossierTypeTreeThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: archiveTypeUrl.getDossierType,
			data: {
				id,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

// 获取类别号
export const getDossierTypeThunk = () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: archiveTypeUrl.getDossierType,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

export const updateDossierType = data => {
	return _fetch({
		url: archiveTypeUrl.updateDossierTypeTree,
		type: 'post',
		isJson: true,
		data,
	})
}

// 获取角色
export const getRoleThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			type: 'get',
			url: roleManagerUrl.getRole,
			ContentType: 'application/x-www-form-urlencoded',
			data: {
				current: 1,
				size: 999,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 更新主管角色
 * @param {object} data 申请数据
 */
export const updateRoleTypeThunk = data => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '编辑主管角色',
			content: '确定保存？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: archiveTypeUrl.updateRoleType,
					type: 'post',
					isJson: true,
					data,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.UPDATE_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 批量修改档案类型审核角色
 * @param   {object}  data  包括以下属性
 *
 * @param   {Array}  ids  批量设置的档案类型id列表
 * @param   {number}  needVerify  是否需要审核
 * @param   {number}  role  设置的审核角色id（可选）
 * @param   {boolean}  supportBatch  是否连带更改子节点（可选，默认为 是）
 */
export const batchUpdateVerifyThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: archiveTypeUrl.batchUpdateVerify,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success(tips.UPDATE_SUCCESS)
				resolve(res.data.data)
			}
		})
	})
