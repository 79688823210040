import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { treeUtil } from '@/Utils'
import { applicationCorrectColumns } from '@/Public/PubTable/pageColumns'
import { correctApprovalUrl, dossierUrl } from '@/Utils/Urls.js'
import { connect } from 'react-redux'
import { history } from '@/index'
import { Button, Space, Modal } from 'antd'
import ApplyApproveModal from './ApplyApproveModal' // 审核模态框
import { linkTo } from '@/Routes/ManageRouter'
import {
	statusEnum,
	tips,
	finishApplyFixAction,
	routeChangeAction,
	addFixDataAction,
	pushRoutersAction,
	getVerifyDetailThunk,
	verifyApplyThunk, // 档案修正审核
	recallApplyThunk, // 撤回档案修正申请
} from './ActionCreator'

const applyApproveModalFormRef = React.createRef(null) // 审核 PubForm 实例
const fixLink = {
	title: '修正详情',
	key: '/workarea/commonComponents/fixDetails',
}
const markName = 'ApprovalCorrect' // redux的标识
const mapStateToProps = state => {
	return {
		current: state.pubForm.pub_fixApplyDetails.current, // 获取当前修正申请状态
		Selections: state.pubTable.pub_rowSelection.ApprovalCorrect, // 获取选中数据
	}
}
const mapDispatchToProps = dispatch => {
	return {
		verifyApply: (...rest) => dispatch(verifyApplyThunk(...rest)),
		recallApply: (...rest) => dispatch(recallApplyThunk(...rest)),
		getFixDetail: (...rest) => dispatch(getVerifyDetailThunk(...rest)), // 详情
		addFixData: (data, category) => dispatch(addFixDataAction(data, category)), // 添加
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey.key))
			} else {
				dispatch(routeChangeAction(activeKey.key))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
		},
		finishApplyFix() {
			dispatch(finishApplyFixAction())
		},
		pushRouters(link) {
			return dispatch(pushRoutersAction(link))
		},
	}
}
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class ApprovalCorrect extends Component {
	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{
				type: 'Input',
				name: 'topic',
				label: '题名',
				placeholder: '请输入题名',
			},
			{
				type: 'AsyncTreeSelect',
				name: 'type',
				label: '类型',
				ajaxConfig: {
					url: dossierUrl.getDossierType,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys(
						data.children,
						{
							typeName: 'title',
							id: 'value',
						},
						'children',
						node => {
							node['value'] = node['title']
						}
					)
				},
			},
			{
				type: 'DatePicker',
				name: 'applyTime',
				label: '申请时间',
				placeholder: '请选择申请时间',
			},
			{
				type: 'Select',
				name: 'status',
				label: '状态',
				placeholder: '请输入处理状态',
				options: statusEnum.toOptions(), // 申请的处理状态
				minWidth: '165px',
				initialValue: 1,
			},
			{
				type: 'Extra',
				name: 'buttonList',
				render: saerchFormRef => {
					return (
						<Space style={{ marginTop: '3px' }}>
							<Button
								className='btn-success iconfont-buttons'
								title='审核'
								onClick={() => this.openApplyApproveModal()}>
								&#xe616; 审核
							</Button>
							<Button
								className='btn-warning iconfont-buttons'
								title='撤回'
								onClick={() => this.handleWithdrawApply()}>
								&#xe637; 撤回
							</Button>
						</Space>
					)
				},
			},
		],
		ajaxConfig: {
			ajaxType: 'post',
			url: correctApprovalUrl.getFixApplyList,
			ContentType: 'application/json',
		},
	}

	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 * BUGBUGBUGBUGBUGBUGBUGBUGBUGBUG
	 */
	changeSearchLimit = data => {
		data.topic = data.topic || data.topic === '' ? [data.topic] : data.topic // 给topic转换为数组形式
		data.current = data.currentPage
		delete data.currentPage
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = res => {
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}

	returnButton = record => {
		// 返回按钮数据
		return (
			<Button
				onClick={() => {
					this.props.getFixDetail(record.id).then(data => {
						const insertData = {
							applyCode: record.applyCode, // 申请编号
							createTime: record.createTime, // 申请时间
							applyUser: record.applyUser, // 申请人
							fix_status: record.status, // 修正表状态
							applyId: record.id, // 申请表id
							fixApply_status: data[data.length - 1]['status'], // 因为bug重新改一个命名
						}
						let lastData = data.pop() // 取出最后一个对象
						lastData = {
							// 添加值
							...lastData,
							...insertData,
						}
						data.push(lastData) // 放进去
						this.props.addFixData(data, 1) // 传参，申请表状态
						this.props.onChange(fixLink, history) // 更改路由
						this.props.pushRouters(fixLink) // 添加分页
					})
				}}>
				查看
			</Button>
		)
	}

	// 弹出模态框（至少选择一项）
	showModel = () => {
		Modal.error({
			content: tips.CHOOSE_AT_LEAST_ONE,
			maskClosable: true,
		})
	}

	// 撤回修正申请
	handleWithdrawApply = async () => {
		const { recallApply, pub_getAllData, Selections } = this.props
		if (Selections.length) {
			let applyIds = []
			if (!Selections.every(item => statusEnum[item.status] === '待审批')) {
				return Modal.error({
					content: tips.ONLY_RECALL_APPROVE_STATUS,
					maskClosable: true,
				})
			}
			applyIds = Selections.map(({ id }) => id)
			recallApply(applyIds).then(() => {
				pub_getAllData()
			})
		} else {
			this.showModel()
		}
	}

	// 打开审核模态框
	openApplyApproveModal = async () => {
		try {
			const { Selections } = this.props
			if (Selections.length) {
				if (!Selections.every(item => statusEnum[item.status] === '待审批')) {
					return Modal.error({
						content: tips.ONLY_VERIFY_APPROVE_STATUS,
						maskClosable: true,
					})
				}
				applyApproveModalFormRef.current.show()
			} else {
				this.showModel()
			}
		} catch (err) {}
	}

	// 对申请单个/批量审核
	handleVerifyApply = (formData, { hide, finish }) => {
		const {
			verifyApply, // 审核接口
			pub_getAllData,
			Selections,
		} = this.props

		const ids = Selections.map(({ id }) => id)
		formData['applyId'] = ids

		verifyApply(formData)
			.then(() => {
				hide()
				pub_getAllData()
			})
			.catch(() => finish())
	}

	render() {
		return (
			<Fragment>
				<WrappedTable>
					<SearchForm
						name='Approval-Correct-search-form'
						formItems={this.searchFormConfig}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
					/>
					<BaseTable
						tableConfig={applicationCorrectColumns}
						actions={this.actions}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
						operationButton={this.returnButton} // 自定义操作按钮
						withPreview={false}
					/>
					{/* 档案修正申请审核模态框 */}
					<ApplyApproveModal
						modalRef={applyApproveModalFormRef}
						onOk={this.handleVerifyApply}
					/>
				</WrappedTable>
			</Fragment>
		)
	}
}

export default ApprovalCorrect
