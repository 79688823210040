import _fetch from '@/Utils/Fetch'
import { fondsManagerUrl } from '@/Utils/Urls'
import { Modal, message } from 'antd'
export const tips = {
	CHOOSE_AT_LEAST_ONE: '请选择至少一项后再进行操作',
	UPDATE_SUCCESS: '保存成功',
}

// 删除全宗号
export const deleteFondsIdentifierThunk = id => () =>
	new Promise((resolve, reject) => {
		Modal.confirm({
			maskClosable: true,
			title: '删除全宗申请',
			content: '您正在对当前申请进行删除操作，是否继续？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: fondsManagerUrl.deleteFondsIdentifier,
					type: 'post',
					data: id,
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success('删除成功！')
						resolve(res)
					}
				})
			},
		})
	})

// 新增全宗号
export const addFondsIdentifierThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: fondsManagerUrl.addFondsIdentifier,
			type: 'post',
			data: data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('添加成功！')
				resolve(res.data)
			} else {
				reject(res)
			}
		})
	})

/**
 * 编辑申请
 * @param {object} data 申请数据
 */
export const updateFixApplyThunk = data => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '编辑宗卷',
			content: '确定保存？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: fondsManagerUrl.updateFondsIdentifier,
					type: 'post',
					isJson: true,
					data,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.UPDATE_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})
