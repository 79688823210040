import React, { Component } from 'react'
import { connect } from 'react-redux'
import OldDetails from './OldDetails'
import Application from './Application'
import SelectFixFile from './SelectFixFile'
import './index.less'
import { Steps } from 'antd'

const { Step } = Steps

// 步骤条配置
const steps = [
	{
		key: 'First',
		title: '选择文件',
	},
	{
		key: 'Second',
		title: '修正文件&案卷',
	},
	{
		key: 'Last',
		title: '修正申请表',
	},
]

@connect(state => ({
	current: state.pubForm.pub_fixApplyDetails.current, // 获取当前状态
}))
class FileFixApply extends Component {
	render() {
		const { current, fix_apply } = this.props
		return (
			<>
				{/* {//console.log(current)} */}
				<Steps current={current}>
					{steps.map(item => (
						<Step key={item.title} title={item.title} />
					))}
				</Steps>
				<div className='steps-content'>
					{current === 0 && (
						// 选择文件页
						<SelectFixFile />
					)}
					{current === 1 && (
						// 修改内容页
						<OldDetails className='oldDetails' />
					)}
					{current === 2 && (
						// 填写申请表
						<Application record={fix_apply} />
					)}
				</div>
			</>
		)
	}
}

export default FileFixApply
