import StreamSaver from 'streamsaver'
import _fetch from '@/Utils/Fetch'
import { collectedFileUrl } from '@/Utils/Urls'
import { PUB_CLEAR_SELECTED } from '@/Redux/ActionTypes/Public/PubTable/publicActionType.js'
import { TAB_ADD } from '@/Redux/ActionTypes/Route'

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

// 获取类别号
export const getCollectedFileTypeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: collectedFileUrl.getCollectedFileType,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 删除资料
export const deleteCollectedFileThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: collectedFileUrl.deleteCollectedFile,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 导出资料
export const exportCollectedFileThunk = (fileIds, themeId, themeTopic) => () =>
	new Promise((resolve, reject) => {
		fetch(collectedFileUrl.getExcelAndZip, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				Authorization:
					JSON.parse(localStorage.getItem('userMessage') ?? {}).token || '',
			},
			body: JSON.stringify({
				ids: fileIds,
				themeId,
			}),
		}).then(res => {
			if (res && res.status === 200) {
				const fileStream = StreamSaver.createWriteStream(
					(themeTopic ? themeTopic : '资料') + '.zip'
				)
				const readableStream = res.body

				// more optimized
				if (window.WritableStream && readableStream.pipeTo) {
					return readableStream.pipeTo(fileStream).then(resolve)
				}

				const writer = fileStream.getWriter()

				const reader = res.body.getReader()
				const pump = () =>
					reader
						.read()
						.then(({ value, done }) =>
							done ? writer.close() : writer.write(value).then(pump)
						)

				pump()
			} else {
				reject()
			}
		})
	})

// 获取主题
export const getMyThemeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: collectedFileUrl.getMyTheme,
			type: 'post',
		})
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data.data)
				} else {
					reject(res.data)
				}
			})
			.catch(err => {
				reject(err.response.data.message)
			})
	})

/**
 * 清空redux上被选中项
 * @param {*} markId 组件独特标志
 * @returns
 */
export const clearSelectedDataThunk = markId => ({
	type: PUB_CLEAR_SELECTED,
	markId,
})
