import React, { Component } from 'react'
import SiriWave from './siriwave'

export default class Wave extends Component {
	switchShow = status => {
		const { uniqueName } = this.props
		if (status === 'play') {
			this.wave.canvas.style.opacity = 1
			document.getElementById(`wave-bar-${uniqueName}`).style.opacity = 0
		} else {
			this.wave.canvas.style.opacity = 0
			document.getElementById(`wave-bar-${uniqueName}`).style.opacity = 1
		}
	}

	componentDidMount() {
		const { uniqueName } = this.props
		const height = document.querySelector('.wave-container').offsetHeight
		const width = document.querySelector('.wave-container').offsetWidth

		this.wave = new SiriWave({
			container: document.getElementById(`waveform-${uniqueName}`),
			width,
			height,
			cover: true,
			speed: 0.04,
			amplitude: 0.5,
			frequency: 2,
			color: '#A410EA',
		})
		this.wave.start()

		this.wave.height = height
		this.wave.height_2 = height / 2
		this.wave.MAX = this.wave.height_2 - 4
		this.wave.width = width
		this.wave.width_2 = width / 2
		this.wave.width_4 = width / 4
		this.wave.canvas.height = height
		this.wave.canvas.width = width

		this.switchShow('pause')
	}

	componentDidUpdate() {
		const { status } = this.props
		if (status) this.switchShow(status)
	}

	render() {
		const { uniqueName } = this.props
		return (
			<div className='wave-container'>
				<div className='waveform' id={`waveform-${uniqueName}`}></div>
				<div className='wave-bar' id={`wave-bar-${uniqueName}`}></div>
			</div>
		)
	}
}
