import React, { Component } from 'react'
import { Drawer, Button, Divider, Collapse, Popover, Table } from 'antd'
import { connect } from 'react-redux'
import { dossierEnum } from '@/Utils/Enum'
import FilePreviewer from '@/Public/FilePreviewer'
import { PubForm } from '@/Public/PubForm'
import { filesUrl } from '@/Utils/Urls.js'
import { addArchivesUrl } from '@/Utils/Urls'

const { Panel } = Collapse

const mapStateToProps = state => {
	return {
		pub_OldDetail: state.pubForm.pub_fixApplyDetails.old_details, // 获取修正前数据
		pub_NewDetails: state.pubForm.pub_fixApplyDetails.new_details, // 获取修正后数据
	}
}
const mapDispatchToProps = dispatch => ({})

@connect(mapStateToProps, mapDispatchToProps)
class DrawerDetails extends Component {
	state = {
		visible: false, // 抽屉开关
	}

	// 开抽屉
	showDrawer = () => {
		this.setState({
			visible: true,
		})
	}

	// 关抽屉
	onClose = () => {
		this.setState({
			visible: false,
		})
	}

	formItems = [
		[
			{
				type: 'Input',
				name: 'topic',
				label: '题名',
				placeholder: '无',
				rules: [{ required: false }],
				disabled: true,
			},
			{
				type: 'AsyncSelect',
				name: 'fondsIdentifierId', // 全宗号id
				label: '全宗',
				ajaxConfig: {
					url: addArchivesUrl.getFondsIdentifier,
				},
				formatDataFunc: data => {
					return data.map(item => ({
						label: item.fondsName,
						value: item.id,
					}))
				},
				propsClass: 'fondsIdentifierId',
				disabled: true,
			},
			{
				type: 'Input',
				name: 'yearNumber',
				label: '年度',
				placeholder: '无',
				rules: [{ required: false }],
				disabled: true,
			},
			{
				type: 'AsyncTreeSelect',
				name: 'categoryCodeId', // 类别号
				label: '类别号',
				ajaxConfig: {
					url: filesUrl.getDossierType,
				},
				formatDataFunc: (data, changeKeys) => {
					let treeData = changeKeys(data.children, {
						typeName: 'title',
						id: 'value',
					})
					return treeData
				},
				rules: [{ required: false }],
				placeholder: '请输入类别号',
				disabled: true,
				propsClass: 'categoryCodeId',
			},
			{
				type: 'Select',
				name: 'confidentialLevel',
				label: '密级',
				options: dossierEnum.confidentialLevel.toOptions(),
				placeholder: '无',
				rules: [{ required: false }],
				disabled: true,
			},
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId', // 部门id
				label: '部门',
				ajaxConfig: {
					url: filesUrl.getDepartmentTree,
				},
				formatDataFunc: (data, changeKeys) => {
					let treeData = changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
					return treeData
				},
				rules: [{ required: false }],
				placeholder: '请输入部门',
				disabled: true,
				propsClass: 'departmentId',
			},
			{
				type: 'Input',
				name: 'seeNumber',
				label: '参见号',
				placeholder: '无',
				rules: [{ required: false }],
				disabled: true,
			},
			{
				type: 'Input',
				name: 'keyWord',
				label: '关键词',
				placeholder: '无',
				rules: [{ required: false }],
				disabled: true,
			},
			{
				type: 'Radio',
				name: 'retentionPeriod',
				width: 200,
				label: '保管年限',
				options: dossierEnum.retentionPeriod.toOptions(),
				placeholder: '无',
				rules: [{ required: false }],
				disabled: true,
			},
			{
				type: 'InputWithPicker',
				name: 'recordUserAndTime',
				keys: this.props.pub_OldDetail.type
					? ['recordUserName', 'recordTime']
					: ['recordUser', 'recordTime'],
				label: '著录用户',
				rules: [{ required: false }],
				value: this.props.pub_OldDetail.recordUserAndTime,
				disabled: true,
			},
			{
				type: 'InputWithPicker',
				name: 'archiveUserAndTime',
				keys: this.props.pub_OldDetail.type
					? ['archiveUserName', 'archiveTime']
					: ['archiveUser', 'archiveTime'],
				label: '归档用户',
				rules: [{ required: false }],
				value: this.props.pub_OldDetail.archiveUserAndTime,
				disabled: true,
			},
			{
				type: 'InputWithPicker',
				name: 'storageUserAndTime',
				keys: this.props.pub_OldDetail.type
					? ['storageUserName', 'storageTime']
					: ['storageUser', 'storageTime'],
				label: '入库用户',
				rules: [{ required: false }],
				value: this.props.pub_OldDetail.storageUserAndTime,
				disabled: true,
			},
		],
	]

	// 设置元数据表单的值
	renderMetadata = data => {
		const nowRecord = data.metadataStructTreeBoList || []
		const metadataForm = [] // 元数据配置数组

		nowRecord.forEach(record => {
			// 遍历找到父节点
			if (record.parentId === 0) {
				metadataForm.push(
					...[
						[
							{
								type: 'Extra',
								name: `parent_${record.id}`,
								rules: [{ required: false }],
								render: formRef => (
									<div>
										<Divider
											className='matedataDivider'
											orientation='left'
											id={`parent_${record.id}`}>
											{record['metadataName']}
										</Divider>
									</div>
								),
							},
						],
					]
				)

				if (record['metadataValue']) {
					// 如果没有子节点则渲染自己的数据值
					metadataForm.push(
						...[
							[
								{
									type: 'Input',
									name: `${record.id}`,
									label: `${record['metadataName']}`,
									span: 19,
									rules: [{ required: false }],
									initialValue: record['metadataValue'],
									bordered: false,
								},
							],
						]
					)
				} else {
					// 重新遍历找到该父节点的子节点
					nowRecord.forEach(recordSon => {
						if (recordSon.parentId === record.id) {
							metadataForm.push(
								...[
									[
										{
											type: 'Input',
											name: `${recordSon.id}`,
											label: `${recordSon['metadataName']}`,
											span: 19,
											rules: [{ required: false }],
											initialValue: recordSon['metadataValue'],
											bordered: false,
										},
									],
								]
							)
						}
					})
				}
			}
		})
		return metadataForm.length
			? metadataForm
			: [
					[
						{
							type: 'Input',
							name: 'topic',
							label: '',
							placeholder: '暂无数据',
							rules: [{ required: false }],
							bordered: false,
						},
					],
				]
	}

	// 设置时间和人物并列数据
	setTimeAndUserData = data => {
		// 著录人和时间都是
		const {
			recordTime,
			recordUserName,
			archiveTime,
			archiveUserName,
			storageTime,
			storageUserName,
			recordUser,
			archiveUser,
			storageUser,
		} = this.props.pub_OldDetail
		// 著录人和时间
		data['recordUserAndTime'] = { recordUserName, recordUser, recordTime }
		// 归档人和时间
		data['archiveUserAndTime'] = { archiveUserName, archiveUser, archiveTime }
		// 入库人和时间
		data['storageUserAndTime'] = { storageUserName, storageUser, storageTime }
		return data
	}

	pushTemporary = (Temporary, recordContent, recordNewMetadata) => {
		Temporary.push(
			...[
				[
					{
						type: 'Input',
						name: `${recordContent.id}`,
						label: `${recordContent['metadataName']}`,
						span: 19,
						rules: [{ required: false }],
						initialValue: recordNewMetadata.metadataValue,
						bordered: false,
					},
				],
			]
		)
	}

	renderNewMetadata = () => {
		if (!this.props.pub_NewDetails.metaData) {
			return this.renderMetadata(this.props.pub_OldDetail) // 如果没有元数据信息则返回旧版信息
		}
		const { fileType, archiveType } = this.props.pub_NewDetails
		let metadataAll = JSON.parse(localStorage.getItem('metadata'))
		let docMetaData = JSON.parse(localStorage.getItem('handledDocMetaData'))
		let nowRecord = this.props.pub_NewDetails.metaData
		let metadataForm = [] // 元数据配置数组
		let Temporary = [] // 元数据配置数组(临时)
		let metadataList = []
		for (let i in nowRecord) {
			// 给nowRecord转换形式
			if (nowRecord[i]) {
				metadataList.push({
					metadataId: parseInt(i),
					metadataValue: nowRecord[i],
				})
			}
		}
		if (archiveType === 1) {
			const currentMetadata = [
				...metadataAll.publicMetadataStruct,
				...metadataAll.specialMetadataStruct,
				...(metadataAll[`${fileType?.split('/')[0]}MetadataStruct`]
					? metadataAll[`${fileType?.split('/')[0]}MetadataStruct`]
					: []),
			] // 判断元数据里是否有该文件类型的数据
			currentMetadata.forEach(record => {
				// 遍历找到父节点
				if (record.parentId === 0) {
					Temporary.push(
						...[
							[
								{
									type: 'Extra',
									name: `parent_${record.id}`,
									rules: [{ required: false }],
									render: formRef => (
										<div>
											<Divider
												className='matedataDivider'
												orientation='left'
												id={`parent_${record.id}`}>
												{record['metadataName']}
											</Divider>
										</div>
									),
								},
							],
						]
					)

					metadataList.forEach(recordNewMetadata => {
						if (recordNewMetadata.metadataId === record.id) {
							this.pushTemporary(Temporary, record, recordNewMetadata)
						} else {
							// 重新遍历找到该父节点的子节点
							record.child &&
								record.child.forEach(recordSon => {
									if (
										recordSon.parentId === record.id &&
										recordNewMetadata.metadataId === recordSon.id
									) {
										this.pushTemporary(
											Temporary,
											recordSon,
											recordNewMetadata
										)
									}
								})
						}
					})
				}
				// 如果有数据则放到主数组
				Temporary.length === 1 || metadataForm.push(...Temporary)
				Temporary = []
			})
		} else if (archiveType === 2) {
			metadataAll.specialMetadataStruct.forEach(record => {
				metadataList.forEach(recordNewMetadata => {
					if (recordNewMetadata.metadataId === record.id) {
						this.pushTemporary(Temporary, record, recordNewMetadata)
					} else {
						// 重新遍历找到该父节点的子节点
						record.child &&
							record.child.forEach(recordSon => {
								if (
									recordSon.parentId === record.id &&
									recordNewMetadata.metadataId === recordSon.id
								) {
									this.pushTemporary(
										Temporary,
										recordSon,
										recordNewMetadata
									)
								}
							})
					}
				})
			})
			docMetaData[0].forEach(record => {
				metadataList.forEach(recordNewMetadata => {
					recordNewMetadata.metadataId === record.id &&
						this.pushTemporary(Temporary, record, recordNewMetadata)
				})
			})
			// 如果有数据则放到主数组
			Temporary.length === 1 || metadataForm.push(...Temporary)
			Temporary = []
		}

		return metadataForm.length
			? metadataForm
			: [
					[
						{
							type: 'Input',
							name: 'topic',
							label: '',
							placeholder: '暂无数据',
							rules: [{ required: false }],
							bordered: false,
						},
					],
				]
	}

	content = archiveDetail => {
		let columns = [
			{ title: '题名', dataIndex: 'topic', width: '12.5%' },
			{
				title: '预览图',
				dataIndex: 'fileData',
				width: '12.5%',
				height: '50px',
				render: (text, record, formRef, tableFuncs) => {
					return record?.fileType == null ? null : (
						<FilePreviewer fileData={record} isThumbnail={true} />
					)
				},
			},
			{ title: '分类', dataIndex: 'categoryCode', width: '12.5%' },
		]

		return (
			<Table
				className='fix-drawer-file-table'
				dataSource={archiveDetail}
				columns={columns}
				scroll={{ y: 350 }}
				pagination={{ defaultPageSize: 5, simple: true }}
			/>
		)
	}

	// 显示文件
	showFields = data => {
		const { type, fileType, fileToken, thumbnailFileToken, thumbnailFileType } = data
		let fileData

		// 案卷返回null
		if (!type) {
			fileData = {
				fileType: null,
			}
		} else {
			if (thumbnailFileToken) {
				fileData = {
					fileToken: thumbnailFileToken,
					fileType: thumbnailFileType || fileType,
				}
			} else if (fileToken) {
				fileData = { fileToken, fileType }
			} else {
				fileData = {
					fileToken: this.props.pub_OldDetail.fileToken,
					fileType: this.props.pub_OldDetail.fileType,
				}
			}
		}
		return (
			<div className='fix-drawer-file'>
				{/* 显示文件 */}
				<FilePreviewer type={type} fileData={fileData} />
				{type ? null : (
					<Popover
						placement='bottomRight'
						content={this.content(data.archiveDetail)}
						trigger='click'>
						<Button className='key-archiveDetail'>查看文件列表</Button>
					</Popover>
				)}
			</div>
		)
	}

	// 不同标红
	setInputStyle = () => {
		let formData = {}
		// 处理
		this.props.pub_OldDetail.metadataStructTreeBoList?.forEach(current => {
			formData[`${current.id}`] = current.metadataValue
		})
		Object.keys(this.props.difObj).forEach(key => {
			// 遍历根据key给不同的dom元素标红
			if (key === 'fileId') {
				let dom = document
					.querySelector(`.fix-drawer-file`)
					?.querySelector(`.file-previewer-wrapper `)
				dom.style.border = '3px solid red'
				return true
			}
			let difDom =
				document
					.getElementById(`FixAfterForm_${this.props.drawerKey}`)
					?.querySelector(`div[name="${key}"]`) ||
				document.getElementById(`FixAfterForm_${this.props.drawerKey}_${key}`) ||
				document
					.getElementById(`FixAfterForm_${this.props.drawerKey}`)
					?.querySelector(`.${key}`)
			if (difDom) {
				difDom.style.borderBottom = '2px solid red'
			} else {
				if (
					key === 'archiveDetail' &&
					document.querySelector('.key-archiveDetail')
				) {
					document.querySelector('.key-archiveDetail').style.borderBottom =
						'2px solid red'
				}
			}
		})

		// 元数据标红
		let difMatedataObj = this.props.difference(
			this.props.pub_NewDetails.metaData,
			formData
		) // 判断不同的对象
		Object.keys(difMatedataObj).forEach(key => {
			// 遍历根据key给不同的dom元素标红

			// 下拉框标红
			if (document.querySelector('.key-metaData')) {
				document.querySelector('.key-metaData').style.borderBottom =
					'2px solid red'
			}

			let difMatedataDom = document.getElementById(
				`MetadataAfterFormDemo_${this.props.drawerKey}_${key}`
			)
			if (difMatedataDom) {
				difMatedataDom.style.borderBottom = '2px solid red'
			}
		})
	}

	render() {
		const { visible } = this.state
		const { pub_OldDetail, drawerKey, pub_NewDetails } = this.props
		return (
			<>
				<Button
					type='primary'
					onClick={this.showDrawer}
					style={{ marginRight: '30px' }}>
					修改对比
				</Button>
				{/* 抽屉 */}
				<Drawer
					title='修正对比'
					placement='right'
					onClose={this.onClose}
					visible={visible}
					className='form-Drawer'
					width='1000px'>
					{/* table是为了可以两个表单并列显示 */}
					<table>
						<tbody>
							<tr>
								<td valign='top'>
									<h1>修正后</h1>
									{this.showFields(pub_NewDetails)}
									{/* 表单 */}
									<PubForm
										ref={this.formRef}
										formItems={this.formItems}
										getInstance={formRef => {
											this.formRef = formRef
											this.formRef.setFieldsValue(
												this.setTimeAndUserData(pub_NewDetails)
											)
											setTimeout(() => {
												// 这里标红是通过dom操作 所以必须得等待dom节点渲染上去才能获取到 setTimeout是宏任务发生在页面渲染之后
												this.setInputStyle()
											}, 0)
										}}
										name={'FixAfterForm_' + drawerKey}
										title='修正后申请表'
									/>
									{/* 判断显示元数据 */}
									{pub_NewDetails.metadataStructTreeBoList && (
										<Collapse defaultActiveKey={['1']}>
											<Panel
												header='元数据详情'
												key='1'
												className='key-metaData'>
												{/* 显示文件详情 */}
												<PubForm
													ref={this.formRef}
													formItems={this.renderNewMetadata()}
													getInstance={formRef => {
														this.formRef = formRef
													}}
													name={
														'MetadataAfterFormDemo_' +
														drawerKey
													}
													title='元数据详情'
													formDisabled
												/>
											</Panel>
										</Collapse>
									)}
								</td>
								<td className='last-td' valign='top'>
									<h1>修正前</h1>
									{this.showFields(pub_OldDetail)}
									{/* 表单 */}
									<PubForm
										ref={this.formRef}
										formItems={this.formItems}
										getInstance={formRef => {
											this.formRef = formRef
											this.formRef.setFieldsValue(
												this.setTimeAndUserData(pub_OldDetail)
											)
										}}
										name={'FixBeforeForm_' + drawerKey}
										title='修正前申请表'
									/>
									{/* 判断显示元数据 */}
									{pub_OldDetail.metadataStructTreeBoList && (
										<Collapse defaultActiveKey={['1']}>
											<Panel header='元数据详情' key='1'>
												{/* 显示文件详情 */}
												<PubForm
													ref={this.formRef}
													formItems={this.renderMetadata(
														pub_OldDetail
													)}
													getInstance={formRef => {
														this.formRef = formRef
													}}
													name={
														'MetadataBeforeFormDemo_' +
														drawerKey
													}
													title='元数据详情'
													formDisabled
												/>
											</Panel>
										</Collapse>
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<Divider />
				</Drawer>
			</>
		)
	}
}

export default DrawerDetails
