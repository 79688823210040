import React, { Component } from 'react'
import axios from 'axios'

import { message } from 'antd'

import { ePhotoAlbum } from '@/Utils/Urls'
import './index.less'

export default class ShareText extends Component {
	state = {
		htmlData: '',
	}

	componentDidMount() {
		const filesStr = window.location.search.replace('?files=', '')
		axios
			.get(ePhotoAlbum.getPublishedRichText, {
				params: {
					token: filesStr,
				},
			})
			.then(res => {
				document.getElementById('share-container').innerHTML = res.data.data
				this.setState({
					htmlData: res.data.data,
				})
			})
			.catch(err => {
				message.error('请求失败，请刷新页面重试', 0)
			})
	}

	componentWillUnmount() {}

	state = {}

	render() {
		return <div id='share-container'></div>
	}
}
