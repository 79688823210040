import React, { Component } from 'react'
import { Button, Table, Checkbox, Modal, message } from 'antd'
import { getAllSystemConfig, updateSystemConfigByKey } from './ActionCreators'

export default class AutoDescriptionConfigure extends Component {
	state = {
		tableData: [],
		disabled: true,
		ready_auto_stage: [], // 阶段配置
		ready_auto_import: [], // 导入方式配置
	}

	stageOptions = [
		{ label: '著录', value: '著录' },
		{ label: '预归档', value: '预归档' },
		{ label: '入库', value: '入库' },
	]
	methodOptions = [
		{ label: '独立文件', value: '独立文件' },
		{ label: '单一案卷', value: '单一案卷' },
		{ label: '多案卷', value: '多案卷' },
	]

	componentDidMount() {
		this.getConfig() // 获取配置信息
	}

	getConfig = () => {
		getAllSystemConfig().then(config => {
			let { ready_auto_stage, ready_auto_import } = config
			ready_auto_stage = ready_auto_stage.split(',') // 分割字符串
			ready_auto_import = ready_auto_import.split(',')

			this.setState({
				ready_auto_stage,
				ready_auto_import,
			})
		})
	}

	//点击按钮提交or编辑回调
	clickBtnCallback = isEdit => {
		this.setState({ disabled: !isEdit })
		if (!isEdit) {
			this.onFinish()
		}
	}

	// 阶段配置改变后调用
	stageChange = e => {
		this.setState({
			ready_auto_stage: e,
		})
	}

	// 导入方式配置改变后调用
	methodChange = e => {
		this.setState({
			ready_auto_import: e,
		})
	}

	onFinish = () => {
		Modal.confirm({
			icon: <></>,
			title: <h3>提示</h3>,
			content: '是否确认修改系统配置？',
			okText: '确认',
			cancelText: '取消',
			onOk: () => {
				let { ready_auto_stage, ready_auto_import } = this.state
				ready_auto_stage = ready_auto_stage.join(',')
				ready_auto_import = ready_auto_import.join(',')
				updateSystemConfigByKey({
					key: 'ready_auto_stage',
					values: ready_auto_stage,
				})
					.then(res => {
						return updateSystemConfigByKey({
							key: 'ready_auto_import',
							values: ready_auto_import,
						})
					})
					.then(res => {
						if (res.success) {
							message.success('修改成功')
						}
					})
			},
		})
	}

	render() {
		let { tableData, ready_auto_stage, ready_auto_import, disabled } = this.state
		tableData = [{ key: 'stage', dataIndex: 'stage' }]
		return (
			<>
				<Button
					onClick={() => {
						this.clickBtnCallback(disabled)
					}}
					style={{ display: 'block' }}
					type={'primary'}>
					{disabled ? '编辑' : '提交'}
				</Button>
				<Table
					pagination={{
						position: ['none', 'none'],
					}}
					columns={[
						{
							title: '选择阶段',
							dataIndex: 'stage',
							key: 'stage',

							render: (text, record, index) => {
								return (
									<Checkbox.Group
										key={ready_auto_stage}
										defaultValue={ready_auto_stage}
										options={this.stageOptions}
										onChange={this.stageChange}
										disabled={disabled}
									/>
								)
							},
						},
						{
							title: '导入方式',
							dataIndex: 'method',
							key: 'method',
							render: (text, record, index) => {
								return (
									<Checkbox.Group
										key={ready_auto_import}
										defaultValue={ready_auto_import}
										options={this.methodOptions}
										onChange={this.methodChange}
										disabled={disabled}
									/>
								)
							},
						},
					]}
					dataSource={tableData}
				/>
			</>
		)
	}
}
