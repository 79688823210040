import _fetch from '@/Utils/Fetch'
import { serialNumberUrl } from '@/Utils/Urls'

export const getAllSystemConfig = () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: serialNumberUrl.getAllSystemConfig,
			type: 'get',
		})
			.then(res => {
				if (res.data.code === 200) {
					resolve(res.data.data)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})

export const updateSystemConfigByKey = data =>
	new Promise((resolve, reject) => {
		_fetch({
			url: serialNumberUrl.updateSystemConfigByKey,
			type: 'post',
			isJson: true,
			data: {
				key: data.key,
				values: data.values,
			},
		})
			.then(res => {
				if (res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})
