import React, { Component } from 'react'
import SearchForm from '@/Public/PubTable/searchForm/index'
import BaseTable from '@/Public/PubTable/baseTable/index'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { CompositeSearchColumns } from '@/Public/PubTable/pageColumns'
import { connect } from 'react-redux'
import { Button, message, Modal } from 'antd'
import { dossierEnum } from '@/Utils/Enum'
import { departmentUrl, dossierUrl } from '@/Utils/Urls'
import { treeUtil } from '@/Utils'

const tips = {
	CHOOSE_AT_LEAST_ONE: '请选择至少一项后再进行操作',
	SUBMIT_SUCCESS: '提交成功',
}

// 表单布局配置项
const formLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 10 },
}
// formItem布局配置项
const formItemLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}

const mapStateToProps = state => {
	return {
		packArchive_filesData: state.packArchive.filesData, // 文件组卷选中的文件
		route: state.route.item,
	}
}
const mapDispatchToProps = dispatch => {
	return {}
}
// 将SearchForm和BaseTable传入baseTableHOC中进行进一步包装，使其两者能够结合并且能共用redux公共组件中的状态
// @baseTableHOC() // 被包装过的组件能直接通过this.props拿到公共组件的数据和方法,如果获取表格数据的方法和查询数据的方法一样，也可以直接传查询的接口配置为参数
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create() // 用create方法包装的组件可以拿到pub方法和数据
class CompositeSearchModal extends Component {
	searchFormRef = null
	// 表单配置项
	searchFormConfig = {
		formItems: [
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId', // 部门id
				label: '部门',
				layout: formItemLayout,
				minWidth: '160px',
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'AsyncTreeSelect',
				name: 'categoryCodeId', // 分类号
				label: '分类',
				minWidth: '155px',
				ajaxConfig: {
					url: dossierUrl.getDossierType,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys(data.children, {
						typeName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'Select',
				name: 'retentionPeriod', // 保管期限
				label: '保管期限',
				minWidth: '155px',
				layout: {
					labelCol: { span: 8 },
					wrapperCol: { span: 16 },
				},
				options: dossierEnum.retentionPeriod.toOptions(), // 案卷保管期限
			},
			{
				type: 'DatePicker', // 年度
				name: 'yearNumber',
				label: '年度',
				width: '150px',
				picker: 'year',
			},
			{
				type: 'ParticiplesSelect',
				name: 'topic', // 题名列表
				label: '题名',
			},
		],
		ajaxConfig: {
			ajaxType: 'post',
			url: this.props.compositeSearchUrl || dossierUrl.getInsertArchiveList,
			ContentType: 'application/json',
		},
	}
	changeSearchLimit = data => {
		for (let i in data) {
			if (data[i] === undefined) {
				delete data[i]
			} else if (this.props.withStatus !== true) {
				if (i === 'status') {
					delete data[i]
				} else if (i === 'type') {
					delete data[i]
				}
			}
		}
		return data
	}
	// 把拿到的表格数据格式改造成符合公共组件格式的数据
	// 这里的data为从后台接收的数据，必须将data转为以下格式返回（data.files为后台返回的示例），即将对应的表格数据作为data.data返回
	changeTableData = data => {
		data = {
			data: data.records,
			pageSize: data.size,
			current: data.current,
			total: data.total,
		}
		return data
	}

	// 检查表格选中数量是否大于一项，如果不满足条件则弹窗提示
	checkRowSelection = () => {
		return new Promise((resolve, reject) => {
			const {
				pub_rowSelection,
				location: { pathname },
			} = this.props
			const markName = pathname
			const rowSelection = pub_rowSelection[markName] || []
			// 还未选中则不能操作
			if (!rowSelection.length) {
				reject(message.error(tips.CHOOSE_AT_LEAST_ONE))
			}
			// 返回所有选中项
			resolve(rowSelection)
		})
	}

	// 提交
	handleSumbit = async () => {
		try {
			let applyRecords = []
			const rowSelection = this.props.checkRowSelection
				? await this.props.checkRowSelection()
				: await this.checkRowSelection()
			applyRecords = rowSelection.map(record => record)
			return applyRecords
		} catch (err) {}
	}

	componentWillMount() {
		this.returnSearchFormConfig()
	}
	// 处理搜素表单配置
	returnSearchFormConfig = () => {
		const { withStatus } = this.props
		if (withStatus) {
			if (this.props.otherFormItem) {
				this.searchFormConfig.formItems = this.searchFormConfig.formItems.concat(
					this.props.otherFormItem
				)
			}
			this.searchFormConfig.moreFormItems = this.props.moreFormItems
		}
	}

	render() {
		this.searchFormConfig.ajaxConfig.url =
			this.props.compositeSearchUrl || dossierUrl.getInsertArchiveList
		const {
			visible,
			onCancel,
			handleCompositeRecords,
			successMsg = '提交成功',
			modalBtnTitle = '提交',
			title = '综合查询',
			rowSelection, // 外部传进来的rowSelection应该怎么配置，配合dataSource，如果没有会用BaseTable自己写的
			noOperationButton,
			checkedChildren,
			unCheckedChildren,
			getInstance,
			markName, //这里因为调用了BaseTable组件，所以必须传markName
			isEPhotoAlbum,
			handleAlbumRecords,
		} = this.props
		return (
			<Modal
				title={title}
				width={1300}
				visible={visible}
				onCancel={onCancel}
				onOk={this.handleOk}
				style={{ top: 30 }}
				footer={[]}
				destroyOnClose={true} // modal关闭之后清除子元素
			>
				<div className='subject-management-subjectsetting'>
					{/*  用WrappedTable包装一下SearchForm和BaseTable */}
					<WrappedTable>
						<SearchForm
							name={'searchFormDemo'}
							checkedChildren={checkedChildren}
							unCheckedChildren={unCheckedChildren}
							formItems={this.searchFormConfig}
							switchCallback={this.props.switchCallback}
							getInstance={getInstance}
							setSearchState={this.props.setSearchState}
							layout={formLayout}
							searchButtonText='查询'
							changeSearchLimit={this.changeSearchLimit}
							changeTableData={this.changeTableData}
							markName={markName}
						/>
						<div style={{ marginTop: '10px' }}>
							<Button
								style={{ marginRight: '10px' }}
								key='sumbit'
								className='btn-success'
								onClick={async () => {
									let datarecords = await this.handleSumbit()
									if (
										datarecords?.length === undefined ||
										datarecords?.length === 0
									) {
										return
									} else {
										;(await handleCompositeRecords) &&
											handleCompositeRecords(datarecords) //返回所选文件/案卷组成的数组，对其进行处理
										if (isEPhotoAlbum) {
											const afterHandleLength =
												handleAlbumRecords(datarecords)
											if (
												afterHandleLength !== datarecords.length
											) {
												message.info(
													'当前仅支持添加图片类型文件，已将图片类型文件添加进主题'
												)
											} else {
												message.success(successMsg)
											}
										}
										onCancel()
									}

									this.props.getAddData?.(datarecords)
								}}>
								{modalBtnTitle}
							</Button>
							<Button key='back' onClick={onCancel}>
								取消
							</Button>
						</div>
						<BaseTable
							tableConfig={CompositeSearchColumns} // 表格表头设置
							changeTableData={this.changeTableData} // 把拿到的表格数据改造成符合公共组件所需数据的格式
							changeSearchLimit={this.changeSearchLimit} // 这里也传一下，后续开发公共组件可能会用到
							rowSelection={rowSelection ? rowSelection() : null}
							noOperationButton={noOperationButton || false}
							onRowEvent={true}
							onRow={rowSelection}
							markName={markName}
						/>
					</WrappedTable>
				</div>
			</Modal>
		)
	}
}

export default CompositeSearchModal
