import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { CookieUtil } from '@/Utils'
import { Divider } from 'antd'
// 档案鉴定申请处理方式
const formLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
}

// 档案鉴定申请表模态框
export default class VerifyApplyModal extends Component {
	state = {
		// 模态框表单的配置
		formItems: [
			[
				{
					// 这个表单项只是为了展示
					type: 'Input',
					name: 'applyUserId',
					label: '申请人',
					disabled: true,
					initialValue: (CookieUtil.user || {})['name'],
				},
			],
			[
				{
					type: 'TextArea',
					name: 'applyReason',
					label: '申请理由',
					placeholder: '请输入申请理由',
				},
			],
		],
	}
	/**
	 * 根据多选的档案（文件）列表渲染对应数量的表单项
	 * @param {Object[]} archives 档案 record 列表
	 */
	renderForm = archives => {
		// 一个申请人和申请理由为基础
		const formItems = [
			[
				{
					// 这个表单项只是为了展示
					type: 'Input',
					name: 'applyUserId',
					label: '申请人',
					disabled: true,
					initialValue: (CookieUtil.user || {})['name'],
				},
			],
			[
				{
					type: 'TextArea',
					name: 'applyReason',
					label: '申请理由',
					placeholder: '请输入申请理由',
				},
			],
		]
		archives.forEach((archive, index) => {
			const { id, topic } = archive
			// 每一项有一个分割线、处理方式下拉框和鉴定说明文本框
			formItems.push(
				...[
					[
						{
							type: 'Extra',
							name: `divider_${id}`,
							render: () => (
								<Divider
									className='modal-divider'
									orientation='left'>{`文件${index + 1}：${topic}`}</Divider>
							),
						},
					],
				]
			)
		})
		this.setState({ formItems })
	}

	render() {
		const { modalRef, onOk, hideCallback } = this.props
		const { formItems } = this.state
		return (
			<PubModalForm
				title='鉴定申请'
				ref={modalRef}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				okText='确认'
				onOk={onOk}
				width={700}
				formProps={{
					// 表单的配置项
					name: 'verify-apply-modal-form',
					formItems,
					layout: formLayout,
				}}
				hideCallback={hideCallback}
				zIndex={1002}
			/>
		)
	}
}
