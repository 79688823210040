import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	uploadThunk,
	faceRecognitionThunk,
	getFaceDetailListThunk,
} from './ActionCreators'
import { Upload, Button, message, Progress } from 'antd'
import FaceRecognitionCrop from './FaceRecognitionCrop'

const mapDispatchToProps = dispatch => ({
	upload: (...rest) => dispatch(uploadThunk(...rest)), // 上传文件
	faceRecognition: (...rest) => dispatch(faceRecognitionThunk(...rest)), // 识别人脸
	getFaceDetailList: (...rest) => dispatch(getFaceDetailListThunk(...rest)), // 查询人脸信息
})

@connect(null, mapDispatchToProps)
class PictureRecognition extends Component {
	constructor(props) {
		super(props)
		this.state = {
			file: null,
			imgFileList: [],
			imgUrl: null,
		}
	}

	/**
	 * 上传需识别的图片
	 * @param {*} file
	 * @returns
	 */
	handleBeforeUpload = file => {
		const { type } = file
		if (type.startsWith('image')) {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				this.setState({
					file,
					imgUrl: reader.result,
				})
			})
			reader.readAsDataURL(file)
			return true
		}
		message.info('请上传图片格式的文件')
		return false
	}
	/**
	 * 上传裁剪后的图片并识别
	 * @param {Array} imgFileList
	 */
	setFaceMsgIterator = async function* (imgFileList) {
		try {
			const { upload, faceRecognition, getFaceDetailList } = this.props
			for (const imgFile of imgFileList) {
				const fileId = (await upload({ multipartFile: imgFile.file })).data.fileId
				const ids = (await faceRecognition(fileId)).data
				if (ids.length !== 0) {
					const FaceDetailList = (await getFaceDetailList(ids)).data
					yield FaceDetailList
				} else {
					yield []
				}
			}
		} catch (err) {
			message.destroy()
			message.error('识别出错！')
		}
	}

	// 更新识别进度条
	upDistinguishPercent = faceDetailPercent => {
		const messageConfig = {
			icon: <i />,
			content: (
				<div style={{ width: 170 }}>
					识别人脸中...
					<Progress percent={faceDetailPercent} size='small' status='active' />
				</div>
			),
			duration: 0,
			key: 'getFaceDetailPercentMessage',
		}
		message.open(messageConfig)
	}

	// 获取人脸信息渲染到列表中
	setFaceMsg = async imgFileList => {
		const { setPictureFaces } = this.props
		try {
			let faceDataList = []
			let faceDetailPercent = 0
			const averageProgress = 100 / imgFileList.length
			this.upDistinguishPercent(faceDetailPercent)
			for await (const FaceDetailList of this.setFaceMsgIterator(imgFileList)) {
				faceDetailPercent += averageProgress
				this.upDistinguishPercent(Math.ceil(faceDetailPercent))
				// 查重
				for (let item of FaceDetailList) {
					let isExistedFace = false
					for (let existedFace of faceDataList) {
						if (item.id === existedFace.id) {
							isExistedFace = true
						}
					}
					if (!isExistedFace) faceDataList.push(item)
				}
			}
			setPictureFaces(faceDataList)
			setTimeout(() => {
				if (faceDataList.length !== 0) {
					message.destroy()
					message.success('图片识别成功')
				} else if (faceDataList.length === 0) {
					message.destroy()
					message.error('未能从图片中识别出人脸信息')
				} else {
					message.destroy()
					message.warning('部分图片未能识别出人脸信息')
				}
			}, 200)
		} catch (err) {
			message.destroy()
			message.error('识别出错！')
		}
	}

	// 关闭裁剪框的回调
	closeCropper = () => {
		this.setState({
			imgUrl: null,
		})
	}

	render() {
		const { imgUrl, file } = this.state
		return (
			<>
				{
					<Upload
						beforeUpload={this.handleBeforeUpload}
						customRequest={() => {}}
						fileList={[]}>
						<Button
							className='iconfont-buttons'
							style={{
								width: '86px',
								height: '33px',
								padding: '0',
								backgroundColor: '#5b80d5',
								color: '#fff',
							}}
							title='上传图片识别图片中的人脸'>
							&#xe85f; 图搜
						</Button>
					</Upload>
				}
				{
					<FaceRecognitionCrop
						file={file}
						imgUrl={imgUrl}
						setFaceMsg={this.setFaceMsg}
						closeCropper={this.closeCropper}
					/>
				}
			</>
		)
	}
}

export default PictureRecognition
