import React, { PureComponent } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { message } from 'antd'
import { treeUtil } from '@/Utils'
import { urlToArchiveManagement, departmentUrl, archiveTypeUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
import {
	addMediaJob,
	urlToArchiveManagementOptionsHZQ,
	testMediaUrl,
} from './ActionCreators'
import { debounce } from 'lodash'
const emailProtocol = [
	{
		value: 'pop3',
		label: 'pop3',
	},
	{
		value: 'imap',
		label: 'imap',
	},
	{
		value: 'smtp',
		label: 'smtp',
	},
] // 邮箱协议

// 表单项的单独配置，优先级高于表单项的统一配置
const formItemLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 24 },
}

export default class AddCrawlingModal extends PureComponent {
	state = {
		whichShow: {
			categoryCode: true,
			dateFromTime: true,
			departmentId: true,
			jobName: true,
			time: true,
			type: true,
			url: true,
			userName: true,
			workId: true,
			gzhName: false,
			file: false,
			emailAddress: false,
			emailProtocol: false,
			emailCode: false,
		},
	}

	setTreeData = (data, titleName, valueName, onlyChildren = false) => {
		let treeData = []
		const recursionToTree = data => {
			let tempObj = {
				title: data[titleName],
				value: data[valueName],
			}

			if (data.children != null) {
				if (onlyChildren) {
					tempObj.disabled = true
				}
				tempObj.children = [...data.children.map(item => recursionToTree(item))]
			}
			return tempObj
		}
		data?.forEach((value, index) => {
			treeData.push(recursionToTree(value))
		})
		return treeData
	}

	// 表单配置项
	formItems = [
		[
			{
				type: 'Radio',
				name: 'type',
				label: '类型',
				layout: formItemLayout,
				// options: urlToArchiveManagementOptions,
				options: urlToArchiveManagementOptionsHZQ,
				style: {
					width: '760px',
					display: 'flex',
					justifyContent: 'space-evenly',
				},
				onChange: valueObj => {
					const value = valueObj.target.value
					if (value === 3 || value === 4) {
						// 公众号,视频号显示上传图片和名称
						this.setState({
							whichShow: {
								...Object.assign(this.state.whichShow, {
									url: false,
									gzhName: true,
									file: true,
									emailAddress: false,
									emailProtocol: false,
									emailCode: false,
								}),
							},
						})
					} else if (value === 7) {
						// 邮箱
						this.setState({
							whichShow: {
								...Object.assign(this.state.whichShow, {
									url: false,
									gzhName: false,
									file: false,
									emailAddress: true,
									emailProtocol: true,
									emailCode: true,
								}),
							},
						})
					} else {
						// 其他状态显示网址
						this.setState({
							whichShow: {
								...Object.assign(this.state.whichShow, {
									url: true,
									gzhName: false,
									file: false,
									emailAddress: false,
									emailProtocol: false,
									emailCode: false,
								}),
							},
						})
					}
				},
				placeholder: '请选择爬取网址的类型',
				span: 9,
			},
		],
		[
			{
				type: 'Input',
				name: 'jobName',
				label: '频道名称',
				layout: formItemLayout,
				placeholder: '请输入频道名称',
				span: 9,
				style: {
					width: '760px',
				},
			},
		],
		[
			{
				type: 'Input',
				name: 'url',
				label: '目标网址',
				layout: formItemLayout,
				placeholder: '请输入目标网址',
				span: 9,
				style: {
					width: '760px',
				},
				onChange: valueObj => {
					const value = valueObj.target.value
					const { setFieldsValue } = this.props.modalRef.current.formRef
					// let topDomain = value.match(/([^./]+\.[^./]+)\//);  // 匹配顶级域名
					let domain = value.match(/^http(s)?:\/\/(.*?)\//)
					if (domain !== null) {
						if (domain[0].includes('douyin')) {
							setFieldsValue({ type: 1 })
						} else if (domain[0].includes('xuexi')) {
							setFieldsValue({ type: 5 })
						} else if (domain[0].includes('weibo')) {
							setFieldsValue({ type: 6 })
						} else {
							setFieldsValue({ type: 2 })
						}
					}
				},
			},
		],
		[
			{
				type: 'Input',
				name: 'gzhName',
				label: '目标名称',
				placeholder: '请输入对应的目标名称',
				span: 9,
			},
			{
				type: 'Upload',
				name: 'file',
				label: '上传二维码',
				maxCount: 1,
				span: 9,
				rules: [{ required: true }],
				onChange: (file, setFileList) => {
					setFileList(file.fileList)
					const { setFieldsValue } = this.props.modalRef.current.formRef
					if (file.fileList.length !== 0) {
						const reader = new FileReader()
						reader.readAsDataURL(file.fileList[0].originFileObj)
						reader.onloadend = function (e) {
							setFieldsValue({ file: e.target.result.split(',')[1] })
						}
					} else {
						setFieldsValue({ file: '' })
					}
				},
			},
		],
		[
			{
				type: 'Input',
				name: 'emailAddress',
				label: '目标邮箱账号',
				placeholder: '请输入目标邮箱的账号',
				layout: formItemLayout,
				span: 9,
				rules: [
					{
						required: true,
						type: 'string',
						pattern: new RegExp(/@/),
						message: `邮箱账号必须包含@字符`,
					},
				],
				style: {
					width: '760px',
				},
			},
		],
		[
			{
				type: 'Select',
				name: 'emailProtocol',
				label: '邮箱协议',
				placeholder: '请选择邮箱协议',
				span: 9,
				options: emailProtocol,
				rules: [{ required: false }],
			},
			{
				type: 'Input',
				name: 'emailCode',
				label: '邮箱授权码',
				placeholder: '请输入邮箱授权码',
				span: 8,
				rules: [{ required: true }],
				style: {
					width: '446px',
				},
			},
		],
		[
			{
				type: 'AsyncTreeSelect',
				name: 'categoryCode',
				label: '类别',
				placeholder: '请选择类别',
				layout: formItemLayout,
				ajaxConfig: {
					type: 'get',
					url: archiveTypeUrl.getDossierType,
					ContentType: 'application/x-www-form-urlencoded',
				},
				formatDataFunc: (data, changeKeys) => {
					return this.setTreeData(data.children, 'typeName', 'id', true)
				},
				span: 9,
				style: {
					width: '206px',
				},
				rules: [{ required: true }],
			},
			{
				type: 'TimePicker',
				name: 'time',
				label: '选择时间',
				layout: formItemLayout,
				format: 'HH:mm',
				placeholder: '请选择定期爬取时间',
				style: {
					width: '167px',
				},
				span: 7,
			},
			{
				type: 'DatePicker',
				name: 'dateFromTime',
				label: '始于日期',
				layout: formItemLayout,
				style: {
					width: '192px',
				},
				disabledDate: () => false,
				placeholder: '请选择始于日期',
				span: 8,
			},
		],
		[
			{
				type: 'AsyncInput',
				name: 'workId',
				label: '责任人工号',
				required: true,
				placeholder: '请输入责任人工号',
				layout: formItemLayout,
				ajaxConfig: {
					type: 'get',
					url: urlToArchiveManagement.searchSpecialUser,
				},
				onSearch: debounce(async (value, ajaxConfig, setOptions) => {
					const { departmentId = null } =
						this.props.modalRef.current.formRef.getFieldsValue([
							'departmentId',
						])
					const searchData = Object.assign(ajaxConfig, {
						data: { departmentId, workId: value },
					})
					const res = await _fetch(searchData)
					if (res.data.data?.length) {
						setOptions(
							res.data.data.map(item => ({
								value:
									item.workId +
									' - ' +
									item.name +
									' - ' +
									item.departmentName,
								departmentId: item.departmentId,
								userName: item.name,
							}))
						)
					} else {
						setOptions([])
					}
				}, 300),
				onSelect: (value, option, setValue) => {
					setValue(value.split('-')[0].trim())
					this.props.modalRef.current.formRef.setFieldsValue({
						userName: option.userName,
						departmentId: option.departmentId,
					})
				},
				triggerChange: value => {
					const prevValue = this.props.modalRef.current.formRef
						.getFieldValue('workId')
						.split('-')[0]
						.trim()
					const newValue = value.split('-')[0].trim()
					this.props.modalRef.current.formRef.setFieldsValue({ workId: value })
					if (
						!(value === '' || value.includes('-') || newValue === prevValue)
					) {
						this.props.modalRef.current.formRef.setFieldsValue({
							departmentId: null,
						})
					}
				},
				span: 9,
			},
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId',
				label: '部门',
				layout: formItemLayout,
				placeholder: '选择部门',
				rules: [{ required: false }],
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
				onChange: () => {
					this.props.modalRef.current.formRef.setFieldsValue({
						workId: '',
					})
				},
				span: 7,
				style: {
					width: '167px',
				},
			},
			{
				type: 'Input',
				name: 'userName',
				label: '责任人名',
				rules: [{ required: false }],
				placeholder: '输入责任人名',
				layout: formItemLayout,
				span: 8,
				style: {
					width: '192px',
				},
			},
		],
	]

	setButton = () => {
		//配置按钮项
		let buttonList = [
			{
				text: '保存',
				buttonProps: {
					type: 'primary',
				},
				onClick: async () => {
					// 点击新增事件
					message.info('测试中')
					const data = this.props.modalRef.current.formRef.getFieldValue()
					if (Array.isArray(data.file) && data.file.length === 0) {
						data.file = null
					} // 对传给后台的数据做些处理
					const sendData = { ...data } //新增的数据
					const testData = {} //测试的数据
					const { time } = sendData
					testData.type = data.type
					testData.startHourTime = Number(time.split(':')[0])
					testData.startMinTime = Number(time.split(':')[1])
					if (testData.type === 3 || testData.type === 4) {
						testData.file = data.file
						testData.gzhName = data.gzhName
					} else if (testData.type === 7) {
						// 后台要求邮箱爬取url为：账号-授权码-协议
						testData.url = `${data.emailAddress}-${data.emailCode}-${data.emailProtocol}`
						sendData.url = `${data.emailAddress}-${data.emailCode}-${data.emailProtocol}`
						Reflect.deleteProperty(sendData, 'emailAddress')
						Reflect.deleteProperty(sendData, 'emailCode')
						Reflect.deleteProperty(sendData, 'emailProtocol')
					} else {
						testData.url = data.url
					}
					const testRes = await testMediaUrl(testData)
					if (testRes.data.code === 200) {
						sendData.startHourTime = Number(time.split(':')[0])
						sendData.startMinTime = Number(time.split(':')[1])
						sendData.dateFromTime =
							data.dateFromTime.split('t')[0] + ' 00:00:00'
						Reflect.deleteProperty(sendData, 'time')
						const res = await addMediaJob(sendData)
						// 隐藏模态框，刷新表格
						//console.log('testing', res)
						if (res.data.code === 200) {
							message.success('新增成功!')
							this.props.modalRef.current.hide()
							this.props.pub_getAllData()
						} else {
							message.destroy()
							message.warn(res.data.message)
						}
					} else {
						message.destroy()
						message.error(testRes.data?.message ?? '测试不通过')
					}
					this.props.modalRef.current.setState({ loading: false })
				},
			},
		]
		return buttonList
	}

	destroyOnclose = () => {
		this.setState({
			whichShow: Object.assign(this.state.whichShow, {
				url: true,
				gzhName: false,
				file: false,
				emailAddress: false,
				emailProtocol: false,
				emailCode: false,
			}),
		})
		return false
	}

	hideCallback = () => {
		this.setState({
			whichShow: {
				...Object.assign(this.state.whichShow, {
					url: true,
					gzhName: false,
					file: false,
					emailAddress: false,
					emailProtocol: false,
					emailCode: false,
				}),
			},
		})
	}

	render() {
		return (
			<PubModalForm
				ref={this.props.modalRef} // 若隔层由外层通过props传递
				formDisabled={false}
				title={'新增频道'}
				forceRender={false}
				className={'urlToArchiveManagement-Modal'}
				hideCallback={this.hideCallback}
				destroyOnClose={true} // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				buttonList={this.setButton()} // Modal 底部按钮配置列表,
				mask={true}
				maskClosable={false}
				formProps={{
					// 表单的配置项
					name: 'addCrawlingModal',
					formItems: this.formItems,
					whichShow: { ...this.state.whichShow },
				}}
			/>
		)
	}
}
