/*
 * SearchForm的InputItem公共组件
 *【使用方法：可传入placeHolder默认值和类名
 * 第一个参数是placeHolder，不需要的话就传 ""
 * 第二个参数是类名，不需要可以不传】
 */
import React, { Component } from 'react'
import { Input } from 'antd'

/**
 * 普通输入框
 * @param {Object} config 高阶组件配置对象
 */
export const proInput = config => {
	class InnerClass extends Component {
		/**
		 *
		 * 将修改后的 value 传入 antd 的 FormItem 所赋予的 onChange 事件中，使数据得以被管理
		 * @param {any} currency 修改后的表单项的值
		 */
		triggerChange = currency => {
			const { onChange } = this.props
			if (onChange) {
				onChange(currency)
			}
		}
		render() {
			const { value } = this.props
			return (
				<Input
					allowClear
					value={value}
					placeholder={config.placeHolder}
					onChange={this.triggerChange}
				/>
			)
		}
	}

	return <InnerClass />
}
