/*
 * @Author: your name
 * @Date: 2020-07-27 21:18:08
 * @LastEditTime: 2020-07-28 08:43:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /archive-system/src/Redux/Reducer/Public/PubTable/index.js
 */

import initialState from '@/Redux/InitialState' // 引入表格公共组件的初始化状态
import {
	PUB_GET_ROWSELECTION,
	PUB_GET_ALLTABLEDATA,
	PUB_IS_LOADING,
	PUB_CURRENT_PAGE,
	PUB_SEARCH_CONDITION,
	PUB_CLEAR_SELECTED,
	PUB_SET_SELECTED_STATUS,
	IS_MYFILEDETAIL,
} from '@/Redux/ActionTypes/Public/PubTable/publicActionType' // 引入表格公共组件的ActionType
import _ from 'lodash'

let setrowSelection = (state, action) => {
	let rowSelection = _.cloneDeep(state)
	const { pub_rowSelection, markId } = action
	rowSelection.pub_rowSelection[markId] = pub_rowSelection
	return rowSelection // 选中的当行数据
}

let setallTableDate = (state, action) => {
	let allTableDate = _.cloneDeep(state)
	const { pub_allTableData, markId } = action
	allTableDate.pub_allTableData[markId] = pub_allTableData
	return allTableDate // 表格的所有数据
}

let setisLoading = (state, action) => {
	let isLoading = _.cloneDeep(state)
	const { pub_isLoading, markId } = action
	isLoading.pub_isLoading[markId] = pub_isLoading
	return isLoading // 是否获取到表格数据的标识
}

let setcurrentPage = (state, action) => {
	let currentPage = _.cloneDeep(state)
	const { pub_currntPage, pub_pageSize, markId } = action
	currentPage.fixedConf[markId] = {
		currentPage: pub_currntPage,
		size: pub_pageSize,
	}
	return currentPage
}

let setsearchCondition = (state, action) => {
	let searchCondition = _.cloneDeep(state)
	const { pub_searchCondition, markId } = action
	searchCondition.allSearchCondition[markId] = pub_searchCondition
	return searchCondition
}
// 清除某个页面的选中数据
let clearSelectedData = (state, action) => {
	let newState = _.cloneDeep(state)
	newState['pub_rowSelection'] && (newState['pub_rowSelection'][action.markId] = [])
	return newState
}

let setSelectedStatus = (state, action) => {
	let newState = _.cloneDeep(state)
	newState.selectedStatus = action.selectedStatus
	return newState
}

let isMyfileDetail = (state, action) => {
	let newState = _.cloneDeep(state)
	newState.isMyFileDetail = action.isMyFileDetail
	return newState
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState.pubTable, action) => {
	switch (
		action.type // 匹配对应的actionType,执行特定的处理状态的函数或代码
	) {
		case PUB_GET_ROWSELECTION:
			return setrowSelection(state, action) // 选中的当行数据
		case PUB_GET_ALLTABLEDATA:
			return setallTableDate(state, action) // 表格的所有数据
		case PUB_IS_LOADING:
			return setisLoading(state, action) // 是否获取到表格数据的标识
		case PUB_CURRENT_PAGE:
			return setcurrentPage(state, action) // 当前是第几页
		case PUB_SEARCH_CONDITION:
			return setsearchCondition(state, action) // 搜索条件
		case PUB_CLEAR_SELECTED:
			return clearSelectedData(state, action) // 清除数据
		case PUB_SET_SELECTED_STATUS:
			return setSelectedStatus(state, action) //
		case IS_MYFILEDETAIL:
			return isMyfileDetail(state, action)
		default: {
			return state
		}
	}
}
