import React, { PureComponent } from 'react'
import { Menu, Badge } from 'antd'
import { connect } from 'react-redux'
import { linkTo } from '@/Routes/ManageRouter'
import { Link } from 'react-router-dom'
import {
	SmileOutlined,
	SnippetsFilled,
	PieChartFilled,
	FileTextFilled,
} from '@ant-design/icons'
const { Item } = Menu

const mapStateToProps = state => {
	return {
		route: state.route.item,
		redDots: state.redDots,
	}
}

@connect(mapStateToProps, null)
class FirstRouteMenu extends PureComponent {
	// 一级路由，弹出对应首页
	clickFirstRouteToItsHome(firstRoute) {
		const { setStyle, pushRouters, SetRouter } = this.props
		let settingsRoute = {
			title: firstRoute.firstRouteLinkTitle || firstRoute.title,
			key: firstRoute.firstRouteLink,
		}
		if (
			firstRoute.firstRouteLink === '/archived/archived' ||
			firstRoute.firstRouteLink === '/statistics/statistics' ||
			firstRoute.firstRouteLink === '/editingResearch/ePhotoAlbum' ||
			firstRoute.firstRouteLink === '/collectedFiles/collectedFiles' ||
			firstRoute.firstRouteLink === '/mine/mine'
		) {
			setStyle('60px') // 隐藏二级路由
		} else {
			setStyle('230px') // 如果是从收藏点的，宽度把他设置回窄宽度
		}
		pushRouters(null, null, settingsRoute)
		SetRouter(settingsRoute.key)
	}

	/**
	 * 根据是否有该子路由，返回一级路由红点数
	 * @param {Array} newFirstSlide 一级路由数组
	 */
	returnRedDotNum = newFirstSlide => {
		const { redDots } = this.props
		let num = 0
		// 存放侧边栏不同id小格的红点数
		const idToNum = {}
		let workArea = newFirstSlide.find(item => item.id === 52)
		if (workArea) {
			workArea.child.forEach(item => {
				if (item.child && item.child.length > 0) {
					let count = 0
					item.child.forEach(thing => {
						if (redDots[thing.id]) {
							idToNum[thing.id] = redDots[thing.id]
							count += redDots[thing.id] || 0
						}
					})
					idToNum[item.id] = count
					num += count
				}
			})
			idToNum[workArea.id] = num
		}
		return idToNum
	}

	// 渲染一级路由
	renderFirstRouteMenu = newFirstSlide => {
		if (!newFirstSlide) return
		// 计算总共的红点，当其为零时，第一路由没有红点
		let idToNum = this.returnRedDotNum(newFirstSlide)
		// 一级路由的菜单
		const firstRouteMenu = newFirstSlide.map(item => {
			let menu = null

			if (item.img) {
				let i = null
				if (item.id === 52) {
					i = (
						<Badge offset={[-10, 0]} dot={idToNum[item.id]} size={'small'}>
							<i>{item.title}</i>
						</Badge>
					)
				} else {
					i = <i>{item.title}</i>
				}
				menu = (
					<div className='sider-menu-innerdiv'>
						<img src={item.img} alt={item.title} />
						{i}
					</div>
				)
			} else if (item.icon) {
				let i = null
				switch (item.icon) {
					case 'SnippetsFilled':
						i = <SnippetsFilled style={item.style} />
						break
					case 'PieChartFilled':
						i = <PieChartFilled style={item.style} />
						break
					case 'SmileOutlined':
						i = <SmileOutlined style={item.style} />
						break
					case 'FileTextFilled':
						i = <FileTextFilled style={item.style} />
						break
					default:
						break
				}
				menu = (
					<div className='sider-menu-innerdiv'>
						{i}
						<i>{item.title}</i>
					</div>
				)
			}
			return (
				<Item key={item.path}>
					<Link
						to={linkTo.topRoute + item.firstRouteLink}
						onClick={() => {
							this.clickFirstRouteToItsHome(item)
						}}>
						{menu}
					</Link>
				</Item>
			)
		})

		return firstRouteMenu
	}

	render() {
		const { route, newFirstSlide } = this.props
		return (
			<Menu className='first-sider-bar' selectedKeys={[route.split('/')[1]]}>
				{this.renderFirstRouteMenu(newFirstSlide)}
			</Menu>
		)
	}
}

export default FirstRouteMenu
