import React, { PureComponent, Fragment, forwardRef } from 'react'
import { Input, Tree, Spin } from 'antd'
import { connect } from 'react-redux'
import { getDossierTypeTreeThunk, updateDossierType } from './ActionCreators'
import { DownOutlined } from '@ant-design/icons'
import { treeUtil, rjUtils } from '@/Utils'
import WithRef from '@/Public/WithRef'
import { cloneDeep } from 'lodash'
const { Search } = Input
const { flatTree, listToTree } = treeUtil
const mapDispatchToProps = dispatch => ({
	getDossierTypeTree: (...rest) => dispatch(getDossierTypeTreeThunk(...rest)), // 获取树
})

// 部门树组件
@connect(null, mapDispatchToProps)
@WithRef
class ArchiveDepartmentTree extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			treeSpinning: false, // 树是否加载中
			selectedKeys: [], //选中节点的信息
			expandedKeys: [],
			autoExpandParent: true, // 是否自动展开父节点
			flatTreeData: [], // 树的扁平化数据
			searchValue: '', // 搜索内容
		}
	}

	componentWillMount() {
		const { getInstance } = this.props
		if (typeof getInstance === 'function') {
			// 在这里把this暴露给父组件（Utils高阶组件），得以让其通过使用 this.pubFormRef 获取 PubForm 的方法
			getInstance(this)
		}
	}

	componentDidMount() {
		this.getTree()
	}

	// 获取部门树数据并扁平化数据
	getTree = () => {
		const { getDossierTypeTree } = this.props
		const { expandedKeys } = this.state
		this.setState(
			{
				treeSpinning: true,
			},
			() => {
				getDossierTypeTree()
					.then(tree => {
						this.setState({
							flatTreeData: flatTree(tree, node => {
								rjUtils.clearNull(node)
							}),
							treeSpinning: false,
							// 如果有扩展记录则保持原来的扩展状态，否则默认只展示根节点
							expandedKeys: expandedKeys.length > 0 ? expandedKeys : [-1],
						})
					})
					.then(() => {
						this.createTreeData()
					})
			}
		)
	}

	// 树还原过程为节点进行修饰，添加“增/改/删”操作按钮等
	modifyNode = node => {
		const { searchValue } = this.state
		// // 对根节点做一点修改，先修改再获取以得到新的值
		// * 现在要不显示根节点，做个id为零的不显示
		if (node.id === -1) node.id = 0
		const { id, typeName } = node
		// 根据搜索内容显示部分红色字体
		const index = typeName.indexOf(searchValue)
		const beforeStr = typeName.substr(0, index)
		const afterStr = typeName.substr(index + searchValue.length)
		const title =
			index > -1 ? (
				<span key={`highlight-wrapper-span-${id}`}>
					{beforeStr}
					<span key={`highlight-${id}`} style={{ color: '#f50' }}>
						{searchValue}
					</span>
					{afterStr}
				</span>
			) : (
				typeName
			)
		// 自定义 title
		node.title = (
			<Fragment>
				<span key={`title-${id}`}>{title}</span>
			</Fragment>
		)
		return node
	}

	/**
	 * 扩展节点的函数
	 * @param {Array} expandedKeys 目前展开的所有节点
	 */
	onExpand = expandedKeys => this.setState({ expandedKeys, autoExpandParent: false })

	// 搜索内容改变触发事件
	onSearchChange = e => {
		const { value } = e.target
		const { flatTreeData } = this.state
		// 如果值为空则默认展开根节点，否则展开包括搜索内容的节点
		const expandedKeys = !value
			? [-1]
			: flatTreeData
					.filter(node => node.typeName.includes(value))
					.map(({ key }) => key)
		this.setState({
			expandedKeys,
			searchValue: value,
			autoExpandParent: true,
		})
	}

	// 树节点选择触发
	onSelect = (selectedKeys, event) => {
		const { updateSelectNode } = this.props
		this.setState({ selectedKeys })
		// 选中节点的信息
		let newSelectedNodeMsg = cloneDeep(event.selectedNodes[0] || {})
		// 这两个属性无法 JSON.stringify
		delete newSelectedNodeMsg['children']
		delete newSelectedNodeMsg['title']
		// 如果是选择同一个，则不发送请求
		updateSelectNode(newSelectedNodeMsg)
	}

	// 将扁平树还原为树结构
	createTreeData = () => {
		const { flatTreeData } = this.state
		if (!flatTreeData.length) return [] // 不能传入 [] 给 listToTre，会报错
		return listToTree(flatTreeData, this.modifyNode)
	}

	// 侧边栏可拖拽
	onDrop = async info => {
		const { pub_getAllData } = this.props
		const dropKey = info.node.key
		const dragKey = info.dragNode.key
		const dropPos = info.node.pos.split('-')
		const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
		const loop = (data, key, callback) => {
			for (let i = 0; i < data.length; i++) {
				if (data[i].key === key) {
					return callback(data[i], i, data)
				}

				if (data[i].children) {
					loop(data[i].children, key, callback)
				}
			}
		}

		const data = [...this.createTreeData()] // Find dragObject

		let dragObj
		const applyData = {}
		applyData.targetId = dragKey
		loop(data, dragKey, (item, index, arr) => {
			arr.splice(index, 1)
			dragObj = item
		})

		if (!info.dropToGap) {
			// Drop on the content
			loop(data, dropKey, item => {
				item.children = item.children || [] // where to insert 示例添加到头部，可以是随意位置

				item.children.unshift(dragObj)
				applyData.parentId = item.id
				applyData.sequence = item.children.map(item => item.id)
			})
		} else if (
			(info.node.props.children || []).length > 0 && // Has children
			info.node.props.expanded && // Is expanded
			dropPosition === 1 // On the bottom gap
		) {
			loop(data, dropKey, item => {
				item.children = item.children || [] // where to insert 示例添加到头部，可以是随意位置

				item.children.unshift(dragObj) // in previous version, we use item.children.push(dragObj) to insert the
				// item to the tail of the children
			})
		} else {
			let ar = []
			let i
			loop(data, dropKey, (_item, index, arr) => {
				ar = arr
				i = index
			})

			if (dropPosition === -1) {
				ar.splice(i, 0, dragObj)
			} else {
				ar.splice(i + 1, 0, dragObj)
			}
			applyData.parentId = info.node.parentId
			applyData.sequence = ar.map(item => item.id)
		}
		await updateDossierType(applyData).then(res => {
			if (res.data.code !== 200) {
				this.getTree()
			} else {
				pub_getAllData()
			}
		})
		// 先把data转成扁平树，再把扁平树转成树，才能调用到modifyNode使搜索文本高亮
		let flatTreeData = flatTree(data, node => {
			rjUtils.clearNull(node)
		})
		this.setState({
			flatTreeData,
		})
		this.createTreeData()
	}

	render() {
		const { treeSpinning, expandedKeys, selectedKeys, autoExpandParent } = this.state
		return (
			<Fragment>
				<Search
					className='search-bar'
					placeholder='搜索类型'
					onChange={this.onSearchChange}
				/>
				<Spin spinning={treeSpinning}>
					<Tree
						onExpand={this.onExpand} // 节点扩展调用函数
						onSelect={this.onSelect} // 节点选择调用的函数
						expandedKeys={expandedKeys} // 扩展的节点
						selectedKeys={selectedKeys} // 选择的节点
						autoExpandParent={autoExpandParent} // 是否自动展开父节点
						showLine={false} // 不要对齐线
						switcherIcon={<DownOutlined />} // 节点头的图标
						blockNode // 每个node占据整行
						treeData={this.createTreeData()}
						draggable={true}
						onDrop={this.onDrop}
						ref={this.props.myForwardedRef}
					/>
				</Spin>
			</Fragment>
		)
	}
}

export default forwardRef((props, ref) => {
	return <ArchiveDepartmentTree {...props} myForwardedRef={ref} />
})
