import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { connect } from 'react-redux'
import { Badge, message } from 'antd'
import { addUserRoleThunk, deleteUserRoleThunk } from '../ActionCreators'
const columns = [{ title: '角色描述', dataIndex: 'description' }]
let formItems = [
	[
		{
			type: 'Table',
			name: 'AddRoleTable',
			columns,
			isNeedInternalOperation: false,
		},
	],
]

/**
 * 找出 不在a数组中的 b数组元素
 * 例：a=[1,2,3] b=[1,3,4,5] 返回 [4,5]
 * @param {Array} a
 * @param {Array} b
 * @returns
 */
function findNotIn(a, b) {
	return b.filter(item => {
		return !a.includes(item)
	})
}

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	addUserRole: (...rest) => dispatch(addUserRoleThunk(...rest)), // 给用户添加角色
	deleteUserRole: (...rest) => dispatch(deleteUserRoleThunk(...rest)), // 删除用户的角色
})
@connect(mapStateToProps, mapDispatchToProps)
class AddRolesModal extends Component {
	state = {
		visible: false,
	}

	buttonList = [
		{
			text: '保存',
			onClick: (formData, { finish, hide }) => {
				const { addUserRole, deleteUserRole, reflashTable } = this.props
				let userId = JSON.parse(sessionStorage.getItem('userId')),
					// 修改前 已有的角色id
					beforeIds = JSON.parse(sessionStorage.getItem('beforeIds')),
					// 修改后的角色id
					currentIds = formData.AddRoleTable.value.map(item => {
						return item.id
					})

				// 取出需要增或删的id
				let addRoleIds = findNotIn(beforeIds, currentIds),
					deleteRoleIds = findNotIn(currentIds, beforeIds)

				let promises = []
				addRoleIds.length &&
					promises.push(addUserRole({ userId, roleIdList: addRoleIds }))
				deleteRoleIds.length &&
					promises.push(deleteUserRole({ userId, roleIdList: deleteRoleIds }))

				Promise.all(promises)
					.then(res => {
						// 刷新表格
						reflashTable()
						// 取消loading
						finish()
						// 关闭model
						hide()
						message.success('成功修改权限')
					})
					.catch(() => {
						// 取消loading
						finish()
						message.error('修改权限失败')
					})
			},
			buttonProps: {
				className: 'btn-info',
			},
		},
	]

	render() {
		return (
			<PubModalForm
				title={
					<div>
						<span style={{ paddingRight: '40px' }}>给用户添加角色</span>
						<Badge dot='false' color='#38e09a'></Badge>
						<span style={{ fontSize: '12px' }}>已有角色</span>
					</div>
				}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				buttonList={this.buttonList}
				width={600}
				ref={this.props.modalRef} // 若隔层由外层通过props传递
				formProps={{
					// 表单的配置项
					formItems,
					name: 'AddRolesModal',
				}}
			/>
		)
	}
}
export default AddRolesModal
