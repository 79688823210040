import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import Enum, { dossierEnum } from '@/Utils/Enum'
import { archivedColumns } from '@/Public/PubTable/pageColumns'
import { dossierUrl, departmentUrl } from '@/Utils/Urls'
import { treeUtil } from '@/Utils'
import DossierTypeTree from './DossierTypeTree'
import ButtonList from '@/Public/ButtonList'
import './index.less'
import { log } from '@tensorflow/tfjs'
const markName = 'Archived' // redux的标识
const searchType = new Enum(['案卷', '文件', '全部']) // 档案搜索类型

@WrappedTable.create(markName)
class Archived extends Component {
	state = {
		selectedNodeMsg: {}, // 选中的树节点的信息
		isOpen: false,
	}
	handleEmail = () => {
		this.setState({ isOpen: !this.state.isOpen })
	}

	// 搜索表单项的配置
	searchFormConfigOpen = {
		formItems: [
			{
				type: 'Select',
				name: 'type',
				label: '类型',
				options: searchType.toOptions(),
				initialValue: searchType['全部'],
			},
			{
				type: 'DatePicker',
				name: 'yearNumber',
				label: '年度',
				picker: 'year',
				width: '150px',
			},
			{
				type: 'ParticiplesSelect',
				name: 'topic',
				label: '题名',
				inputWidth: '135px',
			},
			{
				type: 'ParticiplesSelect',
				name: 'nameList',
				label: '人物',
				inputWidth: '135px',
			},
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId',
				label: '部门',
				minWidth: '160px',
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'Select',
				name: 'retentionPeriod',
				label: '保管期限',
				minWidth: '155px',
				options: dossierEnum.retentionPeriod.toOptions(), // 档案保管期限
			},
			{
				type: 'ParticiplesSelect',
				name: 'keyWord',
				label: '关键字',
				inputWidth: '135px',
			},
			{
				type: 'ParticiplesSelect',
				name: 'metadata',
				label: '元数据',
				inputWidth: '135px',
			},
			{
				type: 'Extra',
				name: 'buttonList',
				render: saerchFormRef => {
					return (
						<ButtonList
							Open={this.state.isOpen}
							handleEmail={this.handleEmail}
							refresh={this.props.pub_getAllData}
						/>
					)
				},
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'post',
			url: dossierUrl.searchDossierList,
			ContentType: 'application/json',
		},
		// moreFormItems为切换到更多时，给formItems多添加的表单
		// moreFormItems: [
		//   {
		//     type: 'ParticiplesSelect',
		//     name: 'metadata',
		//     label: '元数据',
		//     inputWidth: "135px",
		//   },
		// ]
	}
	searchFormConfigClose = {
		formItems: [
			{
				type: 'Select',
				name: 'type',
				label: '类型',
				options: searchType.toOptions(),
				initialValue: searchType['全部'],
			},
			{
				type: 'DatePicker',
				name: 'yearNumber',
				label: '年度',
				picker: 'year',
				width: '150px',
			},
			{
				type: 'ParticiplesSelect',
				name: 'topic',
				label: '题名',
				inputWidth: '135px',
			},
			{
				type: 'ParticiplesSelect',
				name: 'nameList',
				label: '人物',
				inputWidth: '135px',
			},
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId',
				label: '部门',
				minWidth: '160px',
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'ParticiplesSelect',
				name: 'metadata',
				label: '元数据',
				inputWidth: '135px',
			},
			{
				type: 'Extra',
				name: 'buttonList',
				render: saerchFormRef => {
					return (
						<ButtonList
							Open={this.state.isOpen}
							handleEmail={this.handleEmail}
							refresh={this.props.pub_getAllData}
						/>
					)
				},
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'post',
			url: dossierUrl.searchDossierList,
			ContentType: 'application/json',
		},
	}

	// 选中节点触发并重新获取表格信息
	updateSelectNode = selectedNodeMsg => {
		const { pub_getAllData } = this.props
		this.setState({ selectedNodeMsg }, pub_getAllData)
	}

	// 重新获取树数据和表格数据
	reloadAllData = () => {
		const { pub_getAllData } = this.props
		pub_getAllData() // 重新获取表格数据
	}

	render() {
		const { selectedNodeMsg } = this.state
		return (
			<Fragment>
				<div className='archived-management-wrapper'>
					{/* 左边部门树组件 */}
					<div id='depart-left'>
						<DossierTypeTree
							updateSelectNode={this.updateSelectNode} // 更新选中信息的方法
							selectedNodeMsg={selectedNodeMsg} // 当前树选中节点的信息
							getInstance={ref => {}} // 让页面获取树组件实例的方法，由 WithRef 高阶去调用
						/>
					</div>
					{/* 右边表格部分 */}
					<div id='depart-right'>
						<WrappedTable>
							<SearchForm
								name='archived-search-form'
								formItems={
									this.state.isOpen
										? this.searchFormConfigOpen
										: this.searchFormConfigClose
								}
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData}
								markName={markName}
								// withPrint={true}
								withRecognition={true}
								withExport={true}
							/>
							<BaseTable
								tableConfig={archivedColumns}
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData}
								markName={markName}
								stylePointer={true}
								onRowEvent={true}
								dragSortable={true}
							/>
						</WrappedTable>
					</div>
				</div>
			</Fragment>
		)
	}

	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 */
	changeSearchLimit = data => {
		data['status'] = dossierEnum.status['已归档'] //  档案的状态
		data['yearNumber'] && (data['yearNumber'] = +data['yearNumber'])
		const categoryCodeId = this.state.selectedNodeMsg.id
		if (categoryCodeId !== -1) {
			data['categoryCodeId'] = this.state.selectedNodeMsg.id
		}
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = res => {
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}
}

export default Archived
