import React, { PureComponent } from 'react'
import { Radio, Button, Modal, message, Table } from 'antd'
import { updateConfig, getConfig } from '@/Utils/ModeTranslate'

export default class ModeConfigure extends PureComponent {
	state = {
		tableData: [],
		disabled: true,
	}

	componentDidMount() {
		this.getModeConfig()
	}

	radioChange = (e, index) => {
		const { tableData } = this.state
		tableData[index].mode = e.target.value
		this.setState({
			tableData,
		})
	}

	//点击按钮提交or编辑回调
	clickBtnCallback = isEdit => {
		this.setState({ disabled: !isEdit })
		if (!isEdit) {
			this.onFinish()
		}
	}

	//获取提交模式配置
	getModeConfig = () => {
		getConfig().then(Config => {
			this.setState({
				tableData: Config.commitModeList,
			})
		})
	}

	onFinish = () => {
		const { tableData } = this.state
		getConfig().then(Config => {
			Config.commitModeList = tableData
			Modal.confirm({
				icon: <></>,
				title: <h3>提示</h3>,
				content: '是否确认修改系统配置？',
				okText: '确认',
				cancelText: '取消',
				onOk: () => {
					updateConfig(Config)
					message.success('修改成功')
				},
			})
		})
	}

	render() {
		const { tableData, disabled } = this.state
		return (
			<>
				<Button
					onClick={() => {
						this.clickBtnCallback(disabled)
					}}
					style={{ display: 'block' }}
					type={'primary'}>
					{disabled ? '编辑' : '提交'}
				</Button>
				<Table
					pagination={{
						position: ['none', 'none'],
					}}
					columns={[
						{
							title: '档案类型',
							dataIndex: 'name',
							key: 'name',
						},
						{
							title: '提交模式',
							dataIndex: 'mode',
							key: 'mode',
							render: (text, record, index) => {
								return (
									<Radio.Group
										defaultValue={text}
										onChange={e => {
											this.radioChange(e, index)
										}}
										disabled={this.state.disabled}>
										<Radio value={0}>全部</Radio>
										<Radio value={1}>按件</Radio>
										<Radio value={2}>按卷</Radio>
									</Radio.Group>
								)
							},
						},
					]}
					dataSource={tableData}
				/>
			</>
		)
	}
}
