import { Space, Button } from 'antd'
import React, { Component } from 'react'
import { isEqual } from 'lodash'
import { PubModalForm } from '@/Public/PubForm'
// 表单项的单独配置，优先级高于表单项的统一配置
const formItemLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}
export default class DetailModal extends Component {
	state = {
		title: '查看全宗',
		formItems: null,
		needWithdraw: false,
	}

	componentDidUpdate(preProps) {
		const preRecord = preProps.record // 上次的 record 值
		const nowRecord = this.props.record // 新传入的 record 值
		/**
		 *  preRecord、nowRecord 不一样时，检测 record 改变
		 *  ┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
		 *  ┃ preRecord | nowRecord  |            ┃
		 *  ┃ null      | notNull    | 刚打开模态框 ┃
		 *  ┃ notNull   | notNull    | 打开后改变时 ┃
		 *  ┃ notNull   | null       | 刚关闭模态框 ┃
		 *  ┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
		 */
		if (nowRecord && !isEqual(preRecord, nowRecord)) {
			this.setContent(nowRecord)
		}
	}

	setContent = record => {
		// 设置内容
		// 查看全宗号 表单项配置数组
		const formItems = [
			[
				{
					type: 'Input',
					name: 'id',
					label: 'id:',
					span: 0,
				},
				{
					type: 'Input',
					name: 'fondsIdentifier',
					label: '全宗号:',
					layout: formItemLayout,
					placeholder: '请输入全宗号',
					style: {
						display: 'inline',
					},
				},
			],
			[
				{
					type: 'Input',
					name: 'fondsName',
					label: '全宗名称:',
					layout: formItemLayout,
					placeholder: '请输入全宗名称',
					style: {
						display: 'inline',
					},
				},
			],
			[
				{
					type: 'TextArea',
					name: 'remarks',
					label: '全宗描述:',
					layout: formItemLayout,
					placeholder: '请输入全宗描述',
					rules: [{ required: false }],
					rows: 5,
				},
			],
			[
				{
					type: 'DynamicExtra',
					name: 'buttonList',
					initialState: {
						ifUpadate: false,
					},
					render: (formRef, state, changeDynamicStateFun) => {
						const { modalRef } = this.props
						const { ifUpadate } = state
						const {
							handleDeleteApply, // 删除申请
							handleUpdateUseApply, // 更新申请
						} = this.props
						return (
							<Space style={{ marginBottom: 20, marginLeft: 20 }}>
								{
									// 编辑功能
									!ifUpadate ? (
										<Button
											type='primary'
											className='iconfont-buttons'
											title='编辑'
											onClick={() => {
												changeDynamicStateFun({
													ifUpadate: true,
												})
												modalRef.current.formRef.setDisabled(
													false
												)
											}}>
											&#xeabd; 编辑
										</Button>
									) : (
										<Button
											type='primary'
											onClick={() => handleUpdateUseApply()}>
											保存
										</Button>
									)
								}
								{/* 删除按钮 */}
								<Button
									className='btn-danger iconfont-buttons'
									title='删除'
									onClick={() => handleDeleteApply()}>
									&#xe816; 删除
								</Button>
							</Space>
						)
					},
				},
			],
		]
		this.setState({ formItems })
	}

	render() {
		const { formItems } = this.state
		const { modalRef } = this.props
		return (
			<PubModalForm
				ref={modalRef}
				title='查看宗卷'
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				width={500} // 默认值900
				formProps={{
					// 表单的配置项
					name: 'PubFormDemo',
					formItems,
				}}
				formDisabled // 查看状态下不给予编辑
			/>
		)
	}
}
