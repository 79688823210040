import React, { Component, Fragment } from 'react'
import { PubForm } from '@/Public/PubForm'
import { borrowEnum } from '@/Utils/Enum'
import BaseTable from '@/Public/PubTable/baseTable'
import { BorrowResultColumns } from '@/Public/PubTable/pageColumns'
import './index.less'
import { Button, Space, message } from 'antd'
import { connect } from 'react-redux'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import {
	borrowApplyThunk,
	borrowApplyExtend,
	deleteUseApplyThunk,
	recallUseApplyThunk,
	updateApplyThunk,
	submitApplyThunk,
	getMyUseResultThunk,
	downloadFilesThunk,
	useApplyDelayThunk,
} from './ActionCreator'
import {
	routeChangeAction,
	pushRoutersAction,
	getDetailThunk,
	addPubFileAction,
	addPubDossierAction,
} from '@/Public/PubTable/baseTable/ActionCreator.js' // 为了可以查看文件
import {
	pub_getAllTableData,
	is_my_file_detail,
} from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator.js'
const markName = 'BorrowResult' // redux的标识
const mapStateToProps = state => {
	return {
		borrowResult: state.pubTable.pub_allTableData.BorrowResult, // 获取表格数据
		applyFiles: state.myFiles.selectedFiles, //拿到申请的档案
		isMyFiles: state.pubTable.isMyFileDetail, //判断是不是从我的档案申请的
	}
}
const mapDispatchToProps = dispatch => {
	return {
		isMyFileDetail: (...rest) => dispatch(is_my_file_detail(...rest)), //修改isMyFile标志
		borrowApply: (...rest) => dispatch(borrowApplyThunk(...rest)), // 暂存
		deleteUseApply: (...rest) => dispatch(deleteUseApplyThunk(...rest)), // 删除
		recallUseApply: (...rest) => dispatch(recallUseApplyThunk(...rest)), //撤回
		updateApply: (...rest) => dispatch(updateApplyThunk(...rest)), //编辑
		submitApply: (...rest) => dispatch(submitApplyThunk(...rest)), //提交
		downloadFiles: (...rest) => dispatch(downloadFilesThunk(...rest)), //下载文件
		getMyUseResult: (...rest) => dispatch(getMyUseResultThunk(...rest)), //查看结果
		useApplyDelay: (...rest) => dispatch(useApplyDelayThunk(...rest)), //
		getAllTableData(...rest) {
			return dispatch(pub_getAllTableData(...rest)) // 把获取到的表格所有数据发送到store
		},
		// 以下为查看文件操作
		getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey.key))
			} else {
				dispatch(routeChangeAction(activeKey.key))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
		},
		pushRouters(link) {
			return dispatch(pushRoutersAction(link))
		},
		addPubFileData(data) {
			return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		addPubDossierData(data) {
			return dispatch(addPubDossierAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
	}
}

@connect(mapStateToProps, mapDispatchToProps)
class BorrowDetails extends Component {
	state = {
		editStatus: false, // 编辑状态 false不可编辑,true可编辑
		loadingStatus: false, // 加载状态 false未加载,true正在加载
		haveFile: 0, // 判断有没有档案
	}

	//设置表单值
	componentDidMount() {
		const formType = JSON.parse(sessionStorage.getItem('formType'))
		if (formType === 1) {
			const borrowId = JSON.parse(sessionStorage.getItem('borrowId'))
			this.props.getMyUseResult(borrowId).then(res => {
				this.setState({
					haveFile: res.length,
				})
				this.setTableData(res)
			})
		} else {
			if (this.props.isMyFiles && this.props.applyFiles.length !== 0) {
				this.setTableData(this.props.applyFiles)
			}
		}
	}

	setPubForm = () => {
		const formType = JSON.parse(sessionStorage.getItem('formType'))
		// PubForm 表单项配置数组
		//点击查看按钮出现的申请表
		let formItems_detail = [
			// 基本表单内容
			[
				{
					type: 'Extra',
					name: 'Title',
					render: formRef => {
						return <h1>借阅申请表</h1>
					},
					span: 23,
				},
			],
			[
				{
					type: 'Input',
					name: 'applyCode',
					label: '申请编号',
					disabled: true,
					rules: [{ required: false }],
					span: 8,
				},
				{
					type: 'Input',
					name: 'statusName',
					label: '状态',
					span: 8,
					disabled: true,
					rules: [{ required: false }],
				},
				{
					type: 'Input',
					name: 'departmentName',
					label: '部门',
					span: 8,
					disabled: true,
					rules: [{ required: false }],
				},
			],
			[
				{
					type: 'TextArea',
					name: 'applyReason',
					label: '申请原因',
					colclassname: 'end-table',
					disabled: !this.state.editStatus && formType === 1,
					maxLength: 1500,
					showCount: true,
				},
			],
			[
				{
					type: 'TextArea',
					name: 'applyContent',
					label: '申请内容',
					disabled: !this.state.editStatus && formType === 1,
					maxLength: 1500,
					showCount: true,
				},
			],
			[
				{
					type: 'Input',
					name: 'applyName',
					label: '申请人',
					span: 20,
					disabled: true,
					rules: [{ required: false }],
				},
			],
			[
				{
					type: 'Input',
					name: 'applyTime',
					label: '日期',
					span: 22,
					disabled: true,
					rules: [{ required: false }],
				},
			],
		]
		//新建一张申请表（点击申请按钮出现的表单）
		let formItems_base = [
			[
				{
					type: 'Extra',
					name: 'Title',
					render: formRef => {
						return <h1>借阅申请表</h1>
					},
					span: 23,
				},
			],
			[
				{
					type: 'Input',
					name: 'departmentName',
					label: '部门',
					span: 6,
					disabled: true,
					rules: [{ required: false }],
				},
				{
					type: 'Input',
					name: 'name',
					label: '申请人',
					span: 6,
					disabled: true,
					rules: [{ required: false }],
				},
			],
			[
				{
					type: 'TextArea',
					name: 'applyReason',
					label: '申请原因',
					colclassname: 'end-table',
					disabled: !this.state.editStatus && formType === 1,
					maxLength: 1500,
					showCount: true,
				},
			],
			[
				{
					type: 'TextArea',
					name: 'applyContent',
					label: '申请内容',
					disabled: !this.state.editStatus && formType === 1,
					maxLength: 1500,
					showCount: true,
				},
			],
			[
				{
					type: 'Extra',
					name: 'ButtonList', // 按钮组，用于进行按钮点击事件
					render: formRef => {
						return <Space>{this.setButtonList(formRef)}</Space>
					},
					span: 23, // span：23代表无边框表单组件
				},
			],
		]
		return formType === 1 ? formItems_detail : formItems_base
	}

	setButtonList = formRef => {
		const {
			deleteUseApply, // 删除
			recallUseApply, // 撤回
			updateApply, // 编辑
			submitApply, // 提交
			applyFiles,
		} = this.props

		const formType = JSON.parse(sessionStorage.getItem('formType'))

		if (formType === 0) {
			return (
				<Space className='submitBtn'>
					<Button
						onClick={() => {
							history.goBack()
						}}
						style={{
							marginRight: '13px',
						}}>
						取消
					</Button>
					<Button
						onClick={async () => {
							await formRef.validateFields()
							let data = await formRef.getFieldValue()
							data['status'] = 0
							//附带档案传给后台
							if (applyFiles.length) {
								data.archiveId = applyFiles.map(file => (file = file.id))
							}
							;(await this.props.isMyFiles)
								? borrowApplyExtend(data)
								: this.props.borrowApply(data)
							message.success('新增成功')
							// history.goBack()
							// formRef.resetFields()
						}}
						className='btn-warning'
						style={{
							marginRight: '13px',
						}}>
						暂存
					</Button>
					<Button
						type='primary'
						onClick={async () => {
							await formRef.validateFields()
							let data = formRef.getFieldValue()
							data['status'] = 1
							//附带档案传给后台
							if (applyFiles.length) {
								data.archiveId = applyFiles.map(file => (file = file.id))
							}
							;(await this.props.isMyFiles)
								? borrowApplyExtend(data)
								: this.props.borrowApply(data)
							message.success('提交成功')
							history.goBack()
							formRef.resetFields()
						}}>
						提交
					</Button>
				</Space>
			)
		} else {
			const data = JSON.parse(sessionStorage.getItem('borrowDetails'))
			const borrowId = JSON.parse(sessionStorage.getItem('borrowId'))
			const buttonType = data.status // 按钮类型
			if (this.state.editStatus) {
				return (
					<>
						<Button
							onClick={() => {
								this.setState({ editStatus: false })
							}}>
							取消
						</Button>
						<Button
							type='primary'
							onClick={async () => {
								await this.myFormRef.validateFields()
								let updateData = await this.myFormRef.getFieldsValue(true)
								let newData = {
									id: borrowId,
									applyReason: updateData.applyReason,
									applyContent: updateData.applyContent,
									departmentId: updateData.departmentId,
								}
								await updateApply(newData)
								history.goBack()
							}}>
							保存
						</Button>
					</>
				)
			} else {
				switch (buttonType) {
					case 0: {
						// 申请
						return (
							<>
								<Button
									className='btn-danger iconfont-buttons'
									title='删除'
									onClick={() => {
										deleteUseApply([borrowId]).then(res => {
											history.goBack()
										}) // 删除成功返回上一级
									}}>
									&#xe816; 删除
								</Button>
								<Button
									className='btn-warning iconfont-buttons'
									title='编辑'
									onClick={() => {
										this.setState({ editStatus: true }, () => {}) // 调成可编辑状态
									}}>
									&#xeabd;
								</Button>
								<Button
									type='primary'
									title='提交'
									className='iconfont-buttons'
									onClick={() => {
										submitApply(borrowId).then(res => {
											history.goBack()
										}) // 提交成功到审批状态
									}}>
									&#xe64c; 提交
								</Button>
							</>
						)
					}
					case 1: {
						// 审批
						// 如果已经被审批过，就不可以出现撤回按钮
						if (data.useReviewListBoList.length === 0) {
							return (
								<Button
									className='btn-warning'
									onClick={() => {
										recallUseApply([borrowId]).then(res => {
											history.goBack()
										})
									}}>
									撤回
								</Button>
							)
						}
						break
					}
					case 4: {
						// 已过期才可以申请延期
						if (JSON.parse(sessionStorage.getItem('isExpired')) === 1) {
							return (
								<Button
									className='btn-warning'
									disabled={data.delay === 0 ? false : true}
									onClick={() => {
										this.props.useApplyDelay(borrowId).then(() => {
											history.goBack()
										})
									}}>
									{data.delay === 0
										? '申请延期'
										: data.delay === -1
											? '延期已被拒'
											: '已申请'}
								</Button>
							)
						}
						break
					}
					case 5: {
						return (
							<Button className='btn-warning' disabled={true}>
								{'已申请延期'}
							</Button>
						)
					}
					default: {
					}
				}
			}
		}
	}

	returnResultButton = (record, loadingStatus) => {
		if (record.expired === 1) return
		if (record.downloaded === 1) {
			return (
				<div>
					<Button
						loading={loadingStatus}
						onClick={() => {
							this.handledownloadFiles(record.useContentId, record.type)
						}}>
						下载
					</Button>
				</div>
			)
		}
	}
	// 设置申请表详情
	setFormData = formRef => {
		const formType = JSON.parse(sessionStorage.getItem('formType'))
		if (formType === 1) {
			const data = JSON.parse(sessionStorage.getItem('borrowDetails'))
			formRef.setFieldsValue(data)
		} else {
			const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
			formRef.setFieldsValue(userInfo)
		}
	}
	// 设置历代审批详情
	setPreviousFormData = formRef => {
		const detailList = JSON.parse(
			sessionStorage.getItem('borrowDetails')
		).useReviewListBoList
		let addDetail = {} // 添加的第几次修改申请数据
		if (detailList !== '{}') {
			detailList.forEach((detail, n) => {
				const { id } = detail // 审核表id
				detail['createTime'] = detail['createTime'].includes('T')
					? detail['createTime'].replace(/T(.*)/, '')
					: detail['createTime'].replace(/\s(.*)/, '')
				addDetail[`id_${id}`] = detail['id'] //id
				addDetail[`name_${id}`] = detail['name'] //处理人
				addDetail[`department_${id}`] = detail['department'] //部门
				addDetail[`result_${id}`] = detail['result'] //审批意见
				addDetail[`createTime_${id}`] = detail['createTime'] //审批时间
				addDetail[`status_${id}`] = detail['status'] //状态
			})
		}
		// 设置参数
		formRef.setFieldsValue(addDetail)
	}
	// 设置历代详情的formitems
	setFormItems = () => {
		const data = JSON.parse(sessionStorage.getItem('borrowDetails'))
		let approvaldata = data.useReviewListBoList
		let renderFormItem = [] // 基本表单内容
		// 需要插入修改详情和审批意见项
		if (JSON.stringify(approvaldata) === '{}') return
		approvaldata.forEach((detail, n) => {
			const { id } = detail // 审核表id
			renderFormItem.push(
				...[
					[
						{
							type: 'TextArea',
							name: `result_${id}`,
							label: '审批意见',
							rules: [{ required: false }],
							initialValue: detail['result'],
							disabled: true,
							maxLength: 1500,
							showCount: true,
						},
					],
					[
						{
							type: 'Radio',
							name: `status_${id}`,
							label: '审批状态',
							span: 21,
							options: borrowEnum.verifyStatus.toOptions(),
							rules: [{ required: false }],
							initialValue: detail['status'],
							disabled: true,
						},
					],
					[
						{
							type: 'Input',
							name: `name_${id}`,
							label: '审批人:',
							span: 20,
							rules: [{ required: false }],
							initialValue: detail['name'],
							disabled: true,
						},
					],
					[
						{
							type: 'Input',
							name: `createTime_${id}`,
							label: '日期:',
							span: 22,
							rules: [{ required: false }],
							initialValue: detail['createTime'],
							disabled: true,
						},
					],
				]
			)
		})
		renderFormItem.push([
			{
				type: 'Extra',
				name: 'ButtonList', // 按钮组，用于进行按钮点击事件
				render: formRef => {
					return (
						<Space style={{ marginBottom: 20, marginLeft: 20 }}>
							{this.setButtonList(formRef)}
						</Space>
					)
				},
				span: 23, // span：23代表无边框表单组件
			},
		])
		return renderFormItem
	}

	setTableData(tableList) {
		let data = {}
		data['data'] = tableList
		data.data.forEach(item => {
			// 让每一个表格数据都获得key
			item.key = item.id
		})
		data.pageData = {
			// 配置表格的页码器
			pageSize: data.size || 10,
			current: data.page || 1,
			total: data.data.length,
		}
		this.props.getAllTableData(data, markName) // 把表格数据发送到store
	}

	handledownloadFiles = (useContentId, type) => {
		this.setState({ loadingStatus: true })
		const { downloadFiles } = this.props
		downloadFiles(useContentId, type).then(() => {
			this.setState({ loadingStatus: false })
			message.success('成功导出！')
		})
	}

	render() {
		const formType = JSON.parse(sessionStorage.getItem('formType'))
		const data = JSON.parse(sessionStorage.getItem('borrowDetails'))
		if (formType === 0) {
			return (
				<Fragment>
					<PubForm
						ref={this.formRef}
						formItems={this.setPubForm()}
						getInstance={formRef => {
							this.formRef = formRef
							this.setFormData(this.formRef)
						}}
						name={'applicationForm'}
						title='借阅申请表'
					/>
					{this.props.isMyFiles && (
						<div style={{ marginTop: '70px' }} className='borrowResultTable'>
							<BaseTable
								tableConfig={BorrowResultColumns}
								actions={this.actions}
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData}
								markName={markName}
								notRowSelection
								noOperationButton={true}
							/>
						</div>
					)}
				</Fragment>
			)
		} else if (data.status === 4) {
			const reject = data.useReviewListBoList.slice(-1)[0].status
			return (
				<Fragment>
					<PubForm
						ref={this.formRef}
						formItems={this.setPubForm()}
						getInstance={formRef => {
							this.formRef = formRef
							this.setFormData(this.formRef)
						}}
						name={'BorrowDetails'}
						title='借阅申请表'
					/>
					<PubForm
						ref={this.formRef}
						formItems={this.setFormItems()}
						getInstance={formRef => {
							this.formRef = formRef
							this.setPreviousFormData(this.formRef)
						}}
						name={'approvalPreviousForm'}
						title=' 审批详情'
					/>
					{
						// 审批状态是拒绝时，不出现下面的借阅到的文件
						reject !== 1 && (
							<div className='borrowResultTable'>
								<BaseTable
									tableConfig={BorrowResultColumns}
									actions={this.actions}
									changeSearchLimit={this.changeSearchLimit}
									changeTableData={this.changeTableData}
									markName={markName}
									notRowSelection
									operationButton={record =>
										this.returnResultButton(
											record,
											this.state.loadingStatus
										)
									} // 自定义操作按钮
								/>
							</div>
						)
					}
				</Fragment>
			)
		} else {
			if (this.state.haveFile) {
				return (
					<Fragment>
						<PubForm
							ref={this.formRef}
							formItems={this.setPubForm()}
							getInstance={formRef => {
								this.formRef = formRef
								this.myFormRef = formRef // 上面那行不确定删了会发生什么，就没删
								this.setFormData(this.formRef)
							}}
							name={'BorrowDetails'}
							title='借阅申请表'
						/>
						<PubForm
							ref={this.formApprovalRef}
							formItems={this.setFormItems()}
							getInstance={formRef => {
								this.formApprovalRef = formRef //改了命名
								this.setPreviousFormData(this.formApprovalRef)
							}}
							name={'approvalPreviousForm'}
							title=' 审批详情'
						/>
						<div style={{ marginTop: '70px' }} className='borrowResultTable'>
							<BaseTable
								tableConfig={BorrowResultColumns}
								actions={this.actions}
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData}
								markName={markName}
								notRowSelection
								noOperationButton={true}
							/>
						</div>
					</Fragment>
				)
			} else {
				return (
					<Fragment>
						<PubForm
							ref={this.formRef}
							formItems={this.setPubForm()}
							getInstance={formRef => {
								this.formRef = formRef
								this.myFormRef = formRef // 上面那行不确定删了会发生什么，就没删
								this.setFormData(this.formRef)
							}}
							name={'BorrowDetails'}
							title='借阅申请表'
						/>
						<PubForm
							ref={this.formApprovalRef}
							formItems={this.setFormItems()}
							getInstance={formRef => {
								this.formApprovalRef = formRef //改了命名
								this.setPreviousFormData(this.formApprovalRef)
							}}
							name={'approvalPreviousForm'}
							title=' 审批详情'
						/>
					</Fragment>
				)
			}
		}
	}
}

export default BorrowDetails
