/**
 * 将javascript代码生成url
 * @param {function} javascriptCode 函数体包含待转换代码的函数
 * @return {string} blob url字符串
 */
const generateScriptUrl = javascriptCode => {
	let code = javascriptCode.toString()
	code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'))
	const blob = new Blob([code], { type: 'application/javascript' })
	const scriptUrl = URL.createObjectURL(blob)
	return scriptUrl
}

export default generateScriptUrl
