import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import {
	mapUrl,
	// dossierUrl,
	archiveUrl,
} from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
import { INITIAL_PUBARCHIVE } from '@/Redux/ActionTypes/PubArchive'

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

// 初始化pubArchive[markName].old
export const inititalPubArchiveAction = (markName, pubArchiveData) => {
	return {
		type: INITIAL_PUBARCHIVE,
		filter: {
			markName,
			...pubArchiveData,
		},
	}
}

// 爬取网址
export const getInformationByUrlThunk = data => () =>
	new Promise((resolve, reject) => {
		data.operationType += 1
		_fetch({
			url: mapUrl.getInformationByUrl,
			type: 'get',
			data,
		})
			.then(res => {
				if (res && res.data.code === 200) {
					// 因后台使用了消息队列，此接口不返回data，也不要求跳转至详情页
					// let dossierId = res.data.data[0]?.dossierId
					// if (dossierId) {
					//   // 成功获取案卷信息
					//   _fetch({
					//     url: dossierUrl.getDossierDetail,
					//     data: {
					//       id: dossierId
					//     }
					//   }).then((resp) => {
					//     if (resp && resp.data.code === 200) {
					//       // 成功则顺带请求其下文件列表
					//       _fetch({
					//         url: archiveUrl.getArchiveList,
					//         type: 'get',
					//         data: {
					//           id: dossierId,
					//           size: 999
					//         },
					//         isJson: true
					//       }).then((resData) => {
					//         if (resData && resData.data.code === 200) {
					//           resp.data.data['archiveDetail'] = resData.data.data.records || [];
					//           resolve({
					//             ...resp.data.data,
					//             type: 0,
					//             duplicated: res.data.data[0]?.duplicated
					//           });
					//         } else {
					//           reject()
					//         }
					//       })
					//     } else {
					//       reject()
					//     }
					//   })
					// } else {
					//   // 如果不是案卷是档案
					//   resolve(res)
					// }
					resolve(res)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})

export const getArchiveDetail = id => {
	return _fetch({
		url: archiveUrl.getArchiveDetail,
		type: 'get',
		data: {
			id,
		},
	})
}

export const getLogDetail = () => {
	return _fetch({
		url: archiveUrl.getArchiveDetail,
		type: 'get',
		params: {
			size: 10,
			current: 1,
		},
	})
}

export const urlToArchiveManagementOptions = [
	{
		label: '网页',
		value: 2,
	},
	{
		label: '公众号',
		value: 3,
	},
	{
		label: '视频号',
		value: 4,
	},
	{
		label: '抖音',
		value: 1,
	},
	{
		label: '学习强国',
		value: 5,
	},
	{
		label: '微博',
		value: 6,
	},
]

export const urlToArchiveManagementOptionsHZQ = [
	{
		label: '网页',
		value: 2,
	},
	{
		label: '公众号',
		value: 3,
	},
	{
		label: '抖音',
		value: 1,
	},
	{
		label: '微博',
		value: 6,
	},
]
