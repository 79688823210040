import React, { Component, Fragment } from 'react'
import BaseTable from '@/Public/PubTable/baseTable'
import { HealthColumns } from '@/Public/PubTable/pageColumns'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { isloading } from './ActionCreator'

const markName = 'SystemHealth' //redux标识
@WrappedTable.create(markName)
class Health extends Component {
	componentDidMount() {
		const { pub_sendAllTableData, pub_changeisLoading } = this.props
		pub_changeisLoading(true, markName)
		isloading().then(res => {
			pub_changeisLoading(false, markName)
			pub_sendAllTableData(res, markName)
		})
	}
	render() {
		const { pub_allTableData, pub_isLoading } = this.props
		return (
			<Fragment>
				<BaseTable
					tableConfig={HealthColumns}
					withPreview={false}
					pub_allTableData={pub_allTableData}
					markName={markName}
					notRowSelection
					noOperationButton
					pub_isLoading={pub_isLoading}
					noshowpagination
				/>
			</Fragment>
		)
	}
}

export default Health
