import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { archiveTypeUrl } from '@/Utils/Urls.js'
import _fetch from '@/Utils/Fetch'
import { Message } from 'antd'
import { wordLimit } from '../DepartmentManagement/ActionCreators'

const formLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}
// formItem布局配置项
const formItemLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}
// 修正申请详情模态框
// @connect(mapStateToProps, mapDispatchToProps)
export default class AddTypeModal extends Component {
	// 表单配置项
	formItems = [
		[
			{
				type: 'AsyncTreeSelect',
				name: 'parentId',
				label: '父节点',
				placeholder: '请选择父节点,默认为一级节点',
				layout: {
					labelCol: { span: 6 },
					wrapperCol: { span: 18 }, // item
				},
				ajaxConfig: {
					url: archiveTypeUrl.getDossierType,
				},
				formatDataFunc: (data, changeKeys) => {
					let treeData = changeKeys(data.children, {
						typeName: 'title',
						id: 'value',
					})
					return treeData
				},
				span: 24,
				rules: [{ required: false }],
			},
		],
		[
			{
				type: 'Input',
				name: 'typeName',
				label: '分类名称',
				layout: formItemLayout,
				placeholder: '请输入分类名称',
				span: 20,
			},
			{
				type: 'Input',
				name: 'categoryCode',
				label: '分类代码',
				layout: formItemLayout,
				placeholder: `请输入${wordLimit['f'] !== null ? wordLimit['f'] + '位' : ''}分类代码`,
				span: 20,
				maxLength: wordLimit['f'],
				rules: [
					{
						required: true,
						type: 'string',
						len: wordLimit['f'],
						message: `请输入${wordLimit['f'] !== null ? wordLimit['f'] + '位' : ''}分类代码`,
					},
				],
			},

			{
				type: 'Input',
				name: 'defaultRetentionPeriod',
				label: '保管权限',
				layout: formItemLayout,
				placeholder: `请输入保管时间：1-永久 2-30年 3-10年`,
				span: 20,
				maxLength: 1,
				rules: [
					{
						required: true,
						type: 'string',
					},
				],
			},

		],
	]

	render() {
		const { modalRef, hideCallback, pub_getAllData, reloadAllData } = this.props
		return (
			<PubModalForm
				title={[
					<span style={{ fontFamily: 'Microsoft YaHei' }}>档案类型管理</span>,
				]}
				ref={modalRef}
				okText='保存'
				onOk={(formData, { finish, hide, setSpinning }) => {
					// 如果传入了 buttonList 则不用传 onOk，它是默认的两个按钮的提交事件
					_fetch({
						url: archiveTypeUrl.addDossierType,
						type: 'post',
						data: formData,
						isJson: true,
					}).then(res => {
						if (res.data.code === 200) {
							Message.success('添加成功')
							pub_getAllData()
							reloadAllData()
						}
						finish()
						hide()
					})
				}}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				width={550}
				formProps={{
					// 表单的配置项
					name: 'addTypeModalForm',
					formItems: this.formItems,
					layout: formLayout,
				}}
				hideCallback={hideCallback}
			/>
		)
	}
}
