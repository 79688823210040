import React, { PureComponent, Fragment } from 'react'
import {
	Form,
	Input,
	Select,
	TreeSelect,
	Radio,
	Collapse,
	DatePicker,
	Button,
} from 'antd'
import './index.less'
import moment from 'moment'
import 'moment/locale/zh-cn'
import {
	FileTextOutlined,
	FileWordOutlined,
	FilePdfOutlined,
	FilePptOutlined,
	FileExcelOutlined,
	FileUnknownOutlined,
} from '@ant-design/icons'
import { getConfig } from '@/Utils/ModeTranslate'

moment.locale('zh-cn')

const { Option } = Select
const { Panel } = Collapse
const formRef = React.createRef(null) // 表单ref
export default class PaperDocumentEditor extends PureComponent {
	state = {
		changeNullValues: {}, //表单被修改为空的字段集合
		sensitiveDetected: {},
	}
	// 添加自定义样式
	setStyle = () => {
		let { height = 580, width = 350, uniqueName, buttons } = this.props
		height = height.toString()
		width = width.toString()
		if (width.replace(/[0-9]/g, '') === '') {
			width = `${width}px`
		}
		if (height.replace(/[0-9]/g, '') === '') {
			height = `${height}px`
		}
		const property = {
			'--height': height,
			'--width': width,
			'--form-height': `calc(${height} - 60px)`,
			'--content-height': buttons ? '88%' : '95%',
			'--buttons-height': buttons ? '12%' : '0',
		}
		const fileEditor = document.querySelector(`.${uniqueName}`)
		for (let i in property) {
			fileEditor.style.setProperty(i, property[i])
		}
	}

	sensitiveDetect = () => {
		//敏感词提示
		const detectedDataList = ['topic', 'keyWord'] //探测的字段
		let sensitiveDetected = { topic: false, keyword: false } //默认false
		detectedDataList?.forEach(item => {
			const string = formRef.current?.getFieldValue(item)
			//探测条件
			if (string?.match(/\*\*/g)) {
				sensitiveDetected[item] = true
			}
		})
		//同步到状态
		this.setState({ sensitiveDetected })
	}
	// 表单提交函数
	setData = values => {
		const fileMetadata = []
		const data = {}
		const { changeNullValues } = this.state
		// 遍历要格式化的数据
		for (let i in values) {
			// i 为数字，则证明是元数据内容
			if (!isNaN(Number(i))) {
				if (values[i]) {
					fileMetadata.push({
						metadataId: Number(i),
						metadataValue:
							Number(i) === 75 || Number(i) === 86
								? moment(values[i])
										.format('YYYY-MM-DDTHH:mm:ss')
										.replace(/\.(.*)/, '')
								: values[i],
					})
				}
			}
			// 不是元数据的内容就直接放进请求数组里
			else {
				data[i] = values[i]
			}
		}

		//把修改为空的字段也添加进去
		for (let key in changeNullValues) {
			if (!isNaN(Number(key))) {
				fileMetadata.push({
					metadataId: Number(key),
					metadataValue: changeNullValues[key],
				})
			} else {
				data[key] = changeNullValues[key]
			}
		}
		// 返回格式化完成的数据
		data.metadata = fileMetadata
		return data
	}

	async componentDidMount() {
		if (!this.props.getRef) {
			throw Error('FileEditor中，getRef是必传参数')
		}
		this.setStyle()
		await this.props.getRef(formRef)
		this.getModeConfig()
		this.sensitiveDetect()
	}

	componentDidUpdate() {
		this.setStyle()
	}

	/**
	 * 用于将数据分为常用和不常用
	 * @param { Array} data
	 */
	separateData() {
		if (localStorage.getItem('handledDocMetaData')) {
			return
		}
		const commonUse = [] // 存放常用元数据
		const notCommonUse = [] //存放不常用数据
		const keyData = [] //存带星号的项，放在数组最前面
		const docMetaData = JSON.parse(
			localStorage.getItem('metadata')
		).docExclusiveMetadataList
		commonUse.splice(0)
		notCommonUse.splice(0)
		docMetaData.forEach((item, index) => {
			if (item.isOften === 1) {
				if (item.id === 86 || item.id === 99) {
					keyData.push(item)
				} else {
					commonUse.push(item)
				}
			} else {
				notCommonUse.push(item)
			}
		})
		localStorage.setItem(
			'handledDocMetaData',
			JSON.stringify([keyData.concat(commonUse), notCommonUse])
		)
	}

	setTreeData = (data, titleName, valueName, onlyChildren = false) => {
		let treeData = []
		const recursionToTree = data => {
			let tempObj = {
				title: data[titleName],
				value: data[valueName],
			}

			if (data.children != null) {
				if (onlyChildren) {
					tempObj.disabled = true
				}
				tempObj.children = [...data.children.map(item => recursionToTree(item))]
			}
			return tempObj
		}
		data?.forEach((value, index) => {
			treeData.push(recursionToTree(value))
		})
		return treeData
	}

	disabledDate = current => {
		return current && current > moment().endOf('day')
	}

	dataRule = {
		82: [
			{
				type: 'number',
				message: '件号只能输入数字',
				transform: value => value && Number(value),
			},
		],
		97: [
			{
				type: 'number',
				message: '页数只能输入数字',
				transform: value => value && Number(value),
			},
		],
		86: [{ required: true, message: `请填写成文日期` }],
		99: [{ required: true, message: `请填写开放状态` }],
	}

	//收集被清空字段
	onValuesChange = changedValues => {
		if (Object.values(changedValues)[0] === '') {
			this.setState({
				changeNullValues: { ...this.state.changeNullValues, ...changedValues },
			})
		}
	}

	//获取提交模式配置
	getModeConfig = () => {
		getConfig().then(Config => {
			this.setState({
				modeConfig: Config.modeConfig,
			})
		})
	}

	render() {
		const {
			disabled = false, // 是否禁用表单，默认为false
			uniqueName, // 表单唯一表示, 必须要
			buttons, // 按钮组
			onFinish, // 表单提交函数
			onValuesChange, // 表单value改变触发的函数
			others,
			isFaceDetails, // 是否需要人脸编辑
			fileType,
		} = this.props
		const { modeConfig = {}, sensitiveDetected } = this.state
		this.separateData()
		const fonds = JSON.parse(localStorage.getItem('fonds')),
			dossierType = this.setTreeData(
				JSON.parse(localStorage.getItem('dossierType')),
				'typeName',
				'id',
				true
			),
			department = this.setTreeData(
				JSON.parse(localStorage.getItem('department')),
				'departmentName',
				'id'
			),
			metadata = JSON.parse(localStorage.getItem('metadata')),
			commonUse = JSON.parse(localStorage.getItem('handledDocMetaData'))[0]
		//console.log(metadata);
		//console.log(commonUse);
		const filteredCommonUse = commonUse.filter(
			item => item.parentId === 174 || item.id === 174
		)
		//console.log(filteredCommonUse);
		//判断是否在新建著录填写信息页面
		let isNewFiles = window.location.href.split('/').pop().includes('archiveType')
		//在新建著录填写信息页面  && 按卷情况下  不显示 ，其他情况都显示
		let formItemShow = !(isNewFiles && modeConfig['commit_mode_wenshu'] === 2)

		return (
			<div>
				<Fragment>
					<div className={`paper-document-editor ${uniqueName}`}>
						<div className='information-form'>
							<h3>
								{(() => {
									switch (fileType.split('/')[1]) {
										case 'plain':
											return (
												<FileTextOutlined className='file-type-icon' />
											)
										case 'msword':
											return (
												<FileWordOutlined className='file-type-icon' />
											)
										case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
											return (
												<FileWordOutlined className='file-type-icon' />
											)
										case 'pdf':
											return (
												<FilePdfOutlined className='file-type-icon' />
											)
										case 'vnd.openxmlformats-officedocument.presentationml.presentation':
											return (
												<FilePptOutlined className='file-type-icon' />
											)
										case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
											return (
												<FileExcelOutlined className='file-type-icon' />
											)
										default:
											return (
												<FileUnknownOutlined className='file-type-icon' />
											)
									}
								})()}
								{(() => '文书信息')()}
							</h3>
							<Form
								onFinish={values => {
									if (onFinish) {
										onFinish(this.setData(values))
									}
								}}
								onValuesChange={changedFields => {
									if (onValuesChange) {
										onValuesChange(changedFields)
									} else {
										this.onValuesChange(changedFields)
									}
								}}
								ref={formRef}>
								<div className='infomation-content'>
									{/* 题名 */}
									<Form.Item
										label='题名'
										name='topic'
										rules={[
											{ required: true, message: '请输入题名' },
										]}>
										<Input.TextArea
											style={{
												WebkitLineClamp: disabled ? 4 : 'auto',
											}}
											className={
												sensitiveDetected.topic ? 'detected' : ''
											}
											disabled={disabled}
											placeholder='无'
											bordered={!disabled}
											autoSize={disabled ? { maxRows: 4 } : true}
											data-label='题名'
											maxLength={300}
											showCount={true}
										/>
									</Form.Item>

									{/* 时地人事 */}
									{metadata?.specialMetadataStruct[0]?.child.map(
										value => {
											switch (value.metadataName) {
												case '时间': {
													return (
														<Form.Item
															label={'成文时间'}
															name={value.id}
															key={value.id}
															rules={[
																{
																	required: true,
																	message:
																		'请输入成文时间',
																},
															]}>
															<DatePicker
																disabled={disabled}
																placeholder='请选择日期'
																bordered={!disabled}
																name={value.id}
																disabledDate={
																	this.disabledDate
																}
															/>
														</Form.Item>
													)
												}

												case '人物': {
													return (
														<div
															className='people'
															key={value.id}>
															<Form.Item
																label={value.metadataName}
																name={value.id}>
																<Input.TextArea
																	style={
																		isFaceDetails
																			? {
																					paddingRight:
																						'38px',
																					WebkitLineClamp:
																						disabled
																							? 4
																							: 'auto',
																				}
																			: null
																	}
																	autoSize={
																		disabled
																			? {
																					maxRows: 4,
																				}
																			: true
																	}
																	data-label={
																		value.metadataName
																	}
																	disabled={disabled}
																	placeholder='无'
																	bordered={!disabled}
																	maxLength={1500}
																	showCount={true}
																/>
															</Form.Item>
														</div>
													)
												}

												default: {
													return (
														<Form.Item
															label={value.metadataName}
															name={value.id}
															key={value.id}>
															<Input.TextArea
																style={{
																	WebkitLineClamp:
																		disabled
																			? 4
																			: 'auto',
																}}
																placeholder='无'
																disabled={disabled}
																bordered={!disabled}
																autoSize={
																	disabled
																		? { maxRows: 4 }
																		: true
																}
																data-label={
																	value.metadataName
																}
																name={value.id}
																maxLength={1500}
																showCount={true}
															/>
														</Form.Item>
													)
												}
											}
										}
									)}

									{formItemShow && (
										<>
											{/* 全宗号 */}
											<Form.Item
												label='全宗'
												name='fondsIdentifierId'
												rules={[
													{
														required: true,
														message: '请输入全宗号',
													},
												]}>
												<Select
													disabled={disabled}
													bordered={!disabled}
													placeholder='无'>
													{fonds &&
														fonds.map(value => {
															return (
																<Option
																	key={value.id}
																	value={value.id}>
																	{value.name}
																</Option>
															)
														})}
												</Select>
											</Form.Item>

											{/* 类别号 */}
											<Form.Item
												label='类别'
												name='categoryCodeId'
												rules={[
													{
														required: true,
														message: '请选择类别号',
													},
												]}>
												<TreeSelect
													disabled={disabled}
													bordered={!disabled}
													placeholder='无'
													treeData={dossierType}
													treeDefaultExpandAll
													virtual={false}
												/>
											</Form.Item>

											{/* 部门 */}
											<Form.Item
												label='部门'
												name='departmentId'
												rules={[
													{
														required: true,
														message: '请选择部门',
													},
												]}>
												<TreeSelect
													disabled={disabled}
													bordered={!disabled}
													placeholder='无'
													treeData={department}
													initialvalues={22}
													virtual={false}
												/>
											</Form.Item>

											{/* 密级 */}
											<Form.Item
												label='密级'
												name='confidentialLevel'
												rules={[
													{
														required: true,
														message: '请选择密级',
													},
												]}>
												<Select
													disabled={disabled}
													bordered={!disabled}
													placeholder='无'>
													<Option key={0} value={0}>
														公开
													</Option>
													<Option key={1} value={1}>
														内部
													</Option>
													<Option key={2} value={2}>
														绝密
													</Option>
													<Option key={3} value={3}>
														机密
													</Option>
													<Option key={4} value={4}>
														秘密
													</Option>
												</Select>
											</Form.Item>

											{/* 保管期限 */}
											<Form.Item
												label='保管期限'
												name='retentionPeriod'
												rules={[
													{
														required: true,
														message: '请选择保管期限',
													},
												]}
												style={{ marginLeft: '-7px' }}>
												<Radio.Group disabled={disabled}>
													<Radio value={1}>永久</Radio>
													<Radio value={2}>30年</Radio>
													<Radio value={3}>10年</Radio>
												</Radio.Group>
											</Form.Item>
										</>
									)}

									{/* 关键字 */}
									<Form.Item label='关键字' name='keyWord'>
										<Input.TextArea
											style={{
												WebkitLineClamp: disabled ? 4 : 'auto',
											}}
											className={
												sensitiveDetected.keyWord
													? 'detected'
													: ''
											}
											placeholder='无'
											disabled={disabled}
											bordered={!disabled}
											autoSize={disabled ? { maxRows: 4 } : true}
											data-label='关键字'
											name='keyWord'
											maxLength={1500}
											showCount={true}
										/>
									</Form.Item>

									{others?.map((props, index) => {
										const [inputKey, DataKey] = props.keys
										return (
											<div
												key={index}
												className='input-with-picker'>
												<Form.Item
													label={props.label}
													name={inputKey}>
													<Input
														bordered={false}
														placeholder='无'
														disabled={true}
													/>
												</Form.Item>

												<Form.Item name={DataKey}>
													<DatePicker
														placeholder='无'
														disabled={true}
														disabledDate={this.disabledDate}
													/>
												</Form.Item>
											</div>
										)
									})}

									{/* 常用元数据 */}
									<Collapse ghost defaultActiveKey={['0']}>
										<Panel header='常用元数据'>
											<div className='metadata-form'>
												<Form.Item>
													<div id='newfiles-metedata-container'>
														{filteredCommonUse.length !== 0
															? filteredCommonUse.map(
																	value => {
																		switch (
																			value.id
																		) {
																			case 86: //成文日期
																				return (
																					<Form.Item
																						label={
																							value.metadataName
																						}
																						name={
																							value.id
																						}
																						key={
																							value.id
																						}
																						rules={
																							this
																								.dataRule[86]
																						}>
																						<DatePicker
																							disabled={
																								disabled
																							}
																							placeholder='无'
																							bordered={
																								!disabled
																							}
																							name={
																								value.id
																							}
																							disabledDate={
																								this
																									.disabledDate
																							}
																						/>
																					</Form.Item>
																				)
																			case 99:
																				return (
																					<Form.Item
																						label={
																							value.metadataName
																						}
																						name={
																							value.id
																						}
																						key={
																							value.id
																						}
																						initialValue={
																							'原控'
																						}
																						rules={
																							this
																								.dataRule[99]
																						}>
																						<Select
																							disabled={
																								disabled
																							}
																							bordered={
																								!disabled
																							}
																							placeholder='无'>
																							<Option
																								key={
																									0
																								}
																								value={
																									'原控'
																								}>
																								原控
																							</Option>
																							<Option
																								key={
																									1
																								}
																								value={
																									'开放'
																								}>
																								开放
																							</Option>
																							<Option
																								key={
																									2
																								}
																								value={
																									'控制'
																								}>
																								控制
																							</Option>
																						</Select>
																					</Form.Item>
																				)
																			default:
																				return (
																					<Form.Item
																						label={
																							value.metadataName
																						}
																						name={
																							value.id
																						}
																						key={
																							value.id
																						}
																						rules={
																							this
																								.dataRule[
																								value
																									.id
																							]
																						}>
																						<Input.TextArea
																							style={{
																								WebkitLineClamp:
																									disabled
																										? 4
																										: 'auto',
																							}}
																							disabled={
																								disabled
																							}
																							placeholder='无'
																							bordered={
																								!disabled
																							}
																							autoSize={
																								disabled
																									? {
																											maxRows: 4,
																										}
																									: true
																							}
																							data-label={
																								value.metadataName
																							}
																							maxLength={
																								1500
																							}
																							showCount={
																								true
																							}
																						/>
																					</Form.Item>
																				)
																		}
																	}
																)
															: null}
													</div>
												</Form.Item>
											</div>
										</Panel>
									</Collapse>
								</div>
								{/* 按钮 */}
								<div className='fill-information-btns'>
									<Form.Item id='button-fix'>
										{buttons &&
											buttons.map((btn, index) => {
												const { text, ...props } = btn
												if (
													props.isAssembly &&
													props.isAssembly === true
												) {
													return (
														<div key={index.toString()}>
															{text}
														</div>
													)
												}
												delete props.isAssembly
												return (
													<Button
														key={index.toString()}
														{...props}>
														{text}
													</Button>
												)
											})}
									</Form.Item>
								</div>
							</Form>
						</div>
					</div>
				</Fragment>
			</div>
		)
	}
}
