import React, { PureComponent, Fragment } from 'react'
import { Button, Space, message, Modal } from 'antd'
import AddCrawlingModal from './addCrawlingModal'
import UpdateCrawlingModal from './updateCrawlingModal'
import CheckMediaJobModal from './checkMediaJobModal'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import BaseTable from '@/Public/PubTable/baseTable'
import SearchForm from '@/Public/PubTable/searchForm'
import { deleteMediaJob, cancelOrStartJob, tips } from './ActionCreators'
import { urlToArchiveManagementColumns } from '@/Public/PubTable/pageColumns'
import { urlToArchiveManagement, departmentUrl } from '@/Utils/Urls'
import { registryClient } from './ActionCreators'
import { treeUtil } from '@/Utils'
import { debounce } from 'lodash'
import './index.less'

const markName = 'urlToArchiveManagement' //redux的标志
const sourceMap = {
	2: 'newsSite',
	3: 'publicAccount',
	4: 'videoAccount',
	1: 'tiktok',
	5: 'xuexiqiangguo',
	6: 'weibo',
	7: 'email',
}

const addCrawlingModalRef = React.createRef()
const updateCrawlingModalRef = React.createRef()
const checkMediaJobModalRef = React.createRef()

@WrappedTable.create(markName)
class UrlToArchiveManagement extends PureComponent {
	state = {
		record: null,
	}
	// 表单配置项
	searchFormConfig = {
		formItems: [
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId',
				label: '部门',
				placeholder: '选择部门',
				rules: [{ required: false }],
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
				width: 200,
			},
			{
				type: 'Extra',
				name: '操作按钮',
				span: 7,
				render: () => {
					return (
						<Space>
							<Button
								className='btn-success iconfont-buttons'
								title='新增'
								style={{ paddingBotton: '6px' }}
								onClick={() => {
									const { name, workId, departmentId } = JSON.parse(
										localStorage.getItem('userMessage')
									).user

									addCrawlingModalRef.current.show(formRef => {
										formRef.setFieldsValue({
											userName: name,
											workId,
											departmentId,
											type: 2,
											file: [],
										})
									})
								}}
								type='primary'>
								&#xe634;
							</Button>
							<Button
								className='iconfont-buttons'
								title='删除'
								onClick={() => {
									const { pub_rowSelection, pub_getAllData } =
										this.props
									const selectList = pub_rowSelection[markName]
									if (selectList.length !== 0) {
										Modal.confirm({
											maskClosable: true,
											title: '删除频道',
											content:
												'所选频道下的所有栏目和采集规则都将被删除，请确认',
											okText: '确定',
											cancelText: '取消',
											onOk: () => {
												deleteMediaJob(
													selectList.map(i => i.id)
												).then(res => {
													if (res.data.code === 200) {
														message.success('删除成功')
														pub_getAllData()
													}
												})
											},
										})
									} else {
										Modal.error({
											content: tips.CHOOSE_AT_LEAST_ONE,
											maskClosable: true,
										})
									}
								}}
								type='danger'>
								&#xe816; 删除
							</Button>
							<Button
								type='primary'
								title='注册'
								onClick={() => {
									registryClient().then(res => {
										if (res.data.success) {
											// 后台数据库插入失败也会返回200与success，跟后台约定判断data为false就报错
											if (res.data === false) {
												message.error('服务器繁忙，请稍后再试')
											} else {
												message.success('注册成功！')
											}
										}
									})
								}}>
								注册
							</Button>
							<Button
								type='primary'
								title='检查频道是否可用'
								onClick={() => {
									checkMediaJobModalRef.current.show()
								}}>
								检查
							</Button>
						</Space>
					)
				},
			},
		],
		ajaxConfig: {
			type: 'get',
			url: urlToArchiveManagement.getMediaJob,
		},
	}

	changeSearchLimit = data => {
		return data
	}

	changeTableData = data => {
		data = {
			data: data.records,
			pageSize: data.size,
			current: data.current, // 后台给的数据默认是第一页
			total: data.total,
		}
		return data
	}

	returnButton = record => {
		//自定义按钮
		// 对record修改成表单对应的属性
		record.time = record.startTime
		return (
			<Space>
				{/* 开始暂停按钮 */}
				{record.status !== 4 ? (
					<Button
						onClick={debounce(async () => {
							const data = {
								jobId: record.id,
								isOpen: record.status === 3 ? 1 : 0,
							}
							const res = await cancelOrStartJob(data)
							if (res.data.code === 200) {
								message.success('操作成功')
							}
							this.props.pub_getAllData()
						}, 150)}
						size='small'>
						{record.status === 3 ? '开始' : '暂停'}
					</Button>
				) : null}
				{/* 编辑按钮 */}
				<Button
					className='iconfont-buttons'
					title='编辑'
					onClick={() => {
						this.setState({ record }, () => {
							record.file = [
								{
									name: record.gzhName,
									url: '/api/file/download/' + record.fileToken,
									status: 'removed',
								},
							]
							updateCrawlingModalRef.current.show(formRef => {
								formRef.setFieldsValue(record)
							})
						})
					}}
					size='small'>
					&#xeabd; 编辑
				</Button>
			</Space>
		)
	}

	// 自定义渲染列
	changeColumns = urlToArchiveManagementColumns => {
		urlToArchiveManagementColumns.forEach(item => {
			if (Object.keys(item)[0] === 'source') {
				item.render = (text, record) => {
					return (
						<div
							className={`${sourceMap[record.type]}-icon`}
							title={text}></div>
					)
				}
			}
		})
		return urlToArchiveManagementColumns
	}

	render() {
		return (
			<div className='urlToArchiveManagementForm'>
				<Fragment>
					<WrappedTable>
						<SearchForm
							name={'urlToArchiveSearchForm'}
							formItems={this.searchFormConfig}
							markName={markName}
							changeSearchLimit={this.changeSearchLimit}
							changeTableData={this.changeTableData}
						/>
						<BaseTable
							tableConfig={this.changeColumns(
								urlToArchiveManagementColumns
							)}
							changeTableData={this.changeTableData} // 把拿到的表格数据改造成符合公共组件所需数据的格式
							changeSearchLimit={this.changeSearchLimit} // 这里也传一下，后续开发公共组件可能会用到
							markName={markName}
							operationButton={this.returnButton} // 自定义操作按钮
							withPreview={false}
							dragSortable={true}
						/>
					</WrappedTable>
					<AddCrawlingModal
						modalRef={addCrawlingModalRef}
						pub_getAllData={this.props.pub_getAllData} // 刷新
					/>
					<UpdateCrawlingModal
						modalRef={updateCrawlingModalRef}
						record={this.state.record} // 表格详情信息
						pub_getAllData={this.props.pub_getAllData} // 刷新
					/>
					<CheckMediaJobModal modalRef={checkMediaJobModalRef} />
				</Fragment>
			</div>
		)
	}
}

export default UrlToArchiveManagement
