import React, { Component } from 'react'
import Enum from '@/Utils/Enum'
import { connect } from 'react-redux'
import CompositeSearchButton from '@/Public/CompositeSearch/CompositeSearchButton'
import { addAppraisalDataToRedux } from './ActionCreators'
import { cloneDeep } from 'lodash'
import { PlusOutlined } from '@ant-design/icons'
import { dossierUrl } from '@/Utils/Urls'
import { dossierEnum } from '@/Utils/Enum'

// const enumStatus = new Enum({ 3: '已过期' });
const preRetentionPeriodType = new Enum({ 1: '永久', 2: '30年', 3: '10年' }) //前有效期

const mapStateToProps = state => {
	return {
		appraisalData: state.appraisalDetails.appraisalData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		addAppraisalData: (...rest) => dispatch(addAppraisalDataToRedux(...rest)),
	}
}

const otherFormItem = [
	{
		type: 'Select',
		name: 'status', // 状态
		label: '状态',
		placeholder: '请选择状态',
		options: dossierEnum.status.toOptions(),
		initialValue: dossierEnum.status.toOptions()[3].value,
		width: '150px',
		allowClear: false,
		disabled: true,
	},
]

@connect(mapStateToProps, mapDispatchToProps)
class AddBtn extends Component {
	state = {
		tmpSelectedFilesData: [], // 放置暂时选中的已有文件
	}

	markName = this.props.markName

	// 传入CompositeSearchButton，设置<BaseTable>的rowSelection
	returnRowSelection = () => {
		const { tmpSelectedFilesData } = this.state
		const { appraisalData } = this.props
		const filesData = appraisalData[0]?.verifyDetails || [] // 拿出来Table栏中的文件
		const onSelect = (record, selected, selectedRows, event) => {
			if (selected) {
				this.setState({
					tmpSelectedFilesData: [...tmpSelectedFilesData, record],
				})
			} else {
				let tmpFilesData = tmpSelectedFilesData.filter(item => {
					if (this.props.status === 0 && item.verifyId) {
						return item.verifyId !== record.id
					} else {
						return item.id !== record.id
					}
				})
				this.setState({
					tmpSelectedFilesData: tmpFilesData,
				})
			}
		}

		// 全选的函数
		const onSelectAll = (selected, allSelectedRows, willSelectedRows) => {
			if (selected) {
				this.setState({
					tmpSelectedFilesData: [...tmpSelectedFilesData, ...willSelectedRows],
				})
			} else {
				const tmpFilesData = tmpSelectedFilesData.filter(item => {
					let checkWillWithIn = true
					for (let i = 0; i < willSelectedRows.length; i++) {
						if (this.props.status === 0 && willSelectedRows[i].verifyId) {
							if (willSelectedRows[i].verifyId === item.id) {
								checkWillWithIn = false
								break
							}
						} else {
							if (willSelectedRows[i].id === item.id) {
								checkWillWithIn = false
								break
							}
						}
					}
					return checkWillWithIn
				})
				this.setState({
					tmpSelectedFilesData: tmpFilesData,
				})
			}
		}
		let selectedRowKeys = [...tmpSelectedFilesData, ...filesData] // 把此次选中的选中，和Table栏的文件并到一起，设置为选中的文件

		// 禁选已经在Table栏中的文件
		const getCheckboxProps = item => {
			let checkWithin = false

			for (let i = 0; i < filesData.length; i++) {
				if (this.props.status === 0 && filesData[i].verifyId) {
					if (filesData[i].verifyId === item.id) {
						checkWithin = true
						break
					}
				} else {
					if (filesData[i].id === item.id) {
						checkWithin = true
						break
					}
				}
			}

			return { disabled: checkWithin }
		}
		// 按着官方文档要求配置，然后放回
		const rowSelection = {
			type: 'checkbox',
			onSelect: onSelect,
			onSelectAll: onSelectAll,
			getCheckboxProps: getCheckboxProps,
			selectedRowKeys: selectedRowKeys
				? selectedRowKeys.map(item => {
						if (this.props.status === 0 && item.verifyId) {
							return item.verifyId
						} else {
							return item.id
						}
					})
				: [],
		}

		return rowSelection
	}

	// 点击加入按钮后触发的函数
	handleCompositeRecords = records => {
		const { appraisalData, addAppraisalData, status, updateVerifyApply } = this.props
		const currentIds = appraisalData[0].verifyDetails.map(item => {
			if (status === 0) {
				return item.verifyId || item.id
			} else {
				return item.id
			}
		})

		let cloneAppraisalData = cloneDeep(appraisalData)
		const filesData = appraisalData[0]?.verifyDetails // 获取已经在Table表格里的文件
		records = records.filter(item => !currentIds.includes(item.id))

		records.forEach((value, index) => {
			value[`preRetentionPeriod`] = preRetentionPeriodType[value.retentionPeriod]
		})
		const tmpVerifyFilesData = [...filesData, ...records]
		cloneAppraisalData[0].verifyDetails = tmpVerifyFilesData
		addAppraisalData(cloneAppraisalData)

		if (status === 0) {
			// 更新鉴定表
			let verifyStore = {}
			let verifyIdList = []
			cloneAppraisalData[0].verifyDetails.forEach((value, index) => {
				verifyIdList[index] = {
					type: value.verifyType || value.type,
					verifyId: value.verifyId || value.id,
				}
			})
			verifyStore['applyId'] = cloneAppraisalData[2].id
			verifyStore['verifyIdList'] = verifyIdList

			updateVerifyApply(verifyStore)
		}
	}

	// 判断加入已有文件的模态框中，选中是否为空
	checkRowSelection = () => {
		const { tmpSelectedFilesData } = this.state
		return new Promise((resolve, reject) => {
			// 还未选中则不能操作
			if (!tmpSelectedFilesData.length) {
				reject()
			} else {
				// 返回所有选中项
				resolve(tmpSelectedFilesData)
			}
		})
	}

	render() {
		return (
			<React.Fragment>
				<CompositeSearchButton
					title={`加入可鉴定案卷或文件`}
					btnTitle={<PlusOutlined />}
					modalBtnTitle={'加入'}
					typeDisabled={true}
					rowSelection={this.returnRowSelection}
					checkRowSelection={this.checkRowSelection}
					handleCompositeRecords={this.handleCompositeRecords}
					noOperationButton={true}
					successMsg={'加入成功'}
					compositeSearchUrl={dossierUrl.getDossierList}
					withStatus={true}
					otherFormItem={otherFormItem}
				/>
			</React.Fragment>
		)
	}
}

export default AddBtn
