import { newActionTypeFunc } from '../../../Utils/index'
/* 第一位(Login)是标识这些action是属于哪一个页面的
    经过newActionTypeFunc处理之后出来的对象：
    {
        FETCHSTART: "LoginFETCHSTART",
        FETCHEND: "LoginFETCHEND"
    }
*/
const LoginActionType = newActionTypeFunc('Login', [
	'FETCHSTART',
	'FETCHFAIL',
	'FETCHEND',
	'PERMISSION',
	'USERTYPE',
	'ROLELIST',
	'USER',
])

export default LoginActionType
