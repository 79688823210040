import _fetch from '@/Utils/Fetch'
import { workStation, redDotsUrl } from '@/Utils/Urls'
import { message } from 'antd'
import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import { CHANGE_OPENKEYS, CHANGE_SELECTEDKEY } from '@/Redux/ActionTypes/SlideBar'
import { UPDATA_REDDOTS } from '@/Redux/ActionTypes/RedDots'
import store from '@/Redux/store'

export const SetRouterAction = data => {
	return {
		type: ROUTE_CHANGE,
		filter: data,
	}
}

// 红点更新redux
export const updataRedDotsAction = data => {
	return {
		type: UPDATA_REDDOTS,
		data: data,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

// 获取收藏列表
export const getWorkStaionAction =
	(status = 1) =>
	dispatch => {
		return new Promise((resolve, reject) => {
			_fetch({
				url: workStation.getWorkbenchList,
				isJson: true,
				type: 'get',
				data: {
					status,
				},
			}).then(res => {
				if (res.data.code === 200) {
					let data = res.data.data || []
					resolve(data)
				} else {
					reject(res)
				}
			})
		})
	}
/**
 *
 * @param {Object} data 创建收藏夹的数据
 * @param {Function} closeFunc 关闭modal框的函数
 */
export const addWorkStaionAction = (data, closeFunc, reflashMenu) => dispatch => {
	return _fetch({
		url: workStation.addWorkbench,
		isJson: true,
		type: 'post',
		data: data,
	}).then(res => {
		if (res.data.code === 200) {
			message.success('添加成功')
			reflashMenu(null, true)
			closeFunc()
		}
	})
}
/**
 *
 * @param {Object} data 要发送给后台的数据
 * @param {*} closeFunc 关闭modal框的函数
 * @param {*} reflashMenu 刷新菜单的函数
 */
export const deleteWorkStationAction = (data, closeFunc, reflashMenu) => dispatch => {
	return _fetch({
		url: workStation.deleteWorkbench,
		isJson: true,
		type: 'get',
		data: data,
	}).then(res => {
		if (res.data.code === 200) {
			message.success('删除成功')
			closeFunc()
			reflashMenu(null, true)
		}
	})
}

// 收藏夹状态修改
export const editWorkbenchStatusAction = (data, closeFunc, reflashMenu) => dispatch => {
	return _fetch({
		url: workStation.editWorkbenchStatus,
		isJson: true,
		type: 'post',
		data: data,
	}).then(res => {
		if (res.data.code === 200) {
			message.success('修改成功')
			closeFunc()
			reflashMenu(null, true)
		}
	})
}

// 获取红点
export const updataRedDots = store.dispatch(
	() => () =>
		new Promise((resolve, reject) => {
			_fetch({
				// 判断是否为普通用户(角色列表只有一个且为普通用户)
				url: redDotsUrl.getAllRedDots,
				isJson: true,
				type: 'get',
			}).then(res => {
				if (res.data.code === 200) {
					let data = res.data.data
					// 更新redux
					store.dispatch(updataRedDotsAction(data))
					resolve(data)
				} else {
					reject(res)
				}
			})
		})
)

// 更新openKeys
export const updateOpenKeysAction = data => {
	return {
		type: CHANGE_OPENKEYS,
		filter: data,
	}
}

// 更新selectedKeys
export const updateselectedKeysAction = data => {
	return {
		type: CHANGE_SELECTEDKEY,
		filter: data,
	}
}
