import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { workStation } from '@/Utils/Urls'
import { addWorkbenchThunk } from './ActionCreator'
import { connect } from 'react-redux'
import { message } from 'antd'
import { reflashMenu, exportWorkAreaMenuConfig } from '../../Components/SlideBar'
import { routeChangeAction, pushRoutersAction } from './ActionCreator'
import { linkTo } from '@/Routes/ManageRouter'
import { history } from '@/index'

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	addWorkbench: (...rest) => dispatch(addWorkbenchThunk(...rest)), // 新增收藏
	onChange(activeKey, history) {
		if (history) {
			history.push(linkTo.to(activeKey.key))
		} else {
			dispatch(routeChangeAction(activeKey.key))
		}
		dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
	},
	pushRouters(link) {
		return dispatch(pushRoutersAction(link))
	},
})

// 将文件加入收藏夹的模态框
@connect(mapStateToProps, mapDispatchToProps)
class JoinWorkbenchModal extends Component {
	state = {
		newWorkbench: false, // 是否处于新建收藏夹状态
		newId: null, // 本次新建路由的id
		skip: false, // 是否跳转到新建的收藏
	}

	/**
	 * 根据选中的收藏id生成路径
	 * @param {Number} id 选中的收藏id
	 */
	getNodePath = id => {
		// result 为根据id查找到的节点， idArr 为节点本身以及节点所有父级的id数组, path 为生成路径
		let result = null,
			idArr = [],
			path = '/workarea/workStation'
		// 根据id在指定范围内查找节点
		const findNode = (dataArea, id) => {
			for (let i = 0, len = dataArea.length; i < len; i++) {
				if (dataArea[i].value === id) {
					result = dataArea[i]
					break
				}
				dataArea[i].children && findNode(dataArea[i].children, id)
			}
			return result
		}
		// 设置id数组
		const setIdArr = id => {
			const node = findNode(this.treedata, id)
			idArr.push(node.value)
			node.parentId !== -1 && setIdArr(findNode(this.treedata, node.parentId))
		}
		setIdArr(id)
		idArr.reverse().forEach(item => {
			path += `/${item}`
		})
		return {
			title: `收藏 - ${findNode(this.treedata, id).title}`,
			key: path,
		}
	}

	// 加入收藏的表单配置
	formItemsAddWorkbench = [
		[
			{
				type: 'AsyncTreeSelect',
				name: 'workbenchId',
				label: '选择收藏夹',
				layout: {
					labelCol: { span: 6 },
					wrapperCol: { span: 18 },
				},
				ajaxConfig: {
					url: workStation.getWorkbenchList, // 获取收藏夹列表
					data: {
						status: 1,
					},
				},
				formatDataFunc: (data, changeKeys) => {
					let treedata = changeKeys(
						data,
						{
							workbench: 'title',
							id: 'value',
							child: 'children',
						},
						'child'
					)
					this.treedata = treedata
					return treedata
				},
			},
		],
	]

	// 新建收藏的表单配置
	formItemsNewWorkbench = [
		[
			{
				type: 'AsyncTreeSelect',
				name: 'parentId',
				label: '选择父级收藏夹',
				placeholder: '根节点',
				rules: [{ required: false }],
				layout: {
					labelCol: { span: 6 },
					wrapperCol: { span: 18 },
				},
				ajaxConfig: {
					url: workStation.getWorkbenchList, // 获取收藏夹列表
					data: {
						status: 1,
					},
				},
				formatDataFunc: (data, changeKeys) => {
					return changeKeys(
						data,
						{
							workbench: 'title',
							id: 'value',
							child: 'children',
						},
						'child'
					)
				},
			},
		],
		[
			{
				type: 'Input',
				name: 'workbench',
				label: '收藏名称',
				rules: [{ max: 255 }],
				layout: {
					labelCol: { span: 6 },
					wrapperCol: { span: 18 },
				},
			},
		],
	]

	// 加入收藏夹的按钮列表
	buttonListAddWorkbench = [
		{
			text: '新建收藏夹',
			needLoading: false,
			needValidations: false,
			onClick: () => this.setState({ newWorkbench: true }),
			buttonProps: {
				style: { float: 'left' },
			},
		},
		{
			text: '确定加入',
			onClick: (formData, { finish, hide }) => {
				this.props.onOk(formData, { hide, finish }, () => {
					const { skip } = this.state
					if (skip) {
						const path = this.getNodePath(formData.workbenchId)
						this.props.onChange(path, history)
						this.props.pushRouters(path)
					}
				})
				this.formItemsAddWorkbench[0][0].initialValue = null
				finish()
			},
			buttonProps: {
				type: 'primary',
			},
		},
		{
			text: ` `,
			needLoading: false,
			needValidations: false,
			onClick: () => {
				const { skip } = this.state
				this.buttonListAddWorkbench[2].text = skip ? ` ` : `✓`
				this.setState({ skip: !skip })
			},
			buttonProps: {
				style: { width: '30px', padding: '0' },
			},
		},
	]

	// 新建收藏夹的按钮列表
	buttonListNewWorkbench = [
		{
			text: '返回',
			needLoading: false,
			needValidations: false,
			onClick: () => this.setState({ newWorkbench: false }),
			buttonProps: {
				style: { float: 'left' },
			},
		},
		{
			text: '新建',
			onClick: (formData, { finish, hide }) => {
				// 如果没指定父级就删除 parentId
				formData.parentId === undefined && delete formData.parentId
				this.props
					.addWorkbench(formData)
					.then(res => {
						message.success('新建成功')
						this.setState({ newWorkbench: false }, () => {
							// this.formItemsAddWorkbench[0][0].initialValue = res.data.id;
							// 刷新slidebar
							reflashMenu(exportWorkAreaMenuConfig, true)
						})
					})
					.catch(err => {})
				finish()
			},
			buttonProps: {
				type: 'primary',
			},
		},
	]

	render() {
		const { modalRef, hideCallback } = this.props
		const { newWorkbench } = this.state
		return (
			<PubModalForm
				title={newWorkbench ? '新建收藏夹' : '加入收藏夹'}
				ref={modalRef}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				buttonList={
					newWorkbench
						? this.buttonListNewWorkbench
						: this.buttonListAddWorkbench
				}
				width={500}
				formProps={{
					// 表单的配置项
					name: 'JoinWorkbenchModalForm',
					formItems: newWorkbench
						? this.formItemsNewWorkbench
						: this.formItemsAddWorkbench,
				}}
				hideCallback={hideCallback}
				zIndex={1001}
			/>
		)
	}
}

export default JoinWorkbenchModal
