import React, { Component } from 'react'
import { Upload, Button } from 'antd'
import { FolderAddOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import Status from './Status'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
import {
	multiUploadThunk2,
	autoDescriptionThunk,
	getAutoDescriptionDetailThunk,
	createFileChunk,
	getFileMd5,
	downloadMultipartResultThunk,
	noChunkMultiUpload,
} from './ActionCreators'
const { Dragger } = Upload

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	// multiUpload: (...rest) => dispatch(multiUploadThunk2(...rest)), // 自动著录上传文件
	autoDescription: (...rest) => dispatch(autoDescriptionThunk(...rest)), // 自动著录上传文件
	getAutoDescriptionDetail: (...rest) =>
		dispatch(getAutoDescriptionDetailThunk(...rest)), // 获取指定UUID的著录信息
	downloadMultipartResult: (...rest) => dispatch(downloadMultipartResultThunk(...rest)),
})

@connect(mapStateToProps, mapDispatchToProps)
class FileUploader extends Component {
	state = {
		filePath: '', // 文件路径，用于判断属于哪个文件夹
		uuid: '', // 用于判断文件是否属于同个文件夹
		reset: false,
		filesList: [],
		status: 0, // 步骤条所处阶段
		status1: 0, // 选择阶段(著录、入库)
		status2: 0, // 导入方式(独立文件、单一案卷、多案卷)
		allAmount: 0,
		disabled: false,
		firstUpload: false, // 用于判断是否已经完成首次上传文件
		showFinishBtn: false,
		showFormTable: false, // 是否展示参数选择表
		uploadDisabled: false, // 是否允许上传文件夹
		moreUpload: false, // 用于判断是第一次导入后接着进行的导入操作
		rootPath: '', // 选择的文件夹名
		chunkList: [],
		correctFiles: [], //上传的正确文件(剔除多余模板后)数组
		fileList: [], // 上传的文件(剔除多余模板前)数组
		mapUuidFileAmount: [], // 记录uuid以及使用该uuid的文件数量
		finishMark: 0, // 记录导入成功的文件组数
	}

	config = {
		name: 'file',
		directory: true,
	}

	// 上传文件后先获取总文件数
	onChange = res => {
		const { getAllAmount, resetAll } = this.props
		if (this.state.moreUpload) {
			resetAll()
		}

		this.setState({
			allAmount: res.fileList.length,
			firstUpload: true,
			filesList: [], // 清空已上传的文件
			showFinishBtn: false,
			rootPath: res.fileList[0].originFileObj.webkitRelativePath.split('/')[0],
		})

		// 计算除去模板文件以外的总文件数
		let fileAmount = res.fileList.length
		res.fileList?.forEach(item => {
			if (item.name.includes('统一信息') || item.name.includes('详细信息')) {
				fileAmount = fileAmount - 1
			}
		})

		getAllAmount(fileAmount)

		let { file, fileList } = res
		//console.log(res)
		this.setState({
			fileList,
		})
		if (file.size === 0) {
			return
		}
		let fileLength = fileList.length
		let times = 0
		// while 中的条件表示该文件正处于上传状态，此时需要移动到数组的最前面
		while (
			typeof fileList[fileList.length - 1]?.uid !== 'number' &&
			times < fileLength
		) {
			fileList.unshift(fileList.pop())
			times += 1
		}
	}

	// 上传文件夹后的处理
	uploadFiles = async files => {
		const { getDossierAmount } = this.props

		// 把文件一个个添加进来
		this.setState({
			filesList: [...this.state.filesList, files.file],
		})

		if (this.state.allAmount === this.state.filesList.length) {
			this.changeStatus_1()
			// resetAmount()
			this.setState({
				disabled: true,
				showFormTable: true,
			})

			// 文件数组添加uuid
			this.state.filesList.forEach(item => {
				let filePath = item?.webkitRelativePath.split('/')
				filePath.pop()
				filePath = filePath.join('/')

				const prePath = this.state.filePath
				const preUuid = this.state.uuid
				const newUuid = this.getUId() // 生成一个新的uuid

				// 判断前后上传的两个文件是否同属一个文件夹，是的话用同个uuid，否则用新生成的uuid
				let nowUuid = filePath === prePath ? preUuid : newUuid
				item.uuid = nowUuid

				if (nowUuid !== preUuid) {
					getDossierAmount()
					this.setState({
						mapUuidFileAmount: [
							...this.state.mapUuidFileAmount,
							{ uuid: nowUuid, fileAmount: 0, finishAmount: 0 },
						], // 创建一个uuid映射文件数量的对象
					})
				}

				this.setState({
					filePath,
					uuid: nowUuid,
				})
			})

			this.state.fileList.forEach(item => {
				let uuid = item.originFileObj.uuid
				let mapUuidFileAmount = this.state.mapUuidFileAmount
				mapUuidFileAmount.forEach(item => {
					// 统计每个uuid有多少个文件
					if (item.uuid === uuid) {
						item.fileAmount += 1
					}
				})
				this.setState({
					mapUuidFileAmount,
				})
			})
		}
	}

	// 点击导入后调用
	upload = async () => {
		const { noDossier, singleDossier, showChart, getAllFileInfo, getStatus } =
			this.props
		const { status1, status2, filePath } = this.state
		switch (status2) {
			case 1:
				noDossier()
				break
			case 2:
				singleDossier()
				break
			default:
				break
		}

		showChart()
		getStatus(status2)

		let { filesList, chunkList } = this.state

		const newUuid = this.getUId() // 生成一个新的uuid，在选择独立文件或单一案卷时作为共同的uuid使用
		const rootUUID = this.getUId()

		let correctFiles = []
		let excelList = [] // 用于备份全部模板excel
		let excelPathList = [] // 用于记录对应路径模板存在情况
		// 先循环,看是否需要剔除多余的模板文件
		filesList.forEach(item => {
			const fileName = item.name.split('.')[0]
			const filePathDepth = item.webkitRelativePath.split('/').length
			switch (status2) {
				case 1:
				case 2:
					// 判断是否是根目录的统一信息，不是则剔除
					if (fileName.includes('统一信息') && filePathDepth > 2) {
						break
					} else {
						item.uuid = newUuid
						correctFiles.push(item)
					}
					break
				// 多案卷不需要剔除多余的模板
				default:
					if (fileName.includes('统一信息')) {
						// 判断是否是模板文件，是就添加进备份数组
						excelList.push(item)
					}
					excelPathList.push({
						path: item.webkitRelativePath.split('/').slice(0, -1).join('/'),
						excel: item.webkitRelativePath
							.split('/')
							.pop()
							.includes('统一信息')
							? true
							: false,
						uuid: item.uuid,
					}) // 对应路径一开始默认没有模板
					correctFiles.push(item)
					break
			}
		})

		// 如果选择独立文件或单一案卷,则文件都用相同uuid
		if (status2 === 1 || status2 === 2) {
			this.setState({
				mapUuidFileAmount: [
					{ uuid: newUuid, fileAmount: correctFiles.length, finishAmount: 0 },
				],
			})
		} else if (status2 === 3) {
			//如果选择多案卷，则要进行单独处理，若某个文件夹内没有统一信息模板，就使用上一层的模板，如果上一层没有就用上
			let { mapUuidFileAmount } = this.state
			// 对象数组去重
			excelPathList = excelPathList.filter(function (item, index, self) {
				return self.findIndex(el => el.path === item.path) === index
			})

			// 判断要上传的文件中各路径下的模板存在情况
			excelPathList.forEach(item1 => {
				correctFiles.forEach(item2 => {
					if (item2.webkitRelativePath === `${item1.path}/${item2.name}`) {
						if (item2.name.includes('统一信息')) {
							item1.excel = true
						}
					}
				})
			})

			// 多案卷情况下，若某个文件夹内没有统一信息模板，就使用上一层的模板，如果上一层没有就用上上一层，以此类推
			excelPathList.forEach(item => {
				let path = item.path
				if (!item.excel) {
					for (let i = 0; i < path.split('/').length - 1 && !item.excel; i++) {
						//判断文件夹路径深度，看最多能搜索模板到第几层，直到根目录或已找到可用的模板文件为止
						excelList.forEach(item2 => {
							if (
								item2.webkitRelativePath.includes(
									`${path
										.split('/')
										.slice(0, -(i + 1))
										.join('/')}/统一信息`
								)
							) {
								// 判断当前目录下是否有模板文件，有就直接使用
								let changeUuidFile = new File([item2], item2.name, {
									// 复制一份模板文件，否则引用的是同个地址
									type: item2.type,
									lastModified: Date.now(),
								})
								changeUuidFile.uuid = item.uuid
								correctFiles.push(changeUuidFile) // 将代替用的上一层模板替换uuid后添加进要上传的文件列表中
								mapUuidFileAmount.forEach(item3 => {
									// 判断当前路径下的模板是否是来自上层文件夹，是的话该uuid对应的文件数+1
									if (item3.uuid === item.uuid) {
										item3.fileAmount += 1
									}
								})
								item.excel = true // 标志该路径文件夹已有模板
							}
						})
					}
				}
			})

			correctFiles.sort((a, b) => {
				// 对文件数组进行排序，相同uuid的放一起
				return a.uuid > b.uuid ? 1 : -1
			})

			this.setState({
				mapUuidFileAmount,
			})
		}

		getAllFileInfo(correctFiles, status2)
		this.setState({
			showFormTable: false,
			disabled: false,
		})

		let noChunkList = [] // 存储不使用分片的文件
		// 文件分片大于3时采用断点续传，否则直接上传
		correctFiles.forEach(item => {
			let tempchunkList = createFileChunk(item)
			tempchunkList.forEach(chunk => {
				chunk.fileName = item.name
				chunk.path = item.webkitRelativePath
			})
			tempchunkList.length >= 3
				? chunkList.push(tempchunkList)
				: noChunkList.push(item)
		})

		// 给切片添加信息
		for (let index = 0; index < chunkList.length; index++) {
			for (let a = 0; a < correctFiles.length; a++) {
				if (chunkList[index][0].path === correctFiles[a].webkitRelativePath) {
					// 判断分片文件和文件列表中是否是同一个文件
					let md5 = await getFileMd5(chunkList[index])
					for (let i = 0; i < chunkList[index].length; i++) {
						chunkList[index][i].uuid = correctFiles[a].uuid
						chunkList[index][i].type = correctFiles[a].type
						chunkList[index][i].wholeMd5 = md5
					}
					await multiUploadThunk2(
						chunkList[index],
						correctFiles[a].webkitRelativePath,
						newUuid,
						status1,
						status2,
						this.handleSuccess,
						chunkList[index][0].wholeMd5,
						rootUUID
					) // 上传一个文件的所有切片
				}
			}
		}

		this.setState({
			filesList: [],
			uploadDisabled: true,
			correctFiles,
			chunkList: [],
		})

		// 上传所有非分片文件
		noChunkMultiUpload(
			noChunkList,
			newUuid,
			status1,
			status2,
			this.handleSuccess,
			rootUUID
		)
	}

	handleNoExcel = () => {
		let { fileList, status2 } = this.state
		// 用于判断每个文件夹是否有缺少模板的情况
		let pathList = fileList.map(item => {
			return item.originFileObj.webkitRelativePath
		})

		let newPathList = pathList.map(item => {
			item = item.split('/')
			item.pop()
			return item.join('/').toString()
		})

		if (status2 === 1 || status2 === 2) {
			newPathList.splice(1, newPathList.length - 1)
		}

		newPathList = new Set(newPathList)
		// 用于判断每个案卷是否有要求的模板文件
		let checkExcelList = []
		newPathList.forEach(item1 => {
			checkExcelList.push({ path: item1, excel: false })
			pathList.forEach(item2 => {
				if (item2.includes(`${item1}/统一信息`)) {
					checkExcelList.forEach(item3 => {
						item3.excel = item3.path === item1 ? true : item3.excel
					})
				}
			})
		})

		this.setState({
			checkExcelList,
		})
	}

	// 每上传成功一个文件(该文件所有切片上传成功)后调用
	handleSuccess = (fileName, uuid) => {
		let { mapUuidFileAmount } = this.state
		const { addSuccessAmount } = this.props

		mapUuidFileAmount.forEach(item => {
			if (item.uuid === uuid) {
				item.finishAmount += 1 // 每有一个文件上传成功，该文件对应的uuid上传成功的文件数+1
				if (item.finishAmount === item.fileAmount) {
					this.autoDescriptionFunc(uuid) // 如果一个uuid对应的所有文件都上传成功，传入uuid，开始自动著录
				}
			}
		})

		// 判断文件是否是模板文件，如果非模板文件，则文件上传成功数+1
		if (!fileName.includes('统一信息') && !fileName.includes('详细信息')) {
			addSuccessAmount()
		}
	}

	autoDescriptionFunc = uuid => {
		const { autoDescription, getAutoDescriptionDetail, getDetails } = this.props
		let { mapUuidFileAmount } = this.state
		let uuidAmount = mapUuidFileAmount.length // uuid数量
		autoDescription(uuid).then(res => {
			// 调用著录接口，传入uuid
			if (res.success) {
				getAutoDescriptionDetail(uuid).then(res => {
					// 著录成功后获取著录情况
					let { finishMark } = this.state
					if (res.data.length > 0) {
						getDetails(res.data[0])
					} else {
						getDetails()
					}
					finishMark += 1
					if (finishMark === uuidAmount) {
						finishMark = 0 // 一次批量著录之后，将已著录的文件夹数量重置为0
						this.setState(
							{
								showFinishBtn: true,
								uploadDisabled: false,
								moreUpload: true,
								finishMark,
								mapUuidFileAmount: [],
							},
							() => {
								updataRedDots()
							}
						)
					}

					this.setState({
						finishMark,
					})
				})
			}
		})
	}

	// 上传文件后改变步骤条状态
	changeStatus_1 = () => {
		this.setState({
			status: 1,
		})
	}

	changeStatus_2 = () => {
		this.setState({
			status: 2,
		})
	}

	// 获取"选择阶段"和"导入方式"的标识
	getStatus = (status1, status2) => {
		this.setState({
			status1,
			status2,
		})
	}

	getUId = () => {
		return uuidv4().split('-')[0]
	}

	onClick = e => {
		e.preventDefault()
	}

	render() {
		const {
			status,
			disabled,
			firstUpload,
			showFinishBtn,
			showFormTable,
			status1,
			rootPath,
			checkExcelList,
		} = this.state
		const { resetAll, downloadMultipartResult } = this.props
		return (
			<div style={{ position: 'relative' }}>
				<Dragger
					style={{
						position: 'relative',
						margin: '30px 0px',
						top: '28px',
						marginBottom: '-65px',
						marginLeft: '40px',
						zIndex: '100',
						width: '120px',
					}}
					{...this.config}
					onChange={this.onChange}
					beforeUpload={this.beforeUpload}
					customRequest={files => {
						this.uploadFiles(files)
					}}
					fileList={[]}
					disabled={this.state.uploadDisabled}>
					<p
						className='ant-upload-drag-icon'
						style={{
							position: 'absolute',
						}}></p>
					<p
						className='ant-upload-text'
						style={{
							position: 'relative',
							top: '-9px',
							fontSize: '15px',
							height: '0px',
						}}>
						{firstUpload ? '替换文件夹' : '选择文件夹'}
						<FolderAddOutlined
							style={{
								position: 'relative',
								top: '2px',
								marginLeft: '5px',
								fontSize: '20px',
							}}
						/>
					</p>
					<p className='ant-upload-hint'>
						{/* Support for a single or bulk upload. */}
					</p>
				</Dragger>
				<Status
					status={status}
					changeStatus_2={this.changeStatus_2}
					getStatus={this.getStatus}
					upload={this.upload}
					disabled={disabled}
					showFormTable={showFormTable}
					checkExcelList={checkExcelList}
					handleNoExcel={this.handleNoExcel}
				/>
				{rootPath && (
					<span
						style={{
							position: 'absolute',
							fontWeight: '700',
							top: '90px',
							left: '42px',
							width: '350px',
						}}>{`选择的文件夹：${rootPath}`}</span>
				)}
				<Button
					className='finish-btn'
					style={{ display: `${showFinishBtn ? 'block' : 'none'}` }}
					type='primary'
					onClick={() => {
						resetAll()
						setTimeout(() => {
							switch (status1) {
								case 0:
									history.push(
										linkTo.to('/workarea/myFiles/recordFiles')
									)
									break
								case 1:
									history.push(
										linkTo.to('/workarea/myFiles/prearchive')
									)
									break
								case 2:
									history.push(linkTo.to('/archived/archived'))
									break
								default:
									break
							}
						}, 1000)
						this.setState({
							status: 0,
							firstUpload: false,
							showFinishBtn: false,
							showFormTable: false,
						})
					}}>
					查看导入情况
				</Button>
				<Button
					className='finish-btn2'
					style={{ display: `${showFinishBtn ? 'block' : 'none'}` }}
					type='primary'
					onClick={() => {
						downloadMultipartResult()
					}}>
					下载著录日志
				</Button>
			</div>
		)
	}
}

export default FileUploader
