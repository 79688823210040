import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { connect } from 'react-redux'
import { message } from 'antd'
import { changePasswordThunk } from '../ActionCreator'
const formLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 10 },
}
// 表单项的单独配置，优先级高于表单项的统一配置
const formItemLayout = {
	labelCol: { span: 7 },
	wrapperCol: { span: 17 },
}
// PubForm 表单项配置数组
let formItems = [
	[
		{
			type: 'PasswordInput',
			name: 'oldPassword',
			label: '旧密码',
			autocomplete: true,
			placeholder: '请输入密码',
			layout: formItemLayout,
			span: 20,
		},
	],
	[
		{
			type: 'PasswordInput',
			name: 'newPassword',
			label: '新密码',
			autocomplete: true,
			placeholder: '请输入密码',
			layout: formItemLayout,
			rules: [
				{
					required: true,
				},
				{
					pattern: /^\w+$/,
					message: '格式：字母、数字及下划线',
				},
			],
			span: 20,
		},
	],
	[
		{
			type: 'PasswordInput',
			name: 'passwordConfirm',
			label: '确认密码',
			autocomplete: true,
			placeholder: '请输入密码',
			layout: formItemLayout,
			rules: [
				{
					required: true,
				},
				{
					pattern: /^\w+$/,
					message: '格式：字母、数字及下划线',
				},
			],
			span: 20,
		},
	],
]

const mapDispatchToProps = dispatch => ({
	changePassword: (...rest) => dispatch(changePasswordThunk(...rest)), // 修改用户密码
})

@connect(null, mapDispatchToProps)
class modal extends Component {
	state = {
		visible: false,
		title: '修改密码',
	}

	handleChangePassword = (formData, { finish, hide }) => {
		if (formData.newPassword === formData.oldPassword) {
			message.warning('“新密码”与“旧密码”一致')
			finish()
			return
		}
		if (formData.newPassword !== formData.passwordConfirm) {
			message.warning('“新密码”与“确认密码”不一致')
			finish()
			return
		}
		const { changePassword } = this.props
		changePassword(formData, { finish, hide })
	}

	render() {
		const { title } = this.state
		return (
			<PubModalForm
				title={title}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				onOk={this.handleChangePassword}
				//buttonList={buttonList}
				formDisabled={false}
				okText='提交'
				ref={this.props.modalRef} // 若隔层由外层通过props传递
				width={480} // 默认值900
				formProps={{
					// 表单的配置项
					name: 'UpdatePasswordModal',
					formItems,
					layout: formLayout,
				}}
			/>
		)
	}
}

export default modal
