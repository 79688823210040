import React, { PureComponent } from 'react'
import debounce from 'lodash/debounce'
import { Input, Radio, Form, Button, Spin, Empty, Row, Col, message, Divider } from 'antd'
import { searchAssginPeople } from './ActionCreator'

import { createAsyncTreeSelect } from '@/Public/PubForm/FormItem/Select'
import { departmentUrl } from '@/Utils/Urls'
import { treeUtil, planeTree } from '@/Utils'
import searchImg from '@/Static/search.png'
import './ExamineModal.less'

const departmentFlatTree = planeTree(
	JSON.parse(localStorage.getItem('department')),
	{},
	'children',
	'id',
	'departmentName'
)
const formRef1 = React.createRef(null)
const formRef2 = React.createRef(null)
class ExamineModal extends PureComponent {
	state = {
		loading: false,
		options: [],
		searchRange: 0,
	}

	componentDidMount() {
		this.props.getFormData(this.onSummit)
		formRef1.current.setFieldsValue({
			departmentId: this.props.departmentName,
		})
		if (this.props.departmentId) {
			this.onFinish({ departmentId: this.props.departmentId })
		}
	}

	config = {
		ajaxConfig: {
			type: 'get',
			url: departmentUrl.getDepartmentTree,
			isJson: true,
		},
		formatDataFunc: data =>
			treeUtil.changeKeys([data], {
				departmentName: 'title',
				id: 'value',
			}),
		onChange: value => {
			this.onFinish({ departmentId: value })
		},
	}

	onFinish = debounce(value => {
		this.setState({
			loading: true,
		})
		searchAssginPeople(value)
			.then(data => {
				this.setState(
					{
						options: data.map(({ name, departmentId, workId }) => ({
							label: `${name}-${departmentFlatTree[departmentId]}-${workId}`,
							value: workId,
						})),
					},
					() => {
						const { options, searchRange } = this.state // 目前可选的审核人列表(options) 以及 部门/个人(0/1, searchRange)
						if (searchRange === 0 && options.length === 0) {
							message.warning('该部门没有审核人可选，请选择其他部门或个人')
						}
						formRef2.current.setFieldsValue({
							workId: this.state.options[0]?.value,
						})
					}
				)
			})
			.finally(() => {
				this.setState({
					loading: false,
				})
			})
	}, 200)

	onSummit = () =>
		new Promise((resolve, reject) => {
			formRef2.current
				.validateFields()
				.then(value => {
					resolve(value)
				})
				.catch(err => {
					reject(err)
					const { options, searchRange } = this.state // 目前可选的审核人列表(options) 以及 部门/个人(0/1, searchRange)
					if (searchRange === 0 && options.length === 0) {
						message.error('该部门没有审核人可选，请选择其他部门或个人')
					} else {
						message.error('请填写表单数据')
					}
				})
		})

	chooseSearchRange = e => {
		this.setState({
			searchRange: e.target.value,
		})
	}

	render() {
		const { options, loading, searchRange } = this.state

		return (
			<>
				<div className='examine-modal-wrapper'>
					<h3>
						您正在对{this.props?.typeName}进行审核操作，请选择审核人并指派
					</h3>
					<Divider />
					<Form ref={formRef1} onFinish={this.onFinish}>
						<Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
							<Col>
								<Form.Item>
									<Radio.Group
										options={[
											{ label: '个人', value: 1 },
											{ label: '部门', value: 0 },
										]}
										onChange={this.chooseSearchRange}
										defaultValue={0}
									/>
								</Form.Item>
							</Col>
							{searchRange ? (
								<>
									<Col span={7}>
										<Form.Item name='workId' label='登陆账户'>
											<Input placeholder='请输入登陆账户' />
										</Form.Item>
									</Col>
									<Col span={7}>
										<Form.Item name='userName' label='姓名'>
											<Input placeholder='请输入姓名' />
										</Form.Item>
									</Col>
									<Col span={3}>
										<Form.Item>
											<Button
												type='primary'
												className='searchImgWrapper'
												htmlType='submit'>
												<img
													src={searchImg}
													className='searchImg'
													alt='搜索图标'
													title='查询'
												/>
											</Button>
										</Form.Item>
									</Col>
								</>
							) : (
								<Col span={7}>
									<Form.Item name='departmentId' label='部门'>
										{createAsyncTreeSelect(this.config, formRef1)}
									</Form.Item>
								</Col>
							)}
						</Row>
					</Form>
					<Form ref={formRef2}>
						<Form.Item
							label={'审核人'}
							name='workId'
							rules={[{ required: true, message: '请选择一个用户' }]}>
							<Spin spinning={loading}>
								{options.length ? (
									<Radio.Group
										options={options}
										defaultValue={options[0].value}
									/>
								) : (
									<Empty
										description={'暂无数据'}
										image={Empty.PRESENTED_IMAGE_SIMPLE}
									/>
								)}
							</Spin>
						</Form.Item>
						<Form.Item
							label={'指派原因'}
							name='message'
							rules={[{ required: true, message: '请填入指派原因' }]}>
							<Input.TextArea
								rows={4}
								placeholder='请填入指派原因'
								maxLength={1000}
							/>
						</Form.Item>
					</Form>
				</div>
			</>
		)
	}
}

export default ExamineModal
