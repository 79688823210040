export const linkTo = {
	topRoute: '/Manager',
	to(route) {
		return this.topRoute + route
	},
}

let routeMethod = function () {}
routeMethod.prototype = {
	// 处理上面的 ManagerRouterList， 引入每个页面的组件
	requireComponents(ManageRouterList) {
		const menusPic = require.context('@/Components/ContentIn/', true, /\.jsx$/)
		let cache = {} // 缓存对象：键为路径名，值为组件
		menusPic.keys().forEach(key => {
			// 新建界面中多媒体和文书用了同一个组件 这里需要特殊处理
			if (key === './NewConstruction/NewFiles/index.jsx') {
				cache['./NewConstruction/NewFiles-multiMedia/index.jsx'] = menusPic(key)
				cache['./NewConstruction/NewFiles-document/index.jsx'] = menusPic(key)
			} else {
				cache[key] = menusPic(key)
			}
		})
		let routerNow = []
		for (let n in ManageRouterList) {
			let manageRouterListSet = []
			if (ManageRouterList[n].children) {
				manageRouterListSet = ManageRouterList[n].children
			} else {
				manageRouterListSet[0] = ManageRouterList[n]
			}
			while (1) {
				routerNow = []
				// eslint-disable-next-line no-loop-func
				manageRouterListSet.map(item => {
					// 如果component字段是空字符串，则自动引入文件
					let array = item.path.split('/').slice(1)
					// 把item的一些首字母转成大写，这样能和文件夹的名称匹配，所以文件夹的命名都是大写开头
					for (let count in array) {
						array[count] =
							array[count][0].toUpperCase() + array[count].slice(1)
					}
					// 若果是非收藏路由
					if (item.path.indexOf('workStation') === -1) {
						let theComponent = cache['./' + array.join('/') + '/index.jsx']
						theComponent && (theComponent = theComponent.default)
						item.component = theComponent
					}
					if (item.children) routerNow.push(...item.children)
					return true
				})
				if (routerNow.length === 0) break
				manageRouterListSet = routerNow
			}
		}
		return ManageRouterList
	},
}

export const RouteMethod = new routeMethod()

export const rooteRouter = {
	workarea: '/workarea',
}

export const secRouter = {
	myFiles: rooteRouter.workarea + '/myFiles',
	myApplication: rooteRouter.workarea + '/myApplication',
	myApproval: rooteRouter.workarea + '/myApproval',
	system: rooteRouter.workarea + '/system',
}
