import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import Enum, { dossierEnum } from '@/Utils/Enum'
import { expiredFilesColumns } from '@/Public/PubTable/pageColumns'
import { dossierUrl, departmentUrl } from '@/Utils/Urls'
import { treeUtil } from '@/Utils'
import { connect } from 'react-redux'
import { Button } from 'antd'
import {
	addOldDetailsAction,
	finishApplyFixAction,
	getDetailThunk,
	inititalPubArchiveAction,
} from './ActionCreator'
import { pub_getAllTableData } from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator.js'

const markName = 'SelectFixFile' // redux的标识
const searchType = new Enum(['案卷', '文件', '全部']) // 档案搜索类型
const mapStateToProps = state => ({
	pub_OldDetail: state.pubForm.pub_fixApplyDetails.old_details, // 获取修正前数据
})
const mapDispatchToProps = dispatch => {
	return {
		addOldDetails(data, current) {
			dispatch(addOldDetailsAction(data, current))
		},
		finishApplyFix() {
			dispatch(finishApplyFixAction())
		},
		getDetail: (...rest) => dispatch(getDetailThunk(...rest)), // 详情
		// 表格
		getAllTableData(...rest) {
			return dispatch(pub_getAllTableData(...rest)) // 把获取到的表格所有数据发送到store
		},
		// 初始化案卷
		inititalPubArchive: (markName, pubArchive) =>
			dispatch(inititalPubArchiveAction(markName, pubArchive)),
	}
}
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class SelectFixFile extends Component {
	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{
				type: 'Select',
				name: 'type',
				label: '类型',
				options: searchType.toOptions(),
				initialValue: searchType['全部'],
			},
			{
				type: 'AsyncTreeSelect',
				name: 'categoryCodeId',
				label: '分类',
				ajaxConfig: {
					url: dossierUrl.getDossierType,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys(data.children, {
						typeName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'DatePicker',
				name: 'yearNumber',
				label: '年度',
				picker: 'year',
				width: '165px',
			},
			{
				type: 'ParticiplesSelect',
				name: 'topic',
				label: '题名',
			},
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId',
				label: '部门',
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'Select',
				name: 'retentionPeriod',
				label: '保管期限',
				width: 200,
				options: dossierEnum.retentionPeriod.toOptions(), // 档案保管期限
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'post',
			url: dossierUrl.getDossierList,
			ContentType: 'application/json',
		},
	}

	returnButton = record => {
		// 返回按钮数据
		return (
			<Button
				className='btn-info'
				onClick={() => {
					this.handleFixApply(record)
				}}>
				修正
			</Button>
		)
	}

	handleFixApply = record => {
		const { pub_OldDetail, addOldDetails, finishApplyFix } = this.props
		this.props.getDetail(record).then(data => {
			// 请求详情数据
			if (!record.type) {
				data.archiveDetail.forEach(archiveData => {
					const { fileToken, fileType } = archiveData
					// 设置预览图
					archiveData['preview'] = {
						type: 1,
						fileData: {
							fileToken,
							fileType,
						},
					}
					// 每个来个key
					archiveData['key'] = archiveData.id
				})

				this.props.inititalPubArchive('FixDossierDetails', {
					filesData: data.archiveDetail || [],
					archiveData: data,
				})
			}
			Object.keys(pub_OldDetail).length !== 0 && finishApplyFix()
			addOldDetails(data, 1)
		})
	}

	// 设置表单值
	setTableData = showTableList => {
		let data = {}
		data['data'] = showTableList
		data.data.forEach((item, n) => {
			// 让每一个表格数据都获得key
			const { fileToken, fileType } = item
			item['fileData'] = { fileToken, fileType }
			item['startIndex'] = n
			item.key = item.id || item.archiveId
		})

		data.pageData = {
			// 配置表格的页码器
			pageSize: data.size || 10,
			current: data.page || 1,
			total: data.total,
		}
		this.props.getAllTableData(data, 'FixDossierDetails') // 把表格数据发送到store
	}

	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 */
	changeSearchLimit = data => {
		data['status'] = dossierEnum.status['已归档'] //  档案的状态
		data['yearNumber'] && (data['yearNumber'] = +data['yearNumber'])
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = res => {
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}

	render() {
		return (
			<Fragment>
				<WrappedTable>
					<SearchForm
						name='expiredFiles-search-form'
						formItems={this.searchFormConfig}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
					/>
					<BaseTable
						tableConfig={expiredFilesColumns}
						actions={this.actions}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
						notRowSelection // 取消多选
						operationButton={this.returnButton} // 自定义操作按钮
					/>
				</WrappedTable>
			</Fragment>
		)
	}
}

export default SelectFixFile
