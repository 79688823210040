import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Modal, Button, Checkbox, Space, message, Radio } from 'antd'
import { injectUnmount } from '@/Utils'
import { exportDossier } from './ActionCreators'
import { exportExcelZip } from '@/Public/PubArchive/ActionCreators'

const Item = Form.Item

const mapStateToProps = state => {
	return {
		Selections: state.pubTable.pub_rowSelection, // 获取选中数据
	}
}
const mapDispatchToProps = dispatch => {
	return {}
}

/**
 * 以图识图按钮
 */
@connect(mapStateToProps, mapDispatchToProps)
@injectUnmount
class ExportButton extends Component {
	// fileName = [{ 1: "封面" }, { 2: "卷内考备表" }, { 3: "卷内文件目录" }] 海珠区删除
	fileType = [
		{ type: 'json', value: 0 },
		{ type: 'excel', value: 1 },
	]

	state = {
		isShowExport: false,
	}

	handleShow = () => {
		const { Selections } = this.props
		if (Selections.Archived.length === 0) {
			Modal.error({
				content: '请选择至少一项案卷后再进行操作',
				maskClosable: true,
			})
			return
		} else if (
			!Selections.Archived.every(item => {
				return item.type === 0
			})
		) {
			Modal.error({
				content: '选项中包含文件，不能导出！',
				maskClosable: true,
			})
			return
		}
		this.setState({
			isShowExport: true,
		})
	}

	// 关闭模态框
	handleCancel = () => {
		this.setState({
			isShowExport: false,
			value: -1,
		})
	}

	onOk = () => {
		const { value } = this.state
		const { Selections } = this.props
		if (value === -1) {
			//console.log(111)
			message.info('请选择至少一项后再进行操作')
			return
		}
		const dossierIds = [
			...Selections.Archived.map(item => {
				return item.id
			}),
		]
		// const fileName = value.length > 1 ? '案卷信息' : this.fileName[value - 1][value];海珠区删除
		const fileName = '案卷信息'
		const fileType = this.fileType[value]['value']
		exportExcelZip(dossierIds, fileName, fileType)
		this.setState({
			isShowExport: false,
			value: -1,
		})
	}

	// 改变模态框选项
	onChange = e => {
		// this.setState({
		//   value: [...checkedValues],
		// });海珠区删除
		this.setState({
			value: e.target.value,
		})
	}

	render() {
		const { isShowExport, value } = this.state
		return (
			<Item>
				{
					<Button
						type='primary iconfont-buttons'
						title='导出'
						className='print-export'
						onClick={this.handleShow}
						style={{ marginLeft: '5px' }}>
						&#xe8c6; 导出
					</Button>
				}
				{
					<Modal
						title='请选择导出格式'
						visible={isShowExport}
						onOk={() => {
							this.onOk()
						}}
						onCancel={this.handleCancel}>
						{/* <Checkbox.Group onChange={this.onChange} value={value}>
            <Space direction="vertical">
              {this.fileName.map((item, index) => {
                return (
                  <Checkbox key={item} value={index + 1}>
                    {item[index + 1]}
                  </Checkbox>
                );
              })}

            </Space>
          </Checkbox.Group> 海珠区删除*/}
						<Radio.Group onChange={this.onChange} value={value}>
							{this.fileType.map((item, index) => {
								return (
									<Radio key={index} value={index}>
										{item.type}
									</Radio>
								)
							})}
						</Radio.Group>
					</Modal>
				}
			</Item>
		)
	}
}

export default ExportButton
