import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button, message, Modal, Space, Tag, Typography } from 'antd'
import {
	ExclamationCircleOutlined,
	DeleteOutlined,
	VerticalAlignTopOutlined,
	VerticalAlignBottomOutlined,
	PlusOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { themeManageColumns } from '@/Public/PubTable/pageColumns'
import {
	getMyThemeThunk,
	deleteThemeThunk,
	setNumForThemeThunk,
	cancelTopThemeThunk,
	pushRoutersAction,
} from './ActionCreators'
import { collectedFileUrl, departmentUrl } from '@/Utils/Urls'
import { treeUtil } from '@/Utils'

import NewThemeModal from '@/Public/ButtonList/NewThemeModal.jsx'
import Enum from '@/Utils/Enum'
import { authUtil } from '@/Utils'

import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import EventBus from '@/Utils/pubsub'

import './index.less'

const { confirm } = Modal
const { Title, Text } = Typography
const { withAuth } = authUtil

const markName = 'ThemeManage' //redux标识
const themeStatusType = new Enum({ 0: '待征集', 1: '征集中', 2: '结束' })
const themeModalRef = React.createRef(null)

const mapStateToProps = state => ({
	selectedFiles: state.pubTable.pub_rowSelection.ThemeManage,
})
const mapDispatchToProps = dispatch => ({
	getMyTheme: (...rest) => dispatch(getMyThemeThunk(...rest)),
	deleteTheme: (...rest) => dispatch(deleteThemeThunk(...rest)),
	setNumForTheme: (...rest) => dispatch(setNumForThemeThunk(...rest)), //置顶主题
	cancelTopTheme: (...rest) => dispatch(cancelTopThemeThunk(...rest)), //取消置顶
	pushRouters: route => dispatch(pushRoutersAction(route)),
})

@WrappedTable.create(markName)
@connect(mapStateToProps, mapDispatchToProps)
class ThemeManage extends PureComponent {
	state = {
		theme: {},
		visible: false,
	}

	searchFormConfig = {
		formItems: [
			{
				type: 'Extra',
				name: 'buttonList',
				render: saerchFormRef => {
					return (
						<Space>
							{withAuth(
								<Button
									onClick={this.deleteTheme}
									type='danger'
									title='删除主题'>
									<DeleteOutlined />
								</Button>,
								collectedFileUrl.addNewTheme
							)}
							{withAuth(
								<Button
									onClick={this.newTheme}
									title='新建主题'
									className='btn-success'>
									<PlusOutlined />
								</Button>,
								collectedFileUrl.addNewTheme
							)}
						</Space>
					)
				},
			},
			{
				type: 'ParticiplesSelect',
				name: 'topics',
				label: '主题题名',
				inputWidth: '160px',
			},
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId',
				label: '部门',
				minWidth: '230px',
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'ParticiplesSelect',
				name: 'collectdObjectList',
				label: '征集对象',
				inputWidth: '135px',
			},
			{
				type: 'DatePicker',
				name: 'createTime',
				label: '创建时间',
				inputWidth: '135px',
				picker: 'year',
			},
			{
				type: 'Select',
				name: 'status',
				label: '状态',
				options: themeStatusType.toOptions(),
			},
		],
		ajaxConfig: {
			ajaxType: 'post',
			url: collectedFileUrl.searchTheme,
			ContentType: 'application/json',
		},
	}

	//表格操作按钮
	operationButton = record => {
		return (
			<>
				<Button
					onClick={() => {
						this.previewTheme(record)
					}}
					style={{ marginRight: '5px' }}>
					管理
				</Button>
				{themeStatusType[record.status] !== 0 &&
				themeStatusType[record.status] !== 2 ? (
					record.orderNum === null ? (
						<Button
							title='置顶'
							onClick={() => {
								this.setThemeTop(record)
							}}
							icon={<VerticalAlignTopOutlined />}
						/>
					) : (
						<Button
							title='取消置顶'
							onClick={() => {
								this.cancelTopTheme(record)
							}}
							icon={<VerticalAlignBottomOutlined />}
						/>
					)
				) : null}
			</>
		)
	}

	///刷新表格数据
	refreshTable = () => {
		const { pub_getAllData } = this.props
		pub_getAllData()
	}

	newTheme = () => {
		//拿到模态框原型，调用显示方法，传一个回调函数
		themeModalRef.current.show()
	}

	//删除主题
	deleteTheme = () => {
		const { selectedFiles } = this.props
		const ids = selectedFiles?.map(theme => {
			return theme.themeId
		})

		if (!ids.length) {
			message.info('请至少选择一项')
			return
		}

		confirm({
			icon: <ExclamationCircleOutlined />,
			content: '确定删除选中主题吗',
			onOk: () => {
				this.props
					.deleteTheme(ids)
					.then(() => {
						message.success('删除成功')
						this.refreshTable()
					})
					.catch(() => {
						message.error('删除失败')
					})
			},
		})
	}

	//编辑主题显示模态框
	modifyTheme = record => {
		//关掉预览框
		this.setState(
			{
				visible: false,
			},
			() => {
				//拿到模态框原型，调用显示方法，传一个回调函数
				themeModalRef.current.show(() => {
					//把受控表单的值填进去
					themeModalRef.current.formRef.setFieldsValue(record)
					EventBus.dispatchEvent('setRichValue', {
						htmlContent: record.content,
					})
				})
			}
		)
	}

	//置顶主题
	setThemeTop = record => {
		this.props.setNumForTheme([record.themeId]).then(() => {
			this.refreshTable()
		})
	}
	//取消置顶
	cancelTopTheme = record => {
		this.props.cancelTopTheme([record.themeId]).then(() => {
			this.refreshTable()
		})
	}

	//主题查看
	previewTheme = record => {
		// //console.log(record);
		this.setState({
			visible: true,
			theme: { ...record },
		})
	}

	//返回模态框底部按钮
	// 0: '未发布', 1: '征集中', 2: '已过期'
	modalFooter = () => {
		const { theme } = this.state
		const footerButton = []
		switch (themeStatusType[theme.status]) {
			case 0:
				footerButton.push(
					{
						text: '编辑',
						onClick: () => {
							this.modifyTheme(theme)
						},
					},
					{
						text: '发布',
						onClick: () => {
							this.newThemeModal.release(1)
							this.modalCancel()
						},
					}
				)
				break
			case 1:
				footerButton.push({
					text: '停止征集',
					onClick: () => {
						this.newThemeModal.release(0)
						this.modalCancel()
					},
				})
				break
			case 2:
				//判断当前时间是否到期,到期显示延期按钮,没到期显示激活按钮
				if (moment(new Date().getTime()).diff(theme.end) < 0) {
					footerButton.push({
						text: '激活',
						onClick: () => {
							this.newThemeModal.release(1)
							this.modalCancel()
						},
					})
				} else {
					footerButton.push({
						text: '延期',
						onClick: () => {
							//重新填写日期
							this.modifyTheme(theme)
						},
					})
				}
				break
			default:
				break
		}
		return footerButton.map((item, index) => {
			return (
				<Button
					key={item.text}
					type='primary'
					title={item.text}
					onClick={item.onClick}>
					{item.text}
				</Button>
			)
		})
	}

	//跳转到资料上传页面
	JumpUploadPage = () => {
		history.push(`/collectFiles?${this.state.theme.themeId}?1`)
	}

	//关闭模态框
	modalCancel = () => {
		this.setState({ visible: false })
	}

	// 修改状态样式 渲染主题列
	changeColumns = () => {
		const colorArr = ['blue', 'green', 'red'] //蓝色未发布,绿色征集中,红色已过期
		themeManageColumns.forEach(item => {
			if (Object.keys(item)[0] === 'status') {
				item.render = text => {
					let color = colorArr[themeStatusType[text]]
					return <Tag color={color}>{text}</Tag>
				}
			} else if (Object.keys(item)[0] === 'topic') {
				item.render = text => {
					return (
						<Button
							type='link'
							className='theme-topic-btn'
							onClick={this.themeTopicClick}>
							{text}
						</Button>
					)
				}
			}
		})
		return themeManageColumns
	}

	themeTopicClick = () => {
		history.push(linkTo.to('/collectedFiles/collectedFiles'))
		this.props.pushRouters({
			title: '资料',
			key: '/collectedFiles/collectedFiles',
		})
	}

	render() {
		const { theme, visible } = this.state
		return (
			<Fragment>
				<WrappedTable>
					<SearchForm
						name='myRecordFiles-search-form'
						formItems={this.searchFormConfig}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
					/>
					<BaseTable
						tableConfig={this.changeColumns()}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
						stylePointer={true}
						onRowEvent={true}
						withPreview={false}
						operationButton={this.operationButton}
					/>
				</WrappedTable>
				<NewThemeModal
					modalRef={themeModalRef}
					theme={theme}
					refreshTable={this.refreshTable}
					getNewThemeModal={ref => {
						this.newThemeModal = ref
					}}
				/>
				<Modal
					visible={visible}
					bodyStyle={{ width: '80vw' }}
					wrapClassName='preThemeModal'
					onCancel={this.modalCancel}
					footer={this.modalFooter()}>
					<Title level={2} style={{ textAlign: 'center' }}>
						{theme.topic}
					</Title>
					<div className='theme-message'>
						<Text type='secondary'>
							创建时间: {theme.createdTime?.replace('T', ' ')}
						</Text>
						<Text type='success'>创建部门: {theme.department || '无'}</Text>
						<Text type='secondary'>征集对象：{theme.collectedObject}</Text>
						<Text type='secondary'>
							征集时间：{theme.begin?.replace('T', ' ')} -{' '}
							{theme.end?.replace('T', ' ')}
						</Text>
					</div>
					<div className='themeContent'>
						<div
							className='theme-text-area'
							dangerouslySetInnerHTML={{ __html: theme.content }}
						/>
						<div className='theme-QRcode-area'>
							<Button
								type='text'
								onClick={this.JumpUploadPage}
								block={true}
								style={{ color: '#5B80D5' }}>
								点此上传资料
							</Button>
						</div>
					</div>
				</Modal>
			</Fragment>
		)
	}

	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 */
	changeSearchLimit = data => {
		if (data.createTime) {
			data.createTime = Number(data.createTime)
		}
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = res => {
		const themeArray = res.records.filter(item => item.deleted === 0)
		themeArray.forEach(item => {
			item.id = item.themeId
			//如果时间到期,状态不是结束,那就修改后台状态
			if (moment(new Date().getTime()).diff(item.end) > 0 && item.status !== 2) {
				this.setState(
					{
						theme: { ...item },
					},
					() => {
						this.newThemeModal.release(2)
					}
				)
			} else if (
				moment(new Date().getTime()).diff(item.end) < 0 &&
				item.status === 2
			) {
				//如果时间未到期,状态是结束,那就修改后台状态为待征集
				this.setState(
					{
						theme: { ...item },
					},
					() => {
						this.newThemeModal.release(0)
					}
				)
			}
			item.status = themeStatusType[item.status]
		})
		return {
			data: themeArray,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}
}

export default ThemeManage
