import React, { Component, Fragment } from 'react'
import { Button, message } from 'antd'
import {
	addApplyContentAction,
	fixApplyThunk,
	finishApplyFixAction,
	addNewDetailsAction,
	routeChangeAction,
	pushRoutersAction,
} from './ActionCreator'
import { PubForm } from '@/Public/PubForm'
import { connect } from 'react-redux'
import { applicationEnum } from '@/Utils/Enum'
import DrawerDetails from './DrawerDetails'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import _ from 'lodash'

const mapStateToProps = state => {
	return {
		pub_OldDetail: state.pubForm.pub_fixApplyDetails.old_details, // 获取修正前数据
		pub_NewDetails: state.pubForm.pub_fixApplyDetails.new_details, // 获取修正后数据
		fix_apply: state.pubForm.pub_fixApplyDetails.fix_apply,
	}
}
const mapDispatchToProps = dispatch => ({
	addApplyContent(data, current) {
		dispatch(addApplyContentAction(data, current))
	},
	finishApplyFix() {
		dispatch(finishApplyFixAction())
	},
	fixApply: (...rest) => dispatch(fixApplyThunk(...rest)), // 修正申请,
	addNewDetails(data, current) {
		dispatch(addNewDetailsAction(data, current))
	},
	// 路由跳转
	onChange(activeKey, history) {
		if (history) {
			history.push(linkTo.to(activeKey.key))
		} else {
			dispatch(routeChangeAction(activeKey.key))
		}
		dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
	},
	pushRouters(link) {
		return dispatch(pushRoutersAction(link))
	},
})

const difference = (object, base) => {
	function changes(object, base) {
		return _.transform(object, function (result, value, key) {
			if (!_.isEqual(value, base[key])) {
				result[key] =
					_.isObject(value) && _.isObject(base[key])
						? changes(value, base[key])
						: value
			}
		})
	}

	return changes(object, base)
}

@connect(mapStateToProps, mapDispatchToProps)
class Application extends Component {
	difObj = difference(this.props.pub_NewDetails, this.props.pub_OldDetail) // 判断不同的对象

	// PubForm 表单项配置数组
	formItems = [
		// 基本表单内容
		[
			{
				type: 'Extra',
				name: 'ButtonList', // 按钮组，用于进行按钮点击事件
				render: formRef => (
					<div className='fix_button_list'>
						<Button
							onClick={() => {
								// 申请表上一步后数据的保存
								this.props.addApplyContent(
									formRef.getFieldsValue(true),
									1
								)
							}}>
							上一步
						</Button>
					</div>
				),
				span: 23, // span：23代表无边框表单组件
			},
		],
		[
			{
				type: 'Extra',
				name: 'Title',
				render: () => <h1>修正申请表</h1>,
				span: 23, // span：23代表无边框表单组件
			},
		],
		[
			{
				type: 'Input',
				name: 'applyCode',
				label: '申请编号',
				disabled: true,
				bordered: false,
				span: 6,
				rules: [{ required: false }],
			},
			{
				type: 'Input',
				name: 'id',
				label: 'ID',
				span: 0,
				disabled: true,
				bordered: false,
				rules: [{ required: false }],
			},
			{
				type: 'Select',
				name: 'status',
				label: '处理状态',
				options: applicationEnum.status.toOptions(), // 申请的处理状态
				span: 6,
				disabled: true,
				bordered: false,
				rules: [{ required: false }],
			},
			{
				type: 'Input',
				name: 'applyUser',
				label: '申请人',
				span: 6,
				disabled: true,
				bordered: false,
				rules: [{ required: false }],
			},
			{
				type: 'DatePicker',
				name: 'createTime',
				label: '申请时间',
				span: 6,
				disabled: true,
				bordered: false,
				rules: [{ required: false }],
			},
		],
		[
			{
				type: 'TextArea',
				name: 'fixContent',
				label: '修改详情',
				colclassname: 'end-table',
				maxLength: 1500,
				showCount: true,
			},
		],
		[
			{
				type: 'Extra',
				name: 'ButtonFix',
				render: () => (
					<DrawerDetails
						drawerKey={'fixApply'}
						difObj={this.difObj}
						difference={difference}
					/>
				),
				span: 22, // span:22 代表把该表单组件放在上个表格的右下角
			},
		],
		[
			{
				type: 'TextArea',
				name: 'applyReason',
				label: '申请原因',
				maxLength: 1500,
				showCount: true,
			},
		],
		[
			{
				type: 'Extra',
				name: 'ButtonSumbit', // 按钮组，用于进行按钮点击事件
				render: formRef => (
					<div>
						<Button
							className='temporary-storage'
							onClick={() => {
								formRef.validateFields().then(() => {
									let data = formRef.getFieldValue()
									data['operateStatus'] = 0
									this.submitFixApplication(data)
								})
							}}>
							暂存
						</Button>

						<Button
							type='primary'
							onClick={() => {
								if (this.isFixDetails()) {
									formRef.validateFields().then(() => {
										const formObj = formRef.getFieldValue()
										if (
											formObj.applyReason.trim() &&
											formObj.fixContent.trim()
										) {
											let data = formRef.getFieldValue()
											data['operateStatus'] = 1
											this.submitFixApplication(data)
										} else {
											message.destroy()
											message.warn('不能提交空的内容')
										}
									})
								} else {
									message.destroy()
									message.warn('没有新的修正内容')
								}
							}}>
							提交
						</Button>
					</div>
				),
				span: 22, // span:22 代表把该表单组件放在上个表格的右下角
			},
		],
	]

	// 提交修正申请
	submitFixApplication = data => {
		const link = {
			title: '修正申请',
			key: '/workarea/myApplication/correct',
		}
		const {
			// redux上的信息
			pub_NewDetails,
			// 修改redux上的信息
			addApplyContent,
			finishApplyFix,
			// 修正申请
			fixApply,
			// 路由跳转
			onChange,
			pushRouters,
		} = this.props
		// 在第三页存放数据
		addApplyContent(data, 2)
		const allData = pub_NewDetails // 修正的基本数据
		const archive = {
			categoryCodeId: allData.categoryCodeId,
			confidentialLevel: allData.confidentialLevel,
			departmentId: allData.departmentId,
			id: allData.id,
			keyWord: allData.keyWord,
			retentionPeriod: allData.retentionPeriod,
			seeNumber: allData.seeNumber,
			topic: allData.topic,
		}
		// 整理请求数据
		const newRecord = {
			applyReason: data.applyReason,
			fixContent: data.fixContent,
			operateStatus: data.operateStatus, // 状态标识
		}
		if (allData.type) {
			// 如果是文件则按内容存放
			archive.thumbnailFileId = allData.thumbnailFileId
			archive.fileId = allData.fileId

			newRecord.archive = archive
			const { metaData } = allData // 元数据

			if (metaData) {
				// 如果有元数据新信息 newRecord对象就再加一项fileMetadata
				let metadataList = []
				for (let key in metaData) {
					// 给metaData转换形式
					metaData[key] &&
						metadataList.push({
							metadataId: parseInt(key),
							metadataValue: metaData[key],
						})
				}
				newRecord.fileMetadata = metadataList
			}
			// 没有元数据则直接传入 发出请求，如果成功则清空跳到第一页，如果失败则跳到第二页修改
			fixApply(newRecord)
				.then(() => {
					finishApplyFix()
					onChange(link, history) // 修改路由
					pushRouters(link) //添加分页栏
				})
				.catch(() => {
					addApplyContent(data, 1)
				})
		} else {
			// 如果是案卷则按内容存放
			// 档案id组
			const archiveIds = allData.archiveDetail.map(archiveItem => archiveItem.id)
			const dossier = archive
			newRecord.dossierDetail = { dossier, archiveIds }
			// 发出请求，如果成功则清空跳到第一页，如果失败则跳到第二页修改
			fixApply(newRecord)
				.then(() => {
					finishApplyFix()
					onChange(link, history) // 修改路由
					pushRouters(link) //添加分页栏
				})
				.catch(() => {
					addApplyContent(data, 1)
				})
		}
	}

	// 判断是否有修正内容
	// metaData,fixContent都是{}则返回fasle表示没有修正内容
	isFixDetails = () => {
		const { metaData, ...fixContent } = this.difObj
		const { type } = this.props.pub_OldDetail
		// 案卷没有元数据 type=0为案卷type=1为文件
		if (type === 0) {
			return JSON.stringify(fixContent) !== '{}'
		} else {
			return (
				JSON.stringify(metaData) !== '{}' || JSON.stringify(fixContent) !== '{}'
			)
		}
	}

	render() {
		return (
			<Fragment>
				<PubForm
					ref={this.formRef}
					formItems={this.formItems}
					getInstance={formRef => {
						this.formRef = formRef
						this.formRef.setFieldsValue(this.props.fix_apply)
					}}
					name={'FixForm'}
					title='修正申请表'
				/>
			</Fragment>
		)
	}
}

export default Application
