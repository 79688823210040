import { Modal, message } from 'antd'
import { fixApply, correctApprovalUrl, filesUrl } from '@/Utils/Urls'
import { ADD_FIXDATA, CHANGE_FIXDATA_STATUS } from '@/Redux/ActionTypes/FixDetail'
import Enum from '@/Utils/Enum'
import _fetch from '@/Utils/Fetch'
import { ROUTE_CHANGE } from '@/Redux/ActionTypes/Route'
// 更新红点函数
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
// 消息提示
export const tips = {
	CHOOSE_AT_LEAST_ONE: '请选择至少一项后再进行操作',
	RECALL_SUCCESS: '撤回成功',
	DELETE_SUCCESS: '删除成功',
	VERIFY_SUCCESS: '审核成功',
	SUBMIT_SUCCESS: '提交成功',
	UPDATE_SUCCESS: '保存成功',
	ONLY_RECALL_APPROVE_STATUS: '只能对“审批状态”下的修正申请进行撤回',
	ONLY_VERIFY_APPROVE_STATUS: '只能对“审批状态”下的修正申请进行审核',
}

const verifyStatusEnum = new Enum(['拒绝', '同意', '回退'])

// 添加修正申请表详情
export const addFixDataAction = (data, category) => {
	return {
		type: ADD_FIXDATA,
		data: data,
		category: category, // 申请
	}
}

// 修改修正申请表状态
export const changeFixDataStatusAction = buttonType => {
	return {
		type: CHANGE_FIXDATA_STATUS,
		buttonType: buttonType, // 按钮详情
	}
}

// 删除tab
export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 获取修正审批详情列表
 * @param {Number} id 申请表id
 */
export const getVerifyDetailThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: correctApprovalUrl.getVerifyDetail,
			data: {
				id,
			},
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 撤回申请
 * @param {Array<Number>} ids 要撤回的申请id数组
 */
export const recallApplyThunk = ids => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '撤回修正申请',
			content: '您正在对当前申请进行撤回操作，是否继续？',
			onOk: () => {
				_fetch({
					url: correctApprovalUrl.recallApply,
					data: ids,
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.RECALL_SUCCESS)
						resolve()
					}
				})
			},
		})
	})

/**
 * 删除申请
 * @param {Number} id 申请id
 */
export const deleteFixApplyThunk = id => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '删除修正申请',
			content: '您正在对当前申请进行删除操作，是否继续？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: fixApply.deleteFixApply,
					data: id,
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.DELETE_SUCCESS)
						updataRedDots()
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 档案修正审核
 * @param {Number} applyId 申请表id
 * @param {String} reviewResult 审核意见
 * @param {Number} status 0-拒绝 1-同意 2-回退
 */
export const verifyApplyThunk =
	({ applyId, reviewResult, status }) =>
	() =>
		new Promise((resolve, reject) => {
			Modal.confirm({
				maskClosable: true,
				title: '审核修正申请',
				content: `您正在对当前申请进行审核操作,  \t\t\n
              审核意见: ${reviewResult},   \t\t\n
              审核状态:${verifyStatusEnum[status]}  \t\t\n
              是否继续？`,
				okType: 'danger',
				onOk: () => {
					_fetch({
						url: correctApprovalUrl.verifyApply,
						data: {
							applyId,
							reviewResult,
							status,
						},
						type: 'post',
						isJson: true,
					}).then(res => {
						if (res && res.data.code === 200) {
							message.success(tips.VERIFY_SUCCESS)
							updataRedDots()
							resolve(res.data.data)
						} else {
							reject()
						}
					})
				},
			})
		})

/**
 * 提交申请
 * @param {Number} id 申请id
 */
export const submitFixApplyThunk = id => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '提交修正申请',
			content: '您正在对当前申请进行提交操作，是否继续？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: fixApply.submitFixApply,
					data: {
						id,
					},
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.SUBMIT_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 编辑申请
 * @param {object} data 申请数据
 */
export const updateFixApplyThunk = data => () =>
	new Promise(resolve => {
		Modal.confirm({
			zIndex: 2000,
			maskClosable: true,
			title: '编辑修正申请',
			content: '确定保存？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: fixApply.updateFixApply,
					type: 'post',
					isJson: true,
					data,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.UPDATE_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 获取元数据结构树
 * @param {Number} id 申请表id
 */
export const getMetaStructTreeThunk = formItems => () =>
	new Promise(resolve => {
		_fetch({
			url: filesUrl.getFileMetadataStructTree,
			type: 'get',
		})
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data.data)
				}
			})
			.catch(err => {
				//console.log('获取元数据结构树失败' + err)
			})
	})
