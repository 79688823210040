import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import { connect } from 'react-redux'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { MetaDataManagementColumns } from '@/Public/PubTable/pageColumns'
import { metaDataManagementUrl } from '@/Utils/Urls.js'
import { Space, Button, Modal, Upload, message } from 'antd'
import AddWordModal from './AddWordModal'
import ChangeWordModal from './ChangeWordModal'

import {
	addMetaDataThunk,
	deleteMetaDataThunk,
	changeMetaDataChunk,
	tips,
} from './ActionCreators'
import xml2js from 'xml2js'
const detailModalRef = React.createRef(null) // 获取查看模态框表单实例
let addmodalRef = React.createRef(null)
let changemodalRef = React.createRef(null)
const markName = 'metaDataManagement' // redux的标识
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	addMetaData: (...rest) => dispatch(addMetaDataThunk(...rest)), //新增敏感词
	deleteMetaData: (...rest) => dispatch(deleteMetaDataThunk(...rest)), // 删除敏感词
	changeMetaData: (...rest) => dispatch(changeMetaDataChunk(...rest)), // 编辑敏感词
})
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class SensitiveWordsManagement extends Component {
	state = {
		record: null, // 当前操作（查看后入库、回退、加入收藏夹）的行数据
		recordIndex: -1, // 当前操作行数据在当前页数据的 index 值
	}
	//保存上传的文件的数组
	fileList = []
	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{ type: 'Input', name: 'metaName', label: '元数据：' },
			{
				type: 'Extra',
				name: 'metaDataManagementList',
				render: formRef => {
					return (
						<Space>
							<Button
								className='btn-success iconfont-buttons'
								title='新增'
								onClick={() => this.viewAddModal(null, -1)}>
								&#xe634;
							</Button>
							<Button
								className='btn-danger iconfont-buttons'
								title='删除'
								onClick={() => this.handleDeleteApply()}>
								&#xe816; 删除
							</Button>
							<Upload
								name='xmlFile'
								multiple={true}
								customRequest={this.uploadFiles} //接受文件信息
								style={{ marginTop: '20px' }}
								beforeUpload={this.handleBeforeUpload} //审核
								fileList={this.fileList} //保存文件的变量
								showUploadList={false} //不展示文件列表
								// 解决网页崩溃的问题，不能删掉
								previewFile={false}>
								<Button title='XML导入' className='btn-success'>
									XML导入
								</Button>
							</Upload>
						</Space>
					)
				},
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'post',
			url: metaDataManagementUrl.getMetaData,
			ContentType: 'application/json',
		},
	}

	/**
	 * 审核通过后，文件对象到这里执行函数
	 * @param {object} file 审核后的文件对象
	 */
	uploadFiles = async file => {
		let reader = new FileReader()
		const { addMetaData, pub_getAllData } = this.props //ajax

		reader.onload = async e => {
			const fileData = e.target.result // 这里是文件内容字符串
			const result = await xml2js.parseStringPromise(fileData)

			//console.log(result)

			//发送多条添加单个元数据的ajax
			for (let index = 0; index < result.data.metadataName.length; index++) {
				addMetaData({
					metadataName: result.data.metadataName[index],
					metadataType: result.data.metadataType[index],
				})
			}
		}
		reader.readAsText(file.file) //触发文件onload事件
		//延迟触发刷新
		setTimeout(() => {
			pub_getAllData()
		}, 1000)
	}

	/**
	 * 文件上传前首先触发的函数，判断大小
	 * @param {object} file 得到的文件对象
	 */
	handleBeforeUpload = file => {
		return new Promise((resolve, reject) => {
			if (file.size > 1024 * 1024 * 10) {
				message.destroy()
				message.err('文件过大!')
				reject()
			}
			if (file.type !== 'text/xml') {
				message.destroy()
				message.warning('请确保上传的文件是xml格式')
				reject()
			}
			resolve()
		})
	}

	changeSearchLimit = data => {
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = _res => {
		let res = new Object()
		res.records = _res
		//console.log(res)
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}

	// 检查表格选中数量是否大于一项，如果不满足条件则弹窗提示
	checkRowSelection = () => {
		return new Promise((resolve, reject) => {
			const { pub_rowSelection } = this.props
			const rowSelection = pub_rowSelection[markName] || []
			// 还未选中则不能操作
			if (!rowSelection.length) {
				reject(
					Modal.error({
						content: tips.CHOOSE_AT_LEAST_ONE,
						maskClosable: true,
					})
				)
			}
			// 返回所有选中项
			resolve(rowSelection)
		})
	}

	// 删除按钮
	handleDeleteApply = async record => {
		const {
			deleteMetaData, // 删除接口
			pub_getAllData,
		} = this.props
		// const { record } = this.state
		try {
			let applyIds = []
			// 如果 record 有内容那么通过模态框点击删除按钮
			if (record) {
				applyIds = [record.id]
			} else {
				// 否则是表格多项选中点击删除按钮
				const rowSelection = await this.checkRowSelection()
				applyIds = rowSelection.map(({ id }) => id)
			}
			deleteMetaData(applyIds).then(res => {
				pub_getAllData()
				detailModalRef.current?.hide()
			})
		} catch (err) {}
	}

	//添加 弹出|修改 模态框
	viewAddModal = (record, recordIndex) => {
		this.setState({ record, recordIndex }, addmodalRef.current.show)
	}

	//修改模态框
	viewChangeModal = (record, recordIndex) => {
		this.setState({ record, recordIndex }, changemodalRef.current.show)
	}

	// 关闭模态框后一些数据进行初始化
	hideCallback = () => this.setState({ record: null, recordIndex: -1 })

	//行尾操作按钮
	returnButton = (record, index) => {
		return (
			<Space>
				<Button onClick={() => this.viewChangeModal(record, index)} size='small'>
					修改
				</Button>
				<Button
					className='table-btn-danger'
					onClick={() => this.handleDeleteApply(record)}
					size='small'>
					删除
				</Button>
			</Space>
		)
	}
	render() {
		const { pub_getAllData, addMetaData, changeMetaData } = this.props
		const { record } = this.state

		return (
			<Fragment>
				<WrappedTable>
					<SearchForm
						name='expiredFiles-search-form'
						//搜索的选项
						//搜索限制（这里是无）
						//markName，每个table的唯一标识
						formItems={this.searchFormConfig}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
					/>
					<BaseTable
						//table的columns配置
						//actions不知道有啥用
						//搜索限制（无）
						//格式baseTable中返回的数据
						//table唯一标识
						//是否带有前面的预览行
						tableConfig={MetaDataManagementColumns}
						//自定义行尾操作按钮
						operationButton={this.returnButton}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
						withPreview={false}
					/>
					<AddWordModal
						modalRef={addmodalRef}
						addWord={addMetaData}
						pub_getAllData={pub_getAllData}
						// 获取表格数据的函数
						// 推测时路由注入components的时附加的
						record={record} // 表格详情信息
					/>
					<ChangeWordModal
						modalRef={changemodalRef}
						changeWord={changeMetaData}
						pub_getAllData={pub_getAllData}
						// 获取表格数据的函数
						// 推测时路由注入components的时附加的
						record={record} // 表格详情信息
					/>
				</WrappedTable>
			</Fragment>
		)
	}
}

export default SensitiveWordsManagement
