import React, { Component } from 'react'

export default class FileItem extends Component {
	render() {
		const { originNode, file, fileType, fileSubtype, renderFileItem } = this.props
		return (
			<div className='ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-picture'>
				<div className='ant-upload-list-item-info'>
					<span className='ant-upload-span'>
						<a
							className='ant-upload-list-item-thumbnail'
							href='#!'
							rel='noopener noreferrer'>
							{renderFileItem(fileType, fileSubtype, file)}
						</a>
						<a
							rel='noopener noreferrer'
							className='ant-upload-list-item-name'
							style={{ cursor: 'pointer' }}
							title={file.name}
							href='#!'>
							{file.name}
						</a>
						<span className='ant-upload-list-item-card-actions picture'>
							{
								originNode.props.children[0].props.children.props
									.children[1][1].props.children
							}
						</span>
					</span>
				</div>
			</div>
		)
	}
}
