import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm/index'
import BaseTable from '@/Public/PubTable/baseTable/index'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import Enum from '@/Utils/Enum'
import { connect } from 'react-redux'
import { Button, Space, message } from 'antd'
import { appraisalUrl } from '@/Utils/Urls.js'
import { typeUtil } from '@/Utils'
import _ from 'lodash'
import {
	deleteVerifyThunk,
	getVerifyDetailThunk,
	addAppraisalDataToRedux,
	tips,
} from './ActionCreators'
import { history } from '@/index'
import { appraisalSuperColumns } from '@/Public/PubTable/pageColumns'
import { routeChangeAction, pushRoutersAction } from './ActionCreators'
import CompositeSearchButton from '@/Public/CompositeSearch/CompositeSearchButton'
import { linkTo } from '@/Routes/ManageRouter'
import { dossierUrl } from '@/Utils/Urls'
import { dossierEnum } from '@/Utils/Enum'

const markName = 'Appraisal' // redux 的标识
const applyStatus = new Enum(['待鉴定', '完成', '暂存'])
const preRetentionPeriodType = new Enum(['永久', '30年', '10年'])
const otherFormItem = [
	{
		type: 'Select',
		name: 'status', // 状态
		label: '状态',
		placeholder: '请选择状态',
		options: dossierEnum.status.toOptions(),
		initialValue: dossierEnum.status.toOptions()[3].value,
		width: '150px',
		allowClear: false,
		disabled: true,
	},
]

const mapStateToProps = state => ({
	appraisalData: state.appraisalDetails.appraisalData,
})
const mapDispatchToProps = dispatch => ({
	deleteVerify: (...rest) => dispatch(deleteVerifyThunk(...rest)), // 删除鉴定申请
	getVerifyDetail: (...rest) => dispatch(getVerifyDetailThunk(...rest)), // 查看详情
	addAppraisalDataToRedux: (...rest) => dispatch(addAppraisalDataToRedux(...rest)),
	onChange(activeKey, history) {
		if (history) {
			history.push(linkTo.to(activeKey.key))
		} else {
			dispatch(routeChangeAction(activeKey.key))
		}
		dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
	},
	pushRouters(link) {
		return dispatch(pushRoutersAction(link))
	},
})
const { getAllVerifyList } = appraisalUrl
const appraisalLink = {
	title: '鉴定详情',
	key: '/workarea/commonComponents/appraisalDetails',
}

@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class index extends Component {
	state = {
		record: null, // 当前操作（查看后入库、回退、加入收藏夹）的行数据
		recordIndex: -1, // 当前操作行数据在当前页数据的 index 值
		verfiyDetailData: null, // 查看里的申请数据
	}

	// 查看用户权限
	searchFormConfig = {
		formItems: [
			{
				type: 'Input',
				name: 'applyUserName',
				label: '申请人',
				placeholder: '请输入申请人',
			},
			{
				type: 'DatePicker',
				name: 'applyTime',
				label: '申请时间',
				placeholder: '请选择申请时间',
				width: 250,
			},
			{
				type: 'Select',
				name: 'status',
				label: '处理状态',
				placeholder: '请输入处理状态',
				options: applyStatus.toOptions(),
				initialValue: 0,
				minWidth: '165px',
			},
			{
				type: 'Extra',
				name: 'buttonList',
				render: formRef => {
					return (
						<Space style={{ marginTop: '3px' }}>
							<CompositeSearchButton
								title={`选择可鉴定文件或案卷`}
								btnTitle={<>&#xe634; </>}
								modalBtnTitle={'鉴定'}
								handleCompositeRecords={this.handleCompositeRecords}
								noOperationButton={true}
								successMsg={'请先编辑申请原因'}
								compositeSearchUrl={dossierUrl.getDossierList}
								withStatus={true}
								otherFormItem={otherFormItem}
								onRowEvent={true}
							/>
							<Button
								className='btn-danger iconfont-buttons'
								title='删除'
								onClick={() => {
									this.handleDeleteVerify()
								}}>
								&#xe816; 删除
							</Button>
						</Space>
					)
				},
			},
		],
		ajaxConfig: {
			ajaxType: 'post',
			url: getAllVerifyList,
		},
	}

	returnButton = record => {
		// 返回按钮数据
		return (
			<Space>
				<Button
					size='small'
					onClick={() => {
						this.setDossierDetail(record)
					}}>
					查看
				</Button>
				{record.status !== applyStatus['完成'] && (
					<Button
						size='small'
						className='table-btn-danger'
						onClick={() => {
							this.handleDeleteVerify(record)
						}}>
						删除
					</Button>
				)}
			</Space>
		)
	}

	changeSearchLimit = data => {
		data.current = data.currentPage
		delete data.currentPage
		return data
	}

	changeTableData = res => {
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}

	// 检查数量
	checkRowSelection = () => {
		return new Promise((resolve, reject) => {
			const { pub_rowSelection } = this.props
			const rowSelection = pub_rowSelection[markName] || []
			// 还未选中则不能操作
			if (!rowSelection.length) {
				reject(message.error(tips.CHOOSE_AT_LEAST_ONE))
			}
			// 返回所有选中项和id数组
			resolve(rowSelection)
		})
	}

	renovateReduxData = status => {
		const { appraisalData, addAppraisalDataToRedux } = this.props // 获取案卷或档案（文件）详情
		let temp = _.cloneDeep(appraisalData)
		temp[1].status = status
		temp[2].status = applyStatus[status]
		addAppraisalDataToRedux(temp)
		setTimeout(() => {
			this.forceUpdate()
		}, 100)
	}

	// 删除鉴定申请
	handleDeleteVerify = async record => {
		const { deleteVerify, pub_getAllData } = this.props
		try {
			let records = record ? [record] : await this.checkRowSelection()
			let ids = []
			records.forEach(
				(record, index) =>
					record.status !== applyStatus['完成'] && ids.push(record.id)
			)
			// 长度不一致说明选中的有不是为申请状态的 不能继续往下执行
			if (ids.length !== records.length) {
				message.error(tips.ONLY_APPLY_AND_RECORD_CAN_BE_DELETED)
				return
			}
			deleteVerify(ids).then(() => {
				message.success(tips.DELETE_SUCCESS)
				pub_getAllData()
			})
		} catch {}
	}

	// 获取鉴定申请详情数据并设置到表单
	setDossierDetail = record => {
		const { getVerifyDetail, addAppraisalDataToRedux } = this.props // 获取案卷或档案（文件）详情

		getVerifyDetail(record.id)
			.then(data => {
				let tempData = {}
				data.verifyDetails.forEach((value, index) => {
					tempData[`topic_${value.id}`] = value.topic // 题名
					tempData[`code_${value.id}`] = value.code // 档案号
					tempData[`categoryCode_${value.id}`] = value.categoryCode // 类别号
					tempData[`explanation_${value.id}`] = value.explanation // 备注
					tempData[`preRetentionPeriod_${value.id}`] =
						preRetentionPeriodType[value.preRetentionPeriod] // 前有效期
					tempData[`result_${value.id}`] = value.result // 鉴定结果
				})
				let formData = {
					...tempData,
					status: applyStatus[record.status], // 状态
					applyCode: record.applyCode, // 申请编号
					applyReason: record.applyReason, // 申请原因
					applyPerson: record.applyPerson, // 申请人
					createTime: record.createTime.split('T')[0], // 申请时间
					verifyPerson: record.verifyPerson, // 鉴定人
				}
				addAppraisalDataToRedux([data, formData, record])
			})
			.then(() => {
				sessionStorage.setItem('fromExpired', 'false')
				this.props.onChange(appraisalLink, history)
				this.props.pushRouters(appraisalLink)
			})
	}

	handleCompositeRecords = records => {
		const { addAppraisalDataToRedux } = this.props // 把数据存放到redux
		try {
			// 在档案详情模态框中点击鉴定或表格外多选点击鉴定
			if (typeUtil.isObject(records)) records = [records]
			// 不支持同时鉴定文件与案卷
			// if (records.some(record => record['type'] !== records[0].type)) {
			//   return Modal.error({
			//     content: '不支持同时鉴定文件与案卷',
			//     maskClosable: true
			//   });
			// }
			const tempData = {}
			const verifydata = {
				verifyDetails: records,
				attachmentList: [],
			}
			// 鉴定的附录文件列表
			records.forEach((value, index) => {
				value.preRetentionPeriod = value.retentionPeriod
				tempData[`topic_${value.id}`] = value.topic // 题名
				tempData[`code_${value.id}`] = value.code // 档案号
				tempData[`categoryCode_${value.id}`] = value.categoryCode // 类别号
				tempData[`preRetentionPeriod_${value.id}`] = value.retentionPeriod // 前有效期
			})
			// 申请人
			const applyPerson = {
				applyPerson: JSON.parse(localStorage.getItem('userMessage')).user.name,
			}
			// 把数据放到redux上
			addAppraisalDataToRedux([
				verifydata,
				{
					...applyPerson,
					...tempData,
				},
				{
					...applyPerson,
				},
				{},
			])
			// 判断明细表类型
			sessionStorage.setItem('fromExpired', 'true') //从过期档案进入鉴定详情页面
			this.props.onChange(appraisalLink, history) //更改路由
			this.props.pushRouters(appraisalLink) //添加分页
		} catch (err) {}
	}

	render() {
		return (
			<Fragment>
				<WrappedTable>
					<SearchForm
						name='appraisal-search-form'
						formItems={this.searchFormConfig}
						getInstance={formRef => {
							this.searchFormRef = formRef
						}}
						searchButtonText='查询'
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
					/>
					<BaseTable
						tableConfig={appraisalSuperColumns} // 表格表头设置
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData} // 把拿到的表格数据改造成符合公共组件所需数据的格式
						markName={markName}
						withPreview={false}
						operationButton={this.returnButton} // 自定义操作按钮
						onRowEvent={true}
					/>
				</WrappedTable>
			</Fragment>
		)
	}
}
export default index
