import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import * as nsfwjs from 'nsfwjs'
import _fetch from './Fetch'

export const newActionTypeFunc = (base, array) => {
	return Object.assign(
		{},
		...array.map(item => {
			let object = {}
			return (object[item] = base + item), object
		})
	)
}

// 对当前页面可用的cookie进行处理的函数
export const CookieUtil = {
	get: function (name) {
		// 调用get直接返回userMessage对象，如果没有返回null
		return localStorage.getItem(name) || null
	},
	// 获取权限列表, 若已经销毁则返回{}
	get permissionList() {
		return JSON.parse(CookieUtil.get('userMessage'))
			? JSON.parse(CookieUtil.get('userMessage')).permissionList
			: []
	},
	// 获取角色信息 若已经销毁则返回{}
	get roleList() {
		return JSON.parse(CookieUtil.get('userMessage'))
			? JSON.parse(CookieUtil.get('userMessage')).roleList
			: {}
	},
	// 获取用户信息 若已经销毁则返回{}
	get user() {
		return JSON.parse(CookieUtil.get('userMessage'))
			? JSON.parse(CookieUtil.get('userMessage')).user
			: {}
	},
	/**
	 *
	 * @param {string} name  要存储的数据的key
	 * @param {json} value  要存储数据的值
	 * @param {number} expires 过期时间戳
	 * @param {*} path 改成localstorage后，已弃用
	 * @param {*} domain 改成localstorage后，已弃用
	 * @param {*} secure 改成localstorage后，已弃用
	 */
	set: function (name, value, expires, path = '/', domain, secure) {
		let valueObj = JSON.parse(value)
		valueObj.expires = expires
		localStorage.setItem(name, JSON.stringify(valueObj))
	},

	/**
	 *
	 * @param {string} name 要删除的local的key
	 * @param {*} path 已弃用
	 * @param {*} domain 已弃用
	 * @param {*} secure 已弃用
	 */
	unset: function (name, path, domain, secure) {
		localStorage.removeItem(name)
	},
}

// import moment from 'moment'
// 时间处理函数
export const timeUtil = {
	// 传入 moment 对象，转为后台要的字符串格式，具体到某一天
	toDay: moment => moment.format('YYYY-MM-DD'),
	// 传入 moment 对象，转为后台要的字符串格式，具体到某一秒
	toSecond: moment => moment.format('YYYY-MM-DD HH:mm:ss'),
	// 将时间字符串转为时间戳
	toTimestamp: date => {
		if (!typeUtil.isString(date)) {
			throw new Error('请输入时间字符串如YYYY-MM-DD HH:mm:ss')
		}
		return moment(date, 'YYYY-MM-DD HH:mm:ss').valueOf()
	},
	// 将时间戳转为北京时间
	toLocalTime: timestamp => {
		return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
	},
}

// 能够防止被包裹的组件在卸载后使用 setState 而报错（异步结果返回的时候组件已经卸载的情况）
export let injectUnmount = target => {
	// 改装componentWillUnmount，销毁的时候记录一下
	try {
		let next = target.prototype.componentWillUnmount
		target.prototype.componentWillUnmount = function () {
			if (next) next.call(this, ...arguments)
			this.unmount = true
		}
		// 对setState的改装，setState查看目前是否已经销毁
		let setState = target.prototype.setState
		target.prototype.setState = function () {
			if (this.unmount) return
			setState.call(this, ...arguments)
		}
	} catch {}
}

/**
 * 用于检查数据的类型
 * @param {any} data
 * return type 返回数据的类型
 */
export let typeUtil = {}
let type = ['String', 'Array', 'Number', 'Object', 'Undefined', 'Null', 'Boolean']
for (let i = 0; i < type.length; i++) {
	typeUtil['is' + type[i]] = function (obj) {
		return Object.prototype.toString.call(obj) === '[object ' + type[i] + ']'
	}
}
export const cfUtils = {
	// TODO: 平面树还原
	listToTree(list) {
		// 进行类型检查
		if (!typeUtil.isArray(list)) {
			throw new Error('请确保传入的数据是一个数组！')
		}
		list = _.cloneDeep(list)
		let map = {},
			node,
			tree = []
		for (let i = 0; i < list.length; i++) {
			map[list[i].id] = list[i]
			list[i].child = []
		}
		for (let i = 0; i < list.length; i++) {
			node = list[i]
			if (node.parentId !== -1) {
				map[node.parentId].child.push(node)
			} else {
				tree.push(node)
			}
		}
		return tree
	},
	/**
	 * 将[parentId id,children: []] --> 转换为平面树
	 * @param {Array} tree 树🌲
	 * @param {Function} fn 对每个节点的数据进行处理，处理完后需要将其返回
	 */
	flatTree(tree, fn) {
		if (!tree) throw new Error('请确保传入了')
		let newTree = null
		if (typeUtil.isObject(tree)) {
			newTree = [..._.cloneDeep(tree)]
		} else if (typeUtil.isArray(tree)) {
			newTree = _.cloneDeep(tree)
		} else {
			throw new Error('请确保传入的数据为数组或者对象！')
		}
		let queen = []
		queen = queen.concat(newTree)
		let out = []
		while (queen.length > 0) {
			let first = queen.shift()
			first.position = first.position ? first.position : `/workStation/${first.id}`
			if (first.child && first.child.length > 0) {
				queen = queen.concat(
					first.child.map(item => {
						item.position = first.position + '/' + item.id
						return fn(item, first)
					})
				)
				first.hasChildren = true
				delete first['child']
			} else {
				delete first['child']
				first.hasChildren = false
			}
			out.push(fn(first))
		}
		return out
	},
	/**
	 * 用于找出某个item在二维数组的第一次出现的下标
	 * @param {Array} doubleArray 二维数组
	 * @param {*} fn 回掉函数，返回值为true。false。若满足条件则返回true
	 * return 返回值为满足条件的item，在二维数组下标,若没有则为null
	 */
	findIndexInDoubleArray(doubleArray, fn) {
		let index = null
		doubleArray.forEach((item, row) => {
			item.forEach((innerItem, col) => {
				if (fn(innerItem)) {
					index = [row, col]
					return false
				}
			})
		})
		return index
	},
}

// 与树相关的公共方法
export const treeUtil = {
	/**
	 * 更改树的属性名
	 * @param {Object[]} originTree  // 源树数据
	 * @param {Object} keyMap  // 旧key到新key的映射对象
	 * @param {String} recurseKey  // 递归的字段，如 children/child，主要是为了适配
	 * @param {Function} callback  // 遍历过程中可以修改节点内容，经过 keyMap 修饰后才调用
	 * @returns 返回改造后的树
	 * Example:
	 * {
	 *   oldKey: 'newKey',
	 *   id: 'value',
	 *   name: 'label'
	 * }
	 * 即将树结构中的 id 属性名改为 'value', name属性名改为 'label'
	 */
	changeKeys: (originTree, keyMap, recurseKey = 'children', callback) => {
		let copyTree = _.cloneDeep(originTree) // 深度克隆避免污染源数据
		let newRecurseKey = recurseKey // 新的递归字段，先默认为旧字段
		// 如果 keyMap 存在 recurseKey -> xxx 的映射，那么说明递归字段会被改变
		// 此时在递归的时候先改变递归字段，再递归新字段
		if (keyMap[recurseKey]) newRecurseKey = keyMap[recurseKey]
		// 修改该节点对应的属性名
		const changeNodeKeys = node => {
			for (let oldKey in keyMap) {
				if (keyMap.hasOwnProperty(oldKey)) {
					let newKey = keyMap[oldKey]
					if (newKey) {
						node[newKey] = node[oldKey]
						delete node[oldKey]
					}
				}
			}
			callback && callback(node) // 可以支持另外修改节点，注意callback的调用时机
		}
		// 遍历该层所有孩子节点并修改对应的属性名，传入的为数组
		const changeTreeKeys = children => {
			// 传入的 children 为空，则返回不继续往下
			children &&
				children.forEach(child => {
					changeNodeKeys(child)
					// 如果该节点有孩子则继续往下修改
					changeTreeKeys(child[newRecurseKey])
				})
		}
		if (typeUtil.isArray(copyTree)) {
			// 如果是一个数组，则可能不止有一个根节点
			changeTreeKeys(copyTree)
		} else if (typeUtil.isObject(copyTree)) {
			// 是一个对象，那么可能是一个根节点，先更改根节点后再遍历
			changeNodeKeys(copyTree)
			changeTreeKeys(copyTree[newRecurseKey])
		}
		return copyTree
	},

	/**
	 * 将树进行扁平化，并添加上 parentId、hasChildren、depth、与 id 相同的 key 等字段
	 * @param {Object[]} originTree  // 源树数据
	 * @param {String} recurseKey    // 递归的字段，如 children/child，主要是为了适配
	 * @param {Function} callback    // 扁平化过程中可以修改节点内容
	 * @returns 返回扁平化后的树
	 */
	flatTree: (originTree, callback, recurseKey = 'children') => {
		const { isArray, isObject } = typeUtil
		let copyTree = _.cloneDeep(originTree) // 保护源数据
		let flatTree = [] // 存放平面树节点
		if (isObject(copyTree)) copyTree = [copyTree]
		if (!isArray(copyTree))
			throw new Error('预扁平化的树数据必须为一个对象或一个数组')
		while (copyTree.length > 0) {
			let headNode = copyTree.shift() // 取队头
			// 如果没有深度，则是第一级的节点，初始化深度为1
			if (!headNode['depth']) {
				headNode['depth'] = 1
				// headNode['parentId'] = -1;
			}
			let children = headNode[recurseKey] // 获取队头的孩子节点
			if (children && children.length > 0) {
				// 如果有后代，则加个后代的标志
				headNode['hasChildren'] = true
				// 修饰其后代后继续将其后代入队
				copyTree.push(
					...children.map(child => {
						child['parentId'] = headNode['id'] // 为其孩子添加父 id
						child['depth'] = headNode['depth'] + 1 // 深度加一
						return child
					})
				)
			} else headNode['hasChildren'] = false
			delete headNode[recurseKey] // 删除递归字段
			// 如果没有 key 且有 id，则加上 key 作为唯一标志
			if (!headNode['key'] && headNode['id']) {
				headNode['key'] = headNode['id']
			}
			callback && callback(headNode) // 回调修改节点，无需返回，引用类型直接修改
			flatTree.push(headNode)
		}
		return flatTree
	},
	/**
	 * 扁平树数组还原为树结构
	 * @param {Array} list 要还原的树
	 * @param {Object} {parentIdKey = "parentId",childrenKey = "children"} 父节点的key和child的key， 默认为null
	 */
	listToTree(
		list,
		fn,
		{ parentIdKey = 'parentId', childrenKey = 'children' } = {
			parentIdKey: 'parentId',
			childrenKey: 'children',
		}
	) {
		// 进行类型检查
		if (!typeUtil.isArray(list)) {
			throw new Error('请确保传入的数据是一个数组！')
		}
		if (parentIdKey && !typeUtil.isString(parentIdKey)) {
			throw new Error('请确保parentIdKey是一个字符串！')
		}
		if (childrenKey && !typeUtil.isString(childrenKey)) {
			throw new Error('请确保childrenKey是一个字符串！')
		}
		list = _.cloneDeep(list)
		let map = {},
			node,
			tree = []
		for (let i = 0; i < list.length; i++) {
			map[list[i].id] = list[i]
			list[i][childrenKey] = []
		}
		for (let i = 0; i < list.length; i++) {
			node = fn ? fn(list[i]) : list[i]
			// 多一行判断-1来确定是否为根节点
			if (!typeUtil.isUndefined(node[parentIdKey]) && node[parentIdKey] !== -1) {
				if (map[node[parentIdKey]]) {
					map[node[parentIdKey]][childrenKey].push(node)
				} else {
					tree.push(node)
				}
			} else {
				// * 现在要不显示根节点，做个id为零的不显示
				if (node.id === 0) {
					continue
				}
				const ids = tree.map(item => item.id)
				if (!ids.includes(node.id)) {
					tree.push(node)
				}
			}
		}
		return tree
	},
	/**
	 * 将[parentId id,children: []] --> 转换为平面树
	 * @param {Array} tree 树🌲
	 * @param {Function} fn 对每个节点的数据进行处理，处理完后需要将其返回
	 */
	// flatTree(tree, fn) {
	//   if (!tree) throw new Error("请确保传入了");
	//   let newTree = null;
	//   if (typeUtil.isObject(tree)) {
	//     newTree = [..._.cloneDeep(tree)]
	//   } else if (typeUtil.isArray(tree)) {
	//     newTree = _.cloneDeep(tree)
	//   } else {
	//     throw new Error("请确保传入的数据为数组或者对象！")
	//   }
	//   let queen = [];
	//   queen = queen.concat(newTree);
	//   let out = [];
	//   while (queen.length > 0) {
	//     let first = queen.shift();
	//     if (first.child && first.child.length > 0) {
	//       queen = queen.concat(first.child)
	//       first.hasChildren = true;
	//       delete first['child'];
	//     } else {
	//       delete first['child'];
	//       first.hasChildren = false;
	//     }
	//     out.push(fn(first));
	//   }
	//   return out;
	// },
}

// 与权限相关
export const authUtil = {
	// 存储计算后的permissionList，减少重复计算
	permissionList: undefined,
	// 判断有无权限来控制按钮等 React 组件显示与否
	withAuth: (reactNode, urls) => {
		return authUtil.hasAuth(urls) ? reactNode : null
	},

	hasAuth: urls => {
		if (!CookieUtil.permissionList) return false
		let urlArr = []
		if (typeUtil.isString(urls)) {
			urlArr = [urls]
		} else if (typeUtil.isArray(urls)) {
			urlArr = urls
		} else {
			throw new Error('传入的urls必须是一个字符串或一个数组')
		}
		// 获取接口权限列表，并消除其前后的空格避免全等判断失败
		const permissionList =
			authUtil['permissionList'] ||
			CookieUtil.permissionList.map(permission => permission.trim())
		if (!authUtil['permissionList']) {
			authUtil['permissionList'] = permissionList
		}
		const targetPermissions = urlArr.map(url => url.split('/').pop()) // 获取 urls 最后的API 作为权限
		// 所有都被包含才算有权限
		const hasAuth = targetPermissions.every(permission =>
			permissionList.includes(permission)
		)
		return hasAuth
	},
}

export const rjUtils = {}
// 深度过滤对象中的 ['String', 'Array', 'Number', 'Object', 'Undefined', 'Null', 'Boolean'] 的属性值
for (let i = 0; i < type.length; i++) {
	rjUtils[`clear${type[i]}`] = function (obj) {
		const { isObject } = typeUtil
		if (!isObject(obj)) throw new Error(`过滤 ${type[i]} 的源数据必须为一个对象`)
		const keys = Object.keys(obj)
		for (let key of keys) {
			const value = obj[key]
			// 如果是 type[i] 则直接删除该属性
			if (typeUtil[`is${type[i]}`](value)) {
				delete obj[key]
			} else if (isObject(value) && !React.isValidElement(value)) {
				// 是对象且不是 React 组件，则递归调用
				rjUtils[`clear${type[i]}`](value)
			}
		}
		// 引用类型会被修改，但是还是返回其本身，有时候方便获取不需要定义一个变量
		return obj
	}
}

/**
 * 导出文件模板
 * @param {Object} ajaxConfig {url, method, data/params}
 * @param {Obejct} param
 * @param {Function} callback
 * @returns
 */
export const downloadFileTemplate = (
	ajaxConfig,
	{ fileName, fileType, fileExt },
	callback
) => {
	let token = JSON.parse(CookieUtil.get('userMessage')).token
	if (!token) return
	const config = {
		...ajaxConfig,
		headers: {
			Authorization: token,
			'Content-Type': 'application/json',
		},
		responseType: 'blob',
	}
	_fetch(config).then(res => {
		const blob = res.data
		const urlObject = window.URL || window.webkitURL || window
		const export_blob = new Blob([blob], {
			type: fileType,
		})
		const a = document.createElement('a') // 利用a标签特性下载
		a.style.display = 'none'
		const url = urlObject.createObjectURL(export_blob)
		a.href = url
		a.setAttribute('download', `${fileName}.${fileExt}`)
		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)
		callback && callback()
	})
}

/**
 * 上传文件
 * @param {Obejct} ajaxConfig
 * @param {Function} callback
 * @returns
 */
export const uploadFilesTemplate = async (ajaxConfig, callback) => {
	let token = JSON.parse(CookieUtil.get('userMessage')).token
	if (!token) return
	const config = {
		...ajaxConfig,
		headers: {
			Authorization: token,
			'Content-Type': ajaxConfig.contentType || 'multipart/form-data',
		},
	}
	let res = await _fetch(config)
	if (res && res.data.code === 200) {
		callback && callback(res)
		return res.data
	} else if (res && res.data) {
		return res.data
	} else {
		return res
	}
}

/**
 * 把深度树展成平面树
 *
 * @param {Array} deepObject 要遍历的树
 * @param {*} [result={}] 返回的平面树
 * @param {string} [deepByKey='children'] 通过哪个属性进行深度查找
 * @param {string} [deepKey='value']  返回的平面树中对象的属性
 * @param {string} [deepValue='title'] 返回的平面树中对象的属性值
 * @return {*}
 */
export const planeTree = (
	deepObject,
	result = {},
	deepByKey = 'children',
	deepKey = 'value',
	deepValue = 'title'
) => {
	if (!deepObject) {
		return result
	}
	deepObject.forEach(item => {
		result[item[deepKey]] = item[deepValue]
		if (item[deepByKey]) {
			planeTree(item[deepByKey], result, deepByKey, deepKey, deepValue)
		}
	})
	return result
}

/**
 * 鉴黄函数
 * @param {antdFile | File} antdFile antd的file格式 或 File类
 * @param {Number} pornThreshold 色情阈值
 * @param {Number} sexyThreshold 性感阈值
 * @returns 返回对象 {isPorn, isSexy, isImg} 或 {tip ,isImg}
 */
export const filterYellow = async (
	antdFile,
	pornThreshold = 80,
	sexyThreshold = 92,
	hentaiThreshold = 84
) => {
	let file = antdFile instanceof File ? antdFile : antdFile?.file
	return new Promise((resolve, reject) => {
		if (!!file === false)
			reject({
				tip: '请传入文件',
				isImg: false,
			})
		// 判断传入的，是否为图片。不是则返回提示
		let regExp = /(jpeg)|(jpg)|(gif)|(svg)|(webp)|(apng)|(bmp)|(png)/
		const isImg = regExp.test(file.type)
		if (!isImg)
			resolve({
				tip: '请传入图片',
				isImg,
				file,
			})
		resolve({
			file,
			isImg,
		})
	})
		.then(async value => {
			const { file, isImg, tip } = value
			if (!isImg)
				return {
					isImg,
					tip,
				}
			// 开始处理图像并鉴黄
			let src = URL.createObjectURL(file) // 传进File类型
			let img = document.createElement('img')
			img.setAttribute('src', src)
			const model = await nsfwjs.load()
			let isPorn = false
			let isSexy = false
			let isHentai = false
			const predictions = await model.classify(img)
			predictions.forEach(item => {
				if (item.className === 'Porn') {
					isPorn = item.probability.toFixed(2) * 100 > pornThreshold
				}
				if (item.className === 'Sexy') {
					isSexy = item.probability.toFixed(2) * 100 > sexyThreshold
				}
				if (item.className === 'Hentai') {
					isHentai = item.probability.toFixed(2) * 100 > hentaiThreshold
				}
			})
			return {
				isPorn,
				isSexy,
				isImg,
				isHentai,
			}
		})
		.catch(err => {
			return err
		})
}
