import React, { Component } from 'react'
import { Drawer, Button, Divider, Collapse, Popover, Table } from 'antd'
import { dossierEnum } from '@/Utils/Enum'
import { PubForm } from '@/Public/PubForm'
import FilePreviewer from '@/Public/FilePreviewer'
import _ from 'lodash'
const { Panel } = Collapse
const formItems = [
	[
		{
			type: 'Select',
			name: 'fondsName',
			label: '全宗号',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Input',
			name: 'yearNumber',
			label: '年度',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Input',
			name: 'categoryName',
			label: '类别名',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Select',
			name: 'categoryCodeId',
			label: '类别号',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Select',
			name: 'confidentialLevel',
			label: '密级',
			options: dossierEnum.confidentialLevel.toOptions(),
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Select',
			name: 'departmentName',
			label: '所属部门',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Input',
			name: 'seeNumber',
			label: '参见号',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Input',
			name: 'topic',
			label: '题名',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Input',
			name: 'keyWord',
			label: '关键词',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Input',
			name: 'time',
			label: '时间',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Input',
			name: 'people',
			label: '人物',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Input',
			name: 'address',
			label: '地点',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'TextArea',
			name: 'event',
			label: '事件描述',
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'Radio',
			name: 'retentionPeriod',
			label: '保管年限',
			options: dossierEnum.retentionPeriod.toOptions(),
			placeholder: '无',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'InputWithPicker',
			name: 'recordUserAndTime',
			keys: ['recordUser', 'recordTime'],
			label: '著录用户',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'InputWithPicker',
			name: 'archiveUserAndTime',
			keys: ['archiveUser', 'archiveTime'],
			label: '归档用户',
			rules: [{ required: false }],
			disabled: true,
		},
		{
			type: 'InputWithPicker',
			name: 'storageUserAndTime',
			keys: ['storageUser', 'storageTime'],
			label: '入库用户',
			rules: [{ required: false }],
			disabled: true,
		},
	],
]

function difference(object, base) {
	function changes(object, base) {
		return _.transform(object, function (result, value, key) {
			if (!_.isEqual(value, base[key])) {
				result[key] =
					_.isObject(value) && _.isObject(base[key])
						? changes(value, base[key])
						: value
			}
		})
	}
	return changes(object, base)
}
export default class DrawerDetails extends Component {
	state = {
		visible: false, // 抽屉开关
	}

	// 开抽屉
	showDrawer = () => {
		this.setState({
			visible: true,
		})
	}

	// 关抽屉
	onClose = () => {
		this.setState({
			visible: false,
		})
	}

	// 设置元数据表单的值
	renderMetadata = data => {
		const nowRecord = data.metadataStructTreeBoList || []
		const metadataForm = [] // 元数据配置数组

		nowRecord.forEach(record => {
			// 遍历找到父节点
			if (record.parentId === 0) {
				metadataForm.push(
					...[
						[
							{
								type: 'Extra',
								name: `parent_${record.id}`,
								rules: [{ required: false }],
								render: formRef => (
									<div>
										<Divider
											className='matedataDivider'
											orientation='left'
											id={`parent_${record.id}`}>
											{record['metadataName']}
										</Divider>
									</div>
								),
							},
						],
					]
				)

				if (record['metadataValue']) {
					// 如果没有子节点则渲染自己的数据值
					metadataForm.push(
						...[
							[
								{
									type: 'Input',
									name: `${record.id}`,
									label: `${record['metadataName']}`,
									span: 19,
									rules: [{ required: false }],
									initialValue: record['metadataValue'],
									bordered: false,
								},
							],
						]
					)
				} else {
					// 重新遍历找到该父节点的子节点
					nowRecord.forEach(recordSon => {
						if (recordSon.parentId === record.id) {
							metadataForm.push(
								...[
									[
										{
											type: 'Input',
											name: `${recordSon.id}`,
											label: `${recordSon['metadataName']}`,
											span: 19,
											rules: [{ required: false }],
											initialValue: recordSon['metadataValue'],
											bordered: false,
										},
									],
								]
							)
						}
					})
				}
			}
		})
		return metadataForm.length
			? metadataForm
			: [
					[
						{
							type: 'Input',
							name: 'topic',
							label: '',
							placeholder: '暂无数据',
							rules: [{ required: false }],
							bordered: false,
						},
					],
				]
	}

	// 设置时间和人物并列数据
	setTimeAndUserData = data => {
		const {
			recordTime,
			recordUser,
			archiveTime,
			archiveUser,
			storageTime,
			storageUser,
		} = data
		// 著录人和时间
		data['recordUserAndTime'] = { recordUser, recordTime }
		// 归档人和时间
		data['archiveUserAndTime'] = { archiveUser, archiveTime }
		// 入库人和时间
		data['storageUserAndTime'] = { storageUser, storageTime }
		return data
	}

	content = archiveDetail => {
		let columns = [
			{ title: '题名', dataIndex: 'topic', width: '12.5%' },
			{
				title: '预览图',
				dataIndex: 'fileData',
				width: '12.5%',
				height: '50px',
				render: (text, record, formRef, tableFuncs) => {
					return record?.fileType == null ? null : (
						<FilePreviewer fileData={record} isThumbnail={true} />
					)
				},
			},
			{ title: '分类', dataIndex: 'categoryName', width: '12.5%' },
		]

		return (
			<Table
				className='fix-drawer-file-table'
				dataSource={archiveDetail}
				columns={columns}
				scroll={{ y: 350 }}
				pagination={{ defaultPageSize: 5, simple: true }}
			/>
		)
	}

	// 显示文件
	showFields = data => {
		const { fileType, fileToken, thumbnailFileToken, thumbnailFileType } = data
		let fileData
		if (thumbnailFileToken) {
			fileData = { fileToken: thumbnailFileToken, fileType: thumbnailFileType }
		} else {
			fileData = { fileToken, fileType }
		}

		// 案卷返回null
		if (!fileType) {
			fileData = {
				fileType: null,
			}
		}
		return (
			<div className='fix-drawer-file'>
				{/* 显示文件 */}
				<FilePreviewer fileData={fileData} />
				{fileType ? null : (
					<Popover
						placement='bottomRight'
						content={this.content(data.archiveDetail)}
						trigger='click'>
						<Button className='key-archiveDetail'>查看文件列表</Button>
					</Popover>
				)}
			</div>
		)
	}

	// 不同标红
	setInputStyle = () => {
		let difObj = difference(this.props.beforeData, this.props.afterData) // 判断不同的对象
		let afterMetadata = {},
			beforeMetadata = {}

		this.props.afterData?.metadataStructTreeBoList?.forEach(current => {
			afterMetadata[`${current.id}`] = current.metadataValue
		})

		this.props.beforeData?.metadataStructTreeBoList?.forEach(current => {
			beforeMetadata[`${current.id}`] = current.metadataValue
		})

		Object.keys(difObj).forEach(key => {
			// 遍历根据key给不同的dom元素标红
			if (key === 'fileToken' && this.props.drawerKey === 0) {
				let dom = document
					.querySelector(`.fix-drawer-file`)
					?.querySelector(`.file-previewer-wrapper `)
				dom.style.border = '3px solid red'
				return true
			}
			let difDom =
				document
					.getElementById(`FixAfterForm_${this.props.drawerKey}`)
					?.querySelector(`div[name="${key}"]`) ||
				document.getElementById(`FixAfterForm_${this.props.drawerKey}_${key}`) ||
				document
					.getElementById(`FixAfterForm_${this.props.drawerKey}`)
					?.querySelector(`.${key}`)
			if (difDom) {
				difDom.style.borderBottom = '2px solid red'
			} else {
				if (
					key === 'archiveDetail' &&
					document.querySelector('.key-archiveDetail')
				) {
					document.querySelector('.key-archiveDetail').style.borderBottom =
						'2px solid red'
				}
			}
		})

		// 元数据标红
		let difMatedataObj = difference(afterMetadata, beforeMetadata) // 判断不同的对象
		Object.keys(difMatedataObj).forEach(key => {
			// 遍历根据key给不同的dom元素标红

			// 下拉框标红
			if (document.querySelector('.key-metaData')) {
				document.querySelector('.key-metaData').style.borderBottom =
					'2px solid red'
			}

			let difMatedataDom = document.getElementById(
				`MetadataAfterFormDemo_${this.props.drawerKey}_${key}`
			)
			if (difMatedataDom) {
				difMatedataDom.style.borderBottom = '2px solid red'
			}
		})
	}

	render() {
		const { visible } = this.state
		const { beforeData, drawerKey, afterData } = this.props
		return (
			<>
				<Button
					type='primary'
					onClick={this.showDrawer}
					style={{ marginRight: '30px' }}>
					修改对比
				</Button>
				{/* 抽屉 */}
				<Drawer
					title='修正对比'
					placement='right'
					onClose={this.onClose}
					visible={visible}
					className='form-Drawer'
					width='1000px'>
					{/* table是为了可以两个表单并列显示 */}
					<table>
						<tbody>
							<tr>
								<td valign='top'>
									<h1>修正后</h1>
									{this.showFields(afterData)}
									{/* 表单 */}
									<PubForm
										ref={this.formRef}
										formItems={formItems}
										getInstance={formRef => {
											this.formRef = formRef
											this.formRef.setFieldsValue(
												this.setTimeAndUserData(afterData)
											)
											setTimeout(() => {
												// 延迟标红
												this.setInputStyle()
											}, 1000)
										}}
										name={'FixAfterForm_' + drawerKey}
										title='修正后申请表'
									/>
									{/* 判断显示元数据 */}
									{afterData.metadataStructTreeBoList && (
										<Collapse defaultActiveKey={['1']}>
											<Panel header='元数据详情' key='1'>
												{/* 显示文件详情 */}
												<PubForm
													ref={this.formRef}
													formItems={this.renderMetadata(
														afterData
													)}
													getInstance={formRef => {
														this.formRef = formRef
													}}
													name={
														'MetadataAfterFormDemo_' +
														drawerKey
													}
													title='元数据详情'
													formDisabled
												/>
											</Panel>
										</Collapse>
									)}
								</td>
								<td className='last-td' valign='top'>
									<h1>修正前</h1>
									{this.showFields(beforeData)}
									{/* 表单 */}
									<PubForm
										ref={this.formRef}
										formItems={formItems}
										getInstance={formRef => {
											this.formRef = formRef
											this.formRef.setFieldsValue(
												this.setTimeAndUserData(beforeData)
											)
										}}
										name={'FixBeforeForm_' + drawerKey}
										title='修正前申请表'
									/>
									{/* 判断显示元数据 */}
									{beforeData.metadataStructTreeBoList && (
										<Collapse defaultActiveKey={['1']}>
											<Panel header='元数据详情' key='1'>
												{/* 显示文件详情 */}
												<PubForm
													ref={this.formRef}
													formItems={this.renderMetadata(
														beforeData
													)}
													getInstance={formRef => {
														this.formRef = formRef
													}}
													name={
														'MetadataBeforeFormDemo_' +
														drawerKey
													}
													title='元数据详情'
													formDisabled
												/>
											</Panel>
										</Collapse>
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<Divider />
				</Drawer>
			</>
		)
	}
}
