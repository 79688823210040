import _fetch from '@/Utils/Fetch'
import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import { borrowUrl } from '@/Utils/Urls'
import { Modal, message } from 'antd'
// 更新红点函数
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
// 消息提示
export const tips = {
	CHOOSE_AT_LEAST_ONE: '请选择一项后再进行操作',
	RECALL_SUCCESS: '撤回成功',
	DELETE_SUCCESS: '删除成功',
	SUBMIT_SUCCESS: '提交成功',
	UPDATE_SUCCESS: '保存成功',
	ONLY_APPROVAL_STATUS: '只能对“审批状态”下的借阅申请进行撤回',
	ONLY_APPLICATION_STATUS: '只能对“申请状态”下的借阅申请进行删除',
}

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}
/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

/**
 * 撤回申请
 * @param {Number} id 申请id
 */
export const recallUseApplyThunk = id => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '撤回借阅申请',
			content: '您正在对当前申请进行撤回操作，是否继续？',
			onOk: () => {
				_fetch({
					url: borrowUrl.recallUseApply,
					data: id,
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.RECALL_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 删除申请
 * @param {Number} id 申请id
 */
export const deleteUseApplyThunk = id => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '删除借阅申请',
			content: '您正在对当前申请进行删除操作，是否继续？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: borrowUrl.deleteUseApply,
					type: 'post',
					data: id,
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.DELETE_SUCCESS)
						updataRedDots()
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 获取借阅申请审批的详情列表
 * @param {Number} id 申请表id
 */
export const getUseApplyDetailThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: borrowUrl.getUseApplyDetail,
			data: {
				id,
			},
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 状态为完成时可获取申请结果详情
 */
export const getMyUseResultThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: borrowUrl.getMyUseResult,
			type: 'get',
			data: {
				id,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 获取已登录用户的信息
 */
export const getUserInfoThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: borrowUrl.getUserInfo,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})
