import { filesUrl } from '@/Utils/Urls'
import axios from 'axios'

// 上传文件
const upload = data =>
	axios({
		url: filesUrl.visitorUpload,
		method: 'post',
		data: data.formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		onUploadProgress: data.progressListen,
	})

const visitorUpload = data => {
	let progressListen
	if (data.file) {
		progressListen = progressEvent => {
			let percent = ((progressEvent.loaded / progressEvent.total) * 100) | 0
			if (percent !== 100) {
				data.file.onProgress({
					percent,
				})
			} else {
				data.file.onSuccess()
			}
		}
	} else {
		progressListen = null
	}
	data.progressListen = progressListen
	return new Promise((resolve, reject) => {
		upload(data)
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res)
				} else {
					reject(res.data.message)
				}
			})
			.catch(err => {
				reject(err?.response)
			})
	})
}

export default visitorUpload
