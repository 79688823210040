import { GET_USERDATA } from '@/Redux/ActionTypes/HeaderBar'
import InitialState from '@/Redux/InitialState'
import _ from 'lodash'
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = InitialState.headerBarData, action) => {
	switch (action.type) {
		case GET_USERDATA: {
			let formDate = _.cloneDeep(state)
			const { data } = action
			formDate.userData = data
			return formDate
		}
		default: {
			return state
		}
	}
}
