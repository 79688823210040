import myFilesImg from '@/Static/myFiles.png'
import workStationImg from '@/Static/workStation.png'
import myApplicationImg from '@/Static/myApplication.png'
import myApprovalImg from '@/Static/myApproval.png'
import systemImg from '@/Static/system.png'
import appraisalImg from '@/Static/appraisal.png'

import newSvg from '@/Static/svg/new.svg'
import settingSvg from '@/Static/svg/system.svg'
import editSvg from '@/Static/svg/edit.svg'
import managementSvg from '@/Static/svg/management.svg'
import statisticsSvg from '@/Static/svg/statistics.svg'
import archivedSvg from '@/Static/svg/archived.svg'
import peopleFaceSvg from '@/Static/svg/peopleFace.svg'
import mineSvg from '@/Static/svg/mine.svg'
import fileSvg from '@/Static/svg/file.svg'

import multimediaSvg from '@/Static/svg/multimedia.svg'
import documentSvg from '@/Static/svg/document.svg'
import dossierSvg from '@/Static/svg/dossier.svg'
import newmediaSvg from '@/Static/svg/newmedia.svg'

// 侧边栏各个id与路由和中文的对应
export let idToRoute = new Map([
	[
		2,
		{
			path: '/newConstruction',
			title: '新建',
			firstRouteLink: '/newConstruction/newFiles?archiveType=1',
			img: newSvg,
		},
	],
	[
		3,
		{
			path: '/statistics',
			title: '统计',
			firstRouteLink: '/statistics/statistics',
			img: statisticsSvg,
		},
	],
	[
		4,
		{
			path: '/editingResearch',
			title: '编研',
			firstRouteLink: '/editingResearch/ePhotoAlbum',
			img: editSvg,
		},
	],
	[5, { path: '/settings', title: '系统', img: settingSvg }],
	[
		6,
		{
			path: '/newConstruction/newFiles-multiMedia?archiveType=1',
			title: '多媒体',
			img: multimediaSvg,
			className: 'icon-style',
		},
	],
	[
		10,
		{
			path: '/newConstruction/newArchives',
			title: '案卷',
			img: dossierSvg,
			className: 'icon-style',
			contentClassName: 'zone-style',
		},
	],
	[
		11,
		{
			path: '/newConstruction/UrlToArchives',
			title: '新媒体',
			img: newmediaSvg,
			className: 'icon-style',
		},
	],
	[
		60,
		{
			path: '/newConstruction/newFiles-document?archiveType=2',
			title: '文书',
			img: documentSvg,
			className: 'icon-style',
			contentClassName: 'zone-style',
		},
	],
	[
		65,
		{
			path: '/newConstruction/automaticDescription',
			title: '自动著录',
			img: documentSvg,
			className: 'icon-style',
		},
	],
	[
		68,
		{
			path: '/newConstruction/urlToArchiveManagement',
			title: '频道管理',
			img: documentSvg,
			className: 'icon-style',
		},
	],
	[12, { path: '/workarea/myFiles', title: '工作台', img: myFilesImg }],
	[23, { path: '/workarea/myFiles', title: '工作台', img: myFilesImg }], // 档案管理员
	[13, { path: '/workarea/workStation', title: '收藏', img: workStationImg }],
	[
		14,
		{
			path: '/workarea/myApplication',
			title: '我的申请',
			img: myApplicationImg,
		},
	],
	[15, { path: '/workarea/myApproval', title: '我的审批', img: myApprovalImg }],
	[16, { path: '/workarea/appraisal', title: '我的鉴定', img: appraisalImg }],
	[17, { path: '/workarea/myFiles/recordFiles', title: '著录中' }],
	[19, { path: '/archived/archived', title: '档案' }],
	[24, { path: '/workarea/myFiles/recordFiles', title: '著录中' }], // 档案管理员
	[25, { path: '/workarea/myFiles/recordFiles', title: '著录中' }], // 部门管理员
	//[67, { path: '/workarea/myFiles/underReview', title: '审核中' }], // 档案管理员
	[18, { path: '/workarea/myFiles/prearchive', title: '预归档' }],
	[27, { path: '/workarea/myFiles/prearchive', title: '预归档' }], // 档案管理员
	[28, { path: '/workarea/myFiles/prearchive', title: '预归档' }], // 部门管理员
	[20, { path: '/workarea/myFiles/expiredFiles', title: '已过期' }],
	[21, { path: '/workarea/myFiles/destroyFiles', title: '已销毁' }],
	[22, { path: '/workarea/myFiles/recycleBin', title: '回收站' }],
	[33, { path: '/workarea/myApplication/borrow', title: '借阅申请' }],
	[34, { path: '/workarea/myApplication/correct', title: '修正申请' }],
	[35, { path: '/workarea/myApproval/borrow', title: '借阅审批' }],
	[36, { path: '/workarea/myApproval/correct', title: '修正审批' }],
	[37, { path: '/workarea/myApproval/borrow', title: '借阅审批' }], // 档案管理员
	[38, { path: '/workarea/myApproval/borrow', title: '借阅审批' }], // 部门管理员
	[66, { path: '/workarea/myApproval/claim', title: '认领审批' }],
	[39, { path: '/settings/system', title: '系统管理', img: systemImg }],
	[41, { path: '/settings/system/all', title: '全宗管理' }],
	[42, { path: '/settings/system/userManager', title: '用户管理' }],
	[43, { path: '/settings/system/roleManager', title: '角色管理' }],
	[44, { path: '/settings/system/departmentManagement', title: '部门管理' }],
	[45, { path: '/settings/system/archiveTypeManagement', title: '档案类型管理' }],
	[69, { path: '/settings/system/ePhotoAlbumManagement', title: '编研类型管理' }],
	[70, { path: '/settings/system/Health', title: '健康检查' }],
	[71, { path: '/settings/system/dataTableManagement', title: '表数据管理' }],
	[72, { path: '/settings/system/sensitiveWordsManagement', title: '敏感词管理' }],
	[73, { path: '/settings/system/metaDataManagement', title: '元数据管理' }],
	[74, { path: '/settings/system/paramsManagement', title: '参数管理' }],
	[75, { path: '/settings/system/exceptionLogManagement', title: '出错记录' }],
	[59, { path: '/settings/system/fileTypeManagement', title: '数据字典' }],
	[56, { path: '/settings/system/systemConfigure', title: '系统配置' }],
	[
		40,
		{
			path: '/face',
			title: '人脸库',
			firstRouteLink: '/face/addFace',
			img: peopleFaceSvg,
		},
	],
	[46, { path: '/face/addFace', title: '录入人脸' }],
	[47, { path: '/face/searchFace', title: '人脸管理' }],
	[48, { path: '/face/faceGroud', title: '人脸分组' }],
	[52, { path: '/workarea', title: '管理', img: managementSvg }],
	[
		53,
		{
			path: '/archived',
			title: '档案',
			firstRouteLink: '/archived/archived',
			img: archivedSvg,
		},
	],
	[
		57,
		{
			path: '/collectedFiles',
			title: '资料',
			firstRouteLink: '/collectedFiles/collectedFiles',
			img: fileSvg,
		},
	], // 为了统一 暂时先用档案的图标
	[58, { path: '/collectedFiles/collectedFiles', title: '资料' }],
	[64, { path: '/collectedFiles/themeManage', title: '主题管理' }],
	[
		62,
		{
			path: '/mine',
			title: '我的',
			firstRouteLink: '/mine/mine',
			img: mineSvg,
		},
	],
])
