import LoginActionType from '../../ActionTypes/Login'
import initialState from '../../InitialState'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState.login, action) {
	switch (action.type) {
		case LoginActionType.FETCHSTART:
			return {
				...state,
				status: '登录中',
			}

		case LoginActionType.FETCHEND:
			return {
				...state,
				status: '已登录',
				data: action.filter,
			}

		case LoginActionType.FETCHFAIL:
			return {
				...state,
				status: '登录失败',
			}

		case LoginActionType.PERMISSION:
			return {
				...state,
				permission: action.filter,
			}

		case LoginActionType.USERTYPE:
			return {
				...state,
				userType: action.filter,
			}

		case LoginActionType.ROLELIST:
			return {
				...state,
				roleList: action.filter,
			}

		case LoginActionType.USER:
			return {
				...state,
				user: action.filter,
			}

		default:
			return state
	}
}
