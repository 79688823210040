import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import { connect } from 'react-redux'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { EPhotoAlbumTypeColumns } from '@/Public/PubTable/pageColumns'
import { ePhotoAlbum } from '@/Utils/Urls.js'
import AddTypeModal from './AddTypeModal'
import EPhotoAlbumTree from './EPhotoAlbumTree'
import TypeDetailModal from './TypeDetailModal' // 查看案卷或档案（文件）详情的模态框
import { treeUtil } from '@/Utils'
import { Space, Button } from 'antd'
import './index.less'
import { deleteTypeThunk, updateTypeThunk, addDossierTypeThunk } from './ActionCreators'

const typeDetailModalRef = React.createRef(null) // 获取查看模态框表单实例
let addmodalRef = React.createRef(null)
let typeDepartmentTreeRef = null // 类型树实例
const markName = 'EPhotoAlbumManagement' // redux的标识
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	deleteType: (...rest) => dispatch(deleteTypeThunk(...rest)), // 删除
	updateType: (...rest) => dispatch(updateTypeThunk(...rest)), // 编辑
	addDossierType: (...rest) => dispatch(addDossierTypeThunk(...rest)), // 新增
})
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class EPhotoAlbumManagement extends Component {
	state = {
		record: null, // 当前操作（查看后入库、回退、加入收藏夹）的行数据
		recordIndex: -1, // 当前操作行数据在当前页数据的 index 值
		selectedNodeMsg: {}, // 选中的树节点的信息
	}

	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{ type: 'Input', name: 'topic', label: '主题名称：', width: '300px' },
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'post',
			url: ePhotoAlbum.searchTheme,
			ContentType: 'application/json',
		},
	}
	// 选中节点触发并重新获取表格信息
	updateSelectNode = selectedNodeMsg => {
		const { pub_getAllData } = this.props
		this.setState({ selectedNodeMsg }, pub_getAllData)
	}
	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 */
	changeSearchLimit = data => {
		const { selectedNodeMsg } = this.state
		// 如果点击根节点或初始状态则获取全部（不传部门id）
		if (JSON.stringify(selectedNodeMsg) !== '{}' && selectedNodeMsg['id'] !== -1) {
			data['id'] = selectedNodeMsg['id']
		}
		if (data.topic) {
			let topic = data.topic
			data.topic = [topic]
		} else {
			delete data.topic
		}
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = data => {
		let records = []
		treeUtil.flatTree(data.records, data => {
			if (data.id !== -1) {
				records.push(data)
			}
		})

		data = {
			data: records,
			pageSize: data.size,
			current: data.current,
			total: data.total,
		}
		return data
	}
	// 重新获取树数据和表格数据
	reloadAllData = () => {
		const { pub_getAllData } = this.props
		pub_getAllData() // 重新获取表格数据
		typeDepartmentTreeRef.getTree() // 重新获取树数据
	}

	// 删除按钮
	handleDeleteType = record => {
		const { deleteType } = this.props // 删除接口
		deleteType(record).then(() => {
			if (record.id === this.state.selectedNodeMsg.id) {
				// 如果删掉了目前选中的节点，那么就不能再次获取当前节点的部门列表了，而是获取全部，因此设置为 {}
				this.setState(
					{
						selectedNodeMsg: {},
					},
					this.reloadAllData
				)
			} else {
				this.reloadAllData()
			}
		})
	}

	// 查看详情模态框
	viewDetailModal = (record, recordIndex) => {
		this.setState({ record, recordIndex }, () => {
			typeDetailModalRef.current.show(formRef => {
				const formData = {
					...record,
					parentId: record.parentTopic,
				}
				formRef.setFieldsValue(formData)
			})
		})
	}

	// 编辑申请
	handleUpdateApply = () => {
		const { updateType, pub_getAllData } = this.props
		let updateData = typeDetailModalRef.current.formRef.getFieldsValue()
		let data = {
			id: updateData.id,
			topic: updateData.topic,
			categoryCode: updateData.categoryCode,
			parentId:
				typeof updateData.parentId === 'number' ? updateData.parentId : null,
		}
		if (updateData) {
			// 通过点击模态框内部的编辑按钮
			updateType(data).then(() => {
				pub_getAllData()
				typeDetailModalRef.current.hide()
			})
		}
	}

	// 查看详情
	returnButton = (record, index) => {
		return (
			<Space>
				<Button onClick={() => this.viewDetailModal(record, index)} size='small'>
					查看
				</Button>
				<Button
					className='table-btn-danger'
					onClick={() => this.handleDeleteType(record)}
					size='small'>
					删除
				</Button>
			</Space>
		)
	}

	// 关闭模态框后一些数据进行初始化
	hideCallback = () => this.setState({ record: null, recordIndex: -1 })
	render() {
		const { addFonds, pub_getAllData, selectedNodeMsg } = this.props
		const { record } = this.state
		return (
			<Fragment>
				<div className='type-ephoto-album-wrapper'>
					{/* 左边部门树组件 */}
					<div id='type-depart-left'>
						<EPhotoAlbumTree
							updateSelectNode={this.updateSelectNode} // 更新选中信息的方法
							selectedNodeMsg={selectedNodeMsg} // 当前树选中节点的信息
							pub_getAllData={pub_getAllData}
							handleDeleteType={this.handleDeleteType} // 删除部门的方法
							getInstance={ref => {
								typeDepartmentTreeRef = ref
							}} // 让页面获取树组件实例的方法，由 WithRef 高阶去调用
						/>
					</div>
					<div id='type-depart-right'>
						<WrappedTable>
							<SearchForm
								name='archiveType-search-form'
								formItems={this.searchFormConfig}
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData}
								markName={markName}
							/>
							<BaseTable
								tableConfig={EPhotoAlbumTypeColumns}
								actions={this.actions}
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData}
								markName={markName}
								operationButton={this.returnButton} // 自定义操作按钮
								withPreview={false}
								notRowSelection
							/>
							{/* 新增类型 */}
							<AddTypeModal
								modalRef={addmodalRef}
								addFonds={addFonds}
								pub_getAllData={pub_getAllData} // 刷新
								record={record} // 表格详情信息
								reloadAllData={this.reloadAllData}
							/>
							{/* 详情宗卷 */}
							<TypeDetailModal
								modalRef={typeDetailModalRef}
								record={record} // 表格详情信息
								handleUpdateApply={this.handleUpdateApply}
							/>
						</WrappedTable>
					</div>
				</div>
			</Fragment>
		)
	}
}
export default EPhotoAlbumManagement
