import { mapUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
import { ADD_FACEDATA } from '@/Redux/ActionTypes/AddFace'
import { Modal, message } from 'antd'

export const addFaceDataAction = (data, current) => {
	return {
		type: ADD_FACEDATA,
		data: data,
		current: current,
	}
}

// 上传文件
export const uploadThunk = file => () =>
	new Promise((resolve, reject) => {
		// 判断上传图片文件格式是否正确
		const fileName = file.multipartFile.name
		const type = fileName.slice(fileName.lastIndexOf('.') + 1)
		if (
			type !== 'jpg' &&
			type !== 'png' &&
			type !== 'jpeg' &&
			type !== 'webp' &&
			type !== 'svg' &&
			type !== 'bmp' &&
			type !== 'eps' &&
			type !== 'cdr' &&
			type !== 'psd' &&
			type !== 'jfif'
		) {
			message.error('不支持此格式')
			return resolve()
		}
		_fetch({
			url: mapUrl.faceUpload,
			type: 'post',
			data: file,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 查询人脸信息
export const getFaceListThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.getFaceList,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 根据姓名精确查询人脸信息
export const selectFaceByNameThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.selectFaceByName,
			type: 'get',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 人脸识别
export const faceRecognitionThunk = fileId => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.faceServiceRecognition,
			type: 'get',
			data: {
				fileId,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 录入人脸及其对应信息
export const faceInformationEntryThunk = data => () =>
	new Promise((resolve, reject) => {
		data.name = data.name.replace(/\s+/g, '') // 名字去空格
		_fetch({
			url: mapUrl.faceInformationEntry,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 删除人脸的thunk
export const deleteFaceThunk = (params, refresh) => () =>
	new Promise((resolve, reject) => {
		Modal.confirm({
			maskClosable: true,
			title: '删除人脸',
			content: '您正在对当前人脸进行删除操作，是否继续？',
			okText: '确定',
			cancelText: '取消',
			onOk: () => {
				_fetch({
					url: mapUrl.faceDelete,
					type: 'get',
					data: {
						...params,
					},
					isJson: true,
				}).then(res => {
					if (res.data.code === 200) {
						message.success('删除成功！')
						refresh()
					}
				})
			},
		})
	})

// 人脸合并
export const mergeFaceThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.mergeFace,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 比较已有的人脸和图片中人脸的相似度
export const compareToExistedUserThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.compareToExistedUser,
			type: 'get',
			data: {
				imageId: data.imageId,
				uid: data.uid,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 替换人脸
export const faceReplaceThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.faceReplace,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 添加人脸
export const addExtraFaceThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.addExtraFace,
			type: 'post',
			data: {
				extraFaceImageId: data.extraFaceImageId.fileId,
				uid: data.uid,
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 添加人脸
export const faceFeatureDeleteThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.faceFeatureDelete,
			type: 'post',
			data: {
				midList: [1543],
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

//导出excel
export const downloadFocusNameExcelThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.downloadFocusNameExcel,
			type: 'get',
			headers: {
				'Content-Type': 'application/json',
			},
			responseType: 'blob',
		}).then(res => {
			if (res) {
				const blob = res.data
				const urlObject = window.URL || window.webkitURL || window
				const export_blob = new Blob([blob], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				})
				const a = document.createElement('a') // 利用a标签特性下载
				a.style.display = 'none'
				const url = urlObject.createObjectURL(export_blob)
				a.href = url
				a.setAttribute('download', '人脸用户.xlsx')
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
				message.success('成功导出！')
				resolve(res.data)
			} else {
				reject()
			}
		})
	})
