import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import { ADD_FIXDATA } from '@/Redux/ActionTypes/FixDetail'
import { APPLYFIX_FINISH } from '@/Redux/ActionTypes/Public/PubForm'
import _fetch from '@/Utils/Fetch'
import { fixApply, correctApprovalUrl } from '@/Utils/Urls'
import { Modal, message } from 'antd'
// 更新红点函数
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
// 消息提示
export const tips = {
	NO_PRE_RECORD: '没有上一项了',
	NO_NEXT_RECORD: '没有下一项了',
	CHOOSE_AT_LEAST_ONE: '请选择一项后再进行操作',
	RECALL_SUCCESS: '撤回成功',
	DELETE_SUCCESS: '删除成功',
	SUBMIT_SUCCESS: '提交成功',
	UPDATE_SUCCESS: '保存成功',
	ONLY_APPLICATION_STATUS: '只能对“待审批状态”下的修正申请进行撤回',
}

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

// 清空申请修正数据
export const finishApplyFixAction = data => {
	return {
		type: APPLYFIX_FINISH,
	}
}

// 添加修正申请表详情
export const addFixDataAction = (data, category) => {
	return {
		type: ADD_FIXDATA,
		data: data,
		category: category, // 申请
	}
}

/**
 * 获取修正审批详情列表
 * @param {Number} id 申请表id
 */
export const getVerifyDetailThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: correctApprovalUrl.getVerifyDetail,
			data: {
				id,
			},
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 删除申请
 * @param {Number} id 申请id
 */
export const deleteFixApplyThunk = id => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '删除修正申请',
			content: '您正在对当前申请进行删除操作，是否继续？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: fixApply.deleteFixApply,
					data: id,
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.DELETE_SUCCESS)
						updataRedDots()
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 撤回申请
 * @param {Number} id 申请id
 */
export const recallFixApplyThunk = id => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '撤回借阅申请',
			content: '您正在对当前申请进行撤回操作，是否继续？',
			onOk: () => {
				_fetch({
					url: fixApply.recallApply,
					data: id,
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.RECALL_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})
