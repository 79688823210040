import { roleManagerUrl, archiveTypeUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
import { message } from 'antd'

// 添加角色的thunk
export const addRoleThunk =
	({ name, description }, reflashTable) =>
	() => {
		_fetch({
			url: roleManagerUrl.addRole,
			type: 'post',
			data: {
				name,
				description,
			},
			isJson: true,
		}).then(res => {
			if (res.data.code === 200) {
				// 重新刷新表格
				reflashTable()
				message.success('添加角色成功！')
			}
		})
	}

// 删除角色
export const deleteRoleThunk = (id, reflashTable) => () => {
	_fetch({
		url: roleManagerUrl.deleteRole,
		type: 'get',
		data: {
			id,
		},
		isJson: true,
	}).then(res => {
		if (res.data.code === 200) {
			// 重新刷新表格
			reflashTable()
			message.success('删除角色成功！')
		}
	})
}

// 获取权限列表
export const getAllPermissionThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: roleManagerUrl.getAllPermission,
			type: 'get',
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取完整树状侧边栏
export const getFullSidebarTree = async () => {
	const res = await _fetch({
		url: roleManagerUrl.getFullSidebarTree,
		type: 'get',
		isJson: true,
	})
	if (res && res.data.code === 200) {
		return res.data
	} else {
		return res
	}
}

// 根据角色id获取侧边栏id列表
export const getSidebarIdsByRoleId = async roleId => {
	const res = await _fetch({
		url: roleManagerUrl.getSidebarIdsByRoleId,
		type: 'get',
		isJson: true,
		data: {
			roleId,
		},
	})
	if (res && res.data.code === 200) {
		return res.data.data
	} else {
		return undefined
	}
}

// 给角色授权
export const authorizeThunk = (
	{
		roleId, // 角色id
		sidebarIds, // 侧边栏ID集合
	},
	{ reflashTable }
) => {
	_fetch({
		url: roleManagerUrl.authorizeBySidebar,
		type: 'post',
		isJson: true,
		data: {
			roleId,
			sidebarIds,
		},
	}).then(res => {
		if (res.data.code === 200) {
			message.success('授权成功！')
			// 刷新表格
			reflashTable()
		}
	})
}

/**
 * 获取分类树
 * @param {Number} id 分类名id（不填查询所有的分类）
 */
export const getDossierTypeTree = async id => {
	const res = await _fetch({
		url: archiveTypeUrl.getDossierType,
		data: {
			id,
		},
	})
	if (res && res.data.code === 200) {
		return res.data
	}
}

//获取角色可访问档案类型列表
export const getListDossierTypeByRoleId = async roleId => {
	const res = await _fetch({
		url: roleManagerUrl.listDossierTypeByRoleId,
		type: 'get',
		data: { roleId },
		isJson: true,
	})
	if (res && res.data.code === 200) {
		return res.data.data.dossierTypeList
	} else {
		return undefined
	}
}

//批量更改可访问档案类型
export const updateBatchType = (
	{
		roleId, //角色id
		categoryCodeIds, //权限id
	},
	{ reflashTable }
) => {
	_fetch({
		url: roleManagerUrl.updateBatchType,
		type: 'post',
		isJson: true,
		data: {
			roleId,
			categoryCodeIds,
		},
	}).then(res => {
		if (res.data.code === 200) {
			message.success('更改成功！')
			//刷新表格
			reflashTable()
		}
	})
}
