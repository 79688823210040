import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import {
	PUBFILE_ADD,
	PUBDOSSIER_ADD,
	OLDDETAILS_ADD,
} from '@/Redux/ActionTypes/Public/PubForm'
import { INITIAL_PUBARCHIVE } from '@/Redux/ActionTypes/PubArchive'
import _fetch from '@/Utils/Fetch'
import { dossierEnum } from '@/Utils/Enum'
import {
	preArchivedUrl,
	workStation,
	recordFileUrl,
	submitFileUrl,
	appraisalUrl,
	toDeleteUrl,
	archiveUrl,
	dossierUrl,
	filesUrl,
	recycleBinUrl,
	collectedFileUrl,
} from '@/Utils/Urls'
import { Modal, message } from 'antd'
import { authUtil } from '@/Utils'
import ApprasialActionType from '@/Redux/ActionTypes/AppraisalDetails'
// 更新红点函数
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
import ExamineModal from './ExamineModal'

const { APPRAISAL_DATA } = ApprasialActionType

const { hasAuth } = authUtil //权限判断方法

// 假请求返回数据
const successRes = {
	data: {
		code: 200,
	},
}

// 消息提示
export const tips = {
	NO_PRE_RECORD: '没有上一项了',
	NO_NEXT_RECORD: '没有下一项了',
	CHOOSE_AT_LEAST_ONE: '请选择至少一项后再进行操作',
	STORAGE_SUCCESS: '入库成功',
	ROLLBACK_SUCCESS: '回退成功',
	WITHDRAW_SUCCESS: '撤回成功',
	ADD_WORKBENCH_CONTENT_SUCCESS: '加入收藏夹成功',
	DELETE_SUCCESS: '删除成功',
	NO_METADATA: '此文件暂无元数据信息',
	VERIFY_APPLY_SUCCESS: '鉴定申请成功',
	ONLY_VERIFY_ARCHIVES: '只允许鉴定文件',
	ONLY_FIX_ONE: '只支持修正单个案卷/文件',
	ONLY_ARCHIVED_ONETYPE: '不支持同时鉴定文件与案卷',
	INCLUDE_NOT_ARCHIVED: '非已过期文件不能鉴定',
	ARCHIVE_SUCCESS: '归档成功',
	SEARCH_SUCCESS: '查询成功',
	REVIEW_SUCCESS: '归档成功',
	UPDATE_SUCCESS: '更新成功',
	ASSIGN_SUCCESS: '指派成功',
	DELETE_FAVORITE_SUCCESS: '已取消收藏',
}

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

export const addPubFileAction = data => {
	return {
		type: PUBFILE_ADD,
		data: data,
	}
}

export const addPubDossierAction = data => {
	return {
		type: PUBDOSSIER_ADD,
		data: data,
	}
}

export const addOldDetailsAction = (data, current) => {
	return {
		type: OLDDETAILS_ADD,
		data: data,
		current: current,
	}
}

// 初始化pubArchive[markName].old
export const inititalPubArchiveAction = (markName, pubArchiveData) => {
	return {
		type: INITIAL_PUBARCHIVE,
		filter: {
			markName,
			...pubArchiveData,
		},
	}
}

// 添加鉴定数据
export const addAppraisalDataToRedux = data => {
	return {
		type: APPRAISAL_DATA,
		data: data,
	}
}

/**
 * 获取案卷或档案（文件）详情
 * @param {Object} record 案卷或档案（文件）的信息
 */
export const getDetailThunk = record => () =>
	new Promise(async (resolve, reject) => {
		const { id, type, archiveType } = record
		let url = null
		switch (dossierEnum.type[type]) {
			case '案卷':
				url = dossierUrl.getDossierDetail
				break
			case '文件':
				archiveType === 1
					? (url = archiveUrl.getArchiveDetail)
					: (url = archiveUrl.getDocDetail)
				break
			default:
				return
		}
		let res = await _fetch({
			url,
			data: {
				id,
			},
		})
		if (res && res.data.code === 200) {
			if (type) {
				resolve({
					...res.data.data,
					type: record.type,
				})
			} else {
				// 案卷顺带请求其下的列表
				_fetch({
					url: archiveUrl.getArchiveList,
					type: 'get',
					data: {
						id,
						size: 999,
					},
					isJson: true,
				}).then(data => {
					if (data && data.data.code === 200) {
						res.data.data['archiveDetail'] = data.data.data.records || []
						resolve({
							...res.data.data,
							type: record.type,
						})
					} else {
						reject()
					}
				})
			}
		} else {
			reject()
		}
	})

/**
 * 将文档设置为确定关联状态
 * @param {array} archiveId 档案id
 *
 */
// const setLinkArchiveCertain = (archiveId) => new Promise((resolve, reject) => {
//   _fetch({
//     url: preArchivedUrl.setLinkArchiveCertain,
//     type: "post",
//     data: {
//       archiveId
//     },
//     isJson: true
//   }).then(res => {
//     if (res && res.data.code === 200) {
//       resolve(res.data);
//     } else {
//       reject();
//     }
//   })
// })

/**
 * 案卷或档案（文件）入库
 * @param {Object[]} records 案卷或档案（文件）的内容
 */
export const storageThunk = (records, changeSpinning) => () =>
	new Promise(resolve => {
		const dossierIds = [],
			archiveIds = []
		records.forEach(record => {
			if (record.type === dossierEnum.type['案卷']) dossierIds.push(record.id)
			else archiveIds.push(record.id)
		})
		const hasDossier = dossierIds.length > 0 // 是否有案卷
		const hasArchive = archiveIds.length > 0 // 是否有文件
		const typeName =
			hasDossier && hasArchive ? '案卷和文件' : hasDossier ? '案卷' : '文件'
		Modal.confirm({
			maskClosable: true,
			title: `${typeName}入库`,
			content: `您正在对“${typeName}”进行入库操作，是否继续？`,
			zIndex: 1005,
			onOk: () => {
				changeSpinning && changeSpinning(true)
				// 将文档设置为确定关联状态
				// if (hasArchive) {
				//   setLinkArchiveCertain(archiveIds)
				// }
				// 对案卷请求入库
				const dossierFetch = hasDossier
					? _fetch({
							url: preArchivedUrl.storageDossier,
							data: {
								ids: dossierIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				// 对档案请求入库
				const archiveFetch = hasArchive
					? _fetch({
							url: preArchivedUrl.storageArchive,
							data: {
								ids: archiveIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				Promise.all([dossierFetch, archiveFetch]).then(([res1, res2]) => {
					changeSpinning && changeSpinning(false)
					if (
						res1 &&
						res1.data.code === 200 &&
						res2 &&
						res2.data.code === 200
					) {
						message.success(tips.STORAGE_SUCCESS)
						updataRedDots()
						resolve()
					}
				})
			},
		})
	})

/**
 * 管理员回退或撤回案卷或档案（文件）
 * @param {Object[]} records 案卷或档案（文件）的内容
 * @param {String} rollbackMessage 回退意见
 */
export const managerRollbackThunk = (records, rollbackMessage) => () =>
	new Promise((resolve, reject) => {
		const dossierIds = [],
			archiveIds = []
		records.forEach(record => {
			if (record.type === dossierEnum.type['案卷']) dossierIds.push(record.id)
			else archiveIds.push(record.id)
		})
		const hasDossier = dossierIds.length > 0 // 是否有案卷
		const hasArchive = archiveIds.length > 0 // 是否有文件
		const typeName =
			hasDossier && hasArchive ? '案卷和文件' : hasDossier ? '案卷' : '文件'
		const waitForVerifyUser = records[0].waitForVerifyUser // 当前被点击的文件&&案卷的被指派者id
		const userId = JSON.parse(localStorage.getItem('userMessage')).user.id // 当前操作者id
		let verifyCode // 撤回或回退操作的验证码
		let operation // 提示正在进行什么操作
		let successTip // 撤回或回退操作成功消息提示
		if (12 === records[0].status && waitForVerifyUser !== userId) {
			// 判断是 审核中查看状态下的撤回操作 还是 回退操作
			verifyCode = 0
			rollbackMessage = '撤回'
			operation = '撤回'
			successTip = tips.WITHDRAW_SUCCESS
		} else {
			verifyCode = 1
			operation = '回退'
			successTip = tips.ROLLBACK_SUCCESS
		}
		Modal.confirm({
			maskClosable: true,
			title: `${typeName}${operation}`,
			content: `您正在对“${typeName}”进行${operation}操作，是否继续？`,
			zIndex: 1005,
			onOk: () => {
				// 对案卷请求回退或撤回
				const dossierFetch = hasDossier
					? _fetch({
							url: preArchivedUrl.managerRollbackDossier,
							data: {
								ids: dossierIds,
								message: rollbackMessage,
								verifyCode,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				// 对档案请求回退或撤回
				const archiveFetch = hasArchive
					? _fetch({
							url: preArchivedUrl.managerRollbackArchive,
							data: {
								ids: archiveIds,
								message: rollbackMessage,
								verifyCode,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				Promise.all([dossierFetch, archiveFetch]).then(([res1, res2]) => {
					if (
						res1 &&
						res1.data.code === 200 &&
						res2 &&
						res2.data.code === 200
					) {
						message.success(successTip)
						updataRedDots()
						resolve()
					} else {
						reject()
					}
				})
			},
		})
	})

/**
 * 添加文件/案卷到收藏夹
 * @param {Number} workbenchId 收藏夹id
 * @param {Object[]} ids 案卷/文件的数组
 * {
 *  contentId : 案卷/文件的数组,
 *  type: 档案类型：0-案卷 1-文件
 * }
 */
export const addWorkbenchContentThunk = (workbenchId, ids) => () =>
	new Promise((resolve, reject) => {
		//获取收藏夹的档案信息
		_fetch({
			url: workStation.getWorkbenchContentList,
			data: {
				workbenchId,
				type: 2,
				status: 6,
			},
			type: 'post',
			isJson: true,
		}).then(res => {
			let idArr = []
			if (res && res.data.code === 200) {
				for (let i = 0; i < res.data.data.records.length; i++) {
					idArr.push(res.data.data.records[i].id)
				}
				ids = ids.filter(id => idArr.includes(id.contentId) === false)
				if (ids.length === 0) {
					message.error('收藏夹已包含该档案')
				} else {
					_fetch({
						url: workStation.addWorkbenchContent,
						data: {
							workbenchId,
							ids,
						},
						type: 'post',
						isJson: true,
					}).then(res => {
						if (res && res.data.code === 200) {
							message.success(tips.ADD_WORKBENCH_CONTENT_SUCCESS)
							resolve()
						} else {
							reject()
						}
					})
				}
				resolve()
			} else {
				reject()
			}
		})
	})

/**
 * 删除案卷或档案（文件）
 * @param {Object[]} records 案卷或档案（文件）的内容
 */
export const toDeleteThunk = records => () =>
	new Promise((resolve, reject) => {
		const dossierIds = [],
			archiveIds = []
		records.forEach(record => {
			if (record.type === dossierEnum.type['案卷']) dossierIds.push(record.id)
			else archiveIds.push(record.id)
		})
		const hasDossier = dossierIds.length > 0 // 是否有案卷
		const hasArchive = archiveIds.length > 0 // 是否有文件
		const typeName =
			hasDossier && hasArchive ? '案卷和文件' : hasDossier ? '案卷' : '文件'
		Modal.confirm({
			maskClosable: true,
			title: `${typeName}删除`,
			content: `您正在对“${typeName}”进行删除操作，是否继续？`,
			zIndex: 1005,
			onOk: () => {
				// 对案卷请求删除
				const dossierFetch = hasDossier
					? _fetch({
							url: hasAuth(toDeleteUrl.managerDeleteDossier)
								? toDeleteUrl.managerDeleteDossier
								: toDeleteUrl.userDeleteDossier, // 判断权限
							data: {
								ids: dossierIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				// 对档案请求删除
				const archiveFetch = hasArchive
					? _fetch({
							url: hasAuth(toDeleteUrl.managerDeleteArchive)
								? toDeleteUrl.managerDeleteArchive
								: toDeleteUrl.userDeleteArchive, // 判断权限
							data: {
								ids: archiveIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				Promise.all([dossierFetch, archiveFetch]).then(([res1, res2]) => {
					if (
						res1 &&
						res1.data.code === 200 &&
						res2 &&
						res2.data.code === 200
					) {
						message.success(tips.DELETE_SUCCESS)
						updataRedDots()
						resolve()
					} else {
						reject()
					}
				})
			},
		})
	})

export const searchAssginPeople = data =>
	new Promise((resolve, reject) => {
		_fetch({
			url: submitFileUrl.searchUser,
			method: 'GET',
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success(tips.SEARCH_SUCCESS)
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * @description: 档案指派
 * @param {Number[]} ids 档案的id数组
 */

export const assignFileThunk = records => () =>
	new Promise((resolve, reject) => {
		const dossierIds = [],
			departmentNames = [],
			departmentIds = [],
			archiveIds = []
		records.forEach(record => {
			departmentNames.push(record.departmentName)
			departmentIds.push(record.departmentId)
			if (record.type === dossierEnum.type['案卷']) dossierIds.push(record.id)
			else archiveIds.push(record.id)
		})
		const hasDossier = dossierIds.length > 0 // 是否有案卷
		const hasArchive = archiveIds.length > 0 // 是否有文件
		const typeName =
			hasDossier && hasArchive ? '案卷和文件' : hasDossier ? '案卷' : '文件'
		// 判断已选择文件数目
		let departmentName = departmentNames.length === 1 ? departmentNames[0] : undefined
		let departmentId = departmentIds.length === 1 ? departmentIds[0] : undefined
		let onSummit

		const getFormData = value => {
			onSummit = value
		}

		Modal.confirm({
			maskClosable: false,
			icon: <></>,
			title: `${typeName}档案`,
			content: (
				<ExamineModal
					typeName={typeName}
					getFormData={getFormData}
					departmentName={departmentName}
					departmentId={departmentId}
				/>
			),
			width: 900,
			onOk: close => {
				onSummit()
					.then(value => {
						_fetch({
							url: submitFileUrl.submitToUser,
							data: {
								archiveIds,
								dossierIds,
								workId: value.workId,
								message: value.message,
							},
							type: 'post',
							isJson: true,
						}).then(res => {
							if (res && res.data.code === 200) {
								message.success(tips.ASSIGN_SUCCESS)
								resolve()
								close()
							} else {
								reject()
							}
						})
					})
					.catch(() => {})
			},
			onCancel: () => {
				resolve()
			},
		})
	})

/**
 * 档案审核
 * @param {Number[]} ids 档案的id数组
 */
export const reviewFileThunk = records => () =>
	new Promise((resolve, reject) => {
		const dossierIds = [],
			archiveIds = [],
			needVerifyDossierIds = [],
			needVerifyArchiveIds = []
		const needVerifyDossierType =
			JSON.parse(localStorage.getItem('needVerifyDossierType')) || {}

		records.forEach(record => {
			// 案卷或文件  是否需要审核
			if (record.type === dossierEnum.type['案卷']) {
				if (needVerifyDossierType[record?.categoryCode]) {
					needVerifyDossierIds.push(record.id)
				} else {
					dossierIds.push(record.id)
				}
			} else {
				if (needVerifyDossierType[record?.categoryCode]) {
					needVerifyArchiveIds.push(record.id)
				} else {
					archiveIds.push(record.id)
				}
			}
		})
		const hasDossier = dossierIds.length > 0 // 是否有案卷
		const hasArchive = archiveIds.length > 0 // 是否有文件
		const hasNeedVerifyDossier = needVerifyDossierIds.length > 0 // 是否有需要审核的案卷
		const hasNeedVerifyArchiveIds = needVerifyArchiveIds.length > 0 // 是否有需要审核的文件
		const typeName =
			hasDossier && hasArchive ? '案卷和文件' : hasDossier ? '案卷' : '文件'

		Modal.confirm({
			maskClosable: true,
			title: `${typeName}归档`,
			content: `您正在对“${typeName}”进行归档操作，是否继续？`,
			onOk: () => {
				// 对案卷请求审核
				const dossierFetch = hasNeedVerifyDossier
					? _fetch({
							url: submitFileUrl.submitDossierVerify,
							data: {
								dossierIds: needVerifyDossierIds,
							},
							type: 'post',
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						})
					: successRes
				// 对档案请求审核
				const archiveFetch = hasNeedVerifyArchiveIds
					? _fetch({
							url: submitFileUrl.submitToVerify,
							data: {
								archiveIds: needVerifyArchiveIds,
							},
							type: 'post',
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						})
					: successRes
				// 对案卷请求归档
				const dossierFile = hasDossier
					? _fetch({
							url: recordFileUrl.archiveDossier,
							data: {
								ids: dossierIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				// 对文件请求归档
				const archiveFile = hasArchive
					? _fetch({
							url: recordFileUrl.archiveFile,
							data: {
								ids: archiveIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				Promise.all([dossierFetch, archiveFetch, dossierFile, archiveFile]).then(
					([res1, res2, res3, res4]) => {
						if (
							res1 &&
							res1.data.code === 200 &&
							res2 &&
							res2.data.code === 200 &&
							res3 &&
							res3.data.code === 200 &&
							res4 &&
							res4.data.code === 200
						) {
							message.success(tips.REVIEW_SUCCESS)
							updataRedDots()
							resolve()
						} else {
							reject()
						}
					}
				)
			},
			onCancel: () => {
				resolve()
			},
		})
	})

/**
 * 档案归档
 * @param {Number[]} ids 档案的id数组
 */
export const archiveFileThunk = records => () =>
	new Promise((resolve, reject) => {
		const dossierIds = [],
			archiveIds = []
		records.forEach(record => {
			if (record.type === dossierEnum.type['案卷']) dossierIds.push(record.id)
			else archiveIds.push(record.id)
		})
		const hasDossier = dossierIds.length > 0 // 是否有案卷
		const hasArchive = archiveIds.length > 0 // 是否有文件
		const typeName =
			hasDossier && hasArchive ? '案卷和文件' : hasDossier ? '案卷' : '文件'
		Modal.confirm({
			maskClosable: true,
			title: `${typeName}归档`,
			content: `您正在对“${typeName}”进行归档操作，是否继续？`,
			onOk: () => {
				// 对案卷请求归档
				const dossierFetch = hasDossier
					? _fetch({
							url: recordFileUrl.archiveDossier,
							data: {
								ids: dossierIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				// 对档案请求归档
				const archiveFetch = hasArchive
					? _fetch({
							url: recordFileUrl.archiveFile,
							data: {
								ids: archiveIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				Promise.all([dossierFetch, archiveFetch]).then(([res1, res2]) => {
					if (
						res1 &&
						res1.data.code === 200 &&
						res2 &&
						res2.data.code === 200
					) {
						message.success(tips.ARCHIVE_SUCCESS)
						updataRedDots()
						resolve()
					} else {
						reject()
					}
				})
			},
		})
	})

/**
 * 一键改状态
 */
export const oneKeyChangeStatusThunk = status => () =>
	new Promise((resolve, reject) => {
		const statusMap = {
			1: '归档',
			2: '入库',
		}
		Modal.confirm({
			maskClosable: true,
			title: `一键${statusMap[status.newStatus]}`,
			content: `您确定进行一键${statusMap[status.newStatus]}操作吗？`,
			onOk: () => {
				_fetch({
					url: dossierUrl.oneClickChange,
					type: 'post',
					data: {
						oldStatus: status.oldStatus,
						newStatus: status.newStatus,
						archiveType: status.archiveType || 3, // 默认全部
						type: status.type || 2, // 默认全部
					},
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.STORAGE_SUCCESS)
						updataRedDots()
						resolve(res.data.data)
					} else {
						reject()
					}
				})
			},
		})
	})

/**
 * 档案鉴定申请
 * @param {Object} fetchData 请求数据
 */
export const verifyApplyThunk = fetchData => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: appraisalUrl.verifyApply,
			type: 'post',
			data: fetchData,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success(tips.VERIFY_APPLY_SUCCESS)
				updataRedDots()
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 销毁案卷或档案（文件）
 * @param {Object[]} records 案卷或档案（文件）的内容
 */
export const toDestroyThunk = records => () =>
	new Promise((resolve, reject) => {
		const dossierIds = [],
			archiveIds = []
		records.forEach(record => {
			if (record.type === dossierEnum.type['案卷']) dossierIds.push(record.id)
			else archiveIds.push(record.id)
		})
		const hasDossier = dossierIds.length > 0 // 是否有案卷
		const hasArchive = archiveIds.length > 0 // 是否有文件
		const typeName =
			hasDossier && hasArchive ? '案卷和文件' : hasDossier ? '案卷' : '文件'
		Modal.confirm({
			maskClosable: true,
			title: `${typeName}删除`,
			content: `您正在对“${typeName}”进行删除操作，是否继续？`,
			zIndex: 1005,
			onOk: () => {
				// 对案卷请求删除
				const dossierFetch = hasDossier
					? _fetch({
							url: hasAuth(recycleBinUrl.managerDestroyDossier)
								? recycleBinUrl.managerDestroyDossier
								: recycleBinUrl.userDestroyDossier, // 判断权限
							data: {
								ids: dossierIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				// 对档案请求删除
				const archiveFetch = hasArchive
					? _fetch({
							url: hasAuth(recycleBinUrl.managerDestroyArchive)
								? recycleBinUrl.managerDestroyArchive
								: recycleBinUrl.userDestroyArchive, // 判断权限
							data: {
								ids: archiveIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				Promise.all([dossierFetch, archiveFetch]).then(([res1, res2]) => {
					if (
						res1 &&
						res1.data.code === 200 &&
						res2 &&
						res2.data.code === 200
					) {
						message.success(tips.DELETE_SUCCESS)
						updataRedDots()
						resolve()
					} else {
						reject()
					}
				})
			},
		})
	})

/**
 * 还原案卷或档案（文件）
 * @param {Object[]} records 案卷或档案（文件）的内容
 */
export const toRestoreThunk = records => () =>
	new Promise((resolve, reject) => {
		const dossierIds = [],
			archiveIds = []
		records.forEach(record => {
			if (record.type === dossierEnum.type['案卷']) dossierIds.push(record.id)
			else archiveIds.push(record.id)
		})
		const hasDossier = dossierIds.length > 0 // 是否有案卷
		const hasArchive = archiveIds.length > 0 // 是否有文件
		const typeName =
			hasDossier && hasArchive ? '案卷和文件' : hasDossier ? '案卷' : '文件'
		Modal.confirm({
			maskClosable: true,
			title: `${typeName}还原`,
			content: `您正在对“${typeName}”进行还原操作，是否继续？`,
			zIndex: 1005,
			onOk: () => {
				// 对案卷请求删除
				const dossierFetch = hasDossier
					? _fetch({
							url: hasAuth(recycleBinUrl.managerResortDossier)
								? recycleBinUrl.managerResortDossier
								: recycleBinUrl.userResortDossier, // 判断权限
							data: {
								ids: dossierIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				// 对档案请求删除
				const archiveFetch = hasArchive
					? _fetch({
							url: hasAuth(recycleBinUrl.managerRestoreArchive)
								? recycleBinUrl.managerRestoreArchive
								: recycleBinUrl.userRestoreArchive, // 判断权限
							data: {
								ids: archiveIds,
							},
							type: 'post',
							isJson: true,
						})
					: successRes
				Promise.all([dossierFetch, archiveFetch]).then(([res1, res2]) => {
					if (
						res1 &&
						res1.data.code === 200 &&
						res2 &&
						res2.data.code === 200
					) {
						message.success(tips.RESTORY_SUCCESS)
						updataRedDots()
						resolve()
					} else {
						reject()
					}
				})
			},
		})
	})

/**
 * 著录编辑
 * @param {object} data 申请数据
 */
export const updateRecordThunk = data => () =>
	new Promise(resolve => {
		const typeName = data.type === 0 ? '案卷' : '文件'
		const dataDetail = data.type === 0 ? data.dossier : data.archive
		Modal.confirm({
			title: `${typeName}编辑`,
			maskClosable: true,
			content: '确定保存？',
			okType: 'danger',
			onOk: () => {
				if (data.type === 0) {
					// 对案卷请求归档
					_fetch({
						url: recordFileUrl.updateDossierDetail,
						isJson: true,
						data: {
							id: dataDetail.id,
							categoryCodeId: dataDetail.categoryCodeId,
							confidentialLevel: dataDetail.confidentialLevel,
							retentionPeriod: dataDetail.retentionPeriod,
							departmentId: dataDetail.departmentId,
							topic: dataDetail.topic,
							keyWord: dataDetail.keyWord,
						},
						type: 'post',
					}).then(res => {
						if (res && res.data.code === 200) {
							message.success(tips.UPDATE_SUCCESS)
							resolve()
						}
					})
				} else {
					// 对档案请求归档
					_fetch({
						url: recordFileUrl.updateArchiveDetail,
						isJson: true,
						data: {
							id: dataDetail.id,
							categoryCode: dataDetail.categoryCode,
							confidentialLevel: dataDetail.confidentialLevel,
							retentionPeriod: dataDetail.retentionPeriod,
							departmentId: dataDetail.departmentId,
						},
						type: 'post',
					}).then(res => {
						if (res && res.data.code === 200) {
							message.success(tips.UPDATE_SUCCESS)
							resolve()
						}
					})
				}
			},
		})
	})

/**
 * 获取元数据结构树
 * @param {Number} id 申请表id
 */
export const getMetaStructTreeThunk = formItems => () =>
	new Promise(resolve => {
		_fetch({
			url: filesUrl.getFileMetadataStructTree,
			type: 'get',
		})
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data.data)
				}
			})
			.catch(err => {
				//console.log('获取元数据结构树失败' + err)
			})
	})

/**
 * 新建收藏
 * @param {Object} data
 */
export const addWorkbenchThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: workStation.addWorkbench,
			isJson: true,
			data,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取案卷最新的回退记录getDossierRollbackRecord
export const getDossierRollbackRecord = dossierId => () =>
	new Promise(resolve => {
		_fetch({
			url: recordFileUrl.getDossierRollbackRecord,
			data: {
				id: dossierId, //档案id
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

// 获取档案最新的回退记录
export const getArchiveRollbackRecord = archiveId => () =>
	new Promise(resolve => {
		_fetch({
			url: recordFileUrl.getArchiveRollbackRecord,
			data: {
				id: archiveId, //档案id
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

// 清除档案和案卷回收站，即清除处于删除状态的档案和案卷
export const emptyRecycleBinThunk = () => () =>
	new Promise(resolve => {
		_fetch({
			url: recycleBinUrl.emptyRecycleBin,
		}).then(res => {
			if (res && res.data.code === 200) {
				updataRedDots()
				resolve()
			}
		})
	})

//新增主题
export const addNewThemeThunk = formData =>
	new Promise(resolve => {
		_fetch({
			url: collectedFileUrl.addNewTheme,
			type: 'post',
			data: formData,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve()
				_fetch({
					url: collectedFileUrl.getMyTheme,
					type: 'post',
				}).then(res => {
					if (res && res.data.code === 200) {
						localStorage.setItem('theme', JSON.stringify(res.data.data))
					} else {
						throw Error(res)
					}
				})
			}
		})
	})
// 取消收藏
export const deleteWorkbenchContentThunk = (data, workbenchId) => () =>
	new Promise((resolve, reject) => {
		let content = []
		data.forEach(item => {
			content.push({
				contentId: item.id,
				type: item.type,
				workbenchId,
			})
		})
		Modal.confirm({
			maskClosable: true,
			title: '取消收藏',
			content: '是否取消收藏该档案？',
			zIndex: 1005,
			onOk: () => {
				_fetch({
					url: workStation.deleteWorkbenchContent,
					isJson: true,
					data: content,
					type: 'post',
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.DELETE_FAVORITE_SUCCESS)
						updataRedDots()
						resolve()
					} else {
						reject()
					}
				})
			},
		})
	})
