import {
	filesUrl,
	addArchivesUrl,
	archiveTypeUrl,
	departmentUrl,
	archiveUrl,
	mapUrl,
	recordFileUrl,
} from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
import SparkMD5 from 'spark-md5'
import asyncPool from '@/Utils/AsyncPool'
import generateScriptUrl from '@/Utils/generateScriptUrl'
import readChunkWorkerCode from './readChunkWorkerCode'

const spark = new SparkMD5.ArrayBuffer()

// 新建临时档案
export const addTemporaryArchiveThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.addTemporaryArchive,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 查询临时文件
export const selectTemporaryArchiveThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.selectTemporaryArchive,
			type: 'get',
			isJson: true,
			headers: {
				'Cache-control': 'no-store',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取全宗号
export const getFondsIdentifierThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: addArchivesUrl.getFondsIdentifier,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取类别号
export const getDossierTypeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: archiveTypeUrl.getDossierType,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取部门树
export const getDepartmentTreeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: departmentUrl.getDepartmentTree,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 转换临时档案为新档案
export const changeTemporaryArchiveToNormalArchiveThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.changeTemporaryArchiveToNormalArchive,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 建立人脸信息和档案的关系
export const replaceArchiveFacesThunk = (faceIds, archiveId) => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.replaceArchiveFaces,
			type: 'post',
			data: {
				recognitionUserIdList: faceIds,
				archiveId,
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 删除临时档案
export const deleteTemporaryArchiveThunk = ids => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.deleteTemporaryArchive,
			type: 'post',
			data: ids,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取文件详情
export const getArchiveDetailThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: archiveUrl.getArchiveDetail,
			type: 'get',
			data: {
				id,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 新增案卷
export const insertDossierThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: addArchivesUrl.insertDossier,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 档案组卷
export const packArchiveThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: addArchivesUrl.packArchive,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 复制临时档案信息到目标临时档案
export const fillTemporaryArchiveThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.fillTemporaryArchive,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 人脸识别
export const faceRecognitionThunk = fileId => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.faceRecognition,
			type: 'get',
			data: {
				fileId,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 识别照片经纬度信息
export const extraInformationRecognitionThunk = fileId => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.extraInformationRecognition,
			type: 'get',
			data: {
				fileId,
			},
		}).then(res => {
			if (res && res?.data?.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 识别照片经纬度信息
export const getImageMetadataThunk = fileId => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.getImageMetadata,
			type: 'get',
			data: {
				fileId,
			},
		}).then(res => {
			//该部分由于后台接口停掉了人脸部分，因此返回数据中 res.data的值为null，所以以下代码无法执行，需要重新写
			// if (res && res.data.code === 200)

			if (res && res.status === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

/**
 * @description: 上传文件并转换成pdf
 * @param {file} file 来自customRquest的file.file
 */
export const pdfConvert = file => {
	const formData = new FormData()
	formData.append('file', file)
	return _fetch({
		url: filesUrl.pdfConvert,
		type: 'post',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

/**
 * 文件切片
 * @param {file} file
 */
export const createFileChunk = file => {
	const fileChunkList = []
	const CHUNK_SIZE = 2097152 // 切片大小为 2M

	for (let start = 0; start < file.size; start += CHUNK_SIZE) {
		fileChunkList.push({
			file: file.slice(start, start + CHUNK_SIZE),
		})
	}

	return fileChunkList
}

/**
 * 创建文件的 md5 标识
 * @param {array} fileChunkList
 */
export const getFileMd5 = fileChunkList => {
	// let count = 0
	// const totalCount = fileChunkList.length
	// const fileReader = new FileReader()
	// return new Promise((resolve, reject) => {
	//   fileReader.onload = e => {
	//     if (e.target && e.target.result) {
	//       count += 1
	//       spark.append(e.target.result)
	//     }
	//     if (count < totalCount) {
	//       loadNext()
	//     } else {
	//       resolve(spark.end())
	//     }
	//   }

	//   fileReader.onerror = function () {
	//     reject({
	//       message: '文件读取失败',
	//     })
	//   }

	//   const loadNext = () => {
	//     fileReader.readAsArrayBuffer(fileChunkList[count]?.file)
	//   }

	//   loadNext()
	// })

	/**
	 * 优化前👆
	 * 优化后👇
	 */

	return new Promise(resolve => {
		/**
		 * 定义获取/修改变量的方法是为了消除ESLint的Warning：
		 * Function declared in a loop contains unsafe references to variable(s) 'xxx'
		 */
		let finishWorkerCount = 0 // 已处理完成切片的线程数
		const addFinishWorkerCount = () => {
			finishWorkerCount++
		}
		const getFinishWorkerCount = () => finishWorkerCount
		let allChunkDataResults = [] // 所有切片数据最终的读取结果
		const getAllChunkDataResults = () => allChunkDataResults

		const TOTAL_CHUNK_COUNT = fileChunkList.length // 文件切片总数
		let threadCount = navigator.hardwareConcurrency || 4 // 开启线程数为 CPU核数或4
		const WORKER_CHUNK_COUNT = Math.ceil(TOTAL_CHUNK_COUNT / threadCount) // 每个线程处理的切片数

		// 分配切片数后删去未分配到切片的线程
		while (WORKER_CHUNK_COUNT * threadCount > TOTAL_CHUNK_COUNT) {
			threadCount -= 1
		}

		for (let i = 0; i < threadCount; i++) {
			const worker = new Worker(generateScriptUrl(readChunkWorkerCode), {
				type: 'module',
			})

			// 每个线程处理的切片起始索引
			let startIndex = i * WORKER_CHUNK_COUNT
			let endIndex = startIndex + WORKER_CHUNK_COUNT - 1
			// 例：100个切片，16个线程，则每个线程需处理7个切片，其中最后一个线程的startIndex为105（>100），它不需处理任何切片（因为所有切片已分配给之前的线程处理），故直接终止
			if (startIndex > TOTAL_CHUNK_COUNT - 1) {
				worker.terminate()
				addFinishWorkerCount()
				return
			}
			// 例：100个切片，8个线程，则每个线程需处理13个切片，其中最后一个线程的endIndex为104（>100），它只需处理索引为[91...99]的共10个切片，故重新赋值endIndex
			if (endIndex > TOTAL_CHUNK_COUNT - 1) {
				endIndex = TOTAL_CHUNK_COUNT - 1
			}

			// 向worker线程发送数据
			worker.postMessage({ fileChunkList, startIndex, endIndex })

			// 接收worker线程的数据
			worker.onmessage = e => {
				getAllChunkDataResults()[i] = e.data
				worker.terminate() // 处理完切片，终止线程
				addFinishWorkerCount()

				// 所有线程都处理完切片，返回结果
				if (getFinishWorkerCount() === threadCount) {
					const allChunkDataResults = getAllChunkDataResults().flat() // 扁平化数组
					for (
						let workerIndex = 0;
						workerIndex < allChunkDataResults.length;
						workerIndex++
					) {
						spark.append(allChunkDataResults[workerIndex]) // 添加已经读取的切片数据
					}
					resolve(spark.end())
				}
			}
		}
	})
}

const getChunk = wholeMd5 => {
	return _fetch({
		url: filesUrl.getChunk,
		type: 'get',
		data: {
			wholeMd5,
		},
		isJson: true,
	})
}

/**
 * 上传文件（断点续传）
 * @param {*} fileMd5Value
 * @param {*} fileName
 * @param {*} fileChunkList
 */
export const uploadFile = async (
	fileMd5Value,
	fileName,
	fileChunkList,
	fileType,
	files
) => {
	const total = fileChunkList.length
	let fileCurrent = (await getChunk(fileMd5Value))?.data?.data + 1
	let progressCurrent = fileCurrent

	fileChunkList = fileChunkList.map(fileChunk => {
		return new File([fileChunk.file], fileName, {
			type: fileType,
			lastModified: Date.now(),
		})
	})

	// eslint-disable-next-line
	for await (const _ of asyncPool(
		2,
		fileChunkList.slice(fileCurrent - 1, total - 1),
		file => {
			const formData = new FormData()
			formData.append('file', file)
			formData.append('wholeMd5', fileMd5Value)
			formData.append('chunks', total)
			formData.append('chunk', fileCurrent)

			fileCurrent++
			return _fetch({
				url: filesUrl.fileUpload,
				type: 'post',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}).then(() => {
				files.onProgress(
					{
						percent: ((progressCurrent / fileChunkList.length) * 100) | 0,
					},
					files.file
				)
				progressCurrent++
			})
		}
	)) {
	}

	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const file = fileChunkList[total - 1]

		formData.append('file', file)
		formData.append('wholeMd5', fileMd5Value)
		formData.append('chunks', total)
		formData.append('chunk', total)

		_fetch({
			url: filesUrl.fileUpload,
			type: 'post',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				files.onProgress(
					{
						percent: 100,
					},
					files.file
				)
				resolve(res.data)
			} else {
				reject(res.data)
			}
		})
	})
}

// 更新附件
export const updateAttachmentThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.updateAttachment,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取表格数据
export const getArchiveListThunk = record => () =>
	new Promise(async (resolve, reject) => {
		// 案卷顺带请求其下的列表
		_fetch({
			url: archiveUrl.getArchiveList,
			type: 'get',
			data: {
				id: record,
				size: 999,
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data.records)
			} else {
				reject()
			}
		})
	})

//更新案卷信息
export const updateRecordThunk = data => () =>
	new Promise(resolve => {
		// 对档案请求编辑
		_fetch({
			url: recordFileUrl.updateDossierDetail,
			isJson: true,
			data,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				// message.success("编辑成功");
				resolve()
			}
		})
	})
