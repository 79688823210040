import React, { Fragment } from 'react'
import { Space, Button, Modal, Form, Input, message, TreeSelect, Radio } from 'antd'
import { PlusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import MyEditor from '@/Public/WangEditor/index'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import Enum from '@/Utils/Enum'
import { ePhotoAlbumColumns } from '@/Public/PubTable/pageColumns'
import { ePhotoAlbum, departmentUrl } from '@/Utils/Urls' // 待换
import {
	getThemeTreeThunk,
	addThemeThunk,
	pullThunk,
	saveThunk,
	publishRichTextThunk,
	deleteTypeThunk,
} from './ActionCreators'
import { treeUtil } from '@/Utils'
import DossierTypeTree from './DossierTypeTree'
import './index.less'
import { useState, useRef, useEffect } from 'react'
const markName = 'EPhotoAlbum' // redux的标识
const searchType = new Enum(['编辑', '完成', '封存']) // 主题搜索类型
const themeType = new Enum(['电子相册', '推文']) // 主题类型

const mapStateToProps = state => ({
	selectedRows: state.pubTable.pub_rowSelection.EPhotoAlbum,
})

const mapDispatchToProps = dispatch => ({
	getThemeTree: (...rest) => dispatch(getThemeTreeThunk(...rest)), // 获取编研主题树
	addTheme: (...rest) => dispatch(addThemeThunk(...rest)), // 获取编研主题树
	pull: (...rest) => dispatch(pullThunk(...rest)), // 获取编研主题树
	save: (...rest) => dispatch(saveThunk(...rest)), // 获取编研主题树
	publishRichText: (...rest) => dispatch(publishRichTextThunk(...rest)), // 发布推文
	deleteType: (...rest) => dispatch(deleteTypeThunk(...rest)), // 删除主题
})

function EPhotoAlbum(props) {
	const formRef = useRef(null)
	const editorRef = useRef(null)
	const [selectedNodeMsg, changeSelectedNodeMsg] = useState({}) // 选中的树节点的信息
	const [themeTreeData, changeThemeTreeData] = useState({}) // 部门树信息
	const [modalVisible_1, changeModalVisible_1] = useState(false) // 新建主题Modal
	const [modalVisible_2, changeModalVisible_2] = useState(false) // 新建推文Modal
	const [modalVisible_delete, changeModalVisible_delete] = useState(false) // 删除主题Modal
	const [record, changeRecord] = useState({}) // 每个主题的信息
	const [textId, changeTextId] = useState(null) // 每个推文的id
	const [copyModalVisible, changeCopyModalVisible] = useState(false) // 发布推文后显示链接复制的Modal
	const [shareUrl, changeShareUrl] = useState('') // 分享页面url
	const [typeValue, changeTypeValue] = useState(1)

	const msgRef = useRef(null) // 保存要删除的key

	// 搜索表单项的配置
	const searchFormConfig = {
		formItems: [
			{
				type: 'Select',
				name: 'themeType',
				label: '类型',
				options: themeType.toOptions(),
			},
			{
				type: 'Select',
				name: 'status',
				label: '状态',
				options: searchType.toOptions(),
			},
			{
				type: 'ParticiplesSelect',
				name: 'topic',
				label: '题名',
				inputWidth: '125px',
			},
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId',
				label: '部门',
				minWidth: '150px',
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'Input',
				name: 'keyWord',
				label: '关键字',
				inputWidth: '135px',
			},
			{
				type: 'Extra',
				name: 'buttonList',
				render: saerchFormRef => {
					return (
						<Space>
							<Button
								title='新建主题'
								onClick={() => changeModalVisible_1(true)}
								className='btn-success iconfont-buttons'>
								&#xe634;{' '}
							</Button>
							<Button
								onClick={() => {
									if (msgRef.current.length === 0) {
										message.error('请选择至少一项后再进行操作')
										return
									}
									changeModalVisible_delete(true)
								}}
								className='btn-danger'>
								删除
							</Button>
						</Space>
					)
				},
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'post',
			url: ePhotoAlbum.searchTheme,
			ContentType: 'application/json',
		},
	}

	useEffect(() => {
		const { getThemeTree } = props
		getThemeTree().then(res => {
			changeThemeTreeData(res.data)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// 选中节点信息变化，重新获取表格信息
	useEffect(() => {
		if (props.pub_getAllData) {
			props.pub_getAllData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedNodeMsg])

	// 选中节点触发
	function updateSelectNode(selectedNodeMsg) {
		changeSelectedNodeMsg(selectedNodeMsg)
	}

	// 删除主题
	useEffect(() => {
		msgRef.current = props.selectedRows
	}, [props.selectedRows])

	// 删除主题
	function deleteTheme() {
		const { deleteType } = props
		const ids = msgRef.current.map(item => item.id)
		changeModalVisible_delete(false)
		deleteType(ids).then(res => {
			if (res.success) {
				reloadAllData()
			}
		})
	}

	function onOk(params) {
		const { addTheme } = props
		let themeData = formRef.current.getFieldsValue()
		themeData.parentId = Number(themeData.parentId)
		themeData.leaf = 1
		addTheme(themeData).then(res => {
			if (res.success) {
				message.success('新建成功')
				reloadAllData()
				formRef.current.resetFields()
				changeModalVisible_1(false)
			}
		})
	}

	function onCancel(params) {
		changeModalVisible_1(false)
	}

	// 推文点击发布
	function onOk_2(params) {
		const { publishRichText, save } = props
		save({
			id: textId,
			content: editorRef.current.editor.txt.html(),
			themeId: record.id,
		}).then(res => {
			if (res.success) {
				editorRef.current.editor.txt.html('') // 清空推文编辑器内容
				changeModalVisible_2(false)
				publishRichText(record.id).then(res => {
					const shareUrl = `${window.location.origin}/shareText?files=${res.data}`
					changeShareUrl(shareUrl)
				})
				changeCopyModalVisible(true)
			} else {
				message.error(res.message)
			}
		})
	}

	// 推文点击暂存
	function onCancel_2(params) {
		const { save } = props
		save({
			id: textId,
			content: editorRef.current.editor.txt.html(),
			themeId: record.id,
		}).then(res => {
			if (res.success) {
				editorRef.current.editor.txt.html('') // 清空推文编辑器内容
				changeModalVisible_2(false)
				message.success('暂存成功')
			} else {
				message.error(res.message)
			}
		})
	}

	function operationButton(record, clickEvent) {
		return (
			<>
				<Button
					onClick={() => {
						record._type === 1 ? clickEvent(record) : operateRichText(record)
					}}>
					预览
				</Button>
			</>
		)
	}

	// 重新获取树数据和表格数据
	function reloadAllData() {
		const { pub_getAllData } = props
		pub_getAllData() // 重新获取表格数据
	}

	function operateRichText(record) {
		const { pull } = props
		changeRecord(record)
		changeModalVisible_2(true)
		pull(record.id).then(res => {
			res.content && editorRef.current.editor.txt.html(res.content)
			changeTextId(res.id || null)
		})
	}

	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 */
	function changeSearchLimit(data) {
		const categoryCodeId = selectedNodeMsg.id
		if (categoryCodeId !== -1) {
			data['id'] = selectedNodeMsg.id || -1
		}
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	function changeTableData(res) {
		res.records.forEach(item => {
			item.status = searchType[item.status]
			item.submitTime = item.submitTime.split('T')[0]
			item.typeName = item.type === 1 ? '电子相册' : '推文'
			item._type = item.type
			delete item.type
		})

		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}

	function copyToClipboard(s) {
		if (window.clipboardData) {
			window.clipboardData.setData('text', s)
		} else {
			const setCopyValue = e => {
				e.preventDefault()
				e.clipboardData.setData('text', s)
				document.removeEventListener('copy', setCopyValue)
			}
			document.addEventListener('copy', setCopyValue)
			document.execCommand('Copy')
		}
	}

	function typeChange(e) {
		changeTypeValue(e.target.value)
	}

	return (
		<Fragment>
			<div className='archived-management-wrapper'>
				{/* 左边部门树组件 */}
				<div id='depart-left'>
					<DossierTypeTree
						updateSelectNode={updateSelectNode} // 更新选中信息的方法
						selectedNodeMsg={selectedNodeMsg} // 当前树选中节点的信息
						getInstance={ref => {}} // 让页面获取树组件实例的方法，由 WithRef 高阶去调用
					/>
				</div>
				{/* 右边表格部分 */}
				<div id='depart-right'>
					<WrappedTable>
						<SearchForm
							name='ephoto-search-form'
							formItems={searchFormConfig}
							changeSearchLimit={changeSearchLimit}
							changeTableData={changeTableData}
							markName={markName}
							withPrint={true}
							withRecognition={false}
						/>
						<BaseTable
							tableConfig={ePhotoAlbumColumns}
							changeSearchLimit={changeSearchLimit}
							changeTableData={changeTableData}
							operationButton={operationButton}
							markName={markName}
							stylePointer={true}
							onRowEvent={true}
							isEPhotoAlbum={true}
						/>
					</WrappedTable>
				</div>
				<Modal
					title='新建主题'
					icon={<PlusCircleOutlined />}
					okText='确认'
					cancelText='取消'
					visible={modalVisible_1}
					onOk={onOk}
					onCancel={onCancel}>
					<Form
						name='basic'
						labelCol={{ span: 5 }}
						wrapperCol={{ span: 16 }}
						initialValues={{ remember: true }}
						autoComplete='off'
						ref={formRef}>
						<Form.Item
							label='题名'
							name='topic'
							rules={[{ required: true, message: '请输入题名!' }]}>
							<Input />
						</Form.Item>

						<Form.Item
							label='父节点'
							name='parentId'
							rules={[{ required: true, message: '请选择父节点!' }]}>
							<TreeSelect
								style={{ width: '100%' }}
								dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
								treeData={treeUtil.changeKeys([themeTreeData], {
									topic: 'title',
									id: 'value',
								})}
								treeDefaultExpandAll
							/>
						</Form.Item>

						<Form.Item
							label='类型'
							name='type'
							rules={[{ required: true, message: '请选择主题类型!' }]}>
							<Radio.Group onChange={typeChange} value={typeValue}>
								<Radio value={1}>电子相册</Radio>
								<Radio value={2}>推文</Radio>
							</Radio.Group>
						</Form.Item>

						<Form.Item label='关键字' name='keyWord'>
							<Input />
						</Form.Item>

						<Form.Item label='简介' name='description'>
							<Input />
						</Form.Item>
					</Form>
				</Modal>
				<Modal
					title='新建推文'
					width={850}
					icon={<PlusCircleOutlined />}
					okText='发布'
					cancelText='暂存'
					onOk={onOk_2}
					onCancel={onCancel_2}
					visible={modalVisible_2}
					maskClosable={false}>
					<MyEditor ref={editorRef} />
				</Modal>
				<Modal
					title={
						<p>
							<ExclamationCircleOutlined
								style={{
									color: '#faad14',
									marginRight: '16px',
									fontSize: '22px',
								}}
							/>
							删除主题
						</p>
					}
					width={450}
					okText='确认'
					cancelText='取消'
					onOk={deleteTheme}
					onCancel={() => changeModalVisible_delete(false)}
					visible={modalVisible_delete}>
					<p>您正在对选中主题进行删除操作，是否继续？</p>
				</Modal>
				<Modal
					title='分享'
					width={800}
					visible={copyModalVisible}
					footer={null}
					onCancel={() => changeCopyModalVisible(false)}>
					<div className='ephoto-modal-content'>
						<Input value={shareUrl} />
						<Button
							type='primary'
							style={{ marginLeft: '10px' }}
							onClick={() => {
								copyToClipboard(shareUrl)
								message.success('复制成功')
								changeCopyModalVisible(false)
							}}>
							点击复制
						</Button>
					</div>
				</Modal>
			</div>
		</Fragment>
	)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WrappedTable.create(markName)(EPhotoAlbum))
