import React, { Component, Fragment } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { connect } from 'react-redux'
import { Modal, Button, Image, message, Empty, Slider, Progress } from 'antd'
import {
	uploadThunk,
	faceRecognitionThunk,
	getFaceDetailListThunk,
	getRecognitionLinkArchiveThunk,
	searchDossierListThunk,
} from './ActionCreators'
import {
	pub_getAllTableData,
	pub_isLoading,
} from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator'

import {
	ScissorOutlined,
	UploadOutlined,
	MinusCircleOutlined,
	PlusCircleOutlined,
	FileImageOutlined,
	UserOutlined,
} from '@ant-design/icons'
import { nanoid } from 'nanoid'
import './index.less'
import deleteImg from '@/Static/delete.png'

// 最大和最小放缩 每次放缩大小
const [minScale, maxScale, sliderStep] = [1, 4, 0.5]

const mapDispatchToProps = dispatch => ({
	upload: (...rest) => dispatch(uploadThunk(...rest)), // 上传文件
	faceRecognition: (...rest) => dispatch(faceRecognitionThunk(...rest)), // 识别人脸
	getFaceDetailList: (...rest) => dispatch(getFaceDetailListThunk(...rest)), // 查询人脸信息
	getRecognitionLinkArchive: (...rest) =>
		dispatch(getRecognitionLinkArchiveThunk(...rest)), //通过人脸id来获得相关联的档案
	getAllTableData: (...rest) => dispatch(pub_getAllTableData(...rest)), // 把获取到的表格所有数据发送到store
	pub_changeisLoading: (...rest) => dispatch(pub_isLoading(...rest)), // 把标志是否获取表格数据的isLoading标识修改
	searchDossierList: async (...rest) => dispatch(searchDossierListThunk(...rest)), //向后端发送搜索请求，返回数据
	postAllTableData: async (...rest) => dispatch(pub_getAllTableData(...rest)),
})

@connect(null, mapDispatchToProps)
class FaceRecognitionCrop extends Component {
	state = {
		// height，width：裁剪框初始宽高 x，y裁剪框的坐标
		crop: {
			unit: '%',
			height: 30,
			width: 30,
			x: 0,
			y: 0,
		},
		// 放缩或移动图片时新的裁剪框坐标
		newCropXy: {
			x: 0,
			y: 0,
		},
		// 图片偏移量
		transformXy: {
			x: 0,
			y: 0,
		},
		imgFileList: [],
		scaleVal: 1,
		faceDatas: [],
		faceDataName: [],
		imgTotal: 0,
	}

	// 设置放缩
	setZoomVal = scaleVal => {
		this.setState(
			{
				scaleVal,
				transformXy: {
					x: 0,
					y: 0,
				},
			},
			() => {
				const { transformXy, crop } = this.state
				this.setState({
					newCropXy: {
						x:
							this.getOverflowWH(this.imageRef, 'width') -
							transformXy.x +
							crop.x,
						y:
							this.getOverflowWH(this.imageRef, 'height') -
							transformXy.y +
							crop.y,
					},
				})
			}
		)
	}

	// 关闭裁剪框时的回调 重置各项属性
	onCloseCropper = () => {
		this.setState({
			crop: {
				unit: '%',
				height: 30,
				width: 30,
				x: 0,
				y: 0,
			},
			newCropXy: {
				x: 0,
				y: 0,
			},
			transformXy: {
				x: 0,
				y: 0,
			},
			imgFileList: [],
			scaleVal: 1,
			faceDatas: [],
			faceDataName: [],
			faceDataId: [],
			imgTotal: 0,
		})
		document.onmousewheel = null
		this.props.closeCropper()
	}

	// 图片加载完成调用
	onImageLoaded = image => {
		this.imageRef = image
		document.onmousewheel = e => {
			this.props.imgUrl &&
				this.setState(
					state => {
						return {
							transformXy: {
								x: 0,
								y: 0,
							},
							scaleVal:
								e.wheelDelta > 0
									? state.scaleVal >= maxScale
										? maxScale
										: state.scaleVal + sliderStep
									: state.scaleVal <= minScale
										? minScale
										: state.scaleVal - sliderStep,
						}
					},
					() => {
						const { crop, transformXy } = this.state
						this.setState({
							newCropXy: {
								x:
									this.getOverflowWH(this.imageRef, 'width') -
									transformXy.x +
									crop.x,
								y:
									this.getOverflowWH(this.imageRef, 'height') -
									transformXy.y +
									crop.y,
							},
						})
					}
				)
		}
		let cropperWrap = document.querySelector('.cropper-wrap .ReactCrop')
		cropperWrap.onmousedown = e => {
			let { transformXy, scaleVal } = this.state
			let [x, y] = [e.clientX, e.clientY]
			cropperWrap.style.cursor = 'grabbing'
			cropperWrap.onmousemove = e => {
				let [dragDistanceX, dragDistanceY] = [
					e.clientX - x + transformXy.x,
					e.clientY - y + transformXy.y,
				]
				if (
					Math.abs(dragDistanceX) <
						this.getOverflowWH(this.imageRef, 'width') &&
					Math.abs(dragDistanceY) < this.getOverflowWH(this.imageRef, 'height')
				) {
					cropperWrap.firstElementChild.style.transform = `matrix( ${scaleVal}, 0, 0, ${scaleVal}, ${dragDistanceX}, ${dragDistanceY})`
				}
			}

			document.onmouseup = e => {
				this.setState(
					{
						transformXy: {
							x: Number(
								this.getTranMatrixs(cropperWrap.firstElementChild)[4]
							),
							y: Number(
								this.getTranMatrixs(cropperWrap.firstElementChild)[5]
							),
						},
					},
					() => {
						const { transformXy, crop } = this.state
						this.setState({
							newCropXy: {
								x:
									this.getOverflowWH(this.imageRef, 'width') -
									transformXy.x +
									crop.x,
								y:
									this.getOverflowWH(this.imageRef, 'height') -
									transformXy.y +
									crop.y,
							},
						})
						document.onmouseup = null
					}
				)
				cropperWrap.style.cursor = 'grab'
				cropperWrap.onmousemove = null
			}
			return false
		}
	}

	//获取图片放缩后的溢出宽高
	getOverflowWH = (target, WH) => {
		return (
			(Number(getComputedStyle(target, null)[WH].replace('px', '')) *
				(this.state.scaleVal - 1)) /
			2
		)
	}

	//获取transform数值
	getTranMatrixs = target => {
		let transformMatrix = getComputedStyle(target, null).transform
		let transforms = transformMatrix.slice(7, transformMatrix.length - 1).split(',')
		return transforms
	}

	// 裁剪框状态改变时调用
	onCropChange = (crop, percentCrop) => {
		const { transformXy } = this.state
		this.setState({
			crop,
			newCropXy: {
				x: this.getOverflowWH(this.imageRef, 'width') - transformXy.x + crop.x,
				y: this.getOverflowWH(this.imageRef, 'height') - transformXy.y + crop.y,
			},
		})
	}

	// 在拖动或调整大小后释放光标或触摸时发生的回调
	onCropDragEnd = () => {
		document.querySelector('.cropper-wrap .ReactCrop__crop-selection') ||
			this.setState({
				crop: {
					unit: '%',
					height: 30,
					width: 30,
					x: 0,
					y: 0,
				},
			})
	}

	// 获取裁剪图片
	getCroppedImg(image, crop) {
		if (this.state.imgFileList.length >= 10) {
			message.destroy()
			message.warning('裁剪图片数量最大为10张')
			return
		}
		const { newCropXy, scaleVal } = this.state
		const canvas = document.createElement('canvas')
		const pixelRatio = window.devicePixelRatio
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		const ctx = canvas.getContext('2d')

		canvas.width = crop.width * pixelRatio * scaleX
		canvas.height = crop.height * pixelRatio * scaleY

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
		ctx.imageSmoothingQuality = 'high'

		ctx.drawImage(
			image,
			scaleVal === 1 ? crop.x * scaleX : (newCropXy.x / scaleVal) * scaleX,
			scaleVal === 1 ? crop.y * scaleX : (newCropXy.y / scaleVal) * scaleY,
			(crop.width / scaleVal) * scaleX,
			(crop.height / scaleVal) * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY
		)
		const { type, name, uid } = this.props.file
		canvas.toBlob(blob => {
			if (!blob) {
				//reject(new Error('Canvas is empty'));
				console.error('Canvas is empty')
				return
			}
			let newFile = new File([blob], name, { type })
			newFile.uid = uid
			const reader = new FileReader()
			let Ofile = { file: newFile, imgUrl: reader.result, key: nanoid() }
			this.setFaceMsg([Ofile])
			reader.addEventListener('load', () => {
				Ofile = { file: newFile, imgUrl: reader.result, key: nanoid() }
				this.setState(state => {
					return {
						imgFileList: [...state.imgFileList, Ofile],
					}
				})
			})
			reader.readAsDataURL(newFile)
		}, type)
	}

	// 更新识别进度条
	upDistinguishPercent = faceDetailPercent => {
		const messageConfig = {
			icon: <i />,
			content: (
				<div style={{ width: 170 }}>
					识别人脸中...
					<Progress percent={faceDetailPercent} size='small' status='active' />
				</div>
			),
			duration: 0,
			key: 'getFaceDetailPercentMessage',
		}
		message.open(messageConfig)
	}

	setFaceMsgIterator = async function* (imgFileList) {
		try {
			const { upload, faceRecognition, getFaceDetailList } = this.props
			for (const imgFile of imgFileList) {
				const fileId = (await upload({ multipartFile: imgFile.file })).data.fileId
				const ids = (await faceRecognition(fileId)).data
				if (ids.length !== 0) {
					const FaceDetailList = (await getFaceDetailList(ids)).data
					yield FaceDetailList
				} else {
					yield []
				}
			}
		} catch (err) {
			message.destroy()
			message.error('识别出错！')
		}
	}

	// 获取人脸信息并渲染
	setFaceMsg = async imgFileList => {
		try {
			let [faceDataList, faceDataNameList, faceDataIdList] = [[], [], []]
			let isRecognize = false
			let faceDetailPercent = 0
			const averageProgress = 100 / imgFileList.length
			this.upDistinguishPercent(faceDetailPercent)
			for await (const FaceDetailList of this.setFaceMsgIterator(imgFileList)) {
				if (FaceDetailList.length !== 0) isRecognize = true
				faceDetailPercent += averageProgress
				this.upDistinguishPercent(Math.ceil(faceDetailPercent))
				// 查重
				for (let item of FaceDetailList) {
					let isExistedFace = false
					for (let existedFace of this.state.faceDatas) {
						if (item.id === existedFace.id) {
							isExistedFace = true
						}
					}
					if (!isExistedFace) faceDataList.push(item)
				}
				// 设置人脸信息
				for (const FaceDetailObj of FaceDetailList) {
					if (FaceDetailObj.name !== null && FaceDetailObj.id !== null) {
						faceDataNameList.push(FaceDetailObj.name)
						faceDataIdList.push(FaceDetailObj.id)
					}
				}
				let imgLength = this.state.imgTotal + 1
				this.setState({
					faceDatas: [...this.state.faceDatas, ...faceDataList],
					faceDataId: faceDataIdList,
					faceDataName: faceDataNameList,
					imgTotal: imgLength,
				})
			}
			// setTimeout的作用是可以观察进度条到达100%
			setTimeout(() => {
				if (isRecognize) {
					message.destroy()
					message.success('图片识别成功')
				} else if (!isRecognize) {
					message.destroy()
					message.error('未能从图片中识别出人脸信息')
				} else {
					message.destroy()
					message.warning('部分图片未能识别出人脸信息')
				}
			}, 200)
		} catch (err) {
			message.destroy()
			message.error('识别出错！')
		}
	}

	// 删除人脸信息
	deleteFaceData = faceMark => {
		let { faceDatas, faceDataName, faceDataId } = this.state
		faceDatas.splice(faceMark, 1)
		faceDataId.splice(faceMark, 1)
		faceDataName.splice(faceMark, 1)
		this.setState({
			faceDatas,
		})
	}

	// 确认上传图片
	uploadImgFile = () => {
		const { faceDataName, faceDatas, faceDataId } = this.state
		const { handleRecognitionSearchData } = this.props

		let searchData = { recognitionIdList: faceDataId, nameList: faceDataName } // 拿到搜索数据
		handleRecognitionSearchData(searchData)
		if (faceDataName.length === 0 && faceDatas.length !== 0) {
			this.onCloseCropper()
			message.destroy()
			message.error('人脸暂未标识')
		} else if (faceDatas.length === 0) {
			this.onCloseCropper()
			message.destroy()
			message.error('无人脸信息')
		} else {
			message.destroy()
			message.success('识别成功')
			this.onCloseCropper()
		}
	}

	render() {
		const { crop, scaleVal, imgFileList, faceDatas, imgTotal } = this.state
		const dataLength = faceDatas.length
		const { imgUrl } = this.props
		return (
			<>
				{imgUrl && (
					<Modal
						width={1000}
						title='图搜'
						visible={true}
						maskClosable={false}
						onCancel={this.onCloseCropper}
						className='cropper-modal-wrap'
						footer={
							<>
								<Button
									onClick={this.uploadImgFile}
									disabled={
										imgTotal === imgFileList.length ? false : true
									}>
									<UploadOutlined />
									确认上传
								</Button>
							</>
						}>
						<div className='cropper-Imagebefore'>
							<div className='cropper-wrap'>
								<ReactCrop
									src={imgUrl}
									crop={crop}
									scale={scaleVal}
									keepSelection={true}
									ruleOfThirds
									onImageLoaded={this.onImageLoaded}
									onChange={this.onCropChange}
									onDragEnd={this.onCropDragEnd}
								/>
							</div>
							<div className='cropper-slider-container'>
								<MinusCircleOutlined
									style={{ color: '#fff' }}
									onClick={() => {
										scaleVal.toFixed(1) * 1 - sliderStep >=
											minScale &&
											this.setZoomVal(scaleVal - sliderStep)
									}}
								/>
								<Slider
									max={maxScale}
									min={minScale}
									step={sliderStep}
									value={scaleVal}
									onChange={this.setZoomVal}
									className='cropper-slider'
								/>
								<PlusCircleOutlined
									style={{ color: '#fff' }}
									onClick={() => {
										scaleVal.toFixed(1) * 1 + sliderStep <=
											maxScale &&
											this.setZoomVal(scaleVal + sliderStep)
									}}
								/>
								<span
									style={{ backgroundColor: 'rgba(91,128,214)' }}
									onClick={() => {
										this.getCroppedImg(this.imageRef, this.state.crop)
									}}
									className='cropper-btn'>
									<ScissorOutlined />
									裁剪图片
								</span>
							</div>
						</div>
						<div className='cropper-Imageafter'>
							<div className='cropper-container-title'>
								<FileImageOutlined />
								<span>裁剪图片</span>
							</div>
							<div className='cropper-image-container'>
								<Image.PreviewGroup>
									{imgFileList.length === 0 ? (
										<Empty
											className='empty-croppered-img'
											image={Empty.PRESENTED_IMAGE_SIMPLE}
											description='暂无裁剪图片'
										/>
									) : (
										imgFileList.map((imgFile, index) => {
											return (
												<div
													key={imgFile.key}
													className='croppered-img-container'>
													<div className='croppered-img'>
														<Image src={imgFile.imgUrl} />
													</div>
												</div>
											)
										})
									)}
								</Image.PreviewGroup>
							</div>
							<div className='cropper-container-title'>
								<UserOutlined />
								<span>人脸信息</span>
							</div>
							<div className='cropper-face-container'>
								{dataLength === 0 ? (
									<Empty
										className='empty-croppered-img'
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										description='暂无人脸信息'
									/>
								) : (
									<Fragment>
										<div className='faceRecognitionSelect clearfix'>
											{faceDatas.map((item, index) => {
												return (
													<div
														key={index}
														className='face-item'>
														<div className='face-content'>
															<div>
																<Image
																	className='ant-image-img-search-face'
																	src={
																		item
																			.faceImageUrl[0]
																	}
																	preview={false}
																/>
															</div>
															<div
																className='detail'
																title={item.name || '无'}>
																<span className='face-name'>
																	姓名：
																	{item.name || ''}
																</span>
																<Button
																	type='link'
																	className='deleteButton'
																	onClick={() => {
																		this.deleteFaceData(
																			index
																		)
																	}}>
																	<img
																		className='deleteImg'
																		src={deleteImg}
																		alt='删除图标'
																	/>
																</Button>
															</div>
														</div>
													</div>
												)
											})}
										</div>
									</Fragment>
								)}
							</div>
						</div>
					</Modal>
				)}
			</>
		)
	}
}

export default FaceRecognitionCrop
