import React, { Component } from 'react'
import { injectUnmount } from '@/Utils'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import './index.less'
import { ap } from '../FilePreviewer/AudioPlayer'
import { playerList } from '../FilePreviewer/VideoPlayer'

const ref = React.createRef(null)

@injectUnmount
class ImageGalleryInjectUnmount extends Component {
	// 添加自定义样式
	setStyle = () => {
		let { height = 580, width = 70, uniqueName, items, showThumbnails } = this.props
		height = height.toString()
		width = width.toString()

		let isShowThumbnails

		if (showThumbnails != null) {
			isShowThumbnails = showThumbnails
		} else {
			isShowThumbnails = items.length > 1
		}

		if (width.replace(/[0-9]/g, '') === '') {
			width = `${width}px`
		}
		if (height.replace(/[0-9]/g, '') === '') {
			height = `${height}px`
		}
		height = isShowThumbnails ? `calc(${height} - 100px)` : height
		const property = {
			'--slides-height': height,
			'--width': width,
		}
		const imageGallery = document.querySelector(`.${uniqueName}`)
		for (let i in property) {
			imageGallery.style.setProperty(i, property[i])
		}
	}

	componentDidMount() {
		this.setStyle()

		const { getImageRef } = this.props
		if (getImageRef) {
			getImageRef(ref)
		}
	}

	componentDidUpdate() {
		this.setStyle()
	}

	render() {
		const { items, onSlide, uniqueName, startIndex = 0, showThumbnails } = this.props

		if (!uniqueName) {
			throw Error('FileDetails中，uniqueName是必传参数')
		}

		if (!items) {
			throw Error('FileDetails中，items是必传参数')
		}

		let isShowThumbnails

		if (showThumbnails != null) {
			isShowThumbnails = showThumbnails
		} else {
			isShowThumbnails = items.length > 1
		}

		return (
			<div className={`pub-image-gallery ${uniqueName}`}>
				{items.length === 0 && (
					<div className='imageGalleryTip'>
						温馨提示:文件上传成功后需要耐心等待页面渲染
					</div>
				)}
				<ImageGallery
					useTranslate3D={false} // 取消3d
					ref={ref}
					infinite={false} // 取消无限轮播
					showThumbnails={isShowThumbnails} // 显示缩略图
					showFullscreenButton={false} // 隐藏全屏按钮
					showIndex={true} // 显示几分之几
					showPlayButton={false} // 隐藏播放按钮
					disableKeyDown={false} // 开启键盘左右键
					items={items}
					startIndex={startIndex}
					onSlide={currentIndex => {
						// 暂停正在播放的音频
						if (ap) {
							ap.pause()
						}

						// 暂停正在播放的视频
						playerList.forEach(player => {
							player.pause()
						})

						if (onSlide) {
							onSlide(currentIndex)
						}
					}}
				/>
			</div>
		)
	}
}

export default ImageGalleryInjectUnmount
