import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { archiveTypeUrl } from '@/Utils/Urls.js'
import { Space, Button } from 'antd'

const formLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}
// formItem布局配置项
const formItemLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}
// 修正申请详情模态框
// @connect(mapStateToProps, mapDispatchToProps)
export default class TypeDetailModal extends Component {
	// 表单配置项
	formItems = [
		[
			{
				type: 'AsyncTreeSelect',
				name: 'parentId',
				label: '父节点',
				placeholder: '请选择父节点',
				layout: {
					labelCol: { span: 6 },
					wrapperCol: { span: 18 }, // item
				},
				ajaxConfig: {
					url: archiveTypeUrl.getDossierType,
				},
				formatDataFunc: (data, changeKeys) => {
					let treeData = changeKeys(data.children, {
						typeName: 'title',
						id: 'value',
					})
					return treeData
				},
				span: 24,
				rules: [{ required: false }],
			},
		],
		[
			{
				type: 'Input',
				name: 'typeName',
				label: '分类名称',
				layout: formItemLayout,
				placeholder: '请输入分类名称',
				span: 18,
			},
		],
		[
			{
				type: 'Input',
				name: 'categoryCode',
				label: '分类代码',
				layout: formItemLayout,
				placeholder: '请输入分类代码',
				span: 18,
			},
		],
		[
			{
				type: 'Input',
				name: 'defaultRetentionPeriod',
				label: '保管权限',
				layout: formItemLayout,
				placeholder: '1-永久 2-30年 3-10年',
				span: 18,
			},
		],
		[
			{
				type: 'DynamicExtra',
				name: 'buttonList',
				initialState: {
					ifUpadate: false,
				},
				render: (formRef, state, changeDynamicStateFun) => {
					const { ifUpadate } = state
					const {
						modalRef,
						handleUpdateApply, // 更新申请
					} = this.props
					return (
						<Space style={{ marginLeft: '70%', marginTop: '20px' }}>
							{
								// 禁用的情况下，应该返回编辑（不具备发送请求的功能）和提交按钮，非禁用的情况下应该返回保存（此时相当于更新）
								!ifUpadate ? (
									<Space>
										<Button
											type='primary'
											className='iconfont-buttons'
											title='编辑'
											onClick={() => {
												changeDynamicStateFun({
													ifUpadate: true,
												})
												modalRef.current.formRef.setDisabled(
													false
												)
											}}>
											&#xeabd; 编辑
										</Button>
									</Space>
								) : (
									<Button
										type='primary'
										onClick={() => handleUpdateApply()}>
										保存
									</Button>
								)
							}
							<Button onClick={() => modalRef.current.hide()}>取消</Button>
						</Space>
					)
				},
			},
		],
	]
	render() {
		const { modalRef, hideCallback } = this.props
		return (
			<PubModalForm
				title={[
					<span style={{ fontFamily: 'Microsoft YaHei' }}>档案类型管理</span>,
				]}
				className='typeDetailModalForm'
				ref={modalRef}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				width={550}
				formProps={{
					// 表单的配置项
					name: 'typeDetailModalForm',
					formItems: this.formItems,
					layout: formLayout,
				}}
				hideCallback={hideCallback}
				formDisabled // 查看状态下不给予编辑
			/>
		)
	}
}
