import React, { PureComponent } from 'react'
import { message, Modal } from 'antd'
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import FileDetails from '@/Public/FileDetails'
import { addCollectedFileThunk, downloadCertificateThunk } from './ActionCreators'

const mapDispatchToProps = dispatch => ({
	addCollectedFile: (...rest) => dispatch(addCollectedFileThunk(...rest)),
	downloadCertificate: (...rest) => dispatch(downloadCertificateThunk(...rest)),
})

@connect(null, mapDispatchToProps)
class FillInformation extends PureComponent {
	state = {
		currentIndex: 0,
		attachmentIds: [],
		submitedFile: [], //标记已经被提交图片
	}

	formData = [] //存每张图片的表单数据

	componentDidMount() {
		const { submitedFile } = this.state
		const { themeId, fileList, getFillInformationRef } = this.props
		const defaultData = {
			themeId,
		}
		//判断如果是 /collectFiles?50?1 就是从资料管理页过来的 自动填上用户信息
		if (window.location.href.split('?')[2]) {
			let userMessage = JSON.parse(window.localStorage.getItem('userMessage')).user
			Object.assign(defaultData, {
				departmentId: userMessage.departmentId,
				providerPhone: userMessage.phone,
				providerName: userMessage.name,
			})
		}
		this.formRef.current.setFieldsValue(defaultData)
		for (let i = 0; i < (fileList ? fileList.length : 0); i++) {
			submitedFile[i] = false
		}
		//把子组件的this给父组件
		getFillInformationRef(this)
	}

	componentDidUpdate(prevProps) {
		const { submitedFile, currentIndex } = this.state
		const { fileList, getFillInformationRef } = this.props
		//把子组件的this给父组件
		getFillInformationRef(this)
		if (prevProps.fileList.length !== fileList.length) {
			//更新初始化未标记文件
			for (let i = 0; i < fileList.length; i++) {
				submitedFile[i] = false
				this.formData[i] = { topic: fileList[i].name }
			}
			this.formRef.current.setFieldsValue(this.formData[currentIndex])
		}
	}

	// 获取附件列表
	getAttachmentFileList = aFileList => {
		this.attachmentFileList = aFileList
	}

	// 获取表单 ref
	getRef = ref => {
		this.formRef = ref
	}

	// 获取图片栏 ref
	getImageRef = imageRef => {
		this.imageRef = imageRef
	}

	// 设置请求数据
	setAjaxData = (values, index) => {
		const { attachmentIds } = this.state
		const { fileList, themeId } = this.props
		const { fileId, thumbnailFileId, zippedImageFileId } =
			fileList[index].response.data

		values.attachmentIds = attachmentIds[index]

		Object.keys(values).forEach(key => {
			if (values[key] == null) {
				delete values[key]
			}
		})

		return {
			...values,
			fileId,
			themeId,
			thumbnailFileId,
			zippedFileId: zippedImageFileId,
		}
	}

	// 提交表单函数
	onFinish = values => {
		const { currentIndex, submitedFile } = this.state
		const { addCollectedFile, goBack, fileList, finishClear, downloadCertificate } =
			this.props
		const component = this
		let certificateId = [],
			title
		//如果fileList为undefinded
		if (!(fileList && fileList.length)) {
			message.error('请先上传文件')
			return
		}
		//提交前把当前文件数据存起来
		this.formData[currentIndex] = this.formRef.current.getFieldValue()

		try {
			let providerPhoneCopy = undefined,
				providerNameCopy = undefined,
				emailCopy = undefined

			//找到一个有填的providerPhone providerName
			for (let i = 0; i < this.formData.length; i++) {
				if (this.formData[i]?.hasOwnProperty('providerPhone')) {
					providerPhoneCopy = this.formData[i]['providerPhone']
				}
				if (this.formData[i]?.hasOwnProperty('providerName')) {
					providerNameCopy = this.formData[i]['providerName']
				}
				if (this.formData[i]?.hasOwnProperty('email')) {
					emailCopy = this.formData[i]['email']
				}
				if (providerPhoneCopy && providerNameCopy && emailCopy) break
			}
			if (!(providerPhoneCopy && providerNameCopy)) {
				message.info('请填写完整信息')
				throw new Error('请填写完整信息')
			}

			if (
				!this.formData.every(item =>
					item.providerName ? item.providerName === providerNameCopy : 'true'
				)
			) {
				message.info('捐赠人需唯一')
				throw new Error('捐赠人需唯一')
			}

			//填进未填的
			this.formData.forEach((item, index) => {
				if (!item.topic) {
					message.info('未填资料名称')
					this.imageRef.current?.slideToIndex(index)
					throw new Error('未填资料名称')
				}
				if (!item.providerPhone) {
					item.providerPhone = providerPhoneCopy
				}
				if (!item.providerName) {
					item.providerName = providerNameCopy
				}
				if (!item.email) {
					item.email = emailCopy
				}
			})
		} catch {
			return
		}

		message.loading('正在提交资料，请稍等...', 0.8)
		let rejectnum = 0 //提交失败文件数
		let submiteddata
		Promise.all(
			submitedFile.map((item, index) => {
				if (!item) {
					submiteddata = this.setAjaxData(this.formData[index], index)
					return new Promise((reslove, reject) => {
						addCollectedFile(submiteddata)
							.then(res => {
								//标记已提交文件
								submitedFile[index] = true
								reslove(res)
							})
							.catch(err => {
								this.imageRef.current?.slideToIndex(index)
								rejectnum++
								reject(err)
							})
					})
				} else return 0
			})
		)
			.then(result => {
				result.forEach(res => {
					if (res !== 0) certificateId.push(res.data)
				})
				if (this.formData.length === certificateId.length) {
					downloadCertificate(certificateId)
				}
				if (submiteddata.email) {
					title = '捐赠证书已发往邮箱，'
				}
				Modal.confirm({
					title: `所选资料已经全部提交完毕，${
						title ? title : ''
					}是否继续上传文件？`,
					icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
					okText: '退出',
					cancelText: '继续上传',
					onOk() {
						goBack()
						message.success('所选资料已经全部提交完毕，自动为您返回首页')
					},
					onCancel() {
						finishClear()
						component.forceUpdate()
					},
				})
			})
			.catch(() => {
				Modal.warning({
					title: `有${rejectnum}个文件上传失败，请稍后重试`,
					icon: <WarningOutlined style={{ color: '#faad14' }} />,
					okText: '确定',
					onOk() {
						finishClear()
						component.forceUpdate()
					},
				})
			})
	}

	//提交时清空所有
	finishClear = () => {
		this.setState({
			currentIndex: 0,
			attachmentIds: [],
			submitedFile: [],
		})
		this.formData.length = 0
		this.formRef.current.resetFields()
	}

	// 滑动函数
	onSlide = index => {
		const { currentIndex } = this.state
		this.formData[currentIndex] = this.formRef.current.getFieldValue()
		this.setState({ currentIndex: index }, () => {
			const currentFormData = this.formData[index]
			//如果电话为undefined,表示未填,则复用一份过来
			if (!currentFormData?.hasOwnProperty('providerPhone')) {
				const copyFormData = this.formData.find(element => {
					return element?.hasOwnProperty('providerPhone')
				})
				this.formData[index] = {
					...copyFormData,
					topic: this.formData[index].topic,
				}
				this.formRef.current.setFieldsValue({
					...copyFormData,
					topic: this.formData[index].topic,
				})
			} else {
				this.formRef.current.setFieldsValue(currentFormData)
			}
		})
	}

	//删除文件时,连带删除对应formData数据
	deleteFormData = () => {
		this.formData.splice(this.state.currentIndex, 1)
		this.formRef.current.setFieldsValue(this.formData[this.state.currentIndex])
	}

	render() {
		const { images, randerMoreButtons, themeTopic } = this.props
		const { currentIndex, submitedFile } = this.state
		const collectedFileEditorBtn = [
			{
				text: `${submitedFile[currentIndex] ? '该资料已提交' : '提交资料'}`,
				type: `${submitedFile[currentIndex] ? 'text' : 'primary'}`,
				disabled: submitedFile[currentIndex],
				htmlType: 'submit',
			},
		]
		return (
			<FileDetails
				uniqueName='collected-file-details'
				style={{ marginTop: '10px' }}
				height='80vh'
				getRef={this.getRef}
				getImageRef={this.getImageRef}
				getAttachmentFileList={this.getAttachmentFileList}
				onFinish={this.onFinish}
				onSlide={this.onSlide}
				items={images}
				disabled={submitedFile[currentIndex]}
				isCollectedFile={true}
				isVisitor={true}
				themeTopic={themeTopic}
				buttons={collectedFileEditorBtn}
				moreButtons={randerMoreButtons}
				//TODO debugger
				useGallery={true}
			/>
		)
	}
}

export default FillInformation
