import React, { Component } from 'react'
import { message, Card, Layout } from 'antd'
import './index.less'
import LoginForm from './LoginForm'
const { Content } = Layout
message.config({
	top: 30,
})

class Login extends Component {
	render() {
		return (
			<Layout id='login-page'>
				<Content className='login-content'>
					<Card id='login-form-box'>
						<LoginForm className='login-form' />
					</Card>
				</Content>
			</Layout>
		)
	}
}
export default Login
