import _fetch from '@/Utils/Fetch'
import { borrowUrl } from '@/Utils/Urls'

/**
 * 获取已登录用户的信息
 */
export const getUserInfoThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: borrowUrl.getUserInfo,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})
