import _fetch from '@/Utils/Fetch'
import { collectedFileUrl } from '@/Utils/Urls'
import { TAB_ADD } from '@/Redux/ActionTypes/Route'

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

export const getMyThemeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: collectedFileUrl.getMyTheme,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

export const deleteThemeThunk = themeIds => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: collectedFileUrl.deleteTheme,
			type: 'post',
			data: {
				themeIds,
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

export const setNumForThemeThunk = themeIds => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: collectedFileUrl.setNumForTheme,
			type: 'post',
			data: {
				themeIds,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

export const cancelTopThemeThunk = themeIds => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: collectedFileUrl.cancelTopTheme,
			type: 'post',
			data: {
				themeIds,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})
