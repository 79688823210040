import React, { Component } from 'react'
import PubArchive from '@/Public/PubArchive'
import { Button, message, Spin, Modal } from 'antd'
import { connect } from 'react-redux'
import './index.less'
import {
	insertDossierThunk,
	packArchiveThunk,
	archiveDossierThunk,
	pushRoutersAction,
} from './ActionCreators'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
const markName = 'newArchive'

const mapStateToProps = state => {
	return {
		pubArchive: state.pubArchive,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		pushRouters: route => dispatch(pushRoutersAction(route)),
		insertDossier: (...rest) => dispatch(insertDossierThunk(...rest)),
		packArchive: (...rest) => dispatch(packArchiveThunk(...rest)),
		archiveDossier: (...rest) => dispatch(archiveDossierThunk(...rest)),
	}
}

@connect(mapStateToProps, mapDispatchToProps)
class NewArchive extends Component {
	state = {
		isModalVisible: false,
		waiting: false,
		modal: {
			content: '',
			method: -1, // 新建or归档
		},
	}
	// 关闭模态框
	handleCancel = () => {
		this.setState({
			isModalVisible: false,
			waiting: false,
		})
	}

	// 新建案卷
	newArchive = async callback => {
		await this.setState({ waiting: true })
		let attachIds = await this.getAttachmentIds()
		const { insertDossier, packArchive, pushRouters } = this.props
		const { removePubArchive, getPubArchiveData } = this.formRef
		const pubArchive = getPubArchiveData()
		const filesIds = pubArchive.new.filesData.map(item => item.id)
		const archiveData = pubArchive.new.archiveData
		archiveData.timeMeta += 'T00:00:00'
		archiveData.attachmentList = attachIds
		let res = await insertDossier(archiveData, this.handleCancel)
		const data = {
			dossierId: res.data,
			ids: filesIds,
		}
		res = await packArchive(data)
		if (callback) {
			await callback([data.dossierId]) // 用于预归档
		} else {
			if (res.code === 200) {
				removePubArchive()
				message.success('新建案卷成功')
				history.push(linkTo.to('/workarea/myFiles/recordFiles'))
				const route = {
					title: '著录',
					key: '/workarea/myFiles/recordFiles',
				}
				updataRedDots()
				pushRouters(route)
			}
		}
	}

	// 显示模态框
	showModal = async (content, method) => {
		const { pubArchive } = this.props
		const filesData = pubArchive[markName].new.filesData
		try {
			await this.formRef.validateFields() // 检查必填项有没有填
			// 再判断有没有加文件
			if (filesData.length === 0) {
				message.info('请加入文件')
			} else {
				this.setState({
					isModalVisible: true,
					modal: {
						content,
						method,
					},
				})
			}
		} catch (error) {
			// 没有填，会进入这个catch
			message.info('请填写必要的表单信息')
		}
	}

	// 直接送到预归档
	toPreArchive = async ids => {
		const { archiveDossier, pushRouters } = this.props
		const { removePubArchive } = this.formRef
		const data = { ids }
		const res = await archiveDossier(data)
		if (res && res.code === 200) {
			removePubArchive()
			message.success('案卷已经加入预归档')
			history.push(linkTo.to('/workarea/myFiles/prearchive'))
			const route = {
				title: '预归档',
				key: '/workarea/myFiles/prearchive',
			}
			updataRedDots()
			pushRouters(route)
		}
	}

	// 获取表单的实例
	getInstance = async formRef => {
		this.formRef = formRef
	}

	// 渲染在案卷信息底部的按钮
	renderBottomButtons = () => {
		return (
			<React.Fragment>
				<Button
					className='btn-success'
					onClick={() => {
						this.showModal('确定新建案卷吗？', 0)
					}}>
					新建
				</Button>
				<Button
					className='ant-btn-primary'
					onClick={() => {
						this.showModal('确定归档案卷吗？', 1)
					}}>
					归档
				</Button>
			</React.Fragment>
		)
	}

	// 模态框的确定按钮
	onOk = () => {
		const method = this.state.modal.method
		if (method === 0) {
			this.newArchive()
		} else if (method === 1) {
			this.newArchive(this.toPreArchive)
		}
	}

	// 左边表格操作中的按钮
	renderOperation = (text, record, formRef) => {
		return (
			<Button
				className='iconfont-buttons'
				title='删除'
				onClick={() => {
					const { getPubArchiveData, upgradePubArchive } = this.formRef
					const pubArchiveData = getPubArchiveData()
					const filesData = pubArchiveData.new.filesData
					const archiveData = pubArchiveData.new.archiveData
					const filesLength = filesData.length
					let tmpFilesData = filesData.filter(item => {
						return item.id !== record.id
					})
					const tmpPubArchive = {
						archiveData,
						filesData: tmpFilesData,
					}
					tmpFilesData.length !== filesLength &&
						upgradePubArchive(tmpPubArchive)
				}}>
				&#xe816; 删除
			</Button>
		)
	}

	// 获取附件的id
	getAttachmentIds = func => {
		this.getAttachmentIds = func || (() => {})
	}

	render() {
		const { isModalVisible, waiting, modal } = this.state
		return (
			<React.Fragment>
				<div className='new-archives'>
					<PubArchive
						renderBottomButtons={this.renderBottomButtons}
						appendFiles={true}
						getInstance={this.getInstance}
						renderOperation={this.renderOperation}
						markName={markName}
						statusDisabled={false}
						testCallback={this.testCallback}
						getAttachmentIds={this.getAttachmentIds}
					/>
				</div>
				<Modal
					title='提示'
					visible={isModalVisible}
					onOk={this.onOk}
					onCancel={this.handleCancel}>
					<p>{modal.content}</p>
				</Modal>
				<div className='waiting' style={{ display: waiting ? 'block' : 'none' }}>
					<Spin id='new-spin' size='large' tip='处理中，请耐心等候'></Spin>
				</div>
			</React.Fragment>
		)
	}
}

export default NewArchive
