import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import './HomePage.less'
import { Provider } from 'react-redux'
import store from '@/Redux/store'
import Login from './Components/Login'
import HomePage from './HomePage'
import { linkTo } from './Routes/ManageRouter'
import FindNotFoundPage from './Components/FindNotFoundPage'
import CollectedFiles from './Components/CollectedFiles'
import Share from './Components/Share'
import ShareText from './Components/ShareText'
import zhCN from 'antd/es/locale/zh_CN'
import { ConfigProvider } from 'antd'
import './index.less'
import { CookieUtil } from '@/Utils/index'

export const history = createBrowserHistory()

ReactDOM.render(
	<ConfigProvider locale={zhCN}>
		<Provider store={store}>
			<Router history={history}>
				<Switch>
					<Route exact path='/' key='/Login' component={Login} />
					<Route key='/shareText' path='/shareText' component={ShareText} />
					<Route
						path={linkTo.topRoute}
						key='/Homepage'
						render={routeProps => {
							if (!CookieUtil.get('userMessage')) {
								return <Redirect to={{ pathname: '/' }} />
							}
							return <HomePage {...routeProps} />
						}}
					/>
					<Route key='/share' path='/share' component={Share} />
					<Route
						key='/collectFiles'
						path='/collectFiles'
						component={CollectedFiles}
					/>
					<Route key='404' component={FindNotFoundPage} />
				</Switch>
			</Router>
		</Provider>
	</ConfigProvider>,
	document.getElementById('root')
)
