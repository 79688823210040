import React, { Component } from 'react'
import { message } from 'antd'
import { PubModalForm } from '@/Public/PubForm'
import { claimArchive } from './ActionCreators'
import { history } from '@/index'

const formLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 10 },
}
// 表单项的单独配置，优先级高于表单项的统一配置
const formItemLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}

// PubForm 表单项配置数组
let formItems = [
	[
		{
			type: 'TextArea',
			name: 'applyReason',
			label: '认领原因:',
			layout: formItemLayout,
			placeholder: '请输入认领的原因',
			rules: [{ required: true }],
			rows: 5,
		},
	],
]

export default class modal extends Component {
	state = {
		visible: false,
		title: '认领申请',
	}

	setButton = () => {
		const { archiveId } = this.props
		//配置按钮项
		let buttonList = [
			{
				text: '认领',
				buttonProps: {
					type: 'primary',
				},
				onClick: async (formData, { finish, hide }) => {
					// 点击新增事件
					history.goBack()
					message.info('申请认领中')
					const data = {
						archiveId,
						applyReason: formData.applyReason,
					}
					claimArchive(data).then(res => {
						if (res.data.success) {
							message.success('申请认领成功')
							history.goBack()
						} else {
							message.error('申请认领失败')
						}
						hide()
					})
					// addFonds(formData).then(res => {
					//   hide();
					//   pub_getAllData();    // 刷新表格
					// }).catch(err => {
					//   finish();
					// })
				},
			},
		]
		return buttonList
	}

	render() {
		const { title } = this.state
		const { modalRef } = this.props
		return (
			<PubModalForm
				ref={modalRef}
				title={title}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				buttonList={this.setButton()} // Modal 底部按钮配置列表,
				width={500} // 默认值900
				formProps={{
					// 表单的配置项
					name: 'claimModal',
					formItems,
					layout: formLayout,
				}}
			/>
		)
	}
}
