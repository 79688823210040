import React, { PureComponent } from 'react'
import { Steps, Button, Radio, Space, Badge, Modal, List, Alert } from 'antd'
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { getAllSystemConfig } from './ActionCreators'
import './index.less'
const { Step } = Steps

class Status extends PureComponent {
	steps = [
		{
			title: '选择文件夹',
		},
		{
			title: '填写导入参数',
		},
		{
			title: '导入',
		},
	]

	state = {
		visible: true,
		value1: 3,
		value2: 0,
		disabled: true,
		show: false, // 是否展示入库提示
		isModalVisible: false, // 确认导入提示框
		isModalVisible2: false,
		ready_auto_stage: '', // 配置的阶段
		ready_auto_import: '', // 配置的导入方式
	}

	componentDidMount() {
		getAllSystemConfig().then(config => {
			let { ready_auto_stage, ready_auto_import } = config
			this.setState({
				ready_auto_stage,
				ready_auto_import,
			})
		})
	}

	// 选择阶段
	onChange1 = e => {
		this.setValue1(e.target.value)
	}

	setValue1 = value => {
		this.setState(
			{
				value1: value,
				show: value === 2 ? true : false,
			},
			() => {
				this.checkStatus()
			}
		)
	}

	// 导入方式
	onChange2 = e => {
		this.setValue2(e.target.value)
	}

	setValue2 = value => {
		this.setState(
			{
				value2: value,
			},
			() => {
				this.checkStatus()
			}
		)
	}

	// 判断导入参数是否选择完成,完成了才能点击导入按钮
	checkStatus = () => {
		const { value1, value2 } = this.state
		const { getStatus, changeStatus_2 } = this.props
		if (value1 !== 3 && value2 !== 0) {
			this.setState({
				disabled: false,
			})
			getStatus(value1, value2)
			changeStatus_2()
		}
	}

	onClick = () => {
		const { handleNoExcel } = this.props
		handleNoExcel()
		this.setState({
			isModalVisible: true,
		})
	}

	handleCancel = () => {
		this.setState({
			isModalVisible: false,
		})
	}

	handleCancel2 = () => {
		this.setState({
			isModalVisible2: false,
		})
	}

	handleOk = () => {
		const { upload } = this.props
		upload()
		this.setState({
			isModalVisible: false,
		})
	}

	render() {
		const { status, showFormTable, checkExcelList } = this.props
		const {
			value1,
			value2,
			disabled,
			show,
			isModalVisible,
			ready_auto_stage,
			ready_auto_import,
		} = this.state
		return (
			<div>
				<Steps current={status} style={{ margin: '30px 0px' }}>
					{this.steps.map(item => (
						<Step key={item.title} title={item.title} />
					))}
				</Steps>
				<div
					className='form-table'
					style={{ display: `${showFormTable ? 'block' : 'none'}` }}>
					<span className='title-1'>选择阶段 :</span>
					<Radio.Group onChange={this.onChange1} value={value1}>
						<Radio
							value={0}
							disabled={ready_auto_stage.includes('著录') ? false : true}>
							著录
						</Radio>
						<Radio
							value={1}
							disabled={ready_auto_stage.includes('预归档') ? false : true}>
							预归档
						</Radio>
						<Radio
							value={2}
							disabled={ready_auto_stage.includes('入库') ? false : true}>
							入库
						</Radio>
						<Badge
							style={{
								position: 'relative',
								backgroundColor: 'rgb(250, 173, 20)',
								left: '-70px',
								top: '20px',
							}}
							count={show ? '请注意,入库后无法回退!' : ''}
						/>
					</Radio.Group>
					<span className='title-2'>导入方式 :</span>
					<Radio.Group
						onChange={this.onChange2}
						value={value2}
						className='ant-radio-group-2'>
						<Space direction='vertical'>
							<Radio
								value={1}
								disabled={
									ready_auto_import.includes('独立文件') ? false : true
								}>
								独立文件
							</Radio>
							<Radio
								value={2}
								disabled={
									ready_auto_import.includes('单一案卷') ? false : true
								}>
								单一案卷
							</Radio>
							<Radio
								value={3}
								disabled={
									ready_auto_import.includes('多案卷') ? false : true
								}>
								多案卷
							</Radio>
						</Space>
					</Radio.Group>
					<Button
						className='lead-in-btn'
						type='primary'
						disabled={disabled}
						onClick={this.onClick}
						style={{ position: 'absolute' }}>
						导入
					</Button>
					<Modal
						title='提示'
						visible={isModalVisible}
						onOk={this.handleOk}
						onCancel={this.handleCancel}
						centered>
						<List
							size='default'
							bordered
							header={<h3>各文件夹模板存在情况</h3>}
							dataSource={checkExcelList}
							itemLayout='vertical'
							style={{
								height: '330px',
								overflow: 'scroll',
							}}
							renderItem={item => (
								<div>
									<List.Item>
										<p>{item.path}</p>
										<p>
											{`统一信息`}
											{item.excel === true ? (
												<CheckCircleOutlined
													style={{
														marginLeft: '5px',
														transform: 'translate(0,1px)',
														color: 'rgb(28, 214, 108)',
													}}
												/>
											) : (
												<ExclamationCircleOutlined
													style={{
														marginLeft: '5px',
														transform: 'translate(0,1px)',
														color: '#FFB90F',
													}}
												/>
											)}
										</p>
									</List.Item>
								</div>
							)}
						/>
						<Alert
							style={{ marginTop: '10px' }}
							message='温馨提示：缺少模板文件会导致著录失败'
							type='info'
							showIcon
						/>
						<p style={{ marginTop: '10px' }}>是否确定导入?</p>
					</Modal>
				</div>
			</div>
		)
	}
}

export default Status
