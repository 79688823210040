import React, { useEffect, useRef, useState } from 'react'
import { EmlReader as EmlParser } from './emlParse/emlReader'
import { Image, Spin, message } from 'antd'
import Axios from 'axios'
// const reader = new FileReader() //bolb格式用这个
const encoder = new TextEncoder() //string格式用这个

const EmlPreview = ({ mailData, style }) => {
	const [eml, setEml] = useState(null)
	const [isProfileToggle, setIsProfileToggle] = useState(false) //是否展开简介
	const [isEmlLoading, setIsEmlLoading] = useState(true) //是否正在加载eml
	const [isAttchmentLoading, setIsAttchmentLoading] = useState(true) //是否正在加载附件
	const [attachmentBriefList, setAttachmentBreifList] = useState([])
	const contentTargetRef = useRef(null)

	//获取指定类型的转换
	useEffect(() => {
		setIsEmlLoading(true)
		const fetchData = async () => {
			// const domain = window.location.host
			// 	.split(':')
			// 	.some(item => item === 'localhost' || item === '8080')
			// 	? 'test-hzdag.topviewclub.cn'
			// 	: window.location.host

			const url = '/api/file/download/' + mailData.fileToken
			const res = await Axios.get(url)
			//console.log(res)
			if (res.status > 300 && res.status < 200) {
				message.destroy()
				message.error('未找到邮件信息')
				return
			}
			//将eml数据转换为arrayBuffer
			//console.log('mailData:', res.data, typeof mailData)

			//blob格式用下面这个代码
			if (!mailData) {
				message.destroy()
				message.error('邮件解析出错')
			}
			// const arrayBuffer = reader.readAsArrayBuffer(mailData)

			//string格式用下面这个代码
			const arrayBuffer = encoder.encode(res.data)

			const email = new EmlParser(arrayBuffer)

			setEml(email)
			setIsEmlLoading(false)
		}
		fetchData()
	}, [mailData])

	//获取eml内容渲染到正文
	useEffect(() => {
		if (eml && contentTargetRef.current) {
			if (eml.getMessageText() === '') {
				contentTargetRef.current.innerText = '邮件内容为空或者无法解析该邮件'
			} else {
				contentTargetRef.current.innerHTML = eml.getMessageHtml()
			}
		}
	}, [eml])

	//获取附件
	useEffect(() => {
		setIsAttchmentLoading(true)
		const attachmentList = eml?.getAttachments()
		//提取信息同步到state state和url
		if (attachmentList && attachmentList.length > 0) {
			const breifList = attachmentList.map(item => {
				const blob = new Blob([item.content], {
					type: item.contentType,
				})
				const url = URL.createObjectURL(blob)
				return {
					name: item.filename,
					type: item.contentType,
					url: url,
				}
			})
			setAttachmentBreifList(breifList)
		}
		setIsAttchmentLoading(false)
	}, [eml])
	return (
		<div className='emlPreview-wrapper ' style={style}>
			<Spin spinning={isEmlLoading}>
				<div className='eml-contianer'>
					<h1>邮件标题： {eml?.getSubject()}</h1>
					<div
						onClick={() => setIsProfileToggle(!isProfileToggle)}
						className={`eml-profile`}>
						<div className='avatar'>
							<Image src='https://res.mail.qq.com/zh_CN/htmledition/images/rss/male.gif?rand=1617349242' />
						</div>
						<div className='eml-detail-profile'>
							<div>
								<div>来自：</div>
								<div> {eml?.getFrom()}</div>
							</div>
							<div>
								<div>发送至：</div>
								<div> {eml?.getTo()}</div>
							</div>
							<div>
								<div>发送时间：</div>
								<div> {eml?.getDate()?.toString()}</div>
							</div>
							<div>
								<div>回复：</div>
								<div>{eml?.getReplyTo() ? eml?.getReplyTo() : '无'}</div>
							</div>
						</div>

						{/* 正文 */}
						<div ref={contentTargetRef}></div>
					</div>
				</div>
			</Spin>
			{/* 附件 */}
			<Spin spinning={isAttchmentLoading}>
				<div className='attachment-list'>
					{attachmentBriefList.length === 0 && <div className=''>无附件</div>}
					{attachmentBriefList.map((item, index) => {
						return (
							<div
								key={index}
								className='attachment-item'
								onClick={() => {
									const a = document.createElement('a')
									a.href = item.url
									a.download = item.name
									document.body.appendChild(a)
									a.click()
									document.body.removeChild(a)
								}}>
								<div className=''> {item.name}</div>
								<div className=''> {item.type}</div>
							</div>
						)
					})}
				</div>
			</Spin>
		</div>
	)
}

export default EmlPreview
