import React, { PureComponent } from 'react'
import { Form, Input, Anchor } from 'antd'
import { AimOutlined } from '@ant-design/icons'
import './index.less'
import moment from 'moment'
import 'moment/locale/zh-cn'
const { Link } = Anchor
const infrequentFormRef = React.createRef(null)
export default class InfrequentData extends PureComponent {
	state = {
		lastValues: null,
	}

	setStyle = () => {
		let { height = 580, width = 350, buttons } = this.props
		height = height.toString()
		width = width.toString()
		if (width.replace(/[0-9]/g, '') === '') {
			width = `${width}px`
		}
		if (height.replace(/[0-9]/g, '') === '') {
			height = `${height}px`
		}
		const property = {
			'--height': height,
			'--width': width,
			'--form-height': `calc(${height} - 60px)`,
			'--content-height': buttons ? '88%' : '95%',
			'--buttons-height': buttons ? '12%' : '0',
		}
		const infrequentdata = document.querySelector(
			`#infrequentdata-metedata-container`
		)
		for (let i in property) {
			infrequentdata.style.setProperty(i, property[i])
		}
	}

	// 处理元数据
	setData = values => {
		const fileMetadata = []
		// 遍历要格式化的数据
		for (let i in values) {
			// i 为数字，则证明是元数据内容
			if (!isNaN(Number(i))) {
				if (values[i]) {
					fileMetadata.push({
						metadataId: Number(i),
						metadataValue: values[i],
					})
				}
			}
		}
		// 返回格式化完成的数据
		return fileMetadata
	}

	componentDidMount() {
		this.setStyle()
		this.props.getRef(infrequentFormRef)
		let infrequentData = JSON.parse(window.sessionStorage.getItem('infrequentData'))
		this.setData(infrequentData).forEach(({ metadataId, metadataValue }) => {
			if (metadataId === 86) {
				metadataValue = moment(metadataValue)
			}
			infrequentFormRef?.current?.setFields([
				{
					name: metadataId,
					value: metadataValue,
				},
			])
		})
	}

	//文本框输入限制
	dataRule = {}

	//配置不同文本框长度
	dataStyle = {
		156: { width: '780px' },
	}

	render() {
		const { disabled = false, style, getValue } = this.props
		const notCommonUse = JSON.parse(localStorage.getItem('handledDocMetaData'))[1]

		return (
			<div className='infrequent' style={style}>
				<div className='metadata-form'>
					<h3>其他元数据</h3>
					<Form
						onValuesChange={(changedValues, allValues) => {
							getValue(allValues)
							window.sessionStorage.setItem(
								'infrequentData',
								JSON.stringify(allValues)
							)
						}}
						ref={infrequentFormRef}>
						<div id='infrequentdata-metedata-container'>
							{notCommonUse.map(value => (
								<div id={value.id} key={value.id}>
									<div className='metadata-name'>
										{value.metadataName}
									</div>
									<div className='subclass'>
										{value.child.map(value => (
											<Form.Item
												label={value.metadataName}
												name={value.id}
												key={value.id}
												rules={this.dataRule[value.id]}
												style={this.dataStyle[value.id]}>
												<Input.TextArea
													style={{
														WebkitLineClamp: disabled
															? 4
															: 'auto',
													}}
													disabled={disabled}
													placeholder='无'
													bordered={!disabled}
													autoSize={
														disabled ? { maxRows: 4 } : true
													}
													data-label={value.metadataName}
													maxLength={1500}
													showCount={true}
												/>
											</Form.Item>
										))}
									</div>
								</div>
							))}
						</div>
					</Form>
				</div>
				{/* 定位锚点 */}
				<div className='positioning-metadata'>
					<AimOutlined />
					<div className='metadata-content'>
						<Anchor affix={false}>
							{notCommonUse?.map(item => {
								return (
									<Link
										href={`#${item.id}`}
										key={`#${item.id}`}
										title={item.metadataName}
									/>
								)
							})}
						</Anchor>
					</div>
				</div>
			</div>
		)
	}
}
