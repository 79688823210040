import _fetch from '@/Utils/Fetch'
import { filesUrl, blockchainUrl } from '@/Utils/Urls'

// 获取档案日志
export const getArchiveLogThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.getArchiveLog,
			type: 'get',
			data: {
				id,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})
// 获取案卷日志
export const getDossierLogThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.getDossierLog,
			type: 'get',
			data: {
				id,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取档案历史
export const getHistoryThunk = archiveId => () =>
	new Promise((resolve, reject) => {
		fetch(`${blockchainUrl.getHistory}?archiveId=${archiveId}`)
			.then(response => response.json())
			.then(res => {
				if (res && res.code === 200) {
					resolve(res.data)
				} else {
					reject()
				}
			})
	})
