// 读取切片数据线程的代码
const readChunkWorkerCode = () => {
	const fileReader = new FileReader()

	const readChunk = (fileChunkList, startIndex, endIndex) => {
		return new Promise((resolve, reject) => {
			let count = startIndex
			const results = []

			// 读取分片完成后
			fileReader.onload = e => {
				if (e.target && e.target.result) {
					count += 1
					results.push(e.target.result)
				}
				if (count < endIndex) {
					loadNext()
				} else {
					resolve(results)
				}
			}

			// 读取分片失败后
			fileReader.onerror = function () {
				reject({
					message: '文件读取失败',
				})
			}

			// 读取文件的分片
			const loadNext = () => {
				fileChunkList[count] &&
					fileReader.readAsArrayBuffer(fileChunkList[count]?.file)
			}

			loadNext()
		})
	}

	// 接收主线程的数据
	onmessage = async e => {
		const { fileChunkList, startIndex, endIndex } = e.data
		const chunkDataResult = await readChunk(fileChunkList, startIndex, endIndex)
		postMessage(chunkDataResult) // 向主线程发送数据
	}
}

export default readChunkWorkerCode
