import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import Enum, { dossierEnum } from '@/Utils/Enum'
import { destroyFilesColumns } from '@/Public/PubTable/pageColumns'
import { dossierUrl, departmentUrl } from '@/Utils/Urls'
import { treeUtil } from '@/Utils'

const markName = 'DestroyFiles' // redux的标识
const searchType = new Enum(['案卷', '文件', '全部']) // 档案搜索类型
const docSearchType = new Enum({ 1: '多媒体', 2: '文书', 3: '全部' }) // 文件搜索类型
@WrappedTable.create(markName)
class DestroyFiles extends Component {
	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{
				type: 'Select',
				name: 'type',
				label: '类型',
				options: searchType.toOptions(),
				initialValue: searchType['全部'],
			},
			{
				type: 'AsyncTreeSelect',
				name: 'categoryCodeId',
				label: '分类',
				minWidth: '155px',
				ajaxConfig: {
					url: dossierUrl.getDossierType,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys(data.children, {
						typeName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'DatePicker',
				name: 'yearNumber',
				label: '年度',
				picker: 'year',
				width: '150px',
			},
			{
				type: 'ParticiplesSelect',
				name: 'topic',
				label: '题名',
			},
			{
				type: 'ParticiplesSelect',
				name: 'nameList',
				label: '人物',
			},
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId',
				label: '部门',
				minWidth: '160px',
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
			},
			{
				type: 'Select',
				name: 'retentionPeriod',
				label: '保管期限',
				minWidth: '155px',
				options: dossierEnum.retentionPeriod.toOptions(), // 案卷或档案（文件）保管期限
			},
			{
				type: 'ParticiplesSelect',
				name: 'keyWord',
				label: '关键字',
			},
			{
				type: 'Select',
				name: 'archiveType',
				label: '载体',
				options: docSearchType.toOptions(),
				initialValue: docSearchType['全部'],
			},
			{
				type: 'ParticiplesSelect',
				name: 'metadata',
				label: '元数据',
				inputWidth: '135px',
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'post',
			url: dossierUrl.getDossierList,
			ContentType: 'application/json',
		},
	}

	render() {
		return (
			<Fragment>
				<SearchForm
					name='expiredFiles-search-form'
					formItems={this.searchFormConfig}
					changeSearchLimit={this.changeSearchLimit}
					changeTableData={this.changeTableData}
					markName={markName}
				/>
				<BaseTable
					tableConfig={destroyFilesColumns}
					changeSearchLimit={this.changeSearchLimit}
					changeTableData={this.changeTableData}
					markName={markName}
					notRowSelection={true}
					stylePointer={true}
					onRowEvent={true}
					dragSortable={true}
				/>
			</Fragment>
		)
	}

	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 */
	changeSearchLimit = data => {
		data['status'] = dossierEnum.status['已销毁'] //  档案的状态
		data['yearNumber'] && (data['yearNumber'] = +data['yearNumber'])
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = res => {
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}
}

export default DestroyFiles
