/**
 * @description: 点击searchform的"检查"按钮弹出的检查模态框
 */
import React, { useState, useEffect, useRef, Fragment, useImperativeHandle } from 'react'
import { Modal, Spin, Descriptions, Badge, Empty, Space } from 'antd'
import _ from 'lodash'
import { checkMediaJob } from './ActionCreators'

const CheckMediaJobModal = props => {
	const { modalRef } = props // 从props传入modalRef
	const [isModalVisible, setIsModalVisible] = useState(false) // 设置模态框的可见状态
	const [isSpinning, setIsSpinning] = useState(true) // 设置模态框内容是否在加载
	const [record, setRecord] = useState([]) // 设置
	const resChannels = useRef([]) // 这里使用useRef类似实例变量，用于记录请求得到的频道种类数
	const allChannels = [
		{
			channelName: '抖音',
			channelType: 1,
		},
		{
			channelName: '公众号',
			channelType: 3,
		},
		{
			channelName: '视频号',
			channelType: 4,
		},
		{
			channelName: '学习强国',
			channelType: 5,
		},
	] // 所有可用频道的种类数目
	// 后面会把求得到的频道种类数resChannels与所有可用频道数allChannels进行比较，找出没有返回的频道种类，将其标记为"未开通服务"

	useEffect(() => {
		// 模态框一打开则发起请求
		if (isModalVisible === true) {
			setIsSpinning(true)
			checkMediaJob().then(res => {
				// 记录返回的频道数目，这句一定要在后面两句setState之前调用，否则会导致组件更新渲染后，resChannels数据还没更新的情况
				res.data.data.forEach(item => {
					resChannels.current = [
						...resChannels.current,
						{
							channelName: item.channelName,
							channelType: item.channelType,
						},
					]
				})
				setIsSpinning(false)
				setRecord(res.data.data)
			})
		}
	}, [isModalVisible])

	// 对外暴露show方法，外部组件可通过modalRef.show(callback)来展示模态框
	useImperativeHandle(modalRef, () => {
		// 这里传入的ref是通过props传进来的
		return {
			show: callback => {
				setIsModalVisible(true)
				callback && callback()
			},
		}
	})

	const handleCancel = () => {
		setIsModalVisible(false)
	}

	return (
		<Fragment>
			<Modal
				title='检查频道是否可用'
				visible={isModalVisible}
				onCancel={handleCancel} // 这个必须传，不传会导致模态框无法关闭
				wrapClassName='CheckMediaJob-Modal'
				width={1000}
				footer={null}>
				<Spin spinning={isSpinning}>
					{/* 未请求成功时展示<Empty>"暂无数据"组件 */}
					{isSpinning ? (
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					) : (
						/* 请求成功时展示<Descriptions>组件 */
						<Space
							direction='vertical'
							size='large'
							style={{ display: 'flex' }}>
							{/* 遍历record展示请求返回的频道数据 */}
							{record.map(item => {
								return (
									<Descriptions
										key={item.channelType}
										title={
											<>
												{item.channelName}：
												<Badge
													status={
														item.available === true
															? 'success'
															: 'error'
													}
													text={item.message}
												/>
											</>
										}
										bordered>
										{/* 请求返回数据某些字段会有为null/0的情况，这里直接判断为null则展示"无"，不为null则展示原有数据 */}
										<Descriptions.Item
											label='限制同时工作频道数量'
											span={1.5}>
											{item.limitChannelSize !== null
												? item.limitChannelSize
												: '无'}
										</Descriptions.Item>
										<Descriptions.Item
											label='当前同时工作频道数量'
											span={1.5}>
											{item.channelActiveCount !== null
												? item.channelActiveCount
												: '无'}
										</Descriptions.Item>
										<Descriptions.Item
											label='限制频道总提交任务量'
											span={1.5}>
											{item.limitTaskCount !== null
												? item.limitTaskCount
												: '无'}
										</Descriptions.Item>
										<Descriptions.Item
											label='当前频道已提交任务量'
											span={1.5}>
											{item.provideTaskCount !== null
												? item.provideTaskCount
												: '无'}
										</Descriptions.Item>
										<Descriptions.Item label='过期时间'>
											{item.expireTime !== null
												? item.expireTime
												: '无'}
										</Descriptions.Item>
									</Descriptions>
								)
							})}
							{
								/*
								 * 与后台约定没有返回的频道则为未开通服务的频道
								 * _.differenceWith()第三个参数传入_.isEqual用于比较两个复杂数组的不同并返回它们之间不同的数组（忽略顺序）
								 * 此处用于找出没有返回的频道类型
								 */
								_.differenceWith(
									allChannels,
									resChannels.current,
									_.isEqual
								).map(item => {
									/* 返回没有开通服务的频道 */
									return (
										<Descriptions
											key={item.channelType}
											title={
												<>
													{item.channelName}：
													<Badge
														status='error'
														text='未开通服务'
													/>
												</>
											}
											bordered></Descriptions>
									)
								})
							}
						</Space>
					)}
				</Spin>
			</Modal>
		</Fragment>
	)
}

export default CheckMediaJobModal
