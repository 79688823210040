import React, { useState, useEffect } from 'react'
import { Radio, Form, message, Divider } from 'antd'
import { createAsyncSelect } from '@/Public/PubForm/FormItem/Select'
import { roleManagerUrl } from '@/Utils/Urls'
// import { treeUtil } from '@/Utils'

const formRef = React.createRef(null)
const NeedVerifyModal = props => {
	const {
		record: { needVerify, role },
		getFormData,
	} = props
	const [value, setValue] = useState(needVerify || 0)

	useEffect(() => {
		formRef.current.setFieldsValue({
			needVerify: needVerify ?? 0,
			role,
		})
	}, [needVerify, role])

	const needVerifyOnchange = e => {
		let currentValue = e.target.value
		setValue(currentValue)
	}

	const config = {
		ajaxConfig: {
			type: 'get',
			url: roleManagerUrl.getRole,
			ContentType: 'application/x-www-form-urlencoded',
			data: {
				current: 1,
				size: 999,
			},
		},
		formatDataFunc: function (data) {
			let roles = data.records.map(item => {
				return {
					label: item.description,
					value: item.id,
				}
			})
			return roles
		},
	}

	useEffect(() => {
		getFormData(onSummit)
	}, [getFormData])

	const onSummit = () =>
		new Promise((resolve, reject) => {
			formRef.current
				.validateFields()
				.then(value => {
					resolve(value)
				})
				.catch(err => {
					reject(err)
					message.error('请填写表单数据')
				})
		})

	return (
		<Form ref={formRef}>
			<Divider />
			<Form.Item
				label='审核'
				name='needVerify'
				rules={[{ required: true, message: '请选择一个用户' }]}>
				<Radio.Group
					options={[
						{ label: '需要审核', value: 1 },
						{ label: '无需审核', value: 0 },
					]}
					onChange={needVerifyOnchange}
					value={0}
				/>
			</Form.Item>
			{value ? (
				<Form.Item
					label='角色'
					name='role'
					rules={[{ required: true, message: '请选择一个用户' }]}>
					{createAsyncSelect(config)}
				</Form.Item>
			) : null}
		</Form>
	)
}

export default NeedVerifyModal
