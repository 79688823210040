import React, { PureComponent, Fragment } from 'react'
import { Button, message, Popconfirm } from 'antd'
import FilePreviewer from '@/Public/FilePreviewer'
import { connect } from 'react-redux'
import {
	faceRecognitionThunk,
	extraInformationRecognitionThunk,
	addTemporaryArchiveThunk,
	selectTemporaryArchiveThunk,
	changeTemporaryArchiveToNormalArchiveThunk,
	replaceArchiveFacesThunk,
	packArchiveThunk,
	insertDossierThunk,
	fillTemporaryArchiveThunk,
	deleteTemporaryArchiveThunk,
	getImageMetadataThunk,
	updateAttachmentThunk,
	getArchiveListThunk,
	updateRecordThunk,
} from './ActionCreators'
import { reviewFileThunk, assignFileThunk } from '@/Public/ButtonList/ActionCreator'
import { injectUnmount, authUtil, planeTree } from '@/Utils'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/zh-cn'
import FileDetails from '@/Public/FileDetails'
import PubArchive from '@/Public/PubArchive'
import { recordFileUrl, addArchivesUrl } from '@/Utils/Urls'
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
import { getConfig } from '@/Utils/ModeTranslate'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'

const { hasAuth } = authUtil
moment.locale('zh-cn')

const userName = JSON.parse(localStorage.getItem('userMessage'))?.user?.name
const dossierTypeflatTree = planeTree(
	JSON.parse(localStorage.getItem('dossierType')),
	{},
	'children',
	'id',
	'typeName'
)

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	faceRecognition: (...rest) => dispatch(faceRecognitionThunk(...rest)), // 识别人脸
	extraInformationRecognition: (...rest) =>
		dispatch(extraInformationRecognitionThunk(...rest)), // 识别经纬度和位置
	addTemporaryArchive: (...rest) => dispatch(addTemporaryArchiveThunk(...rest)), // 新增（保存临时档案）
	selectTemporaryArchive: (...rest) => dispatch(selectTemporaryArchiveThunk(...rest)), // 查询临时档案
	changeTemporaryArchiveToNormalArchive: (...rest) =>
		dispatch(changeTemporaryArchiveToNormalArchiveThunk(...rest)), // 临时档案转为正式档案
	replaceArchiveFaces: (...rest) => dispatch(replaceArchiveFacesThunk(...rest)), // 建立人脸信息和档案的关系
	packArchive: (...rest) => dispatch(packArchiveThunk(...rest)), // 档案组卷
	insertDossier: (...rest) => dispatch(insertDossierThunk(...rest)), // 新增案卷
	fillTemporaryArchive: (...rest) => dispatch(fillTemporaryArchiveThunk(...rest)), // 复制临时档案信息到目标临时档案
	deleteTemporaryArchive: (...rest) => dispatch(deleteTemporaryArchiveThunk(...rest)), //
	getImageMetadata: (...rest) => dispatch(getImageMetadataThunk(...rest)),
	updateAttachment: (...rest) => dispatch(updateAttachmentThunk(...rest)),
	reviewFile: (...rest) => dispatch(reviewFileThunk(...rest)), // 档案&案卷审核
	assignFile: (...rest) => dispatch(assignFileThunk(...rest)), // 档案&案卷指派
	getArchiveList: (...rest) => dispatch(getArchiveListThunk(...rest)), //获取案卷文件列表
	updateDossier: (...rest) => dispatch(updateRecordThunk(...rest)), //更新案卷信息
})

@connect(mapStateToProps, mapDispatchToProps)
@injectUnmount
class FillInformation extends PureComponent {
	/**
	 * 设置this.TemporaryArchive, 方便在整个类里面直接调用
	 */
	setThisTemporaryArchive = () => {
		const { checkedArr } = this.props,
			TemporaryArchive = JSON.parse(localStorage.getItem('TemporaryArchive')),
			currentTemporaryArchive = []
		let pushLength = 0
		for (let i = 0, len = TemporaryArchive.length; i < len; i++) {
			if (checkedArr.includes(TemporaryArchive[i].id)) {
				currentTemporaryArchive[pushLength++] = TemporaryArchive[i]
				if (pushLength === checkedArr.length) break
			}
		}
		this.TemporaryArchive = currentTemporaryArchive
	}

	isFresh = () => {
		this.props.selectTemporaryArchive().then(res => {
			localStorage.setItem('TemporaryArchive', JSON.stringify(res.data))
			this.setThisTemporaryArchive()
			this.setImage()
		})
	}

	// 返回图标或者视频
	renderFilePreviewer = data => {
		return (
			<FilePreviewer
				fileData={data.fileData}
				archiveId={data.archiveId}
				isShowBtn={true}
				isTemporaryArchive={true}
				isFresh={this.isFresh.bind(this)}
			/>
		)
	}

	renderThumbFilePreviewer = data => {
		return <FilePreviewer fileData={data.fileData} isThumbnail={true} />
	}

	/**
	 * 设置图片列表
	 */
	setImage = () => {
		const images = []
		if (Array.isArray(this.TemporaryArchive)) {
			this.TemporaryArchive.forEach((image, index) => {
				const imgData = {
					archiveId: image.id,
					original: `/api/file/download/${image.fileToken}`,
					thumbnail: `/api/file/download/${image.thumbnailFileToken || image.fileToken}`,
					fileData: image.fileData || {
						fileToken: image.fileToken,
						fileType: image.fileType,
						fileName: image.fileName,
					}, // 放在这里利于传参
					renderItem: this.renderFilePreviewer.bind(), // 设置显示图标、视频、图片
				}

				// 如果没有缩略图且没有图片显示则下方缩略图片显示FilePreviewer
				if (!image.thumbnailFileToken && image.fileType !== 'image/jpeg') {
					imgData.renderThumbInner = this.renderThumbFilePreviewer.bind()
				}

				// 如果是音频，显示小图标
				if (image?.fileType?.includes('audio')) {
					imgData.renderThumbInner = this.renderThumbFilePreviewer.bind()
				}

				images.push(imgData)
			})
		}
		this.setState({ images })
	}

	componentDidMount() {
		this.ids = null
		this.setThisTemporaryArchive()
		this.setImage()
		this.setFormValue(0) // 设置表单内容
		this.getCurrentDebounce(0)
		this.changeAttachmentList()
		this.TemporaryArchive[0] &&
			this.setState({
				fileType: this.TemporaryArchive[0].fileType,
			})

		this.getModeConfig()

		this.TemporaryArchive2 = this.TemporaryArchive // 点击组卷按钮后组卷界面对应的临时案卷
		this.packArchiveValues = null // 组卷信息
	}

	changeAttachmentList = () => {
		const { currentIndex } = this.state

		const attachmentList =
			this.TemporaryArchive[currentIndex]?.attachmentList?.map(item => ({
				uid: item.fileId,
				name: item.fileName,
				status: 'done',
				url: `/api/file/download/${item.fileToken}`,
			})) || []
		this.setState({
			attachmentList,
		})
	}

	state = {
		images: [], // 图片列表
		currentIndex: 0, // 当前索引
		// 不需要刷新的表格数据
		lastField: {
			confidentialLevel: 0,
			retentionPeriod: 1,
			fondsIdentifierId: 1,
			departmentId: JSON.parse(localStorage.getItem('userMessage')).user
				.departmentId,
		},
		whetherArchived: false, // 是否选中组卷
		whetherSubmit: false, // 是否提交
		loading: false, // 组卷加载状态
		disabled: false, // 表单填写状态
		prearchive: false, // 提交到著录 / 预归档
		faces: {}, // 当前人脸信息
		showHeadBtn: true, //
		fileType: '', // 当前文件的格式
		attachmentList: [],
		clickComposition: false, //是否点击了组卷按钮
		toExamine: false, // 是否点击了提交按钮
		toAssign: false, //是否点击了指派按钮
		isShowArchivedPopconfirm: false, // 是否展示生成案卷按钮的气泡确认框
	}

	// 判断一个值是否存在
	isExist = value => {
		if (value == null || value === '') return false
		return true
	}

	setFillInformationState = (states, callback) =>
		this.setState({ ...states }, () => {
			callback && callback()
		})

	// 重新进行人脸识别并填写数据
	resetImageFace = fileIndex =>
		new Promise((resolve, reject) => {
			let formData = this.TemporaryArchive[fileIndex]
			const { faceRecognition, getParentCurrent } = this.props
			const { faces } = this.state
			faceRecognition(formData.fileId)
				.then(res => {
					const faceData = res.data.faceRecognition
					const preNames = this.formRef.current.getFieldValue(74)?.split(',')
					let names = faceData
						.map(item => item.name)
						.filter(item => item !== null)
					if (preNames?.length && preNames?.length > 0) {
						names = [...preNames, ...names]
						names = [...new Set(names)]
					}
					names = names.join(',')
					if (
						getParentCurrent() === 1 &&
						this.state.currentIndex === fileIndex
					) {
						this.formRef.current.setFields([
							{
								name: 74,
								value: names,
							},
						])
						this.setState({
							faces: {
								...faces,
								// 为了防止请求完成顺序跟请求顺序不一样，需要给数据加一个索引
								// 这里用文件的id作为索引
								[formData.fileId]: faceData,
							},
						})
					}
					resolve()
				})
				.catch(() => reject())
		})

	// 识别图片经纬度和地点
	getCoordinatesAndLocation = async fileIndex => {
		// 42：纬度，44：经度，75：时间，76：地点
		try {
			let formData = this.TemporaryArchive[fileIndex]
			const existArray = [
				this.isExist(formData['42']),
				this.isExist(formData['44']),
				this.isExist(formData['75']),
				this.isExist(formData['76']),
			]

			if (existArray.includes(false)) {
				const { extraInformationRecognition, getParentCurrent } = this.props
				let { data } = await extraInformationRecognition(formData.fileId)
				if (getParentCurrent() === 1) {
					const mes = []
					existArray[0] === false &&
						mes.push({ name: 42, value: data?.latitude?.toString() })
					existArray[1] === false &&
						mes.push({ name: 44, value: data?.longitude?.toString() })
					existArray[2] === false &&
						data?.time &&
						mes.push({
							name: 75,
							value: moment(data.time, 'YYYY-MM-DDTHH:mm:ss'),
						})
					existArray[3] === false &&
						mes.push({ name: 76, value: data?.location })
					this.formRef.current.setFields(mes)
				}
			}
		} catch (err) {}
	}

	// 识别图片元数据
	getImageMetadata = async fileIndex => {
		try {
			const formData = this.TemporaryArchive[fileIndex]
			const { getImageMetadata } = this.props
			// 获取元数据
			const data = (await getImageMetadata(formData.fileId)).data
			data.forEach(item => {
				const { metadataId, metadataValue } = item
				// 如果表单中没有该项元数据，则把识别出来的填进去
				if (this.isExist(formData[metadataId]) === false) {
					this.formRef.current.setFields([
						{
							name: metadataId,
							value: metadataValue,
						},
					])
				}
			})
		} catch (err) {}
	}

	// 获取图片信息
	getImageInformation = fileIndex => {
		this.setState({ currentIndex: fileIndex })
		const fileType = this.TemporaryArchive[fileIndex]?.fileType?.split('/')[0]
		const { faces } = this.state
		if (fileType === 'image') {
			this.getCoordinatesAndLocation(fileIndex)
			this.getImageMetadata(fileIndex)
		} else {
			this.TemporaryArchive[fileIndex] &&
				this.setState({
					faces: {
						...faces,
						[this.TemporaryArchive[fileIndex].fileId]: [],
					},
				})
		}
	}

	// 切换页面实际执行的防抖函数， 防止切换过快导致接口出现问题
	getCurrentDebounce = _.debounce(this.getImageInformation, 1200)

	/**
	 * 表单提交函数
	 * @param {*} values 提交的表单数据
	 */
	onSubmit = async (values, callback) => {
		const { whetherArchived, faces } = this.state
		const { getParentCurrent, replaceArchiveFaces } = this.props
		// 如果不处于组卷状态，则证明是保存临时档案的操作
		if (whetherArchived === false) {
			let data = this.setTemporaryArchiveAjaxData(values)
			const {
				addTemporaryArchive,
				fillTemporaryArchive,
				checkedArr,
				selectTemporaryArchive,
			} = this.props
			// 保存临时档案信息
			//console.log(data);
			if (
				data[0] &&
				data[0].metadata &&
				Array.isArray(data[0].metadata) &&
				data[0].metadata.length > 0
			) {
				for (let i = 0; i < data[0].metadata.length; i++) {
					if (
						data[0].metadata[i].metadataValue !== undefined &&
						data[0].metadata[i].metadataId > 174 &&
						data[0].metadata[i].metadataId !== 180
					) {
						//后台接口给的比较迷，有些元数据需要置空，有些又不需要，所以需要前端来简单处理一下
						data[0].metadata[i].metadataValue = ''
					}
				}
			}
			//console.log(data);

			addTemporaryArchive(data)
				.then(res => {
					message.destroy()
					message.success('暂存成功')
					// 更改保存状态
					// 空档案复制当前信息
					fillTemporaryArchive({
						source: data[0].id,
						target: checkedArr,
					}).then(res => {
						// 重新设置 this.TemporaryArchive
						selectTemporaryArchive()
							.then(res => {
								localStorage.setItem(
									'TemporaryArchive',
									JSON.stringify(res.data)
								)
								this.setThisTemporaryArchive()
								if (
									getParentCurrent() === 1 &&
									whetherArchived === false
								) {
									this.setFormValue(this.state.currentIndex)
								}
								// 如果是点击了组卷 在将所有信息复用之后 再打开组卷页面
								this.state.clickComposition &&
									setTimeout(() => {
										this.setState({ whetherArchived: true })
									}, 0)
							})
							.then(() => {
								callback && callback(values)
							})
					})
				})
				.catch(err => {})
		}
		// 组卷的操作
		else {
			const packArchiveArr = []
			this.archiveFormRef.getPubArchiveData()?.new?.filesData.forEach(record => {
				packArchiveArr.push(record.id)
			})
			this.setState({ loading: true })
			const { changeTemporaryArchiveToNormalArchive } = this.props
			const normalArchiveAjaxData = this.setNormalArchiveAjaxData(
				false,
				values,
				whetherArchived
			)
			// 删除无用数据
			for (let item in values) {
				values[item] === undefined && delete values[item]
			}
			// 获取正式档案id
			if (this.ids === null) {
				for (let i = 0; i < normalArchiveAjaxData.length; i += 1) {
					if (normalArchiveAjaxData[i].categoryCode == null) {
						normalArchiveAjaxData[i].categoryCode = values.categoryCodeId
					}
				}

				const res = (
					await changeTemporaryArchiveToNormalArchive(normalArchiveAjaxData)
				).data

				// 建立人脸与档案的联系
				normalArchiveAjaxData
					.filter(item => faces[item.fileId]?.length > 0)
					.map(item => {
						const facesUids = faces[item.fileId].map(item => item.uid)
						return replaceArchiveFaces(
							facesUids,
							res[item.temporaryArchiveId]
						)
					})

				let ids = []
				for (let i in res) {
					if (packArchiveArr.includes(Number(i))) ids.push(res[i])
				}
				this.ids = ids
			}

			if (this.ids !== null) {
				this.packArchiveValues = values // 更新组卷信息

				// 案卷为空（组卷后用户删除文件的情况）
				if (this.ids.length === 0) {
					this.showArchivedPopconfirm() // 展示生成案卷按钮的气泡确认框
				} else {
					this.hideArchivedPopconfirm() // 隐藏生成案卷按钮的气泡确认框
					// 生成空案卷并组卷
					this.handlePackArchive(this.packArchiveValues)
				}
			}
		}
	}

	// 生成空案卷并组卷
	handlePackArchive = values => {
		this.hideArchivedPopconfirm()

		const { insertDossier, packArchive, setParentState } = this.props

		values.timeMeta += 'T00:00:00'
		// 生成空案卷
		insertDossier(values)
			.then(res => {
				let data = {
					ids: this.ids,
					dossierId: res.data,
				}
				// 组卷
				packArchive(data)
					.then(() => {
						message.success('组卷成功')
						this.setState({ loading: false })
						this.ids = null
						updataRedDots()
						setParentState({ current: 0, checkedArr: [] })
					})
					.catch(err => {
						this.setState({ loading: false })
					})
			})
			.catch(err => {
				this.setState({ loading: false })
			})
	}

	// 展示生成案卷按钮的气泡确认框
	showArchivedPopconfirm = () => {
		this.setState({ isShowArchivedPopconfirm: true })
	}

	// 隐藏生成案卷按钮的气泡确认框
	hideArchivedPopconfirm = () => {
		this.setState({ isShowArchivedPopconfirm: false })
	}

	/**
	 * 设置临时档案保存时的请求数据
	 * @param {Object} values 提交的表单数据
	 */
	setTemporaryArchiveAjaxData = values => {
		// 获取当前文件的信息
		const { currentIndex } = this.state
		let currentData = this.TemporaryArchive[currentIndex]
		// 设置元数据数组
		const previousFileMetadata = currentData.temporaryFileMetadata || []
		const currentFileMetadata = []
		// 设置请求对象
		const data = {}
		data.fileId = currentData.fileId
		data.thumbnailFileId = currentData.thumbnailFileId
		data.zippedFileId = currentData.zippedFileId
		data.id = currentData.id
		data.archiveType = this.props.archiveType
		const momentTime = [75, 18, 86, 20] // 存是时间的元数据
		// 遍历要格式化的数据
		for (let i in values) {
			// i 为数字，则证明是元数据内容
			if (!isNaN(Number(i))) {
				if (values[i]) {
					currentFileMetadata.push({
						id: null,
						metadataId: Number(i),
						metadataValue: momentTime.includes(Number(i))
							? moment(values[i])
									.format('YYYY-MM-DDTHH:mm:ss')
									.replace(/\.(.*)/, '')
							: values[i],
					})
				}
			}
			// 不是元数据的内容就直接放进请求数组里
			else {
				data[i] = values[i]
			}
		}

		if (Array.isArray(data.metadata)) {
			data.metadata.forEach(item => {
				currentFileMetadata.push(item)
			})
		}

		// 用之前的元数据和现在的元数据进行对比
		if (previousFileMetadata.length > 0) {
			if (currentFileMetadata.length === 0) {
				previousFileMetadata.forEach((item, index) => {
					currentFileMetadata.push({
						id: item.id,
						metadataId: item.metadataId,
						metadataValue: '',
					})
				})
			} else {
				previousFileMetadata.forEach((item, index) => {
					for (let i = 0, len = currentFileMetadata.length; i < len; i++) {
						if (item.metadataId === currentFileMetadata[i].metadataId) {
							currentFileMetadata[i].id = item.id
							break
						}
						if (i === len - 1) {
							currentFileMetadata.push({
								id: item.id,
								metadataId: item.metadataId,
								metadataValue: '',
							})
						}
					}
				})
			}
		}
		// 返回格式化完成的数据
		data.fileMetadata = currentFileMetadata
		return [data]
	}

	/**
	 * 设置切换时表单展示的数据
	 * @param {number} currentIndex  当前索引
	 */
	setFormValue = currentIndex => {
		if (this.formRef.current === null) return
		this.formRef.current.resetFields()
		const { lastField } = this.state
		let currentData = this.TemporaryArchive[currentIndex]
		if (currentData?.categoryCodeId === null) {
			currentData = Object.assign(currentData, lastField)
		}
		if (currentData?.temporaryFileMetadata) {
			currentData.temporaryFileMetadata.forEach(item => {
				currentData[item.metadataId] = item.metadataValue
			})
		}
		if (lastField?.keyWord) {
			currentData.keyWord = lastField.keyWord
		}
		;[75, 18, 20, 86].forEach(item => {
			if (currentData?.[item]) {
				currentData[item] = moment(currentData[item], 'YYYY-MM-DD HH:mm:ss')
			}
		})
		this.formRef.current.setFieldsValue(currentData)
	}

	/**
	 * 保存最后一次填写的不想被刷新的数据
	 * @param {Object} changedFields  这次改变的对象
	 */
	saveLastField = changedFields => {
		let key = Object.keys(changedFields)[0]
		if (isNaN(Number(key)) && key !== 'topic') {
			let { lastField } = this.state
			lastField = {
				...lastField,
				...changedFields,
			}
			this.setState({ lastField })
		}
	}

	/**
	 * 设置临时文件转成正式文件的请求数据
	 * @param {Boolean} archived  true为预归档 false为著录
	 * @param {object} values 案卷表单信息
	 * @param {Boolean} whetherArchived 是否在组卷状态
	 */
	setNormalArchiveAjaxData = (archived, values, whetherArchived = false) => {
		let data = []
		let necessaryItmes = [
			'confidentialLevel',
			'retentionPeriod',
			'departmentId',
			'topic',
			'fileId',
			'fondsIdentifierId',
			'thumbnailFileId',
			'zippedFileId',
		]
		let TemporaryArchive = whetherArchived
			? this.TemporaryArchive2
			: this.TemporaryArchive // 组卷状态下则获取组卷界面对应的临时案卷
		TemporaryArchive.forEach(item => {
			// 删除不必要的数据
			let temp = {}
			for (let i in item) {
				if (necessaryItmes.indexOf(i) !== -1) {
					temp[i] = item[i]
				}
			}
			temp.temporaryArchiveId = item.id
			temp.categoryCode = item.categoryCodeId || values.categoryCodeId
			temp.archiveType = this.props.archiveType
			// 设置元素据
			const temporaryFileMetadata = item.temporaryFileMetadata
			const metadata = []

			temporaryFileMetadata
				? temporaryFileMetadata.forEach(i =>
						metadata.push({
							metadataId: i.metadataId,
							metadataValue: i.metadataValue,
						})
					)
				: // 给一个默认时间，默认跟当前文件一样
					metadata.push({
						metadataId: 75,
						metadataValue: moment(values[75])
							.format('YYYY-MM-DDTHH:mm:ss')
							.replace(/\.(.*)/, ''),
					})
			temp.metadata = metadata
			temp.archived = archived
			// 设置关键字
			temp.keyWord = item.keyWord
			//如果在组卷状态，将全宗 类别 部门 密级 保管期限，从案卷复用
			if (whetherArchived) {
				let copyArchiveData = {
					categoryCode: values.categoryCodeId,
					fondsIdentifierId: values.fondsIdentifierId,
					departmentId: values.departmentId,
					confidentialLevel: values.confidentialLevel,
					retentionPeriod: values.retentionPeriod,
				}
				temp = Object.assign(temp, copyArchiveData)
			}
			data.push(temp)
		})
		return data
	}

	/**
	 * 确认文件提交操作 临时档案 -> 著录 / 预归档
	 */
	confirmFilesSubmit = async values => {
		const {
			changeTemporaryArchiveToNormalArchive,
			setParentState,
			replaceArchiveFaces,
			reviewFile,
			assignFile,
		} = this.props
		const { prearchive, faces, toExamine, toAssign } = this.state
		let data = this.setNormalArchiveAjaxData(prearchive, values)
		changeTemporaryArchiveToNormalArchive(data)
			.then(async res => {
				updataRedDots()
				message.destroy()
				message.success(`已将所有文件变为${prearchive ? '预归档' : '著录'}状态`)
				let records = data.map(item => {
					return {
						type: 1,
						id: res.data[item.temporaryArchiveId],
						categoryCode: dossierTypeflatTree[item.categoryCode],
					}
				})
				//判断是否由案卷编辑转过来的，是则将文件加入案卷
				if (this.props.markName) {
					if (this.props.markName === 'newArchive') {
						history.push(linkTo.to('/newConstruction/newArchives'))
					} else {
						this.props
							.getArchiveList(this.props.archiveId)
							.then(async archiveList => {
								const archiveIds = []
								archiveList.forEach(item => {
									archiveIds.push(item.id)
								})
								archiveIds.push(records[0].id)
								const requestData = {
									id: this.props.archiveId,
									archiveIds: archiveIds,
									confidentialLevel: values.confidentialLevel,
									categoryCodeId: values.categoryCodeId,
									retentionPeriod: values.retentionPerio,
									departmentId: values.departmentId,
									topic: values.topic,
								}
								sessionStorage.setItem('lastRoute', JSON.stringify(1))
								await this.props.updateDossier(requestData).then(() => {
									window.history.back()
								})
							})
					}
				}
				toAssign && (await assignFile(records))
				toExamine && (await reviewFile(records))

				setParentState({ current: 0, checkedArr: [] })

				// 建立人脸与档案的联系
				data.filter(item => faces[item.fileId]?.length > 0).map(item => {
					const facesUids = faces[item.fileId].map(item => item.uid)
					return replaceArchiveFaces(
						facesUids,
						res.data[item.temporaryArchiveId]
					)
				})
			})
			.catch(err => {})
	}

	/**
	 * 文件组卷按钮
	 * @param {Number} currentIndex 当前索引
	 */
	archivedBtnClick = currentIndex => {
		this.setState({ whetherSubmit: false }, () => {
			this.formRef.current
				.validateFields()
				.then(() => {
					this.formRef.current.submit()
					this.setState({
						clickComposition: true,
					})
				})
				.catch(() => {})
		})
	}

	/**
	 * 图片滑动函数
	 * @param {Number} currentIndex 当前索引
	 * @param {Boolean} whetherArchived 是否处于组卷状态
	 */
	onSlide = (currentIndex, whetherArchived) => {
		this.getCurrentDebounce(currentIndex)
		const { disabled } = this.state
		if (!disabled) {
			const FieldsValue = this.formRef.current.getFieldsValue()
			this.onSubmit(FieldsValue)
		}
		this.setState({ currentIndex }, () => {
			this.changeAttachmentList()
		})
		whetherArchived === false && this.setFormValue(currentIndex)
	}

	// 获取到文件编辑器的ref 和 终止人脸识别的函数
	getRef = (ref, stopRecognition) => {
		this.formRef = ref
		this.stopRecognition = stopRecognition
	}

	//获取不常用数据的ref
	getInfrequentFormRef = ref => {
		this.infrequentFormRef = ref
	}

	//存取不常用数据
	accessInfrequentData = () => {
		return {
			setData: localStorage.setItem(
				'TemporaryInfrequentData',
				JSON.stringify(this.infrequentFormRef.getFieldsValue())
			),
			getData: JSON.parse(localStorage.getItem('TemporaryInfrequentData')),
		}
	}

	// 获取新建案卷的ref
	getInstance = async formRef => {
		this.archiveFormRef = formRef
	}

	// 删除新建案卷左边的某一项
	deleteItem = index => {
		this.TemporaryArchive2 = this.TemporaryArchive2.filter((_, i) => i !== index) // 将该项从TemporaryArchive2中去除
		const data = this.archiveFormRef.getPubArchiveData()
		if (data === undefined || data === null) {
			return
		}
		data.new.filesData.splice(index, 1)
		this.archiveFormRef.upgradePubArchive({ data })
	}

	// 将文件的相同的信息，抽取出来，赋给案卷未填写的信息
	// 其实就类别号和部门号
	formatFormValue = (filesWillWithIn, defaultFormValue) => {
		if (!filesWillWithIn.length) return defaultFormValue
		let categoryCodeId = filesWillWithIn[0].categoryCodeId
		let departmentId = filesWillWithIn[0].departmentId
		let fondsIdentifierId = filesWillWithIn[0].fondsIdentifierId
		if (filesWillWithIn.length === 1) {
			return (defaultFormValue = {
				...defaultFormValue,
				categoryCodeId, // 类别号
				departmentId,
				fondsIdentifierId, // 全宗号
			})
		} else {
			const checkCCI = filesWillWithIn.every(item => {
				return item.categoryCodeId === categoryCodeId
			})
			const checkDI = filesWillWithIn.every(item => {
				return item.departmentId === departmentId
			})
			const checkFId = filesWillWithIn.every(item => {
				return item.fondsIdentifierId === fondsIdentifierId
			})
			return (defaultFormValue = {
				...defaultFormValue,
				categoryCodeId: checkCCI ? categoryCodeId : undefined, // 类别号
				departmentId: checkDI ? departmentId : undefined,
				fondsIdentifierId: checkFId ? fondsIdentifierId : undefined, // 全宗号
			})
		}
	}

	// 更新附件
	changeAttachments = (attachmentList, documentId, callback) => {
		const { updateAttachment, selectTemporaryArchive } = this.props
		updateAttachment({
			fileIds: attachmentList,
			type: 2, // 2 为临时档案更新附件的 type
			documentId,
		})
			.then(() => {
				message.success('保存成功')
				selectTemporaryArchive()
					.then(res => {
						localStorage.setItem('TemporaryArchive', JSON.stringify(res.data))
						this.setThisTemporaryArchive()
					})
					.then(() => {
						this.changeAttachmentList()
					})
			})
			.catch(() => {
				message.error('保存失败')
			})
			.finally(() => {
				callback && callback()
			})
	}
	//获取提交模式配置
	getModeConfig = () => {
		getConfig().then(Config => {
			this.setState({
				modeConfig: Config.modeConfig,
			})
		})
	}

	// 返回按钮的回调
	returnButtonCallback = () => {
		this.setState({ whetherArchived: false }, () => {
			this.setFormValue(0)
		})
	}

	render() {
		const {
				images,
				whetherArchived,
				disabled,
				faces,
				currentIndex,
				fileType,
				attachmentList,
				modeConfig = [],
				isShowArchivedPopconfirm,
			} = this.state,
			{ setParentState, archiveType } = this.props
		// 根据文件id从faces里面取出当前的人脸数据
		const currentFileId =
			this.TemporaryArchive && this.TemporaryArchive[currentIndex]?.fileId
		const currentId = this.TemporaryArchive && this.TemporaryArchive[currentIndex]?.id
		const faceData = (faces && faces[currentFileId]) || ['beforeFetch']
		//把archiveType和英文名对应起来
		const archiveTypeEnName = {
			1: 'commit_mode_duomeiti', //多媒体
			2: 'commit_mode_wenshu', //文书
		}

		// 处于组卷状态则设置PubArchive的参数
		let temporaryArchive = null
		if (whetherArchived) {
			temporaryArchive = [...this.TemporaryArchive]
			for (let i of temporaryArchive) {
				i.preview = {
					fileData: {
						fileType: i.thumbnailFileType || i.fileType,
						fileToken: i.thumbnailFileToken || i.fileToken,
						fileName: i.fileName,
					},
				}
				// 默认类别号和部门
				i.categoryCodeId =
					i.categoryCodeId || temporaryArchive[currentIndex].categoryCodeId
				i.departmentId =
					i.departmentId || temporaryArchive[currentIndex].departmentId
				if (!i['recordUser']) {
					i['recordUser'] = userName
					i['recordTime'] = '此刻'
				}
			}
		}

		const buttons = []

		if (modeConfig[archiveTypeEnName[archiveType]] !== 2) {
			buttons.push({
				text: <>&#xec09; 保存</>,
				title: '保存',
				type: 'primary',
				htmlType: 'submit',
				className: 'iconfont-buttons',
				onClick: () => {
					this.setState({ prearchive: false, whetherSubmit: true })
				},
			})
		}

		// 根据权限判断是否可以归档
		if (
			modeConfig[archiveTypeEnName[archiveType]] !== 2 &&
			hasAuth(recordFileUrl.archiveFile)
		) {
			// buttons.push({
			//   text: '归档',
			//   htmlType: "submit",
			//   className: "btn-success",
			//   onClick: () => {
			//     this.setState({ prearchive: true, whetherSubmit: true })
			//   }
			// })

			buttons.push(
				{
					text: <>&#xe632; 指派</>,
					title: '指派',
					htmlType: 'submit',
					className: 'ant-btn-primary iconfont-buttons',
					onClick: () => {
						this.setState({
							prearchive: false,
							whetherSubmit: true,
							toAssign: true,
						})
					},
				},
				{
					text: <>&#xe628; 归档</>,
					title: '归档',
					htmlType: 'submit',
					className: 'ant-btn-primary iconfont-buttons',
					onClick: () => {
						this.setState({
							prearchive: false,
							whetherSubmit: true,
							toExamine: true,
						})
					},
				}
			)
		}

		// 根据权限判断是否可以组卷
		if (
			modeConfig[archiveTypeEnName[archiveType]] !== 1 &&
			hasAuth(addArchivesUrl.insertDossier)
		) {
			buttons.push({
				text: <>&#xe64a; 组卷</>,
				title: '组卷',
				className: 'ant-btn-primary iconfont-buttons',
				onClick: () => {
					const { currentIndex } = this.state
					this.archivedBtnClick(currentIndex)
				},
			})
		}

		if (!whetherArchived) {
			buttons.push({
				text: <>&#xe816; 删除</>,
				title: '删除',
				type: 'primary',
				className: 'btn-danger iconfont-buttons',
				onClick: () => {
					const { deleteTemporaryArchive, checkedArr } = this.props,
						{ currentIndex } = this.state,
						temporaryId = this.TemporaryArchive[currentIndex].id
					deleteTemporaryArchive([temporaryId])
						.then(res => {
							message.success('删除成功')
							checkedArr.splice(currentIndex, 1)
							setParentState(
								{
									checkedArr,
									current: checkedArr.length === 0 ? 0 : 1,
								},
								() => {
									this.setThisTemporaryArchive()
									this.setImage()
								}
							)
							window.sessionStorage.removeItem('infrequentData')
						})
						.catch(err => {})
				},
			})
		}

		return (
			<Fragment>
				{!whetherArchived && (
					<FileDetails
						style={{ marginTop: '10px' }}
						setFillInformationState={this.setFillInformationState}
						uniqueName='temporary'
						items={images}
						buttons={buttons}
						disabled={disabled}
						fileType={fileType}
						onSlide={currentIndex => {
							this.onSlide(currentIndex, whetherArchived)
							this.setState({
								fileType: this.TemporaryArchive[currentIndex].fileType,
							}) // 切换后清空人脸数据
							this.stopRecognition && this.stopRecognition()
						}}
						isArchived={false}
						onFinish={values =>
							this.onSubmit(values, values => {
								const { whetherSubmit } = this.state
								if (whetherSubmit) {
									this.confirmFilesSubmit(values)
								}
							})
						}
						onValuesChange={changedFields => {
							this.saveLastField(changedFields)
						}}
						getRef={this.getRef}
						// 传人脸数据
						isFaceDetails={faceData}
						// 重新设置人物输入框函数
						resetImageFace={() =>
							this.resetImageFace(this.state.currentIndex)
						}
						defaultFileList={attachmentList}
						changeAttachments={(ids, callback) => {
							this.changeAttachments(ids, currentId, callback)
						}}
						isPaperDocument={archiveType === 2}
						getInfrequentFormRef={this.getInfrequentFormRef}
						isNewConstruction={true}
						returnCallback={() => {
							if (this.props.markName)
								window.history.back() //若为案卷新建文件，则直接返回
							else
								setParentState({
									checkedArr: [],
									current: 0,
								})
						}}
					/>
				)}

				{whetherArchived && (
					<PubArchive
						markName='newFiles'
						filesWillWithIn={temporaryArchive}
						getInstance={this.getInstance}
						formatFormValue={this.formatFormValue}
						hasDragger={true}
						renderOperation={(text, record, index) => (
							<Button
								className='table-btn-danger'
								onClick={() => {
									this.deleteItem(index)
								}}>
								删除
							</Button>
						)}
						renderBottomButtons={() => (
							<Fragment>
								<Button
									onClick={() => {
										this.returnButtonCallback()
									}}>
									取消
								</Button>
								<Popconfirm
									title='当前案卷为空，是否生成空案卷？'
									onConfirm={() =>
										this.handlePackArchive(this.packArchiveValues)
									}
									onCancel={this.hideArchivedPopconfirm}
									okText='是'
									cancelText='否'
									visible={isShowArchivedPopconfirm}>
									<Button
										className='btn-success'
										onClick={() => {
											this.archiveFormRef
												.validateFields()
												.then(values => {
													this.onSubmit(values)
												})
												.catch(() => {})
										}}>
										生成案卷
									</Button>
								</Popconfirm>
							</Fragment>
						)}
						returnButtonCallback={this.returnButtonCallback} // 返回按钮的回调
					/>
				)}
			</Fragment>
		)
	}
}

export default FillInformation
