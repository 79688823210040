import React, { PureComponent, Fragment, forwardRef } from 'react'
import { Input, Tree, Spin } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { treeUtil, rjUtils } from '@/Utils'
import { connect } from 'react-redux'
import { getThemeTreeThunk } from './ActionCreators'
import WithRef from '@/Public/WithRef'
import { cloneDeep } from 'lodash'
const { Search } = Input
const { flatTree, listToTree } = treeUtil
const mapDispatchToProps = dispatch => ({
	getThemeTree: (...rest) => dispatch(getThemeTreeThunk(...rest)), // 获取编研主题树
})
const rootNodeName = '全部' // 根节点名称

// 部门树组件
@connect(null, mapDispatchToProps)
@WithRef
class DossierTypeTree extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			treeSpinning: false, // 树是否加载中
			selectedKeys: [],
			expandedKeys: [],
			autoExpandParent: true, // 是否自动展开父节点
			flatTreeData: [], // 树的扁平化数据
			searchValue: '', // 搜索内容
		}
	}

	componentWillMount() {
		const { getInstance } = this.props
		if (typeof getInstance === 'function') {
			// 在这里把this暴露给父组件（Utils高阶组件），得以让其通过使用 this.pubFormRef 获取 PubForm 的方法
			getInstance(this)
		}
	}

	componentDidMount() {
		this.getTree()
	}

	// 获取类别号数据并扁平化数据
	getTree = () => {
		const { expandedKeys } = this.state
		const { getThemeTree } = this.props

		this.setState(
			{
				treeSpinning: true,
			},
			() => {
				getThemeTree().then(tree => {
					this.setState({
						flatTreeData: flatTree(tree.data, node => {
							rjUtils.clearNull(node)
						}),
						treeSpinning: false,
						// 如果有扩展记录则保持原来的扩展状态，否则默认只展示根节点
						expandedKeys: expandedKeys.length > 0 ? expandedKeys : [-1],
					})
				})
			}
		)
	}

	// 树还原过程为节点进行修饰
	modifyNode = node => {
		const { searchValue } = this.state
		// 对根节点做一点修改，先修改再获取以得到新的值
		if (node.id === -1) node['topic'] = rootNodeName
		// 对根节点做一点修改，先修改再获取以得到新的值
		const { id, topic } = node
		// 根据搜索内容显示部分红色字体
		const index = topic.indexOf(searchValue)
		const beforeStr = topic.substr(0, index)
		const afterStr = topic.substr(index + searchValue.length)
		const title =
			index > -1 ? (
				<span key={`highlight-wrapper-span-${id}`}>
					{beforeStr}
					<span key={`highlight-${id}`} style={{ color: '#f50' }}>
						{searchValue}
					</span>
					{afterStr}
				</span>
			) : (
				topic
			)
		// 自定义 title，添加上操作图标
		node.title = (
			<Fragment>
				<span key={`title-${id}`}>{title}</span>
			</Fragment>
		)
		return node
	}

	/**
	 * 扩展节点的函数
	 * @param {Array} expandedKeys 目前展开的所有节点
	 */
	onExpand = expandedKeys => this.setState({ expandedKeys, autoExpandParent: false })

	// 搜索内容改变触发事件
	onSearchChange = e => {
		const { value } = e.target
		const { flatTreeData } = this.state
		// 如果值为空则默认展开根节点，否则展开包括搜索内容的节点
		const expandedKeys = !value
			? [-1]
			: flatTreeData
					.filter(node => node.topic.includes(value))
					.map(({ key }) => key)
		this.setState({
			expandedKeys,
			searchValue: value,
			autoExpandParent: true,
		})
	}

	// 树节点选择触发
	onSelect = (selectedKeys, event) => {
		const { updateSelectNode, selectedNodeMsg } = this.props
		this.setState({ selectedKeys })
		// // 选中节点的信息
		let newSelectedNodeMsg = cloneDeep(event.selectedNodes[0] || {})
		// 这两个属性无法 JSON.stringify
		delete newSelectedNodeMsg['children']
		delete newSelectedNodeMsg['title']
		// 如果是选择同一个，则不发送请求
		if (newSelectedNodeMsg.id === selectedNodeMsg.id) return
		// 将选择的节点的相关信息存入 state，每次选择新节点，则发送请求获取对应部门的数据
		updateSelectNode(newSelectedNodeMsg)
	}

	// 将扁平树还原为树结构
	createTreeData = () => {
		const { flatTreeData } = this.state
		if (!flatTreeData.length) return [] // 不能传入 [] 给 listToTre，会报错
		return listToTree(flatTreeData, this.modifyNode)
	}

	render() {
		const { treeSpinning, expandedKeys, selectedKeys, autoExpandParent } = this.state
		return (
			<Fragment>
				<Search
					className='search-bar'
					placeholder='搜索类别号'
					onChange={this.onSearchChange}
				/>
				<Spin spinning={treeSpinning}>
					<Tree
						onExpand={this.onExpand} // 节点扩展调用函数
						onSelect={this.onSelect} // 节点选择调用的函数
						expandedKeys={expandedKeys} // 扩展的节点
						selectedKeys={selectedKeys} // 选择的节点
						autoExpandParent={autoExpandParent} // 是否自动展开父节点
						showLine={false} // 不要对齐线
						showIcon // 展示自己的图标
						switcherIcon={<DownOutlined />} // 节点头的图标
						blockNode // 每个node占据整行
						treeData={this.createTreeData()}
						ref={this.props.myForwardedRef}
					/>
				</Spin>
			</Fragment>
		)
	}
}

export default forwardRef((props, ref) => {
	return <DossierTypeTree {...props} myForwardedRef={ref} />
})
