/**
 * @author: Daaavid
 * @date: 2022.08.20
 * @contact: 915516703@qq.com
 * @description: antd的Upload组件封装
 */
import React, { PureComponent } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import { Button, Upload } from 'antd'
import _ from 'lodash'

// FIXME: 这个组件封装代码运行没问题，但封装得不是很好，很多东西写死了，需要优化或者重新封装一下
export const createUpload = (config, formRef) => {
	class FileUpload extends PureComponent {
		state = { fileList: [] }

		componentDidUpdate(prevProps, prevState) {
			// 通过setfieldsvalue设置进来默认的fileList,在props中
			if (_.isEqual(prevProps.fileList, this.props.fileLst)) {
				this.setState({ fileList: this.props.fileList })
			}
		}

		setFileList = fileList => {
			this.setState({ fileList })
		}

		render() {
			const { onChange = () => {} } = config

			return (
				<ImgCrop rotate>
					<Upload
						maxCount={config.maxCount}
						onChange={file => {
							onChange(file, this.setFileList)
						}}
						beforeUpload={() => false}
						listType={config.listType ? config.listType : 'text'}
						fileList={this.state.fileList}>
						<Button icon={<UploadOutlined />}>上传二维码</Button>
						{/* <div>
            <PlusOutlined />
            <div
              style={{
                marginTop: 8,
              }}
            >
              Upload
            </div>
          </div> */}
					</Upload>
				</ImgCrop>
			)
		}
	}
	return <FileUpload />
}
