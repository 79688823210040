import _fetch from '@/Utils/Fetch'
import { appraisalUrl } from '@/Utils/Urls'
import { Modal } from 'antd'
import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import ApprasialActionType from '@/Redux/ActionTypes/AppraisalDetails'
// 更新红点函数
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'

const { APPRAISAL_DATA } = ApprasialActionType

export const tips = {
	NO_PRE_RECORD: '没有上一项了',
	NO_NEXT_RECORD: '没有下一项了',
	CHOOSE_AT_LEAST_ONE: '请选择至少一项后再进行操作',
	DELETE_SUCCESS: '删除鉴定成功',
	ALLOW_REVIEW_APPLY_SUCCESS: '已同意该审核鉴定',
	VERIFY_SUCCESS: '鉴定成功',
	ONLY_VERIFY_CAN_BE_IDENTIFIED: '只有处于待鉴定状态才能进行鉴定',
	SUBMIT_SUCCESS: '提交成功',
	ONLY_APPLY_AND_RECORD_CAN_BE_SUBMITED: '只有处于待鉴定状态才能提交',
	ONLY_APPLY_AND_RECORD_CAN_BE_DELETED: '只有处于待鉴定状态才能删除',
}

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

// 提交鉴定申请
export const submitVerifyApplyThunk = ids => () =>
	new Promise((resolve, reject) => {
		Modal.confirm({
			maskClosable: true,
			title: `提交鉴定申请`,
			content: `您正在进行提交鉴定申请操作，是否继续？`,
			zIndex: 1005,
			onOk: () => {
				_fetch({
					url: appraisalUrl.verify,
					data: {
						ids: ids,
					},
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						resolve(res.data.data)
					} else {
						reject()
					}
				})
			},
		})
	})

// 删除鉴定申请
export const deleteVerifyThunk = ids => () =>
	new Promise((resolve, reject) => {
		Modal.confirm({
			maskClosable: true,
			title: '删除鉴定申请',
			content: '您正在对当前申请进行删除操作，是否继续？',
			okType: 'primary',
			okText: '确定',
			cancelText: '取消',
			onOk: () => {
				_fetch({
					url: appraisalUrl.deleteVerify,
					data: {
						ids: ids,
					},
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						updataRedDots()
						resolve(res.data.data)
					} else {
						reject()
					}
				})
			},
		})
	})

// 提交鉴定
export const verifyThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: appraisalUrl.verify,
			data: data,
			type: 'post',
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

// 查看
export const getVerifyDetailThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: appraisalUrl.getVerifyDetail,
			data: {
				id: id,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

export const addAppraisalDataToRedux = data => {
	return {
		type: APPRAISAL_DATA,
		data: data,
	}
}
