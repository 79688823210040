import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PubModalForm } from '@/Public/PubForm'
import { editUserThunk } from '../ActionCreator'
const formLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 10 },
}
// 表单项的单独配置，优先级高于表单项的统一配置
const formItemLayout = {
	labelCol: { span: 7 },
	wrapperCol: { span: 17 },
}

const mapStateToProps = state => {
	return {
		userInfo: state.headerBarData.userData,
	}
}
const mapDispatchToProps = dispatch => ({
	editUser: (...rest) => dispatch(editUserThunk(...rest)), //修改用户资料
})
@connect(mapStateToProps, mapDispatchToProps)
class modal extends Component {
	formItems = [
		[
			{
				type: 'Input',
				name: 'name',
				label: '姓名',
				layout: formItemLayout,
				placeholder: '请输入名字',
				span: 20,
			},
		],
		[
			{
				type: 'Input',
				name: 'phone',
				label: '电话号码',
				placeholder: '请输入电话号码',
				layout: formItemLayout,
				rules: [
					{
						required: false,
					},
					{
						pattern: /^1[3456789]\d{9}$/,
						message: '输入正确的电话号码',
					},
				],
				span: 20,
			},
		],
		[
			{
				type: 'Input',
				name: 'email',
				label: '邮箱',
				placeholder: '请输入邮箱',
				layout: formItemLayout,
				rules: [
					{
						required: false,
					},
					{
						type: 'email',
						message: '请输入正确的邮箱',
					},
				],
				span: 20,
			},
		],
	]

	state = {
		visible: false,
		title: '编辑资料',
	}

	handleEditInfo = (formData, { finish, hide }) => {
		formData['id'] = this.props.userInfo.id
		const { editUser } = this.props
		editUser(formData, { finish, hide })
	}

	render() {
		let { title } = this.state
		return (
			<div>
				<PubModalForm
					title={title}
					destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
					onOk={this.handleEditInfo}
					formDisabled={false}
					ref={this.props.modalRef} // 若隔层由外层通过props传递
					width={480} // 默认值900
					formProps={{
						// 表单的配置项
						name: 'EditOwnInfoModal',
						formItems: this.formItems,
						layout: formLayout,
					}}
				/>
			</div>
		)
	}
}

export default modal
