import React, { PureComponent } from 'react'
import SerialNumber from '../SerialNumber'
import ModeConfigure from './ModeConfigure'
import FaceConfigure from './FaceConfigure'
import AutoDescriptionConfigure from './AutoDescriptionConfigure'
import { Radio } from 'antd'

const { Group, Button } = Radio

export default class SystemConfigure extends PureComponent {
	state = {
		pageValue: 0,
	}

	onChange = e => {
		this.setState({
			pageValue: Number(e.target.value),
		})
	}

	changePage = () => {
		const { pageValue } = this.state

		switch (pageValue) {
			case 0:
				return <SerialNumber />
			case 1:
				return <ModeConfigure />
			case 2:
				return <FaceConfigure />
			case 3:
				return <AutoDescriptionConfigure />
			default:
		}
	}

	render() {
		return (
			<>
				<Group
					defaultValue='0'
					onChange={this.onChange}
					style={{ marginBottom: '20px' }}>
					<Button value='0'>档案号配置</Button>
					<Button value='1'>提交模式配置</Button>
					<Button value='2'>人脸识别配置</Button>
					<Button value='3'>批量著录配置</Button>
				</Group>
				{this.changePage()}
			</>
		)
	}
}
