import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import './index.less'
import { history } from '@/index'
import { Modal, Popover } from 'antd'
import { getUserInfoThunk, getUserDataAction } from './ActionCreator'
import EditOwnInfoModal from './EditOwnInfoModal'
import UpdatePasswordModal from './UpdatePasswordModal'
import { getConfig } from '@/Utils/ModeTranslate'

const EditOwnInfoModalRef = React.createRef()
const UpdatePasswordModalRef = React.createRef()

const mapStateToProps = state => {
	return {
		route: state.route.item,
		userInfo: state.headerBarData.userData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getUserInfo: (...rest) => dispatch(getUserInfoThunk(...rest)), // 获取已登录用户信息
		getUserData: (...rest) => dispatch(getUserDataAction(...rest)),
		clearTabsAll() {
			dispatch({ type: 'TAB_ALLCLEAR' })
		},
	}
}

class HeaderBar extends React.Component {
	state = {
		isModalVisible: false,
		content: (
			<div
				style={{
					width: '60px',
					height: '55px',
					color: '#4A6DB8',
					cursor: 'pointer',
				}}>
				<p onClick={() => this.showInfoModal()}>编辑资料</p>
				<p onClick={() => this.showPwdModal()}>修改密码</p>
			</div>
		),
	}

	// 点击退出登录弹出模态框
	loginOutHandler() {
		this.setState({ isModalVisible: true })
	}
	// 确认退出
	handleOk() {
		history.push('')
		window.location.reload()
		localStorage.clear()
	}
	// 取消，关掉模态框
	handleCancel() {
		this.setState({ isModalVisible: false })
	}

	// 显示修改用户信息的模态框
	showInfoModal() {
		EditOwnInfoModalRef.current.show(formRef => {
			this.props.getUserInfo().then(res => {
				// 确保每次打开都是最新的数据
				formRef.setFieldsValue(res)
			})
		})
	}

	// 显示修改密码的模态框
	showPwdModal() {
		UpdatePasswordModalRef.current.show(formRef => {})
	}

	componentDidMount() {
		this.props.getUserInfo().then(res => {
			this.props.getUserData(res)
		})

		getConfig().then(Config => {
			this.setState({
				Config,
			})
		})
	}

	render() {
		const { isModalVisible, content, Config } = this.state
		const { userInfo } = this.props
		return (
			<div className='header-menu'>
				<div className='header-menu-logo'>
					<div
						className='logo-img'
						style={{ backgroundImage: `url(${Config?.logo})` }}></div>
					<i>{Config?.systemNameCN}</i>
				</div>
				<div className='header-right'>
					<Popover content={content} title='' trigger='hover'>
						<div className='header-menu-innerdiv'>
							{userInfo.departmentName} : {userInfo.name}
						</div>
					</Popover>
					<div
						className='header-menu-innerdiv'
						id='login-out'
						onClick={() => {
							this.loginOutHandler()
						}}>
						退出登录
					</div>
					<Modal
						title='提示'
						visible={isModalVisible}
						onOk={() => {
							this.handleOk()
						}}
						onCancel={() => {
							this.handleCancel()
						}}>
						<p>是否确定要退出系统？</p>
					</Modal>
					<EditOwnInfoModal modalRef={EditOwnInfoModalRef} />
					<UpdatePasswordModal modalRef={UpdatePasswordModalRef} />
				</div>
			</div>
		)
	}
}

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(HeaderBar)
