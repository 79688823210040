import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { history } from '@/index'
import { connect } from 'react-redux'
import {
	getWorkStaionAction,
	SetRouterAction,
	pushRoutersAction,
	updataRedDots,
} from './ActionCreator'
import { cloneDeep } from 'lodash'
import { cfUtils } from '@/Utils'
import FirstRouteMenu from './FirstRouteMenu'
import SecondRouteMenu from './SecondRouteMenu'
import ActionModal from './ActionModal'
import { homePath } from '@/Components/Login/LoginForm'
import { updateOpenKeysAction, updateselectedKeysAction } from './ActionCreator'
import './index.less'

// 红点接口即权限
import { redDotsUrl } from '@/Utils/Urls'

/**
 * 返回处理后的一二级路由
 * @param {*} [slideBar=[]]
 * @return {*}
 */
function newSlideBar() {
	const firstRoute = JSON.parse(localStorage.getItem('firstRoute')) || []
	if (slideContainer.newFirstSlide) {
		return [slideContainer.newFirstSlide, slideContainer.newSecondSlide]
	}
	let secondRoute = new Map()
	// 取出二级路由
	firstRoute.forEach(item => {
		const key = item.key.replace('/', '')
		if (item.id === 61)
			item.child = [{ title: '首页', path: '/home/home', key: '/home/home' }]
		if (item.id === 3)
			item.child = [
				{
					title: '统计',
					path: '/statistics/statistics',
					key: '/statistics/statistics',
				},
			]
		if (item.id === 4)
			item.child = [
				{
					title: '编研',
					path: '/editingResearch/ePhotoAlbum',
					key: '/editingResearch/ePhotoAlbum',
				},
			]
		if (item.id === 58)
			item.child = [
				{
					title: '资料',
					path: '/collectedFiles/collectedFiles',
					key: '/collectedFiles/collectedFiles',
				},
			]
		secondRoute.set(key, item.child)
	})
	slideContainer = {
		newFirstSlide: firstRoute,
		newSecondSlide: secondRoute,
	}
	return [firstRoute, secondRoute]
}

// 放在全局，存侧边栏的对象
export let slideContainer = {
	newFirstSlide: null,
	newSecondSlide: null,
}
// 暴露出去的方法和对象
export let reflashMenu = null
export let exportWorkAreaMenuConfig = null
export let setSecRouteStyle = null // 设置二级路由侧边栏的宽度
// 存放从后台拿到的收藏
let userWorkStation = null
let firstRender = true
// 二级路由的ref

/**
 * 把menucConfig转成 一维数组， 用数组套对象，title是中文，key是路由
 * @param {*} menuConfig
 */
function turnToRouterList(menuConfig, children = []) {
	for (let item of menuConfig) {
		let urlSplitArr = item.key.split('/')
		if (urlSplitArr[2] === 'workStation' && urlSplitArr.length > 3) {
			children.push({
				title: '收藏 - ' + item.title,
				key: item.key,
			})
		} else {
			children.push({
				title: item.title,
				key: item.key,
			})
		}
		item.child && item.child.length && turnToRouterList(item.child, children)
	}
	return children
}

/**
 * 把拿到的路由，和转换后的侧边栏路由列表匹配，找出来，返回对象
 *
 * @param {*} data
 * @param {*} [routesList=turnToRouterList(menuConfig)]
 */
function findRouterData(data, routesList) {
	data = data.slice(0, 1) === '/' ? data : '/' + data
	let newData = routesList.filter(item => {
		return item.key === data
	})[0]
	return newData
}

const mapStateToProps = state => {
	return {
		route: state.route.item,
		routes: state.route.items,
		redDots: state.redDots,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getWorkStation: status => dispatch(getWorkStaionAction(status)),
		SetRouter: data => dispatch(SetRouterAction(data)),
		updateSelectedKey: data => dispatch(updateselectedKeysAction(data)),
		updateOpenKeys: data => dispatch(updateOpenKeysAction(data)),
		// 添加到Redux上的route.items
		pushRouters: (data, RoutesList, directRoute) => {
			if (directRoute) {
				directRoute && dispatch(pushRoutersAction(directRoute))
				return
			}
			// 返回的routeData是一个对象，{title: 'chinese', key: 'path'} title是中文，key是路径
			let routerData = findRouterData(data, RoutesList)
			return routerData && dispatch(pushRoutersAction(routerData))
		},
	}
}

// 菜单的一级节点
@withRouter
@connect(mapStateToProps, mapDispatchToProps, null)
class SliderBar extends React.Component {
	state = {
		siderWidth: '230px', // 初始值
		// 红点状态
		countStatus: false,
		modalConfig: {
			title: '添加收藏',
			visible: false,
			targetPath: null,
			id: null,
			status: null,
		},
	}
	rootSubmenuKeys = [] // 按钮的一级submenu节点

	componentDidUpdate(preProps) {
		const nowPathname = this.props.location.pathname
		const prePathname = preProps.location.pathname
		const nowPath = nowPathname.split('/')[2]
		const prePath = prePathname.split('/')[2]
		const nowMenuConfig = slideContainer.newSecondSlide?.get(nowPath)
		// 与上次不同的话则进行判断
		if (nowPath !== prePath) {
			// 如果一级路由改变了，则换成对应的rootSubmenuKeys,menuConfig,RoutesList
			nowMenuConfig && this.didMountCallBack(nowMenuConfig)
			if (nowPath?.includes('workarea')) exportWorkAreaMenuConfig = nowMenuConfig
		} else {
			// 如果是第一次渲染
			if (firstRender) {
				firstRender = false
				nowMenuConfig && this.reflashMenu(nowMenuConfig)
			}
		}
		if (nowPathname !== prePathname) {
			this.autoOpenCurrentKey()
		}
	}

	// componentDidMount的回调
	didMountCallBack(userMenuConfig) {
		// 将第一层submenu按钮的key找出
		userMenuConfig.forEach(item => {
			item.child && this.rootSubmenuKeys.push(item.key)
		})
		this.reflashMenu(userMenuConfig)
	}

	componentWillMount() {
		this.setStyle()
		setSecRouteStyle = this.setStyle // 把设置样式的方法暴露出去
		// 权限判断
		if (redDotsUrl.getAllRedDots) {
			updataRedDots()
		}
	}

	// 设置第二级路由侧边栏的宽度
	setStyle = width => {
		// 从history拿的当前网页路由
		const route = history.location.pathname
		if (
			!width &&
			(route.includes('/archived/archived') ||
				route.includes('/statistics/statistics') ||
				route.includes('/editingResearch/ePhotoAlbum') ||
				route.includes('/collectedFiles') ||
				route.includes('/collectedFileDetails') ||
				route.includes('/mine/mine'))
		) {
			width = '60px'
		} else if (!width && route.includes('/workStation')) {
			width = '330px'
		}
		this.setState({ siderWidth: width || '230px' }, () => {
			const { siderWidth } = this.state
			const property = {
				'--home-page-sider-bar-width': siderWidth,
			}
			const homePageSiderElement = document.querySelector(`.home-page-sider-bar`)
			for (let i in property) {
				homePageSiderElement.style.setProperty(i, property[i])
			}
		})
	}

	// 获取收藏的数据
	fetchWorkbenchData = async status => {
		const { getWorkStation } = this.props
		let data = await getWorkStation(status)
		if (status === 0) {
			//判断是否隐藏公有收藏夹
			let data1 = []
			await data.forEach(element => {
				const newstatus = element.status
				if (newstatus === status) {
					data1.push(element)
				}
			})
			let workStationConfig = this.deepTraverse(data1)
			userWorkStation = workStationConfig
			return userWorkStation
		} else {
			let workStationConfig = this.deepTraverse(data)
			userWorkStation = workStationConfig
			return userWorkStation
		}
	}

	// 拿到收藏的数据，放进传进来的侧边栏
	setIntoMenuConfig = async (menuConfig, status = 1) => {
		let copyMenuConfig = cloneDeep(menuConfig).map(item => {
			if (item.key === '/workarea/workStation') {
				// 判断是【管理】下的【收藏】再去请求拿到收藏的数据，这样点击【新建】就不会去无用地请求
				;(async function (fetchWorkbenchData) {
					let workStationConfig = await fetchWorkbenchData(status)
					item.child = cloneDeep(workStationConfig)
				})(this.fetchWorkbenchData)
			}
			return item
		})
		return copyMenuConfig
	}

	/**
	 * 用于刷新菜单
	 * @param {*} menuConfig
	 * @param {boolean} [optionCheck=false] 删除和增加工作区的action，传参为true，自动刷新工作区
	 * @param {Number} [status=1] 判断有没有显示公共收藏夹
	 */
	reflashMenu = async (menuConfig, optionCheck = false, status = 1) => {
		const { pushRouters, route, SetRouter } = this.props
		let newMenuConfig = null
		if (!menuConfig) {
			const { newSecondSlide } = slideContainer
			const pathName = history.location.pathname.split('/').slice(2, 3)[0]
			menuConfig = newSecondSlide?.get(pathName)
		}
		let routesList = turnToRouterList(menuConfig) // 展成一维用以匹配
		let checkWithOutChildren = menuConfig.find(item => item.id === 13 && !item.child)
		if (!!checkWithOutChildren === false || optionCheck) {
			newMenuConfig = await this.setIntoMenuConfig(menuConfig, status)
			let firstRoute = newMenuConfig[0] && newMenuConfig[0].path.split('/')[1]
			if (firstRoute === 'workarea') {
				// 设置后才有侧边栏的收藏
				slideContainer.newSecondSlide.delete(firstRoute)
				slideContainer.newSecondSlide.set(firstRoute, newMenuConfig)
				routesList = turnToRouterList(newMenuConfig) // 展成一维用以匹配
			}
		}
		this.setState({
			menuConfig: newMenuConfig,
			RoutesList: routesList,
		})
		SetRouter(route)
		pushRouters(route, routesList)
		this.setStyle()
	}

	/**
	 * 深度遍历收藏的数据，创建收藏menu数据
	 * @param {Array} data 树型数据
	 */
	deepTraverse = data => {
		let copyData = cloneDeep(data)
		let flatData = cfUtils.flatTree(copyData, item => {
			// 从postion中获取路由路径
			let path = item.position
				? `/workarea${item.position}`
				: `/workarea/workStation/${item.id}`
			item.key = path
			item.path = path
			item.title = item.workbench
			return item
		})
		return cfUtils.listToTree(flatData)
	}

	/**
	 * 编辑收藏
	 * @param {*} e 事件
	 * @param {*} id 节点id
	 * @param {*} status 状态
	 */
	editWorkStation = (e, id, status) => {
		e.stopPropagation()
		this.setState({
			modalConfig: Object.assign(this.state.modalConfig, {
				visible: true,
				title: '更改收藏夹状态',
				id,
				status,
			}),
		})
	}

	/**
	 * 点击添加按钮时触发
	 * @param {Object[]} e 事件对象
	 * @param {*} position 当前节点的位置
	 */
	addWorkStation = (e, position) => {
		e.stopPropagation()
		this.setState({
			modalConfig: Object.assign(this.state.modalConfig, {
				visible: true,
				title: '添加收藏夹',
				targetPath: position,
			}),
		})
	}

	/**
	 * 删除收藏
	 * @param {*} e
	 * @param {*} position 当前节点的位置
	 */
	deleteWorkStation = (e, position) => {
		e.stopPropagation()
		this.setState({
			modalConfig: Object.assign(this.state.modalConfig, {
				visible: true,
				title: '提示',
				targetPath: position,
			}),
		})
	}

	/**
	 * 收藏的弹框的取消按钮
	 */
	onCancel = () => {
		this.setState({
			modalConfig: Object.assign(this.state.modalConfig, { visible: false }),
		})
	}

	/**
	 * 切换有没有显示公共收藏夹
	 * @param {*} e
	 */
	switchShowPublic = e => {
		e.stopPropagation()
		const { isCurrentShowPublic } = this.state
		this.reflashMenu(null, true, isCurrentShowPublic ? 0 : 1)
		this.setState({ isCurrentShowPublic: !isCurrentShowPublic })
	}

	/**
	 * 自动展开当前节点的所有父节点（用于刷新的情况）
	 * 写成非箭头函数，需要改变函数的this指向
	 * @param {boolean} firstRender 判断是否为初次渲染
	 */
	autoOpenCurrentKey(firstRender = false) {
		let {
			SetRouter,
			RoutesList,
			pushRouters,
			pathName,
			updateOpenKeys,
			updateSelectedKey,
		} = this.props
		if (!!RoutesList === false) {
			RoutesList = this.state.RoutesList
		}
		if (!!pathName === false) {
			pathName = history.location.pathname + history.location.search
		}
		let strArr = pathName.split('/').slice(2)
		let openKeys = []
		// 组合出当前所在节点的所有父节点
		let strPath = strArr.reduce((pre, cur) => {
			openKeys.push(pre + '/' + cur)
			return pre + '/' + cur
		}, '')
		// 设置需要自动展开的openKeys
		updateOpenKeys(openKeys)
		updateSelectedKey([strPath])
		// pushRouters之后有Tabs栏
		if (firstRender) {
			pushRouters(strPath, RoutesList)
		}
		// 设置Router之后，激活对应Tabs栏
		SetRouter(strPath || homePath.url)
	}

	render() {
		const { pushRouters, SetRouter, updateOpenKeys, updateSelectedKey } = this.props
		const { RoutesList, modalConfig, menuConfig, isCurrentShowPublic } = this.state
		let [newFirstSlide] = newSlideBar()
		const pathName = history.location.pathname + history.location.search
		const firstPathName = pathName.split('/').slice(2, 3)[0]
		reflashMenu = this.reflashMenu // 把刷新sliderBar方法暴露出去
		return (
			<Fragment>
				{
					<FirstRouteMenu
						newFirstSlide={newFirstSlide}
						pushRouters={pushRouters}
						setStyle={this.setStyle}
						SetRouter={SetRouter}
					/>
				}
				{
					// 保证有RouteList再渲染二级路由
					RoutesList && (
						<SecondRouteMenu
							secondSlide={menuConfig}
							pathName={pathName}
							firstPathName={firstPathName}
							pushRouters={pushRouters}
							RoutesList={RoutesList}
							SetRouter={SetRouter}
							setStyle={this.setStyle}
							autoOpenCurrentKey={this.autoOpenCurrentKey}
							updateOpenKeys={updateOpenKeys}
							updateSelectedKey={updateSelectedKey}
							modalHandler={{
								editWorkStation: this.editWorkStation,
								addWorkStation: this.addWorkStation,
								deleteWorkStation: this.deleteWorkStation,
								switchShowPublic: this.switchShowPublic,
							}}
							isCurrentShowPublic={isCurrentShowPublic}
						/>
					)
				}
				{
					<ActionModal
						visible={modalConfig.visible}
						title={modalConfig.title}
						targetPath={modalConfig.targetPath}
						id={modalConfig.id}
						status={modalConfig.status}
						onCancel={this.onCancel}
						reflashMenu={this.reflashMenu}
					/>
				}
			</Fragment>
		)
	}
}

export default SliderBar
