import { UPDATA_REDDOTS } from '@/Redux/ActionTypes/RedDots'
import InitialState from '@/Redux/InitialState'

const createRedNumData = data => {
	// 数字代表侧边栏各个小格的id
	const res = {
		// workArea
		17: data.editorCount,
		18: data.preArchiveCount,
		20: data.expiredCount,
		22: data.recycleCount,
		67: data.underReview,
		// 我的申请
		33: data.applyCount,
		34: data.incompleteFixApplyCount,
		// 我的审批
		35: data.reviewCount,
		36: data.incompleteFixVerifyCount,
		// 档案鉴定
		16: data.incompleteVerifyCount,
	}
	return res
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = InitialState.redDots, action) => {
	switch (action.type) {
		case UPDATA_REDDOTS:
			return createRedNumData(action.data)
		default:
			return state
	}
}
