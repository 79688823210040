import _fetch from '@/Utils/Fetch'
import { userManagerUrl, serialNumberUrl } from '@/Utils/Urls'

export const updateAutoRecognition = auto =>
	new Promise((resolve, reject) => {
		_fetch({
			url: userManagerUrl.updateAutoRecognition,
			type: 'get',
			data: {
				auto,
			},
			isJson: true,
		})
			.then(res => {
				if (res.data.code === 200) {
					resolve(res)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})

export const getAllSystemConfig = () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: serialNumberUrl.getAllSystemConfig,
			type: 'get',
		})
			.then(res => {
				if (res.data.code === 200) {
					resolve(res.data.data)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})
