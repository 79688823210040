import React, { PureComponent } from 'react'
import { TreeSelect } from 'antd'
import _fetch from '@/Utils/Fetch.js'
import { injectUnmount } from '@/Utils'

/**
 * @description:
 * @param {type}
 * @return:
 */

export const proTreeSelect = config => {
	class InnerClass extends PureComponent {
		triggerChange = currency => {
			const { onChange } = this.props
			if (onChange) {
				onChange(currency)
			}
		}
		render() {
			const { value } = this.props
			const { ifMultiple } = config
			return (
				<TreeSelect
					style={{ width: '100%' }}
					value={value}
					allowClear
					dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
					dropdownMatchSelectWidth={false}
					treeData={config.treeData}
					placeholder={config.placeholder || ''} // 下拉框提示文字
					treeDefaultExpandAll
					onChange={this.triggerChange}
					multiple={ifMultiple}
					virtual={false}
				/>
			)
		}
	}
	return <InnerClass />
}
/**
 * @description:
 * @param {type}
 * @return:
 */
export const proAsyncTreeSelect = config => {
	@injectUnmount
	class InnerClass extends PureComponent {
		constructor(props) {
			super(props)
			this.state = {
				// value: undefined,
				treeData: [],
			}
		}
		componentDidMount() {
			const {
				ajaxConfig, // 异步请求配置对象
				formatDataFunc, // 将请求到的数据转换为 treeData 格式的函数
			} = config
			// _fetch()
			_fetch(ajaxConfig).then(async res => {
				if (res && res.data.code === 200) {
					let data = res.data.data
					// //console.log(data)
					// 将请求数据转换为 { label, value }[]，如果有配置formatDataFunc则调用它，如果无该配置则使用默认从后台拿到的数据
					// 如：treeData: [
					//   {
					//     title: 'Node1',
					//     value: '0-0',
					//     children: [
					//       {
					//         title: 'Child Node1',
					//         value: '0-0-1',
					//       },
					//       {
					//         title: 'Child Node2',
					//         value: '0-0-2',
					//       },
					//     ],
					//   },
					//   {
					//     title: 'Node2',
					//     value: '0-1',
					//   },
					// ]
					let treeData = formatDataFunc ? await formatDataFunc(data) : data
					this.setState({
						treeData,
					})
				}
			})
		}
		triggerChange = currency => {
			const { onChange } = this.props
			if (onChange) {
				onChange(currency)
			}
		}
		render() {
			const { treeData } = this.state
			const { value } = this.props
			const { ifMultiple } = config
			return (
				<TreeSelect
					style={{ width: '100%' }}
					allowClear
					value={value}
					dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
					dropdownMatchSelectWidth={false}
					placeholder={config.placeholder || ''} // 下拉框提示文字
					onChange={this.triggerChange}
					treeData={treeData}
					treeDefaultExpandAll
					multiple={ifMultiple}
					className={`${config.propsClass} search-select`} // 配置的class类名
					virtual={false}
				/>
			)
		}
	}
	return <InnerClass />
}
