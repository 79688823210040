import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button, message, Modal, Space } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { collectedFileColumns } from '@/Public/PubTable/pageColumns'
import { collectedFileUrl, departmentUrl } from '@/Utils/Urls'
import { treeUtil } from '@/Utils'
import EventBus from '@/Utils/pubsub'
import CollectedFileTheme from './CollectedFileTheme'
import {
	deleteCollectedFileThunk,
	exportCollectedFileThunk,
	clearSelectedDataThunk,
	getMyThemeThunk,
} from './ActionCreators'
import { pub_isLoading } from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator.js'
import ButtonList from '@/Public/ButtonList'
import { history } from '@/index'

import './index.less'

const { confirm } = Modal

const markName = 'CollectedFiles' // redux的标识
const mapStateToProps = state => ({
	selectedFiles: state.pubTable.pub_rowSelection.CollectedFiles,
	selectedTheme: state.pubTable.pub_rowSelection.ThemeManage,
})
const mapDispatchToProps = dispatch => ({
	deleteCollectedFile: (...rest) => dispatch(deleteCollectedFileThunk(...rest)),
	exportCollectedFile: (...rest) => dispatch(exportCollectedFileThunk(...rest)),
	clearSelectedData: (...rest) => dispatch(clearSelectedDataThunk(...rest)),
	getMyTheme: (...rest) => dispatch(getMyThemeThunk(...rest)),
	pub_changeisLoading: (...rest) => dispatch(pub_isLoading(...rest)),
})

@WrappedTable.create(markName)
@connect(mapStateToProps, mapDispatchToProps)
class CollectedFiles extends Component {
	state = {
		selectedNodeMsg: {}, // 选中的树节点的信息
		themeId:
			this.props.selectedTheme && this.props.selectedTheme.toString() !== ''
				? this.props.selectedTheme[0].themeId
				: null,
	}

	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{
				type: 'Extra',
				name: 'buttonList',
				render: saerchFormRef => {
					return (
						<Space>
							<Button
								title='添加'
								onClick={this.addCollectFile}
								className='btn-success iconfont-buttons'>
								&#xe634;{' '}
							</Button>
							<Button
								title='删除'
								onClick={this.showConfirm}
								className='btn-danger iconfont-buttons'>
								&#xe816; 删除
							</Button>
							<Button
								title='导出'
								type='primary'
								onClick={this.exportFile}
								className='export-button  iconfont-buttons'>
								&#xe8c6; 导出
							</Button>
							<Button
								title='主题资料'
								type='primary'
								onClick={() => {
									this.exportFile(this.state.themeId)
								}}
								className='export-button iconfont-buttons'>
								&#xe65e; 主题资料
							</Button>
							<ButtonList refresh={this.props.pub_getAllData} />
						</Space>
					)
				},
			},
			{
				type: 'ParticiplesSelect',
				name: 'topics',
				label: '题名',
				inputWidth: '135px',
			},
			{
				type: 'ParticiplesSelect',
				name: 'names',
				label: '人名',
				inputWidth: '135px',
			},
			{
				type: 'ParticiplesSelect',
				name: 'places',
				label: '地名',
				inputWidth: '135px',
			},
			{
				type: 'AsyncTreeSelect',
				name: 'departmentId',
				label: '部门',
				minWidth: '160px',
				ajaxConfig: {
					url: departmentUrl.getDepartmentTree,
				},
				formatDataFunc: data => {
					return treeUtil.changeKeys([data], {
						departmentName: 'title',
						id: 'value',
					})
				},
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'post',
			url: collectedFileUrl.searchCollectedFile,
			ContentType: 'application/json',
		},
	}

	// 重新获取树数据和表格数据
	reloadAllData = () => {
		const { pub_getAllData } = this.props
		pub_getAllData() // 重新获取表格数据
	}

	//添加资料
	addCollectFile = () => {
		if (!this.state.themeId) {
			message.error('不能在全部下新增资料！请在侧边栏选择一个主题')
		} else {
			history.push(`/collectFiles?${this.state.themeId}?1`)
		}
	}

	// 删除资料
	deleteCollectedFiles = ids => {
		const { deleteCollectedFile, getMyTheme, pub_changeisLoading } = this.props
		pub_changeisLoading(true, markName) //更改表格loading状态，解决页面无响应
		deleteCollectedFile(ids)
			.then(res => {
				message.success('删除成功')
				this.reloadAllData()
				//更新左边部门树组件主题下资料数量
				getMyTheme()
					.then(theme => {
						this.CollectedFileTheme.setState({
							theme,
						})
					})
					.catch(err => {
						message.error('资料数量更新失败')
					})
			})
			.catch(err => {
				message.error('删除失败')
				pub_changeisLoading(false, markName)
			})
	}

	// 删除资料模态框
	showConfirm = () => {
		const { selectedFiles } = this.props
		const ids = selectedFiles?.map(item => item.id)

		if (ids.length === 0) {
			message.info('请至少选择一项')
			return
		}

		confirm({
			icon: <ExclamationCircleOutlined />,
			content: '确定删除选中资料吗',
			onOk: () => this.deleteCollectedFiles(ids),
		})
	}

	//导出资料文件
	exportFile = themeId => {
		const { selectedFiles, clearSelectedData, exportCollectedFile } = this.props

		//从本地拿到主题题名
		let themeTopic = undefined
		JSON.parse(localStorage.getItem('MyTheme')).forEach((item, index) => {
			item.themeId === themeId && (themeTopic = item.topic)
		})

		//如果有传参过来,就是导出主题资料
		if (typeof themeId === 'number' && themeTopic) {
			confirm({
				icon: <ExclamationCircleOutlined />,
				content: `确定导出《${themeTopic}》主题所有资料吗`,
				onOk: () => {
					message.info({
						key: themeId,
						content: '资料正在导出，请稍等片刻！',
						duration: 0,
					})
					exportCollectedFile(null, themeId, themeTopic)
						.then(() => {
							message.success({
								key: themeId,
								content: '资料成功导出',
								duration: 3,
							})
							//清空redux选中项
							clearSelectedData(markName)
						})
						.catch(() => {
							message.error({
								key: themeId,
								content: '资料导出失败',
								duration: 3,
							})
						})
				},
			})
		} else {
			const fileIds = selectedFiles.map(item => {
				return item.id
			})

			if (fileIds.length === 0) {
				message.info('请至少选择一项')
				return
			}

			confirm({
				icon: <ExclamationCircleOutlined />,
				content: '确定导出选中资料吗',
				onOk: () => {
					message.info({
						key: 'mark',
						content: '资料正在导出，请稍等片刻！',
						duration: 0,
					})
					exportCollectedFile(fileIds)
						.then(() => {
							message.success({
								key: 'mark',
								content: '资料成功导出',
								duration: 3,
							})
							//清空redux选中项
							clearSelectedData(markName)
						})
						.catch(() => {
							message.error({
								key: 'mark',
								content: '资料导出失败',
								duration: 3,
							})
						})
				},
			})
		}
	}

	//监听到点击后调用，拿到主题id重新渲染页面
	reSearchCollectFile = e => {
		this.setState(
			{
				themeId: Number(e.detail.themeId),
			},
			() => {
				this.reloadAllData()
			}
		)
	}

	componentDidMount() {
		//监听点击主题侧边栏
		EventBus.addEventListener('clickMenuItem', this.reSearchCollectFile)
	}

	componentWillUnmount() {
		//移除监听
		EventBus.removeEventListener('clickMenuItem', this.reSearchCollectFile)
	}

	render() {
		return (
			<Fragment>
				<div className='collected-management-wrapper'>
					{/* 左边部门树组件 */}
					<div id='depart-left'>
						<CollectedFileTheme
							onRef={ref => {
								this.CollectedFileTheme = ref
							}}
						/>
					</div>
					{/* 右边表格部分 */}
					<div id='depart-right'>
						<WrappedTable>
							<SearchForm
								name='collected-search-form'
								formItems={this.searchFormConfig}
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData}
								markName={markName}
							/>
							<BaseTable
								tableConfig={collectedFileColumns}
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData}
								markName={markName}
								stylePointer={true}
								onRowEvent={true}
								isCollectedFile={true}
							/>
						</WrappedTable>
					</div>
				</div>
			</Fragment>
		)
	}

	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 */
	changeSearchLimit = data => {
		const { themeId } = this.state
		const categoryId = this.state.selectedNodeMsg.id
		data.categoryId = ''
		if (categoryId !== -1) {
			data.categoryId = this.state.selectedNodeMsg.id
		}

		themeId && (data.themeIds = this.state.themeId)
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = res => {
		res.records.forEach(record => (record.type = 3))
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}
}

export default CollectedFiles
