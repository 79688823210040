import React, { Component } from 'react'
import { Button } from 'antd'
import CompositeSearchModal from './CompositeSearchModal'
class CompositeSearchButton extends Component {
	state = {
		current: 'mail',
		visible: false,
	}

	render() {
		/*
      传入该组件的this.props的可配置项——需要可再加，在CompositeSearchModal中引入
      1. modalBtnTitle(string)：提交按钮的名字
      2. handleCompositeRecords(function)：点击提交按钮后的执行的函数，有一个参数records(包含着选中文件的信息)
      3. enumStatus(new Enum())：可选择的状态(著录、已归档、预归档、过期、销毁)
      4. title(string)：模态框最上面的标题
      5. btnTitle(string): 引用该组件<CompositeSearchButton />的按钮的名字
      6. rowSelection(function): 传入设置<BaseTable />的rowSelection，此参数传函数就行
      7. typeDisabled(boolean)：查询框<SearchForm />的类型(type)是否禁用
      8. initialType(number): 查询框的类型(type)的默认值
      9. checkRowSelection(function): 检查选中的是否为空，应返回一个Promise
      10. noOperationButton(boolean): 案卷和文件的操作，是否要加
      11. statusDisabled(boolean): 查询框<SearchForm />状态(status)是否禁用
      12. cancelCallback(boolean): 关闭模态框后的回调
    */
		return (
			<>
				<Button
					title={this.props.hoverTitle || '添加文件'}
					type='primary'
					onClick={this.handleSearch}
					className='btn-success  iconfont-buttons'>
					&#xe634;
				</Button>
				<CompositeSearchModal
					visible={this.state.visible}
					onCancel={() => {
						this.props.cancelCallback && this.props.cancelCallback()
						this.setState({ visible: false })
					}}
					{...this.props}
				/>
			</>
		)
	}

	handleSearch = () => {
		this.setState({
			visible: true,
		})
	}
}

export default CompositeSearchButton
