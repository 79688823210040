import _fetch from '@/Utils/Fetch'
import { ePhotoAlbum } from '@/Utils/Urls'
import { Modal, message } from 'antd'
// 消息提示
export const tips = {
	NO_PRE_RECORD: '没有上一项了',
	NO_NEXT_RECORD: '没有下一项了',
	CHOOSE_AT_LEAST_ONE: '请选择一项后再进行操作',
	RECALL_SUCCESS: '撤回成功',
	DELETE_SUCCESS: '删除成功',
	SUBMIT_SUCCESS: '提交成功',
	UPDATE_SUCCESS: '保存成功',
	ONLY_APPLICATION_STATUS: '只能对“非申请状态”下的借阅申请进行撤回',
}

/**
 * 删除分类
 * @param {Number} id 申请id
 */
export const deleteTypeThunk = record => () =>
	new Promise(resolve => {
		const { topic, id } = record
		Modal.confirm({
			maskClosable: true,
			title: '删除分类',
			content: `您正在对当前"${topic}"主题进行删除操作，是否继续？`,
			okText: '确认',
			cancelText: '取消',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: ePhotoAlbum.deleteTheme,
					type: 'get',
					data: { id },
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.DELETE_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 更新分类
 * @param {object} data 申请数据
 */
export const updateTypeThunk = data => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '编辑主题',
			content: '确定保存？',
			okType: 'danger',
			onOk: () => {
				_fetch({
					url: ePhotoAlbum.updateTheme,
					type: 'post',
					isJson: true,
					data,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.UPDATE_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

/*
 *新增案卷
 *
 */
export const addDossierTypeThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: _fetch({
				url: ePhotoAlbum.addTheme,
				type: 'post',
				data: data,
				isJson: true,
			}).then(res => {
				if (res && res.data.code === 200) {
					message.success('添加成功！')
					resolve(res.data)
				}
			}).addDossierType,
			type: 'post',
			data: data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('添加成功！')
				resolve(res.data)
			}
		})
	})

/**
 * 获取分类树
 * @param {Number} id 分类名id（不填查询所有的分类）
 */
export const getDossierTypeTreeThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: ePhotoAlbum.getThemeTree,
			data: { id },
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

// 获取类别号
export const getDossierTypeThunk = () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: ePhotoAlbum.getThemeTree,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

/**
 * 查找编研主题
 * @param {object} data
 */
export const searchThemeThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: ePhotoAlbum.searchTheme,
			isJson: true,
			data,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			}
		})
	})
