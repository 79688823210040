import React, { PureComponent } from 'react'
import { Button, Space, message, Modal } from 'antd'
import _ from 'lodash'
import _fetch from '@/Utils/Fetch'
import { connect } from 'react-redux'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import SearchForm from '@/Public/PubTable/searchForm'
import { myArchiveUrl } from '@/Utils/Urls'
import {
	pub_getAllTableData,
	pub_isLoading,
	pub_searchCondition,
	pub_clearSelectedData,
	is_my_file_detail,
} from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator.js'
import { setMyFiles } from '@/Redux/ActionTypes/MyFiles/ActionCreators'
import {
	routeChangeAction,
	pushRoutersAction,
	getDetailThunk,
	addPubFileAction,
} from '@/Public/PubTable/baseTable/ActionCreator'
import { myFileColumns } from '@/Public/PubTable/pageColumns'
import { getUserInfoThunk } from './ActionCreators'
import './index.less'

const mapStateToProps = (state, ownProps) => {
	return {
		pub_isLoading: state.pubTable.pub_isLoading, // 是否拿到表格数据的标识
		fixedConf: state.pubTable.fixedConf, // 分页条件
		allSearchCondition: state.pubTable.allSearchCondition, // 所有搜索的条件
		pub_allTableData: state.pubTable.pub_allTableData, // 获取表格所有数据
		selectedFiles: state.pubTable.pub_rowSelection,
		route: state.route.item,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		getAllTableData(...rest) {
			return dispatch(pub_getAllTableData(...rest)) // 把获取到的表格所有数据发送到store
		},
		pub_changeisLoading(...rest) {
			return dispatch(pub_isLoading(...rest)) // 把标志是否获取表格数据的isLoading标识修改
		},
		pub_sendSearchCondition(...rest) {
			return dispatch(pub_searchCondition(...rest)) // 把搜索条件都发送到store
		},
		pub_clearSelectedData(...rest) {
			return dispatch(pub_clearSelectedData(...rest))
		},
		addPubFileData(data) {
			return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey.key))
			} else {
				dispatch(routeChangeAction(activeKey.key))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
		},
		pushRouters(link) {
			return dispatch(pushRoutersAction(link))
		},
		isMyFileDetail: (...rest) => dispatch(is_my_file_detail(...rest)),
		getUserInfo: (...rest) => dispatch(getUserInfoThunk(...rest)), // 获取已登录用户信息,
		setMyFiles: (...rest) => dispatch(setMyFiles(...rest)),
	}
}

@connect(mapStateToProps, mapDispatchToProps)
class MyTables extends PureComponent {
	state = {
		selectedNodeMsg: {}, // 选中的树节点的信息
	}

	searchFormConfig = {
		formItems: [
			{
				type: 'Extra',
				name: 'buttonList',
				render: saerchFormRef => {
					return (
						<Space>
							<Button
								onClick={() => {
									this.apply()
								}}
								type='primary'>
								申请
							</Button>
						</Space>
					)
				},
			},
			{
				type: 'Input',
				width: '0px',
				name: 'no-show',
				style: {
					display: 'none',
				},
			},
		],
		ajaxConfig: {
			url: myArchiveUrl.getMyArchiveDetail,
			ajaxType: 'get',
			data: {
				labelId: this.props.labelId,
			},
		},
	}

	changeTableData(res) {
		const data = res.records
		data.forEach(item => {
			item.type = 1
		})
		return {
			data,
			pageSize: res.size,
			current: res.current,
			total: res.total,
			labelId: res.countId,
		}
	}

	changeSearchLimit = data => {
		const { selectedNodeMsg } = this.state
		// 如果点击根节点或初始状态则获取全部（不传部门id）
		if (JSON.stringify(selectedNodeMsg) !== '{}' && selectedNodeMsg['id'] !== -1) {
			data['id'] = selectedNodeMsg['id']
		}
		return data
	}

	operationButton(record) {
		if (record.linked === 0 || record.linked === 2) {
			return (
				<Button
					onClick={() => {
						this.showDetail(record)
					}}
					className='no-linked'>
					查看（?）
				</Button>
			)
		} else {
			return (
				<Button
					onClick={() => {
						this.showDetail(record)
					}}>
					查看
				</Button>
			)
		}
	}
	//刷新表格
	refreshTable = searchValue => {
		const { pub_sendSearchCondition, pub_clearSelectedData } = this.props
		const { changeSearchLimit } = this
		let searchData = {}
		searchData = this.searchAddPagination(searchData, searchValue) // 增加分页条件
		searchData = changeSearchLimit(searchData) // 格式化搜索数据格式
		let copySearchData = _.cloneDeep(searchData)
		copySearchData.ajaxConfig = {
			url: myArchiveUrl.getMyArchiveDetail,
			ajaxType: 'get',
			data: {
				labelId: this.props.labelId,
			},
		} // 把ajaxConfig也一起发过去
		pub_sendSearchCondition(copySearchData, this.props.markName) // 把搜索条件发送到store
		// 搜索后，清空当前页面的选中数据
		pub_clearSelectedData(this.props.markName)
		this.returnAllTableData(searchData) // 将格式化后的搜索数据传给表格
		return searchData // 将格式化后的数据返回
	}

	searchFunc = (ajaxConfig, data) => {
		// 请求后台表格数据，发送请求时应该先请求第一页数据
		let {
			ajaxType,
			data: searchData,
			url,
			ContentType = 'application/json',
		} = ajaxConfig
		//searchAddPagination已经配置好默认请求第一页了
		let option = {
			type: ajaxType,
			url,
			data: Object.assign({}, searchData, data),
			headers: {
				'Content-Type': ContentType, // 默认是'application/json'
			},
		}
		sessionStorage.setItem('pubSearchConfig', JSON.stringify(data))
		return _fetch(option)
	}

	// 跳转到详情页
	showDetail(record) {
		const detailsNeeded = {
			// lastHref, 考虑到一个页面可能会有多个表格，lastHref不能判断是哪个表格，因此用markName
			type: record.type,
			markName: this.props.markName,
		}
		sessionStorage.setItem('detailsNeeded', JSON.stringify(detailsNeeded))
		const { isCollectedFile = false } = this.props
		record.isCollectedFile = isCollectedFile
		const link = {
			title: isCollectedFile ? '资料详情' : '详情',
			key: `/workarea/commonComponents/${isCollectedFile ? 'collectedFileDetails' : 'details'}`,
		}
		this.props.getDetail(record).then(data => {
			// 请求详情数据
			data['route'] = this.props.route // 由此可以知道从哪打开详情，并判断是否添加按钮
			// this.props.isMyFileDetail(true)
			this.props.addPubFileData(data) // 文件详情传到redux
			this.props.onChange(link, history) // 修改路由
			this.props.pushRouters(link) //添加分页栏
		})
	}

	returnAllTableData = async searchData => {
		const { getAllTableData, pub_changeisLoading } = this.props
		pub_changeisLoading(true, this.props.markName) //先置为true
		try {
			const ajaxConfig = {
				url: myArchiveUrl.getMyArchiveDetail,
				ajaxType: 'get',
				data: {
					labelId: this.props.labelId,
				},
			}
			let result = await this.searchFunc(ajaxConfig, searchData) // 如果是搜索的话，就传搜索条件data

			if (result.data.success === false) {
				throw new Error()
			}

			let tableData = {}
			if (result && result.data.data) {
				tableData = this.changeTableData(result.data.data) // 调用父组件传来的changeTableData，修改获得的表格的数据成符和格式的数据
				let Data = _.cloneDeep(tableData) // 深拷贝一份数据
				Data.data.forEach(item => {
					// 让每一个表格数据都获得key
					item.key = item.id
				})
				Data.pageData = {
					// 配置表格的页码器
					pageSize: Data.size || 10,
					current: Data.page || 1,
					total: Data.total,
				}
				pub_changeisLoading(false, this.props.markName) // 获取到数据就置为false
				getAllTableData(Data, this.props.markName) // 把表格数据发送到store
			}
		} catch (err) {
			pub_changeisLoading(false, this.props.markName) // 获取到数据就置为false
			message.error('表格请求错误')
		}
	}

	searchAddPagination = (values, searchValue) => {
		// 增加分页的条件
		let search = _.cloneDeep(values)
		// 有值传入时即为刷新页面，没有则重新渲染
		if (searchValue?.current) {
			search.current = searchValue.current
			search.size = searchValue.size
		} else {
			const {
				fixedConf: { size },
			} = this.props
			search.current = 1 // 现在默认每一次点击搜索都是默认搜索第一页,后台字段是current
			search.size =
				size || Number(sessionStorage.getItem('tableHeight')) > 950 ? 20 : 10
		}
		return search
	}

	// 申请
	apply() {
		const selectedFiles = this.props.selectedFiles[this.props.markName]
		if (selectedFiles.length === 0) {
			this.showModal()
		} else {
			this.props.setMyFiles(selectedFiles)
			this.props.isMyFileDetail(true)
			sessionStorage.setItem('formType', 0)
			const link = {
				title: '借阅申请',
				key: '/workarea/commonComponents/borrowDetails',
			}
			this.props.getUserInfo().then(res => {
				sessionStorage.setItem('userInfo', JSON.stringify(res))
				this.props.onChange(link, history) // 修改路由
				this.props.pushRouters(link) // 添加分页栏
			})
		}
	}

	// 弹出模态框（至少选择一项）
	showModal = () => {
		Modal.error({
			content: '请至少选择一个档案进行申请',
			maskClosable: true,
		})
	}

	render() {
		const markName = this.props.markName
		return (
			<WrappedTable>
				<SearchForm
					formItems={this.searchFormConfig}
					changeSearchLimit={this.changeSearchLimit}
					changeTableData={this.changeTableData}
					markName={markName}></SearchForm>
				<BaseTable
					markName={markName}
					tableConfig={myFileColumns} // 表格表头设置
					changeTableData={this.changeTableData} // 把拿到的表格数据改造成符合公共组件所需数据的格式
					changeSearchLimit={this.changeSearchLimit}
					stylePointer={true}
					onRowEvent={true}
					operationButton={record => this.operationButton(record)}></BaseTable>
			</WrappedTable>
		)
	}
}

export default MyTables
