/*
 * searchForm公共组件的ActionCreator
 */
import {
	PUB_GET_ROWSELECTION,
	PUB_GET_ALLTABLEDATA,
	PUB_IS_LOADING,
	PUB_CURRENT_PAGE,
	PUB_SEARCH_CONDITION,
	PUB_CLEAR_SELECTED,
	PUB_SET_SELECTED_STATUS,
	IS_MYFILEDETAIL,
} from './publicActionType'

/*
 * 功能: 把对于的搜索条件翻入store
 * markId: 这个表单组件唯一标识
 * originVal 原始的搜索条件 即在搜索栏是什么就是什么
 * options一些ajax配置
 *     type: 类型
 *     url
 *     data   有可能经过调用者处理的搜索条件   以及分页
 *     headers  请求头
 */
export const pub_rowSelectionToStore = (pub_rowSelection, markId) => {
	return {
		type: PUB_GET_ROWSELECTION,
		pub_rowSelection,
		markId,
	}
}
export const pub_getAllTableData = (pub_allTableData, markId) => ({
	type: PUB_GET_ALLTABLEDATA,
	pub_allTableData,
	markId,
})
export const pub_isLoading = (pub_isLoading, markId) => ({
	type: PUB_IS_LOADING,
	pub_isLoading,
	markId,
})
export const pub_currntPage = (pub_currntPage, pub_pageSize, markId) => ({
	type: PUB_CURRENT_PAGE,
	pub_currntPage,
	pub_pageSize,
	markId,
})
export const pub_searchCondition = (pub_searchCondition, markId) => ({
	type: PUB_SEARCH_CONDITION,
	pub_searchCondition,
	markId,
})
// 清空某个页面的选中数据
export const pub_clearSelectedData = markId => ({
	type: PUB_CLEAR_SELECTED,
	markId,
})

export const pub_setSelectedStatus = selectedStatus => ({
	type: PUB_SET_SELECTED_STATUS,
	selectedStatus,
})

export const is_my_file_detail = isMyFileDetail => {
	return {
		type: IS_MYFILEDETAIL,
		isMyFileDetail,
	}
}
