import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { departmentUrl } from '@/Utils/Urls'
import {
	deleteDepartmentThunk,
	addDepartmentThunk,
	updateDepartmentThunk,
} from './ActionCreators'
import { Space, Button } from 'antd'
import { connect } from 'react-redux'
import DepartmentModal from './DepartmentModal'
import DepartmentTree from './DepartmentTree'
import { rjUtils } from '@/Utils'
import { DepartmentManagementColumns } from '@/Public/PubTable/pageColumns'
import { wordLimit } from './ActionCreators'
import './index.less'
const markName = 'DepartmentManagement' // redux 的标识
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	deleteDepartment: (...rest) => dispatch(deleteDepartmentThunk(...rest)), // 删除部门
	addDepartment: (...rest) => dispatch(addDepartmentThunk(...rest)), // 新增部门
	updateDepartment: (...rest) => dispatch(updateDepartmentThunk(...rest)), // 更新部门信息
})
const departmentModalRef = React.createRef(null) // 新增/编辑部门模态框实例
let departmentTreeRef = null // 部门树实例
// 部门管理页面
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class DepartmentManagement extends Component {
	state = {
		selectedNodeMsg: {}, // 选中的树节点的信息
		modalTitle: '', // 模态框的标题
		modalOkText: '', // 模态框的确认按钮信息
		editNodeMsg: {}, // 当前正在编辑的部门节点信息
	}

	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{
				type: 'Input',
				name: 'departmentNumber',
				label: '部门编号',
				width: '300px',
				rules: [
					{
						type: 'string',
						pattern:
							wordLimit['d'] !== null
								? new RegExp(`^[A-Z0-9]{${wordLimit['d']}}$`)
								: null,
						message: `请输入${wordLimit['d'] !== null ? wordLimit['d'] + '位' : ''}纯数字或纯大写字母`,
					},
				],
			},
			{
				type: 'Input',
				name: 'departmentName',
				label: '部门名称',
				width: '300px',
			},
			{
				type: 'Extra',
				name: 'SystemAllButtonList',
				render: formRef => {
					return (
						<Space>
							<Button
								className={'btn-success iconfont-buttons'}
								title='新增'
								onClick={() => {
									const { selectedNodeMsg } = this.state
									this.openAddDepartmentModal(selectedNodeMsg)
								}}>
								&#xe634; 新增
							</Button>
						</Space>
					)
				},
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			url: departmentUrl.getDepartmentList,
			ajaxType: 'post',
			ContentType: 'application/json',
		},
	}

	// 选中节点触发并重新获取表格信息
	updateSelectNode = selectedNodeMsg => {
		const { pub_getAllData } = this.props
		this.setState({ selectedNodeMsg }, pub_getAllData)
	}

	// 表格的相关配置
	tableConfig = [...DepartmentManagementColumns]

	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 */
	changeSearchLimit = data => {
		const { selectedNodeMsg } = this.state
		// 如果点击根节点或初始状态则获取全部（不传部门id）
		if (JSON.stringify(selectedNodeMsg) !== '{}' && selectedNodeMsg['id'] !== -1) {
			data['id'] = selectedNodeMsg['id']
		}
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = res => {
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}

	// 删除部门后重新获取树数据和表格数据
	handleDeleteDepartment = node => {
		const { deleteDepartment } = this.props
		deleteDepartment(node).then(() => {
			if (node.id === this.state.selectedNodeMsg.id) {
				// 如果删掉了目前选中的节点，那么就不能再次获取当前节点的部门列表了，而是获取全部，因此设置为 {}
				this.setState(
					{
						selectedNodeMsg: {},
					},
					this.reloadAllData
				)
			} else {
				this.reloadAllData()
			}
		})
	}

	// 重新获取树数据和表格数据
	reloadAllData = () => {
		const { pub_getAllData } = this.props
		pub_getAllData() // 重新获取表格数据
		departmentTreeRef.getTree() // 重新获取树数据
	}

	// 打开新增部门的模态框
	openAddDepartmentModal = node => {
		this.setState(
			{
				modalTitle: '新增部门',
				modalOkText: '保存',
			},
			() => {
				departmentModalRef.current.show(formRef => {
					// 通过点击树节点则自动填入业务主管部门
					node &&
						formRef.setFieldsValue({
							parentId: node['id'],
						})
				})
			}
		)
	}

	// 打开编辑部门的模态框
	openEditDepartmentModal = node => {
		this.setState(
			{
				modalTitle: '编辑部门',
				modalOkText: '更新',
				editNodeMsg: node,
			},
			() => {
				departmentModalRef.current.show(formRef => {
					const formData = rjUtils.clearNull({
						departmentName: node['departmentName'], // 编辑的部门名称
						departmentNumber: node['departmentNumber'], // 编辑的部门编号
						parentId: node['parentId'], // 编辑的部门父id
						fondsIdentifierId: node['fondsName'], // 编辑的部门绑定的全宗
						manager: node['manager'], // 编辑的部门的业务负责人信息
						description: node['description'], // 编辑的部门的简介
						managerId: node['manager']
							? `${node['manager'].name}——${node['manager'].workId}`
							: null,
					})
					// 通过点击树节点则自动填入所有的信息
					formRef.setFieldsValue(formData)
				})
			}
		)
	}

	// 增加或更新部门信息
	onDepartmentModalOk = (formData, { hide, finish }) => {
		const { addDepartment, updateDepartment } = this.props
		const { modalTitle } = this.state
		let onOk = null
		switch (modalTitle) {
			case '新增部门':
				onOk = addDepartment
				// 如果是要在根节点下添加，则不传 parentId
				if (formData['parentId'] === -1) delete formData['parentId']
				break
			case '编辑部门':
				onOk = updateDepartment
				// 编辑部门是对该部门进行编辑，而不是父部门
				formData['id'] = this.state.editNodeMsg['id']
				// 如果是 React DOM 要删掉，不发，只是做显示而已
				if (React.isValidElement(formData['managerId'])) {
					delete formData['managerId']
				}
				delete formData['parentId']
				break
			default:
				return
		}
		rjUtils.clearUndefined(formData) // 深度过滤对象的 undefined 值
		const { managerId, ...department } = formData
		// 操作后关闭模态框并重新获取所有数据
		onOk({
			managerId,
			department,
		})
			.then(() => {
				hide()
				this.reloadAllData()
			})
			.catch(() => finish())
	}

	// 操作按钮的渲染函数
	operationButton = record => {
		return (
			<Space>
				<Button
					className='iconfont-buttons'
					title='编辑'
					size='small'
					onClick={() => this.openEditDepartmentModal(record)}>
					&#xeabd; 编辑
				</Button>
				<Button
					size='small'
					title='删除'
					className='table-btn-danger iconfont-buttons'
					onClick={() => this.handleDeleteDepartment(record)}>
					{' '}
					&#xe816; 删除
				</Button>
			</Space>
		)
	}

	render() {
		const { selectedNodeMsg, modalTitle, modalOkText } = this.state
		return (
			<Fragment>
				<div className='department-management-wrapper'>
					{/* 左边部门树组件 */}
					<div id='depart-left'>
						<DepartmentTree
							updateSelectNode={this.updateSelectNode} // 更新选中信息的方法
							selectedNodeMsg={selectedNodeMsg} // 当前树选中节点的信息
							openAddDepartmentModal={this.openAddDepartmentModal}
							openEditDepartmentModal={this.openEditDepartmentModal}
							handleDeleteDepartment={this.handleDeleteDepartment} // 删除部门的方法
							pub_getAllData={this.props.pub_getAllData} // 更新右边表格的方法
							getInstance={ref => {
								departmentTreeRef = ref
							}} // 让页面获取树组件实例的方法，由 WithRef 高阶去调用
						/>
					</div>
					{/* 右边表格部分 */}
					<div id='depart-right'>
						<WrappedTable>
							<SearchForm
								name='department-management-search-form' // 组件名称
								formItems={this.searchFormConfig}
								getInstance={formRef => {
									this.searchFormRef = formRef
								}}
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData}
								markName={markName}
							/>

							<BaseTable
								tableConfig={this.tableConfig} // 表格表头设置
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData} // 把拿到的表格数据改造成符合公共组件所需数据的格式
								markName={markName}
								withPreview={false}
								operationButton={this.operationButton}
								notRowSelection
							/>
						</WrappedTable>
					</div>
				</div>
				{/* 新增或编辑部门的模态框 */}
				<DepartmentModal
					modalRef={departmentModalRef}
					title={modalTitle}
					okText={modalOkText}
					hideCallback={() =>
						this.setState({
							modalTitle: '',
							modalOkText: '',
							editNodeMsg: {},
						})
					}
					onOk={this.onDepartmentModalOk}
				/>
			</Fragment>
		)
	}
}

export default DepartmentManagement
