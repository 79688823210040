import {
	ADD_FIXDATA, // 添加修正表详情
	CHANGE_FIXDATA_STATUS,
} from '@/Redux/ActionTypes/FixDetail'
import InitialState from '@/Redux/InitialState'
import _ from 'lodash'
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = InitialState.fixDetails, action) => {
	switch (action.type) {
		case ADD_FIXDATA: {
			// 添加文件详情
			let formDate = _.cloneDeep(state)
			const { data, category } = action
			formDate.fixData = data
			formDate.category = category
			return formDate
		}
		case CHANGE_FIXDATA_STATUS: {
			let formDate = _.cloneDeep(state)
			const {
				buttonType, // 按钮类型
			} = action
			formDate.fixData[formDate.fixData.length - 1].fix_status = buttonType
			return formDate
		}
		default: {
			return state
		}
	}
}
