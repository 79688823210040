import AppraisalActionType from '@/Redux/ActionTypes/AppraisalDetails'
import initialState from '@/Redux/InitialState'
import _ from 'lodash'

const addAppraisalDataToRedux = (state, action) => {
	let newState = _.cloneDeep(state)
	newState.appraisalData = action.data
	return newState
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState.appraisalDetails, action) {
	switch (action.type) {
		case AppraisalActionType.APPRAISAL_DATA:
			return addAppraisalDataToRedux(state, action)
		default:
			return state
	}
}
