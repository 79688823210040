import React, { Component, Fragment } from 'react'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import SearchForm from '@/Public/PubTable/searchForm'
import { myApprovalBorrowUrl } from '@/Utils/Urls'
import { approvalBorrowColumns } from '@/Public/PubTable/pageColumns'
import { connect } from 'react-redux'
import { message } from 'antd'
import { routeChangeAction, pushRoutersAction } from './ActionCreator'
import { Button, Modal } from 'antd'
import { linkTo } from '@/Routes/ManageRouter'
import { history } from '@/index'
import {
	addBorrowApprovalAction,
	statusEnum,
	getUseReviewDetailThunk,
} from '../../Components/ContentIn/Workarea/MyApproval/Borrow/ActionCreator'
import { pub_getAllTableData } from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator.js'

const approvalLink = {
	title: '借阅审批详情',
	key: '/workarea/commonComponents/approvalDetails',
}

const markName = 'BorrowApproval' // redux的标识

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	onChange(activeKey, history) {
		if (history) {
			history.push(linkTo.to(activeKey.key))
		} else {
			dispatch(routeChangeAction(activeKey.key))
		}
		dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
	},
	pushRouters(link) {
		return dispatch(pushRoutersAction(link))
	},
	addBorrowApproval: data => dispatch(addBorrowApprovalAction(data)), // 添加
	getUseReviewDetail: (...rest) => dispatch(getUseReviewDetailThunk(...rest)), // 查看借阅申请的审批详情
	getAllTableData(...rest) {
		return dispatch(pub_getAllTableData(...rest)) // 把获取到的表格所有数据发送到store
	},
})
// 将文件加入收藏夹的模态框
@connect(mapStateToProps, mapDispatchToProps)
class BorrowApprovalModal extends Component {
	searchFormRef = React.createRef(null)
	state = {
		bottomCenter: 'bottomCenter',
		applyUser: '',
		formData: {
			status: '',
		},
		records: [],
		state: 1, // 审批状态
	}
	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{
				type: 'Input',
				name: 'applyUser',
				label: '申请人',
				placeholder: '请输入申请人',
				width: '200px',
			},
			{
				type: 'DatePicker',
				name: 'applyTime',
				label: '申请时间',
				placeholder: '请选择申请时间',
				width: '200px',
				picker: 'year',
			},
			{
				type: 'Select',
				name: 'status',
				label: '处理状态',
				options: statusEnum.toOptions(),
				width: '200px',
				initialValue: 2,
				disabled: true,
			},
		],
		ajaxConfig: {
			ajaxType: 'post',
			url: myApprovalBorrowUrl.getDepartmentUseApplyList,
		},
	}

	changeSearchLimit = data => {
		this.setState({ state: data.status })
		if (data.applyUser === undefined) {
			delete data.applyUser
		} else {
			this.setState({
				applyUser: data.applyUser,
			})
		}
		if (data.applyTime === undefined) delete data.applyTime
		return data
	}
	changeTableData = data => {
		data = {
			data: data.records,
			pageSize: data.size,
			current: data.current,
			total: data.total,
		}
		return data
	}

	returnButton = record => {
		// 返回按钮数据
		return (
			<Button
				onClick={() => {
					this.props
						.getUseReviewDetail(record.id)
						.then(res => {
							res['id'] = record.id
							this.props.addBorrowApproval(res) // 文件详情传到redux
							this.props.onChange(approvalLink, history) // 更改路由
							if (this.props.records) {
								// 如果有数据则添加
								let data = {}
								data['data'] = this.props.records
								data.pageData = {
									// 配置表格的页码器
									pageSize: data.size || 10,
									current: data.page || 1,
									total: data.total,
								}
								this.props.getAllTableData(data, 'SelectBorrowFile') // 把表格数据发送到store
							}
							this.props.pushRouters(approvalLink) // 添加分页
						})
						.then(() => {
							message.success('添加成功')
						})
				}}
				style={{ margin: 'auto 0' }}>
				添加
			</Button>
		)
	}

	render() {
		const { onCancel, visible } = this.props
		return (
			<Modal
				title={'选择加入的借阅审批表'}
				visible={visible}
				width={1250}
				onCancel={onCancel}
				className={'BorrowApprovalTable'}
				footer={[
					<Button key='back' onClick={this.props.onCancel}>
						取消
					</Button>,
				]}>
				<Fragment>
					<WrappedTable>
						<SearchForm
							markName={markName}
							name={'BorrowApproval'}
							formItems={this.searchFormConfig} // 完成状态更改搜索栏
							getInstance={formRef => {
								this.searchFormRef = formRef
							}}
							searchButtonText='查询'
							changeSearchLimit={this.changeSearchLimit}
							changeTableData={this.changeTableData}
						/>
						<BaseTable
							markName={markName}
							tableConfig={approvalBorrowColumns}
							changeTableData={this.changeTableData}
							operationButton={this.returnButton} // 自定义操作按钮
							changeSearchLimit={this.changeSearchLimit}
							notRowSelection={true}
							withPreview={false}
						/>
					</WrappedTable>
				</Fragment>
			</Modal>
		)
	}
}

export default BorrowApprovalModal
