import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import {
	PUBFILE_ADD,
	PUBDOSSIER_ADD,
	PUB_COLLECTEDFILE_ADD,
	PUB_Album_ADD,
} from '@/Redux/ActionTypes/Public/PubForm'
import { INITIAL_PUBARCHIVE } from '@/Redux/ActionTypes/PubArchive'
import _fetch from '@/Utils/Fetch'
import { dossierUrl, archiveUrl, collectedFileUrl, ePhotoAlbum } from '@/Utils/Urls'
import { dossierEnum } from '@/Utils/Enum'

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

export const addPubFileAction = data => {
	return {
		type: PUBFILE_ADD,
		data: data,
	}
}

export const addPubCollectedFileAction = data => {
	return {
		type: PUB_COLLECTEDFILE_ADD,
		data: data,
	}
}

export const addPubAlbumAction = data => {
	return {
		type: PUB_Album_ADD,
		data: data,
	}
}

export const addPubDossierAction = data => {
	return {
		type: PUBDOSSIER_ADD,
		data: data,
	}
}

// 初始化pubArchive[markName].old
export const inititalPubArchiveAction = (markName, pubArchiveData) => {
	return {
		type: INITIAL_PUBARCHIVE,
		filter: {
			markName,
			...pubArchiveData,
		},
	}
}

/**
 * 获取案卷或档案详情
 * @param {Object} record 案卷或档案的信息
 */
export const getDetailThunk = record => () =>
	new Promise(async (resolve, reject) => {
		const {
			id,
			type,
			isCollectedFile,
			isEPhotoAlbum,
			archiveType, // 文件分为 文书2 和 多媒体1
		} = record

		let url = null
		let isCollectedFileMark = null

		//配置ajax config参数
		if (dossierEnum.type[type] === '资料' || isCollectedFile === true) {
			isCollectedFileMark = true
		}

		if (dossierEnum.type[type] === '案卷') {
			url = dossierUrl.getDossierDetail
		} else if (dossierEnum.type[type] === '文件') {
			archiveType === 2
				? (url = archiveUrl.getDocDetail)
				: (url = dossierUrl.getDossierDetail)
		} else if (isCollectedFileMark) {
			url = collectedFileUrl.getCollectedFileDetail
		} else if (isEPhotoAlbum) {
			url = ePhotoAlbum.getTheme
		}

		if (dossierEnum.type[type] === '案卷') {
			url = dossierUrl.getDossierDetail
		} else if (dossierEnum.type[type] === '文件') {
			archiveType === 2
				? (url = archiveUrl.getDocDetail)
				: (url = archiveUrl.getArchiveDetail)
		} else if (isCollectedFileMark) {
			url = collectedFileUrl.getCollectedFileDetail
		} else if (isEPhotoAlbum) {
			url = ePhotoAlbum.getTheme
		}

		const res = await _fetch({
			url,
			data: {
				[isCollectedFileMark ? 'collectedFileId' : 'id']: id,
			},
		})

		if (res && res.data.code === 200) {
			resolve({
				...res.data.data,
				type: record.type,
			})
		} else {
			reject()
		}
	})

// /** 后台修好了
//  * 获取单个文件的案卷的档案文件具体内容
//  * 需要先执行getDetailThunk(action)
//  * @param {number} id archiveID
//  * @returns
//  */
// export const getFileDetailThunk = records => () =>
// 	new Promise(async (resolve, reject) => {
// 		//获取ArchiveID
// 		const { id } = records
// 		const res = await _fetch({
// 			url: archiveUrl.getArchiveDetail,
// 			data: {
// 				id
// 			}
// 		})
// 		if (res && res.data.code === 200) {
// 			resolve({
// 				...res.data.data
// 			})
// 		} else {
// 			reject()
// 		}
// 	})

// 获取表格数据
export const getArchiveListThunk = record => () =>
	new Promise(async (resolve, reject) => {
		// 案卷顺带请求其下的列表
		_fetch({
			url: archiveUrl.getArchiveList,
			type: 'get',
			data: {
				id: record.id,
				size: 999,
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data.records)
			} else {
				reject()
			}
		})
	})
