import React, { Component } from 'react'
import { Modal, Image, Button, Form, Input, Space, message } from 'antd'
const formRef = React.createRef(null) // 表单ref

class FaceModal extends Component {
	modifyFaceDetail = (showData, index, handleHide) => {
		return new Promise((resolve, reject) => {
			const newData = formRef.current.getFieldsValue()
			newData.url = showData.url
			newData.uid = showData.uid
			this.props.faceInformationEntry({ id: newData.uid, ...newData }).then(() => {
				this.props.resetData(newData, index, resolve)
				message.success('修改成功！')
				handleHide && handleHide()
			}, reject)
		})
	}

	isModifyFaceDetail = (oldValue, newValue) => {
		const value = { ...oldValue }
		Reflect.deleteProperty(value, 'information')
		Reflect.deleteProperty(value, 'uid')
		Reflect.deleteProperty(value, 'url')

		for (const key in newValue) {
			if (Object.hasOwnProperty.call(newValue, key)) {
				if (newValue[key] !== value[key]) {
					return true
				}
			}
		}

		return false
	}

	render() {
		const {
			visible,
			handleHide,
			isRecognized,
			showData,
			switchFaceModal,
			propIndex,
		} = this.props
		return (
			<Modal
				visible={visible}
				destroyOnClose={true}
				className='face-modal'
				onCancel={() => {
					handleHide()
				}}
				footer={
					<Space>
						<Button
							onClick={() => {
								if (
									this.isModifyFaceDetail(
										showData,
										formRef.current.getFieldsValue()
									)
								) {
									this.modifyFaceDetail(showData, propIndex).then(
										() => {
											switchFaceModal('pre', formRef, isRecognized)
										}
									)
								} else {
									switchFaceModal('pre', formRef, isRecognized)
								}
							}}>
							上一张
						</Button>
						<Button
							onClick={() => {
								if (
									this.isModifyFaceDetail(
										showData,
										formRef.current.getFieldsValue()
									)
								) {
									this.modifyFaceDetail(showData, propIndex).then(
										() => {
											switchFaceModal('next', formRef, isRecognized)
										}
									)
								} else {
									switchFaceModal('next', formRef, isRecognized)
								}
							}}>
							下一张
						</Button>
						<Button
							type='primary'
							onClick={() => {
								// 校验表单并获取表单信息发请求
								formRef.current.validateFields().then(() => {
									this.modifyFaceDetail(showData, propIndex, handleHide)
								})
							}}>
							确定
						</Button>
					</Space>
				}>
				<Image
					src={showData.url}
					style={{
						height: '150px',
						width: '150px',
						textAlign: 'center',
						margin: '0 auto',
					}}
				/>
				<Form
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 14 }}
					initialValues={showData}
					ref={formRef}>
					<Form.Item
						label='姓名'
						name='name'
						rules={[{ required: true, message: '请输入姓名' }]}>
						<Input placeholder='无' />
					</Form.Item>
					<Form.Item label='部门' name='department'>
						<Input placeholder='无' />
					</Form.Item>
					<Form.Item label='职务' name='position'>
						<Input placeholder='无' />
					</Form.Item>
					<Form.Item label='身份ID' name='identity'>
						<Input placeholder='无' />
					</Form.Item>
				</Form>
			</Modal>
		)
	}
}

export default FaceModal
