import React, { PureComponent } from 'react'
import { Tabs } from 'antd'
import MyTables from '../MyTables'
import './index.less'

const { TabPane } = Tabs

export default class UserArchive extends PureComponent {
	render() {
		const myInfo = this.props.myInfo
		const groups = myInfo.length !== 0 ? myInfo.labelName : []
		return (
			groups.length !== 0 && (
				<Tabs defaultActiveKey='0' className='user-tabs'>
					{groups.map((item, index) => {
						return (
							<TabPane
								tab={item}
								key={index}
								className='site-collapse-custom-panel'>
								<MyTables labelId={index} markName={item}></MyTables>
							</TabPane>
						)
					})}
				</Tabs>
			)
		)
	}
}
