/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react'
import { Button, message, Modal } from 'antd'
import EditTransfer from './EditTransfer'
import SelectNumber from './SelectNumber'
import _fetch from '@/Utils/Fetch'
import { serialNumberUrl } from '@/Utils/Urls'
import './index.less'

// 默认数据
const defaultOrder = 'd0-n-f0-b-j-q-l0'

const orderMap = new Map([
	['d', '机构代码'],
	['n', '年度'],
	['f', '分类号'],
	['b', '保管期限'],
	['j', '卷/件标识'],
	['q', '全宗号'],
	['l', '流水号'],
])
const tips = {
	withNumber: '请编辑相应字段的数字',
	formatIsNumber: '请选择数字格式',
}

/**
 * 格式化order的字符串，返回一个数组，配合antd组件
 * @param {String} orderStr order的string
 * @returns
 */
const formatOrderData = orderStr => {
	const orderArr = orderStr.split('-')
	const orderData = orderArr.map(order => {
		const number = order.slice(1, order.length)
		order = order[0]
		const label = orderMap.get(order)
		const obj = {
			key: order,
			value: order,
			label,
		}
		if (number) {
			obj.number = number
			obj.editable = true
		}
		return obj
	})
	return orderData
}
/**
 * 格式化order的数组，返回order字符串，配合接口
 * @param {*} targetKeys
 * @param {*} data
 */
const formatOrderStr = (targetKeys, data) => {
	let order = ''
	for (let i in targetKeys) {
		const key = targetKeys[i]
		let str = ''
		const item = data.find(item => item.key === key)
		if (Number(i)) str += '-'
		str += item.key
		if (item.number) str += item.number
		order += str
	}
	return order
}

/**
 * 将order的字符串形式，转为targetKeys数组
 * @param {*} orderStr
 * @returns
 */
const formatTargetKeys = orderStr => {
	return orderStr.split('-').map(order => order.replace(/\d/g, ''))
}

export const DefaultDataContext = React.createContext()
// 获取档案号的已有的配置，如果没有，则使用默认情况
const getSerialNumberConfig = async () => {
	let serialNumberConfig = JSON.parse(sessionStorage.getItem('serialNumberConfig'))
	if (serialNumberConfig) return serialNumberConfig

	const res = await _fetch({
		url: serialNumberUrl.getSerialNumberConfig,
		type: 'get',
	})

	if (res?.data?.code === 200) {
		const { data } = res.data
		serialNumberConfig = {
			order: formatOrderData(data.order),
			formatIsNumber: data.formatIsNumber,
			targetKeys: formatTargetKeys(data.order),
			hadConfig: true, // 是否已经配置过了
		}
	} else {
		serialNumberConfig = {
			order: formatOrderData(defaultOrder),
			formatIsNumber: '',
			hadConfig: false,
		}
	}
	sessionStorage.setItem('serialNumberConfig', JSON.stringify(serialNumberConfig))
	return serialNumberConfig
}

function SerialNumber() {
	const [data, setData] = useState([]) // 穿梭框的所有数据来源
	const [targetKeys, setTargetKeys] = useState([])
	const [formatIsNumber, setFormat] = useState('')
	const [hadConfig, setHadConfig] = useState(false)
	const [visible, setVisible] = useState(false)
	const [flash, setFlash] = useState(false) // 设置成功后，刷新

	useEffect(async () => {
		const serialNumberConfig = await getSerialNumberConfig()
		setData(serialNumberConfig.order)
		setFormat(serialNumberConfig.formatIsNumber)
		setTargetKeys(serialNumberConfig.targetKeys)
		setHadConfig(serialNumberConfig.hadConfig)
	}, [flash])

	// 档案号格式预览的变量
	const orderPreView = useMemo(() => {
		let res = ''
		for (let i in targetKeys) {
			const key = targetKeys[i]
			const curItem = data.find(item => item.key === key)
			let str = !Number(i) ? '' : ' —— '
			str += `[${curItem.label}]`
			if (Number(curItem.number)) str += `（${curItem.number}位）`
			res += str
		}
		return res
	}, [targetKeys, data])

	// 判断用户输入是否正确
	const checkRule = () => {
		return new Promise((resolve, reject) => {
			let res = []
			for (let key of targetKeys) {
				const item = data.find(item => item.key === key)
				if (item.editable && !Number(item.number)) {
					reject({ message: tips.withNumber })
				}
				res.push(item)
			}
			if (!!formatIsNumber === false) {
				reject({ message: tips.formatIsNumber })
			}
			resolve(res)
		})
	}

	// 发送请求，修改档案号配置
	const configSerialNumber = async (order, formatIsNumber) => {
		const config = {
			url: serialNumberUrl.configureSerialNumber,
			type: 'post',
			data: {
				order,
				formatIsNumber,
			},
		}
		return await _fetch(config)
	}

	// 点击保存的回调函数
	const submit = async () => {
		try {
			const data = await checkRule()
			const order = formatOrderStr(targetKeys, data)
			const res = await configSerialNumber(order, formatIsNumber)
			if (res?.data?.code === 200) {
				message.success('修改成功')
				sessionStorage.removeItem('serialNumberConfig')
				setFlash(!flash)
			} else {
				message.error(res.message)
			}
		} catch (error) {
			message.info(error.message)
		}
		hideModal()
	}

	const hideModal = () => setVisible(false)

	const showModal = () => setVisible(true)

	return (
		<div className='serial-number-container'>
			<div className='order-preview'>
				档案号格式预览：
				<span>{orderPreView}</span>
				{!hadConfig && (
					<span className='serial-number-submit'>
						<Button type='primary' onClick={showModal}>
							保存
						</Button>
					</span>
				)}
			</div>
			<DefaultDataContext.Provider value={data}>
				<EditTransfer
					dataSource={data}
					setData={setData}
					targetKeys={targetKeys}
					setTargetKeys={setTargetKeys}
					hadConfig={hadConfig}
				/>
			</DefaultDataContext.Provider>
			<SelectNumber
				setFormat={setFormat}
				formatIsNumber={formatIsNumber}
				hadConfig={hadConfig}
			/>
			{
				<Modal
					title='设置档案号'
					visible={visible}
					onOk={submit}
					onCancel={hideModal}
					okText='确认'
					cancelText='取消'>
					<p>档案号一经配置，不可修改，是否修改？</p>
				</Modal>
			}
		</div>
	)
}

export default SerialNumber
