import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	Pagination,
	Button,
	message,
	Modal,
	Space,
	Input,
	Form,
	Image,
	Radio,
} from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { sortableHandle } from 'react-sortable-hoc'
import PubArchive from '@/Public/PubArchive'
import FilePreviewer from '@/Public/FilePreviewer'
import './index.less'
import ButtonList from '@/Public/ButtonList'
import {
	routeChangeAction,
	pushRoutersAction,
	addPubFileAction,
	// updateAttachmentThunk,
	updateThemeThunk,
	searchThemeThunk,
	addFileThunk,
	updateFileThunk,
	deleteFileThunk,
	getThemeThunk,
	applyShareStudyingFilesThunk,
	getShareStudyingFilesThunk,
	changeFilePriorityThunk,
	deleteTypeThunk,
} from './ActionCreator'
import { inititalPubArchiveAction } from '@/Public/PubTable/baseTable/ActionCreator'
import Enum from '@/Utils/Enum'
import { linkTo } from '@/Routes/ManageRouter'
import { history } from '@/index'
import _ from 'lodash'
const statusType = new Enum(['编辑', '完成', '封存']) // 档案搜索类型
const markName = 'ePhotoAlbumDetails'
// PubForm 表单项配置数组
const formItems = [
	[
		{
			type: 'TextArea',
			name: 'topic',
			label: '题名',
			placeholder: '请输入题名',
			rules: [{ required: false }],
			bordered: false,
			autoSize: { maxRows: 4 },
			style: { WebkitLineClamp: 4 },
		},
	],
	[
		{
			type: 'TextArea',
			name: 'departmentName',
			label: '部门',
			placeholder: '无',
			rules: [{ required: false }],
			bordered: false,
			autoSize: { maxRows: 4 },
			style: { WebkitLineClamp: 4 },
		},
	],
	[
		{
			type: 'TextArea',
			name: 'keyWord',
			placeholder: '无',
			rules: [{ required: false }],
			label: '关键字',
			bordered: false,
			autoSize: { maxRows: 4 },
			style: { WebkitLineClamp: 4 },
		},
	],
	[
		{
			type: 'TextArea',
			name: 'introduction', // 简介
			label: '简介',
			rules: [{ required: false }],
			placeholder: '无',
			rows: 8,
			resize: 'noResize',
			bordered: false,
			autoSize: { maxRows: 4 },
			style: { WebkitLineClamp: 4 },
		},
	],
	[
		{
			type: 'Input',
			name: 'userId', // 作者
			label: '作者',
			rules: [{ required: false }],
			placeholder: '无',
			rows: 8,
			resize: 'noResize',
			bordered: false,
			autoSize: { maxRows: 4 },
			style: { WebkitLineClamp: 4 },
		},
	],
	[
		{
			type: 'TextArea',
			name: 'time', // 时间
			label: '时间',
			rules: [{ required: false }],
			placeholder: '无',
			rows: 8,
			resize: 'noResize',
			bordered: false,
			autoSize: { maxRows: 4 },
			style: { WebkitLineClamp: 4 },
		},
	],
]
let pageCurrent = 1

const DragHandle = sortableHandle(() => (
	<MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
))

const mapStateToProps = state => {
	return {
		allEPhotoAlbumData: state.pubTable.pub_allTableData.EPhotoAlbum,
		nowEPhotoAlbumData: state.pubArchive.ePhotoAlbumDetails?.new.archiveData,
		pubArchive: state.pubArchive,
		route: state.route.item,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		inititalPubArchive: (markName, pubArchive) =>
			dispatch(inititalPubArchiveAction(markName, pubArchive)),
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey.key))
			} else {
				dispatch(routeChangeAction(activeKey.key))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
		},
		pushRouters(link) {
			return dispatch(pushRoutersAction(link))
		},
		addPubFileData(data) {
			return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		// 获取、更新主题信息
		getTheme: (...rest) => dispatch(getThemeThunk(...rest)),
		searchTheme: (...rest) => dispatch(searchThemeThunk(...rest)),
		updateTheme: (...rest) => dispatch(updateThemeThunk(...rest)),
		deleteType: (...rest) => dispatch(deleteTypeThunk(...rest)), // 删除当前主题
		// 主题包含的文件
		addFile: (...rest) => dispatch(addFileThunk(...rest)),
		updateFile: (...rest) => dispatch(updateFileThunk(...rest)),
		deleteFile: (...rest) => dispatch(deleteFileThunk(...rest)),
		applyShareStudyingFiles: (...rest) =>
			dispatch(applyShareStudyingFilesThunk(...rest)),
		getShareStudyingFiles: (...rest) => dispatch(getShareStudyingFilesThunk(...rest)),
		changeFilePriority: (...rest) => dispatch(changeFilePriorityThunk(...rest)),
		// 更新案卷信息
		// updateAttachment: (...rest) => dispatch(updateAttachmentThunk(...rest)),
	}
}

@connect(mapStateToProps, mapDispatchToProps)
class EPhotoAlbumDetails extends Component {
	descriptRef = React.createRef()
	state = {
		edit: false, // 用于标识编辑状态
		visible: false, // modal是否显示
		isRowSelection: false, //是否可以行选
		currentIndex: -1,
		id: -1,
		shareUrl: sessionStorage.getItem('shareUrl') || '',
		descriptVisible: false,
		selectFileData: {},
		archiveIdMapId: new Map(), // 档案id映射编研文件id
		previewRecord: [], // 预览文件信息
		previewVisible: false, // 是否放大图片预览器
		previewSrc: '', // 预览图片地址
		record: {},
	}

	// 主题详情左边表格表头配置
	tableColumns = [
		{
			title: '序号',
			dataIndex: 'sequantial',
			width: 35,
			render: (text, record, formRef) => {
				let child = record.sequantial
				return child
			},
		},
		{
			title: '排序',
			dataIndex: 'sequantial',
			width: 35,
			render: (text, record, formRef) => {
				let child = <DragHandle />
				return child
			},
		},
		{
			title: '预览',
			dataIndex: 'fileData',
			render: (text, record, index, tableFuncs) => {
				return record.fileType === null ? null : (
					<FilePreviewer
						click={() => {
							this.props?.clickPhoto?.(record, index)
						}}
						fileData={record.preview.fileData}
						isThumbnail={true}
					/>
				)
			},
			width: 50,
		},
		{ title: '题名', dataIndex: 'topic', width: 180, ellipsis: true },
		{ title: '部门', dataIndex: 'departmentName', width: 60 },
		{ title: '著录人', dataIndex: 'recordUser', ellipsis: true, width: 60 },
		{
			title: '著录时间',
			dataIndex: 'recordTime',
			ellipsis: true,
			width: 60,
			render: text => {
				if (text) {
					return text.includes('T')
						? text.replace(/T(.*)/, '')
						: text.replace(/\s(.*)/, '')
				}
			},
		},
		{
			title: '操作',
			dataIndex: 'operation',
			render: (text, record, index) => {
				const component = this
				return this.state.edit ? (
					<div className='edit-button'>
						<Button
							type='primary'
							style={{ color: 'white' }}
							className='iconfont-buttons'
							title='编辑'
							onClick={() => {
								component.descriptRef.current &&
									component.descriptRef.current.setFieldsValue(record)
								this.setState({
									descriptVisible: true,
									selectFileData: record,
								})
							}}>
							&#xeabd;{' '}
						</Button>
						<Button
							className='btn-danger iconfont-buttons'
							onClick={() => {
								const { deleteFile, pubArchive, inititalPubArchive } =
									this.props
								const { archiveIdMapId } = this.state

								const themeData = pubArchive.ePhotoAlbumDetails.new
								let tmpFilesData = themeData.filesData.filter(item => {
									return item.id !== record.id
								})
								deleteFile({
									id: archiveIdMapId.get(record.id)
										? archiveIdMapId.get(record.id)
										: record.id,
								}).then(res => {
									if (res.success) {
										inititalPubArchive('ePhotoAlbumDetails', {
											filesData: tmpFilesData,
											archiveData: themeData.archiveData,
										})
									}
								})
							}}>
							&#xe816;
						</Button>
					</div>
				) : (
					<Button
						onClick={() => {
							this.setState({
								previewVisible: true,
								previewSrc: `/api/file/download/${record.fileToken}`,
								record,
							})
						}}>
						预览
					</Button>
				)
			},
			width: 60,
		},
	]

	componentWillMount() {
		const dossierData = this.props.pubArchive[markName]
		// 判断新旧是否相等，不相等进入编辑状态
		if (!_.isEqual(dossierData?.new, dossierData?.old)) {
			this.setState({ edit: true })
		}
	}

	componentDidMount() {
		const { nowEPhotoAlbumData, allEPhotoAlbumData } = this.props
		const currentIndex = this.getCurrentIndex(nowEPhotoAlbumData, allEPhotoAlbumData)
		this.setState({
			id: nowEPhotoAlbumData.id,
			currentIndex,
			allEPhotoAlbumData,
		})
	}

	// 获取当前项在当前请求数据中的索引
	getCurrentIndex = (nowEPhotoAlbumData, allEPhotoAlbumData) => {
		const id = nowEPhotoAlbumData.id
		for (let i = 0; i < allEPhotoAlbumData.data.length; i++) {
			const temp = allEPhotoAlbumData.data[i]
			if (temp.id === id) {
				return i
			}
		}
		return -1
	}

	// 获取分页器索引
	getTotalIndex = allEPhotoAlbumData => {
		const { currentIndex } = this.state
		if (currentIndex === -1) return -1

		const { current, pageSize } = allEPhotoAlbumData || {}
		// const totalIndex = Math.min(((current - 1) * pageSize + currentIndex + 1), total)
		const totalIndex = (current - 1) * pageSize + currentIndex + 1

		return totalIndex
	}

	// 保存时触发的函数
	saveButtonHandle = async () => {
		if (this.formRef?.separateDossierAfterSaveAction) {
			this.formRef.separateDossierAfterSaveAction()
			this.setState({
				edit: false,
				isRowSelection: false,
			})
			return
		}
		const { updateTheme, pubArchive } = this.props
		let formData = this.formRef.current.getFieldValue()
		let { archiveData: themeData } = pubArchive.ePhotoAlbumDetails?.new
		// 把详情表单信息改成正确格式
		formData = {
			...formData,
			topic: Array.isArray(formData.topic) ? formData.topic[0] : formData.topic,
			id: themeData.id,
			status: isNaN(formData.status)
				? statusType[formData.status]
				: formData.status,
		}

		updateTheme(formData)

		this.setState({ edit: false })
		//改变行选状态，不可以行选
		this.setState({
			isRowSelection: false,
		})
	}

	// 删除当前主题时触发的函数
	deleteButtonHandle = () => {
		const { deleteType, pubArchive } = this.props
		const themeId = pubArchive.ePhotoAlbumDetails.new.archiveData.id
		Modal.confirm({
			maskClosable: true,
			title: '删除主题',
			content: '您正在对当前主题进行删除操作，是否继续？',
			okType: 'primary',
			okText: '确定',
			cancelText: '取消',
			onOk: () => {
				deleteType([themeId]).then(res => {
					if (res.success) {
						history.goBack()
					}
				})
			},
		})
	}

	setEdit = () => {
		this.setState({
			edit: true,
		})
	}

	// 渲染按钮列表的函数
	renderBottomButtons = formRef => {
		const { edit } = this.state
		const oldData = this.props.pubArchive[markName]?.old // 旧版信息
		return (
			<Space style={{ marginBottom: 20 }}>
				{/* 非编辑状态显示 */}
				{!edit && (
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						{/* 著录状态显示 */}
						{oldData?.archiveData.status === 0 ||
						oldData?.archiveData.status === 3 ? (
							<Button
								style={{ marginRight: 10, marginTop: '5px' }}
								className='iconfont-buttons'
								title='编辑'
								onClick={() => {
									this.setState({
										edit: true,
										isRowSelection: true,
									})
								}}>
								&#xeabd; 编辑
							</Button>
						) : null}
						<ButtonList />
					</div>
				)}
				{/* 编辑状态显示 */}
				{edit && (
					<div>
						<Button
							title='取消'
							className='iconfont-buttons'
							onClick={() => {
								let rollBackData = {
									archiveData: oldData.archiveData,
									filesData: oldData.filesData,
								}
								this.formRef.upgradePubArchive(rollBackData)
								this.setState({
									edit: false,
									isRowSelection: false,
								})
							}}>
							&#xe619; 取消
						</Button>
						<Button
							title='保存'
							className='iconfont-buttons'
							type='primary'
							onClick={this.saveButtonHandle}>
							&#xec09; 保存
						</Button>
					</div>
				)}
			</Space>
		)
	}

	// 头部左边的按钮
	renderLeftButtons = formRef => {
		const { edit } = this.state
		return (
			<Space>
				<Button
					className='share-button iconfont-buttons'
					style={{ backgroundColor: 'rgb(95, 212, 95)', color: '#fff' }}
					onClick={() => {
						this.handleShare()
						this.showModal()
					}}
					type='text'
					title='分享'>
					&#xe739; 分享
				</Button>
				{edit && (
					<Button
						className='iconfont-buttons'
						style={{ color: '#fff' }}
						onClick={() => {
							this.changeFilePriority()
						}}
						type='text'
						title='保存排序'>
						&#xec09; 保存排序
					</Button>
				)}
			</Space>
		)
	}

	// 保存文件排序时调用
	changeFilePriority = () => {
		const { pubArchive, changeFilePriority } = this.props
		const newPriority = pubArchive.ePhotoAlbumDetails.new.filesData.map(file => {
			return file.id
		})
		changeFilePriority({ sequence: newPriority })
	}

	// 点击查看文件详情
	jumpToFileDetail = async (record, index, route) => {
		const { addPubFileData, getDetail, onChange, pushRouters } = this.props
		const dossierData = this.props.pubArchive[markName].new
		const link = {
			title: '文件详情',
			key: '/workarea/commonComponents/fileDetails',
		}
		let data = await getDetail(record) // 请求详情数据
		data['archiveList'] = dossierData?.filesData
		data['route'] = route || this.props.route // 由此可以知道从哪打开详情，并判断是否添加按钮
		data['startIndex'] = (pageCurrent - 1) * 10 + index // 跳转文件详情第几张图片
		data['fatherDossierId'] = dossierData?.archiveData.id //由此可以知道父级的案卷是哪一个
		addPubFileData(data) // 文件详情传到redux
		onChange(link, history) // 修改路由
		pushRouters(link) // 添加分页栏
	}

	// 处理添加后的文件
	handleAlbumRecords = records => {
		const { pubArchive, addFile, inititalPubArchive } = this.props
		let { archiveData: themeData, filesData } = pubArchive.ePhotoAlbumDetails?.new
		const preLength = filesData.length
		filesData = filesData.filter(item => {
			return (
				item.fileType === 'image/jpeg' ||
				item.fileType === 'image/webp' ||
				item.fileType === 'image/png'
			)
		})
		const nextLength = filesData.length
		preLength !== nextLength &&
			inititalPubArchive('ePhotoAlbumDetails', {
				filesData,
				archiveData: themeData,
			})

		records = records.filter(item => {
			return (
				item.fileType === 'image/jpeg' ||
				item.fileType === 'image/webp' ||
				item.fileType === 'image/png'
			)
		})

		records.forEach(file => {
			addFile({
				topic: file.topic,
				description: '',
				archiveId: file.id,
				type: file.type,
				themeId: themeData.id,
			}).then(res => {
				if (res) {
					this.setState({
						archiveIdMapId: this.state.archiveIdMapId.set(file.id, res.id),
					})
				}
			})
		})

		return records.length
	}

	// 点击分享后调用
	handleShare = () => {
		const { nowEPhotoAlbumData } = this.props
		const { applyShareStudyingFiles } = this.props
		applyShareStudyingFiles({ themeId: nowEPhotoAlbumData.id }).then(res => {
			const shareUrl = `${window.location.origin}/share?files=${res}`
			this.setState({ shareUrl }, () => {
				sessionStorage.setItem('shareUrl', shareUrl)
			})
		})
	}

	// 点击图片跳转文件详情
	clickPhoto = (record, index) => {
		this.jumpToFileDetail(record, index)
	}

	// 获取表单的实例
	getInstance = async formRef => {
		this.formRef = formRef
		if (!formRef) return
	}

	// 判断文本框是否过长
	isTooLong = e => {
		const target = e.target
		const nodeName = target.nodeName.toLowerCase()
		if (nodeName === 'textarea') {
			if (target.disabled) {
				const inputValue = target.value
				if (inputValue.length > 52) {
					return true
				}
			}
		}
		return false
	}

	// 关闭模态框
	handleCancel = () => {
		this.setState({
			visible: false,
		})
	}

	// 附件的
	changeAttachments = (attachmentList, documentId, callback) => {
		const { updateAttachment, record } = this.props // 从actionCreators里面引入的更新附件的函数
		// 如果有新的附件再发送请求
		// 过滤值为null的附件。已有的附件值都为null
		attachmentList = attachmentList.filter(item => item !== null)
		updateAttachment({
			fileIds: attachmentList,
			type: 0, // 表示案卷的类型
			documentId,
		})
			.then(() => {
				message.destroy()
				message.success('保存成功')
				this.changeItem(record)
			})
			.catch(() => {
				message.destroy()
				message.error('保存失败')
			})
			.finally(() => {
				callback && callback() // 这个一定要加
			})
	}

	// 改变页面中的项时发送数据到redux
	changeItem = record => {
		const { inititalPubArchive } = this.props
		inititalPubArchive('ePhotoAlbumDetails', {
			filesData: record.files,
			archiveData: record,
		})
		this.setState({
			id: record.id,
		})
	}

	// 页码改变的回调
	pageChange = (page, pageSize) => {
		pageCurrent = page
	}

	// 填写主题详情信息
	getThemeData = themeData => {
		const { topic, keyWord, brief, submitTime, departmentId, userId, status } =
			themeData
		const defaultDepartmentId = JSON.parse(
			window.localStorage.getItem('department')
		)[0].children[0].id
		return [
			{ name: 'topic', value: topic },
			{ name: 'departmentId', value: departmentId || defaultDepartmentId },
			{ name: 'keyWord', value: keyWord },
			{ name: 'brief', value: brief },
			{ name: 'submitTime', value: submitTime.split('T')[0] },
			{ name: 'userId', value: userId },
			{ name: 'status', value: statusType[status] },
		]
	}

	// 改变页面
	changePage = async page => {
		const { searchTheme, getTheme } = this.props
		const { allEPhotoAlbumData } = this.state
		const { current, pageSize } = allEPhotoAlbumData

		const targetIndex = page - 1
		const targetCurrent = Math.floor(targetIndex / pageSize) + 1

		// 如果想查看的项超出了当前范围，则必须重新请求一次数据
		if (targetCurrent !== current) {
			const res = await searchTheme({ current: targetCurrent, size: 10 })
			const newAllEPhotoAlbumData = {
				current: res.current,
				data: res.records,
				pageSize: res.size,
				total: res.total,
			}
			this.setState({ allEPhotoAlbumData: newAllEPhotoAlbumData }, () =>
				this.changePage(page)
			)
		} else {
			const record = allEPhotoAlbumData.data[targetIndex - (current - 1) * pageSize]
			const res = await getTheme({ id: record.id })
			if (record) {
				record.isEPhotoAlbum = true
				record.files = res.files
			}

			this.setState(
				{
					id: -1,
					currentIndex: page - (current - 1) * pageSize - 1,
				},
				() => {
					this.changeItem(record)
				}
			)
		}
	}

	showModal = _ => {
		this.setState({
			visible: true,
		})
	}

	copyToClipboard = s => {
		if (window.clipboardData) {
			window.clipboardData.setData('text', s)
		} else {
			const setCopyValue = e => {
				e.preventDefault()
				e.clipboardData.setData('text', s)
				document.removeEventListener('copy', setCopyValue)
			}
			document.addEventListener('copy', setCopyValue)
			document.execCommand('Copy')
		}
	}

	onOk = () => {
		const { updateFile, inititalPubArchive, pubArchive } = this.props
		const { id } = this.state.selectFileData
		const { archiveIdMapId } = this.state
		const { topic, description, fontColor } =
			this.descriptRef.current.getFieldsValue()

		updateFile({
			id: archiveIdMapId.get(id) || id,
			topic,
			description,
			fontColor,
		}).then(res => {
			if (res.success) {
				let newFilesData = pubArchive.ePhotoAlbumDetails.new.filesData.map(
					item => {
						if (item.id === id) {
							item.topic = topic
							item.description = description
							item.fontColor = fontColor
						}
						return item
					}
				)
				inititalPubArchive('ePhotoAlbumDetails', {
					filesData: newFilesData,
					archiveData: pubArchive.ePhotoAlbumDetails.new.archiveData,
				})
			}
		})
		this.setState({
			descriptVisible: false,
		})
	}

	onCancel = () => {
		this.setState({
			descriptVisible: false,
		})
	}

	// 渲染预览放大图片
	renderPreviewImage = () => {}

	render() {
		// 编辑状态
		const {
			edit,
			isRowSelection,
			allEPhotoAlbumData = {},
			shareUrl,
			descriptVisible,
			selectFileData,
			previewVisible,
			previewSrc,
			record,
		} = this.state
		const { pubArchive, setStyle } = this.props
		// 刷新后由于 redux 数据会清空，所以选择跳转路由
		if (JSON.stringify(pubArchive) === '{}') {
			window.location.replace('/Manager/editingResearch/ePhotoAlbum')
		}
		const totalIndex = this.getTotalIndex(allEPhotoAlbumData)
		const { total } = allEPhotoAlbumData

		const formValue = this.getThemeData(
			pubArchive.ePhotoAlbumDetails?.new.archiveData
		)
		let filesWillWithIn = pubArchive.ePhotoAlbumDetails?.new.filesData || []
		filesWillWithIn.forEach(fileData => {
			const { fileToken, fileType } = fileData
			fileData['preview'] = {
				type: 1,
				fileData: {
					fileToken,
					fileType,
				},
			}
			fileData['key'] = fileData.id
		})

		let dossierId = pubArchive?.dossierDetails?.new?.archiveData.id

		// 资料编辑器按钮
		const ePhotoAlbumEditorBtn = [
			{
				text: <>&#xe816; 删除</>,
				className: 'btn-danger album-button iconfont-buttons',
				title: '删除',
				onClick: this.deleteButtonHandle,
			},
			{
				text: <>&#xec09; 保存</>,
				className: 'btn-success album-button iconfont-buttons',
				title: '保存',
				htmlType: 'submit',
				onClick: this.saveButtonHandle,
				style: {
					display: edit ? 'block' : 'none',
				},
			},
			{
				text: <>&#xeabd; 编辑</>,
				className: 'ant-btn-primary album-button iconfont-buttons',
				title: '编辑',
				style: {
					display: edit ? 'none' : 'block',
				},
				onClick: this.setEdit,
			},
		]

		return (
			<React.Fragment>
				<Modal
					title='分享'
					width={800}
					visible={this.state.visible}
					footer={null}
					onCancel={this.handleCancel}>
					<div className='ephoto-modal-content'>
						<Input value={shareUrl} />
						<Button
							type='primary'
							style={{ marginLeft: '10px' }}
							onClick={() => {
								this.copyToClipboard(shareUrl)
								message.success('复制成功')
								this.handleCancel()
							}}>
							点击复制
						</Button>
					</div>
				</Modal>
				<Modal
					title={'编辑文件描述'}
					visible={descriptVisible}
					onOk={this.onOk}
					onCancel={this.onCancel}>
					<Form
						name='basic'
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 16 }}
						initialValues={selectFileData}
						autoComplete='off'
						ref={this.descriptRef}>
						<Form.Item
							label='题名'
							name='topic'
							rules={[{ required: true, message: '请填写文件题名' }]}>
							<Input.TextArea
								placeholder='请输入编研文件题名'
								autoSize={true}
							/>
						</Form.Item>

						<Form.Item label='描述' name='description'>
							<Input.TextArea placeholder='请输入编研文件描述' rows={4} />
						</Form.Item>

						<Form.Item label='描述字体颜色' name='fontColor'>
							<Radio.Group
								onChange={e =>
									this.setState({ radioValue: e.target.value })
								}
								value={this.state.radioValue}>
								<Radio value={'#000000'}>黑色</Radio>
								<Radio value={'#ffffff'}>白色</Radio>
							</Radio.Group>
						</Form.Item>
					</Form>
				</Modal>
				<PubArchive
					getInstance={this.getInstance}
					markName={markName}
					compositeSearchUrl={'/api/dossier/searchDossierList'}
					appendFiles={true}
					dossierId={dossierId}
					setStyle={setStyle || {}}
					// 左边文件表格
					// filesWillWithIn={filesWillWithIn}
					// formItemsValue={nowEPhotoAlbumData}
					// renderBottomButtons={this.renderBottomButtons}
					renderLeftButtons={this.renderLeftButtons}
					clickPhoto={this.clickPhoto}
					pageChange={this.pageChange}
					handleAlbumRecords={this.handleAlbumRecords}
					handleShare={this.handleShare}
					showModal={this.showModal}
					hasDragger={true}
					isrowSelection={isRowSelection} //是否可以行选
					tableColumns={this.tableColumns}
					// 右边主题详情表单
					contentTitle={'主题详情'}
					clickHandler={this.clickTextArea}
					formItems={edit ? null : formItems}
					buttons={ePhotoAlbumEditorBtn}
					formDisabled={!edit}
					isEPhotoAlbum={true}
					formValue={formValue}
				/>
				{previewVisible && (
					<div className='preview-descript' style={{ color: record.fontColor }}>
						<div className='title'>{record.topic}</div>
						<div className='descript'>{record.description}</div>
					</div>
				)}
				{
					<Image
						style={{ display: 'none' }}
						src={`https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
						preview={{
							visible: previewVisible,
							src: previewSrc,
							onVisibleChange: value => {
								this.setState({
									previewVisible: value,
								})
							},
						}}></Image>
				}

				{shareUrl.length > 0 && (
					<div>
						<iframe title='编研分享' src={shareUrl} />
					</div>
				)}

				{/* 翻页 */}
				<Pagination
					className='details-pagination'
					current={totalIndex}
					onChange={page => {
						this.changePage(page)
					}}
					total={total}
					pageSize={1}
					showSizeChanger={false}
				/>
			</React.Fragment>
		)
	}
}

export default EPhotoAlbumDetails
