import { MY_SELECTED_FILES } from '@/Redux/ActionTypes/MyFiles/ActionTypes'
import initialState from '@/Redux/InitialState' // 引入表格公共组件的初始化状态
import _ from 'lodash'

const setMyFiles = (state, action) => {
	let newState = _.cloneDeep(state)
	newState.selectedFiles = action.myFiles
	return newState
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState.myFiles, action) => {
	switch (action.type) {
		case MY_SELECTED_FILES:
			return setMyFiles(state, action)
		default: {
			return state
		}
	}
}
