import { Modal } from 'antd'
import _fetch from '@/Utils/Fetch'
import { appraisalUrl, filesUrl } from '@/Utils/Urls'
import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import ApprasialActionType from '@/Redux/ActionTypes/AppraisalDetails'
import { CookieUtil } from '@/Utils'
// 更新红点函数
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
const { APPRAISAL_DATA } = ApprasialActionType

export const tips = {
	NO_PRE_RECORD: '没有上一项了',
	NO_NEXT_RECORD: '没有下一项了',
	CHOOSE_AT_LEAST_ONE: '请选择至少一项后再进行操作',
	DELETE_SUCCESS: '删除鉴定表成功',
	ALLOW_REVIEW_APPLY_SUCCESS: '已同意该审核鉴定',
	ONLY_VERIFY_CAN_BE_IDENTIFIED: '只有处于待鉴定状态才能进行鉴定',
	ONLY_APPLY_AND_RECORD_CAN_BE_SUBMITED: '只有处于待鉴定才能提交',
	ONLY_APPLY_AND_RECORD_CAN_BE_DELETED: '只有处于待鉴定才能删除',
	VERIFY_APPLY_SUCCESS: '鉴定申请成功',
	SUBMIT_SUCCESS: '鉴定提交成功',
	VERIFY_SUCCESS: '鉴定暂存成功',
}

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

/**
 * 提交申请
 * @param {Number} id 申请id
 */
export const submitVerifyThunk = data => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: data.operate === 2 ? '暂存鉴定申请' : '提交鉴定申请',
			content:
				data.operate === 2
					? '您正在对当前申请进行暂存操作，是否继续？'
					: '您正在对当前申请执行提交操作，提交之后不可更改，是否继续？',
			okType: 'primary',
			okText: '确定',
			cancelText: '取消',
			onOk: () => {
				_fetch({
					url: appraisalUrl.verify,
					data,
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						updataRedDots()
						resolve(res.data.data)
					}
				})
			},
		})
	})

export const addAppraisalDataToRedux = data => {
	return {
		type: APPRAISAL_DATA,
		data: data,
	}
}

// 删除鉴定申请
export const deleteVerifyThunk = ids => () =>
	new Promise((resolve, reject) => {
		Modal.confirm({
			maskClosable: true,
			title: '删除鉴定申请',
			content: '您正在对当前申请进行删除操作，是否继续？',
			okType: 'primary',
			okText: '确定',
			cancelText: '取消',
			onOk: () => {
				_fetch({
					url: appraisalUrl.deleteVerify,
					data: {
						ids: ids,
					},
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						updataRedDots()
						resolve(res.data.data)
					} else {
						reject()
					}
				})
			},
		})
	})

// 查看
export const getVerifyDetailThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: appraisalUrl.getVerifyDetail,
			data: {
				id: id,
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

//编辑
export const updateVerifyApplyThunk = data => () =>
	new Promise(resolve => {
		_fetch({
			url: appraisalUrl.updateVerifyApply,
			data,
			type: 'post',
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

/**
 * 上传文件
 * @param {Object[]} record 案卷或档案（文件）的内容
 */
export const uploadFileThunk = formData => () =>
	new Promise((resolve, reject) => {
		_fetch({
			type: 'post',
			url: filesUrl.upload,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

//下载附件
export const downloadFileThunk = value => () =>
	new Promise((resolve, reject) => {
		let token = JSON.parse(CookieUtil.get('userMessage')).token
		_fetch({
			url: `/api/file/download/${value.fileToken}`,
			headers: {
				Authorization: token,
				'Content-type': 'application/x-www-form-urlencoded',
			},
			type: 'get',
			responseType: 'blob',
		}).then(res => {
			let filename = `${value.fileName}`
			let fileType = decodeURIComponent(res.headers['content-type'])
			let blob = new Blob([res.data], {
				type: fileType,
			})
			let url = window.URL.createObjectURL(blob)
			// 创建一个临时的url指向blob对象
			// 创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
			let a = document.createElement('a')
			a.href = url
			a.download = filename
			// 下载
			a.click()
			// 释放这个临时的对象url
			window.URL.revokeObjectURL(url)
		})
	})

//导出鉴定申请Excel
export const getVerifyDetailExcelThunk = id => () =>
	new Promise((resolve, reject) => {
		let token = JSON.parse(CookieUtil.get('userMessage')).token
		_fetch({
			url: appraisalUrl.getVerifyDetailExcel,
			headers: {
				Authorization: token,
			},
			type: 'get',
			responseType: 'blob',
			params: {
				id: id,
			},
		}).then(res => {
			let filename = `鉴定表${id}详情.xlsx`
			let fileType = decodeURIComponent(res.headers['content-type'])
			let blob = new Blob([res.data], {
				type: fileType,
			})
			let url = window.URL.createObjectURL(blob)
			// 创建一个临时的url指向blob对象
			// 创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
			let a = document.createElement('a')
			a.href = url
			a.download = filename
			// 下载
			a.click()
			// 释放这个临时的对象url
			window.URL.revokeObjectURL(url)
		})
	})

/**
 * 档案鉴定申请
 * @param {Object} fetchData 请求数据
 */
export const verifyApplyThunk = fetchData => () =>
	new Promise((resolve, reject) => {
		Modal.confirm({
			maskClosable: true,
			title: '鉴定申请',
			content: '您正在对当前申请进行鉴定操作，是否继续？',
			okType: 'primary',
			okText: '确定',
			cancelText: '取消',
			onOk: () => {
				_fetch({
					url: appraisalUrl.verifyApply,
					type: 'post',
					data: fetchData,
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						updataRedDots()
						resolve(res.data.data)
					} else {
						reject()
					}
				})
			},
		})
	})
