import { CookieUtil, authUtil } from './index'

export const generateCaptcha = '/api/user/generateCaptcha'

// 登录
export const login = '/api/user/login'

// 获取该用户的侧边栏的接口
export const getSidebarTree = '/api/permission/getSidebarTree'

// 收藏相关接口
export const workStation = {
	addWorkbench: '/api/workbench/addWorkbench',
	deleteWorkbench: '/api/workbench/deleteWorkbench',
	addWorkbenchContent: '/api/workbench/addWorkbenchContent', // 添加文件入收藏夹
	getWorkbenchList: '/api/workbench/getWorkbenchList',
	getWorkbenchContentList: '/api/workbench/getWorkbenchContentList', // 获取收藏夹案卷和文件列表
	deleteWorkbenchContent: '/api/workbench/deleteWorkbenchContent', //移除收藏夹内容
	updateDossier: '/api/dossier/updateDossier', // 更新未归档的案卷
	editWorkbenchStatus: '/api/workbench/editWorkbenchStatus', // 收藏夹状态修改
}
// 案卷相关公用接口
export const dossierUrl = {
	getDossierDetail: '/api/dossier/getDossierDetail', // 获取案卷详情
	getMyDossierList: '/api/dossier/getMyDossierList', // 获取我的著录案卷/文件列表【普通用户】
	getPartDossierList: '/api/dossier/getPartDossierList', // 获取部分案卷/文件列表【部分查看权限用户】
	// 可以通过 dossierUrl.getDossierList 获取案卷/文件列表，已经经过权限处理并返回
	get getDossierList() {
		if (!CookieUtil.permissionList) return ''
		// 获取接口权限列表，并消除其前后的空格避免全等判断失败
		const permissionList = CookieUtil.permissionList.map(permission =>
			permission.trim()
		)
		// 权限高的优先，所以是 Part -> My
		for (let scope of ['Part', 'My']) {
			const permission = `get${scope}DossierList`
			if (permissionList.includes(permission)) return this[permission]
		}
		return '' // 没有则返回空字符
	},
	getDossierType: '/api/type/getDossierType', // 获取案卷类型
	getArchiveList: '/api/archive/getArchiveList', // 获取某案卷下文件列表
	searchDossierList: '/api/dossier/searchDossierList', // 搜索入库案卷
	setNumForArchive: '/api/dossier/setNumForArchive', // 给案卷下的所有档案进行指定序号排序
	getMyStorageDossier: '/api/dossier/getMyStorageDossier', // 获取自己著录的入库的档案
	getInsertArchiveList: '/api/dossier/getInsertArchiveList', // 获取可供组卷文件列表
	printDirectory: '/api/dossier/printDirectory', //导出案卷/文件列表excel【部分查看权限用户】
	generateDossierPdf: '/api/dossier/generateDossierPdf', //导出已归档案卷相关信息
	separateDossier: '/api/dossier/separateDossier', //案卷拆卷
	oneClickChange: '/api/dossier/oneClickChange', // 一键修改状态
	archivePreview: '/api/archive/preview', // 案卷预览
	dragSortting: '/api/dossier/drag', //拖拽排序同步到后端
	exportExcelZip: '/api/export', //导出案卷
}

// 档案（文件）相关公用接口，档案就是文件，案卷就相当于文件夹
export const archiveUrl = {
	getArchiveDetail: '/api/archive/getArchiveDetail', // 获取文件详情
	getArchiveList: '/api/archive/getArchiveList', // 获取某案卷下文件列表
	getDocDetail: '/api/archive/getDocDetail', // 获取文书详情
	multiUpload: '/api/file/multiUpload', // 自动著录上传文件
	exportExcel: '/api/archive/exportModelExcel', // 导出自动著录的excel模板
	exportDetailExcel: '/api/archive/exportDetailExcel', // 导出自动著录功能中的文件详情模板
	autoDescription: '/api/archive/autoDescription', // 根据传入的UUID，对一组文件进行自动著录
	downloadMultipartResult: '/api/file/downloadMultipartResult', // 下载自动著录日志
	getAutoDescriptionDetail: '/api/file/getAutoDescriptionDetail', // 获取指定UUID的著录信息
	claimArchive: '/api/archive/claimArchive',
	logDatail: '/api/log/getArchiveUrlLogListPage',
}

// 借阅申请相关的接口
export const borrowUrl = {
	getAllUseApplyList: '/api/use/getAllUseApplyList', // 获取所有的利用申请列表
	getMyUseApplyList: '/api/use/getMyUseApplyList', // 获取我的利用申请列表
	getMyUseResult: '/api/use/getMyUseResultByUseApplyId', // 根据申请表id获取我的利用申请结果内容
	getUseReview: '/api/use/getUseReview', // 获取审批日志
	getUseReviewDetail: '/api/use/getUseReviewDetail', // 查看申请结果详情
	getUseApplyDetail: '/api/use/getUseApplyDetail',
	recallUseApply: '/api/use/recallUseApply', // 撤回利用申请
	deleteUseApply: '/api/use/deleteUseApply', // 删除利用申请
	useApply: '/api/use/useApply', // 申请利用
	submitUseApply: '/api/use/submitUseApply', // 提交申请
	updateUseApply: '/api/use/updateUseApply', // 更新利用申请表信息
	downloadMyUse: '/api/use/downloadMyUse', // 下载文件
	useApplyDelay: '/api/use/useApplyDelay', // 完成状态的过期档案申请延期
	getUserInfo: '/api/user/getLoginUserMessage', // 获取已登录用户信息
	extendUseApply: '/api/use/extendUseApply', //扩展申请，可以传档案
	getClaimApplyList: '/api/archive/getClaimApplyList', //获取认领申请的表格信息,
	handleApproval: '/api/archive/changeClaimApplyStatus', //修改认领档案申请的状态
}
// 借阅审批的接口
export const myApprovalBorrowUrl = {
	getDepartmentUseApplyList: '/api/use/getDepartmentUseApplyList', // 获取权限下部门的利用申请列表
	useReview: '/api/use/useReview', // 利用申请审核
	distributeFile: '/api/use/distributeFile', // 生产档案/案卷
	getUseReview: '/api/use/getUseReview', // 获取审批日志
	delayReview: '/api/use/delayReview', //下发档案/案卷延期申请的审核
}

export const submitFileUrl = {
	submitToVerify: '/api/archive/submitToVerify', // 审核档案
	submitDossierVerify: '/api/dossier/submitDossierVerify', // 审核案卷
	submitToUser: '/api/archive/submitToUser', //获取指定审核人
	searchUser: '/api/user/searchUser', //获取指定审核人
}

// 归档
export const recordFileUrl = {
	archiveFile: '/api/archive/archiveFile', // 归档档案
	archiveDossier: '/api/dossier/archiveDossier', // 归档案卷
	getDossierDetail: '/api/dossier/getDossierDetail', // 获取案卷详情
	getArchiveDetail: '/api/archive/getArchiveDetail', // 获取文件详情
	getArchiveList: '/api/archive/getArchiveList', // 获取某案卷下的文件列表
	getDossierRollbackRecord: '/api/dossier/getDossierRollbackRecord', //获取案卷最新的回退信息
	getArchiveRollbackRecord: '/api/archive/getArchiveRollbackRecord', //获取档案最新的回退信息
	updateDossierDetail: '/api/dossier/updateDossier', //编辑并更新案卷著录信息
	updateArchiveDetail: '/api/archive/updateArchive', //编辑并更新档案著录信息
	submitToVerify: '/api/archive/submitToVerify', // 审核档案
}
// 已归档
export const archivedUrl = {
	managerDeleteArchive: '/api/archive/managerDeleteArchive', //管理员删除档案
	submitFixApply: '/api/fix/submitFixApply', //提交修正申请
	verifyApply: '/api/verify/verifyApply', // 鉴定申请
}

// 预归档的相关接口
export const preArchivedUrl = {
	rollbackDossier: '/api/dossier/rollbackDossier', // 案卷回退
	managerRollbackDossier: '/api/dossier/managerRollbackDossier', // 管理员回退案卷
	managerRollbackArchive: '/api/archive/managerRollbackArchive', // 管理员回退档案
	storageDossier: '/api/dossier/storageDossier', // 案卷入库
	storageArchive: '/api/archive/storageArchive', // 档案入库
	setLinkArchiveCertain: '/api/archive/setLinkArchiveCertain', //将文档设置为确定关联状态
}
//新增案卷的相关接口
export const addArchivesUrl = {
	// getDepartmentTree: '/api/department/getDepartmentTree', // 获取部门树
	getFondsIdentifier: '/api/fondsIdentifier/getFondsIdentifier', // 获取全宗树
	getDossierType: '/api/type/getDossierType', // 获取案卷类型
	insertDossier: '/api/dossier/insertDossier', // 新增案卷
	packArchive: '/api/archive/packArchive', //档案组卷
	getDossierMetadataStructTree: '/api/dossier/getDossierMetadataStructTree', //获取档案元数据结构
}

// 修正审批相关接口
export const correctApprovalUrl = {
	getFixApplyList: '/api/fix/getFixApplyList', // 获取修正申请列表
	verifyApply: '/api/fix/verifyApply', // 档案修正审核
	recallApply: '/api/fix/recallApply', // 档案修正撤回
	deleteFixApply: '/api/fix/deleteFixApply', // 删除修正申请
	getVerifyDetail: '/api/fix/getFixVerifyDetail', // 获取修正审批详情列表
}

// 与部门相关接口
export const departmentUrl = {
	getDepartmentTree: '/api/department/getDepartmentTree', // 获取部门树
	addDepartment: '/api/department/addDepartment', // 新增部门
	deleteDepartment: '/api/department/deleteDepartment', // 删除部门
	updateDepartment: '/api/department/updateDepartment', // 更新部门信息
	getDepartmentList: '/api/department/getDepartmentList', // 【分页】获取部门列表
	getAllDepartmentTree: '/api/department/getAllDepartmentTree', // 获取所有部门树
	getAllDepartmentNameTree: '/api/department/getAllDepartmentNameTree', // 游客获取部门树
	updateDepartmentTree: '/api/department/updateDepartmentTree', // 更新部门树
}

// 与档案相关接口
export const filesUrl = {
	pdfConvert: '/api/pdfConverter/convertToPdf', //转换并下载为pdf
	upload: '/api/file/upload', // 上传文件
	uploadFace: '/api/faceFile/upload', // 上传需要人脸识别的文件
	visitorUpload: '/api/file/visitorUpload', // 游客上传文件
	visitorFileUpload: '/api/file/visitorFileUpload', //游客断点续传
	addFile: '/api/archive/addArchive', // 新增文件
	getFondsIdentifier: '/api/fondsIdentifier/getFondsIdentifier', // 获取全宗号
	getDossierType: '/api/type/getDossierType', // 获取类别号
	getDepartmentTree: '/api/department/getDepartmentTree', // 获取部门树
	getFileMetadataStructTree: '/api/archive/getFileMetadataStructTree', // 获取元数据结构树
	addTemporaryArchive: '/api/archive/addTemporaryArchive', // 新增临时档案
	selectTemporaryArchive: '/api/archive/selectTemporaryArchive', // 查询临时档案
	deleteTemporaryArchive: '/api/archive/deleteTemporaryArchive', // 删除临时档案
	changeTemporaryArchiveToNormalArchive:
		'/api/archive/changeTemporaryArchiveToNormalArchive', // 转换临时档案为新档案
	replaceArchiveFaces: '/api/face/replaceArchiveFaces', //替换档案人脸信息对应关系
	fillTemporaryArchive: '/api/archive/fillTemporaryArchive',
	getArchiveLog: '/api/archive/getArchiveLog', // 获取档案状态变更记录
	getDossierLog: '/api/dossier/getDossierLog', // 根据案卷id获取案卷状态变更记录
	fileUpload: '/api/file/fileUpload', // 文件上传（断点续传）
	getChunk: '/api/file/getChunk', // 获取当前文件分片数量
	checkIsUploaded: '/api/file/checkIsUploaded', // 检查上传的文件是否为曾经在档案系统下载的文件
	updateAttachment: '/api/archive/updateAttachment', // 支持档案、案卷、临时档案的附件的增加和删除
}

// 角色管理相关接口
export const roleManagerUrl = {
	getRole: '/api/role/getRoleByName', // 搜索角色
	getAllPermission: '/api/permission/getAllPermission', // 获取权限列表
	addRole: '/api/role/addRole', // 新增角色
	deleteRole: '/api/role/deleteRole', // 删除角色
	authorize: '/api/role/authorize', // 给角色授权
	getFullSidebarTree: '/api/permission/getFullSidebarTree', // 获取完整树状侧边栏
	authorizeBySidebar: '/api/role/authorizeBySidebar', // 通过侧边栏给角色授权
	getSidebarIdsByRoleId: '/api/role/getSidebarIdsByRoleId', // 根据角色id获取侧边栏id列表
	listDossierTypeByRoleId: '/api/roleType/listDossierTypeByRoleId', //获取角色可访问档案类型列表
	updateBatchType: '/api/roleType/updateBatchType', //批量更改可访问档案类型
}

//健康检查相关接口
export const HealthUrl = {
	getServiceInstances: '/api/system/getServiceInstances', //获取健康检查用户姓名和健康状态
}

// 用户管理相关接口
export const userManagerUrl = {
	getRole: '/api/role/getRole', // 获取角色
	addUser: '/api/user/addUser', // 新增用户
	editUser: '/api/user/updateUser', // 修改用户信息
	getDepartmentList: '/api/department/getDepartmentList', // 获取部门列表
	getUserList: '/api/user/getUserList', // 获取用户列表
	deleteUser: '/api/user/deleteUser', // 删除用户
	exportUserTemplate: '/api/user/exportUserTemplate', // 获取导入用户的excel的模板
	importUser: '/api/user/importUser', // 导入用户excel文件
	exportUser: '/api/user/exportUser', // 导出用户excel表
	addUserRole: '/api/role/addUserRole', // 给用户添加角色
	deleteUserRole: '/api/role/deleteUserRole', // 删除用户的角色
	getUserRole: '/api/role/getUserRole', // 获取用户的角色列表
	updatePassword: '/api/user/updatePassword', // 修改用户密码
	updateAutoRecognition: '/api/user/updateAutoRecognition', //全局配置，批量注入是否自动人脸识别
}

// 全宗管理
export const fondsManagerUrl = {
	getFondsIdentifier: '/api/fondsIdentifier/getFondsIdentifierList', // 获取全宗列表
	deleteFondsIdentifier: '/api/fondsIdentifier/deleteFondsIdentifier', // 删除全宗号
	updateFondsIdentifier: '/api/fondsIdentifier/updateFondsIdentifier', // 更新
	addFondsIdentifier: '/api/fondsIdentifier/addFondsIdentifier',
}

//数据库字段管理
export const dataTableManagerUrl = {
	getAllTable: '/api/table/all', //获取全部表名
	getTableColumn: '/api/table/getTableColimn', //获取对应表的所有列
	createTable: '/api/table/createTable', //新建表
	deleteTableColumn: '/api/table/deleteTableField', //删除列
	renameTableColumn: '/api/table/updateField', //修改列名
	changeColumnDataType: '/api/table/modify-column-data-type', //修改列的数据类型
	addColumn: '/api/table/add-column', //添加列
}

//敏感词管理
export const sensitiveController = {
	changeSensitiveWord: '/api/sensitive/modify', //修改敏感词
	addSensitiveWord: '/api/sensitive/add', //新增敏感词
	deleteSensitiveWord: '/api/sensitive/delete', //删除敏感词
	getAllSensitiveWord: '/api/sensitive/selectGroupKey', //返回所有敏感词
	getPartSensitiveWord: '/api/sensitive/selectKey', //返回指定敏感词
}

//元数据管理
export const metaDataManagementUrl = {
	addMetaData: '/api/meta/add',
	deleteMetaData: '/api/meta/delete',
	changeMetaData: '/api/meta/modify',
	getMetaData: '/api/meta/getMetadata',
}

//参数管理
export const paramsManagementUrl = {
	addParam: '/api/param/add',
	deleteParam: '/api/param/delete',
	changeParam: '/api/param/modify',
	getParam: '/api/param/getParam',
}

//出错记录
export const exceptionLogManagementUrl = {
	getExceptionLog: '/api/log/getSysExceptionLogPage',
}

// 与修正申请相关接口
export const fixApply = {
	getFixApplyList: '/api/fix/getFixApplyList', // 获取修正申请列表
	getMyFixApplyList: '/api/fix/getMyFixApplyList', // 获取我的修正申请列表
	recallApply: '/api/fix/recallApply', // 撤回申请
	deleteFixApply: '/api/fix/deleteFixApply', // 删除修正申请
	submitFixApply: '/api/fix/submitFixApply', // 提交修正申请
	updateFixApply: '/api/fix/updateFixApply', // 修改修正申请
	fixApply: '/api/fix/fixApply', // 档案修正申请
	getMyDossierList: '/api/dossier/getMyDossierList', // 获取我的著录案卷/文件列表
	getArchiveList: '/api/archive/getArchiveList', // 获取某案卷下文件列表
	getArchiveDetail: '/api/archive/getArchiveDetail', // 获取文件详情
	getDossierDetail: '/api/dossier/getDossierDetail', // 获取案卷详情
}

//鉴定申请相关接口
export const appraisalUrl = {
	getMyVerifyList: '/api/verify/getMyVerifyList', // 用户获取档案鉴定列表
	getAllVerifyList: '/api/verify/getAllVerifyList', // 管理员获取档案鉴定列表
	getVerifyDetail: '/api/verify/getVerifyDetail', // 获取档案鉴定明细列表
	deleteVerify: '/api/verify/deleteVerify', // 删除鉴定申请
	verify: '/api/verify/verify', // 鉴定执行
	verifyApply: '/api/verify/verifyApply', // 档案鉴定申请(提出鉴定)
	getVerifyDetailExcel: '/api/verify/getVerifyDetailExcel', //获取鉴定明细excel
	updateVerifyApply: '/api/verify//updateVerifyApply', //修改编辑状态的鉴定
}
// 与用户相关的接口
export const userUrl = {
	getUserList: '/api/user/getUserList', // 获取用户列表
	searchPartialUser: '/api/user/searchPartialUser', // 搜索部分用户
	getUserInfo: '/api/user/getLoginUserMessage', // 获取已登录用户信息
}
// 获取红点的接口
export const redDotsUrl = {
	getMyRedDots: '/api/red/getMyRedDots', // 获取普通用户的红点
	getRedDots: '/api/red/getRedDots', // 获取用户的红点
	get getAllRedDots() {
		// 权限高的优先，所以是 All -> My
		for (let scope of ['', 'My']) {
			const permission = `get${scope}RedDots`
			if (authUtil.hasAuth(permission)) return this[permission]
		}
		return '' // 没有则返回空字符
	},
}
// 与档案类型管理相关的接口
export const archiveTypeUrl = {
	getDossierType: '/api/type/getDossierType', // 获取案卷类型
	addDossierType: '/api/type/addDossierType', // 新增案卷类型
	deleteDossierType: '/api/type/deleteDossierType', // 删除案卷类型
	updateDossierType: '/api/type/updateDossierType', // 修改档案类型
	searchDossierType: '/api/type/searchDossierType', //根据条件模糊搜索案卷类型
	updateDossierTypeTree: '/api/type/updateDossierTypeTree', //更新档案类型树
	updateRoleType: '/api/roleType/update', //更新主管角色
	batchUpdateVerify: '/api/type/batchUpdateVerify', // 批量修改档案类型审核角色
}
// 删除档案和案卷相关接口
export const toDeleteUrl = {
	managerDeleteDossier: '/api/dossier/managerDeleteDossier', // 管理员删除案卷
	userDeleteDossier: '/api/dossier/userDeleteDossier', // 用户删除案卷
	managerDeleteArchive: '/api/archive/managerDeleteArchive', // 管理员删除档案
	userDeleteArchive: '/api/archive/userDeleteArchive', // 用户删除未归档档案
}
// 回收站相关接口
export const recycleBinUrl = {
	userResortDossier: '/api/dossier/userResortDossier', //用户还原案卷
	managerResortDossier: '/api/dossier/managerResortDossier', //管理员还原案卷
	userRestoreArchive: '/api/archive/userRestoreArchive', //用户还原档案
	managerRestoreArchive: '/api/archive/managerRestoreArchive', //管理员还原档案
	managerDestroyArchive: '/api/archive/managerDestroyArchive', // 管理员销毁档案（清空回收站里的档案）
	userDestroyArchive: '/api/archive/userDestroyArchive', // 用户销毁档案（清空回收站里的档案）
	managerDestroyDossier: '/api/dossier/managerDestroyDossier', // 销毁案卷（管理员）
	userDestroyDossier: '/api/dossier/userDestroyDossier', // 销毁案卷(用户)
	emptyRecycleBin: '/api/archive/emptyRecycleBin', // 清除档案和案卷回收站，即清除处于删除状态的档案和案卷
}

// 识别相关接口
export const mapUrl = {
	faceInformationEntry: '/api/face/faceInformationEntry', //录入人脸及其对应信息
	faceRecognition: '/api/face/faceRecognition', // 识别人脸信息
	faceRecognitionByArchive: '/api/face/faceRecognition', //识别图片中的人脸信息并建立档案和人脸信息的关系
	faceRecognitionByVideo: '/api/face/faceRecognitionByVideo', // 视频人脸识别
	imageInformationEntry: '/api/image/imageInformationEntry', // 录入图片信息
	imageRecognition: '/api/image/imageRecognition', // 识别图片信息
	psTraceRecognition: '/api/image/psTraceRecognition', // 识别是否有ps痕迹
	extraInformationRecognition: '/api/image/extraInformationRecognition', // 识别照片经纬度
	getInformationByUrl: '/api/archive/getInformationByUrl', // 根据url识别组卷信息
	getFaceList: '/api/face/getFaceList', // 查询人脸信息
	selectFaceByName: '/api/face/selectFaceByName', // 根据姓名精确查询人脸信息
	faceDelete: '/api/face/faceDelete', // 人脸信息删除接口
	getImageMetadata: '/api/image/getImageMetadata', // 识别图片元数据
	mergeFace: '/api/face/mergeFace', // 合并人脸
	compareToExistedUser: '/api/face/compareToExistedUser', // 比较已有的人脸和图片中人脸的相似度
	compareWithImages: '/api/face/compareWithImages', // 比较两张图片中人脸的相似度
	faceReplace: '/api/face/faceReplace', // 替换人脸图片
	addExtraFace: '/api/face/addExtraFace', // 添加额外人脸图片
	faceFeatureDelete: '/api/face/faceFeatureDelete', // 批量删除人脸特征信息
	getFaceDetailList: '/api/face/getFaceDetailList', // 根据id搜人脸
	getExistedFace: '/api/face/getExistedFace', // 获取已经存在的人脸
	faceUpload: '/api/faceFile/upload', //上传人脸接口
	faceServiceRecognition: '/api/face/faceServiceRecognition', //识别人脸信息
	downloadFocusNameExcel: '/api/file/downloadFocusName', //导出人脸重点对象excel
	uploadFocusNameExcel: '/api/file/uploadFocusName', //导入人脸重点对象excel
}

// 电子相册相关接口
export const ePhotoAlbum = {
	applyShareFiles: '/api/archive/applyShareFiles', // 申请分享图片或视频
	getShareFiles: '/api/archive/getShareFiles', // 获取文件下载URL
	getThemeTree: '/api/studying/getThemeTree', // 获取编研主题树
	addTheme: '/api/studying/addTheme', //添加编研主题
	updateTheme: '/api/studying/updateTheme', // 修改编研主题
	deleteTheme: '/api/studying/deleteTheme', // 删除编研主题
	searchTheme: '/api/studying/searchTheme', // 查找编研主题
	getTheme: '/api/studying/getTheme', // 获取编研主题
	getUserById: '/api/user/getUserById', // 根据作者id获取作者名
	addFile: '/api/studying/addFile', // 添加编研文件
	updateFile: '/api/studying/updateFile', // 修改编研文件
	deleteFile: '/api/studying/deleteFile', // 删除编研文件
	changeFilePriority: '/api/studying/changeFilePriority', // 修改编研文件在主题中的顺序
	applyShareStudyingFiles: '/api/studying/applyShareStudyingFiles', // 申请分享编研文件
	getShareStudyingFiles: '/api/studying/getShareStudyingFiles', // 外网获取分享的编研文件
	pull: '/api/richTextStudying/pull', // 获取编辑器文本
	save: '/api/richTextStudying/save', // 保存编辑器文本
	publishRichText: '/api/studying/publishRichText', // 发布推文
	getPublishedRichText: '/api/studying/getPublishedRichText', //获取已发布推文
}

// 统计相关接口
export const statisticsUrl = {
	queryDayCount: '/api/data/queryDayCount', //获取当日档案，案卷数量信息
	queryUseCount: '/api/data/queryUseCount', // 获取过去一年借阅案卷、档案数量
	queryCount: '/api/data/queryCount', // 获取过去一年档案、案卷数量（折线图）
	queryCountByType: '/api/data/queryCountByType', // 获取各个类型的档案的数据
	queryIncrease: '/api/data/queryIncrease', // 获取最近12个月新增档案和案卷数据
	getIncreaseCapacity: '/api/data/getIncreaseCapacity', // 获取最近12个月新增容量
}

// 档案号配置
export const serialNumberUrl = {
	downloadSerialNumberTemplate: '/api/user/downloadSerialNumberTemplate', // 下载档案号配置文件模板
	configureSerialNumber: '/api/user/configureSerialNumber', // 上传档案号配置文件
	getSerialNumberConfig: '/api/user/getSerialNumberConfig',
	getServerConfig: '/api/user/getServerConfig', //获取服务器个性化设置
	updateServerConfig: '/api/user/updateServerConfig', //修改服务器个性化设置
	getAllSystemConfig: '/api/user/getAllSystemConfig', //获取所有系统配置
	updateSystemConfigByKey: '/api/user/updateSystemConfigByKey', //通用的更新全局系统配置
}

//* 刷新token用的接口
export const refreshToken = '/api/user/refreshToken'

export const collectedFileUrl = {
	getCollectedFileType: '/api/type/getCollectedFileType', // 获取资料类型
	searchCollectedFileType: '/api/type/searchCollectedFileType', // 查询资料类型
	addCollectedFileType: '/api/type/addCollectedFileType', // 增加资料类型
	addCollectedFile: '/api/collectedFile/addCollectedFile', // 增加资料文件
	downloadCertificate: '/api/collectedFile/downloadCertificate', //生成捐赠证书
	getCollectedFileList: '/api/collectedFile/getCollectedFileList', // 指定类别号获取资料列表/获取全部资料文件
	getCollectedFileDetail: '/api/collectedFile/getCollectedFileDetail', // 获取资料文件详情
	changeToArchive: '/api/collectedFile/changeToArchive', // 将资料文件转为档案
	deleteCollectedFile: '/api/collectedFile/deleteCollectedFile', // 删除资料文件
	updateCollectedFile: '/api/collectedFile/updateCollectedFile', // 修改资料文件
	getAllTheme: '/api/collectedFile/getAllTheme', //获取所有有效主题
	addNewTheme: '/api/collectedFile/addNewTheme', //新增主题
	getMyTheme: '/api/collectedFile/getMyTheme', //获取我所在部门或者子部门人员发布的主题的列表
	searchCollectedFile: '/api/collectedFile/searchCollectedFile', //条件搜索资料文件
	getQrCoder: '/api/file/getQrCoder', //获取主题二维码
	getThemeDetail: '/api/collectedFile/getThemeDetail', //获取主题详情
	getExcelAndZip: '/api/collectedFile/getExcelAndZip', //导出资料
	searchTheme: '/api/collectedFile/searchTheme', //条件搜索主题
	deleteTheme: '/api/collectedFile/deleteTheme', //删除主题
	setNumForTheme: '/api/collectedFile/setNumForTheme', //置顶主题
	cancelTopTheme: '/api/collectedFile/cancelTopTheme', //取消主题置顶
}

// 获取用户关联的文档
export const myArchiveUrl = {
	getMyFaceInformation: '/api/archive/getMyFaceInformation',
	getMyArchiveDetail: '/api/archive/getMyArchiveDetail',
}

//新媒体管理的接口
export const urlToArchiveManagement = {
	addMediaJob: '/api/job/addMediaJob', //增加新媒体任务
	updateMediaJob: '/api/job/updateMediaJob', //更新新媒体任务
	deleteMediaJob: '/api/job/deleteMediaJob', //批量删除新媒体任务
	getMediaJob: '/api/job/getMediaJob', //获取新媒体任务列表
	testMediaUrl: '/api/job/testMediaUrl', //测试url合不合理
	cancelOrStartJob: '/api/job/cancelOrStartJob', //修改爬取状态
	searchSpecialUser: '/api/user/searchSpecialUser', //搜索用户
	registryClient: '/api/job/registryClient', //客户端注册
	checkMediaJob: '/api/job/checkMediaJob', //客户端检查频道是否可用
}

// 区块链相关接口
export const blockchainUrl = {
	verify: 'http://10.21.23.163:21983/chain/verify', // 档案验证
	getHistory: 'http://10.21.23.163:21983/chain/getHistory', // 获取档案历史
	cochainList: 'http://10.21.23.163:21983/chain/cochainList', // 批量日志上链
	cochain: 'http://10.21.23.163:21983/chain/cochain', // 日志上链
}
