import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { addRoleThunk } from '../ActionCreators'
import { connect } from 'react-redux'
const formLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 10 },
}
// 表单项的单独配置，优先级高于表单项的统一配置
const formItemLayout = {
	labelCol: { span: 5 },
	wrapperCol: { span: 19 },
}

// PubForm 表单项配置数组
let formItems = [
	[
		{
			type: 'Input',
			name: 'name',
			label: '角色名称',
			layout: formItemLayout,
			placeholder: '请输入名字',
			span: 24,
			rules: [
				{
					required: true,
					message: '该项不能为空',
				},
				{
					max: 20,
					message: '最多输入20个字',
				},
			],
		},
	],
	[
		{
			type: 'TextArea',
			name: 'description',
			label: '角色描述',
			layout: formItemLayout,
			rows: 5,
			span: 24,
			rules: [
				{
					required: true,
					message: '该项不能为空',
				},
				{
					max: 255,
					message: '最多输入255个字',
				},
			],
		},
	],
]
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	addRole: (...rest) => dispatch(addRoleThunk(...rest)), // 新增角色
})
@connect(mapStateToProps, mapDispatchToProps)
class AddRoleModal extends Component {
	state = {
		visible: false,
		title: '添加角色',
	}
	render() {
		const { title /* buttonList */ } = this.state
		const { addRole, reflashTable } = this.props
		return (
			<PubModalForm
				title={title}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				onOk={(formData, { finish, hide }) => {
					// 如果传入了 buttonList 则不用传 onOk，它是默认的两个按钮的提交事件
					addRole(formData, reflashTable)
					hide()
				}}
				okText='保存' // 若没有buttonList时选传， 默认值为提交
				ref={this.props.modalRef} // 若隔层由外层通过props传递
				width={500} // 默认值900
				formProps={{
					// 表单的配置项
					name: 'PubFormDemo',
					formItems,
					layout: formLayout,
				}}
			/>
		)
	}
}
export default AddRoleModal
