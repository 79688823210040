/* eslint-disable import/no-anonymous-default-export */
import React, { Component } from 'react'
import { Select, TreeSelect } from 'antd'
import { injectUnmount } from '@/Utils'
import _fetch from '@/Utils/Fetch'
import { treeUtil } from '@/Utils'
import { PureComponent } from 'react'
import { debounce } from 'lodash'
const { Option } = Select
/**
 * 最基本的下拉框
 * @param {Object} config formItem的配置对象
 */
export const createSelect = config => {
	class ISelect extends Component {
		triggerChange = value => {
			let { onChange } = this.props
			if (onChange) onChange(value)
		}

		render() {
			const { placeholder, options, ...otherConfig } = config
			const { disabled, value } = this.props
			return (
				<Select
					placeholder={placeholder || ''}
					allowClear
					options={options}
					disabled={disabled}
					bordered={!disabled}
					onChange={this.triggerChange}
					value={value}
					{...otherConfig}
				/>
			)
		}
	}
	return <ISelect />
}

/**
 * 该高阶组件是一个异步下拉框, 将formItem中配置的异步请求函数的结果作为下拉框的选项列表
 * @param {*} config formItem的配置对象
 * @param {*} formRef form表单的ref.current(包含表单的所有方法)
 */
export const createAsyncSelect = (config, formRef) => {
	const {
		mode = null, // 模式
	} = config
	@injectUnmount
	class AsyncSelect extends Component {
		constructor(props) {
			super(props)
			this.state = {
				loading: true, // 异步加载过程中loading
				options: [], // 下拉框的选项
			}
		}

		/**
		 * 此处用来异步获取下拉框的列表并设置初始值
		 * 如果有传入 initialValue 则初始值为 initialValue
		 */
		componentDidMount() {
			const {
				initialValue,
				name,
				ajaxConfig, // 新版异步请求配置对象
				fetchConfig, // 异步请求配置对象
				formatDataFunc, // 将请求到的数据转换为 options 格式的函数
				changeDataFunc,
			} = config
			if (changeDataFunc) {
				throw new Error(
					'现在 PubForm 的 AsyncSelect 的 changeDataFunc 配置已经改名为 formatDataFunc，请跟进修改'
				)
			}
			if (fetchConfig) {
				throw new Error(
					'现在 PubForm 的 AsyncSelect 的 chanfetchConfig经改名为 formajaxConfig修改'
				)
			}
			_fetch(ajaxConfig).then(async res => {
				if (res && res.data.code === 200) {
					// 一般情况
					let data = res.data.data
					// 将请求数据转换为 [{ label: x, value: y }, { label: m, value: n }] 的格式
					let options = formatDataFunc ? await formatDataFunc(data) : data
					this.setState(
						{
							options,
							loading: false,
						},
						() => {
							// 如果有传入初始值(initialValue)则在获取后台数据后将该项的值设置为默认值
							initialValue &&
								formRef.current.setFieldsValue({ [name]: initialValue })
						}
					)


				}
			})
		}

		/**
		 * 遵循 antd 自定义组件的要求，
		 * 需要将修改后的 value 传入 antd 的 FormItem 所赋予的 onChange 事件中，得以被管理
		 * @param {any} value 修改后的表单项的值
		 */
		triggerChange = value => {
			let { onChange } = this.props
			if (onChange) onChange(value)
		}

		render() {
			const { value, disabled } = this.props
			return (
				<Select
					loading={this.state.loading}
					placeholder={config.placeholder || ''}
					allowClear
					options={this.state.options}
					value={value}
					onChange={this.triggerChange}
					disabled={disabled}
					bordered={!disabled}
					mode={mode}
					className={`${config.propsClass}`} // 配置的class类名
				/>
			)
		}
	}

	return <AsyncSelect />
}

/**
 * 普通的树形下拉框
 * @param {*} config formItem的配置对象
 * @param {*} formRef form表单的ref.current(包含表单的所有方法)
 */
export const createTreeSelect = (config, formRef) => {
	const {
		treeDefaultExpandAll = true, // 默认展开所有节点，可以传入来不展开
		treeData,
		placeholder = '',
		...otherConfig
	} = config
	return (
		<TreeSelect
			placeholder={placeholder}
			allowClear
			treeDefaultExpandAll={treeDefaultExpandAll}
			treeData={treeData}
			{...otherConfig}
			virtual={false}
		/>
	)
}

/**
 * 该高阶组件是一个异步树形下拉框, 将formItem中配置的异步请求函数的结果作为下拉框的选项列表
 * @param {*} config formItem的配置对象
 * @param {*} formRef form表单的ref.current(包含表单的所有方法)
 */
export const createAsyncTreeSelect = (config, formRef) => {
	const {
		treeDefaultExpandAll = true, // 默认展开所有节点，可以传入来不展开
		placeholder = '',
		multiple = false,
	} = config
	@injectUnmount
	class AsyncTreeSelect extends Component {
		constructor(props) {
			super(props)
			this.state = {
				loading: true, // 异步加载过程中loading
				treeData: [], // 下拉框的选项
			}
		}

		/**
		 * 此处用来异步获取下拉框的列表并设置初始值
		 * 如果有传入 initialValue 则初始值为 initialValue
		 */
		componentDidMount() {
			const {
				initialValue,
				name,
				ajaxConfig, // 新版异步请求配置对象
				fetchConfig, // 异步请求配置对象
				formatDataFunc, // 将请求到的数据转换为 antd treeData 格式的函数
				changeDataFunc,
			} = config
			if (changeDataFunc) {
				throw new Error(
					'现在 PubForm 的 AsyncTreeSelect 的 changeDataFunc 配置已经改名为 formatDataFunc，请跟进修改'
				)
			}
			if (fetchConfig) {
				throw new Error(
					'现在 PubForm 的 AsyncTreeSelect 的 fetchConfig 配置已经改名为 ajaxConfig，请跟进修改'
				)
			}
			_fetch(ajaxConfig).then(async res => {
				if (res && res.data.code === 200) {
					let data = res.data.data
					/**
					 * 将请求数据转换为antd treeData 的格式： { label, value }[]，
					 * 如果有配置 formatDataFunc 则调用它，如果无该配置则使用默认从后台拿到的数据
					 * 如：treeData: [
					 *   {
					 *     title: 'Node1',
					 *     value: '0-0',
					 *     children: [
					 *       {
					 *         title: 'Child Node1',
					 *         value: '0-0-1',
					 *       },
					 *       {
					 *         title: 'Child Node2',
					 *         value: '0-0-2',
					 *       },
					 *     ],
					 *   },
					 *   {
					 *     title: 'Node2',
					 *     value: '0-1',
					 *   },
					 * ]
					 */
					let treeData = formatDataFunc
						? await formatDataFunc(data, treeUtil.changeKeys)
						: data
					this.setState(
						{
							treeData,
							loading: false,
						},
						() => {
							// 如果有传入初始值(initialValue)则在获取后台数据后将该项的值设置为默认值
							initialValue &&
								formRef.current.setFieldsValue({ [name]: initialValue })
						}
					)
				}
			})
		}

		/**
		 * 遵循 antd 自定义组件的要求，
		 * 需要将修改后的 value 传入 antd 的 FormItem 所赋予的 onChange 事件中，得以被管理
		 * @param {any} value 修改后的表单项的值
		 */
		triggerChange = value => {
			let { onChange } = this.props
			if (onChange) onChange(value)
			if (config.onChange) config.onChange(value)
		}

		render() {
			const { value, disabled } = this.props
			const { loading, treeData } = this.state
			return (
				<TreeSelect
					loading={loading}
					placeholder={placeholder}
					allowClear
					treeDefaultExpandAll={treeDefaultExpandAll}
					treeData={treeData}
					value={value}
					style={config.style}
					onChange={this.triggerChange}
					disabled={disabled}
					bordered={!disabled}
					multiple={multiple}
					className={`${config.propsClass} search-select`} // 配置的class类名
					virtual={false}
					dropdownMatchSelectWidth={false}
				/>
			)
		}
	}
	return <AsyncTreeSelect />
}

// TODO: 自动回填多选下拉框
export const createBackfillAsyncSelect = (config, formRef) => {
	class BackfillSelect extends PureComponent {
		handleChange(value) { }

		render() {
			return (
				<Select
					mode='multiple'
					style={{ width: '100%' }}
					placeholder='select one country'
					defaultValue={['china']}
					onChange={this.handleChange}
					optionLabelProp='label'>
					<Option value='china' label='China'>
						<div className='demo-option-label-item'>
							<span role='img' aria-label='China'>
								🇨🇳
							</span>
							China (中国)
						</div>
					</Option>
					<Option value='usa' label='USA'>
						<div className='demo-option-label-item'>
							<span role='img' aria-label='USA'>
								🇺🇸
							</span>
							USA (美国)
						</div>
					</Option>
				</Select>
			)
		}
	}
	return <BackfillSelect />
}

/**
 * 该高阶组件是一个异步搜索下拉框, 将formItem中配置的异步请求函数的结果作为下拉框的选项列表
 * 搜索和远程数据相结合
 * @param {*} config formItem的配置对象
 * @param {*} formRef form表单的ref.current(包含表单的所有方法)
 */
export const createAsyncSearchSelect = (config, formRef) => {
	const {
		placeholder = '',
		wait = 300, // 防抖间隔，默认为 300 ms
	} = config
	@injectUnmount
	class AsyncSearchSelect extends Component {
		state = {
			loading: false, // 异步加载过程中loading
			options: [], // 下拉框的选项
		}

		// 防抖发送请求的函数
		fetch = debounce(value => {
			const {
				ajaxConfig, // 新版异步请求配置对象
				changeSearchLimit = data => data, // 更改请求参数的方法
				formatDataFunc = data => data, // 将请求到的数据转换为 options 格式的函数
			} = config
			this.setState(
				{
					loading: true,
				},
				async () => {
					// 初始请求数据
					ajaxConfig['data'] = await changeSearchLimit(value)
					let res = await _fetch(ajaxConfig)
					if (res && res.data.code === 200) {
						let data = res.data.data
						// 将请求数据转换为 [{ label: x, value: y }, { label: m, value: n }] 的格式
						this.setState({
							options: await formatDataFunc(data),
							loading: false,
						})
					}
				}
			)
		}, wait)

		// 输入时触发搜索方法
		handleSearch = value => {
			if (!value) return this.setState({ data: [] })
			this.fetch(value)
		}

		/**
		 * 遵循 antd 自定义组件的要求，
		 * 需要将修改后的 value 传入 antd 的 FormItem 所赋予的 onChange 事件中，得以被管理
		 * @param {any} value 修改后的表单项的值
		 */
		triggerChange = value => {
			let { onChange } = this.props
			onChange && onChange(value)
		}

		render() {
			const { value, disabled } = this.props
			const { loading, options } = this.state
			return (
				<Select
					loading={loading}
					showSearch
					allowClear
					value={value}
					placeholder={placeholder}
					disabled={disabled}
					onSearch={this.handleSearch}
					onChange={this.triggerChange}
					defaultActiveFirstOption={false} // 是否默认高亮第一个选项
					showArrow={false} // 是否显示下拉小箭头
					filterOption={false} // 是否根据输入项进行筛选
					notFoundContent={null} // 当下拉列表为空时显示的内容
					options={options}
				/>
			)
		}
	}
	return <AsyncSearchSelect />
}
