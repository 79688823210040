import React, { Component } from 'react'
import { Modal, Image as AntdImage, Button } from 'antd'
import { connect } from 'react-redux'
import { UndoOutlined, RedoOutlined } from '@ant-design/icons'
import {
	createFileChunk,
	getFileMd5,
	uploadFile,
	updateRecordThunk,
	addTemporaryArchiveThunk,
	selectTemporaryArchiveThunk,
} from './ActionCreators'
import { injectUnmount } from '@/Utils'
import './index.less'

const mapStateToProps = state => ({ selectedKey: state.slideBar.selectedKey })
const mapDispatchToProps = dispatch => ({
	addTemporaryArchive: (...rest) => dispatch(addTemporaryArchiveThunk(...rest)), // 新建临时档案
	selectTemporaryArchive: (...rest) => dispatch(selectTemporaryArchiveThunk(...rest)), // 查询临时档案
	updateRecord: (...rest) => dispatch(updateRecordThunk(...rest)), //著录编辑
})

@connect(mapStateToProps, mapDispatchToProps)
@injectUnmount
class RotateImage extends Component {
	state = {
		isRotate: false,
		rotateDeg: 0,
		fileToken: '/api/file/download/' + this.props.fileData.fileToken,
	}

	setRotate = () => {
		this.setState({
			isRotate: !this.state.isRotate,
		})
	}

	handleCancel = () => {
		this.setRotate()
		this.setState({
			fileToken: '/api/file/download/' + this.props.fileData.fileToken,
		})
	}

	rotateLeft = () => {
		this.rotateImg(false, -90)
	}

	rotateRight = () => {
		this.rotateImg(false, 90)
	}

	rotateImg = (isUpload, rotateDeg) => {
		return new Promise(resolve => {
			const img = new Image()
			img.src = this.state.fileToken
			let canvas = document.createElement('canvas')
			img.onload = () => {
				let ctx = canvas.getContext('2d')
				canvas.width = rotateDeg ? img.naturalHeight : img.naturalWidth
				canvas.height = rotateDeg ? img.naturalWidth : img.naturalHeight
				ctx.translate(canvas.width / 2, canvas.height / 2)
				// 旋转转换，改变画笔的旋转角度
				ctx.rotate((rotateDeg * Math.PI) / 180)
				// 调用绘制图片的方法把图片绘制到canvas中
				ctx.drawImage(img, -img.naturalWidth / 2, -img.naturalHeight / 2)
				this.setState({ fileToken: canvas.toDataURL() })
				if (isUpload) {
					const { fileType, fileName } = this.props.fileData
					canvas.toBlob(blob => {
						if (!blob) {
							console.error('Canvas is empty')
							return
						}
						let newFile = new File([blob], fileName, { fileType })
						resolve(newFile)
					}, fileType)
				}
			}
		})
	}

	handleOk = async archiveId => {
		const {
			isTemporaryArchive,
			categoryCode,
			addTemporaryArchive,
			selectTemporaryArchive,
			updateRecord,
		} = this.props
		const { fileType, fileName } = this.props.fileData
		const file = await this.rotateImg(true, 0)
		const fileChunkList = createFileChunk(file)
		const fileMd5Value = await getFileMd5(fileChunkList)
		const { fileId, thumbnailFileId, fileToken } = await uploadFile(
			fileMd5Value,
			fileName,
			fileChunkList,
			fileType,
			file
		).then(res => {
			let img = document.querySelector('.rotate-image')
			img.src = '/api/file/download/' + res.data.fileToken
			return {
				fileId: res.data.fileId,
				thumbnailFileId: res.data.thumbnailFileId,
				fileToken: res.data.fileToken,
			}
		})
		const requestData = {
			id: archiveId,
			topic: fileName,
			fileId,
			thumbnailFileId,
		}
		if (isTemporaryArchive) {
			await addTemporaryArchive([requestData]).then(
				// 保存更改后的数据
				selectTemporaryArchive().then(res => {
					localStorage.setItem('TemporaryArchive', JSON.stringify(res.data))
				})
			)
			this.props.isFresh()
		} else {
			requestData.categoryCode = categoryCode
			await updateRecord(requestData)
		}
		this.props.setSrc('/api/file/download/' + fileToken)
		this.setRotate()
	}

	render() {
		const { fileData, archiveId, isThumbnail = false, isShowBtn } = this.props
		const { isRotate } = this.state
		return (
			<>
				{/* 非缩略图展示按钮 */}
				{!isThumbnail && fileData.fileType?.includes('image') && (
					<Button
						className='file-previewer-rotate'
						style={isShowBtn ? { display: 'block' } : { display: 'none' }}
						onClick={() => this.setRotate()}
						title='图片旋转'>
						<RedoOutlined />
					</Button>
				)}
				{
					<Modal
						width={1000}
						title='图片旋转'
						visible={isRotate}
						onCancel={() => this.handleCancel()}
						className='rotate-modal-wrapper'
						onOk={() => this.handleOk(archiveId)}>
						<div className='rotate-wrapper'>
							<AntdImage
								src={this.state.fileToken}
								preview={false}
								className='rotate-image'></AntdImage>
						</div>
						<div className='rotate-btn-container'>
							<button
								className='rotate-modal-btn'
								onClick={() => this.rotateLeft()}>
								<UndoOutlined />
							</button>
							<button
								className='rotate-modal-btn'
								onClick={() => this.rotateRight()}>
								<RedoOutlined />
							</button>
						</div>
					</Modal>
				}
			</>
		)
	}
}

export default RotateImage
