import _fetch from '@/Utils/Fetch'
import { userUrl, userManagerUrl } from '@/Utils/Urls'
import { timeUtil } from '@/Utils'
import { message } from 'antd'
import { TAB_ALLCLEAR, TAB_ADD } from '@/Redux/ActionTypes/Route'
import { GET_USERDATA } from '@/Redux/ActionTypes/HeaderBar'

export const clearTabAllAction = () => {
	return {
		type: TAB_ALLCLEAR,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

export const getUserDataAction = data => {
	return {
		type: GET_USERDATA,
		data: data,
	}
}
/**
 * 获取已登录用户的信息
 */
export const getUserInfoThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: userUrl.getUserInfo,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 修改用户信息thunk
 */
export const editUserThunk =
	(formData, { finish, hide }) =>
	() => {
		formData.validTime &&
			(formData.validTime = timeUtil.toTimestamp(formData.validTime))
		_fetch({
			url: userManagerUrl.editUser,
			type: 'post',
			data: {
				...formData,
			},
			isJson: true,
		}).then(res => {
			if (res.data.code === 200) {
				// 结束按钮loading状态
				finish()
				// 关闭modal
				hide()
				message.success('编辑成功')
			} else {
				finish()
			}
		})
	}

/**
 * 修改用户密码
 */
export const changePasswordThunk =
	(formData, { finish, hide }) =>
	() => {
		_fetch({
			url: userManagerUrl.updatePassword,
			type: 'post',
			isJson: true,
			data: {
				oldPassword: formData.oldPassword,
				newPassword: formData.newPassword,
			},
		}).then(res => {
			if (res.data.code === 200) {
				// 结束按钮loading状态
				finish()
				// 关闭modal
				hide()
				message.success('修改成功')
			}
		})
	}
