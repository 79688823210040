import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'antd/dist/antd.less'
import { Card, Layout } from 'antd'
import { CookieUtil } from './Utils/index'
import SliderBar from '@/Components/SlideBar'
import HeaderBar from '@/Components/HeaderBar'
import Routes from '@/Routes'
import { SetRouterAction } from '@/Components/SlideBar/ActionCreator'
import {
	SetUserTypeAction,
	SetPermissionAction,
	SetRoleListAction,
	setUserAction,
} from './Components/Login/LoginForm/ActionCreator'
import { Switch } from 'react-router-dom'
import { homePath } from '@/Components/Login/LoginForm'
import { linkTo } from '@/Routes/ManageRouter'

import ContentHeader from './Components/ContentInHeader/index'

const { Header, Content, Sider } = Layout
class HomePage extends Component {
	state = {
		userType: '', // 登陆的角色类型
	}
	componentDidMount() {
		this.setState({
			userType: this.props.login.userType,
		})
		const { SetUserType, SetPermission, SetRoleList, setUser } = this.props

		// 进入管理页面之后如果是已登录
		if (!!this.props.login.userType) {
		} else if (
			!!CookieUtil.get('userMessage') && // 刷新之后保持登录
			typeof JSON.parse(CookieUtil.get('userMessage')) === 'object'
		) {
			//设置window数据
			let userMessage = JSON.parse(CookieUtil.get('userMessage'))
			// 存入redux
			// 改为Utils中现有的方法
			SetUserType(CookieUtil.roleList)
			SetPermission(CookieUtil.permissionList)
			SetRoleList(CookieUtil.roleList)
			setUser(CookieUtil.user)
			window.id = userMessage.user.id
			window.workId = userMessage.user.workId
			// 页面刷新后，如果原来在隐藏公共组件页面 或 收藏页面，则跳回首页
			const route = this.props.location.pathname
			if (route.includes('/workStation') || route.includes('/commonComponents')) {
				// 如果是衍生页面，则跳转到首页
				this.props.history.push(`${linkTo.topRoute}${homePath.url}`)
			}
		} else {
			// 未登录
			CookieUtil.unset('userMessage')
			CookieUtil.unset('JSESSIONID')
			window.location.pathname = ''
		}

		// 禁止右键
		document.oncontextmenu = function (e) {
			e.preventDefault()
			return false
		}
	}

	render() {
		const userType = this.props.login.userType
		return (
			<Layout style={{ height: '100%' }}>
				{/* 头部 */}
				<Header style={{ padding: 0 }}>
					<HeaderBar userType={userType} />
				</Header>
				<Layout>
					{/* 侧边栏 */}
					<Sider
						className='home-page-sider-bar'
						width='var(--home-page-sider-bar-width)'
						collapsed={false}>
						<SliderBar userType={userType} />
					</Sider>
					{/* 主体内容 */}
					<Content style={{ height: 'calc(100vh - 67px)', marginTop: '12px' }}>
						{/* 页面上的tabs栏 */}
						<ContentHeader />
						<Card
							style={{
								minHeight: 'calc(100% - 40px)',
								minWidth: '800px',
								margin: '20px 20px',
								height: 'calc(100% - 40px)',
							}}
							bordered={false}>
							{/* 二级路由 */}
							<Switch>
								<Routes userType={userType} />
							</Switch>
						</Card>
					</Content>
				</Layout>
			</Layout>
		)
	}
}

const mapStateToProps = state => {
	return {
		login: state.login,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		SetRouter(data) {
			return dispatch(SetRouterAction(data))
		},
		SetUserType(data) {
			return dispatch(SetUserTypeAction(data))
		},
		SetPermission(data) {
			return dispatch(SetPermissionAction(data))
		},
		SetRoleList(data) {
			return dispatch(SetRoleListAction(data))
		},
		setUser(data) {
			return dispatch(setUserAction(data))
		},
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
