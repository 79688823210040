import React from 'react'
import { Spin } from 'antd'
import { throttle } from 'lodash'
import { Document, Page, pdfjs } from 'react-pdf'
import { openWarcUrl } from './Warc'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

class MyPdfViewer extends React.Component {
	state = {
		pages: 0,
		curTotal: 0,
		pdfs: [],
	}

	componentDidMount() {
		this.pdfViewer = document.querySelector('.pdf-viewer')
		const { fileType, url, fileId } = this.props
		this.pdfViewer.onclick = () => {
			// ——————————后台做了更改——————————
			// 是warc则请求接口打开html
			// 否则保留原先代码，打开之前pdf的url
			if (fileType === 'text/html') {
				openWarcUrl(fileId)
			} else {
				window.open(url)
			}
		}
	}

	componentWillUnmount() {
		this.pdfViewer.removeEventListener('scroll', this.reachBottomLoad)
	}

	onDocumentComplete = PDFDocumentProxy => {
		const pages = PDFDocumentProxy._pdfInfo.numPages
		this.pdfViewer.addEventListener('scroll', this.reachBottomLoad)
		this.setState({ pages }, () => {
			this.directlyRenderPdf(pages)
		})
	}

	reachBottomLoad = throttle(() => {
		const { pages, curTotal } = this.state
		if (pages === curTotal) {
			this.pdfViewer.removeEventListener('scroll', this.reachBottomLoad)
			delete this.reachBottomLoad
			return
		}
		const pdfViewerScrollTop = this.pdfViewer.scrollTop
		const pdfViewerHeight = this.pdfViewer.clientHeight
		const pdfViewerScrollHeight = this.pdfViewer.scrollHeight

		if (pdfViewerScrollTop + pdfViewerHeight + 100 > pdfViewerScrollHeight) {
			this.directlyRenderPdf(pages, curTotal)
		}
	}, 200)

	directlyRenderPdf = (pages, current = 1, limit = 3) => {
		if (pages === null || current === pages) return
		let curTotal = current + limit
		curTotal = curTotal > pages ? pages + 1 : curTotal
		let pdfs = []
		for (let i = current; i < curTotal; i++) {
			const pdf = {
				current: i,
				key: `pdf${i}`,
			}
			pdfs.push(pdf)
		}
		this.setState(preState => ({ pdfs: [...preState.pdfs, ...pdfs], curTotal }))
	}

	render() {
		const { watermark = false, url } = this.props
		const { pdfs, pages } = this.state
		return (
			<div className='pdf-viewer' style={watermark ? { overflow: 'hidden' } : {}}>
				<Document
					file={url}
					onLoadSuccess={this.onDocumentComplete}
					loading={
						<Spin
							tip='加载中...'
							size='large'
							style={{ marginTop: '200px' }}
						/>
					}>
					{/* 将获取的pdf全部展示，若为水印文件，则显示一张 */}
					{watermark && <Page pageNumber={1} />}
					{!watermark &&
						pages > 1 &&
						pdfs.map(pdf => <Page key={pdf.key} pageNumber={pdf.current} />)}
				</Document>
			</div>
		)
	}
}

export default MyPdfViewer
