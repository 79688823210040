import React, { PureComponent, Fragment } from 'react'
import { Form, Button } from 'antd'
import UtilsHOC from './Utils'
import WithRef from '../WithRef'
import './index.less'
import _ from 'lodash'

// 公共表单组件
@UtilsHOC // 强化组件，注入表单方法等
@WithRef
class PubForm extends PureComponent {
	// 用于存储 Form 实例的Ref
	formRef = React.createRef()
	state = {
		formItemList: [],
		loading: false,
	}

	async componentDidMount() {
		// 初始化生成表单项组件

		const sensitiveDetected = await this.sensitiveDetect()
		this.createFormItems(sensitiveDetected)
	}

	//敏感词提示,给存在敏感词的表单添加一个类名detected，展示不同的样式
	sensitiveDetect = async current => {
		const detectedDataList = ['topic', 'keyWord', 'introduction'] //探测的字段
		let sensitiveDetected = { topic: false, keyword: false } //默认false
		detectedDataList?.forEach(item => {
			//想办法拿到数据
			const string = current?.getFieldValue(item)
			//探测条件
			if (string?.includes('**')) {
				sensitiveDetected[item] = true
			}
		})
		return sensitiveDetected
	}

	// 根据传入的 formItems 配置生成各个表单项组件
	createFormItems = async () => {
		const { renderFormItems, formItems } = this.props
		// 根据配置生成
		const sensitiveDetected = await this.sensitiveDetect(this.formRef.current)
		//console.log('sensitiveDetected', sensitiveDetected)
		let formItemList = await renderFormItems(
			formItems,
			this.formRef.current,
			sensitiveDetected
		)
		this.setState({ formItemList })
	}

	// 渲染表单按钮列表
	createFormButtonList = formButtonList => {
		const { renderFormItems } = this.props
		formButtonList.forEach(item => {
			item.type = 'DynamicExtra'
			if (!item.render && !item.onClick) {
				throw new Error(
					`${item.name}组件必须传入render或者onClick函数，若传入render则渲染内容由render决定，若传入onClick则默认渲染一颗按钮若两者均传则使用render`
				)
			} else if (!item.render && item.onClick) {
				if (!item.text) {
					throw new Error(`${item.name}组件必须传入text`)
				}
				item.render = formRef => {
					return (
						<Button key={item.name} onClick={item.onClick}>
							{item.text}
						</Button>
					)
				}
			}
		})
		// 根据配置生成
		return renderFormItems([formButtonList], this.formRef.current)
	}

	componentDidUpdate(prevProps) {
		// 比较上一次的props和当前props是否有差异，如果有差异就重新更新表单
		if (
			!_.isEqual(prevProps.formItems, this.props.formItems) ||
			prevProps.formDisabled !== this.props.formDisabled
		) {
			this.createFormItems()
			// 更新表单的时候需要重新设置禁用状态
			const { formDisabled = false, setDisabled, setItemShow } = this.props
			setDisabled(formDisabled)
			setItemShow()
		} else if (!_.isEqual(prevProps.whichShow, this.props.whichShow)) {
			// 控制表单某项显示隐藏
			this.props.setItemShow(this.props.whichShow)
		}
	}

	render() {
		const {
			name,
			layout = {
				labelCol: { span: 8 },
				wrapperCol: { span: 16 },
			},
			onSubmit = null,
			getFormData,
			formButtonList = null,
			onValuesChange,
			clickHandler,
		} = this.props
		const { loading } = this.state
		//console.log(getFormData)
		return (
			<Fragment>
				<Form
					{...layout}
					name={name}
					ref={this.formRef}
					onValuesChange={onValuesChange || null}
					onClick={clickHandler}>
					{this.state.formItemList}
				</Form>
				{
					// 如果有 onSubmit 方法则内置获取表单数据后作为参数调用
					formButtonList || onSubmit ? (
						formButtonList ? (
							this.createFormButtonList(formButtonList)
						) : (
							<Button
								type='primary'
								loading={loading}
								onClick={async () => {
									try {
										const formData = await getFormData()
										formData &&
											this.setState(
												{
													loading: true,
												},
												() => {
													// 第二个参数可以手动调用设置 loading 为 false
													onSubmit(formData, () =>
														this.setState({ loading: false })
													)
												}
											)
									} catch {}
								}}>
								提交
							</Button>
						)
					) : null
				}
			</Fragment>
		)
	}
}

export default PubForm
