import React, { Component, Fragment } from 'react'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { connect } from 'react-redux'
import { Button, Form, Input, Radio, message, Table, Switch, Modal, Divider } from 'antd'
import { PubForm } from '@/Public/PubForm'
import BaseTable from '@/Public/PubTable/baseTable'
import { appraisalDetailColumns } from '@/Public/PubTable/pageColumns'
import Enum from '@/Utils/Enum'
import { linkTo } from '@/Routes/ManageRouter'
import { routeChangeAction, pushRoutersAction } from './ActionCreators'
import { history } from '@/index'
import { homePath } from '@/Components/Login/LoginForm'
import {
	addAppraisalDataToRedux,
	submitVerifyThunk,
	deleteVerifyThunk,
	updateVerifyApplyThunk,
	uploadFileThunk,
	verifyApplyThunk,
	downloadFileThunk,
	getVerifyDetailExcelThunk,
	tips,
} from './ActionCreators'
import './index.less'
import { pub_getAllTableData } from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator.js'
import { cloneDeep } from 'lodash'
import AddBtn from './addDossierOrArchive'

const appraisalApprovalLink = {
	title: '档案鉴定',
	key: '/workarea/appraisal',
}

const markName = 'AppraisalDetails' // redux 的标识

const resultType = new Enum(['销毁', '永久', '30年']) //现有效期
const preRetentionPeriodType = new Enum({ 1: '永久', 2: '30年', 3: '10年' }) //前有效期
const applyStatus = new Enum(['待鉴定', '完成', '暂存'])

/**
 * 功能:删除tab栏时，计算出下一次激活的tab
 * 删除激活的tab，若它是第一个，激活最后一个。若不是，激活前一个
 * 删除非激活的tab，那没事了
 * @param {Array} routes
 * @param {string} route
 * @param {string} targetKey
 * @return {string}
 */
function getNextActiveKey(routes, route, targetKey) {
	let nextActiveKey = ''
	if (routes.length === 1) {
		return homePath
	}
	if (targetKey === route) {
		if (routes[0].key === targetKey) {
			return routes[routes.length - 1].key
		} else {
			routes.every(item => {
				item.key !== targetKey && (nextActiveKey = item.key)
				return item.key !== targetKey
			})
		}
		return nextActiveKey
	}
	return route
}
const mapStateToProps = state => ({
	appraisalData: state.appraisalDetails.appraisalData,
	route: state.route.item, // 获取当前路由
	routes: state.route.items,
})

const mapDispatchToProps = dispatch => ({
	submitVerify: (...rest) => dispatch(submitVerifyThunk(...rest)), //鉴定申请
	addAppraisalDataToRedux: (...rest) => dispatch(addAppraisalDataToRedux(...rest)),
	deleteVerify: (...rest) => dispatch(deleteVerifyThunk(...rest)), // 删除
	updateVerifyApply: (...rest) => dispatch(updateVerifyApplyThunk(...rest)), // 编辑
	uploadFile: (...rest) => dispatch(uploadFileThunk(...rest)), //上传文件
	downloadFile: (...rest) => dispatch(downloadFileThunk(...rest)), //下载附件
	getVerifyDetailExcel: (...rest) => dispatch(getVerifyDetailExcelThunk(...rest)), //导出鉴定申请Excel
	verifyApply: (...rest) => dispatch(verifyApplyThunk(...rest)), //提交、暂存鉴定处理
	getAllTableData(...rest) {
		return dispatch(pub_getAllTableData(...rest)) // 把获取到的表格所有数据发送到store
	},
	//切换路由
	onChange(activeKey, history) {
		if (history) {
			history.push(linkTo.to(activeKey.key))
		} else {
			dispatch(routeChangeAction(activeKey.key))
		}
		dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
	},
	//添加分页
	pushRouters(link) {
		return dispatch(pushRoutersAction(link))
	},
	//删除分页
	removeTab(targetKey, history, route, routes) {
		let nextActiveKey = getNextActiveKey(routes, route, targetKey)
		dispatch({ type: 'ROUTE_CHANGE', filter: nextActiveKey })
		dispatch({ type: 'TAB_REMOVE', filter: targetKey })
		if (history) {
			history.push(
				linkTo.to(
					typeof nextActiveKey === 'string' ? nextActiveKey : nextActiveKey.url
				)
			)
		} else {
			dispatch(routeChangeAction(nextActiveKey))
		}
	},
})
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class AppraisalDetails extends Component {
	constructor(props) {
		super(props)
		this.form = React.createRef()
		this.state = {
			manageStatus: true, //处理方式，true代表逐个操作，false代表批量（全部）操作
		}
	}

	// 实时渲染
	setPubForm = () => {
		let formItems = [
			[
				{
					type: 'Input',
					name: 'applyId',
					label: '申请编号',
					span: 5,
					placeholder: '无',
					disabled: true,
					bordered: false,
					rules: [{ required: false }],
					initialValue: this.props.appraisalData[2]?.id || '无',
				},
				{
					type: 'Input',
					name: 'status',
					label: '状态',
					span: 3,
					placeholder: '无',
					disabled: true,
					bordered: false,
					rules: [{ required: false }],
				},
				{
					type: 'Input',
					name: 'applyPerson',
					label: '申请人',
					span: 6,
					placeholder: '无',
					disabled: true,
					bordered: false,
					rules: [{ required: false }],
				},
				{
					type: 'Input',
					name: 'createTime',
					label: '申请时间',
					span: 5,
					placeholder: '无',
					disabled: true,
					bordered: false,
					rules: [{ required: false }],
				},
				{
					type: 'Input',
					name: 'submitUserName',
					label: '提交人',
					span: 5,
					placeholder: '无',
					disabled: true,
					bordered: false,
					rules: [{ required: false }],
					initialValue: this.props.appraisalData[0]?.submitUserName || '无',
				},
			],
			[
				{
					type: 'TextArea',
					name: 'applyReason',
					label: '申请原因',
					placeholder: '无',
					disabled: this.props.appraisalData[2]?.status === 1 ? true : false,
					rules: [{ required: false }],
				},
			],
		]
		return formItems
	}

	componentDidMount() {
		const data = {
			data: this.props.appraisalData[0]?.verifyDetails,
		}
		if (data.data) {
			data.data.forEach((value, index) => {
				value.preRetentionPeriod =
					preRetentionPeriodType[value.preRetentionPeriod]
				value.result = resultType[value.result]
			})
			// 把表格数据(附录文件列表)发送到store
			this.props.getAllTableData(data, markName)
		}
	}

	render() {
		const fromExpired = sessionStorage.getItem('fromExpired')
		const { appraisalData, getVerifyDetailExcel } = this.props
		const formItems = this.setPubForm()
		const status = appraisalData.length > 0 ? appraisalData[2].status : null //0待鉴定1完成2暂存
		//鉴定操作的表格，整理表格数据dataSource
		const { Column } = Table
		const dataSource = []
		appraisalData[0]?.verifyDetails.forEach((value, index) => {
			const dataItem = {
				key: value.id,
				index: index + 1,
				...value,
				preRetentionPeriod: value.preRetentionPeriod || '无',
			}
			dataSource.push(dataItem)
		})
		let fileList = [] //汇总已上传附件id
		this.props.appraisalData[0]?.attachmentList.forEach(value => {
			fileList.push({
				uid: value.fileId,
				name: value.fileName,
				status: 'done',
				url: `/api/file/download/${value.fileToken}`,
			})
		})

		return (
			<Fragment>
				{formItems && (
					<Fragment>
						<h1 style={{ textAlign: 'center' }}>档案鉴定明细表</h1>
						<PubForm
							disabled={true}
							ref={this.formRef}
							formItems={formItems}
							getInstance={formRef => {
								this.formRef = formRef
								this.formRef.setFieldsValue(appraisalData[1])
							}}
							name='AppraisalDetails'
							title='鉴定详情'
						/>
						{fromExpired === 'true' && (
							<div className='verify-btn'>
								<Button
									id='verify-save'
									type='primary'
									onClick={() => {
										this.verifyApply(this.formRef)
									}}>
									保存
								</Button>
							</div>
						)}
						<div id='appraisal-handle'>
							<div style={{ margin: '0 auto', textAlign: 'right' }}>
								<Form
									onFinish={async value => {
										const attachmentList =
											await this.getAttachmentIds()
										const { operate } = this.state
										const formValue = {
											...value,
											applyReason:
												this.formRef.getFieldValue().applyReason,
											operate, //1提交 2暂存
											attachmentList, //附件id
										}
										this.verifyFinish(formValue)
									}}
									initialValues={{
										verifyContent: appraisalData[0]?.verifyContent,
										...appraisalData[1],
									}}>
									<Form.Item
										className='verifyContent'
										name='verifyContent'
										label='鉴定意见'
										required={false}
										style={{ borderRight: '1px solid #707070' }}
										rules={[
											{
												required:
													this.state.operate === 1
														? true
														: false,
												message: '请输入鉴定意见',
											},
										]}>
										<Input.TextArea
											style={{
												height: '400px',
												borderBottom: '1px solid #707070',
											}}
											disabled={
												status === 0 || status === 2
													? false
													: true
											}
											placeholder={
												status === 0 ||
												status === 1 ||
												status === 2
													? ''
													: '处理鉴定时可编辑'
											}
											maxLength={1500}
											showCount={true}
										/>
									</Form.Item>

									<div style={{ display: 'flex', marginTop: '-100px' }}>
										<p
											style={{
												marginLeft: '70px',
												fontSize: '15px',
												zIndex: '10',
											}}>
											鉴定人：
										</p>
										<Form.Item
											className='verifyPersonName'
											style={{ width: '250px' }}
											name='verifyPerson'
											required={false}
											rules={[
												{
													required:
														this.state.operate === 1
															? true
															: false,
													message: '请输入鉴定人',
												},
											]}>
											<Input
												disabled={
													status === 0 || status === 2
														? false
														: true
												}
											/>
										</Form.Item>
									</div>

									{/* 操作按钮 */}
									{(status === 0 || status === 2) && (
										<Form.Item
											style={{
												textAlign: 'right',
												marginTop: '-8px',
												marginBottom: '100px',
											}}>
											<Button
												type='primary'
												htmlType='submit'
												onClick={() => {
													this.setState({ operate: 2 })
												}}>
												保存
											</Button>
											<Button
												className='btn-warning'
												htmlType='submit'
												onClick={() => {
													this.setState({ operate: 1 })
												}}>
												提交
											</Button>

											<Button
												style={{ marginRight: '5px' }}
												className='btn-danger'
												onClick={() => {
													this.handleDeleteVerify(
														appraisalData[2]
													)
												}}>
												删除
											</Button>
										</Form.Item>
									)}

									{status !== 1 && (
										<Fragment>
											<Divider style={{}} />

											<div id='appraisal-add'>
												<AddBtn
													appraisalData={appraisalData || {}}
													status={status}
													disabled={status === 1 ? true : false}
													updateVerifyApply={
														this.props.updateVerifyApply
													}
												/>
												{appraisalData[0]?.verifyDetails.length >
													1 && (
													<Form.Item>
														{'处理方式&鉴定说明：'}
														<Switch
															disabled={
																status === 0 ||
																status === 2
																	? false
																	: true
															}
															checkedChildren='逐个操作'
															unCheckedChildren='批量操作'
															onChange={
																this.handleManagement
															}
															defaultChecked
														/>
													</Form.Item>
												)}
											</div>

											<Table
												id='appraisal-table'
												dataSource={dataSource}
												pagination={false}
												rowSelection={false}
												key='userId'>
												<Column title='序号' dataIndex='index' />
												<Column title='档案号' dataIndex='code' />
												<Column title='题名' dataIndex='topic' />
												<Column
													title='类别'
													dataIndex='categoryCode'
												/>
												<Column
													title='有效期'
													dataIndex='preRetentionPeriod'
												/>
												<Column
													title='处理方式'
													dataIndex='result'
													render={(text, record) => (
														<Form.Item
															name={
																this.state
																	.manageStatus === true
																	? `result_${record.key}`
																	: 'result'
															}
															rules={[
																{
																	required: true,
																	message:
																		'请选择处理方式',
																},
															]}>
															<Radio.Group
																style={{
																	textAlign: 'left',
																}}
																disabled={
																	status === 0 ||
																	status === 2
																		? false
																		: true
																}>
																<Radio value={0}>
																	销毁
																</Radio>
																<Radio value={1}>
																	永久
																</Radio>
																<Radio value={2}>
																	30年
																</Radio>
															</Radio.Group>
														</Form.Item>
													)}
												/>
												<Column
													title='鉴定说明'
													dataIndex='explanation'
													render={(text, record) => (
														<Form.Item
															name={
																this.state
																	.manageStatus === true
																	? `explanation_${record.key}`
																	: 'explanation'
															}
															required={false}>
															<Input
																className='explanationInput'
																disabled={
																	status === 0 ||
																	status === 2
																		? false
																		: true
																}
																allowClear
															/>
														</Form.Item>
													)}
												/>
												{status !== 1 && (
													<Column
														title='操作'
														key='handleDelete'
														render={(text, record) => {
															return (
																<Button
																	size='small'
																	onClick={() => {
																		this.removeAppraisalFiles(
																			text,
																			record
																		)
																	}}>
																	删除
																</Button>
															)
														}}
													/>
												)}
											</Table>
										</Fragment>
									)}
								</Form>
							</div>
							{status === 1 && (
								<div
									style={{
										margin: '0 auto',
										textAlign: 'right',
										marginBottom: '15px',
										marginTop: '140px',
									}}>
									{appraisalData[0].attachmentList.length > 0 && (
										<Button
											className='btn-success'
											style={{ margin: '5px' }}
											onClick={() => {
												this.downloadFileHandle()
											}}>
											下载
										</Button>
									)}

									<Button
										className='btn-info'
										style={{ margin: '5px' }}
										onClick={() => {
											getVerifyDetailExcel(appraisalData[2]?.id)
										}}>
										导出
									</Button>
									<WrappedTable>
										<BaseTable
											tableConfig={appraisalDetailColumns}
											withPreview={false}
											noOperationButton={true}
											changeSearchLimit={this.changeSearchLimit}
											changeTableData={this.changeTableData}
											markName={markName}
											notRowSelection={true}
											actions={this.actions}
										/>
									</WrappedTable>
								</div>
							)}
						</div>
					</Fragment>
				)}
			</Fragment>
		)
	}
	// 上传附件的id
	getAttachmentIds = func => {
		this.getAttachmentIds = func || (() => {})
	}

	// 鉴定详情下面的Table，操作的删除按钮的回调函数
	removeAppraisalFiles = (text, record) => {
		const { addAppraisalDataToRedux, appraisalData } = this.props
		Modal.confirm({
			title: '提示',
			content: '确定删除该条吗？',
			onOk: () => {
				try {
					let cloneAppraisalData = cloneDeep(appraisalData)
					let tmpFilesData = cloneAppraisalData[0].verifyDetails

					const { updateVerifyApply } = this.props // 编辑
					tmpFilesData = tmpFilesData.filter(file => {
						return file.id !== record.id
					})
					cloneAppraisalData[0].verifyDetails = tmpFilesData

					addAppraisalDataToRedux(cloneAppraisalData)

					if (appraisalData[2].status === 0 || appraisalData[2].status === 2) {
						// 更新鉴定表
						let verifyStore = {}
						let verifyIdList = []
						cloneAppraisalData[0].verifyDetails.forEach((value, index) => {
							verifyIdList[index] = {
								type: value.verifyType || value.type,
								verifyId: value.verifyId || value.id,
							}
						})
						verifyStore['applyId'] = cloneAppraisalData[2].id
						verifyStore['verifyIdList'] = verifyIdList
						updateVerifyApply(verifyStore).then(() => {
							if (tmpFilesData.length === 0) {
								const { removeTab, route, routes } = this.props
								this.props.onChange(appraisalApprovalLink, history)
								this.props.pushRouters(appraisalApprovalLink)
								removeTab(route, history, route, routes)
							}
							message.success('删除成功')
						})
					}
				} catch (error) {}
			},
			onText: '确定',
			cancelText: '取消',
			onCancel: close => {
				close()
			},
		})
	}

	//批量操作
	handleManagement = () => {
		this.setState({
			manageStatus: !this.state.manageStatus,
		})
	}

	//提交鉴定申请
	verifyApply = formRef => {
		if (formRef.getFieldValue().applyReason) {
			const verifyIdList = []
			const verifyCache = {}
			const data = this.props.appraisalData
			const { verifyApply } = this.props // 编辑
			data[0].verifyDetails.forEach((value, index) => {
				verifyIdList[index] = {
					type: value.type,
					verifyId: value.id,
				}
			})
			verifyCache['applyReason'] = this.formRef.getFieldValue().applyReason
			verifyCache['verifyIdList'] = verifyIdList

			verifyApply(verifyCache).then(() => {
				const { removeTab, route, routes } = this.props
				this.props.onChange(appraisalApprovalLink, history)
				this.props.pushRouters(appraisalApprovalLink)
				removeTab(route, history, route, routes)
				message.success(tips.VERIFY_APPLY_SUCCESS)
			})
		} else {
			message.error('请先编辑申请原因')
		}
	}

	// 删除鉴定申请
	handleDeleteVerify = async record => {
		const { deleteVerify } = this.props
		try {
			let records = record ? [record] : await this.checkRowSelection()
			let ids = []
			records.forEach(
				(record, index) =>
					record.status !== applyStatus['完成'] && ids.push(record.id)
			)
			// 长度不一致说明选中的有不是为申请状态的 不能继续往下执行
			if (ids.length !== records.length) {
				message.error(tips.ONLY_APPLY_AND_RECORD_CAN_BE_DELETED)
				return
			}
			deleteVerify(ids).then(() => {
				const { removeTab, route, routes } = this.props
				removeTab(route, history, route, routes)
				message.success(tips.DELETE_SUCCESS)
			})
		} catch {}
	}

	//下载附件
	downloadFileHandle = () => {
		const { downloadFile, appraisalData } = this.props
		appraisalData[0].attachmentList.forEach((value, index) => {
			downloadFile(value)
		})
	}

	//编辑申请理由
	updateApplyReason = formValue => {
		let verifyStore = {}
		let verifyIdList = []
		const data = this.props.appraisalData

		const { updateVerifyApply } = this.props // 编辑
		// 获取存储的值
		data[0].verifyDetails.forEach((value, index) => {
			verifyIdList[index] = {
				type: value.type || value.verifyType,
				verifyId: value.verifyId || value.id,
			}
		})
		const { applyReason } = formValue
		verifyStore['applyReason'] = applyReason
		verifyStore['applyId'] = data[2].id
		verifyStore['verifyIdList'] = verifyIdList

		updateVerifyApply(verifyStore)
	}

	//调用提交接口，并在提交成功后返回鉴定审批详情页
	verifyFinish = async value => {
		this.updateApplyReason(value)
		const { submitVerify } = this.props
		let data = this.setVerifyData(value)
		const successMessage =
			data.operate === 1 ? tips.SUBMIT_SUCCESS : tips.VERIFY_SUCCESS
		submitVerify(data).then(() => {
			const { removeTab, route, routes } = this.props
			this.props.onChange(appraisalApprovalLink, history)
			this.props.pushRouters(appraisalApprovalLink)
			removeTab(route, history, route, routes)
			message.success(successMessage)
		})
	}

	//整理返回给接口的数据
	setVerifyData = (formData, fileIds) => {
		let tempData = {}
		const { appraisalData } = this.props
		if (this.state.manageStatus) {
			for (let key in formData) {
				// 这里需要用到的数据的格式为 名称_id, 先切出来备用
				let [dataName, id] = key.split('_')
				/* 如果为result或者explanation 就把数据放到tempData里面
				 * 完成后tempData就是 {id1: {.....}, id2: {.....} }, 其中id*是不需要的
				 * 只需要把后面的对象取出来放到一个数组中 */
				if (dataName === 'result') {
					tempData[id] = {
						...tempData[id],
						id: parseInt(id, 10),
						handle: formData[key],
					}
				}
				if (dataName === 'explanation') {
					tempData[id] = {
						...tempData[parseInt(id)],
						id: parseInt(id, 10),
						explanation: formData[key],
					}
				}
			}
			let verifyDetails = []
			// 这一步把tempData里的每一个对象放到verifyDetails中

			for (let key in tempData) {
				verifyDetails.push(tempData[key])
			}
			// 返回接口需要的数据
			return {
				applyId: appraisalData[2].id,
				verifyContent: formData?.verifyContent,
				verifyPersonName: formData.verifyPerson,
				verifyDetails,
				fileIds: formData.attachmentList,
				operate: formData.operate,
			}
		} else {
			for (let key in formData) {
				if (key === 'result') {
					appraisalData[0].verifyDetails.forEach((value, index) => {
						tempData[index] = {
							id: value.id,
							handle: formData[key],
						}
					})
				}
				if (key === 'explanation') {
					appraisalData[0].verifyDetails.forEach((value, index) => {
						tempData[index].explanation = formData[key]
					})
				}
			}
			let verifyDetails = []
			// 这一步把tempData里的每一个对象放到verifyDetails中
			for (let key in tempData) {
				verifyDetails.push(tempData[key])
			}
			// 返回接口需要的数据
			return {
				applyId: appraisalData[2].id,
				verifyContent: formData?.verifyContent,
				verifyPersonName: formData.verifyPerson,
				verifyDetails,
				fileIds: formData.attachmentList,
				operate: formData.operate,
			}
		}
	}
}

export default AppraisalDetails
