import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import { APPLYFIX_FINISH } from '@/Redux/ActionTypes/Public/PubForm'
import _fetch from '@/Utils/Fetch'
import { ADD_FIXDATA } from '@/Redux/ActionTypes/FixDetail'
import { correctApprovalUrl } from '@/Utils/Urls'
import { Modal, message } from 'antd'
import Enum from '@/Utils/Enum'
// 更新红点函数
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
// 消息提示
export const tips = {
	CHOOSE_AT_LEAST_ONE: '请选择至少一项后再进行操作',
	RECALL_SUCCESS: '撤回成功',
	DELETE_SUCCESS: '删除成功',
	VERIFY_SUCCESS: '审核成功',
	ONLY_RECALL_APPROVE_STATUS: '只能对“审批状态”下的修正申请进行撤回',
	ONLY_VERIFY_APPROVE_STATUS: '只能对“审批状态”下的修正申请进行审核',
}

export const statusEnum = new Enum({
	1: '待审批',
	2: '同意',
	3: '拒绝',
	4: '回退',
})

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

// 清空申请修正数据
export const finishApplyFixAction = data => {
	return {
		type: APPLYFIX_FINISH,
	}
}

// 添加修正申请表详情
export const addFixDataAction = (data, category) => {
	return {
		type: ADD_FIXDATA,
		data: data,
		category: category, // 申请
	}
}

/**
 * 获取修正审批详情列表
 * @param {Number} id 申请表id
 */
export const getVerifyDetailThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: correctApprovalUrl.getVerifyDetail,
			data: {
				id,
			},
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 撤回申请
 * @param {Array<Number>} ids 要撤回的申请id数组
 */
export const recallApplyThunk = ids => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '撤回修正申请',
			content: '您正在对当前申请进行撤回操作，是否继续？',
			onOk: () => {
				_fetch({
					url: correctApprovalUrl.recallApply,
					data: ids,
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.RECALL_SUCCESS)
						updataRedDots()
						resolve()
					}
				})
			},
		})
	})

/**
 * 档案修正审核
 * @param {Number} applyId 申请表id
 * @param {String} reviewResult 审核意见
 * @param {Number} status 0-拒绝 1-同意 2-回退
 */
export const verifyApplyThunk =
	({ applyId, reviewResult, status }) =>
	() =>
		new Promise((resolve, reject) => {
			_fetch({
				url: correctApprovalUrl.verifyApply,
				data: {
					applyId,
					reviewResult,
					status,
				},
				type: 'post',
				isJson: true,
			}).then(res => {
				if (res && res.data.code === 200) {
					message.success(tips.VERIFY_SUCCESS)
					updataRedDots()
					resolve(res.data.data)
				} else {
					reject()
				}
			})
		})
