import React, { PureComponent } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { borrowUrl } from '@/Utils/Urls'
import { statusEnum } from './ActionCreators'
import { claimApprovalColumns } from '@/Public/PubTable/pageColumns'
import FileClaimModal from './fileClaimModal'
import { Button } from 'antd'

const detailModalRef = React.createRef(null) // 获取查看模态框表单实例

const markName = 'claimApproval'
@WrappedTable.create()
class ClaimApproval extends PureComponent {
	state = { record: null }

	searchFormConfig = {
		formItems: [
			{
				type: 'Input',
				name: 'applyUser',
				label: '申请人',
				placeholder: '请输入申请人',
				width: '200px',
			},
			{
				type: 'Select',
				name: 'status',
				label: '状态',
				placeholder: '请输入审批状态',
				options: statusEnum.toOptions(), // 申请的处理状态
				minWidth: '165px',
				initialValue: 1,
			},
		],
		ajaxConfig: {
			ajaxType: 'post',
			url: borrowUrl.getClaimApplyList,
		},
	}

	changeSearchLimit = data => {
		data.topic = data.topic || data.topic === '' ? [data.topic] : data.topic // 给topic转换为数组形式
		data.current = data.currentPage
		delete data.currentPage
		return data
	}

	changeTableData = res => {
		res.records.forEach(item => {
			item.type = 1
			item.archiveId = 1
		})
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}

	extendButton = record => {
		return (
			<Button
				onClick={() =>
					this.setState({ record }, () => {
						this.viewModal(record)
					})
				}>
				{' '}
				查看
			</Button>
		)
	}

	viewModal = record => {
		detailModalRef.current.show(formRef => {
			formRef.setFieldsValue(record)
		})
	}

	render() {
		return (
			<WrappedTable>
				<SearchForm
					name='claim-approval-searchForm'
					markName={markName}
					formItems={this.searchFormConfig}
					changeSearchLimit={this.changeSearchLimit}
					changeTableData={this.changeTableData}
				/>

				<BaseTable
					markName={markName}
					tableConfig={claimApprovalColumns}
					changeSearchLimit={this.changeSearchLimit}
					changeTableData={this.changeTableData}
					operationButton={this.extendButton}
					withPreview={false}
				/>
				<FileClaimModal
					modalRef={detailModalRef}
					record={this.state.record} // 表格详情信息
					pub_getAllData={this.props.pub_getAllData} // 刷新
				/>
			</WrappedTable>
		)
	}
}

export default ClaimApproval
