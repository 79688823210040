import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
export default class RollbackRecordModal extends Component {
	state = {
		title: '', // 模态框的标题
		formItems: [],
	}

	formItems = [
		[
			{
				type: 'TextArea',
				name: 'message',
				label: '回退原因',
				layout: {
					labelCol: { span: 2 },
					wrapperCol: { span: 22 },
				},
				placeholder: '无',
				rows: 5,
				span: 24,
			},
		],
	]
	render() {
		const { modalRef, hideCallback } = this.props
		return (
			<PubModalForm
				title='回退详情'
				ref={modalRef}
				destroyOnclose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				width={1050}
				formProps={{
					//表单配置项
					name: 'RollbackRecordModalForm',
					formItems: this.formItems,
				}}
				hideCallback={hideCallback}
				formDisabled //查看状态下不给予编辑
			/>
		)
	}
}
