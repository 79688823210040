import React, { Component } from 'react'
import { connect } from 'react-redux'
import { linkTo } from '@/Routes/ManageRouter'
import './index.less'
import {
	getInformationByUrlThunk,
	routeChangeAction,
	pushRoutersAction,
	inititalPubArchiveAction,
	urlToArchiveManagementOptionsHZQ,
	getLogDetail,
	// getArchiveDetail,
	// urlToArchiveManagementOptions,
} from './ActionCreator'
import {
	getDetailThunk,
	addPubFileAction,
} from '@/Public/PubTable/baseTable/ActionCreator'
import { ClockCircleOutlined } from '@ant-design/icons'
import { Button, Divider, Typography, notification, Space, Table, Tag } from 'antd'
import { history } from '@/index'
import { homePath } from '@/Components/Login/LoginForm'
import { PubForm } from '@/Public/PubForm'
import _fetch from '@/Utils/Fetch'
import { archiveUrl } from '@/Utils/Urls'

const columns = [
	{
		title: '状态',
		dataIndex: 'status',
		key: 'status',
		render: (_, record) => {
			return (
				<Tag color={record.status === '成功' ? 'green' : 'red'}>
					{record.status}
				</Tag>
			)
		},
	},
	{
		title: '类别',
		dataIndex: ['dossierType', 'typeName'],
		key: 'dossierType.typeName',
	},
	{
		title: 'Url',
		dataIndex: 'targetUrl',
		key: 'targetUrl',
	},
	{
		title: '著录时间',
		dataIndex: 'logTime',
		key: 'logTime',
	},
	{
		title: '完成时间',
		dataIndex: 'finishTime',
		key: 'finishTime',
	},
	{
		title: '用户名',
		dataIndex: ['user', 'userName'],
		key: 'user.userName',
	},
	{
		title: 'email',
		dataIndex: ['user', 'email'],
		key: 'user.email',
	},
	{
		title: '电话',
		dataIndex: ['user', 'phone'],
		key: 'user.phone',
		render: (_, { user: { phone } }) => (phone ? phone : '无'),
	},
	{
		title: '单位',
		dataIndex: ['user', 'departmentName'],
		key: 'user.departmentName',
	},
]

// let link

/**
 * 功能:删除tab栏时，计算出下一次激活的tab
 * 删除激活的tab，若它是第一个，激活最后一个。若不是，激活前一个
 * 删除非激活的tab，那没事了
 * @param {Array} routes
 * @param {string} route
 * @param {string} targetKey
 * @return {string}
 */
function getNextActiveKey(routes, route, targetKey) {
	let nextActiveKey = ''
	if (routes.length === 1) {
		return homePath
	}
	if (targetKey === route) {
		if (routes[0].key === targetKey) {
			return routes[routes.length - 1].key
		} else {
			routes.every(item => {
				item.key !== targetKey && (nextActiveKey = item.key)
				return item.key !== targetKey
			})
		}
		return nextActiveKey
	}
	return route
}

const mapStateToProps = state => {
	return {
		route: state.route.item, // 获取当前路由
		routes: state.route.items,
	}
}
const mapDispatchToProps = dispatch => ({
	getInformationByUrl: (...rest) => dispatch(getInformationByUrlThunk(...rest)), // url
	onChange(activeKey, history) {
		history.push(linkTo.to(activeKey.key))
		dispatch(routeChangeAction(activeKey.key))
	},
	inititalPubArchive: (markName, pubArchive) =>
		dispatch(inititalPubArchiveAction(markName, pubArchive)),
	pushRouters(link) {
		return dispatch(pushRoutersAction(link))
	},
	addPubFileData(data) {
		return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
	},
	getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
	// 以下为删除路由
	removeTab(targetKey, history, route, routes) {
		let nextActiveKey = getNextActiveKey(routes, route, targetKey)
		dispatch({ type: 'ROUTE_CHANGE', filter: nextActiveKey })
		dispatch({ type: 'TAB_REMOVE', filter: targetKey })
		if (history) {
			history.push(
				linkTo.to(
					typeof nextActiveKey === 'string' ? nextActiveKey : nextActiveKey.url
				)
			)
		} else {
			dispatch(routeChangeAction(nextActiveKey))
		}
	},
})
@connect(mapStateToProps, mapDispatchToProps)
class UrlToArchives extends Component {
	state = {
		// spinning: false, // 加载状态
		operationType: 0,
		isLogShown: false,
		logData: [],
	}

	setTreeData = (data, titleName, valueName, onlyChildren = false) => {
		let treeData = []
		const rootNodeId = [] // 根节点的Id数组
		for (let i = 0; i < data.length; i++) {
			rootNodeId.push(data[i].id)
		}
		const recursionToTree = data => {
			let tempObj = {
				title: data[titleName],
				value: data[valueName],
			}

			if (data.children != null) {
				if (onlyChildren) {
					tempObj.disabled = true
				}
				tempObj.children = [...data.children.map(item => recursionToTree(item))]
			} else {
				if (rootNodeId.includes(data.id)) {
					// 如果是根节点则不可选
					if (onlyChildren) {
						tempObj.disabled = true
					}
				}
			}
			return tempObj
		}
		data?.forEach((value, index) => {
			treeData.push(recursionToTree(value))
		})
		return treeData
	}

	dossierType = this.setTreeData(
		JSON.parse(localStorage.getItem('dossierType')),
		'typeName',
		'id',
		true
	)

	formItems = [
		[
			{
				type: 'Radio',
				name: 'type',
				label: '类型',
				options: urlToArchiveManagementOptionsHZQ,
				span: 18,
				style: {
					display: 'flex',
					justifyContent: 'space-evenly',
				},
			},
			{
				type: 'Input',
				name: 'url',
				label: '网址',
				placeholder: '只能获取政府新闻频道、公众号、微博，抖音等单个页面内容',
				rules: [{ required: true }],
				span: 18,
				onChange: valueObj => {
					const value = valueObj.target.value
					const { setFieldsValue } = this.formRef
					// let topDomain = value.match(/([^./]+\.[^./]+)\//);  // 匹配顶级域名
					let domain = value.match(/^http(s)?:\/\/(.*?)\//)
					if (domain !== null) {
						if (domain[0].includes('douyin')) {
							setFieldsValue({ type: 1 })
						} else if (domain[0].includes('xuexi')) {
							setFieldsValue({ type: 5 })
						} else if (domain[0].includes('weibo')) {
							setFieldsValue({ type: 6 })
						} else if (domain[0].includes('mp.weixin.qq')) {
							setFieldsValue({ type: 3 })
						} else {
							setFieldsValue({ type: 2 })
						}
					}
					let isVideoCode = value.match(/(23|05|03|08|01|20)[0-9]{19}$/) // 匹配提取码
					if (isVideoCode !== null) {
						setFieldsValue({ type: 4 })
					}
				},
			},
			{
				type: 'TreeSelect',
				name: 'categoryCodeId',
				label: '类别',
				placeholder: '请选择类别',
				treeData: this.dossierType,
				rules: [{ required: true }],
				span: 18,
			},
			{
				type: 'Extra',
				name: 'URLButton',
				render: formRef => {
					return (
						<Button
							onClick={() => {
								formRef.validateFields().then(() => {
									// const { addPubFileData, route } = this.props
									let formData = formRef.getFieldsValue()
									delete formData.type
									// this.setState({ spinning: true })
									const notificationLoadingKey = `open${Date.now()}`
									notification.open({
										message: '新媒体后台著录中',
										description: '是否继续著录新的档案？',
										icon: (
											<ClockCircleOutlined
												style={{ color: '#108ee9' }}
											/>
										),
										key: notificationLoadingKey,
										btn: (
											<Space>
												<Button
													onClick={() => {
														notification.close(
															notificationLoadingKey
														)
													}}>
													是
												</Button>
												<Button
													danger
													onClick={() => {
														const {
															removeTab,
															route,
															routes,
														} = this.props
														removeTab(
															route,
															history,
															route,
															routes
														) // 删除
														notification.close(
															notificationLoadingKey
														)
													}}>
													否
												</Button>
											</Space>
										),
										onClose: () => {
											const { removeTab, route, routes } =
												this.props
											removeTab(route, history, route, routes) // 删除
										},
									})
									this.props
										.getInformationByUrl(formData)
										.then(async res => {
											const notificationSuccessKey = `open${Date.now()}`
											notification.success({
												message: '新媒体获取成功',
												description: (
													<div
														style={{
															whiteSpace: 'pre-line',
														}}>{`成功目标：“${formData.url}”`}</div>
												),
												key: notificationSuccessKey,
												// 因后台使用了消息队列，此接口不返回data，也不要求跳转至详情页
												// btn: (<Button onClick={() => {
												//   if (res.archiveDetail) {
												//     res.route = route;   // 由此可以知道从哪打开详情，并判断是否添加按钮
												//     res.archiveDetail.forEach(archiveData => {
												//       const {
												//         fileToken,
												//         fileType,
												//       } = archiveData
												//       // 设置预览图
												//       archiveData['preview'] = {
												//         type: 1,
												//         fileData: {
												//           fileToken, fileType
												//         }
												//       }
												//       // 每个来个key
												//       archiveData['key'] = archiveData.id;
												//     })
												//     if (res.duplicated) {
												//       message.info('已存在相同题目的案卷');
												//     }
												//     this.props.inititalPubArchive('dossierDetails', {
												//       filesData: res.archiveDetail || [],
												//       archiveData: res
												//     })
												//     link = {
												//       title: '案卷详情',
												//       key: '/workarea/commonComponents/dossierDetails'
												//     }
												//     this.props.onChange(link, history);   // 修改路由(如果异步，案卷下的异步表单发请求也是异步会报错)
												//     this.props.pushRouters(link)    //添加分页栏
												//     // this.setState({ spinning: false })
												//   } else {
												//     link = {
												//       title: '详情',
												//       key: '/workarea/commonComponents/details'
												//     }
												//     getArchiveDetail(res.data.data[0].archiveIdList[0]).then(res => {
												//       res.data.data.route = route
												//       res.data.data.type = 1
												//       addPubFileData(res.data.data)   // 文件详情传到redux
												//       this.props.onChange(link, history);   // 修改路由(如果异步，案卷下的异步表单发请求也是异步会报错)
												//       this.props.pushRouters(link)    //添加分页栏
												//       // this.setState({ spinning: false })
												//     })
												//   }
												//   notification.close(notificationSuccessKey)
												// }}>查看详情</Button>),
											})
										})
										.catch(err => {
											// this.setState({ spinning: false })
											notification.error({
												message: '新媒体获取失败',
												description: (
													<div
														style={{
															whiteSpace: 'pre-line',
														}}>{`失败目标：“${formData.url}”
                  失败原因：${err.message}`}</div>
												),
											})
										})
								})
							}}>
							获取
						</Button>
					)
				},
				span: 4,
			},
		],
	]

	// onValuesChange(label, newValue) {
	//   if (newValue.operationType !== this.state.operationType) {
	//     this.setState({ operationType: newValue.operationType }, () => {
	//       this.createFormItems()
	//     })
	//   }
	// }

	async componentDidMount() {
		// this.props.getLogDetail()
		const res = await _fetch({
			url: archiveUrl.logDatail,
			type: 'get',
			params: {
				size: 12,
				current: 1,
			},
		})
		if (res.data?.data?.record) {
			//console.log(res.data.data.records)
			this.setState({
				logData: res.data.data.records,
			})
		}
	}

	render() {
		// const { spinning } = this.state
		return (
			<>
				{/* <Spin spinning={spinning} tip="识别中..."> */}
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
					<Typography.Title level={3}>
						{this.state.isLogShown ? '日志' : '新媒体'}
					</Typography.Title>
					<Button
						onClick={() => {
							this.setState({ isLogShown: !this.state.isLogShown })
							//console.log(this.state.isLogShown)
						}}>
						{this.state.isLogShown ? '新媒体' : '日志'}
					</Button>
				</div>
				<Divider style={{ marginTop: '10px' }}></Divider>
				{this.state.isLogShown ? (
					<Table
						columns={columns}
						dataSource={this.state.logData}
						pagination={{ pageSize: 8 }}
					/>
				) : (
					<PubForm
						ref={this.formRef}
						formItems={this.formItems}
						// onValuesChange={(label, newValue) => this.onValuesChange(label, newValue)}
						getInstance={formRef => {
							this.formRef = formRef
						}}
						name={'UrlInput'}
						title='url输入框'
					/>
				)}
				{/* </Spin> */}
			</>
		)
	}
}

export default UrlToArchives
