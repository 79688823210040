import * as inputItem from './Input'
import * as selectItem from './Select'
import * as radioItem from './Radio'
import * as checkboxItem from './Checkbox'
import * as extraItem from './Extra'
import * as pickerItem from './Picker'
import * as tableItem from './Table'
import * as RichItem from './Rich'
import * as UploadItem from './Upload'
import React from 'react'
import { Form, Row, Col } from 'antd'
import { Consumer } from '../Context'
import './index.less'

const { Item } = Form
// 所有构建组件方法的集合
const items = {
	...inputItem,
	...selectItem,
	...radioItem,
	...checkboxItem,
	...extraItem,
	...pickerItem,
	...tableItem,
	...RichItem,
	...UploadItem,
}

/**
 * 该函数用于根据config.type来判定使用哪个函数生成组件
 * @param {*} config 表单子组件的配置
 */
const createFormItem = (rowItems, rowIndex, formRef, sensitiveDetected) => {
	return (
		<Row style={{ width: '100%' }} align='top' key={rowIndex} gutter={[16, 8]}>
			{rowItems.map((itemConfig, colIndex) => {
				let {
					type,
					name,
					label,
					span = 24,
					layout,
					initialValue,
					//被探测到有敏感词的添加类名
					colclassname = sensitiveDetected
						? sensitiveDetected[name]
							? 'detected-item'
							: ''
						: '',
					rules = [
						{
							required: true,
							message: '该项不能为空',
						},
					],
					disabled = false,
				} = itemConfig
				// 表格没有 label，让内容占满整个表单/Modal
				if (type.toUpperCase().includes('TABLE')) {
					layout = {
						labelCol: { span: 0 },
						wrapperCol: { span: 24 },
					}
				}
				let FormItem = null
				// 判断是否有该生成组件的方法，如果没有则响应错误而不继续生成
				try {
					FormItem = items['create' + type](itemConfig, formRef)
				} catch {
					throw new Error(
						`第 ${rowIndex + 1} 行的第 ${colIndex + 1} 个表单项配置出错，可能是类型 type 写错，也可能没有在 FormItem 的 index.jsx 引入 create${type} 方法`
					)
				}

				return (
					<Col key={name} span={span} className={colclassname}>
						{
							//上传组件
							type !== 'Upload' ? ( // 额外组件和异步表格（只用来展示）不需要被表单管理
								type === 'Extra' ||
								type === 'DynamicExtra' ||
								type === 'AsyncTable' ||
								type === 'Rich' ? (
									FormItem
								) : (
									<Consumer>
										{({ disabledObj, whichShowObj }) => {
											const itemDisabled = disabledObj[name]
											const itemShow = whichShowObj[name]
											return (
												itemShow && (
													<Item
														{...layout}
														label={label}
														name={name}
														initialValue={initialValue}
														rules={
															type === 'Table'
																? null
																: rules
														}
														key={name}>
														{React.cloneElement(FormItem, {
															disabled:
																itemDisabled || disabled,
															bordered: !itemDisabled,
														})}
													</Item>
												)
											)
										}}
									</Consumer>
								)
							) : (
								<Consumer>
									{({ disabledObj, whichShowObj }) => {
										const itemDisabled = disabledObj[name]
										const itemShow = whichShowObj[name]

										const normFile = e => {
											if (Array.isArray(e)) {
												return e
											}
											return e && e.fileList
										}

										return (
											itemShow && (
												<Item
													{...layout}
													label={label}
													name={name}
													rules={rules}
													key={name}
													valuePropName='fileList'
													getValueFromEvent={normFile}>
													{React.cloneElement(FormItem, {
														disabled:
															itemDisabled || disabled,
													})}
												</Item>
											)
										)
									}}
								</Consumer>
							)
						}
					</Col>
				)
			})}
		</Row>
	)
}
export default createFormItem
