import { message } from 'antd'
import { dossierUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'

// 导出案卷信息
export const exportDossier = (dossierIds, templateType, fileName) => {
	_fetch({
		url: dossierUrl.generateDossierPdf,
		type: 'post',
		data: {
			templateType,
			dossierIds,
		},
		headers: {
			'Content-Type': 'application/json',
		},
		responseType: 'blob',
	}).then(res => {
		if (res.headers['content-type'] === 'application/json') {
			message.error('该案卷还未入库')
			return
		}
		const blob = res.data
		const urlObject = window.URL || window.webkitURL || window
		const export_blob = new Blob([blob], { type: 'application/octet-stream' })
		const a = document.createElement('a') // 利用a标签特性下载
		a.style.display = 'none'
		const url = urlObject.createObjectURL(export_blob)
		a.href = url
		a.setAttribute('download', `${fileName}.pdf`)
		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)
		message.success('成功导出！')
	})
}
