import React, { PureComponent } from 'react'
import { Descriptions } from 'antd'
import './index.less'
export default class UserInfo extends PureComponent {
	render() {
		const myInfo = this.props.myInfo
		const faceUrl =
			myInfo.length !== 0 && myInfo.faceImageList
				? myInfo.faceImageList[0]?.faceImageUrl
				: myInfo.base64
		return (
			myInfo && (
				<div className='user-info-box'>
					<Descriptions
						title='个人信息'
						colon
						style={{ width: '80%', margin: 'auto 0 ' }}>
						<Descriptions.Item label='姓名'>{myInfo.name}</Descriptions.Item>
						<Descriptions.Item label='职务'>
							{myInfo.position || '暂无职务'}
						</Descriptions.Item>
						<Descriptions.Item label='部门'>
							{myInfo.department || '暂无部门'}
						</Descriptions.Item>
						<Descriptions.Item label='其他信息' span={2}>
							{myInfo.information || '暂无其他信息'}
						</Descriptions.Item>
					</Descriptions>
					{faceUrl ? <img src={faceUrl} alt={myInfo.name} /> : '暂无照片'}
				</div>
			)
		)
	}
}
