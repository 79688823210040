import initialState from '../../InitialState'
import { CHANGE_OPENKEYS, CHANGE_SELECTEDKEY } from '../../ActionTypes/SlideBar'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState.slideBar, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case CHANGE_OPENKEYS:
			return {
				...state,
				openKeys: action.filter,
			}
		case CHANGE_SELECTEDKEY:
			return {
				...state,
				selectedKey: action.filter,
			}
		default:
			return state
	}
}
