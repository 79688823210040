import React, { Component } from 'react'
import mammoth from 'mammoth'

export default class WordViewer extends Component {
	componentDidMount() {
		const jsonFile = new XMLHttpRequest()
		jsonFile.open('GET', this.props.filePath, true)
		jsonFile.send()
		jsonFile.responseType = 'arraybuffer'
		jsonFile.onreadystatechange = () => {
			if (jsonFile.readyState === 4 && jsonFile.status === 200) {
				mammoth
					.convertToHtml(
						{ arrayBuffer: jsonFile.response },
						{ includeDefaultStyleMap: true }
					)
					.then(result => {
						const docEl = document.createElement('div')
						docEl.className = `document-container`
						docEl.innerHTML = result.value
						document.getElementById(this.props.fileId).innerHTML =
							docEl.outerHTML
					})
					.catch(a => {
						//console.log('alexei: something went wrong', a)
					})
					.done()
			}
		}
	}

	render() {
		return (
			<div
				className='viewer-docx'
				id={this.props.fileId}
				style={
					this.props.watermark
						? {
								height: '580px',
								width: '580px',
								overflow: 'hidden',
								fontSize: '12px',
							}
						: {}
				}></div>
		)
	}
}
