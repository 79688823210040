import React, { PureComponent } from 'react'
import { getMyInfo } from './ActionCreators'
import UserInfo from './userInfo'
import UserArchive from './userArchive'
import './index.less'
export default class Mine extends PureComponent {
	state = {
		myInfo: [],
	}

	async componentWillMount() {
		const myInfo = await getMyInfo()
		this.setState({ myInfo: myInfo.data })
	}

	render() {
		return (
			<div className='user-card'>
				<UserInfo myInfo={this.state.myInfo}></UserInfo>
				<UserArchive myInfo={this.state.myInfo}></UserArchive>
			</div>
		)
	}
}
