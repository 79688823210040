import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import { connect } from 'react-redux'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { ArchiveTypeColumns } from '@/Public/PubTable/pageColumns'
import { archiveTypeUrl } from '@/Utils/Urls.js'
import AddTypeModal from './AddTypeModal'
import ArchiveDepartmentTree from './ArchiveDepartmentTree'
import TypeDetailModal from './TypeDetailModal' // 查看案卷或档案（文件）详情的模态框
import NeedVerifyModal from './NeedVerifyModal'
import UpdateRoleTypeModal from './UpdateRoleTypeModal' // 绑定主管角色的模态框
import { treeUtil } from '@/Utils'
import { Space, Button, Modal, message, Spin } from 'antd'
import './index.less'
import {
	deleteTypeThunk,
	updateTypeThunk,
	addDossierTypeThunk,
	getRoleThunk,
	updateRoleTypeThunk,
	batchUpdateVerifyThunk,
} from './ActionCreators'
import { wordLimit } from '../DepartmentManagement/ActionCreators'

const typeDetailModalRef = React.createRef(null) // 获取查看模态框表单实例
const updateRoleTypeModalRef = React.createRef(null) // 获取角色模态框表单实例
let addmodalRef = React.createRef(null)
let typeDepartmentTreeRef = null // 类型树实例
const markName = 'ArchiveTypeManagement' // redux的标识
const mapStateToProps = state => ({
	pub_rowSelection: state.pubTable.pub_rowSelection,
})
const mapDispatchToProps = dispatch => ({
	deleteType: (...rest) => dispatch(deleteTypeThunk(...rest)), // 删除
	updateType: (...rest) => dispatch(updateTypeThunk(...rest)), // 编辑
	addDossierType: (...rest) => dispatch(addDossierTypeThunk(...rest)), // 新增
	getRole: (...rest) => dispatch(getRoleThunk(...rest)), // 获取角色
	updateRoleType: (...rest) => dispatch(updateRoleTypeThunk(...rest)), // 绑定主管角色
	batchUpdateVerify: (...rest) => dispatch(batchUpdateVerifyThunk(...rest)), // 批量修改档案类型审核角色
})
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class ArchiveTypeManagement extends Component {
	state = {
		record: null, // 当前操作（查看后入库、回退、加入收藏夹）的行数据
		recordIndex: -1, // 当前操作行数据在当前页数据的 index 值
		selectedNodeMsg: {}, // 选中的树节点的信息
		roleMap: {}, //角色id和name映射
		rowSelection: [], // 多选选中的项
		spinning: false, // 是否处于请求中
	}

	componentDidMount() {
		this.props.getRole().then(res => {
			let roleMap = {}
			res.records.forEach(item => {
				roleMap[item.id] = item.description
			})
			this.setState({
				roleMap,
			})
		})
	}

	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{
				type: 'Input',
				name: 'categoryCode',
				label: '分类代码：',
				width: '300px',
				rules: [
					{
						required: false,
						type: 'string',
						len: wordLimit['f'],
						message: `请输入${wordLimit['f']}位分类代码`,
					},
				],
			},
			{ type: 'Input', name: 'typeName', label: '分类名称：', width: '300px' },
			{
				type: 'Extra',
				name: 'archiveButtonList',
				render: formRef => {
					const text = 0 // 1：需要审核，0：无需审核，用于展示审核配置的模态框，多选的批量操作不需要用到，故在此直接写死为 0
					return (
						<Space>
							<Button
								className='btn-success iconfont-buttons'
								title='新增'
								onClick={() => this.viewAddModal(null, -1)}>
								&#xe634;
							</Button>
							{/*
							<Button
								className='iconfont-buttons'
								type='primary'
								title='审核'
								onClick={() =>
									this.examineClick(
										text,
										this.props.pub_rowSelection?.[markName]
									)
								}>
								审核
							</Button>
							*/}
						</Space>
					)
				},
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'get',
			url: archiveTypeUrl.searchDossierType,
			ContentType: 'application/json',
		},
	}
	// 选中节点触发并重新获取表格信息
	updateSelectNode = selectedNodeMsg => {
		const { pub_getAllData } = this.props
		this.setState({ selectedNodeMsg }, pub_getAllData)
	}
	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 */
	changeSearchLimit = data => {
		const { selectedNodeMsg } = this.state
		// 如果点击根节点或初始状态则获取全部（不传部门id）
		if (JSON.stringify(selectedNodeMsg) !== '{}' && selectedNodeMsg['id'] !== -1) {
			data['id'] = selectedNodeMsg['id']
		}
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = data => {
		let records = []
		treeUtil.flatTree(data, data => {
			if (data.id !== -1) {
				records.push(data)
			}
		})
		records.forEach((v, i) => {
			records.forEach((value, index) => {
				if (v.parentId === value.id) {
					v.parentNode = value.typeName
				}
				if (v.parentId === -1) {
					v.parentNode = '档案馆'
				}
				if (v.defaultRetentionPeriod === 1) {
					v.retentionPeriod = '永久'
				}
				if (v.defaultRetentionPeriod === 2) {
					v.retentionPeriod = '30年'
				}
				if (v.defaultRetentionPeriod === 3) {
					v.retentionPeriod = '10年'
				}
			})
		})

		data = {
			data: records,
			pageSize: data.size,
			current: data.current,
			total: data.total,
		}
		return data
	}
	// 重新获取树数据和表格数据
	reloadAllData = () => {
		const { pub_getAllData } = this.props
		pub_getAllData() // 重新获取表格数据
		typeDepartmentTreeRef.getTree() // 重新获取树数据
	}

	// 删除按钮
	handleDeleteType = record => {
		const { deleteType } = this.props // 删除接口
		deleteType(record).then(() => {
			if (record.id === this.state.selectedNodeMsg.id) {
				// 如果删掉了目前选中的节点，那么就不能再次获取当前节点的部门列表了，而是获取全部，因此设置为 {}
				this.setState(
					{
						selectedNodeMsg: {},
					},
					this.reloadAllData
				)
			} else {
				this.reloadAllData()
			}
		})
	}

	// 新增操作
	viewAddModal = (record, recordIndex) => {
		this.setState({ record, recordIndex }, () => {
			addmodalRef.current.show(formRef => {
				formRef.setFieldsValue({ parentId: this.state.selectedNodeMsg.id })
			})
		})
	}

	// 查看详情模态框
	viewDetailModal = (record, recordIndex) => {
		this.setState({ record, recordIndex }, () => {
			typeDetailModalRef.current.show(formRef => {
				formRef.setFieldsValue(record)
			})
		})
	}

	// 编辑申请
	handleUpdateApply = () => {
		const { updateType, pub_getAllData } = this.props
		let updateData = typeDetailModalRef.current.formRef.getFieldsValue(true)
		let data = {
			id: updateData.id,
			typeName: updateData.typeName,
			categoryCode: updateData.categoryCode,
			parentId:
				typeof updateData.parentId === 'number' ? updateData.parentId : null,
			defaultRetentionPeriod: updateData.defaultRetentionPeriod,
		}
		if (updateData) {
			// 通过点击模态框内部的编辑按钮
			updateType(data).then(() => {
				pub_getAllData()
				typeDetailModalRef.current.hide()
			})
		}
	}

	// 查看角色模态框
	viewRoleTypeModal(record, recordIndex) {
		this.setState({ record, recordIndex }, () => {
			this.props.getRole().then(res => {
				let dataSource = res.records
				const selectedRowKeys = record.allowedRole // 已选中项key
				for (let i = 0; i < dataSource.length; i++) {
					if (selectedRowKeys.indexOf(dataSource[i]['id']) > -1) {
						dataSource[i]['status'] = true
					} else {
						dataSource[i]['status'] = false
					}
				}
				updateRoleTypeModalRef.current.show(formRef => {
					formRef.setFieldsValue({
						allowedRole: { dataSource, selectedRowKeys },
					})
				})
			})
		})
	}

	// 编辑主管角色
	handleUpdateRoleType = async () => {
		const { updateRoleType, pub_getAllData } = this.props
		const { record } = this.state
		let formData = await updateRoleTypeModalRef.current.formRef.getFormData() // 获取表格中的所选列
		let updateData = {
			categoryCodeId: record.id,
			roleId: formData.allowedRole.selectedRowKeys,
		}
		updateRoleType(updateData).then(() => {
			pub_getAllData()
			updateRoleTypeModalRef.current.hide()
		})
	}

	// 查看详情
	returnButton = (record, index) => {
		return (
			<Space>
				<Button onClick={() => this.viewDetailModal(record, index)} size='small'>
					查看
				</Button>
				{/* 应老师要求屏蔽档案类型管理的角色按钮 */}
				{/* <Button onClick={() => {
          this.viewRoleTypeModal(record, index);
        }} size="small">角色</Button> */}
				<Button
					className='table-btn-danger'
					onClick={() => this.handleDeleteType(record)}
					size='small'>
					删除
				</Button>
			</Space>
		)
	}

	// 关闭模态框后一些数据进行初始化
	hideCallback = () => this.setState({ record: null, recordIndex: -1 })

	// 自定义渲染列
	changeColumns = ArchiveTypeColumns => {
		const { roleMap } = this.state
		ArchiveTypeColumns.forEach(item => {
			if (Object.keys(item)[0] === 'needVerify') {
				item.render = (text, record) => {
					return (
						<Button
							type='link'
							className='theme-topic-btn'
							onClick={() => {
								this.examineClick(text, record)
							}}>
							{text ? '需要审核' : '无需审核'}
						</Button>
					)
				}
			}
			if (Object.keys(item)[0] === 'role') {
				item.render = text => {
					return roleMap[text]
				}
			}
			if (Object.keys(item)[0] === 'allowedRole') {
				item.render = text => {
					let newText = text.map(item => {
						return roleMap[item]
					})
					return newText.join('、')
				}
			}
			if (Object.keys(item)[0] === 'retentionPeriod') {
				item.render = text => {
					return text
				}

			}
		})
		return ArchiveTypeColumns
	}

	// 点击审核栏回调
	examineClick = (text, record) => {
		const isBatch = Array.isArray(record) ? true : false // 是否为点击审核按钮的批量更改
		if (isBatch && record.length === 0) {
			// 如果点击审核按钮的批量更改，那么 record 为选中档案类型组成的数组
			message.info('请至少选一项')
			return
		}
		const { updateType, pub_getAllData, batchUpdateVerify } = this.props
		let onSummit
		const getFormData = value => {
			onSummit = value
		}
		if (isBatch) {
			// 点击审核按钮的批量更改
			const ids = record.map(item => item.id) // 获取被选中审核的id数组
			Modal.confirm({
				title: '审核配置',
				icon: <></>,
				content: <NeedVerifyModal record={record} getFormData={getFormData} />,
				onOk: () =>
					Modal.confirm({
						maskClosable: true,
						title: '编辑分类',
						content: '确定保存？',
						okType: 'danger',
						onOk: () => {
							Modal.destroyAll() // 销毁模态框
							this.setState({ spinning: true }) // 发起请求时让表格处于加载状态
							return onSummit().then(value => {
								batchUpdateVerify({
									ids,
									...value,
								})
									.then(() => {
										pub_getAllData()
									})
									.finally(() => {
										Modal.destroyAll() // 销毁模态框
										this.setState({ spinning: false }) // 请求完成后隐藏表格的加载状态
									})
							})
						},
					}),
			})
		} else {
			// 点击审核文字的单个更改
			Modal.confirm({
				title: '审核配置',
				icon: <></>,
				content: <NeedVerifyModal record={record} getFormData={getFormData} />,
				onOk: () => {
					return onSummit().then(value => {
						updateType({
							id: record.id,
							...value,
						}).then(() => {
							pub_getAllData()
						})
					})
				},
			})
		}
	}

	render() {
		const { addFonds, pub_getAllData, selectedNodeMsg } = this.props
		const { record, spinning } = this.state
		return (
			<Fragment>
				<div className='type-department-wrapper'>
					{/* 左边部门树组件 */}
					<div id='type-depart-left'>
						<ArchiveDepartmentTree
							updateSelectNode={this.updateSelectNode} // 更新选中信息的方法
							selectedNodeMsg={selectedNodeMsg} // 当前树选中节点的信息
							handleDeleteType={this.handleDeleteType} // 删除部门的方法
							pub_getAllData={pub_getAllData} // 更新右边表格的方法
							getInstance={ref => {
								typeDepartmentTreeRef = ref
							}} // 让页面获取树组件实例的方法，由 WithRef 高阶去调用
						/>
					</div>
					<div id='type-depart-right'>
						<WrappedTable>
							<SearchForm
								name='archiveType-search-form'
								formItems={this.searchFormConfig}
								changeSearchLimit={this.changeSearchLimit}
								changeTableData={this.changeTableData}
								markName={markName}
							/>
							<Spin tip='保存中...' spinning={spinning}>
								<BaseTable
									tableConfig={this.changeColumns(ArchiveTypeColumns)}
									actions={this.actions}
									changeSearchLimit={this.changeSearchLimit}
									changeTableData={this.changeTableData}
									markName={markName}
									operationButton={this.returnButton} // 自定义操作按钮
									withPreview={false}
								// notRowSelection
								/>
							</Spin>

							{/* 新增类型 */}
							<AddTypeModal
								modalRef={addmodalRef}
								addFonds={addFonds}
								pub_getAllData={pub_getAllData} // 刷新
								record={record} // 表格详情信息
								reloadAllData={this.reloadAllData}
							/>
							{/* 详情宗卷 */}
							<TypeDetailModal
								modalRef={typeDetailModalRef}
								record={record} // 表格详情信息
								handleUpdateApply={this.handleUpdateApply}
							/>
							{/* 主管角色 */}
							<UpdateRoleTypeModal
								modalRef={updateRoleTypeModalRef}
								record={record} // 表格详情信息
								handleUpdateRoleType={this.handleUpdateRoleType}
							/>
						</WrappedTable>
					</div>
				</div>
			</Fragment>
		)
	}
}
export default ArchiveTypeManagement
