import _fetch from '@/Utils/Fetch'
import { message } from 'antd'
import { ePhotoAlbum } from '@/Utils/Urls'

// 获取编研主题树
export const getThemeTreeThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: ePhotoAlbum.getThemeTree,
			type: 'get',
			isJson: true,
			data: {
				id,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取编研主题树
export const addThemeThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: ePhotoAlbum.addTheme,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

/**
 * 删除编研主题
 * @param {Number} id 申请id
 */
export const deleteTypeThunk = record => () =>
	new Promise(resolve => {
		_fetch({
			url: ePhotoAlbum.deleteTheme,
			type: 'post',
			data: { ids: record },
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('删除成功')
				resolve(res.data)
			}
		})
	})

// 获取编辑器文本
export const pullThunk = themeId => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: ePhotoAlbum.pull,
			type: 'get',
			isJson: true,
			data: {
				themeId,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

// 保存编辑器文本
export const saveThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: ePhotoAlbum.save,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 发布推文
export const publishRichTextThunk = themeId => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: ePhotoAlbum.publishRichText,
			type: 'get',
			isJson: true,
			data: {
				themeId,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})
