import React, { Component } from 'react'
import { Select, message, Button, Upload } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'
import _fetch from '@/Utils/Fetch.js'
import { injectUnmount } from '@/Utils'
import FaceRecognitionCrop from '../RecognitionButton/FaceRecognitionCrop'

/**
 * 普通下拉框
 * @param {Object} config 高阶组件配置对象
 */
export const proSelect = config => {
	let { disabled, allowClear } = config
	return (
		<Select
			placeholder={config.placeholder || ''} // 下拉框提示文字
			allowClear={allowClear === false ? allowClear : true} // 是否允许清空选项
			options={config.options} // 数据化配置选项内容
			className={`${config.propsClass} search-select`} // 配置的class类名
			disabled={disabled} // 自定义禁不禁用
		/>
	)
}

/**
 * 异步下拉框, 将配置的异步请求函数的结果作为下拉框的选项列表
 * @param {*} config 高阶组件配置对象
 * @param {*} formRef form表单的ref.current(包含表单的所有方法)
 */
export const proAsyncSelect = (config, formRef) => {
	@injectUnmount
	class AsyncSelect extends Component {
		constructor(props) {
			super(props)
			this.state = {
				loading: true, // 异步加载过程中loading
				options: [], // 下拉框的选项
			}
		}

		/**
		 * 此处用来异步获取下拉框的列表并设置初始值
		 * 如果有传入 initialValue 则初始值为 initialValue，否则默认选中第一个
		 */
		componentDidMount() {
			const {
				initialValue, // 对应属性默认值
				name, // 对应属性名
				ajaxConfig, // 异步请求配置对象
				formatDataFunc, // 将请求到的数据转换为 options 格式的函数
			} = config
			_fetch(ajaxConfig).then(async res => {
				if (res && res.data.code === 200) {
					let data = res.data.data
					// 将请求数据转换为 { label, value }[]，如果有配置formatDataFunc则调用它，如果无该配置则使用默认从后台拿到的数据
					let options = formatDataFunc ? await formatDataFunc(data) : data
					this.setState(
						{
							options,
							loading: false,
						},
						() => {
							// 如果有传入initialValue则在获取后台数据后将该项的值设置为默认值
							// formRef.setFieldsValue({[name]: initialValue || options[0].value })
							if (initialValue) {
								formRef.setFieldsValue({ [name]: initialValue })
							}
						}
					)
				}
			})
		}

		/**
		 *
		 * 将修改后的 currency 传入 antd 的 FormItem 所赋予的 onChange 事件中，使数据得以被管理
		 * @param {any} currency 修改后的表单项的值
		 */
		triggerChange = currency => {
			const { onChange } = this.props
			if (onChange) {
				onChange(currency)
			}
		}

		render() {
			const { loading, options } = this.state
			const { value } = this.props
			const { placeholder, propsClass, mode } = config
			// const { handleCurrencyChange, currency, selectList, searchFunc, propsClass } = this.props;
			return (
				<Select
				    showSearch
					mode={mode} //配置成多选框
					loading={loading} // 设置加载中状态，默认为true，请求数据成功后修改为false
					allowClear // 允许清空选项
					options={options} // 数据化配置选项内容
					value={value} // 指定当前选中的条目
					placeholder={placeholder || ''} // 下拉框提示文字
					onChange={this.triggerChange} // 	选中 option，或 input 的 value 变化时，调用此函数
					className={`${propsClass} search-select`} // 配置的class类名
				/>
			)
		}
	}

	return <AsyncSelect />
}

/**
 * 自动分词下拉框
 * @param {Object} config 高阶组件配置对象
 */
export const proParticiplesSelect = (config, formRef) => {
	@injectUnmount
	class InnerClass extends Component {
		state = {
			file: null,
			imgFileList: [],
			imgUrl: null,
		}
		/**
		 *
		 * 将修改后的 currency 传入 antd 的 FormItem 所赋予的 onChange 事件中，使数据得以被管理
		 * @param {any} currency 修改后的表单项的值
		 */
		triggerChange = currency => {
			const { onChange } = this.props
			if (onChange) {
				onChange(currency)
			}
		}

		handleBeforeUpload = file => {
			const { type } = file
			if (type.startsWith('image')) {
				const reader = new FileReader()
				reader.addEventListener('load', () => {
					this.setState({
						file,
						imgUrl: reader.result,
					})
				})
				reader.readAsDataURL(file)
				return true
			}
			message.info('请上传图片格式的文件')
			return false
		}

		// 关闭裁剪框的回调
		closeCropper = () => {
			this.setState({
				imgUrl: null,
			})
		}

		setFormFields = faceDataNameList => {
			const { value = [] } = this.props
			formRef.setFields([
				{
					name: config.name,
					value: Array.from(new Set([...value, ...faceDataNameList])),
				},
			])
		}

		render() {
			const { value } = this.props
			const { imgUrl, file } = this.state
			const isPeople = config.label === '人物'
			return (
				<>
					<div
						style={{
							display: 'flex',
							width: config.inputWidth || '180px',
							fontSize: '14px',
						}}>
						<Select
							mode='tags'
							maxTagCount='2'
							placeholder={config.placeholder || ''} // 下拉框提示文字
							style={
								isPeople
									? { width: 'calc(100% - 40px)' }
									: { width: '100%' }
							}
							allowClear // 允许清空选项
							value={value} // 指定当前选中的条目
							title={value}
							tokenSeparators={[',', '，', ';', '；']}
							onChange={this.triggerChange}
							className={`${config.propsClass} search-select`} // 配置的class类名
						></Select>

						{config.label === '人物' && (
							<Upload
								beforeUpload={this.handleBeforeUpload}
								customRequest={() => {}}
								fileList={[]}>
								<Button
									style={{ width: '40px', padding: '0' }}
									title='上传人物信息'>
									<UserAddOutlined />
								</Button>
							</Upload>
						)}

						{
							<FaceRecognitionCrop
								file={file}
								imgUrl={imgUrl}
								value={value}
								closeCropper={this.closeCropper}
								setFormFields={this.setFormFields}
							/>
						}
					</div>
				</>
			)
		}
	}

	return <InnerClass />
}
