import React, { Component } from 'react'
import UploadFiles from './UploadFiles'
import FillInformation from './FillInformation'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	selectTemporaryArchiveThunk,
	getFondsIdentifierThunk,
	getDossierTypeThunk,
	getDepartmentTreeThunk,
} from './ActionCreators'
import './index.less'

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	selectTemporaryArchive: (...rest) => dispatch(selectTemporaryArchiveThunk(...rest)), // 查询临时文件
	getFondsIdentifier: (...rest) => dispatch(getFondsIdentifierThunk(...rest)), // 获取全宗号
	getDossierType: (...rest) => dispatch(getDossierTypeThunk(...rest)), // 获取类别号
	getDepartmentTree: (...rest) => dispatch(getDepartmentTreeThunk(...rest)), // 获取部门
})
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class NewFiles extends Component {
	constructor(props) {
		super(props)
		const { search } = this.props.location
		const paramsString = search.substring(1)
		const searchParams = new URLSearchParams(paramsString)
		// this.state = {
		//   current: Number(searchParams.get('current') ?? 0),
		//   archiveType: Number(searchParams.get('archiveType') ?? 1),
		//   checkedArr: [Number(searchParams.get("checkedId"))].filter(Boolean),
		//   archiveId: Number(searchParams.get('archiveId') ?? 0),
		//   markName: searchParams.get('markName') ?? '',
		// };
		this.state = {
			current: Number(searchParams.get('current') ?? 0),
			archiveType: Number(
				searchParams.get('current')
					? JSON.parse(sessionStorage.getItem('temporaryArchive'))?.archiveType
					: 1
			),
			checkedArr: searchParams.get('current')
				? [
						JSON.parse(sessionStorage.getItem('temporaryArchive'))?.checkedId,
					].filter(Boolean)
				: [],
			archiveId: Number(
				searchParams.get('current')
					? JSON.parse(sessionStorage.getItem('temporaryArchive'))?.archiveId
					: 0
			),
			markName: searchParams.get('current')
				? JSON.parse(sessionStorage.getItem('temporaryArchive'))?.markName
				: '',
		}
	}

	setParentState = (states, callback) =>
		this.setState({ ...states }, () => {
			callback && callback()
		})
	getParentCurrent = () => this.state.current

	render() {
		const { current, checkedArr, archiveType } = this.state
		return (
			<div>
				{current === 0 && (
					<UploadFiles
						setParentState={this.setParentState}
						lastCheckedArr={checkedArr}
					/>
				)}
				{current === 1 && (
					<FillInformation
						setParentState={this.setParentState}
						checkedArr={checkedArr}
						getParentCurrent={this.getParentCurrent}
						archiveType={archiveType}
						archiveId={this.state.archiveId}
						markName={this.state.markName}
					/>
				)}
			</div>
		)
	}
}

export default NewFiles
