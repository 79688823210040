import _fetch from '@/Utils/Fetch'
import { departmentUrl } from '@/Utils/Urls'
import { Modal, message } from 'antd'
// 消息提示
export const tips = {
	DELETE_SUCCESS: '删除部门成功',
	ADD_SUCCESS: '新增部门成功',
	UPDATE_SUCCESS: '更新部门信息成功',
}

/**
 * 获取部门树
 * @param {Number} id 部门id（不填查询所有的部门）
 */
export const getDepartmentTreeThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: departmentUrl.getDepartmentTree,
			data: { id },
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})

export const updateDepartmentTree = data => {
	return _fetch({
		url: departmentUrl.updateDepartmentTree,
		type: 'post',
		isJson: true,
		data,
	})
}

/**
 * 删除部门
 * @param {Object} node 要删除的节点信息
 */
export const deleteDepartmentThunk = node => () =>
	new Promise((resolve, reject) => {
		const { departmentName, id } = node
		Modal.confirm({
			title: '删除节点',
			content: `删除之后将不可恢复，请问是否继续删除“${departmentName}”`,
			okText: '确认',
			cancelText: '取消',
			okType: 'danger',
			maskClosable: true,
			onOk: () => {
				_fetch({
					url: departmentUrl.deleteDepartment,
					data: { id: String(id) },
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.DELETE_SUCCESS)
						resolve()
					}
				})
			},
		})
	})

/**
 * 新增部门
 * @param {Object} data
 * {
 *   managerId            [string]	 部门管理员
 *   department: {
 *     departmentName	    [string]	 部门名
 *     departmentNumber	  [string]	 部门编号
 *     description	      [string]	 部门描述
 *     parentId	          [number]	 父部门id
 *   }
 * }
 */
export const addDepartmentThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: departmentUrl.addDepartment,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success(tips.ADD_SUCCESS)
				resolve()
			} else {
				reject()
			}
		})
	})

/**
 * 更新部门信息
 * @param {Object} data
 * {
 *   managerId            [string]	 部门管理员
 *   department: {
 *     departmentName	    [string]	 部门名
 *     departmentNumber	  [string]	 部门编号
 *     description	      [string]	 部门描述
 *     id	                [number]	 部门id
 *   }
 * }
 */
export const updateDepartmentThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: departmentUrl.updateDepartment,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success(tips.UPDATE_SUCCESS)
				resolve()
			} else {
				reject()
			}
		})
	})

export const wordLimit = JSON.parse(localStorage.getItem('serialNumberConfig'))
