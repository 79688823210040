import React, { Component, Fragment } from 'react'
import { Button, Collapse, Divider } from 'antd'
import { PubForm } from '@/Public/PubForm'
import { applicationEnum } from '@/Utils/Enum'
import Enum from '@/Utils/Enum'
import { connect } from 'react-redux'
import './index.less'
import DrawerDetails from './DrawerDetails'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import { homePath } from '@/Components/Login/LoginForm'

import DrawerFix from './DrawerFix'
import {
	addFixDataAction,
	changeFixDataStatusAction,
	getVerifyDetailThunk,
	recallApplyThunk, // 撤回
	deleteFixApplyThunk, // 删除
	verifyApplyThunk, // 审核
	submitFixApplyThunk, // 提交
	updateFixApplyThunk, // 编辑
	routeChangeAction,
} from './ActionCreator'

const { Panel } = Collapse
// 审核结果枚举
const verifyStatusEnum = new Enum(['拒绝', '同意', '回退'])

/**
 * 功能:删除tab栏时，计算出下一次激活的tab
 * 删除激活的tab，若它是第一个，激活最后一个。若不是，激活前一个
 * 删除非激活的tab，那没事了
 * @param {Array} routes
 * @param {string} route
 * @param {string} targetKey
 * @return {string}
 */
function getNextActiveKey(routes, route, targetKey) {
	let nextActiveKey = ''
	if (routes.length === 1) {
		return homePath
	}
	if (targetKey === route) {
		if (routes[0].key === targetKey) {
			return routes[routes.length - 1].key
		} else {
			routes.every(item => {
				item.key !== targetKey && (nextActiveKey = item.key)
				return item.key !== targetKey
			})
		}
		return nextActiveKey
	}
	return route
}

const mapStateToProps = state => {
	return {
		type: state.fixDetails.category, // 获取申请表类型
		fixData: state.fixDetails.fixData, // 获取申请表数据
		routes: state.route.items,
		route: state.route.item,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		getFixDetail: (...rest) => dispatch(getVerifyDetailThunk(...rest)), // 详情
		deleteFixApply: (...rest) => dispatch(deleteFixApplyThunk(...rest)), // 删除
		recallFixApply: (...rest) => dispatch(recallApplyThunk(...rest)), // 撤回
		verifyApply: (...rest) => dispatch(verifyApplyThunk(...rest)), // 审核
		submitFixApply: (...rest) => dispatch(submitFixApplyThunk(...rest)), // 提交
		updateFixApply: (...rest) => dispatch(updateFixApplyThunk(...rest)), // 编辑
		addFixData: (data, category) => dispatch(addFixDataAction(data, category)), // 添加
		changeFixDataStatus: buttonType =>
			dispatch(changeFixDataStatusAction(buttonType)), // 更改状态
		removeTab(targetKey, history, route, routes) {
			let nextActiveKey = getNextActiveKey(routes, route, targetKey)
			dispatch({ type: 'ROUTE_CHANGE', filter: nextActiveKey })
			dispatch({ type: 'TAB_REMOVE', filter: targetKey })
			if (history) {
				history.push(
					linkTo.to(
						typeof nextActiveKey === 'string'
							? nextActiveKey
							: nextActiveKey.url
					)
				)
			} else {
				dispatch(routeChangeAction(nextActiveKey))
			}
		},
	}
}
@connect(mapStateToProps, mapDispatchToProps)
class FixDetails extends Component {
	state = {
		editStatus: false, // 编辑状态 false不可编辑,true可编辑
		current: 0, // 分页
	}

	// 为了实时渲染
	setPubForm = () => {
		const {
			verifyApply, // 审核
		} = this.props
		const data = this.props.fixData[this.props.fixData.length - 1] // 原先数据
		const id = data.applyId // 申请表id
		const buttonType = data.fix_status // 按钮类型
		const formType = this.props.type // 申请表类型(申请人员&&审批人员)
		// PubForm 表单项配置数组
		let formItems = [
			// 基本表单内容
			[
				{
					type: 'Extra',
					name: 'Title',
					render: formRef => {
						return <h1>修正申请表</h1>
					},
					span: 23, // span：23代表无边框表单组件
				},
			],
			[
				{
					type: 'Input',
					name: 'applyCode',
					label: '申请编号',
					disabled: true,
					bordered: false,
					span: 6,
					rules: [{ required: false }],
				},
				{
					type: 'Input',
					name: 'id',
					label: 'ID',
					span: 0,
					disabled: true,
					bordered: false,
					rules: [{ required: false }],
				},
				{
					type: 'Select',
					name: 'fix_status',
					label: '处理状态',
					options: applicationEnum.status.toOptions(), // 申请的处理状态
					span: 6,
					disabled: true,
					bordered: false,
					rules: [{ required: false }],
				},
				{
					type: 'Input',
					name: 'applyUser',
					label: '申请人',
					span: 6,
					disabled: true,
					bordered: false,
					rules: [{ required: false }],
				},
				{
					type: 'DatePicker',
					name: 'createTime',
					label: '申请时间',
					span: 6,
					disabled: true,
					bordered: false,
					rules: [{ required: false }],
				},
			],
			[
				{
					type: 'TextArea',
					name: 'fixContent',
					label: '修改详情',
					colclassname: 'end-table',
					disabled: !this.state.editStatus,
					rules: [{ required: true }],
					maxLength: 1500,
					showCount: true,
				},
			],
			[
				{
					type: 'Extra',
					name: 'ButtonFix',
					render: formRef => {
						// 设置修改前后的框框
						if (this.state.editStatus) {
							// 判断是否为编辑状态,是的话返回专门的修正文件框框
							return (
								<Button
									type='primary'
									onClick={() => {
										const { fixContent, reason, id } =
											formRef.getFieldValue()
										sessionStorage.setItem(
											'fixDataContent',
											JSON.stringify({ fixContent, reason, id })
										)
										this.setState({ current: 1 })
									}}>
									修改文件详情
								</Button>
							)
						} else {
							return (
								<DrawerDetails
									drawerKey={0}
									beforeData={this.props.fixData[0].beforeFix}
									afterData={
										this.props.fixData[this.props.fixData.length - 1]
											.afterFix
									}
								/>
							)
						}
					},
					span: 22, // span:22 代表把该表单组件放在上个表格的右下角
				},
			],
			[
				{
					type: 'TextArea',
					name: 'reason',
					label: '申请原因',
					disabled: !this.state.editStatus,
					rules: [{ required: true }],
					maxLength: 1500,
					showCount: true,
				},
			],
			[
				{
					type: 'Extra',
					name: 'ButtonList', // 按钮组，用于进行按钮点击事件
					render: formRef => {
						return (
							<div className='operation-button'>
								{this.setButtonList(formRef)}
							</div>
						)
					},
					span: 22, // span：23代表无边框表单组件
				},
			],
			[
				{
					type: 'TextArea',
					name: `result`,
					label: '审批意见',
					rules: [{ required: this.props.type }],
					disabled: !(
						this.props.type &&
						this.props.fixData[this.props.fixData.length - 1].fix_status ===
						1 &&
						this.props.type === 1
					),
					maxLength: 1500,
					showCount: true,
				},
			],
			[
				{
					type: 'InputWithPicker',
					name: `reviewUserAndTime`, // 审批人和审批时间
					keys: ['userName', 'reviewTime'],
					label: '审批人',
					rules: [{ required: false }],
					span: 22, // span:22 代表把该表单组件放在上个表格的右下角
					disabled: true,
				},
			],
			[
				{
					type: 'Radio',
					name: `fixApply_status`,
					label: '审核结果',
					options: verifyStatusEnum.toOptions(),
					disabled: !(
						this.props.type &&
						this.props.fixData[this.props.fixData.length - 1].fix_status ===
						1 &&
						this.props.type === 1
					),
					rules: [{ required: true }],
					span: 21, // span：21 代表把表单组件放在上个表格的左下角
					initialValue: 1,
				},
			],
		]
		if (!this.state.editStatus && formType !== 0 && buttonType === 1) {
			formItems.push([
				{
					type: 'Extra',
					name: 'ButtonList', // 按钮组，用于进行按钮点击事件
					render: formRef => {
						return (
							<Button
								type='primary'
								onClick={() => {
									formRef.validateFields().then(() => {
										const { result, fixApply_status } =
											formRef.getFieldValue() // 获取审核结果和意见
										verifyApply({
											// 发出请求
											applyId: [id],
											reviewResult: result,
											status: fixApply_status,
										}).then(() => {
											this.updateApprovalFormData(data)
										}) // 提交成功到审批状态
									})
								}}
								style={{
									marginRight: '40px',
								}}>
								审核
							</Button>
						)
					},
					span: 22,
				},
			])
		}
		return formItems
	}

	// 设置按钮组
	setButtonList = formRef => {
		const {
			deleteFixApply, // 删除
			recallFixApply, // 撤回
			submitFixApply, // 提交
			updateFixApply, // 编辑
			changeFixDataStatus, // 更改状态
		} = this.props

		const data = this.props.fixData[this.props.fixData.length - 1] // 原先数据
		const id = data.applyId // 申请表id
		const buttonType = data.fix_status // 按钮类型
		const formType = this.props.type // 申请表类型(申请人员&&审批人员)

		if (this.state.editStatus) {
			// 第一层判断是否编辑状态
			return (
				<>
					<Button
						onClick={() => {
							// 判空
							formRef.validateFields().then(() => {
								// 获取存储的值
								let storeAfter = data.afterFix?.newdata || {}
								// 整理新值
								let finishData = {}
								// 如果有存储的值
								if (storeAfter) {
									// 判断是否为文件或者案卷
									if (!storeAfter.type) {
										// 文件
										let metadataList = []

										// 基本信息
										finishData['archive'] = {
											categoryCodeId: storeAfter.categoryCodeId, // 类别号
											confidentialLevel:
												storeAfter.confidentialLevel, // 密级（0公开 1内部 2绝密 3机密 4秘密）
											retentionPeriod: storeAfter.retentionPeriod, // 保管期限（1永久 2表示30年 3表示10年）
											departmentId: storeAfter.departmentId, // 所属部门
											seeNumber: storeAfter.seeNumber, // 参见号
											topic: storeAfter.topic, // 题名
											keyWord: storeAfter.keyWord, // 关键词
											fileId: storeAfter.fileId,
										}

										// 有新文件id则放
										if (storeAfter.fileId) {
											finishData.archive.fileId = storeAfter.fileId
										}

										// 整理元数据
										for (let i in storeAfter.metaData) {
											if (storeAfter.metaData[i]) {
												metadataList.push({
													metadataId: parseInt(i),
													metadataValue: storeAfter.metaData[i],
												})
											}
										}
										finishData['fileMetadata'] = metadataList
									} else {
										// 案卷
										let archiveIds = []

										// 基本信息
										finishData['dossier'] = {
											categoryCodeId: storeAfter.categoryCodeId, // 类别号
											confidentialLevel:
												storeAfter.confidentialLevel, // 密级（0公开 1内部 2绝密 3机密 4秘密）
											retentionPeriod: storeAfter.retentionPeriod, // 保管期限（1永久 2表示30年 3表示10年）
											departmentId: storeAfter.departmentId, // 所属部门
											seeNumber: storeAfter.seeNumber, // 参见号
											topic: storeAfter.topic, // 题名
											keyWord: storeAfter.keyWord, // 关键词
										}

										// 整理档案组id
										storeAfter.archiveDetail.forEach(record => {
											archiveIds.push(record.id)
										})

										finishData['archiveIds'] = archiveIds
									}

									// 获取申请表内容
									const { fixContent, reason } = formRef.getFieldValue()
									finishData['fixContent'] = fixContent
									finishData['applyReason'] = reason
									finishData['fixApplyId'] = id
								} else {
									// 获取申请表内容
									const { fixContent, reason } = formRef.getFieldValue()
									storeAfter['fixContent'] = fixContent
									storeAfter['applyReason'] = reason
									storeAfter['fixApplyId'] = id
								}

								// 更新
								updateFixApply(finishData || storeAfter).then(res => {
									this.updateApplicationFormData(data)
									sessionStorage.removeItem('fixDataContent')
									this.setState({ editStatus: false })
								})
							})
						}}>
						保存
					</Button>
					<Button
						onClick={() => {
							this.setState({ editStatus: false })
							sessionStorage.removeItem('fixDataContent')
						}}>
						取消
					</Button>
				</>
			)
		} else {
			if (formType === 0) {
				// 第二层判断表单类型
				switch (
				buttonType //第三层判断表单状态
				) {
					case 0: {
						// 申请
						return (
							<>
								<Button
									className='btn-danger iconfont-buttons'
									title='删除'
									onClick={() => {
										deleteFixApply([id]).then(res => {
											this.handleCancel()
										}) // 删除成功返回上一级
									}}>
									&#xe816; 删除
								</Button>
								<Button
									className='btn-warning iconfont-buttons'
									title='编辑'
									onClick={() => {
										this.setState({ editStatus: true }) // 调成可编辑状态
									}}>
									&#xeabd; 编辑
								</Button>
								<Button
									className='iconfont-buttons'
									title='提交'
									type='primary'
									onClick={() => {
										submitFixApply(id).then(res => {
											changeFixDataStatus(1)
										}) // 提交成功到审批状态
									}}>
									&#xe64c; 提交
								</Button>
							</>
						)
					}
					case 1: {
						// 审批
						return (
							<Button
								className='btn-warning'
								onClick={() => {
									recallFixApply([id]).then(res => {
										changeFixDataStatus(0)
									}) // 撤回成功退回至申请
								}}>
								撤回
							</Button>
						)
					}
					case 4: {
						return (
							<>
								<Button
									className='btn-warning iconfont-buttons'
									title='编辑'
									onClick={() => {
										this.setState({ editStatus: true }) // 调成可编辑状态
									}}>
									&#xeabd; 编辑
								</Button>
								<Button
									className='iconfont-buttons'
									title='提交'
									type='primary'
									onClick={() => {
										submitFixApply(id).then(res => {
											changeFixDataStatus(1)
										}) // 提交成功到审批状态
									}}>
									&#xe64c; 提交
								</Button>
							</>
						)
					}
					default: {
					}
				}
			}
		}
	}

	// 删除后路由跳转
	handleCancel = () => {
		const { removeTab, route, routes } = this.props
		removeTab(route, history, route, routes)
	}

	// 刷新数据(审批)
	updateApprovalFormData = record => {
		this.props.getFixDetail(record.applyId).then(res => {
			let status
			switch (
			res[res.length - 1]['status'] // 判断并给状态赋值，审批结果和申请表状态不一样
			) {
				case 0: {
					status = 3
					break
				}
				case 1: {
					status = 2
					break
				}
				case 2: {
					status = 4
					break
				}
				default: {
				}
			}
			const insertData = {
				applyCode: record.applyCode, // 申请编号
				createTime: record.createTime, // 申请时间
				applyUser: record.applyUser, // 申请人
				fix_status: status, // 修正表状态
				applyId: record.applyId, // 申请表id
				fixApply_status:
					res[res.length - 1]['status'] === 3
						? 1
						: res[res.length - 1]['status'], // 因为bug重新改一个命名
			}
			let lastData = res.pop() // 取出最后一个对象
			lastData = {
				// 添加值
				...lastData,
				...insertData,
			}
			res.push(lastData) // 放进去
			this.props.addFixData(res, 1) // 传参，申请表状态
		})
	}

	// 刷新数据(申请)
	updateApplicationFormData = record => {
		this.props.getFixDetail(record.applyId).then(res => {
			const insertData = {
				applyCode: record.applyCode, // 申请编号
				createTime: record.createTime, // 申请时间
				applyUser: record.applyUser, // 申请人
				fix_status: record.fix_status, // 修正表状态
				applyId: record.applyId, // 申请表id
			}
			let lastData = res.pop() // 取出最后一个对象
			lastData = {
				// 添加值
				...lastData,
				...insertData,
			}
			res.push(lastData) // 放进去
			this.props.addFixData(res, 0) // 传参，申请表状态
		})
	}

	// 设置历代详情的formitems
	setFormItems = () => {
		const fixData = this.props.fixData
		let renderFormItem = [] // 基本表单内容
		// 需要插入修改详情和审批意见项
		if (JSON.stringify(fixData) !== '{}') {
			fixData.forEach((detail, n) => {
				const index = n + 1
				const { id } = detail // 审核表id
				renderFormItem.push(
					...[
						[
							{
								type: 'Extra',
								name: `compareButton_${id}`,
								rules: [{ required: false }],
								render: formRef => (
									<div>
										<Divider orientation='left'>
											第{index}次修改申请
										</Divider>
									</div>
								),
							},
						],
						[
							{
								type: 'TextArea',
								name: `fixContent_${id}`,
								label: '修改详情',
								rules: [{ required: false }],
								disabled: true,
							},
						],
						[
							{
								type: 'Extra',
								name: 'ButtonFix',
								render: formRef => {
									// 设置修改前后的框框
									return (
										<DrawerDetails
											drawerKey={n + 1}
											beforeData={detail.beforeFix}
											afterData={detail.afterFix}
										/>
									)
								},
								span: 22, // span:22 代表把该表单组件放在上个表格的右下角
							},
						],
					]
				)

				// 判断是否加结果
				if (fixData && fixData.length && fixData[n]['status'] !== 3) {
					renderFormItem.push(
						...[
							[
								{
									type: 'TextArea',
									name: `result_${id}`,
									label: '审批意见',
									rules: [{ required: false }],
									disabled: true,
									maxLength: 1500,
									showCount: true,
								},
							],
							[
								{
									type: 'InputWithPicker',
									name: `reviewUserAndTime_${id}`, // 审批人和审批时间
									keys: ['userName', 'reviewTime'],
									label: '审批人',
									rules: [{ required: false }],
									span: 22, // span:22 代表把该表单组件放在上个表格的右下角
									disabled: true,
								},
							],
							[
								{
									type: 'Radio',
									name: `status_${id}`,
									label: '审核结果',
									options: verifyStatusEnum.toOptions(),
									rules: [{ required: false }],
									span: 21, // span：21 代表把表单组件放在上个表格的左下角
									disabled: true,
								},
							],
						]
					)
				}
			})
		}

		return renderFormItem
	}

	// 设置申请表详情
	setFormData = formRef => {
		const formdata = this.props.fixData[this.props.fixData.length - 1]
		let data = {}
		if (!formdata) return 0
		const { userName, reviewTime } = formdata
		data['reviewUserAndTime'] = { userName, reviewTime } // 审批人与时间
		if (
			this.props.type === 1 &&
			this.props.fixData[this.props.fixData.length - 1].fix_status === 1
		) {
			data['result'] = '' // 清空
			data['fixApply_status'] = 0 // 清空
		}

		// 如果编辑过,则设置编辑状态并赋值
		let storeAfter = JSON.parse(sessionStorage.getItem('fixDataContent'))
		if (storeAfter && this.props.type !== 1) {
			if (storeAfter.id !== formdata.id) {
				sessionStorage.removeItem('fixDataContent')
			} else {
				!this.state.editStatus && this.setState({ editStatus: true })
				data['fixContent'] = storeAfter.fixContent
				data['reason'] = storeAfter.reason
			}
		}

		let fixdata = {
			...formdata,
			...data,
		}
		formRef.setFieldsValue(fixdata)
	}

	// 设置历代申请详情
	setPreviousFormData = formRef => {
		const detailList = this.props.fixData
		let addDetail = {} // 添加的第几次修改申请数据
		if (JSON.stringify(detailList) !== '{}') {
			detailList.forEach((detail, n) => {
				const { id, userName, reviewTime } = detail // 审核表id
				addDetail[`fixContent_${id}`] = detail['fixContent'] //修改内容
				addDetail[`status_${id}`] = detail['status'] //状态
				addDetail[`result_${id}`] = detail['result'] //结果
				addDetail[`reviewUserAndTime_${id}`] = {
					//审批人信息
					userName,
					reviewTime,
				}
			})
		}

		// 设置参数
		formRef.setFieldsValue(addDetail)
	}

	// 上一页函数
	prev = () => {
		this.setState({ current: 0 })
	}

	render() {
		const { current } = this.state
		const { fixData } = this.props
		if (fixData[this.props.fixData.length - 1] === undefined) {
			return null
		}
		fixData[this.props.fixData.length - 1].afterFix.type = fixData[0].type
		return (
			<div className='steps-content fixDetailsWrapper'>
				{current === 0 && (
					<Fragment>
						<PubForm
							ref={this.formRef}
							formItems={this.setPubForm()}
							getInstance={formRef => {
								this.formRef = formRef
								this.setFormData(this.formRef)
							}}
							name={'FixApplyForm'}
							title='修正申请表'
						/>
						{/* 历代详情模态框 */}
						<Collapse className='previous-form'>
							<Panel header='历代修正详情' key='1'>
								<PubForm
									ref={this.formRef}
									formItems={this.setFormItems()}
									getInstance={formRef => {
										this.setPreviousFormData(formRef)
									}}
									name={'FixPreviousForm'}
									title='历代修正申请表'
								/>
							</Panel>
						</Collapse>
					</Fragment>
				)}
				{current === 1 && (
					// 修改内容页
					<DrawerFix
						toPrevStep={this.prev}
						// 附上文件信息
						fileData={fixData[this.props.fixData.length - 1].afterFix}
					/>
				)}
			</div>
		)
	}
}

export default FixDetails
