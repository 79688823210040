import React, { Component } from 'react'
// 只做一件事，把`WrappedComponent`回传个`getInstance`（如果有的话）
// eslint-disable-next-line import/no-anonymous-default-export
export default WrappedComponent => {
	return class WithRef extends Component {
		static displayName = `WithRef(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
		render() {
			// 这里重新定义一个props的原因是:
			// 你直接去修改this.props.ref在react开发模式下会报错，不允许你去修改
			const props = { ...this.props }
			// 在这里把getInstance赋值给ref，
			// 传给`WrappedComponent`，这样就getInstance能获取到`WrappedComponent`实例
			props.ref = child => {
				if (child) {
					const { getInstance, ref } = this.props
					if (getInstance) {
						getInstance(child)
					} else {
						throw new Error('请确保传入了 ‘getInstance’')
					}
					ref && ref(child)
				}
			}
			return <WrappedComponent {...props} />
		}
	}
}
