import {
	PACK_ARCHIVE_CURRENT,
	PACK_FILES,
	SET_ARCHIVE_DATA,
	SET_FILES_ALL,
	SET_FILES_WITHIN,
	CLEAR_PACK_ARCHIVE,
} from '@/Redux/ActionTypes/PackArchive'
import initialState from '../../InitialState'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState.packArchive, action) => {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case PACK_ARCHIVE_CURRENT:
			return {
				...state,
				current: action.filter,
			}
		case SET_ARCHIVE_DATA:
			return {
				...state,
				archiveData: action.filter,
			}
		case PACK_FILES:
			return {
				...state,
				filesData: action.filter,
			}
		case SET_FILES_ALL:
			return {
				...state,
				hasFilesAll: action.filter,
			}
		case SET_FILES_WITHIN:
			return {
				...state,
				filesWithin: action.filter,
			}
		case CLEAR_PACK_ARCHIVE:
			return {
				current: 0,
				filesData: [],
				archiveData: {},
				hasFilesAll: false,
				filesWithin: [],
			}
		default:
			return state
	}
}
