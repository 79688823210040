import React, { useState } from 'react'
import { Transfer } from 'antd'
import CheckGroup from '../CheckGroup'
import EditGroup from '../EditGroup'

const transferOperations = ['增加 —>', '<— 删除']

/**
 * 自定义穿梭框
 * @param {*} param0
 * @returns
 **/
function EditTransfer({ dataSource, ...restProps }) {
	// transfer组件，左边选中的key，在头部显示 x / dataSource.length
	const [leftSelected, setLeftSelected] = useState([])
	// transfer组件，右边选中的key，在头部显示 x / dataSource.length
	const [rightSelected, setRightSelected] = useState([])

	// 显示在右侧框数据的 key 集合
	const { targetKeys, setTargetKeys, hadConfig } = restProps
	// 数据在左右框，穿梭时触发的回调
	const transferChange = (targetKeys, direction, moveKeys) => {
		setTargetKeys(targetKeys)
		setLeftSelected([])
		setRightSelected([])
	}

	const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
		setLeftSelected(sourceSelectedKeys)
		setRightSelected(targetSelectedKeys)
	}

	return (
		<Transfer
			className={hadConfig ? 'hide-svg-action-down' : ''}
			dataSource={dataSource} // 数据源，除去targetKeys中的数据以外的 数据，将会被渲染到左边一栏中
			targetKeys={targetKeys}
			selectedKeys={[...leftSelected, ...rightSelected]}
			showSelectAll={!hadConfig}
			titles={['选择档案号配置', '排序编辑']}
			onChange={transferChange}
			onSelectChange={handleSelectChange}
			operations={transferOperations}
			{...restProps}>
			{({
				direction,
				disabled,
				filteredItems, // 该函数被调用两次，分别表示左右框的数据。
				onItemSelect,
			}) => {
				if (direction === 'left') {
					return (
						<CheckGroup
							dataSource={filteredItems}
							onItemSelect={onItemSelect}
							selectedKeys={leftSelected}
							{...restProps}
						/>
					)
				} else {
					return (
						<EditGroup
							dataSource={filteredItems}
							targetKeys={targetKeys}
							setTargetKeys={setTargetKeys}
							hadConfig={hadConfig}
							onItemSelect={onItemSelect}
							selectedKeys={rightSelected}
							{...restProps}
						/>
					)
				}
			}}
		</Transfer>
	)
}

export default React.memo(EditTransfer)
