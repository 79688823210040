import { filesUrl, recordFileUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
import asyncPool from '@/Utils/AsyncPool'
import SparkMD5 from 'spark-md5'
import { message } from 'antd'

const spark = new SparkMD5.ArrayBuffer()

/**
 * 文件切片
 * @param {file} file
 */
export const createFileChunk = file => {
	const fileChunkList = []
	const CHUNK_SIZE = 2097152 // 切片大小为 2M

	for (let start = 0; start < file.size; start += CHUNK_SIZE) {
		fileChunkList.push({
			file: file.slice(start, start + CHUNK_SIZE),
		})
	}

	return fileChunkList
}

/**
 * 创建文件的 md5 标识
 * @param {array} fileChunkList
 */
export const getFileMd5 = fileChunkList => {
	let count = 0
	const totalCount = fileChunkList.length
	const fileReader = new FileReader()
	return new Promise((resolve, reject) => {
		fileReader.onload = e => {
			if (e.target && e.target.result) {
				count += 1
				spark.append(e.target.result)
			}
			if (count < totalCount) {
				loadNext()
			} else {
				resolve(spark.end())
			}
		}

		fileReader.onerror = function () {
			reject({
				message: '文件读取失败',
			})
		}

		const loadNext = () => {
			fileReader.readAsArrayBuffer(fileChunkList[count]?.file)
		}

		loadNext()
	})
}

const getChunk = wholeMd5 => {
	return _fetch({
		url: filesUrl.getChunk,
		type: 'get',
		data: {
			wholeMd5,
		},
		isJson: true,
	})
}

/**
 * 上传文件（断点续传）
 * @param {*} fileMd5Value
 * @param {*} fileName
 * @param {*} fileChunkList
 */
export const uploadFile = async (fileMd5Value, fileName, fileChunkList, fileType) => {
	const total = fileChunkList.length
	let fileCurrent = (await getChunk(fileMd5Value))?.data?.data + 1

	fileChunkList = fileChunkList.map(fileChunk => {
		return new File([fileChunk.file], fileName, {
			type: fileType,
			lastModified: Date.now(),
		})
	})

	// eslint-disable-next-line
	for await (const _ of asyncPool(
		2,
		fileChunkList.slice(fileCurrent - 1, total - 1),
		file => {
			const formData = new FormData()
			formData.append('file', file)
			formData.append('wholeMd5', fileMd5Value)
			formData.append('chunks', total)
			formData.append('chunk', fileCurrent)

			fileCurrent++
			return _fetch({
				url: filesUrl.fileUpload,
				type: 'post',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
		}
	)) {
	}

	return new Promise((resolve, reject) => {
		const formData = new FormData()
		const file = fileChunkList[total - 1]

		formData.append('file', file)
		formData.append('wholeMd5', fileMd5Value)
		formData.append('chunks', total)
		formData.append('chunk', total)

		_fetch({
			url: filesUrl.fileUpload,
			type: 'post',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject(res.data)
			}
		})
	})
}

/**
 * 编辑上传新图片
 * @param {object} data 申请数据
 */
export const updateRecordThunk = data => () =>
	new Promise(resolve => {
		// 对档案请求编辑
		_fetch({
			url: recordFileUrl.updateArchiveDetail,
			isJson: true,
			data,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('编辑成功')
				resolve()
			}
		})
	})

// 修改临时档案
export const addTemporaryArchiveThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.addTemporaryArchive,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('保存成功')
				resolve()
			} else {
				reject()
			}
		})
	})

// 查询临时文件
export const selectTemporaryArchiveThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.selectTemporaryArchive,
			type: 'get',
			isJson: true,
			headers: {
				'Cache-control': 'no-store',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})
