// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../Static/background.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#login-page {\n  width: 100vw;\n  height: 100vh;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n}\n#login-page .login-content {\n  position: relative;\n}\n#login-page .login-content #login-form-box {\n  position: absolute;\n  top: 50%;\n  right: 27%;\n  transform: translate(50%, -50%);\n  width: 400px;\n  background: #ffffff;\n  box-shadow: 0px 10px 20px rgba(65, 106, 197, 0.16);\n  opacity: 0.95;\n  border-radius: 7px !important;\n}\n#login-page .login-content #login-form-box .ant-card-body {\n  width: 100%;\n  height: 100%;\n  display: contents;\n}\n", "",{"version":3,"sources":["webpack://src/Components/Login/index.less"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,aAAA;EACA,mDAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;AACD;AAPA;EASE,kBAAA;AACF;AAVA;EAYG,kBAAA;EACA,QAAA;EACA,UAAA;EACA,+BAAA;EACA,YAAA;EACA,mBAAA;EACA,kDAAA;EACA,aAAA;EACA,6BAAA;AACH;AArBA;EAuBI,WAAA;EACA,YAAA;EACA,iBAAA;AACJ","sourcesContent":["#login-page {\n\twidth: 100vw;\n\theight: 100vh;\n\tbackground: url('../../Static/background.jpg');\n\tbackground-repeat: no-repeat;\n\tbackground-size: cover;\n\tbackground-position: center;\n\n\t.login-content {\n\t\tposition: relative;\n\n\t\t#login-form-box {\n\t\t\tposition: absolute;\n\t\t\ttop: 50%;\n\t\t\tright: 27%;\n\t\t\ttransform: translate(50%, -50%);\n\t\t\twidth: 400px;\n\t\t\tbackground: #ffffff;\n\t\t\tbox-shadow: 0px 10px 20px rgba(65, 106, 197, 0.16);\n\t\t\topacity: 0.95;\n\t\t\tborder-radius: 7px !important;\n\n\t\t\t.ant-card-body {\n\t\t\t\twidth: 100%;\n\t\t\t\theight: 100%;\n\t\t\t\tdisplay: contents;\n\t\t\t}\n\t\t}\n\t}\n}\n\n@primary-color: #5B80D5;@link-color: #5B80D5;@border-radius-base: 4px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
