import _fetch from '@/Utils/Fetch'
import { archiveTypeUrl } from '@/Utils/Urls'

// 获取类别号
export const getDossierTypeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: archiveTypeUrl.getDossierType,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})
