import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import { connect } from 'react-redux'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { DataTableManagementColumns } from '@/Public/PubTable/pageColumns'
import { dataTableManagerUrl } from '@/Utils/Urls.js'
import { Space, Button, Modal } from 'antd'
import AddColumn from './AddColoumModal'
import AddTable from './AddTableModal'
import ChangeTypeModal from './ChangeTypeModal'

import {
	addColumnThunk,
	createTableThunk,
	deleteTableColumnThunk,
	changeColumnDataTypeThunk,
	renameTableColumnThunk,
	tips,
} from './ActionCreators'

const detailModalRef = React.createRef(null) // 获取查看模态框表单实例
let addmodalRef = React.createRef(null)
let changeTypemodalRef = React.createRef(null)
let addTablemodalRef = React.createRef(null)
let searchColumnName = ''
const markName = 'dataTableManagement' // redux的标识
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	//表操作
	addColumn: (...rest) => dispatch(addColumnThunk(...rest)),
	createTable: (...rest) => dispatch(createTableThunk(...rest)),
	deleteColumn: (...rest) => dispatch(deleteTableColumnThunk(...rest)),
	changeColumnType: (...rest) => dispatch(changeColumnDataTypeThunk(...rest)),
	renameColumn: (...rest) => dispatch(renameTableColumnThunk(...rest)),
})
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class DataTableManagement extends Component {
	state = {
		record: null, // 当前操作（查看后入库、回退、加入收藏夹）的行数据
		recordIndex: -1, // 当前操作行数据在当前页数据的 index 值
		tableName: 'archive', //当前选择的表，默认archive
	}
	componentDidMount() {
		//TODO
		localStorage.setItem('DataTableManagementON', 'ture')
	}
	componentWillUnmount() {
		localStorage.setItem('SelectedDataTable', '')
		localStorage.setItem('DataTableManagementON', '')
	}

	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{
				type: 'AsyncSelect',
				name: 'tableName',
				label: '',
				placeholder: 'archive',
				width: '0px',
				ajaxConfig: {
					url: dataTableManagerUrl.getAllTable,
					type: 'post',
				},
				formatDataFunc: data => {
					return data.map((item, index) => ({ label: item, value: item }))
				},
			},
			{
				type: 'Input',
				name: 'columnName',
				label: '列名',
				width: '300px',
			},
			{
				type: 'Extra',
				name: 'SensitiveWordList',
				render: formRef => {
					return (
						<Space>
							<Button
								className='btn-success iconfont-buttons'
								title='新增列'
								onClick={() => this.viewAddModal(null, -1)}>
								&#xe634; 列
							</Button>
							<Button
								className='btn-success iconfont-buttons'
								title='新增表'
								onClick={() => this.viewAddTableModal(null, -1)}>
								&#xe634; 表
							</Button>
						</Space>
					)
				},
			},
		],
		// 根据表单内容获取表格数据的请求
		ajaxConfig: {
			ajaxType: 'post',
			url: dataTableManagerUrl.getTableColumn,
			ContentType: 'application/json',
		},
	}
	changeSearchLimit = data => {
		searchColumnName = data.columnName
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = res => {
		if (typeof searchColumnName !== 'undefined' && searchColumnName.length != 0) {
			res = res.filter(item => item.columnName.indexOf(searchColumnName) != -1)
		}
		res = res.map(item => ({ columnName: item }))
		return {
			data: res,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}

	// 检查表格选中数量是否大于一项，如果不满足条件则弹窗提示
	checkRowSelection = () => {
		return new Promise((resolve, reject) => {
			const { pub_rowSelection } = this.props
			const rowSelection = pub_rowSelection[markName] || []
			// 还未选中则不能操作
			if (!rowSelection.length) {
				reject(
					Modal.error({
						content: tips.CHOOSE_AT_LEAST_ONE,
						maskClosable: true,
					})
				)
			}
			// 返回所有选中项
			resolve(rowSelection)
		})
	}

	// 删除按钮
	handleDeleteApply = async record => {
		const {
			deleteColumn, // 删除接口
			pub_getAllData,
		} = this.props
		// const { record } = this.state
		try {
			const data = {
				tableName: localStorage.getItem('SelectedDataTable'),
				fieldName: record.columnName,
			}
			deleteColumn(data).then(res => {
				pub_getAllData()
				detailModalRef.current?.hide()
			})
		} catch (err) {}
	}

	//添加 新增列 模态框
	viewAddModal = (record, recordIndex) => {
		this.setState({ record, recordIndex }, addmodalRef.current.show)
	}

	//修改数据类型
	viewChangeTypeModal = (record, recordIndex) => {
		this.setState({ record, recordIndex }, changeTypemodalRef.current.show)
	}

	//新增表
	viewAddTableModal = (record, recordIndex) => {
		this.setState({ record, recordIndex }, addTablemodalRef.current.show)
	}

	// 关闭模态框后一些数据进行初始化
	hideCallback = () => this.setState({ record: null, recordIndex: -1 })

	//行尾操作按钮
	returnButton = (record, index) => {
		return (
			<Space>
				<Button
					onClick={() => this.viewChangeTypeModal(record, index)}
					size='small'>
					修改类型
				</Button>
				<Button
					className='table-btn-danger'
					onClick={() => this.handleDeleteApply(record)}
					size='small'>
					删除
				</Button>
			</Space>
		)
	}
	render() {
		const { pub_getAllData, addColumn, changeColumnType, createTable } = this.props
		const { record } = this.state

		return (
			<Fragment>
				<WrappedTable>
					<SearchForm
						name='expiredFiles-search-form'
						components='dataTable'
						formItems={this.searchFormConfig} //搜索的选项
						changeSearchLimit={this.changeSearchLimit} //搜索限制（这里是无）
						changeTableData={this.changeTableData}
						markName={markName} //markName，每个table的唯一标识
					/>
					<BaseTable
						//table的columns配置
						//actions不知道有啥用
						//搜索限制（无）
						//格式baseTable中返回的数据
						//table唯一标识
						//是否带有前面的预览行
						tableConfig={DataTableManagementColumns}
						//自定义行尾操作按钮
						operationButton={this.returnButton}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
						withPreview={false}
						notRowSelection
					/>
					<AddColumn
						modalRef={addmodalRef}
						addColumn={addColumn}
						pub_getAllData={pub_getAllData}
						// 获取表格数据的函数
						// 推测时路由注入components的时附加的
						record={record} // 表格详情信息
					/>
					<AddTable
						modalRef={addTablemodalRef}
						createTable={createTable}
						pub_getAllData={pub_getAllData}
						// 获取表格数据的函数
						// 推测时路由注入components的时附加的
						record={record} // 表格详情信息
					/>
					<ChangeTypeModal
						modalRef={changeTypemodalRef}
						changeColumnType={changeColumnType}
						pub_getAllData={pub_getAllData}
						// 获取表格数据的函数
						// 推测时路由注入components的时附加的
						record={record} // 表格详情信息
					/>
				</WrappedTable>
			</Fragment>
		)
	}
}

export default DataTableManagement
