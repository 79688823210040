import React from 'react'
import * as XLSX from 'xlsx'
import axios from 'axios'
import { Table } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'

export default class Preview extends React.Component {
	state = {
		tableData: [],
		tableHeader: [],
		axios: false,
	}

	componentDidMount() {
		// 只请求一次
		if (!this.state.axios) {
			axios({
				method: 'get',
				url: this.props.url,
				responseType: 'arraybuffer',
			})
				.then(res => {
					this.setState({ axios: true })
					if (res?.status === 200) {
						let xlsxData = new Uint8Array(res.data)
						let workbook = XLSX.read(xlsxData, { type: 'array' })
						let sheetNames = workbook.SheetNames // 工作表名称集合
						let worksheet = workbook.Sheets[sheetNames[0]] // 默认只读取第一张sheet
						let csv = XLSX.utils.sheet_to_csv(worksheet)

						let rows = csv.split('\n')
						rows.pop() // 最后一行没用的

						const excelHeader = []
						const excelData = []
						// 遍历行
						let length = 0
						rows.forEach((row, rindex) => {
							if (rindex < 500) {
								let columns = row.split(',')
								// 遍历每列，如果表头设置值
								if (rindex === 0) {
									columns.forEach((column, cindex) => {
										if (column) {
											const header = {
												title: column,
												dataIndex: cindex,
												key: cindex + column,
											}
											excelHeader.push(header)
										}
										length = excelHeader.length
									})
								} else {
									let data = {}
									columns.forEach((column, cindex) => {
										if (column) {
											data[cindex] = column
											if (cindex + 1 > length) {
												excelHeader.push({
													title: '',
													dataIndex: cindex,
													key: cindex,
												})
												length = cindex + 1
											}
										}
									})
									if (Object.keys(data).length !== 0) {
										data.key = rindex
										excelData.push(data)
									}
								}
							}
						})
						this.setState({
							tableData: excelData,
							tableHeader: excelHeader,
						})
					}
				})
				.catch(() => {})
		}
	}

	render() {
		// 如果没有获取到数据则返回图标
		if (this.state.tableHeader.length === 0) {
			return (
				<div className='fileicon'>
					<FileExcelOutlined />
				</div>
			)
		} else {
			return (
				<Table
					className='xlxs-table'
					size='small'
					columns={this.state.tableHeader}
					dataSource={this.state.tableData}
					pagination={false}
					bordered={true}
				/>
			)
		}
	}
}
