import React, { PureComponent } from 'react'
import { DatePicker, TimePicker } from 'antd'
import moment from 'moment'

export const createDatePicker = config => {
	class FormatDatePicker extends PureComponent {
		onDateChange = (moment, dateString) => {
			let { onChange } = this.props
			if (onChange) onChange(dateString)
		}
		disabledDate = current => {
			return current && current > moment().endOf('day')
		}
		render() {
			const { value, disabled } = this.props
			return (
				<DatePicker
					picker={config.picker === 'all' ? 'date' : config.picker}
					placeholder={config.placeholder}
					showTime={config.picker === 'all'}
					onChange={this.onDateChange}
					value={value ? moment(value) : ''}
					disabled={disabled}
					style={config.style}
					bordered={!disabled}
					className={config.propsClass} // 配置的class类名
					disabledDate={config.disabledDate || this.disabledDate}
				/>
			)
		}
	}
	return <FormatDatePicker />
}

export const createTimePicker = config => {
	class FormatTimePicker extends PureComponent {
		state = { value: '' }

		componentDidMount() {
			// 输入时间可以不点确定
			const input = document.querySelector('.' + config.name + '-timePicker input')
			input.oninput = e => {
				if (e.target.value.length > 4) {
					this.onTimeChange(
						moment(e.target.value, config.format),
						e.target.value
					)
				}
			}
		}

		onTimeChange(time, timeString) {
			this.setState({ value: time })
			let { onChange } = this.props
			if (onChange) onChange(timeString)
		}

		// 选时间可以不点确定
		onOpenChange = isOpen => {
			if (isOpen === true) {
				setTimeout(() => {
					const pad = document.querySelector(
						'.' + config.name + '-timePicker-pop'
					)
					pad.onclick = e => {
						setTimeout(() => {
							const value = document.querySelector(
								'.' + config.name + '-timePicker input'
							).value
							this.onTimeChange(moment(value, config.format), value)
						})
					}
				})
			}
		}

		render() {
			const { value } = this.props

			return (
				<TimePicker
					onChange={(time, timeString) => {
						this.onTimeChange(time, timeString)
					}}
					format={config.format}
					style={config.style}
					value={value ? moment(value, config.format) : this.state.value}
					className={config.name + '-timePicker'}
					popupClassName={config.name + '-timePicker-pop'}
					onOpenChange={this.onOpenChange}
				/>
			)
		}
	}
	return <FormatTimePicker />
}

/* export default {
  createDatePicker
} */
