import { message } from 'antd'
import { archiveUrl, filesUrl, serialNumberUrl } from '@/Utils/Urls'
import asyncPool from '@/Utils/AsyncPool'
import _fetch from '@/Utils/Fetch'
import SparkMD5 from 'spark-md5'

const spark = new SparkMD5.ArrayBuffer()

/**
 * 自动著录上传文件
 * @param {array} fileChunkList 一个文件的切片组成的数组
 */
export const multiUploadThunk2 = async (
	fileChunkList,
	fileName,
	newUuid,
	status1,
	status2,
	handleSuccess,
	wholeMd5,
	rootUUID
) => {
	const total = fileChunkList.length
	let fileCurrent = (await getChunk(wholeMd5))?.data?.data
	fileCurrent = fileCurrent === 0 ? 1 : fileCurrent
	// eslint-disable-next-line
	for await (const _ of asyncPool(
		5,
		fileChunkList.slice(fileCurrent - 1, total - 1),
		fileChunk => {
			const formData = new FormData()
			formData.append(
				'multipartFile',
				new File([fileChunk.file], fileName, {
					type: fileChunk.type,
					lastModified: Date.now(),
				})
			)
			formData.append('status', status1)
			formData.append('chunks', total)
			formData.append('chunk', fileCurrent)
			formData.append('wholeMd5', fileChunk.wholeMd5)
			formData.append('rootUUID', rootUUID)
			fileCurrent++

			// 判断导入方式
			switch (status2) {
				case 1:
					formData.append('belong', '0')
					formData.append('UUID', newUuid)
					break
				case 2:
					formData.append('belong', '1')
					formData.append('UUID', newUuid)
					break
				default:
					formData.append('belong', '1')
					formData.append('UUID', fileChunk.uuid)
					break
			}

			return new Promise((resolve, reject) => {
				_fetch({
					url: archiveUrl.multiUpload,
					type: 'post',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}).then(res => {
					resolve(res.data.data)
				})
			})
		}
	)) {
	}

	return new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append(
			'multipartFile',
			new File([fileChunkList[total - 1].file], fileName, {
				type: fileChunkList[total - 1].type,
				lastModified: Date.now(),
			})
		)
		formData.append('status', status1)
		formData.append('chunks', total)
		formData.append('chunk', fileCurrent)
		formData.append('wholeMd5', fileChunkList[total - 1].wholeMd5)
		formData.append('rootUUID', rootUUID)

		// 判断导入方式
		switch (status2) {
			case 1:
				formData.append('belong', '0')
				formData.append('UUID', newUuid)
				break
			case 2:
				formData.append('belong', '1')
				formData.append('UUID', newUuid)
				break
			default:
				formData.append('belong', '1')
				formData.append('UUID', fileChunkList[total - 1].uuid)
				break
		}

		_fetch({
			url: archiveUrl.multiUpload,
			type: 'post',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				handleSuccess(
					fileChunkList[total - 1].fileName,
					status2 === 3 ? fileChunkList[total - 1].uuid : newUuid
				)
				resolve(res.data)
			} else {
				reject(res.data)
			}
		})
	})
}

export const noChunkMultiUpload = async (
	noChunkList,
	newUuid,
	status1,
	status2,
	handleSuccess,
	rootUUID
) => {
	// eslint-disable-next-line no-unused-vars
	for await (const _ of asyncPool(5, noChunkList, file => {
		const formData = new FormData()
		formData.append('multipartFile', file)
		formData.append('status', status1)
		formData.append('rootUUID', rootUUID)
		formData.append('fileName', file.webkitRelativePath)

		// 判断导入方式
		switch (status2) {
			case 1:
				formData.append('belong', '0')
				formData.append('UUID', newUuid)
				break
			case 2:
				formData.append('belong', '1')
				formData.append('UUID', newUuid)
				break
			default:
				formData.append('belong', '1')
				formData.append('UUID', file.uuid)
				break
		}

		return new Promise((resolve, reject) => {
			_fetch({
				url: archiveUrl.multiUpload,
				type: 'post',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}).then(res => {
				//console.log('在这3')
				handleSuccess(file.name, status2 === 3 ? file.uuid : newUuid)
				resolve(res.data.data)
			})
		})
	})) {
	}
}

const getChunk = wholeMd5 => {
	return _fetch({
		url: filesUrl.getChunk,
		method: 'get',
		params: {
			wholeMd5,
		},
		isJson: true,
	})
}

/**
 * 文件切片
 * @param {file} file
 */
export const createFileChunk = file => {
	const fileChunkList = []
	const CHUNK_SIZE = 2097152 // 切片大小为 2M

	for (let start = 0; start < file.size; start += CHUNK_SIZE) {
		fileChunkList.push({
			file: file.slice(start, start + CHUNK_SIZE),
		})
	}

	return fileChunkList
}

/**
 * 创建文件的 md5 标识
 * @param {array} fileChunkList
 */
export const getFileMd5 = async fileChunkList => {
	let count = 0
	const totalCount = fileChunkList.length
	const fileReader = new FileReader()
	return new Promise((resolve, reject) => {
		fileReader.onload = e => {
			if (e.target && e.target.result) {
				count += 1
				spark.append(e.target.result)
			}
			if (count < totalCount) {
				loadNext()
			} else {
				resolve(spark.end())
			}
		}

		fileReader.onerror = function () {
			reject({
				message: '文件读取失败',
			})
		}

		const loadNext = () => {
			fileReader.readAsArrayBuffer(fileChunkList[count]?.file)
		}

		loadNext()
	})
}

// 导出excel模板
export const exportExcelThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: archiveUrl.exportExcel,
			type: 'get',
			headers: {
				'Content-Type': 'application/json',
			},
			responseType: 'blob',
		}).then(res => {
			//console.log('modal', res)
			const blob = res.data

			const urlObject = window.URL || window.webkitURL || window
			const export_blob = new Blob([blob], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			})
			const a = document.createElement('a') // 利用a标签特性下载
			a.style.display = 'none'
			const url = urlObject.createObjectURL(export_blob)
			a.href = url
			a.setAttribute('download', '统一信息.xlsx')
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
			message.success(`成功导出：[统一信息] [详细信息]两个模板文件`)
		})
	})

export const exportDetailExcelThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: archiveUrl.exportDetailExcel,
			type: 'get',
			headers: {
				'Content-Type': 'application/json',
			},
			responseType: 'blob',
		}).then(res => {
			//console.log('detail', res)
			const blob = res.data
			const urlObject = window.URL || window.webkitURL || window
			const export_blob = new Blob([blob], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			})
			const a = document.createElement('a') // 利用a标签特性下载
			a.style.display = 'none'
			const url = urlObject.createObjectURL(export_blob)
			a.href = url
			a.setAttribute('download', '详细信息.xlsx')
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
		})
	})

// 自动著录
export const autoDescriptionThunk = UUID => () =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('UUID', UUID)

		_fetch({
			url: archiveUrl.autoDescription,
			type: 'post',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取指定UUID的著录信息
export const getAutoDescriptionDetailThunk = UUID => () =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('UUID', UUID)

		_fetch({
			url: archiveUrl.getAutoDescriptionDetail,
			type: 'get',
			data: {
				UUID,
			},
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取自动著录日志
export const downloadMultipartResultThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: archiveUrl.downloadMultipartResult,
			type: 'get',
			headers: {
				'Content-Type': 'application/json',
			},
			responseType: 'blob',
		}).then(res => {
			const blob = res.data
			const urlObject = window.URL || window.webkitURL || window
			const export_blob = new Blob([blob], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			})
			const a = document.createElement('a') // 利用a标签特性下载
			a.style.display = 'none'
			const url = urlObject.createObjectURL(export_blob)
			a.href = url
			a.setAttribute('download', '著录日志.txt')
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
		})
	})

export const getAllSystemConfig = () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: serialNumberUrl.getAllSystemConfig,
			type: 'get',
		})
			.then(res => {
				if (res.data.code === 200) {
					resolve(res.data.data)
				} else {
					reject(res)
				}
			})
			.catch(err => {
				reject(err)
			})
	})
