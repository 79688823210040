import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import FilePreviewer from '@/Public/FilePreviewer'
import PubArchive from '@/Public/PubArchive'
import moment from 'moment'
import { uploadFileThunk } from '../FileFixApply/ActionCreator'
import {
	routeChangeAction,
	pushRoutersAction,
	getDetailThunk,
	addPubFileAction,
	upgradePubArchiveAction,
} from '../DossierDetails/ActionCreator' // 从案卷详情页拿到函数
import {
	inititalPubArchiveAction,
	getArchiveListThunk,
} from '@/Public/PubTable/baseTable/ActionCreator'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload, Space } from 'antd'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
import PubFileDetails from '@/Public/FileDetails'

const markName = 'FixDetailsDossierDetails' // redux的标识
let dossierRef
let fileRef
const mapStateToProps = state => {
	return {
		pubArchive: state.pubArchive,
		route: state.route.item, // 获取当前路由
	}
}
const mapDispatchToProps = dispatch => {
	return {
		uploadFile: (...rest) => dispatch(uploadFileThunk(...rest)), // 修正文件文件
		// 以下为案卷详情页所需要的请求
		getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey.key))
			} else {
				dispatch(routeChangeAction(activeKey.key))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
		},
		pushRouters(link) {
			return dispatch(pushRoutersAction(link))
		},
		addPubFileData(data) {
			return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		// 更新案卷信息
		upgradePubArchive: (markName, pubArchive) =>
			dispatch(upgradePubArchiveAction(markName, pubArchive)),
		getArchiveList: (...rest) => dispatch(getArchiveListThunk(...rest)),
		inititalPubArchive: (markName, pubArchive) =>
			dispatch(inititalPubArchiveAction(markName, pubArchive)),
	}
}
@connect(mapStateToProps, mapDispatchToProps)
class OldDetails extends Component {
	state = {
		fileList: [], // 上传的文件信息
		uploading: false, // 是否在上传状态
		fileId: null, // 文件id
		fileToken: null, // 新文件token
		fileType: null, // 新文件Type
		thumbnailFileToken: null, // 缩略图token
		thumbnailFileType: null, // 缩略图type
		images: [],
		ids: {}, //缩略图和图片id
	}

	componentDidMount() {
		const { fileData } = this.props // 拿到详情
		// 判断类型后，初始化渲染页面
		if (!fileData.type) {
			this.setImages() // 设置图片
			this.setFormData() // 设置表单值
			this.setState({
				ids: {
					fileId: fileData.fileId,
					thumbnailFileId: fileData.thumbnailFileId,
				},
				// 设置文件参数便于传参
				fileToken: fileData.fileToken, // 新文件token
				fileType: fileData.contentType, // 新文件Type
				thumbnailFileToken: fileData.thumbnailFileToken, // 缩略图token
				thumbnailFileType: fileData.thumbnailContentType, // 缩略图type
			})
		} else {
			// 如果有新的值则设置新的
			let archiveList = fileData.newdata?.archiveDetail || fileData.archiveDetail
			fileData.archiveDetail.forEach(archiveData => {
				const { fileToken, fileType } = archiveData.fileData
				// 设置预览图
				archiveData['preview'] = {
					type: 1,
					fileData: {
						fileToken,
						fileType,
					},
				}
				// 每个来个key
				archiveData['key'] = archiveData.id
			})
			// 初始化案卷
			this.props.inititalPubArchive('FixDetailsDossierDetails', {
				filesData: archiveList || [],
				archiveData: this.setFormRecord(fileData.newdata || fileData),
			})
		}
	}

	// 设置案卷详情的表单值
	setFormRecord = pub_dossierDetails => {
		const {
			recordTime,
			recordUser,
			archiveTime,
			archiveUser,
			storageTime,
			storageUser,
		} = pub_dossierDetails
		// 著录人和时间
		pub_dossierDetails['recordUserAndTime'] = { recordUser, recordTime }
		// 归档人和时间
		pub_dossierDetails['archiveUserAndTime'] = { archiveUser, archiveTime }
		// 入库人和时间
		pub_dossierDetails['storageUserAndTime'] = { storageUser, storageTime }
		return pub_dossierDetails
	}

	// 设置images的值
	setImages = () => {
		const { fileData } = this.props // 拿到详情
		let images = []

		// 没有archiveList显示
		if (!images?.length) {
			// 如果有新的图片则设置新的图片
			if (fileData.newdata?.fileId) {
				let imgData = {
					original: `/api/file/download/${fileData.newdata.fileToken}`,
					thumbnail: `/api/file/download/${fileData.newdata.thumbnailFileToken || fileData.newdata.fileToken}`,
					fileData: {
						fileToken: fileData.newdata.fileToken,
						fileType: fileData.newdata.fileType,
					}, // 放在这里利于传参
					renderItem: this.renderFilePreviewer.bind(this), // 设置显示图标、视频、图片
				}

				if (
					!fileData.newdata.thumbnailFileToken &&
					fileData.newdata.fileType !== 'image/jpeg'
				) {
					// 如果没有缩略图且没有图片显示则下方缩略图片显示FilePreviewer
					imgData = {
						...imgData,
						renderThumbInner: this.renderFilePreviewer.bind(this),
					}
				}

				// 如果是音频，显示小图标
				if (fileData.newdata.fileType?.includes('audio')) {
					imgData.renderThumbInner = this.renderThumbFilePreviewer.bind()
				}

				images.push(imgData)
			} else {
				let imgData = {
					original: `/api/file/download/${fileData.fileToken}`,
					thumbnail: `/api/file/download/${fileData.thumbnailFileToken || fileData.fileToken}`,
					fileData: fileData.fileData || {
						fileToken: fileData.fileToken,
						fileType: fileData.fileType,
					}, // 放在这里利于传参
					renderItem: this.renderFilePreviewer.bind(this), // 设置显示图标、视频、图片
				}
				if (!fileData.thumbnailFileToken && fileData.fileType !== 'image/jpeg') {
					// 如果没有缩略图且没有图片显示则下方缩略图片显示FilePreviewer
					imgData = {
						...imgData,
						renderThumbInner: this.renderFilePreviewer.bind(this),
					}
				}

				// 如果是音频，显示小图标
				if (fileData.newdata?.fileType?.includes('audio')) {
					imgData.renderThumbInner = this.renderThumbFilePreviewer.bind()
				}
				images.push(imgData)
			}
		}
		this.setState({ images })
	}

	// 设置表单的值
	setFormData = () => {
		const { fileData } = this.props // 拿到详情
		// 设置表单的值
		let formData = fileData.newdata || fileData
		// 如果没有新的值则计算matedata
		if (!fileData.newdata) {
			fileData.metadataStructTreeBoList?.forEach(current => {
				formData[`${current.id}`] = current.metadataValue
			})
			if (formData['75']) {
				formData['75'] = moment(formData['75'])
			}
		}

		fileRef.current.setFieldsValue(formData)
	}

	// 修改文件，发请求，拿数据
	handleUpload = file => {
		const formData = new FormData() // 构造一个formData实例对象，用于存储formData数据
		formData.append('multipartFile', file) // 将后台指定的字段名作为属性，文件数据作为属性值

		this.setState({
			// 设置为正在上传的状态
			uploading: true,
		})
		this.props.uploadFile(formData).then(data => {
			let images = []
			let imgData = {
				original: `/api/file/download/${data.fileToken}`,
				thumbnail: `/api/file/download/${data.thumbnailFileToken || data.fileToken}`,
				fileData: data.fileData || {
					fileToken: data.fileToken,
					fileType: data.contentType,
				}, // 放在这里利于传参
				renderItem: this.renderFilePreviewer.bind(this), // 设置显示图标、视频、图片
			}

			if (!data.thumbnailFileToken && data.thumbnailContentType !== 'image/jpeg') {
				// 如果没有缩略图且没有图片显示则下方缩略图片显示FilePreviewer
				imgData = {
					...imgData,
					renderThumbInner: this.renderFilePreviewer.bind(this),
				}
			}
			images.push(imgData)
			this.setState({
				images,
				ids: {
					fileId: data.fileId,
					thumbnailFileId: data.thumbnailFileId,
				},
				fileToken: data.fileToken, // 新文件token
				fileType: data.contentType, // 新文件Type
				thumbnailFileToken: data.thumbnailFileToken, // 缩略图token
				thumbnailFileType: data.thumbnailContentType, // 缩略图type
			})
		})
	}

	// 返回图标或者视频
	renderFilePreviewer = data => {
		return <FilePreviewer fileData={data.fileData} />
	}

	renderThumbFilePreviewer = data => {
		return <FilePreviewer fileData={data.fileData} isThumbnail={true} />
	}

	// 处理本地文件上传，转化为数据
	handleFileChange = info => {
		//处理文件change，保证用户选择的文件只有一个
		if (info.fileList.length === 0) {
			// 取最后一个文件（即只取一个文件）
			this.setState({ fileList: [] })
		} else {
			this.setState({ fileList: [info.fileList[info.fileList.length - 1]] })
		}
	}

	renderButtonList = () => {
		const { fileList } = this.state

		// 上传文件的相关操作
		const props = {
			// 这里的props是设置上传文件的按钮
			onRemove: file => {
				// 移除文件
				this.setState(state => {
					const index = state.fileList.indexOf(file)
					const newFileList = state.fileList.slice()
					newFileList.splice(index, 1)
					return {
						fileList: newFileList,
					}
				})
			},
			beforeUpload: file => {
				// 设置新文件
				this.setState(state => ({
					fileList: [...state.fileList, file],
				}))
				// 传参发请求
				this.handleUpload(file)
				return false
			},
			fileList,
		}
		return (
			<Space className='fixFile-buttonBox'>
				{/* 文件按钮组 */}
				<Space className='upload-box'>
					<Button onClick={this.lastStep}>取消</Button>
					<Upload
						className='fix-upload'
						{...props}
						onChange={this.handleFileChange}
						showUploadList={false}>
						<Button className='btn-warning' icon={<UploadOutlined />}>
							更改文件
						</Button>
					</Upload>
				</Space>
				<Button type='primary' onClick={this.fileDataFinish} htmlType='submit'>
					保存
				</Button>
			</Space>
		)
	}

	// 上一步
	lastStep = () => {
		this.props.toPrevStep()
	}

	// 案卷保存函数
	dossierDataFinish = () => {
		dossierRef.validateFields().then(() => {
			const filesData = this.props.pubArchive[markName]?.new.filesData // 新信息文件列表
			// 设置newdata
			this.props.fileData['newdata'] = {
				...this.props.fileData,
				...dossierRef.getFieldValue(),
				archiveDetail: filesData,
			}
			this.props.toPrevStep()
		})
	}

	// 文件保存函数
	fileDataFinish = () => {
		const { fileToken, thumbnailFileToken, fileType, thumbnailFileType, ids } =
			this.state
		fileRef.current.validateFields().then(() => {
			let fixContent = fileRef.current.getFieldValue()
			const fileMetadata = {}
			const data = {}
			// 遍历要格式化的数据
			for (let i in fixContent) {
				// i 为数字，则证明是元数据内容
				if (!isNaN(Number(i))) {
					if (fixContent[i]) {
						fileMetadata[`${i}`] =
							Number(i) === 75
								? moment(fixContent[i])
										.format('YYYY-MM-DDTHH:mm:ss')
										.replace(/\.(.*)/, '')
								: fixContent[i]
					}
				}
				// 不是元数据的内容就直接放进请求数组里
				else {
					data[i] = fixContent[i]
				}
			}

			// 设置newdata数据
			this.props.fileData['newdata'] = {
				...this.props.fileData,
				...data,
				...fileMetadata,
				metaData: fileMetadata,
				fileToken,
				thumbnailFileToken,
				fileType,
				thumbnailFileType,
				fileId: ids?.fileId,
				thumbnailFileId: ids?.thumbnailFileId,
			}
			this.props.toPrevStep()
		})
	}

	// 案卷上一步下一步分开
	renderLeftButtons = () => {
		return (
			<Button
				style={{ color: 'white', marginRight: '10px' }}
				onClick={this.lastStep}>
				返回
			</Button>
		)
	}

	renderRightButtons = () => {
		return (
			<Button type='primary' onClick={this.dossierDataFinish}>
				保存
			</Button>
		)
	}

	// table栏按钮操作
	returnButton = (text, record, index) => {
		const dossierData = this.props.pubArchive[markName].new
		const link = {
			title: '文件详情',
			key: '/workarea/commonComponents/fileDetails',
		}
		return (
			<div className='fix-table-buttonList'>
				<Button
					onClick={() => {
						this.props.getDetail(record).then(data => {
							// 请求详情数据
							this.props.getDetail(record).then(data => {
								// 请求详情数据
								data['archiveList'] = dossierData?.filesData
								data['route'] = this.props.route // 由此可以知道从哪打开详情，并判断是否添加按钮
								data['startIndex'] = index
								this.props.addPubFileData(data) // 文件详情传到redux
								this.props.onChange(link, history) // 修改路由
								this.props.pushRouters(link) //添加分页栏
							})
						})
					}}>
					查看
				</Button>
				{/* 删除按钮对table行进行删除功能 */}
				<Button
					className='btn-danger'
					style={{ marginTop: '5px' }}
					onClick={() => {
						const filesLength = dossierData.filesData.length
						let tmpFilesData = dossierData.filesData.filter(item => {
							return item.id !== record.id
						})
						let tmpPubArchive = {
							archiveData: dossierData.archiveData,
							filesData: tmpFilesData,
						}
						tmpFilesData.length !== filesLength &&
							this.props.upgradePubArchive(markName, tmpPubArchive)
					}}>
					删除
				</Button>
			</div>
		)
	}

	// 获取表单的实例
	getInstance = async formRef => {
		dossierRef = formRef
	}

	// 获取到文件编辑器的ref
	getRef = ref => {
		fileRef = ref
	}

	render() {
		const { images } = this.state
		if (!this.props.fileData.type) {
			return (
				<Fragment>
					<Space
						style={{ marginBottom: 20, width: '100%' }}
						className='fixFile-buttonBox'>
						{this.renderButtonList()}
					</Space>
					<PubFileDetails
						style={{ marginTop: '10px' }}
						uniqueName='fixDetailsFileDetails'
						items={images}
						disabled={!true}
						isFormat={true}
						onFinish={values => this.onSubmit(values)}
						getRef={this.getRef}
					/>
				</Fragment>
			)
		} else {
			return (
				<React.Fragment>
					<PubArchive
						getInstance={this.getInstance}
						renderOperation={this.returnButton}
						markName={markName}
						formDisabled={!true}
						contentTile={'修正案卷'}
						appendFiles={true}
						renderLeftButtons={this.renderLeftButtons}
						renderRightButtons={this.renderRightButtons}
					/>
				</React.Fragment>
			)
		}
	}
}

export default OldDetails
