import React, { Component, Fragment } from 'react'
//EML处理库
import { Pagination, Modal, message, Skeleton } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { connect } from 'react-redux'
import FileDetails from '@/Public/FileDetails'
import FilePreviewer from '@/Public/FilePreviewer'
import {
	changeToArchiveThunk,
	getCollectedFileListThunk,
	deleteCollectedFileThunk,
	updateCollectedFileThunk,
	updateAttachmentThunk,
	exportCollectedFileThunk,
	getMyThemeThunk,
} from './ActionCreators'
import {
	getDetailThunk,
	addPubCollectedFileAction,
} from '@/Public/PubTable/baseTable/ActionCreator'
import './index.less'

const { confirm } = Modal

const mapStateToProps = state => ({
	collectedFileData: state.pubForm.pub_collectedDetails,
	allCollectedFileData: state.pubTable.pub_allTableData.CollectedFiles,
	searchCondition: state.pubTable.allSearchCondition.CollectedFiles,
})
const mapDispatchToProps = dispatch => ({
	changeToArchive: (...rest) => dispatch(changeToArchiveThunk(...rest)),
	getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
	addPubCollectedFile: (...rest) => dispatch(addPubCollectedFileAction(...rest)),
	getCollectedFileList: (...rest) => dispatch(getCollectedFileListThunk(...rest)),
	deleteCollectedFile: (...rest) => dispatch(deleteCollectedFileThunk(...rest)),
	updateCollectedFile: (...rest) => dispatch(updateCollectedFileThunk(...rest)),
	updateAttachment: (...rest) => dispatch(updateAttachmentThunk(...rest)),
	exportCollectedFile: (...rest) => dispatch(exportCollectedFileThunk(...rest)),
	getMyTheme: (...rest) => dispatch(getMyThemeThunk(...rest)),
})

@connect(mapStateToProps, mapDispatchToProps)
class Details extends Component {
	componentDidMount() {
		const { collectedFileData, allCollectedFileData } = this.props
		const currentIndex = this.getCurrentIndex(collectedFileData, allCollectedFileData)
		this.setState({
			id: collectedFileData.id,
			currentIndex,
			allCollectedFileData,
		})
	}

	// 更新资料详情表格
	componentDidUpdate() {
		const { collectedFileData, allCollectedFileData, searchCondition, getMyTheme } =
			this.props
		if (this.collectedRef?.current) {
			if (collectedFileData.time == null) {
				delete collectedFileData.time
			} else {
				collectedFileData.time = moment(collectedFileData.time)
			}
			//判断文件是否已被删除
			let flag = false
			allCollectedFileData.data.map(item => {
				if (collectedFileData.id === item.id) flag = true
				return item
			})
			if (flag) this.collectedRef.current.setFieldsValue(collectedFileData)
			else {
				//若主题下还有文件则定位到第一页，没有则提示信息还有修改state
				if (allCollectedFileData.data.length >= 1) this.changePage(1)
				else {
					this.setState({ currentIndex: -1, id: -1 })
					//判断是在哪个主题下面来提示信息
					if (searchCondition.hasOwnProperty('themeIds')) {
						getMyTheme().then(themes => {
							themes.map(theme => {
								if (theme.themeId === searchCondition.themeIds)
									message.info(theme.topic + '主题资料为空')
								return theme
							})
						})
					} else message.info('资料为空') //在全部一栏下
				}
			}
		}
	}

	state = {
		btnLoading: false,
		currentIndex: -1,
		id: -1,
		attachmentListArray: [],
		attachmentListIdsArray: [],
		isCollectedFile: true,
		editing: false, //是否处于编辑状态
	}

	renderFilePreviewer = data => <FilePreviewer fileData={data.fileData} />

	// 获取资料详情表格 ref
	getCollectedRef = ref => {
		this.collectedRef = ref
	}

	// 获取填写文件信息的表格ref
	getFileRef = ref => {
		this.fileRef = ref
	}

	// 把资料详情的一些重要信息填进文件详情
	getFileFormData = collectedFileData => {
		const { topic, event, people, place, time, departmentId } = collectedFileData
		const defaultDepartmentId = JSON.parse(
			window.localStorage.getItem('department')
		)[0].children[0].id
		return [
			{ name: 'topic', value: topic },
			{ name: 'departmentId', value: departmentId || defaultDepartmentId },
			{ name: 74, value: people },
			{ name: 75, value: time },
			{ name: 76, value: place },
			{ name: 77, value: event },
		]
	}

	// 资料转正
	changeCollectedFileToArchive = (values, collectedFileData) => {
		this.setState({ btnLoading: true })

		const { changeToArchive } = this.props

		values.categoryCode = values.categoryCodeId
		delete values.categoryCodeId

		const data = Object.assign(values, {
			collectedFileId: collectedFileData.id,
			fileId: collectedFileData.fileId,
			zippedFileId: collectedFileData.zippedFileId,
		})
		changeToArchive(data)
			.then(res => {
				message.success('资料转正成功')
				this.setState({ isCollectedFile: true })
				this.afterDeleteOrChange()
			})
			.catch(err => {
				message.error('资料转正失败')
			})
			.finally(() => {
				this.setState({ btnLoading: false })
			})
	}

	afterDeleteOrChange = async () => {
		const { searchCondition, getCollectedFileList } = this.props
		// const totalIndex = this.getTotalIndex(allCollectedFileData)
		// this.changePage(totalIndex + 1)
		const data = {
			id: searchCondition.id,
			size: searchCondition.size,
			current: 1,
		}
		const res = (await getCollectedFileList(data)).data
		let index = 0
		const newrecords = res.records.filter(item => {
			if (item.themeId !== searchCondition.themeIds) {
				index++
				return false
			} else return true
		})
		const newAllCollectedFileData = {
			current: res.current,
			data: newrecords,
			pageSize: res.size - index,
			total: res.total - index,
		}
		newrecords.length === 0
			? this.setState({ currentIndex: -1, id: -1 }, () =>
					message.info('该主题资料为空')
				)
			: this.setState({ allCollectedFileData: newAllCollectedFileData }, () =>
					this.changePage(1)
				)
	}

	// 获取当前项在当前请求数据中的索引
	getCurrentIndex = (collectedFileData, allCollectedFileData) => {
		const id = collectedFileData.id

		for (let i = 0; i < allCollectedFileData.data.length; i++) {
			const temp = allCollectedFileData.data[i]
			if (temp.id === id) {
				return i
			}
		}

		return -1
	}

	// 获取分页器索引
	getTotalIndex = allCollectedFileData => {
		const { currentIndex } = this.state
		if (currentIndex === -1) return -1

		const { current, total, pageSize } = allCollectedFileData || {}
		const totalIndex = Math.min((current - 1) * pageSize + currentIndex + 1, total)

		return totalIndex
	}

	// 改变页面
	changePage = async page => {
		const { getCollectedFileList, searchCondition } = this.props
		const { allCollectedFileData } = this.state
		const { current, pageSize } = allCollectedFileData

		const targetIndex = page - 1
		const targetCurrent = Math.floor(targetIndex / pageSize) + 1

		// 如果想查看的项超出了当前范围，则必须重新请求一次数据
		if (targetCurrent !== current) {
			const data = {
				id: searchCondition.id,
				size: searchCondition.size,
				current: targetCurrent,
			}
			const res = (await getCollectedFileList(data)).data
			const newAllCollectedFileData = {
				current: res.current,
				data: res.records,
				pageSize: res.size,
				total: res.total,
			}
			this.setState({ allCollectedFileData: newAllCollectedFileData }, () =>
				this.changePage(page)
			)
		} else {
			const record =
				allCollectedFileData.data[targetIndex - (current - 1) * pageSize]
			if (record) {
				record.isCollectedFile = true
			}

			this.setState(
				{
					id: -1,
					currentIndex: page - (current - 1) * pageSize - 1,
				},
				() => {
					this.changeItem(record)
				}
			)
		}
	}

	// 改变页面中的项
	changeItem = record => {
		const { getDetail, addPubCollectedFile } = this.props
		getDetail(record)
			.then(res => {
				addPubCollectedFile(res)
				this.setState({ id: res.id })
			})
			.catch(err => {
				message.destroy()
				message.info('该资料已转正或被删除')
			})
	}

	// 删除资料文件
	onDeleteCollectedFile = () => {
		const { id } = this.state
		const { deleteCollectedFile } = this.props
		deleteCollectedFile([id])
			.then(res => {
				message.success('删除成功')
				this.afterDeleteOrChange()
			})
			.catch(err => {
				message.error('删除失败')
			})
	}

	// 删除资料模态框
	showConfirm = () => {
		confirm({
			icon: <ExclamationCircleOutlined />,
			content: '确定删除该资料吗',
			onOk: this.onDeleteCollectedFile,
		})
	}

	onChangeCollectedFileMsg = values => {
		const { updateCollectedFile } = this.props
		const data = this.setAjaxData(values)
		updateCollectedFile(data)
			.then(res => {
				message.success('修改成功')
				this.setState({ editing: false })
				this.changePage(this.state.currentIndex + 1)
				// addPubCollectedFile(data)
			})
			.catch(err => {
				message.error('修改失败')
			})
	}

	setAjaxData = values => {
		const { collectedFileData, allCollectedFileData } = this.props
		const { attachmentListIdsArray } = this.state
		const { id, attachments, fileId, thumbnailFileId, zippedFileId } =
			collectedFileData
		const totalIndex = this.getTotalIndex(allCollectedFileData)
		let ids = attachmentListIdsArray[totalIndex]

		if (ids == null) {
			ids = attachments?.map(item => item.fileId)
		}

		values.attachmentIds = ids

		Object.keys(values).forEach(key => {
			if (values[key] == null) {
				delete values[key]
			}
		})

		return {
			...values,
			id,
			fileId,
			thumbnailFileId,
			zippedFileId,
		}
	}

	getAttachmentFileList = attachmentFileList => {
		const { allCollectedFileData } = this.props
		const totalIndex = this.getTotalIndex(allCollectedFileData)
		const { attachmentListArray } = this.state
		attachmentListArray[totalIndex] = attachmentFileList
		this.setState({ attachmentListArray })
	}

	// 更新附件
	changeAttachments = (attachmentList, callback) => {
		const { updateAttachment, collectedFileData } = this.props
		const archive = {
			id: collectedFileData.id,
			type: collectedFileData.type,
			archiveType: collectedFileData.archiveType,
		}
		updateAttachment({
			fileIds: attachmentList,
			type: 3, // 3 为资料更新附件的 type
			documentId: collectedFileData?.id,
		})
			.then(() => {
				message.success('保存成功')
				this.changeItem(archive)
			})
			.catch(() => {
				message.error('保存失败')
			})
			.finally(() => {
				callback && callback()
			})
	}

	render() {
		const { collectedFileData } = this.props
		const {
			allCollectedFileData = {},
			attachmentListArray,
			isCollectedFile,
			editing,
		} = this.state
		// 刷新后由于 redux 数据会清空，所以选择跳转路由
		if (collectedFileData == null) {
			window.location.replace('/Manager/collectedFiles/collectedFiles')
		}

		const { btnLoading, id } = this.state
		const { total } = allCollectedFileData
		const totalIndex = this.getTotalIndex(allCollectedFileData)
		const defaultFormValue = this.getFileFormData(collectedFileData)

		// 生成文件预览信息
		const { fileToken, thumbnailFileToken, attachments, fileType } = collectedFileData
		const items = [
			{
				original: `/api/file/download/${fileToken}`,
				thumbnail: `/api/file/download/${thumbnailFileToken || fileToken}`,
				fileData: { fileToken, fileType }, // 放在这里利于传参
				renderItem: this.renderFilePreviewer.bind(), // 设置显示图标、视频、图片
			},
		]

		// 文件编辑器的按钮
		const fileEditorBtns = [
			{
				text: '取消',
				onClick: () => {
					this.setState({ isCollectedFile: true, editing: false })
				},
			},
			{
				text: '确定',
				type: 'primary',
				htmlType: 'submit',
				style: { marginLeft: '20px' },
				loading: btnLoading,
			},
		]

		// 资料编辑器非编辑状态下按钮
		const collectedFileEditorBtn = [
			{
				text: <>&#xeabd; 编辑</>,
				type: 'primary',
				className: 'iconfont-buttons',
				onClick: () => {
					this.setState({ editing: true })
				},
			},
			{
				text: <>&#xe636; 转为档案</>,
				type: 'primary',
				className: 'iconfont-buttons',
				onClick: () => {
					this.setState({ isCollectedFile: false, editing: true })
				},
			},
			{
				text: <>&#xe8c7;下载</>,
				className: 'btn-info iconfont-buttons',
				onClick: () => {
					const { exportCollectedFile, collectedFileData } = this.props
					message.info({
						key: 'mark',
						content: '资料正在下载，请稍等片刻！',
						duration: 0,
					})
					exportCollectedFile([collectedFileData.id])
						.then(() => {
							message.success({
								key: 'mark',
								content: '资料成功下载',
								duration: 3,
							})
						})
						.catch(() => {
							message.error({
								key: 'mark',
								content: '资料下载失败',
								duration: 3,
							})
						})
				},
			},
		]

		//资料编辑器编辑状态下按钮
		const collectedFileEditingrBtn = [
			{
				text: <>&#xe619;取消</>,
				className: 'iconfont-buttons',
				onClick: () => {
					this.setState({ editing: false })
				},
			},
			{
				text: <>&#xec09;保存</>,
				className: 'btn-success iconfont-buttons',
				htmlType: 'submit',
			},
			{
				text: <>&#xe816; 删除</>,
				className: 'btn-danger iconfont-buttons',
				onClick: this.showConfirm,
			},
		]

		// 生成附件信息
		const attachmentList = attachments.map(item => ({
			uid: item.fileId,
			name: item.fileName,
			status: 'done',
			url: `/api/file/download/${item.fileToken}`,
		}))

		return (
			<Fragment>
				{/* 主体内容 */}
				<div>
					{id === -1 && (
						<div className='collected-details-skeleton'>
							<Skeleton.Button
								active={false}
								size='large'
								block={true}
								shape='default'
							/>
							<Skeleton.Button
								active={false}
								size='large'
								block={true}
								shape='default'
							/>
							<Skeleton.Button
								active={false}
								size='large'
								block={true}
								shape='default'
							/>
							<Skeleton.Button
								active={false}
								size='large'
								block={true}
								shape='default'
							/>
							<Skeleton.Button
								active={false}
								size='large'
								block={true}
								shape='default'
							/>
							<Skeleton.Button
								active={false}
								size='large'
								block={true}
								shape='default'
							/>
							<Skeleton.Button
								active={false}
								size='large'
								block={true}
								shape='default'
							/>
							<Skeleton.Button
								active={false}
								size='large'
								block={true}
								shape='default'
							/>
							<Skeleton.Button
								active={false}
								size='large'
								block={true}
								shape='default'
							/>
							<Skeleton.Button
								active={false}
								size='large'
								block={true}
								shape='default'
							/>
						</div>
					)}

					{/* 资料详情 */}
					{id !== -1 && (
						<FileDetails
							style={{ marginTop: '10px' }}
							uniqueName='collected-file-details'
							getRef={
								isCollectedFile ? this.getCollectedRef : this.getFileRef
							}
							height={'calc(75vh - 30px)'}
							//items传入 下载地址 缩略图地址 文件data（token和type） render函数
							items={items}
							onFinish={values => {
								isCollectedFile
									? this.onChangeCollectedFileMsg(values)
									: this.changeCollectedFileToArchive(
											values,
											collectedFileData
										)
							}}
							disabled={!editing}
							isShowAttachment={false}
							isFormat={true}
							isCollectedFile={isCollectedFile}
							defaultFileList={
								attachmentListArray[totalIndex] || attachmentList
							}
							getAttachmentFileList={this.getAttachmentFileList}
							onlyShow={false}
							fileType={fileType}
							defaultFormValue={defaultFormValue}
							buttons={
								isCollectedFile
									? editing
										? collectedFileEditingrBtn
										: collectedFileEditorBtn
									: fileEditorBtns
							}
							changeAttachments={(ids, callback) => {
								this.changeAttachments(ids, callback)
							}}
							addToWorkbenchData={[{ contentId: this.state.id, type: 3 }]}
							notChangefile={true}
						/>
					)}

					{/* 翻页 */}
					<Pagination
						className='details-pagination'
						current={totalIndex}
						onChange={page => {
							this.changePage(page)
						}}
						total={total}
						pageSize={1}
						showSizeChanger={false}
					/>
				</div>
			</Fragment>
		)
	}
}

export default Details
