import { Modal, message } from 'antd'
import { borrowUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
// 更新红点函数
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
// 消息提示
export const tips = {
	CHOOSE_AT_LEAST_ONE: '请选择一项后再进行操作',
	RECALL_SUCCESS: '撤回成功',
	DELETE_SUCCESS: '删除成功',
	SUBMIT_SUCCESS: '提交成功',
	UPDATE_SUCCESS: '保存成功',
	APPLY_SUCCESS: '申请成功',
}
/**
 * 撤回申请
 * @param {Number} id 申请id
 */
export const recallUseApplyThunk = id => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '撤回借阅申请',
			content: '您正在对当前申请进行撤回操作，是否继续？',
			okText: '确定',
			cancelText: '取消',
			okType: 'primary',
			onOk: () => {
				_fetch({
					url: borrowUrl.recallUseApply,
					data: id,
					type: 'post',
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.RECALL_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 删除申请
 * @param {Number} id 申请id
 */
export const deleteUseApplyThunk = id => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '删除借阅申请',
			content: '您正在对当前申请进行删除操作，是否继续？',
			okType: 'primary',
			okText: '确定',
			cancelText: '取消',
			onOk: () => {
				_fetch({
					url: borrowUrl.deleteUseApply,
					type: 'post',
					data: id,
					isJson: true,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.DELETE_SUCCESS)
						updataRedDots()
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 提交申请
 * @param {Number} id 申请id
 */
export const submitApplyThunk = id => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '提交借阅申请',
			content: '您正在对当前申请进行提交操作，是否继续？',
			okType: 'primary',
			okText: '确定',
			cancelText: '取消',
			onOk: () => {
				_fetch({
					url: borrowUrl.submitUseApply,
					data: {
						id,
					},
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.SUBMIT_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 编辑申请
 * @param {object} data 申请数据
 */
export const updateApplyThunk = data => () =>
	new Promise(resolve => {
		Modal.confirm({
			maskClosable: true,
			title: '编辑借阅申请',
			content: '确定保存？',
			okType: 'primary',
			okText: '确定',
			cancelText: '取消',
			onOk: () => {
				_fetch({
					url: borrowUrl.updateUseApply,
					type: 'post',
					isJson: true,
					data,
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.UPDATE_SUCCESS)
						resolve(res.data.data)
					}
				})
			},
		})
	})

/**
 * 状态为完成时可获取申请结果详情
 */
export const getMyUseResultThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: borrowUrl.getMyUseResult,
			type: 'get',
			data: {
				id,
			},
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

/**
 * 下载文件或案卷
 */
export const downloadFilesThunk = (useContentId, type) => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: borrowUrl.downloadMyUse,
			type: 'get',
			responseType: 'blob',
			params: {
				useContentId: useContentId,
			},
		}).then(res => {
			if (res && res.status === 200) {
				resolve()
			}
			const blob = res.data
			const urlObject = window.URL || window.webkitURL || window
			const export_blob = new Blob([blob], {
				type: res.data.type,
			})
			const a = document.createElement('a') // 利用a标签特性下载
			a.style.display = 'none'
			const url = urlObject.createObjectURL(export_blob)
			a.href = url
			let str = res.headers['content-disposition'].split(';')
			let downloadName = str[1].replace(/filename=/, '')
			a.download = decodeURI(downloadName)
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
		})
	})

//暂存
export const borrowApplyThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: borrowUrl.useApply,
			type: 'post',
			data: data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				updataRedDots()
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

//扩展申请
export const borrowApplyExtend = data => {
	return _fetch({
		url: borrowUrl.extendUseApply,
		type: 'post',
		data: data,
		isJson: true,
	}).then(res => {
		if (res && res.data.code === 200) {
			updataRedDots()
		}
	})
}

//申请延期
export const useApplyDelayThunk = id => () =>
	new Promise((resolve, reject) => {
		Modal.confirm({
			maskClosable: true,
			title: '申请延期',
			content: '您正在进行申请延期操作，是否继续？',
			okText: '确定',
			cancelText: '取消',
			okType: 'primary',
			onOk: () => {
				_fetch({
					url: borrowUrl.useApplyDelay,
					data: {
						id,
					},
				}).then(res => {
					if (res && res.data.code === 200) {
						message.success(tips.APPLY_SUCCESS)
						updataRedDots()
						resolve(res.data.data)
					} else {
						reject()
					}
				})
			},
		})
	})
