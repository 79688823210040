import React, { Component } from 'react'
import { userManagerUrl } from '@/Utils/Urls'
import { Button, message, Modal, Upload, Space } from 'antd'
import _fetch from '@/Utils/Fetch'
import { UploadOutlined } from '@ant-design/icons'

export default class Buttons extends Component {
	formRef = React.createRef()
	state = {
		visible: false, // 是否显示上传文件的模态框
		fileList: [], //文件列表，用于控制upload组件
		uploading: false, //上传按钮loading显示控制
		exportUserLoading: false, //导出按钮loading显示控制
		exportTemplateLoading: false, //导出模板按钮loading显示控制
	}
	// 显示模态框
	showModal = () => {
		this.setState({
			visible: true,
		})
	}
	// 处理本地文件上传，转化为数据
	handleFileChange = info => {
		//处理文件change，保证用户选择的文件只有一个
		let fileList = [...info.fileList]
		fileList = fileList.slice(-2)

		// 2. Read from response and show file link
		fileList = fileList.map(file => {
			if (file.response) {
				// Component will show file.url as link
				file.url = file.response.url
			}
			return file
		})

		this.setState({ fileList })
	}
	// 处理文件上传到服务器
	handleUpload = () => {
		// 如果不存在本地上传的文件，则无法上传文件到服务器
		if (!this.state.fileList.length) {
			message.warning('请选择要上传的文件')
			return false
		}
		const formData = new FormData() // 构造一个formData实例对象，用于存储formData数据
		formData.append('file', this.state.fileList[0].originFileObj) // 将后台指定的字段名作为属性，文件数据作为属性值
		this.setState({
			uploading: true, // 上传成功后取消加载状态
		})
		// 调用上传接口
		_fetch({
			type: 'post',
			url: userManagerUrl.importUser,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
			.then(({ data }) => {
				// 此处拿到文件ID
				message.success('上传成功')
				// 成功后关闭模态框
				this.setState({
					visible: false,
				})
				this.props.formRef.setFieldsValue({
					fileId: data.data,
				})
				this.props.reloadTable()
			})
			.catch(err => {})
			.finally(() => {
				this.setState({
					fileList: [],
					uploading: false,
				})
			})
	}

	// 处理模态框关闭
	handleCancel = e => {
		this.setState({
			visible: false,
		})
	}

	//修改按钮loading状态
	changeLoading = button => {
		this.setState({ [button]: !this.state[button] })
	}

	render() {
		const {
			showAddUserManagerModal,
			showEditUserManagerModal,
			deleteUser,
			formRef,
			handledownloadExcel,
			handledownloadModel,
		} = this.props
		return (
			<Space>
				<Button
					className='btn-success iconfont-buttons'
					title='新增'
					onClick={showAddUserManagerModal}>
					&#xe634;
				</Button>
				<Button
					className='iconfont-buttons'
					title='编辑'
					type='primary'
					onClick={showEditUserManagerModal}>
					&#xeabd; 编辑
				</Button>
				<Button
					className='btn-danger iconfont-buttons'
					title='删除'
					onClick={deleteUser}>
					&#xe816; 删除
				</Button>
				<Button
					className='upload-file iconfont-buttons '
					title='导入'
					type='primary'
					onClick={this.showModal}>
					&#xe8c7; 导入
				</Button>
				<Modal
					title='导入'
					visible={this.state.visible}
					onOk={this.handleUpload}
					onCancel={this.handleCancel}>
					<Upload
						accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						fileList={this.state.fileList}
						beforeUpload={(f, fList) => false}
						onChange={this.handleFileChange}>
						<Button>
							<UploadOutlined /> 选择文件（仅限Excel表格）
						</Button>
					</Upload>
				</Modal>
				<Button
					className='iconfont-buttons'
					title='导出'
					type='primary'
					onClick={() => handledownloadExcel(formRef, this.changeLoading)}
					loading={this.state.exportUserLoading}>
					&#xe8c6; 导出
				</Button>
				<Button
					className='iconfont-buttons'
					title='导出模板'
					type='primary'
					onClick={() => {
						handledownloadModel(this.changeLoading)
					}}
					loading={this.state.exportTemplateLoading}>
					&#xe66b; 导出模板
				</Button>
			</Space>
		)
	}
}
