import { dossierUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'

// 获取档案详情
export const getDossierListThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dossierUrl.getDossierList,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

export const getMyStorageDossierThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dossierUrl.getMyStorageDossier,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

export const getInsertArchiveListThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dossierUrl.getInsertArchiveList,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})

export const searchDossierListThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dossierUrl.searchDossierList,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})
