import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authUtil, typeUtil } from '@/Utils'
import { Button, Space, Modal, message } from 'antd'
import { linkTo } from '@/Routes/ManageRouter'
import { homePath } from '@/Components/Login/LoginForm'
import { dossierEnum } from '@/Utils/Enum'
import { withRouter } from 'react-router-dom'
import {
	getDetailThunk,
	storageThunk,
	managerRollbackThunk,
	addWorkbenchContentThunk, // 添加案卷或档案（文件）到收藏夹
	reviewFileThunk, // 档案&案卷审核
	assignFileThunk, // 档案&案卷指派
	archiveFileThunk, // 档案&案卷归档
	oneKeyChangeStatusThunk, // 一键修改状态 归档或入库
	verifyApplyThunk, // 文件鉴定
	toDeleteThunk, // 删除案卷或者文件
	emptyRecycleBinThunk, // 清空回收站
	tips, // 提示消息
	inititalPubArchiveAction, // 初始化案卷
	routeChangeAction,
	addPubFileAction,
	addPubDossierAction,
	addOldDetailsAction,
	pushRoutersAction,
	toRestoreThunk, //还原案卷或档案（文件）
	toDestroyThunk, //销毁案卷或档案（文件）
	getDossierRollbackRecord, //获取案卷最新的回退记录
	getArchiveRollbackRecord, // 获取档案最新的回退记录
	addAppraisalDataToRedux, // 鉴定数据
	deleteWorkbenchContentThunk, //取消收藏
} from './ActionCreator'
import {
	appraisalUrl,
	recordFileUrl,
	submitFileUrl,
	workStation,
	preArchivedUrl,
	archivedUrl,
	myApprovalBorrowUrl,
	recycleBinUrl,
	toDeleteUrl,
	dossierUrl,
} from '@/Utils/Urls'
import { AppstoreAddOutlined } from '@ant-design/icons'
import RollbackModal from './RollbackModal' // 回退案卷或档案（文件）的模态框
import JoinWorkbenchModal from './JoinWorkbenchModal' // 加入收藏夹的模态框
import RollbackRecordModal from './RollbackRecordModal' // 查看回退记录的模态框
import VerifyApplyModal from './VerifyApplyModal' // 文件鉴定
import BorrowApprovalModal from './BorrowApprovalModal' // 借阅审批
import { history } from '@/index'
import {
	pub_getAllTableData,
	pub_setSelectedStatus,
} from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator.js'
import './index.less'

const { withAuth, hasAuth } = authUtil
const rollbackModalRef = React.createRef(null) // 回退案卷或档案（文件）的模态框
const joinWorkbenchModalRef = React.createRef(null) // 获取添加收藏夹模态框的实例
const verifyApplyPubModalFormRef = React.createRef(null) // 获取档案鉴定申请模态框的 PubModalForm 实例
const verifyApplyModalRef = React.createRef(null) // 案卷修正申请模态框实例
const rollbackRecordModalRef = React.createRef(null) // 获取查看回退详情框表单实例

// 用来判断选中文件是否在状态内
const isDisabled = (records, x, y) => {
	for (var record of records) {
		if (record.status !== x) {
			if (y) {
				if (record.status !== y) {
					return true
				}
			} else {
				return true
			}
		}
	}
	return false
}

// 用来判断选中文件是否在状态内
// const isDisabledType = (records, x) => {
//   for (var record of records) {
//     if (record.type !== x) {
//       return true
//     }
//   }
//   return false
// };

/**
 * 功能:删除tab栏时，计算出下一次激活的tab
 * 删除激活的tab，若它是第一个，激活最后一个。若不是，激活前一个
 * 删除非激活的tab，那没事了
 * @param {Array} routes
 * @param {string} route
 * @param {string} targetKey
 * @return {string}
 */
function getNextActiveKey(routes, route, targetKey) {
	let nextActiveKey = ''
	if (routes.length === 1) {
		return homePath
	}
	if (targetKey === route) {
		if (routes[0].key === targetKey) {
			return routes[routes.length - 1].key
		} else {
			routes.every(item => {
				item.key !== targetKey && (nextActiveKey = item.key)
				return item.key !== targetKey
			})
		}
		return nextActiveKey
	}
	return route
}

const mapStateToProps = state => {
	return {
		route: state.route.item, // 路由
		routes: state.route.items,
		pub_dossierDetails: state.pubForm.pub_dossierDetails, // 获取案卷数据
		Selections: state.pubTable.pub_rowSelection, // 获取选中数据
		pub_fileDetails: state.pubForm.pub_fileDetails, // 获取文件数据
		pubArchive: state.pubArchive,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		addPubFileData(data) {
			return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		addPubDossierData(data) {
			return dispatch(addPubDossierAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		addOldDetailsrData(data, current) {
			return dispatch(addOldDetailsAction(data, current)) // 添加Action方法，用于添加文件详情分栏
		},
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey.key))
			} else {
				dispatch(routeChangeAction(activeKey.key))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
		},
		pushRouters(link) {
			return dispatch(pushRoutersAction(link))
		},
		pub_setSelectedStatus(...rest) {
			return dispatch(pub_setSelectedStatus(...rest))
		},
		// 表格
		getAllTableData(...rest) {
			return dispatch(pub_getAllTableData(...rest)) // 把获取到的表格所有数据发送到store
		},
		// 初始化档案
		inititalPubArchive: (markName, pubArchive) =>
			dispatch(inititalPubArchiveAction(markName, pubArchive)),
		// 请求
		getDetail: (...rest) => dispatch(getDetailThunk(...rest)), // 详情
		storage: (...rest) => dispatch(storageThunk(...rest)), // 入库
		emptyRecycleBin: (...rest) => dispatch(emptyRecycleBinThunk(...rest)), // 清空回收站
		managerRollback: (...rest) => dispatch(managerRollbackThunk(...rest)), // 回退
		reviewFile: (...rest) => dispatch(reviewFileThunk(...rest)), // 档案&案卷审核
		assignFile: (...rest) => dispatch(assignFileThunk(...rest)), // 档案&案卷审核
		pigeonhole: (...rest) => dispatch(archiveFileThunk(...rest)), // 档案&案卷归档
		todelete: (...rest) => dispatch(toDeleteThunk(...rest)), //删除案卷或者文件
		verifyApply: (...rest) => dispatch(verifyApplyThunk(...rest)), // 文件鉴定
		addWorkbenchContent: (...rest) => dispatch(addWorkbenchContentThunk(...rest)),
		toDestroy: (...rest) => dispatch(toDestroyThunk(...rest)), // 销毁案卷或者文件
		toRestore: (...rest) => dispatch(toRestoreThunk(...rest)), // 还原案卷或者文件
		getDossierRollback: (...rest) => dispatch(getDossierRollbackRecord(...rest)), //获取回退案卷信息
		getArchiveRollback: (...rest) => dispatch(getArchiveRollbackRecord(...rest)), //获取回退档案信息
		addAppraisalDataToRedux: (...rest) => dispatch(addAppraisalDataToRedux(...rest)),
		oneKeyChangeStatus: (...rest) => dispatch(oneKeyChangeStatusThunk(...rest)),
		deleteWorkbenchContent: (...rest) =>
			dispatch(deleteWorkbenchContentThunk(...rest)), //取消收藏
		// 以下为删除路由
		removeTab(targetKey, history, route, routes) {
			let nextActiveKey = getNextActiveKey(routes, route, targetKey)
			dispatch({ type: 'ROUTE_CHANGE', filter: nextActiveKey })
			dispatch({ type: 'TAB_REMOVE', filter: targetKey })
			if (history) {
				history.push(linkTo.to(nextActiveKey))
			} else {
				dispatch(routeChangeAction(nextActiveKey))
			}
		},
	}
}
const appraisalLink = {
	title: '鉴定详情',
	key: '/workarea/commonComponents/appraisalDetails',
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ButtonList extends Component {
	state = {
		visible: false, // 显示利用模态框
		records: null, // 传入利用模态框的选中数据
		isOpen: false, // 是否展开更多
		value: '',
	}
	/**
	 * 设置状态以判断
	 * @param {Object} data  包含status状态，records所选信息，refresh刷新函数
	 * @param {Num} status  根据status来选择按钮组
	 * @param {Arr} records records所选信息或者文件案卷详情信息，里面的数据用于发请求
	 * @param {Fn} refresh  刷新函数，非详情页面用传入的@param pub_getAllData 函数来刷新，详情页面重新请求
	 */

	handlerChange = () => {
		return e => {
			this.setState({
				value: e.target.value,
			})
		}
	}

	submitHandler = () => {
		return e => {
			if (e.keyCode === 13) {
				this.props.onReceiveKeywords(this.state.value)
				this.setState({
					value: '',
				})
			}
		}
	}

	setData = () => {
		const {
			Selections,
			pub_fileDetails,
			pubArchive,
			refresh, // 刷新表格
			pub_setSelectedStatus,
		} = this.props

		// 数组最后一项
		let statusArrEnd = window.location.href.split('/').pop()
		// 设置status的值使其于pub_rowSelection上的路由相等
		let status = !isNaN(statusArrEnd)
			? 'Workstation'
			: statusArrEnd.substring(0, 1).toUpperCase() + statusArrEnd.substring(1)
		// data里面存储按钮组状态于选中数据（详情数据）
		let data = {}
		switch (status) {
			case 'RecordFiles': {
				// 著录
				data = {
					status: 0,
					records: Selections.RecordFiles,
					refresh: () => refresh(),
					backReturn: false, // 无返回按钮
				}
				break
			}
			case 'UnderReview': {
				// 审核中
				data = {
					status: 12,
					records: Selections.UnderReview,
					refresh: () => refresh(),
					backReturn: false, // 无返回按钮
				}
				break
			}
			case 'Prearchive': {
				// 预归档
				data = {
					status: 1,
					records: Selections.Prearchive,
					refresh: () => refresh(),
					backReturn: false, // 无返回按钮
				}
				break
			}
			case 'Archived': {
				// 已归档
				data = {
					status: 2,
					records: Selections.Archived,
					refresh: () => refresh(),
					backReturn: false, // 无返回按钮
				}
				break
			}
			case 'RecycleBin': {
				// 回收站
				data = {
					status: 4,
					records: Selections.RecycleBin,
					refresh: () => refresh(),
					backReturn: false, // 无返回按钮
				}
				break
			}
			case 'ExpiredFiles': {
				// 过期
				data = {
					status: 6,
					records: Selections.ExpiredFiles,
					refresh: () => refresh(),
					backReturn: false, // 无返回按钮
				}
				break
			}
			case 'Workstation': {
				// 收藏
				data = {
					status: 7,
					records: Selections.Workstation,
					refresh: () => {
						refresh()
						// 清空选中数据
						pub_setSelectedStatus(-2)
					},
					backReturn: false, // 无返回按钮
				}
				break
			}
			case 'FileDetails': {
				// 文件详情
				let dataStatus
				if (
					pub_fileDetails.route ===
						'/workarea/commonComponents/dossierDetails' || // 档案详情
					pub_fileDetails.route === '/newConstruction/newArchives' || // 新建档案
					pub_fileDetails.route ===
						'/workarea/commonComponents/approvalDetails' || // 借阅审批
					pub_fileDetails.route === '/workarea/commonComponents/details' || // 档案详情
					pub_fileDetails.route === '/workarea/commonComponents/fileFixApply' // 修正申请
				) {
					dataStatus = 8 // 查看状态
				} else if (pub_fileDetails.route === '/workarea/myFiles/expiredFiles') {
					dataStatus = 6 // 过期状态
				} else {
					dataStatus = pub_fileDetails.status
				}
				data = {
					status: dataStatus, // 是否过期
					records: [pub_fileDetails],
					refresh: () => {
						this.props.getDetail(pub_fileDetails).then(data => {
							// 请求详情数据
							if (
								pub_fileDetails.route ===
									'/workarea/commonComponents/dossierDetails' || // 档案详情
								pub_fileDetails.route ===
									'/workarea/myFiles/expiredFiles' || // 过期档案
								pub_fileDetails.route ===
									'/newConstruction/newArchives' || // 新建案卷查看
								pub_fileDetails.route ===
									'/newConstruction/UrlToArchives' || // 新媒体
								pub_fileDetails.route ===
									'/workarea/commonComponents/approvalDetails' || // 借阅审批
								pub_fileDetails.route ===
									'/workarea/commonComponents/details' || // 档案详情
								pub_fileDetails.route ===
									'/workarea/commonComponents/fileFixApply' // 修正申请
							) {
								data['route'] = pub_fileDetails.route
							}
							this.props.addPubFileData(data) // 文件详情传到redux
						})
					},
					backReturn: false, // 增加返回按钮
				}
				break
			}
			case 'DossierDetails': {
				// 案卷详情
				let dataStatus
				let pub_dossierDetails = pubArchive['dossierDetails'].old.archiveData
				pub_dossierDetails['archiveDetail'] =
					pubArchive['dossierDetails'].new.filesData
				if (pub_dossierDetails.route === '/workarea/myFiles/expiredFiles') {
					dataStatus = 6 // 过期状态
				} else if (
					pub_dossierDetails.route ===
					'/workarea/commonComponents/approvalDetails'
				) {
					dataStatus = 8 // 查看状态
				} else {
					dataStatus = pub_dossierDetails.status
				}
				data = {
					status: dataStatus, // 判断是否为过期
					records: [pub_dossierDetails],
					refresh: () => {
						this.props.getDetail(pub_dossierDetails).then(data => {
							// 请求详情数据
							data['route'] =
								pub_dossierDetails.route ===
									'/workarea/myFiles/expiredFiles' ||
								pub_dossierDetails.route ===
									'/workarea/commonComponents/approvalDetails'
									? pub_dossierDetails.route
									: null
							data.archiveDetail?.forEach(archiveData => {
								const { fileToken, fileType } = archiveData
								// 设置预览图
								archiveData['preview'] = {
									type: 1,
									fileData: {
										fileToken,
										fileType,
									},
								}
								// 每个来个key
								archiveData['key'] = archiveData.id
							})
							this.props.inititalPubArchive('dossierDetails', {
								filesData: data.archiveDetail || [],
								archiveData: data,
							})
						})
					},
					backReturn: false, // 增加返回按钮
				}
				break
			}
			case 'Details': {
				// 详情
				let dataStatus
				if (JSON.parse(sessionStorage.getItem('detailsNeeded') || '{}')?.type) {
					if (pub_fileDetails.route === '/workarea/myFiles/expiredFiles') {
						dataStatus = 6 // 过期状态
					} else {
						dataStatus = pub_fileDetails.status
					}
					data = {
						status: dataStatus, // 是否过期
						records: [pub_fileDetails],
						refresh: () => {
							this.props.getDetail(pub_fileDetails).then(data => {
								// 请求详情数据
								if (
									pub_fileDetails.route ===
									'/workarea/myFiles/expiredFiles'
								) {
									// 过期档案
									data['route'] = pub_fileDetails.route
								}
								this.props.addPubFileData(data) // 文件详情传到redux
							})
						},
						backReturn: false, // 增加返回按钮
					}
				} else {
					let pub_dossierDetails = pubArchive['dossierDetails'].old.archiveData
					pub_dossierDetails['archiveDetail'] =
						pubArchive['dossierDetails'].new.filesData
					if (pub_dossierDetails.route === '/workarea/myFiles/expiredFiles') {
						dataStatus = 6 // 过期状态
					} else {
						dataStatus = pub_dossierDetails.status
					}
					data = {
						status: dataStatus, // 判断是否为过期
						records: [pub_dossierDetails],
						refresh: () => {
							this.props.getDetail(pub_dossierDetails).then(data => {
								// 请求详情数据
								data['route'] =
									pub_dossierDetails.route ===
									'/workarea/myFiles/expiredFiles'
										? pub_dossierDetails.route
										: null
								data.archiveDetail?.forEach(archiveData => {
									const { fileToken, fileType } = archiveData
									// 设置预览图
									archiveData['preview'] = {
										type: 1,
										fileData: {
											fileToken,
											fileType,
										},
									}
									// 每个来个key
									archiveData['key'] = archiveData.id
								})
								this.props.inititalPubArchive('dossierDetails', {
									filesData: data.archiveDetail || [],
									archiveData: data,
								})
							})
						},
						backReturn: false, // 增加返回按钮
					}
				}
				break
			}
			case 'Record': {
				// 我的档案
				data = {
					status: 9,
					records: Selections.Record,
					refresh: () => refresh(),
					backReturn: false,
				}
				break
			}
			case 'CollectedFiles': {
				// 资料
				data = {
					status: 10,
					records: Selections.CollectedFiles,
					refresh: () => refresh(),
					backReturn: false,
				}
				break
			}
			// case 'Archives': {
			//   data = {
			//     status: 11,
			//     records: Selections.Archives,
			//     refresh: () => refresh(),
			//     backReturn: false
			//   }
			//   break;
			// }
			default: {
				data = {
					status: -1,
					records: [],
					refresh: null,
				}
			}
		}
		return data
	}

	/**
	 * 匹配按钮组
	 * @param {Object} data  包含status状态，records所选信息，refresh刷新函数 0:著录，1:预归档，2预归档，3著录（回退）4回收站 5销毁 6过期 7收藏 8查看
	 * @param {Function} onClick onClick函数里面主要是判断是否有选中项
	 * @param {Function} data.refresh refresh函数里面有数字主要是手动改状态，等后台issue改好了就去掉
	 */
	setButtonList = () => {
		//获取工作台id
		const { route } = this.props
		let workbenchId = route.split('/').slice(-1)[0]

		const {
			storage, //  入库
			todelete, // 删除
			reviewFile, // 审核
			assignFile, // 指派
			pigeonhole, // 归档
			toRestore, //回收站还原
			toDestroy, //回收站销毁
			emptyRecycleBin, //回收站清空
			oneKeyChangeStatus, //一键入库or归档
			deleteWorkbenchContent, //取消收藏
		} = this.props

		const {
			managerRollbackArchive,
			managerRollbackDossier,
			storageArchive,
			storageDossier,
		} = preArchivedUrl

		const { submitFixApply } = archivedUrl
		const {
			userDeleteDossier,
			userDeleteArchive,
			managerDeleteDossier,
			managerDeleteArchive,
		} = toDeleteUrl
		const {
			userRestoreArchive,
			userDestroyArchive,
			userDestroyDossier,
			managerDestroyDossier,
			managerDestroyArchive,
			managerResortDossier,
			userResortDossier,
			managerRestoreArchive,
		} = recycleBinUrl

		const { distributeFile } = myApprovalBorrowUrl
		let data = this.setData()
		let ButtonList

		let haveCollection = true //判断需不需要渲染收藏按钮，在detail页面就不用
		let isDetails = false
		//比如当前的路径是http://localhost:3001/collectFiles lastHref就是collectFiles
		const lastHref = window.location.href.split('/').pop()
		if (
			lastHref === 'details' ||
			lastHref === 'collectedFileDetails' ||
			lastHref === 'dossierDetails'
		) {
			haveCollection = false
			isDetails = true
		}

		let waitForVerifyUser // 当前被点击的文件&&案卷的被指派者id
		const userId = JSON.parse(localStorage.getItem('userMessage')).user.id // 当前操作者id

		switch (data.status) {
			case 0: {
				// 著录
				ButtonList = !this.props.isEdit ? (
					this.props.Open || this.state.isOpen || this.props.notShow ? (
						<Space>
							{data.backReturn && (
								<Button
									title='返回'
									className='iconfont-buttons'
									onClick={() => {
										history.goBack()
									}}>
									&#xe637; 返回
								</Button>
							)}
							{/* this.state.isOpen&&{withAuth(<Button className="btn-success" onClick={() => {
              (data.records.length) ? pigeonhole(data.records).then(() => { data.refresh() }) : this.showModel()
            }}>归档</Button>, recordFileUrl.archiveFile)} */}
							{withAuth(
								<Button
									type='primary'
									className='iconfont-buttons'
									title='归档'
									onClick={() => {
										data.records.length
											? reviewFile(data.records).then(() => {
													data.refresh()
												})
											: this.showModel()
									}}>
									&#xe628; 归档
								</Button>,
								submitFileUrl.submitToVerify
							)}
							{this.props.route.split('/').indexOf('details') === -1 &&
								(() => {
									if (
										hasAuth([userDeleteDossier, userDeleteArchive]) ||
										hasAuth([
											managerDeleteArchive,
											managerDeleteDossier,
										])
									) {
										return (
											<Button
												className='btn-danger iconfont-buttons'
												title='删除'
												onClick={() => {
													data.records.length
														? todelete(data.records).then(
																() => {
																	data.refresh()
																	isDetails &&
																		history.goBack()
																}
															)
														: this.showModel()
												}}>
												&#xe816; 删除
											</Button>
										)
									}
								})()}
							{/*
							{withAuth(
								<Button
									type='primary'
									className='iconfont-buttons'
									title='指派'
									onClick={() => {
										data.records.length
											? assignFile(data.records).then(() => {
													data.refresh()
												})
											: this.showModel()
									}}>
									&#xe632; 指派
								</Button>,
								submitFileUrl.submitToUser
							)}
							*/}
							{haveCollection &&
								withAuth(
									<Button
										className='iconfont-buttons'
										type='primary'
										onClick={() => {
											data.records.length
												? joinWorkbenchModalRef.current.show()
												: this.showModel()
										}}
										title='收藏'>
										<AppstoreAddOutlined
											style={{ fontSize: '18px' }}
										/>{' '}
										收藏
									</Button>,
									workStation.addWorkbenchContent
								)}
							{this.props.route.split('/').indexOf('details') === -1 &&
								this.props.notShow === undefined && (
									<Button
										type='primary'
										value={this.state.value}
										onClick={() => {
											this.setState(
												{ isOpen: !this.state.isOpen },
												this.props.handleEmail(!this.state.isOpen)
											)
										}}>
										收起
									</Button>
								)}
						</Space>
					) : (
						this.props.notShow === undefined && (
							<Space>
								{/* {withAuth(<Button type="primary" className="iconfont-buttons" title='指派' onClick={() => {
                  (data.records.length) ? assignFile(data.records).then(() => { data.refresh() }) : this.showModel()
                }}>&#xe632; 指派</Button>, submitFileUrl.submitToUser)} */}
								{withAuth(
									<Button
										type='primary'
										className='iconfont-buttons'
										title='归档'
										onClick={() => {
											data.records.length
												? reviewFile(data.records).then(() => {
														data.refresh()
													})
												: this.showModel()
										}}>
										&#xe628; 归档
									</Button>,
									submitFileUrl.submitToVerify
								)}
								{this.props.route.split('/').indexOf('details') === -1 &&
									(() => {
										if (
											hasAuth([
												userDeleteDossier,
												userDeleteArchive,
											]) ||
											hasAuth([
												managerDeleteArchive,
												managerDeleteDossier,
											])
										) {
											return (
												<Button
													className='btn-danger iconfont-buttons'
													title='删除'
													onClick={() => {
														data.records.length
															? todelete(data.records).then(
																	() => {
																		data.refresh()
																		isDetails &&
																			history.goBack()
																	}
																)
															: this.showModel()
													}}>
													&#xe816; 删除
												</Button>
											)
										}
									})()}
								{this.props.route.split('/').indexOf('details') ===
									-1 && (
									<Button
										type='primary'
										value={this.state.value}
										onClick={() => {
											this.setState(
												{ isOpen: !this.state.isOpen },
												this.props.handleEmail(this.state.isOpen)
											)
										}}>
										更多
									</Button>
								)}
							</Space>
						)
					)
				) : (
					(() => {
						if (
							hasAuth([userDeleteDossier, userDeleteArchive]) ||
							hasAuth([managerDeleteArchive, managerDeleteDossier])
						) {
							return (
								<Button
									className='btn-danger iconfont-buttons'
									title='删除'
									onClick={() => {
										data.records.length
											? todelete(data.records).then(() => {
													data.refresh()
													isDetails && history.goBack()
												})
											: this.showModel()
									}}>
									&#xe816; 删除
								</Button>
							)
						}
					})()
				)
				break
			}
			case 1: {
				// 预归档
				ButtonList = (
					<Space>
						{data.backReturn && (
							<Button
								className='iconfont-buttons'
								title='返回'
								onClick={() => {
									history.goBack()
								}}>
								&#xe637; 返回
							</Button>
						)}
						{withAuth(
							<Button
								type='primary iconfont-buttons'
								title='入库'
								onClick={() => {
									data.records.length
										? storage(
												data.records,
												this.props.changSpinning
											).then(() => {
												data.refresh()
											})
										: this.showModel()
								}}>
								&#xe681; 入库
							</Button>,
							[storageDossier, storageArchive]
						)}
						{haveCollection &&
							withAuth(
								<Button
									type='primary iconfont-buttons'
									title='一键入库'
									onClick={() => {
										//  0-著录中，1-预归档，2-已归档，12-审核中
										oneKeyChangeStatus({
											oldStatus: 1,
											newStatus: 2,
										}).then(() => {
											data.refresh()
										})
									}}>
									&#xe68c; 一键入库
								</Button>,
								dossierUrl.oneClickChange
							)}
						{withAuth(
							<Button
								className='btn-warning iconfont-buttons'
								title='回退'
								onClick={() => {
									data.records.length
										? rollbackModalRef.current.show()
										: this.showModel()
								}}>
								&#xe637; 回退
							</Button>,
							[managerRollbackArchive, managerRollbackDossier]
						)}
						{(() => {
							if (
								hasAuth([userDeleteDossier, userDeleteArchive]) ||
								hasAuth([managerDeleteArchive, managerDeleteDossier])
							) {
								return (
									<Button
										className='btn-danger iconfont-buttons'
										title='删除'
										onClick={() => {
											data.records.length
												? todelete(data.records).then(() => {
														data.refresh()
													})
												: this.showModel()
										}}>
										&#xe816; 删除
									</Button>
								)
							}
						})()}
						{haveCollection &&
							withAuth(
								<Button
									className='iconfont-buttons'
									type='primary'
									onClick={() => {
										data.records.length
											? joinWorkbenchModalRef.current.show()
											: this.showModel()
									}}
									title='收藏'>
									<AppstoreAddOutlined style={{ fontSize: '18px' }} />{' '}
									收藏
								</Button>,
								workStation.addWorkbenchContent
							)}
					</Space>
				)
				break
			}
			case 2: {
				// 已归档
				ButtonList = (
					<Space>
						{data.backReturn && (
							<Button
								className='iconfont-buttons'
								title='返回'
								onClick={() => {
									history.goBack()
								}}>
								&#xe637; 返回
							</Button>
						)}
						{withAuth(
							<Button
								className='iconfont-buttons'
								type='primary'
								title='修正'
								onClick={() => {
									data.records.length
										? this.handleFixApply(data.records)
										: this.showModel()
								}}
								disabled={
									(!data.records || data.records.length > 1) && true
								}>
								&#xe6d0; 修正
							</Button>,
							submitFixApply
						)}
						{haveCollection &&
							withAuth(
								<Button
									className='iconfont-buttons'
									type='primary'
									onClick={() => {
										data.records.length
											? joinWorkbenchModalRef.current.show()
											: this.showModel()
									}}
									title='收藏'>
									<AppstoreAddOutlined style={{ fontSize: '18px' }} />{' '}
									收藏
								</Button>,
								workStation.addWorkbenchContent
							)}
						{this.props.location.pathname.split('/').indexOf('details') ===
							-1 && (
							<Button
								className='iconfont-buttons'
								type='primary'
								value={this.state.value}
								onClick={() => {
									this.setState(
										{ isOpen: !this.state.isOpen },
										this.props.handleEmail()
									)
								}}>
								{this.props.Open ? '收起' : '更多'}
							</Button>
						)}
						{/* 暂时注释掉 */}
						{/* {withAuth(
              <Popover visible={false}>
                <Button
                  className="btn-warning"
                  onClick={() => {
                    (data.records.length) ? this.openVerifyApplyModal(data) : this.showModel()
                  }}
                >鉴定</Button>
              </Popover>,
              appraisalUrl.verifyApply
            )} */}
					</Space>
				)
				break
			}
			case 3: {
				ButtonList = !this.props.isEdit && (
					<Space>
						{data.backReturn && (
							<Button
								className='iconfont-buttons'
								title='返回'
								type='primary'
								onClick={() => {
									history.goBack()
								}}>
								&#xe637; 返回
							</Button>
						)}
						{
							<Space>
								{/* {withAuth(<Button className="iconfont-buttons" type="primary" title='提交' onClick={() => {
                (data.records.length) ? reviewFile(data.records).then(() => { data.refresh() }) : this.showModel()
                }}>&#xe64c; 提交</Button>, recordFileUrl.submitToVerify)} */}
								{withAuth(
									<Button
										className='ant-btn-primary iconfont-buttons'
										title='指派'
										onClick={() => {
											data.records.length
												? assignFile(data.records).then(() => {
														data.refresh()
													})
												: this.showModel()
										}}>
										&#xe632; 指派
									</Button>,
									submitFileUrl.submitToVerify
								)}
								{withAuth(
									<Button
										className='iconfont-buttons'
										type='primary'
										title='归档'
										onClick={() => {
											data.records.length
												? pigeonhole(data.records).then(() => {
														data.refresh()
													})
												: this.showModel()
										}}>
										&#xe628; 归档
									</Button>,
									recordFileUrl.archiveFile
								)}
							</Space>
						}
						{(() => {
							if (
								hasAuth([userDeleteDossier, userDeleteArchive]) ||
								hasAuth([managerDeleteArchive, managerDeleteArchive])
							) {
								return (
									<Button
										className='btn-danger iconfont-buttons'
										title='删除'
										onClick={() => {
											data.records.length
												? todelete(data.records).then(() => {
														data.refresh()
													})
												: this.showModel()
										}}>
										&#xe816; 删除
									</Button>
								)
							}
						})()}
						{/* 回退状态下才有回退记录按钮 */}
						{/* <Button className="btn-warning" onClick={() => this.viewRollbackRecord(data.records)} >回退记录</Button> */}
						{haveCollection &&
							withAuth(
								<Button
									className='iconfont-buttons'
									type='primary'
									onClick={() => {
										data.records.length
											? joinWorkbenchModalRef.current.show()
											: Modal.error({
													content: tips.CHOOSE_AT_LEAST_ONE,
													maskClosable: true,
												})
									}}
									title='收藏'>
									<AppstoreAddOutlined style={{ fontSize: '18px' }} />{' '}
									收藏
								</Button>,
								workStation.addWorkbenchContent
							)}
					</Space>
				)
				break
			}
			case 4: {
				// 回收站
				ButtonList = (
					<Space>
						{data.backReturn && (
							<Button
								className='iconfont-buttons'
								title='返回'
								onClick={() => {
									history.goBack()
								}}>
								&#xe637; 返回
							</Button>
						)}
						{(() => {
							if (
								hasAuth([managerResortDossier, managerRestoreArchive]) ||
								hasAuth([userRestoreArchive, userResortDossier])
							) {
								return (
									<Button
										className='ant-btn-primary iconfont-buttons'
										title='还原'
										onClick={() => {
											data.records.length
												? toRestore(data.records).then(() => {
														data.refresh()
													})
												: this.showModel()
										}}>
										&#xe690; 还原
									</Button>
								)
							}
						})()}
						{(() => {
							if (
								hasAuth([userDestroyArchive, userDestroyDossier]) ||
								hasAuth([managerDestroyArchive, managerDestroyDossier])
							) {
								return (
									<Button
										className='btn-danger iconfont-buttons'
										title='删除'
										onClick={() => {
											data.records.length
												? toDestroy(data.records).then(() => {
														if (
															this.props.route ===
															'/workarea/commonComponents/details'
														) {
															const {
																removeTab,
																route,
																routes,
															} = this.props
															removeTab(
																route,
																history,
																route,
																routes
															) // 删除
														}
														data.refresh()
													})
												: this.showModel()
										}}>
										&#xe816; 删除
									</Button>
								)
							}
						})()}
						{(() => {
							if (hasAuth(recycleBinUrl.emptyRecycleBin)) {
								if (
									this.props.route ===
									'/workarea/commonComponents/details'
								) {
									return null
								} else {
									return (
										<Button
											className='btn-danger btn-danger-danger iconfont-buttons'
											title='清空'
											onClick={() => {
												Modal.confirm({
													maskClosable: true,
													title: `清空回收站`,
													content: `您正在对回收站进行清空操作，操作不可逆，是否继续？`,
													zIndex: 1005,
													onOk: () => {
														emptyRecycleBin().then(res => {
															message.success('清空成功')
															data.refresh()
														})
													},
												})
											}}>
											&#xe680; 清空
										</Button>
									)
								}
							}
						})()}
					</Space>
				)
				break
			}
			case 5: {
				//  销毁（无）
				ButtonList = (
					<Space>
						{data.backReturn && (
							<Button
								className='iconfont-buttons'
								title='返回'
								onClick={() => {
									history.goBack()
								}}>
								&#xe637; 返回
							</Button>
						)}
					</Space>
				)
				break
			}
			case 6: {
				// 过期
				ButtonList = (
					<Space>
						{data.backReturn && (
							<Button
								className='iconfont-buttons'
								title='返回'
								onClick={() => {
									history.goBack()
								}}>
								&#xe637; 返回
							</Button>
						)}
						{withAuth(
							<Button
								// title='只可选择同种类型文件'
								className='btn-warning iconfont-buttons'
								title='鉴定'
								// disabled={(data.records?.length >= 1 && isDisabledType(data.records, data.records[0].type)) && true}    // 判断预归档
								onClick={() => {
									data.records.length
										? this.setDossierDetail(data)
										: this.showModel()
								}}>
								&#xe74b; 鉴定
							</Button>,
							appraisalUrl.verifyApply
						)}
					</Space>
				)
				break
			}
			case 7: {
				//  收藏
				ButtonList = (
					<Space>
						{data.backReturn && (
							<Button
								className='iconfont-buttons'
								title='返回'
								onClick={() => {
									history.goBack()
								}}>
								&#xe637; 返回
							</Button>
						)}
						{withAuth(
							<Button
								className='btn-warning iconfont-buttons'
								title='鉴定（只可选择同种类型的过期文件）'
								disabled={
									data.records?.length >= 1 &&
									isDisabled(data.records, -1) &&
									true
								} // 判断已归档
								onClick={() => {
									data.records.length
										? this.setDossierDetail(data)
										: this.showModel()
								}}>
								&#xe74b; 鉴定
							</Button>,
							appraisalUrl.verifyApply
						)}
						{withAuth(
							<Button
								className='ant-btn-primary iconfont-buttons'
								title='修正（只可选择单个已归档文件）'
								disabled={
									(!data.records ||
										data.records.length > 1 ||
										(data.records.length === 1 &&
											!(data.records[0]?.status === 2))) &&
									true
								} // 判断已归档且只有一个
								onClick={() => {
									data.records.length
										? this.handleFixApply(data.records)
										: this.showModel()
								}}>
								<span>&#xe6d0; </span>
								<span>&nbsp;修正</span>
							</Button>,
							submitFixApply
						)}
						{withAuth(
							<Button
								className='ant-btn-primary iconfont-buttons'
								title='归档（只可选择著录文件）'
								disabled={
									data.records?.length >= 1 &&
									isDisabled(data.records, 0) &&
									true
								} // 判断著录
								onClick={() => {
									data.records.length
										? pigeonhole(data.records).then(() => {
												data.refresh()
											})
										: this.showModel()
								}}>
								&#xe628; 归档
							</Button>,
							recordFileUrl.archiveFile
						)}
						{withAuth(
							<Button
								className='iconfont-buttons'
								type='primary'
								title='入库（只可选择预归档文件）'
								disabled={
									data.records?.length >= 1 &&
									isDisabled(data.records, 1) &&
									true
								} // 判断预归档
								onClick={() => {
									data.records.length
										? storage(
												data.records,
												this.props.changSpinning
											).then(() => {
												data.refresh()
											})
										: this.showModel()
								}}>
								&#xe681; 入库
							</Button>,
							[storageDossier, storageArchive]
						)}
						{withAuth(
							<Button
								className='btn-danger-danger iconfont-buttons'
								title='利用'
								onClick={() => {
									data.records.length
										? this.showBorrowApprovalModal(data.records)
										: this.showModel()
								}}>
								<span>&#xe636;</span>&nbsp;利用
							</Button>,
							distributeFile
						)}
						{withAuth(
							<Button
								className='btn-danger iconfont-buttons'
								title='取消收藏'
								onClick={() => {
									data.records.length
										? deleteWorkbenchContent(
												data.records,
												Number(workbenchId)
											).then(() => {
												data.refresh()
											})
										: this.showModel()
								}}>
								<span>&#xe816;</span>&nbsp;移除
							</Button>,
							workStation.deleteWorkbenchContent
						)}
					</Space>
				)
				break
			}
			case 8: {
				// 仅查看，案卷下文件，新建案卷
				ButtonList = (
					<Space>
						{data.backReturn && (
							<Button
								className='iconfont-buttons'
								title='返回'
								onClick={() => {
									history.goBack()
								}}>
								&#xe637; 返回
							</Button>
						)}
					</Space>
				)
				break
			}
			case 9: {
				// 我的档案
				ButtonList = (
					<Space>
						{haveCollection &&
							withAuth(
								<Button
									className='iconfont-buttons'
									type='primary'
									onClick={() => {
										data.records.length
											? joinWorkbenchModalRef.current.show()
											: this.showModel()
									}}
									title='收藏'>
									<AppstoreAddOutlined style={{ fontSize: '18px' }} />{' '}
									收藏
								</Button>,
								workStation.addWorkbenchContent
							)}
					</Space>
				)
				break
			}
			case 10: {
				// 资料
				ButtonList = (
					<Space>
						{haveCollection &&
							withAuth(
								<Button
									className='iconfont-buttons'
									type='primary'
									onClick={() => {
										data.records.length
											? joinWorkbenchModalRef.current.show()
											: this.showModel()
									}}
									title='收藏'>
									<AppstoreAddOutlined style={{ fontSize: '18px' }} />{' '}
									收藏
								</Button>,
								workStation.addWorkbenchContent
							)}
					</Space>
				)
				break
			}
			case 11: {
				//我的档案
				ButtonList = (
					<Space>
						{haveCollection &&
							withAuth(
								<Button
									className='iconfont-buttons'
									type='primary'
									onClick={() => {
										data.records.length
											? joinWorkbenchModalRef.current.show()
											: this.showModel()
									}}
									title='收藏'>
									<AppstoreAddOutlined style={{ fontSize: '18px' }} />{' '}
									收藏
								</Button>,
								workStation.addWorkbenchContent
							)}
					</Space>
				)
				break
			}
			case 12: {
				// 审核
				waitForVerifyUser =
					undefined !== data.records &&
					0 !== data.records.length &&
					data.records[0].waitForVerifyUser !== undefined
						? data.records[0].waitForVerifyUser
						: userId
				const { managerRollback } = this.props
				ButtonList = !this.props.isEdit ? (
					<Space>
						{data.backReturn && (
							<Button
								className='iconfont-buttons'
								title='返回'
								type='primary'
								onClick={() => {
									history.goBack()
								}}>
								&#xe637; 返回
							</Button>
						)}
						{withAuth(
							waitForVerifyUser === userId ? (
								<Button
									className='ant-btn-primary iconfont-buttons'
									title='指派'
									onClick={() => {
										data.records.length
											? assignFile(data.records).then(() => {
													data.refresh()
												})
											: this.showModel()
									}}>
									&#xe632; 指派
								</Button>
							) : null,
							submitFileUrl.submitToVerify
						)}
						{withAuth(
							waitForVerifyUser === userId ? (
								<Button
									className='ant-btn-primary iconfont-buttons'
									title='归档'
									onClick={() => {
										data.records.length
											? pigeonhole(data.records).then(() => {
													data.refresh()
												})
											: this.showModel()
									}}>
									&#xe628; 归档
								</Button>
							) : null,
							recordFileUrl.archiveFile
						)}
						{haveCollection &&
							withAuth(
								<Button
									className='ant-btn-primary iconfont-buttons'
									title='一键归档'
									onClick={() => {
										//  0-著录中，1-预归档，2-已归档，12-审核中
										oneKeyChangeStatus({
											oldStatus: 12,
											newStatus: 1,
										}).then(() => {
											data.refresh()
										})
									}}>
									&#xe628; 一键归档
								</Button>,
								dossierUrl.oneClickChange
							)}
						{withAuth(
							waitForVerifyUser === userId ? (
								<Button
									className='btn-warning iconfont-buttons'
									title='回退'
									onClick={() => {
										data.records.length
											? rollbackModalRef.current.show()
											: this.showModel()
									}}>
									&#xe6bb; 回退
								</Button>
							) : null,
							recordFileUrl.archiveFile
						)}
						{withAuth(
							waitForVerifyUser !== userId ? (
								<Button
									className='btn-warning iconfont-buttons'
									title='撤回'
									onClick={() => {
										data.records.length
											? managerRollback(data.records, '撤回')
													.then(() => {
														data.refresh()
													})
													.catch()
											: this.showModel()
									}}>
									&#xe637; 撤回
								</Button>
							) : null,
							recordFileUrl.archiveFile
						)}
						{/* {(this.props.route.split('/').indexOf('details') === -1) &&
              (() => {
                if (hasAuth([userDeleteDossier, userDeleteArchive]) || hasAuth([managerDeleteArchive, managerDeleteArchive])) {
                  return (
                    <Button title='删除' className="btn-danger iconfont-buttons" onClick={() => {
                      (data.records.length) ? todelete(data.records).then(() => { data.refresh() }) : this.showModel()
                    }}>&#xe816; 删除</Button>
                  )
                }
              })()} */}
						{haveCollection &&
							withAuth(
								<Button
									className='iconfont-buttons'
									type='primary'
									onClick={() => {
										data.records.length
											? joinWorkbenchModalRef.current.show()
											: Modal.error({
													content: tips.CHOOSE_AT_LEAST_ONE,
													maskClosable: true,
												})
									}}
									title='收藏'>
									<AppstoreAddOutlined style={{ fontSize: '18px' }} />{' '}
									收藏
								</Button>,
								workStation.addWorkbenchContent
							)}
					</Space>
				) : (
					<></>
				)
				break
			}
			default: {
				// 其他
				// //console.log('无')
			}
		}

		return ButtonList
	}

	// 弹出模态框（至少选择一项）
	showModel = () => {
		Modal.error({
			content: tips.CHOOSE_AT_LEAST_ONE,
			maskClosable: true,
		})
	}

	// 回退操作
	handleRollback = (formData, { hide, finish }) => {
		let data = this.setData()
		const { message } = formData
		const { managerRollback } = this.props
		managerRollback(data.records, message)
			.then(() => {
				data.refresh()
				hide()
			})
			.catch(() => finish())
	}

	// 批量或单个案卷或档案（文件）加入收藏
	handleAddWorkbenchContent = (formData, { hide, finish }, callback) => {
		let data = this.setData()
		const { workbenchId } = formData
		const { addWorkbenchContent } = this.props
		let ids = []
		// 单个案卷或档案（文件）加入收藏夹
		if (data.records.length === 1) {
			const { id, type } = data.records[0]
			ids = [{ contentId: id, type }]
		} else if (data.records.length >= 1) {
			// 多选加入,将选中项的 id 和类型拿出来作为请求参数
			ids = data.records.map(({ id, type }) => ({ contentId: id, type }))
		}
		// 加入收藏
		addWorkbenchContent(workbenchId, ids)
			.then(() => {
				this.setData().refresh() // 刷新
				hide()
				callback && callback()
			})
			.catch(() => finish())
	}

	// 鉴定申请
	setDossierDetail = async data => {
		const { addAppraisalDataToRedux } = this.props // 把数据存放到redux
		try {
			// 在档案详情模态框中点击鉴定或表格外多选点击鉴定
			let records = data.records
			if (typeUtil.isObject(records)) records = [records]
			// 不支持同时鉴定文件与案卷
			// if (records.some(record => record['type'] !== records[0].type)) {
			//   return Modal.error({
			//     content: tips.ONLY_ARCHIVED_ONETYPE,
			//     maskClosable: true
			//   });
			// }
			const tempData = {}
			const verifydata = {
				verifyDetails: records,
				attachmentList: [],
			}

			// 鉴定的附录文件列表
			records.forEach((value, index) => {
				value.preRetentionPeriod = value.retentionPeriod
				tempData[`topic_${value.id}`] = value.topic // 题名
				tempData[`code_${value.id}`] = value.code // 档案号
				tempData[`categoryCode_${value.id}`] = value.categoryCode // 类别号
				tempData[`preRetentionPeriod_${value.id}`] =
					dossierEnum.retentionPeriod[value.retentionPeriod] // 前有效期
			})
			// 申请人
			const applyPerson = {
				applyPerson: JSON.parse(localStorage.getItem('userMessage')).user.name,
			}
			// 把数据放到上
			addAppraisalDataToRedux([
				verifydata,
				{
					...applyPerson,
					...tempData,
				},
				{
					...applyPerson,
				},
				{},
			])
			// 判断明细表类型
			sessionStorage.setItem('fromExpired', 'true') //从过期档案或者档案鉴定-新建鉴定进入鉴定详情页面
			const { removeTab, route, routes } = this.props
			removeTab(route, history, route, routes) // 删除
			this.props.onChange(appraisalLink, history) //更改路由
			this.props.pushRouters(appraisalLink) //添加分页
			message.info('请先编辑申请原因')
		} catch (err) {}
	}

	// 单个/批量申请档案鉴定
	handleVerifyApply = (formData, { hide, finish }) => {
		const { verifyApply } = this.props // 鉴定申请接口
		const { verifyIds, verifyType } = this.state
		let fetchData = {
			applyReason: formData['applyReason'], // 申请理由
			verifyIds,
			type: verifyType,
		}
		verifyApply(fetchData)
			.then(() => {
				this.setData().refresh() // 刷新
				hide()
				// 如果是打开详情模态框后操作按钮，成功时顺便关闭
			})
			.catch(() => finish())
	}

	handleFixApply = record => {
		const link = {
			title: '修正文件',
			key: '/workarea/commonComponents/fileFixApply',
		}

		this.props.getDetail(record[0]).then(data => {
			// 请求详情数据
			if (data.archiveDetail) {
				data.archiveDetail.forEach(archiveData => {
					const { fileToken, fileType } = archiveData
					// 设置预览图
					archiveData['preview'] = {
						type: 1,
						fileData: {
							fileToken,
							fileType,
						},
					}
					// 每个来个key
					archiveData['key'] = archiveData.id
				})
				this.props.inititalPubArchive('FixDossierDetails', {
					filesData: data.archiveDetail || [],
					archiveData: data,
				})
			}

			this.props.addOldDetailsrData(data, 1)
			this.props.onChange(link, history) // 修改路由
			this.props.pushRouters(link) //添加分页栏
		})
	}

	// 修正申请的案卷设置表单值
	setTableData = showTableList => {
		let data = {}
		data['data'] = showTableList
		data.data.forEach((item, n) => {
			// 让每一个表格数据都获得key
			const { fileToken, fileType } = item
			item['fileData'] = { fileToken, fileType }
			item['startIndex'] = n
			item.key = item.id || item.archiveId
		})

		data.pageData = {
			// 配置表格的页码器
			pageSize: data.size || 10,
			current: data.page || 1,
			total: data.total,
		}
		this.props.getAllTableData(data, 'FixDossierDetails') // 把表格数据发送到store
	}

	// 获最新回退案卷/档案信息并设置到表单
	setRollbackRecord = record => {
		const {
			getDossierRollback, // 获取案卷详情
			getArchiveRollback, // 获取回退档案信息🔎
		} = this.props
		let getDossierBackOrgetArchiveBack =
			record[0].type === 0 ? getDossierRollback : getArchiveRollback
		getDossierBackOrgetArchiveBack(record[0].id).then(data => {
			const message = { message: data }
			rollbackRecordModalRef.current.formRef.setFieldsValue(message)
		})
	}

	// 查看档案/案卷最新的回退记录
	viewRollbackRecord = record => {
		rollbackRecordModalRef.current.show(formRef => {
			this.setRollbackRecord(record)
		})
	}

	// 查看档案/案卷最新的回退记录
	showBorrowApprovalModal = records => {
		this.setState({
			visible: true,
			records,
		})
	}

	//渲染收藏按钮
	renderFavorites() {
		return withAuth(
			<Button
				className='ant-btn-primary'
				type='primary'
				onClick={() => {
					joinWorkbenchModalRef.current.show()
				}}
				title='收藏'>
				<AppstoreAddOutlined style={{ fontSize: '18px' }} /> 收藏
			</Button>,
			workStation.addWorkbenchContent
		)
	}

	// 收藏夹模块框的确定按钮
	DetailAddToWorkbench = (formData, { hide, finish }, callback) => {
		const { addWorkbenchContent, addToWorkbenchData } = this.props
		const { workbenchId } = formData
		let ids = addToWorkbenchData
		addWorkbenchContent(workbenchId, ids)
			.then(res => {
				hide()
				callback && callback()
			})
			.catch(() => finish())
	}

	render() {
		const { renderFavorites, addToWorkbenchData } = this.props
		return (
			<div className='button-list'>
				{!renderFavorites && this.setButtonList()}
				{/* 收藏按钮单独渲染 */}
				{renderFavorites && this.renderFavorites()}
				{/* 回退档案的模态框 */}
				<RollbackModal modalRef={rollbackModalRef} onOk={this.handleRollback} />
				{/* 加入收藏夹的模态框 */}
				<JoinWorkbenchModal
					modalRef={joinWorkbenchModalRef}
					onOk={
						addToWorkbenchData
							? this.DetailAddToWorkbench
							: this.handleAddWorkbenchContent
					}
				/>
				{/* 档案鉴定申请模态框 */}
				<VerifyApplyModal
					modalRef={verifyApplyPubModalFormRef}
					ref={verifyApplyModalRef}
					onOk={this.handleVerifyApply}
				/>
				{/* 查看回退记录的模态框*/}
				<RollbackRecordModal
					modalRef={rollbackRecordModalRef}
					hideCallback={this.hideCallback}
				/>
				{/* 利用模态框*/}
				<BorrowApprovalModal
					visible={this.state.visible}
					records={this.state.records}
					onCancel={() => {
						this.setState({ visible: false, records: null })
					}}
				/>
			</div>
		)
	}
}

export default ButtonList
