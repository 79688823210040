const initialState = {
	route: {
		item: '', // 当前选中的tab栏
		items: [], // 当前所有的tabs栏
		module: '',
	},
	login: {
		status: '',
		data: '',
		permission: '',
		userType: '',
	},
	pubTable: {
		pub_rowSelection: {}, // 选中的当行数据，对象才能存带属性的数据
		pub_allTableData: {}, // 获取表格数据
		pub_isLoading: {}, // 是否获取到数据的标识
		allSearchCondition: {}, // 所有搜索条件 { 路由1: {  options: { url, type, data, headers:{} }, originVal: { 原始的搜索条件 }  }, 路由2： { ... } }
		fixedConf: {
			// 固定的搜索条件
			// currentPage: 1,
			// size: 10,
		},
		selectedStatus: null,
		isMyFileDetail: false,
	},
	pubForm: {
		pub_fileDetails: {}, // 文件详情
		pub_dossierDetails: {}, // 案卷详情
		pub_fixApplyDetails: {
			current: 0,
			old_details: {}, // 修正前
			new_details: {}, // 修正后
			fix_apply: {}, //  申请理由
		},
		pub_AsyncTreeSelectValue: {}, // 异步树型选择框数据
	},
	// 新建文件模块，旧版用来，让按钮样式添加失败后一致待载。现在没什么用其实
	fileCreator: {
		created: Symbol('init'),
	},
	/*
	 * 放pubArchive的数据，例如
	 *  {
	 *    'newArchive': {
	 *        old: {filesData: [...], archiveData: {}},
	 *        new: {filesData: [...], archiveData: {}}
	 *     },
	 *
	 *  }
	 */
	pubArchive: {},
	// 文件组卷用的
	packArchive: {
		current: 0,
		filesData: [],
		archiveData: {}, // 选择的案卷的信息，用以请求接口，拿到案卷的详情(里面包含的文件/信息)
		hasFilesAll: false,
		filesWithin: [],
	},
	//鉴定
	appraisalDetails: {
		appraisalData: [],
	},
	// 修正申请表相关内容
	fixDetails: {
		category: 0, // 修正申请表或者审批；0为申请，1为审批
		fixData: {}, // 修正表详情
	},
	// 借阅审批表相关内容
	borrowApprovalData: {
		approvalData: {}, // 修正表详情
	},
	headerBarData: {
		//当前登录用户的信息
		userData: [],
	},
	addFaceData: {
		//当前登录用户的信息
		faceData: [],
		current: false,
	},
	redDots: {},
	slideBar: {
		openKeys: [],
		selectedKey: [],
	},
	myFiles: {
		selectedFiles: {},
	},
}

export default initialState
