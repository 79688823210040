import React, { Component, Fragment } from 'react'
import {
	Button,
	Form,
	Input,
	Image,
	Modal,
	Upload,
	message,
	Progress,
	Tooltip,
	Divider,
	Card,
} from 'antd'
import { connect } from 'react-redux'
import {
	mergeFaceThunk,
	compareToExistedUserThunk,
	faceReplaceThunk,
	addExtraFaceThunk,
	uploadThunk,
	faceFeatureDeleteThunk,
} from './ActionCreators'
import ImageGallery from '@/Public/ImageGallery'
import returnSvg from '@/Static/svg/return.svg'
import { DeleteFilled, CaretUpOutlined } from '@ant-design/icons'
import _ from 'lodash'

const mapStateToProps = state => {
	return {
		face_data: state.addFaceData, // 获取人脸
	}
}
const mapDispatchToProps = dispatch => ({
	uploadFile: (...rest) => dispatch(uploadThunk(...rest)), // 上传
	compareToExistedUser: (...rest) => dispatch(compareToExistedUserThunk(...rest)), // 校验人脸信息
	mergeFace: (...rest) => dispatch(mergeFaceThunk(...rest)), // 合并人脸信息
	faceReplace: (...rest) => dispatch(faceReplaceThunk(...rest)), // 替换人脸
	addExtraFace: (...rest) => dispatch(addExtraFaceThunk(...rest)), // 添加人脸
	faceFeatureDelete: (...rest) => dispatch(faceFeatureDeleteThunk(...rest)), // 添加人脸
})
@connect(mapStateToProps, mapDispatchToProps)
class AddFace extends Component {
	formRef = React.createRef()
	state = {
		images: [],
		id: null, // 人脸id
		faceFeatureId: null, // 当前人脸特征id
		// 同名人脸信息
		sameName: {
			sameFaceId: null, // 同名人脸id
			sameFaceFeatureId: null, // 同名人脸特征id
			sameFaceUrl: null, // 同名人脸url
			sameFaceFileId: null, // 同名人脸文件id\
		},
		visible: false, // 是否显示模态框
		addLoading: false, // 上传按钮loading
		replaceLoading: false, // 替换按钮loading
		mergeCheck: {
			similar: 0,
			warn: false,
		},
	}

	// 判断数据是否重新渲染
	componentDidUpdate(preProps) {
		const faceData = this.props.faceData
		// 通过判断数组形式判断从哪进来
		if (faceData instanceof Array) {
			if (this.state.images.length !== faceData.length) {
				const images = this.setImages(faceData)
				this.setState({ images })
			}
		} else {
			if (!_.isEqual(preProps.faceData.faceImageList, faceData.faceImageList)) {
				// 如果数据不一样重新渲染轮播图
				const images = this.setImages(faceData)
				this.setState({ images })
			} else if (this.state.images.length !== faceData.faceImageList.length) {
				// 如果长度不一样渲染（首次渲染）
				const images = this.setImages(faceData)
				this.setState({ images })
			}
		}
	}

	// 渲染数据
	componentDidMount() {
		const faceData = this.props.faceData
		this.props.getRef(this.formRef)
		if (faceData instanceof Array) {
			if (faceData && this.state.images.length === 0) {
				// 录入的首次渲染
				const images = this.setImages(faceData)
				this.setState({ images })
			}
			this.formRef.current.setFieldsValue(faceData[this.props.startIndex])
		} else {
			// 设置当前人脸id和特征id
			this.setState({
				faceFeatureId: faceData.faceImageList[0].faceFeatureId,
				id: faceData.id,
			})
			this.formRef.current.setFieldsValue(faceData)
		}
	}

	// 设置图片内容
	setImages = data => {
		const images = []
		if (data instanceof Array) {
			data?.forEach(image => {
				// 设置轮播图组
				let imgData = {
					original: image.url,
					thumbnail: image.url,
					fileData: { fileToken: image.url, fileType: 'image/jpeg' }, // 放在这里利于传参
					// renderItem: this.renderFilePreviewer.bind(this),   // 设置显示图标、视频、图片
				}
				images.push(imgData)
			})
		} else {
			// 设置轮播图
			data.faceImageList?.forEach(image => {
				let imgData = {
					original: image.faceImageUrl,
					thumbnail: image.faceImageUrl,
					fileData: { fileToken: image.faceImageUrl, fileType: 'image/jpeg' }, // 放在这里利于传参
					// renderItem: this.renderFilePreviewer.bind(this),   // 设置显示图标、视频、图片
				}
				images.push(imgData)
			})
		}
		return images
	}

	// 返回图标或者视频
	// renderFilePreviewer = (data) => {
	//   return (<FilePreviewer fileData={data.fileData} />);
	// }

	/**
	 * 方法说明
	 * @method 上传文件时操作
	 * @param {Object} info 文件详情
	 * @param {String} state 按钮loading状态字段名
	 */
	handleChange = (info, state) => {
		// 设置按钮加载状态true
		this.setState({ [state]: true }, async () => {
			const {
				uploadFile, // 上传文件
				compareToExistedUser, // 人脸判断
				faceReplace, // 人脸替换
				addExtraFace, // 添加人脸
			} = this.props
			try {
				// 先上传文件并检测是否同一个人
				const fileInfo = await uploadFile({ multipartFile: info.file })
				// 图片格式错误直接终止
				if (!fileInfo) {
					this.setState({ [state]: false })
					return
				}
				const faceInfo = await compareToExistedUser({
					imageId: fileInfo.data,
					uid: this.state.id,
				})
				// 只有检测成功时，才能进入模态框
				if (faceInfo.code === 200) {
					// 设置按钮加载状态true
					this.setState({ [state]: false })
					if (state === 'addLoading') {
						// 添加人脸操作
						this.showModal(
							faceInfo.data,
							() => {
								addExtraFace({
									uid: this.state.id,
									extraFaceImageId: fileInfo.data,
								}).then(() => {
									message.success('添加成功')
									this.props?.toRefresh()
								})
							},
							'添加人脸'
						)
					} else if (state === 'replaceLoading') {
						// 替换人脸操作
						this.showModal(
							faceInfo.data,
							() => {
								faceReplace({
									uid: this.state.id,
									faceFeatureId: this.state.faceFeatureId,
									imageFileId: fileInfo.data.fileId,
								}).then(() => {
									message.success('替换成功')
									this.props?.toRefresh()
								})
							},
							'替换人脸'
						)
					}
				}
			} catch (err) {
				this.setState({ [state]: false }, () => {
					message.destroy()
					message.error('上传失败，请确保上传图片只含有一张人脸')
				})
			}
		})
	}

	/**
	 * 方法说明
	 * @method 模态框函数
	 * @param {Object} data 人脸判断后返回的数据
	 * @param {Function} callback 回调函数
	 */
	showModal = (data, callback, string = '') => {
		data.similar = parseInt(data.similar * 100)
		Modal.confirm({
			maskClosable: true,
			title: `${string}操作提示`,
			content: (
				<>
					您当前进行操作的人脸与现人脸相似度为
					{data.warn ? (
						<>
							<div
								style={{
									color: 'red',
									fontSize: '16px',
									display: 'initial',
								}}>
								{data.similar}%
							</div>
							，
							<div
								style={{
									color: 'red',
									fontSize: '16px',
									display: 'initial',
								}}>
								强烈建议不要进行该操作
							</div>
						</>
					) : (
						<>{data.similar}%</>
					)}
					，是否继续当前操作？
					<div
						style={{
							marginLeft: '78%',
							transform: 'translateY(15px)',
							width: '30px',
							color: 'rgb(254, 167, 86)',
							height: '25px',
						}}>
						<span>82%</span>
						<CaretUpOutlined />
					</div>
					<Progress
						percent={100}
						status='active'
						showInfo={false}
						className='face-progress'
						title='推荐相似度为82%以上'
						strokeWidth='10px'
					/>
					<Tooltip
						placement={data.similar > 50 ? 'bottomRight' : 'bottomLeft'}
						title={`相似度：${data.similar}%`}
						visible={true}
						arrowPointAtCenter={true}
						color={data.similar > 82 ? 'green' : 'red'}
						overlayStyle={{ paddingRight: '5px' }}>
						<CaretUpOutlined
							style={{
								marginLeft: `${data.similar}%`,
								transform: 'translateY(-10px)',
								marginBottom: '25px',
								height: '1px',
								fontSize: '1px',
								opacity: '0',
							}}
						/>
					</Tooltip>
				</>
			),
			okText: `${string || '确定'}`,
			cancelText: `取消`,
			onOk: () => callback(),
		})
	}

	render() {
		const {
			onBack,
			currentIndex = 0,
			onSlide,
			onSubmit,
			buttons = null,
			uniqueName,
			getRef,
			sameNameFaceList,
			faceData,
			faceFeatureDelete,
			compareToExistedUser,
			mergeFace,
		} = this.props
		const {
			images,
			visible,
			sameName,
			addLoading,
			replaceLoading,
			id,
			faceFeatureId,
			mergeCheck,
		} = this.state
		if (!uniqueName) {
			throw Error('FaceDate中，uniqueName是必传参数')
		}
		if (!getRef) {
			throw Error('FaceDate中，getRef是必传参数')
		}

		return (
			<Fragment>
				<div>
					<img
						style={{ width: 35, height: 35, cursor: 'pointer' }}
						src={returnSvg}
						alt={'返回'}
						onClick={onBack}
					/>
					<div
						className={`file-details ${uniqueName}`}
						style={{ marginTop: '30px' }}>
						<ImageGallery
							uniqueName={`${uniqueName}-image-gallery`}
							width={'70%'}
							height={'75vh'}
							items={images}
							startIndex={currentIndex}
							onSlide={
								onSlide
									? onSlide
									: currentIndex => {
											this.setState({
												faceFeatureId:
													faceData.faceImageList[currentIndex]
														.faceFeatureId,
											})
										}
							}
							style={{ position: 'relative' }}
						/>
						<div
							className={`file-editor ${uniqueName}`}
							style={{ width: '30%' }}>
							<div className='information-form'>
								<h3>
									人脸信息
									{/* 操作按钮 */}
									{sameNameFaceList && (
										<>
											<Button
												type='text'
												style={{
													width: '50px',
													padding: '5px 0 0 2px',
													backgroundColor: '#ff4d4f',
												}}
												title={
													faceData.faceImageList.length === 1 &&
													!faceData?.deleteApprove
														? '该张人脸不可删除'
														: '删除该张人脸'
												}
												disabled={
													faceData.faceImageList.length === 1 &&
													!faceData?.deleteApprove
												}
												onClick={() => {
													Modal.confirm({
														maskClosable: true,
														title: '操作提示',
														content: '是否删除该张人脸',
														okText: '确定',
														cancelText: '取消',
														onOk: () => {
															// 删除函数
															faceFeatureDelete(
																faceFeatureId
															).then(() => {
																message.success(
																	'删除成功'
																)
																// 若人脸只有一张则退出
																if (
																	faceData.faceImageList
																		.length > 1
																) {
																	this.props?.toRefresh()
																} else {
																	onBack()
																}
															})
														},
													})
												}}>
												<DeleteFilled className='journal-icons' />
											</Button>
											<Button
												type='text'
												style={{
													width: '50px',
													padding: '5px 0 0 2px',
													backgroundColor: '#FEA756',
												}}
												title='更换人脸'
												loading={replaceLoading}>
												{!replaceLoading && (
													<Upload
														beforeUpload={() => false}
														onChange={info => {
															this.handleChange(
																info,
																'replaceLoading'
															)
														}}
														showUploadList={false}
														maxCount={1}>
														<svg
															t='1630764860683'
															className='journal-icons'
															viewBox='0 0 1024 1024'
															version='1.1'
															xmlns='http://www.w3.org/2000/svg'
															p-id='5571'
															width='18'
															height='18'
															fill='#ffffff'>
															<path
																d='M484.193 1013.08c-7.955 7.882-24.084 7.882-31.966 7.882H77.75c-31.894 0-47.73-15.765-55.686-39.85V630.795c0-31.967 15.837-47.804 39.849-55.687h382.432c15.837 0 24.011 0 39.848 7.883 15.838 7.882 15.838 24.011 24.012 39.848v342.584c-0.292 16.056-8.174 31.893-24.085 47.73z m47.731-573.43c-15.837-7.954-24.011-24.084-24.011-47.803V57.219c0-24.012 15.837-47.804 39.849-55.686h390.314c24.011 0 39.849 7.882 55.686 31.966 0 7.882 7.955 15.765 7.955 23.939v334.628a59.481 59.481 0 0 1-39.849 55.759h-382.14c-23.792-0.292-39.848-0.292-47.804-8.174z m294.78 111.373c-55.687 55.686-103.418 119.546-151.44 175.16h95.534c-7.955 31.966-15.837 63.933-31.966 95.608-24.012 39.848-63.86 71.742-111.665 79.624h-1.24v119.547a272.957 272.957 0 0 0 128.742-47.731c87.58-63.86 127.502-143.34 127.502-247.048h95.535c-55.468-63.569-103.199-119.255-151.003-175.16z'
																p-id='5572'></path>
															<path
																d='M565.935 960.75a60.284 60.284 0 1 0 120.568 0 60.284 60.284 0 0 0-120.568 0zM188.1 470.743c55.686-55.687 103.49-119.547 151.513-175.16h-95.608c7.955-31.967 15.91-63.86 31.966-95.608 24.012-39.849 63.86-71.743 111.665-79.698h1.24V0.584a272.957 272.957 0 0 0-128.742 47.73c-87.58 63.861-127.429 143.34-127.429 247.049H36.88c55.687 63.86 103.49 119.546 151.222 175.451z'
																p-id='5573'></path>
															<path
																d='M328.374 61.087a60.284 60.284 0 1 0 120.569 0 60.284 60.284 0 0 0-120.569 0z'
																p-id='5574'></path>
														</svg>
													</Upload>
												)}
											</Button>
											<Button
												type='text'
												style={{
													width: '50px',
													padding: '5px 0 0 2px',
													backgroundColor: '#5FD45F',
												}}
												title='新增人脸'
												loading={addLoading}>
												{!addLoading && (
													<Upload
														beforeUpload={() => false}
														onChange={info => {
															this.handleChange(
																info,
																'addLoading'
															)
														}}
														showUploadList={false}
														maxCount={1}>
														<svg
															t='1630765522618'
															className='journal-icons'
															viewBox='0 0 1024 1024'
															version='1.1'
															xmlns='http://www.w3.org/2000/svg'
															p-id='10891'
															width='22'
															height='22'
															fill='#ffffff'>
															<path
																d='M0.512 658.286h409.6v-102.4H0.512z m819.2-102.4v-204.8h-102.4v204.8h-204.8v102.4h204.8v204.8h102.4v-204.8h204.8v-102.4h-204.8z m-204.8-409.6H0.512v102.4h614.4v-102.4z m0 204.8H0.512v102.4h614.4v-102.4z m0 0'
																p-id='10892'></path>
														</svg>
													</Upload>
												)}
											</Button>
										</>
									)}
								</h3>
								<Form onFinish={onSubmit} ref={this.formRef}>
									<div className='infomation-content'>
										{/* 人名 */}
										<Form.Item
											label='人名'
											name='name'
											placeholder='无'
											rules={[
												{ required: true, message: '请输入人名' },
											]}
											getValueFromEvent={e => {
												return e.target.value.replace(' ', '')
											}}>
											<Input />
										</Form.Item>
										{/* 部门 */}
										<Form.Item
											label='部门'
											name='department'
											placeholder='无'>
											<Input />
										</Form.Item>
										{/* 职务 */}
										<Form.Item
											label='职务'
											name='position'
											placeholder='无'>
											<Input />
										</Form.Item>
										{/* 身份ID */}
										<Form.Item
											label='身份ID'
											name='identity'
											placeholder='无'
											rules={[
												{
													required: true,
													message: '请输入身份ID',
												},
											]}
											getValueFromEvent={e => {
												return e.target.value.replace(' ', '')
											}}>
											<Input />
										</Form.Item>
										{/* 其他信息 */}
										<Form.Item
											label='其他信息'
											name='information'
											placeholder='无'>
											<Input.TextArea />
										</Form.Item>
									</div>
									<div className='fill-information-btns'>
										<Form.Item>
											<Button type='primary' htmlType='submit'>
												确定
											</Button>
											{buttons ? buttons() : null}
										</Form.Item>
									</div>
								</Form>
								{/* 同名人脸项 */}
								{sameNameFaceList && (
									<div className='same-face-box'>
										<h3 className='same-face-box-title'>同名人脸</h3>
										<Card
											className='same-face-box-card'
											defaultActiveKey={[
												sameNameFaceList.length && '1',
											]}>
											{sameNameFaceList.length === 0 && '无'}
											{sameNameFaceList.length > 0 &&
												sameNameFaceList.map((item, index) => {
													return (
														<Image
															src={item.faceImageUrl}
															className='same-face-box-img'
															preview={false}
															onClick={() => {
																// 点击设置值
																this.setState(
																	{
																		sameName: {
																			sameFaceFeatureId:
																				item.faceFeatureId,
																			sameFaceUrl:
																				item.faceImageUrl,
																			sameFaceId:
																				item.id,
																			sameFaceFileId:
																				item.faceImageId,
																		},
																	},
																	() => {
																		// 判断人脸
																		compareToExistedUser(
																			{
																				imageId:
																					item.faceImageId,
																				uid: id,
																			}
																		)
																			.then(res => {
																				this.setState(
																					{
																						visible: true,
																						mergeCheck:
																							{
																								similar:
																									parseInt(
																										res
																											.data
																											.similar *
																											100
																									),
																								warn: res
																									.data
																									.warn,
																							},
																					}
																				)
																			})
																			.catch(
																				res => {
																					this.setState(
																						{
																							visible: true,
																							mergeCheck: false,
																						}
																					)
																				}
																			)
																	}
																)
															}}
															key={item.faceImageId}
														/>
													)
												})}
										</Card>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				{/* 合并人脸模态框 */}
				<Modal
					visible={visible}
					destroyOnClose={true}
					title='合并同名人脸'
					onCancel={() => {
						this.setState({ visible: false })
					}}
					okText='确认合并'
					onOk={() => {
						mergeFace({
							targetUid: id,
							sourceUid: sameName.sameFaceId,
						}).then(() => {
							message.success('合并成功')
							this.props?.toRefresh()
							this.setState({ visible: false })
						})
					}}
					width={350}>
					<div style={{ textAlign: 'center' }}>
						<Image src={sameName.sameFaceUrl} />
					</div>
					{mergeCheck && (
						<>
							{mergeCheck.warn && (
								<span
									style={{
										position: 'absolute',
										color: 'red',
										right: '30px',
										bottom: '170px',
									}}>
									(强烈建议不要继续操作)
								</span>
							)}
							<Divider orientation='left' style={{ marginBottom: '-10px' }}>
								<h3>人脸相似度:</h3>
							</Divider>
							<div
								style={{
									marginLeft: '78%',
									transform: 'translateY(15px)',
									width: '30px',
									color: 'rgb(254, 167, 86)',
									height: '25px',
								}}>
								<span>82%</span>
								<CaretUpOutlined />
							</div>
							{/* 进度条 */}
							<Progress
								percent={100}
								status='active'
								showInfo={false}
								className='face-progress'
								strokeWidth='10px'
								title='推荐相似度为82%以上'
							/>
							<Tooltip
								placement={
									mergeCheck.similar > 50 ? 'bottomRight' : 'bottomLeft'
								}
								title={`相似度：${mergeCheck.similar}%`}
								visible={true}
								arrowPointAtCenter={true}
								color={mergeCheck.warn ? 'red' : 'green'}
								overlayStyle={{ paddingRight: '5px' }}>
								<CaretUpOutlined
									style={{
										marginLeft: `${mergeCheck.similar}%`,
										transform: 'translateY(-10px)',
										marginBottom: '25px',
										height: '1px',
										fontSize: '1px',
										opacity: '0',
									}}
								/>
							</Tooltip>
						</>
					)}
				</Modal>
			</Fragment>
		)
	}
}

export default AddFace
