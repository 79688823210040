// 该文件主要用于生成表单的布局和fromItem的引入
import * as selectItem from './Select'
import * as inputItem from './Input'
import * as datePickerItem from './DatePicker'
import * as treeSelectItem from './TreeSelect'
import RecognitionButton from './RecognitionButton'
import ExportButton from './ExportButton'
import * as extraItem from './Extra'
import { Form, Button } from 'antd'
import React, { Fragment } from 'react'
import searchImg from '@/Static/search.png'
const Item = Form.Item
// 封装的每一个表单组件
const fromItems = {
	...selectItem,
	...inputItem,
	...datePickerItem,
	...treeSelectItem,
	...extraItem,
}
/**
 * 该函数用于根据formItems来生成指定的表单布局和表单项
 * @param {object} formItems 页面的表单配置对象
 * @param {*} formRef 表单的所有方法对象
 * @param {*} handleSumbit 搜索按钮处理提交的函数
 */

const proFormItem = (
	formItems,
	formRef,
	searchButtonTest,
	handleSumbit,
	handlePrint,
	handleRecognitionSearchData,
	withExport
) => {
	class InnerClass extends React.Component {
		setSearchConfig = () => {
			let Search = [],
				Extra = null
			formItems.formItems.forEach((itemConfig, index) => {
				let {
					type,
					name,
					label,
					initialValue,
					className,
					width,
					labelCol,
					minWidth = '140px',
					maxWidth = '250px',
					rules,
				} = itemConfig
				let FormItem = null
				// 判断是否有该生成组件的方法，如果没有则响应错误而不继续生成
				FormItem = React.cloneElement(
					fromItems['pro' + type](itemConfig, formRef)
				)
				if (type === 'Extra') {
					Extra = React.cloneElement(
						fromItems['pro' + type](itemConfig, formRef)
					)
				} else {
					Search.push(
						<Fragment key={name}>
							<Item
								label={label}
								name={name}
								labelCol={labelCol}
								key={name}
								initialValue={initialValue}
								className={className}
								style={{ width, minWidth, maxWidth }}
								rules={rules}>
								{FormItem}
							</Item>
						</Fragment>
					)
				}
			})
			return [Search, Extra]
		}

		render() {
			const [Search, Extra] = this.setSearchConfig()
			let { withoutSearchButton } = formItems
			return (
				<Fragment>
					<div
						className='search-condition-list clearfix'
						style={{ display: 'flex', flexWrap: 'wrap' }}>
						{Search}
						{!withoutSearchButton && proButton(handleSumbit)}
						{handleRecognitionSearchData &&
							printRecognitionButton(handleRecognitionSearchData)}
						{Extra}
						{withExport && printExportButton()}

						{handlePrint && printButton(handlePrint)}
					</div>
				</Fragment>
			)
		}
	}
	return <InnerClass />
}
/**
 * @description: 该函数用于渲染查询按钮
 * @param {function} handleSumbit 搜索按钮处理提交的函数
 * @return: 返回一个FormItem组件
 */
const proButton = handleSumbit => {
	return (
		<Item>
			<Button type='primary' className='searchImgWrapper' htmlType='submit'>
				<img src={searchImg} className='searchImg' alt='搜索图标' title='查询' />
			</Button>
		</Item>
	)
}
/**
 *
 * @description: 该函数用于渲染导出列表按钮
 * @param {*} handlePrint  导出列表的函数
 * @return {*}
 */
const printButton = handlePrint => {
	return (
		<Item>
			<Button
				type='primary'
				className='print-directory iconfont-buttons'
				title='导出列表'
				onClick={handlePrint}
				style={{ marginLeft: '5px' }}>
				&#xe6fe; 导出列表
			</Button>
		</Item>
	)
}

const printRecognitionButton = handleRecognitionSearchData => {
	return <RecognitionButton handleRecognitionSearchData={handleRecognitionSearchData} />
}

const printExportButton = handleExport => {
	return <ExportButton handleExport={handleExport} />
}

export default proFormItem
