import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Tabs, Popconfirm } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import { routeChangeAction } from './ActionCreator'
import { linkTo } from '@/Routes/ManageRouter'
import { homePath } from '@/Components/Login/LoginForm'
import { setSecRouteStyle } from '@/Components/SlideBar'
import './ContentInHeader.less'

const TabPane = Tabs.TabPane
const mapStateToProps = state => {
	return {
		routes: state.route.items,
		route: state.route.item,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey))
			} else {
				dispatch(routeChangeAction(activeKey))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey })
		},
		removeTab(targetKey, history, route, routes) {
			let nextActiveKey = getNextActiveKey(routes, route, targetKey)
			dispatch({ type: 'ROUTE_CHANGE', filter: nextActiveKey })
			dispatch({ type: 'TAB_REMOVE', filter: targetKey })
			if (history) {
				history.push(
					linkTo.to(
						typeof nextActiveKey === 'string'
							? nextActiveKey
							: nextActiveKey.url
					)
				)
			} else {
				dispatch(routeChangeAction(nextActiveKey))
			}
		},
		clearTabsAll(targetKey) {
			dispatch({ type: 'TAB_ALLCLEAR', filter: targetKey })
		},
	}
}

/**
 * 功能:删除tab栏时，计算出下一次激活的tab
 * 删除激活的tab，若它是第一个，激活最后一个。若不是，激活前一个
 * 删除非激活的tab，那没事了
 * @param {Array} routes
 * @param {string} route
 * @param {string} targetKey
 * @return {string}
 */
function getNextActiveKey(routes, route, targetKey) {
	let nextActiveKey = ''
	if (routes.length === 1) {
		return homePath
	}
	if (targetKey === route) {
		if (routes[0].key === targetKey) {
			return routes[routes.length - 1].key
		} else {
			routes.every(item => {
				item.key !== targetKey && (nextActiveKey = item.key)
				return item.key !== targetKey
			})
		}
		return nextActiveKey
	}
	return route
}

class ContentHeader extends Component {
	rightOperations = (
		<Popconfirm
			title='关闭其他页面'
			onConfirm={() => {
				this.clearTabsWithHome()
			}}
			okText='是'
			cancelText='否'
			placement='bottomRight'>
			<span
				style={{
					display: 'inline-block',
					width: '40px',
					cursor: 'pointer',
					textAlign: 'center',
					color: '#4A6DB8',
				}}
				title={'清空'}
				onClick={this.clearTabsWithHome}>
				<DeleteFilled />
			</span>
		</Popconfirm>
	)

	render() {
		let ActiveKey = this.findActiveKey()
		return (
			<nav className='contentHeaderWithoutTabs'>
				<Tabs
					tabBarExtraContent={this.rightOperations}
					onEdit={(targetKey, action) => {
						this.onEdit(targetKey, action)
					}}
					onChange={activeKey => {
						this.changeTab(activeKey)
					}}
					hideAdd={true}
					type='editable-card'
					activeKey={ActiveKey}>
					{this.props.routes[0] &&
						this.props.routes.map(current => {
							return <TabPane tab={current.title} key={current.key} />
						})}
				</Tabs>
			</nav>
		)
	}

	// 按着路由，找当前需要被激活的tab
	findActiveKey = () => {
		if (this.props.routes[0]) {
			let tmp = this.props.location.pathname.split('/')
			tmp = tmp.slice(2, tmp.length).join('/')
			let pre = '/'
			return pre.concat(tmp)
		}
	}

	// tab改变的时候触发
	changeTab = async activeKey => {
		const { onChange, history } = this.props
		await onChange(activeKey, history)
		setSecRouteStyle()
	}

	// 当点击删除按钮时触发的
	onEdit = async (targetKey, action) => {
		let { removeTab, history, route, routes } = this.props
		if (action === 'remove') {
			await removeTab(targetKey, history, route, routes)
			setSecRouteStyle()
		}
	}

	// 清空所有剩下首页
	clearTabsWithHome = () => {
		const { clearTabsAll, route } = this.props
		clearTabsAll(route)
	}
}

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(ContentHeader)
