import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import { UPGRADE_PUBARCHIVE, INITIAL_PUBARCHIVE } from '@/Redux/ActionTypes/PubArchive'
import { PUBFILE_ADD, PUBDOSSIER_ADD } from '@/Redux/ActionTypes/Public/PubForm'
import _fetch from '@/Utils/Fetch'
import { archiveUrl, recordFileUrl, dossierUrl, filesUrl } from '@/Utils/Urls'
import { dossierEnum } from '@/Utils/Enum'
import { message } from 'antd'

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

export const addPubFileAction = data => {
	return {
		type: PUBFILE_ADD,
		data: data,
	}
}

export const addPubDossierAction = data => {
	return {
		type: PUBDOSSIER_ADD,
		data: data,
	}
}

// 添加或更新pubArchive[markName].new
export const upgradePubArchiveAction = (markName, pubArchiveData) => {
	return {
		type: UPGRADE_PUBARCHIVE,
		filter: {
			markName,
			...pubArchiveData,
		},
	}
}

// 初始化pubArchive[markName].old
export const inititalPubArchiveAction = (markName, pubArchiveData) => {
	return {
		type: INITIAL_PUBARCHIVE,
		filter: {
			markName,
			...pubArchiveData,
		},
	}
}

/**
 * 获取案卷或档案（文件）详情
 * @param {Object} record 案卷或档案（文件）的信息
 */
export const getDetailThunk = record => () =>
	new Promise(async (resolve, reject) => {
		const { id, type, archiveType } = record
		let url = null
		switch (dossierEnum.type[type]) {
			case '案卷':
				url = dossierUrl.getDossierDetail
				break
			case '文件':
				archiveType === 1
					? (url = archiveUrl.getArchiveDetail)
					: (url = archiveUrl.getDocDetail)
				break
			default:
				return
		}
		let res = await _fetch({
			url,
			data: {
				id,
			},
		})
		if (res && res.data.code === 200) {
			if (type) {
				resolve({
					...res.data.data,
					type: record.type,
				})
			} else {
				// 案卷顺带请求其下的列表
				_fetch({
					url: archiveUrl.getArchiveList,
					type: 'get',
					data: {
						id,
						size: 999,
					},
					isJson: true,
				}).then(data => {
					if (data && data.data.code === 200) {
						res.data.data['archiveDetail'] = data.data.data.records || []
						resolve({
							...res.data.data,
							type: record.type,
						})
					} else {
						reject()
					}
				})
			}
		} else {
			reject()
		}
	})

/**
 * 著录编辑
 * @param {object} data 申请数据
 */
export const updateRecordThunk = data => () =>
	new Promise(resolve => {
		// 对档案请求编辑
		_fetch({
			url: recordFileUrl.updateDossierDetail,
			isJson: true,
			data,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				message.success('编辑成功')
				resolve()
			}
		})
	})

// 获取表格数据
export const getArchiveListThunk = record => () =>
	new Promise(async (resolve, reject) => {
		// 案卷顺带请求其下的列表
		_fetch({
			url: archiveUrl.getArchiveList,
			type: 'get',
			data: {
				id: record.id,
				size: 999,
			},
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data.records)
			} else {
				reject()
			}
		})
	})

// 更新附件
export const updateAttachmentThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.updateAttachment,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

/**
 * 给案卷下的所有档案进行指定序号排序
 * @param {*} data dossierId、archiveIds、numbers
 * @returns
 */
export const setNumForArchiveThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: dossierUrl.setNumForArchive,
			type: 'post',
			isJson: true,
			data,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})
