import React, { Component } from 'react'
import { Pagination, Skeleton } from 'antd'
import { connect } from 'react-redux'
import FileDetails from '../FileDetails'
import DossierDetails from '../DossierDetails'
import _ from 'lodash'
import {
	getDetailThunk,
	addPubFileAction,
	getArchiveListThunk,
	inititalPubArchiveAction,
} from '@/Public/PubTable/baseTable/ActionCreator'
import {
	getDossierListThunk,
	getMyStorageDossierThunk,
	getInsertArchiveListThunk,
	searchDossierListThunk,
} from './ActionCreator'

import './index.less'

const mapStateToProps = state => ({
	pubTableData: state.pubTable.pub_allTableData,
	archiveData: state.pubForm.pub_fileDetails,
	dossierData: state.pubArchive?.dossierDetails?.new,
	route: state.pubForm.pub_fileDetails.route,
})

const mapDispatchToProps = dispatch => ({
	getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
	getArchiveList: (...rest) => dispatch(getArchiveListThunk(...rest)),
	addPubFileData(data) {
		return dispatch(addPubFileAction(data))
	},
	inititalPubArchive: (markName, pubArchive) =>
		dispatch(inititalPubArchiveAction(markName, pubArchive)),
	getMyStorageDossier: (markName, pubArchive) =>
		dispatch(getMyStorageDossierThunk(markName, pubArchive)),
	getDossierList: (...rest) => dispatch(getDossierListThunk(...rest)),
	getInsertArchiveList: (...rest) => dispatch(getInsertArchiveListThunk(...rest)),
	searchDossierList: (...rest) => dispatch(searchDossierListThunk(...rest)),
})

@connect(mapStateToProps, mapDispatchToProps)
class Details extends Component {
	// 先把进入的状态（著录，与归档之类）的请求数据 和 进入的索引放进 state 中
	componentDidMount() {
		document.addEventListener('keydown', this.onKeyDown)
		// 考虑到可能一个页面有多个表格，把lastHref改成了markName作为判断依据
		const { type, markName } = JSON.parse(
			sessionStorage.getItem('detailsNeeded') || '{}'
		)
		const { pubTableData } = this.props
		if (pubTableData[markName]) {
			const currentIndex = this.getCurrentIndex(pubTableData[markName], type)
			const record = {
				id: pubTableData[markName].data[currentIndex]?.id,
				type: pubTableData[markName].data[currentIndex]?.type,
			}
			this.setState({
				pubTableData: pubTableData[markName],
				currentIndex,
				type,
				record,
			})
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.onKeyDown)
	}

	state = {
		pubTableData: {},
		currentIndex: -1,
		type: -1,
	}

	onKeyDown = _.throttle(
		e => {
			let { pubTableData, currentIndex } = this.state
			const { current, pageSize, total } = pubTableData
			let page = currentIndex + (current - 1) * pageSize + 1
			switch (e.keyCode) {
				case 37:
					if (page > 1) {
						page--
						this.changePage(page)
					}
					break
				case 39:
					if (page < total) {
						page++
						this.changePage(page)
					}
					break
				default:
					break
			}
		},
		1000,
		{
			leading: true,
			trailing: false,
		}
	)

	// 获取当前项在当前请求数据中的索引
	getCurrentIndex = (pubTableData, type) => {
		const { archiveData, dossierData } = this.props
		// 因为type为1或3都是从archiveData中获取的 ==> 工作室搬家后，后端接口发生了不可描述的变化，type == 1 的案卷的id应当是dossierId (单文件案卷和单文件案卷中的文件id错位了)
		let id = type === 0 ? dossierData?.archiveData?.id : archiveData?.id
		for (let i = 0; i < pubTableData.data.length; i++) {
			const temp = pubTableData.data[i]
			if (temp.id === id && temp.type === type) {
				return i
			}
		}

		return -1
	}

	// 获取分页器索引
	getTotalIndex = () => {
		const { currentIndex, pubTableData } = this.state

		if (currentIndex === -1) return -1

		const { current, total, pageSize } = pubTableData
		const totalIndex = Math.min((current - 1) * pageSize + currentIndex + 1, total)
		return totalIndex
	}

	// 改变页面
	changePage = async page => {
		let { pubTableData } = this.state
		const { current, pageSize } = pubTableData
		const {
			getDossierList,
			getMyStorageDossier,
			getInsertArchiveList,
			searchDossierList,
		} = this.props
		const pubSearchConfig = JSON.parse(
			sessionStorage.getItem('pubSearchConfig') || '{}'
		)
		const { lastHref } = JSON.parse(sessionStorage.getItem('detailsNeeded') || '{}')

		const targetIndex = page - 1
		const targetCurrent = Math.floor(targetIndex / pageSize) + 1
		pubSearchConfig.current = targetCurrent
		// 如果想查看的项超出了当前范围，则必须重新请求一次数据
		if (targetCurrent !== current) {
			let ajaxInterface = getDossierList
			if (lastHref === 'Record') {
				ajaxInterface = getMyStorageDossier
			} else if (lastHref === 'Archived') {
				ajaxInterface = searchDossierList
			} else if (lastHref?.startsWith('/')) {
				ajaxInterface = getInsertArchiveList
			}

			const data = await ajaxInterface(pubSearchConfig)

			pubTableData = {
				current: data.current,
				data: data.records,
				pageSize: data.size,
				total: data.total,
			}
			this.setState({ pubTableData }, () => this.changePage(page))
		} else {
			const record = pubTableData.data[targetIndex - (current - 1) * pageSize]
			const detailsNeeded = JSON.parse(
				sessionStorage.getItem('detailsNeeded') || '{}'
			)
			detailsNeeded.type = record.type
			sessionStorage.setItem('detailsNeeded', JSON.stringify(detailsNeeded))
			this.setState(
				{
					type: -1,
					currentIndex: page - (current - 1) * pageSize - 1,
					record: {
						id: record.id,
						type: record.type,
					},
				},
				() => {
					this.changeItem(record)
				}
			)
		}
	}

	// 改变页面中的项
	changeItem = record => {
		const { getDetail, addPubFileData, getArchiveList, inititalPubArchive } =
			this.props
		getDetail(record).then(data => {
			// 请求详情数据
			if (record.type) {
				//文件类型先请求案卷数据获得archiveID再请求详情数据
				addPubFileData(data) // 文件详情传到redux
			} else {
				getArchiveList(record).then(archiveList => {
					archiveList.forEach(archiveData => {
						const { fileToken, fileType, fileName } = archiveData
						archiveData['preview'] = {
							type: 1,
							fileData: {
								fileToken,
								fileType,
								fileName,
							},
						}
						archiveData['key'] = archiveData.id
					})
					inititalPubArchive('dossierDetails', {
						filesData: archiveList || [],
						archiveData: this.setFormRecord(data),
					})
				})
			}
			this.setState({
				type: record.type,
			})
		})
	}

	setFormRecord = pub_dossierDetails => {
		const {
			recordTime,
			recordUser,
			archiveTime,
			archiveUser,
			storageTime,
			storageUser,
		} = pub_dossierDetails
		// 著录人和时间
		pub_dossierDetails.recordUserAndTime = { recordUser, recordTime }
		// 归档人和时间
		pub_dossierDetails.archiveUserAndTime = { archiveUser, archiveTime }
		// 入库人和时间
		pub_dossierDetails.storageUserAndTime = { storageUser, storageTime }
		return pub_dossierDetails
	}

	render() {
		const {
			type,
			pubTableData: { total },
			record,
		} = this.state
		const totalIndex = this.getTotalIndex()
		return (
			<div>
				{/* 骨架屏 */}
				{type === -1 && (
					<div className='details-skeleton'>
						<Skeleton.Button
							active={false}
							size='large'
							block={true}
							shape='default'
						/>
						<Skeleton.Button
							active={false}
							size='large'
							block={true}
							shape='default'
						/>
						<Skeleton.Button
							active={false}
							size='large'
							block={true}
							shape='default'
						/>
						<Skeleton.Button
							active={false}
							size='large'
							block={true}
							shape='default'
						/>
						<Skeleton.Button
							active={false}
							size='large'
							block={true}
							shape='default'
						/>
						<Skeleton.Button
							active={false}
							size='large'
							block={true}
							shape='default'
						/>
						<Skeleton.Button
							active={false}
							size='large'
							block={true}
							shape='default'
						/>
						<Skeleton.Button
							active={false}
							size='large'
							block={true}
							shape='default'
						/>
						<Skeleton.Button
							active={false}
							size='large'
							block={true}
							shape='default'
						/>
						<Skeleton.Button
							active={false}
							size='large'
							block={true}
							shape='default'
						/>
					</div>
				)}

				{/* 文件详情 */}
				{(type === 1 || type === 3) && <FileDetails height='calc(75vh - 30px)' />}

				{/* 案卷详情 */}
				{type === 0 && (
					<DossierDetails
						setStyle={{ contentHeight: 'calc(75vh - 30px)' }}
						record={record}
					/>
				)}

				{/* 翻页 */}
				{this.props.route !== '/newConstruction/UrlToArchives' &&
					this.props.route !== '/mine/mine' && (
						<Pagination
							className='details-pagination'
							current={totalIndex}
							onChange={page => {
								this.changePage(page)
							}}
							total={total}
							pageSize={1}
							showSizeChanger={false}
						/>
					)}
			</div>
		)
	}
}

export default Details
