import _fetch from '@/Utils/Fetch'
import axios from 'axios'
import LoginActionType from '@/Redux/ActionTypes/Login'
import {
	generateCaptcha,
	login,
	archiveTypeUrl,
	addArchivesUrl,
	departmentUrl,
	filesUrl,
	getSidebarTree,
	collectedFileUrl,
	serialNumberUrl,
} from '@/Utils/Urls'

// 用户类型
export const SetUserTypeAction = data => {
	return {
		type: LoginActionType.USERTYPE,
		filter: data,
	}
}
export const SetPermissionAction = data => {
	return {
		type: LoginActionType.PERMISSION,
		filter: data,
	}
}
export const SetRoleListAction = data => {
	return {
		type: LoginActionType.ROLELIST,
		filter: data,
	}
}
export const setUserAction = data => {
	return {
		type: LoginActionType.USER,
		filter: data,
	}
}

// 获取验证码操作
export const generateCaptchaActionThunk = () =>
	new Promise((resolve, reject) => {
		axios.get(generateCaptcha).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject(res.data.message)
			}
		})
	})

// 登录操作
export const loginActionThunk = data => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: login,
			type: 'post',
			isJson: true,
			data: {
				account: data.username,
				password: data.password,
				verityCode: data.verityCode,
				key: data.codeData.key,
			},
		})
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res.data.message)
				}
			})
			.catch(err => {
				return reject(err)
			})
	})

// 获取该用户的侧边栏
export const getSidebarTreeActionThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: getSidebarTree,
			type: 'get',
		}).then(res => {
			if (res?.data?.code === 200) {
				//TODO delete
				//console.log('getSidebarTree resData:', res)
				resolve(res.data)
			} else {
				reject(res.data.message)
			}
		})
	})

// 获取全宗号
export const getFondsIdentifierThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: addArchivesUrl.getFondsIdentifier,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取类别号
export const getDossierTypeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: archiveTypeUrl.getDossierType,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取部门树
export const getDepartmentTreeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: departmentUrl.getDepartmentTree,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取元数据
export const getFileMetadataStructTreeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: filesUrl.getFileMetadataStructTree,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取资料类别号
export const getCollectedFileTypeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: collectedFileUrl.getCollectedFileType,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

//获取个人主题
export const getMyThemeThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: collectedFileUrl.getMyTheme,
			type: 'post',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取全部主题
export const getAllThemeThunk = () =>
	new Promise((resolve, reject) => {
		axios
			.post(collectedFileUrl.getAllTheme)
			.then(res => {
				if (res && res.data.code === 200) {
					resolve(res.data)
				} else {
					reject(res.data)
				}
			})
			.catch(err => {
				reject(err.response.data.message)
			})
	})

//获取部门编号校验规则
export const getSerialNumberConfigThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: serialNumberUrl.getSerialNumberConfig,
			type: 'get',
		}).then(res => {
			if (res.data.code === 200) {
				const obj = {}
				res.data.data.order.split('-').forEach(value => {
					const orderarr = value.split('')
					obj[orderarr[0]] = Number(orderarr[1])
				})
				resolve(obj)
			} else {
				reject(res.data)
			}
		})
	})
