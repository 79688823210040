import React, { PureComponent } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
export const proDatePicker = config => {
	class FormatDatePicker extends PureComponent {
		state = {
			rangeDates: this.props.value,
		}
		onDateChange = (moment, dateString) => {
			let { onChange } = this.props
			if (onChange) onChange(dateString)
		}
		onRangeDateChange = (moment, dateString) => {
			let { onChange } = this.props
			if (onChange) onChange(moment)
		}
		disabledDate = current => {
			return current && current > moment().endOf('day')
		}
		onOpenChange = open => {
			if (open) {
				this.setState({
					dates: [null, null],
				})
			} else {
				this.setState({
					dates: null,
				})
			}
		}
		render() {
			const { value, disabled } = this.props
			return !config.isRange ? (
				<DatePicker
					picker={config.picker === 'all' ? 'date' : config.picker}
					placeholder={config.placeholder}
					showTime={config.picker === 'all'}
					onChange={this.onDateChange}
					value={value ? moment(value) : ''}
					disabled={disabled}
					disabledDate={config.disabledDate || this.disabledDate}
				/>
			) : (
				<DatePicker.RangePicker
					picker={config.picker === 'all' ? 'date' : config.picker}
					showTime={config.picker === 'all'}
					onChange={this.onRangeDateChange}
					value={this.state.dates || value}
					disabled={disabled}
					disabledDate={config.disabledDate || this.disabledDate}
					onOpenChange={this.onOpenChange}
					onCalendarChange={val => this.setState({ dates: val })}
				/>
			)
		}
	}
	return <FormatDatePicker />
}
