import React, { PureComponent, Fragment } from 'react'
import { Menu, Badge } from 'antd'
import { connect } from 'react-redux'
import { history } from '@/index'
import { SmileOutlined, CopyOutlined } from '@ant-design/icons'
import { linkTo } from '@/Routes/ManageRouter'
const { Item, SubMenu } = Menu

let openChange = false
let clickMenuItem = false
let tmpSecondSlide = []
let idToNum = {}
const mapStateToProps = state => {
	return {
		route: state.route.item,
		redDots: state.redDots,
		userType: state.login.userType,
		openKeys: state.slideBar.openKeys,
		selectedKey: state.slideBar.selectedKey,
	}
}

@connect(mapStateToProps, null)
class SecondRouteMenu extends PureComponent {
	state = {
		isCurrentShowPublic: true,
	}

	componentDidMount() {
		this.props.autoOpenCurrentKey.bind(this)(true)
	}

	/**
	 * 返回要渲染的subMenu
	 * @param {*} item
	 * @param {*} level
	 * @param {*} btn
	 * @returns
	 */
	returnSubMenuTitle = (item, level, btn) => {
		let path = item.path
		let { pushRouters, RoutesList, SetRouter } = this.props
		return (
			// 二级路由下有子路由的路由
			<Fragment>
				<div
					onClick={e => {
						// 若果是收藏相关路由才要优化点击体验
						if (
							path.indexOf('/workStation') > -1 &&
							path !== '/workarea/workStation'
						) {
							e.stopPropagation()
							history.push(linkTo.topRoute + path)
							SetRouter(path)
							pushRouters(path, RoutesList) // push点击的侧边栏到route.items数组里
						}
					}}>
					{level === 0 && item.img ? (
						<span className='second-menu-img'>
							<img src={item.img} alt={item.key} />
						</span>
					) : (
						item.icon === 'SmileOutlined' && (
							<SmileOutlined style={item.style} />
						)
					)}
					<Badge
						offset={[10, 0]}
						showZero={false}
						dot={idToNum[item.id]}
						size={'small'}>
						<span>{item.title}</span>
						<span>{btn}</span>
					</Badge>
				</div>
			</Fragment>
		)
	}

	/**
	 * 返回workStaion中的btn
	 * @param {*} item
	 * @returns
	 */
	returnWorkStaionBtn = item => {
		let btn = null
		const { modalHandler, isCurrentShowPublic } = this.props
		const { editWorkStation, addWorkStation, deleteWorkStation, switchShowPublic } =
			modalHandler

		let path = item.path
		if (path.indexOf('/workStation') > -1) {
			// 若果节点是submenu或者是收藏根节点，则没有删除按钮
			if (path === '/workarea/workStation') {
				btn = (
					<Fragment>
						<i
							onClick={e => switchShowPublic(e)}
							className='iconfont-sider-bar menu-extra show-hide'
							title={`${isCurrentShowPublic ? '隐藏' : '显示'}公有收藏夹`}>
							{isCurrentShowPublic ? (
								<span>&#xe6fe;</span>
							) : (
								<span>&#xe745;</span>
							)}
						</i>
						<i
							onClick={e => addWorkStation(e, item.path)}
							className='iconfont-sider-bar menu-extra add'>
							&#xe742;{' '}
						</i>
					</Fragment>
				)
			} else {
				btn =
					item.child && item.child.length > 0 ? (
						<Fragment>
							<i
								onClick={e => editWorkStation(e, item.id, item.status)}
								className='iconfont-sider-bar menu-extra edit'>
								&#xe6f6;
							</i>
							<i
								onClick={e => addWorkStation(e, item.path)}
								className='iconfont-sider-bar menu-extra add'>
								&#xe742;
							</i>
						</Fragment>
					) : (
						<Fragment>
							<i
								onClick={e => editWorkStation(e, item.id, item.status)}
								className='iconfont-sider-bar menu-extra edit'>
								&#xe6f6;
							</i>
							<i
								onClick={e => addWorkStation(e, item.path)}
								className='iconfont-sider-bar menu-extra add'>
								&#xe742;{' '}
							</i>
							<i
								onClick={e => deleteWorkStation(e, item.path)}
								className='iconfont-sider-bar menu-extra delete'>
								&#xe744;
							</i>
						</Fragment>
					)
			}
		}
		return btn
	}

	/**
	 * 返回Menu.Item
	 * @param {*} item
	 * @param {*} level
	 * @param {*} btn
	 * @returns
	 */
	returnItem = (item, level, btn) => {
		let path = item.path
		let child = null
		if (path.indexOf('/workStation') > -1) {
			child = (
				<div>
					{item.img && (
						<span className='second-menu-img'>
							<img src={item.img} alt={item.key} />
						</span>
					)}
					<span>{item.title}</span>
					<span>{btn}</span>
				</div>
			)
		} else {
			child = item.img ? (
				<Badge
					count={idToNum[item.id]}
					offset={[10, 5]}
					showZero={false}
					size={'small'}>
					<div>
						{/* 这个是二级路由下没有子路由的路由 */}
						<span className='second-menu-img'>
							<img
								src={item.img}
								alt={item.key}
								className={item.className}
							/>
						</span>
						<span>{item.title}</span>
					</div>
				</Badge>
			) : (
				// 这个是二级路由下的子路由
				<Badge
					count={idToNum[item.id]}
					offset={[10, -3]}
					showZero={false}
					size={'small'}>
					{item.icon === 'CopyOutlined' && <CopyOutlined style={item.style} />}
					<span>{item.title}</span>
				</Badge>
			)
		}
		return (
			<Item
				className={`menu-item-box ${
					path.includes('workarea') && 'menu-' + level + '-lesspad'
				} ${
					path.indexOf('/workStation') && level === 1 && 'menu-item-lesspadding'
				} ${item.contentClassName}`}
				key={item.key}>
				{child}
			</Item>
		)
	}

	/**
	 * 用于渲染菜单
	 * @param {Array} item 菜单树🌲
	 */
	renderMenu = (item, level = 0) => {
		let btn = this.returnWorkStaionBtn(item)
		if (item.child && item.child.length > 0) {
			return (
				<SubMenu
					className={`menu-item-box ${'submenu-inline-' + level + '-lesspad'}`}
					key={item.key}
					title={this.returnSubMenuTitle(item, level, btn)}>
					{item.child.map(item => this.renderMenu(item, level + 1))}
				</SubMenu>
			)
		} else {
			return this.returnItem(item, level, btn)
		}
	}

	/**
	 * 根据是否有该子路由，返回二级路由红点数
	 * @param {Array} secondSlide 二级路由数组
	 */
	returnRedDotNum = secondSlide => {
		const { redDots } = this.props
		// 存放侧边栏不同id小格的红点数
		const idToNum = {}
		secondSlide.forEach(item => {
			if (item.child && item.child.length > 0) {
				let count = 0
				item.child.forEach(thing => {
					if (redDots[thing.id]) {
						idToNum[thing.id] = redDots[thing.id]
						count += redDots[thing.id] || 0
					}
				})
				idToNum[item.id] = count
			}
		})
		return idToNum
	}

	/**
	 * 创建侧边栏
	 * @param {Array} secondSlide
	 * @param {String} pathName
	 * @returns
	 */
	userTypeToRender(secondSlide, pathName) {
		/**
		 * 优化：
		 * 当openChange的时候，不创建
		 * 点击menuItem的时候，不创建
		 */
		idToNum = this.returnRedDotNum(secondSlide)
		if (openChange || clickMenuItem) {
			openChange = false
			clickMenuItem = false
			return tmpSecondSlide
		}
		tmpSecondSlide = secondSlide?.map(item => {
			return this.renderMenu(item)
		})
		return tmpSecondSlide
	}

	/**
	 * 点击submenu时触发
	 * @param {Array} openKeys
	 */
	onOpenChange = openKeys => {
		const { updateOpenKeys } = this.props
		openChange = true // 不重新渲染侧边栏
		let strPath = openKeys[openKeys.length - 1]
		if (openKeys.length > 1) {
			// 判断是否是展开workStation的子菜单(SubMenu)。如果不是就展开选中的SubMenu
			let check = openKeys.every(item => item.includes('workStation'))
			if (!check) openKeys = [strPath]
		}
		updateOpenKeys(openKeys)
		// 点击了收藏，侧边栏宽度变大，其他就变小
		if (strPath?.includes('/workStation')) {
			this.props.setStyle('330px')
		} else {
			this.props.setStyle('230px')
		}
	}

	/**
	 * 点击menuitem时触发
	 * @param {Object} params
	 */
	onClick = ({ item, key, keyPath, domEvent }) => {
		//TODO delete
		//console.log('running in SecondRouteMenu onClick')
		clickMenuItem = true // 不重新渲染侧边栏
		const {
			SetRouter,
			pushRouters,
			RoutesList,
			setStyle,
			updateSelectedKey,
			updateOpenKeys,
		} = this.props
		const { openKeys } = this.props
		// 判断是否点击了workStation中的最底层的menuItem。如果是则不收起
		if (openKeys.length > 1) {
			// 判断是否是展开workStation的子菜单(SubMenu)。如果不是就展开选中的SubMenu
			let check = keyPath.every(item => item.includes('workStation'))
			if (!check) {
				updateOpenKeys(keyPath)
			}
		}
		let route = keyPath[0]
		history.push(linkTo.topRoute + route)
		// 设置Router之后，激活对应Tabs栏
		SetRouter(route)
		// 调用函数，把路由push到route.items里面
		pushRouters(route, RoutesList)
		// 设置选中的Menu.Item
		updateSelectedKey([route])
		setStyle()
	}

	render() {
		const { secondSlide, firstPathName, openKeys, selectedKey } = this.props
		return (
			<Menu
				mode='inline'
				style={{ height: '100%' }}
				className='sider-bar'
				openKeys={openKeys}
				onOpenChange={this.onOpenChange}
				onClick={this.onClick}
				selectedKeys={selectedKey} // 激活的选中对应的Menu.Item
			>
				{this.userTypeToRender(secondSlide, firstPathName)}
			</Menu>
		)
	}
}

export default SecondRouteMenu
