import React, { Component } from 'react'
import { Modal, Input, message, Radio } from 'antd'
import { connect } from 'react-redux'
import {
	addWorkStaionAction,
	deleteWorkStationAction,
	editWorkbenchStatusAction,
} from './ActionCreator'
import { withRouter } from 'react-router'

const mapStateToProps = state => {
	return {}
}

const mapDispatchToProps = dispatch => {
	return {
		addWorkStation(data, closeFunc, reflashMenu) {
			dispatch(addWorkStaionAction(data, closeFunc, reflashMenu))
		},
		deleteWorkStation(data, closeFunc, reflashMenu) {
			dispatch(deleteWorkStationAction(data, closeFunc, reflashMenu))
		},
		editWorkbenchStatus(data, closeFunc, reflashMenu) {
			dispatch(editWorkbenchStatusAction(data, closeFunc, reflashMenu))
		},
	}
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class AddWorkStationModal extends Component {
	state = {
		workbenchName: null,
		status: 0,
	}

	inputRef = React.createRef(null)

	editInner = () => {
		return (
			<div>
				<label htmlFor='workbench'>修改收藏夹状态：</label>
				<Radio.Group
					defaultValue={this.props.status}
					onChange={e => {
						this.setState({ status: e.target.value })
					}}>
					<Radio value={0}>私有</Radio>
					<Radio value={1}>公有</Radio>
					<Radio value={2}>保护</Radio>
				</Radio.Group>
			</div>
		)
	}

	addInner = (
		<div>
			<label htmlFor='workbenchName'>收藏名称：</label>
			<Input
				maxLength={255}
				onChange={e => {
					this.setState({ workbenchName: e.target.value })
				}}
				style={{ width: 300 }}
				id='workbenchName'
				placeholder='输入收藏名称'
				defaultValue={this.state.workbenchName}
				ref={this.inputRef}
			/>
		</div>
	)

	deleteInner = '你确定要删除该收藏夹吗？'

	// 更改收藏夹状态
	onEdit = () => {
		const { id, editWorkbenchStatus, onCancel, reflashMenu } = this.props
		const { status } = this.state
		const data = {
			workbenchId: id,
			status,
		}
		editWorkbenchStatus(data, onCancel, reflashMenu)
	}

	// 添加收藏夹
	onAdd = () => {
		const {
			addWorkStation,
			targetPath,
			onCancel,
			reflashMenu,
			location: { pathname },
		} = this.props
		const { workbenchName } = this.state
		if (!workbenchName) {
			message.destroy()
			message.info('请输入收藏夹名称')
			return
		}
		const numReg = /\d+/g // 用于匹配url中的数字
		// 获取当前url中的id
		let ids = targetPath ? targetPath.match(numReg) : pathname.match(numReg)
		let data = {
			workbench: workbenchName,
		}
		// 如果大于0才有节点
		if (!!ids && ids.length > 0 && ids.length <= 4) {
			data.parentId = Number(ids.slice(-1))
			addWorkStation(data, onCancel, reflashMenu)
			this.setState({ workbenchName: null })
		} else if (!ids) {
			addWorkStation(data, onCancel, reflashMenu)
			this.setState({ workbenchName: null })
		} else {
			message.error('最多只能添加四层节点')
			onCancel()
		}
		//输入框置空
		this.inputRef.current.input.value = ''
		this.inputRef.current.state.value = ''
	}

	// 删除收藏夹
	onDelete = () => {
		const {
			targetPath,
			deleteWorkStation,
			onCancel,
			reflashMenu,
			location: { pathname },
		} = this.props
		const numReg = /\d+/g // 用于匹配url中的数字
		// 获取当前url中的id
		let ids = targetPath ? targetPath.match(numReg) : pathname.match(numReg)
		let data = {
			workbenchId: Number(ids.slice(-1)),
		}
		deleteWorkStation(data, onCancel, reflashMenu)
	}

	render() {
		const { title, visible, onCancel, icon } = this.props

		const onOk = {
			提示: this.onDelete,
			添加收藏夹: this.onAdd,
			更改收藏夹状态: this.onEdit,
		}[title]

		const inner = {
			提示: this.deleteInner,
			添加收藏夹: this.addInner,
			更改收藏夹状态: this.editInner(),
		}[title]

		return (
			<Modal
				title={title}
				visible={visible}
				onOk={onOk}
				onCancel={onCancel}
				icon={icon}
				destroyOnClose={true}>
				{inner}
			</Modal>
		)
	}
}

export default AddWorkStationModal
