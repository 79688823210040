import { HealthUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'

export const isloading = () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: HealthUrl.getServiceInstances,
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				res.data.data.forEach(item => {
					item.isHealthtag = item.isHealth ? '健康' : '异常'
				})
				resolve(res.data)
			}
		})
	})
