import React, { Component } from 'react'
import { Button, Progress, List, Divider, Spin, Tag, Radio } from 'antd'
import {
	LoadingOutlined,
	CloseCircleOutlined,
	CheckCircleOutlined,
	ExclamationCircleOutlined,
} from '@ant-design/icons'
import { connect } from 'react-redux'
import FileUploader from './FileUploader'
import { exportExcelThunk, exportDetailExcelThunk } from './ActionCreators'
import './index.less'

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
	exportExcel: (...rest) => dispatch(exportExcelThunk(...rest)), // 导出自动著录模板
	exportDetailExcel: (...rest) => dispatch(exportDetailExcelThunk(...rest)), // 导出自动著录模板
})

@connect(mapStateToProps, mapDispatchToProps)
class AutomaticDescription extends Component {
	state = {
		uploading: false,
		allAmount: 1, // 文件总数
		successAmount: 0, // 上传成功数量
		failAmount: 0, // 上传失败数量
		dossierAomunt: 0, // 案卷总数
		status: 0, // 用户选择的导入方式
		fileNameList: [],
		dossierNameList: [],
		viewKey_1: '1', // 用户选择的文件列表显示设置，默认为1，显示全部
		viewKey_2: '1', // 用户选择的案卷列表显示设置，默认为1，显示全部
	}

	getAllAmount = data => {
		this.setState({
			allAmount: data,
		})
	}

	addSuccessAmount = () => {
		this.setState({
			successAmount: this.state.successAmount + 1,
		})
	}

	noDossier = () => {
		this.setState({
			dossierAomunt: 0,
		})
	}

	singleDossier = () => {
		this.setState({
			dossierAomunt: 1,
		})
	}

	// 计算案卷总数
	getDossierAmount = () => {
		this.setState({
			dossierAomunt: this.state.dossierAomunt + 1,
		})
	}

	exportModalExcel = archiveType => {
		const { exportExcel } = this.props
		//console.log(archiveType)

		exportExcel(archiveType)
	}

	exportDetailExcel = () => {
		const { exportDetailExcel } = this.props
		exportDetailExcel()
	}

	// 重新开始批量著录的流程,回归原点
	resetAll = () => {
		this.setState({
			uploading: false,
			allAmount: 0,
			successAmount: 0,
			dossierAomunt: 0,
		})
	}

	showChart = () => {
		this.setState({
			uploading: true,
		})
	}

	// 获取所有文件名字用来填充列表
	getAllFileInfo = (data, status) => {
		let fileNameList = []
		// 填充文件列表
		data.forEach(item => {
			if (item.name.includes('统一信息') || item.name.includes('详细信息')) {
				return
			} else {
				fileNameList.push({
					path: item.webkitRelativePath,
					uuid: item.uuid,
					success: false,
					error: '',
					isLoading: true,
				})
			}
		})

		// 填充案卷列表
		let dossierNameList = JSON.parse(JSON.stringify(fileNameList))
		dossierNameList = dossierNameList.map(item => {
			item.path =
				item.path.split('/').length > 2
					? item.path.split('/').slice(0, -1).join('/')
					: item.path.split('/').slice(0, -1).toString()
			return item
		})

		dossierNameList = dossierNameList.filter(
			(currentValue, currentIndex, selfArr) => {
				return (
					selfArr.findIndex(x => x.path === currentValue.path) === currentIndex
				)
			}
		)

		if (status === 1 || status === 2) {
			dossierNameList?.splice(1, dossierNameList.length - 1)
		}

		this.setState({
			fileNameList,
			dossierNameList,
		})
	}

	// 获得著录情况
	getDetails = data => {
		const { fileNameList } = this.state
		let newFileNameList = JSON.parse(JSON.stringify(fileNameList))
		data.error = null // 著录情况一开始先设定为没有错误
		data.uuidGroup.forEach(item => {
			if (item.error) {
				data.error = item.error // 如果著录失败，把著录失败的原因存在data里
			}
		})
		newFileNameList.forEach(item => {
			// 循环遍历著录情况，如果没有错误则显示导入成功，否则显示导入失败和失败原因
			if (item.uuid === data.uuid) {
				if (data.error) {
					item.error = data.error
				} else {
					item.success = true
				}
				item.isLoading = false
			}
		})

		this.setState(
			{
				fileNameList: newFileNameList,
			},
			() => {
				this.handleDossierDetails()
			}
		)
	}

	// 用户切换文件列表显示设置时调用
	changeListView_1 = key => {
		let { fileNameList } = this.state
		let viewList_1 = []
		fileNameList.forEach(item => {
			if (key === '1') {
				viewList_1.push(item)
			} else if (key === '2' && item.success) {
				viewList_1.push(item)
			} else if (key === '3' && !item.success && !item.isLoading) {
				viewList_1.push(item)
			}
		})
		this.setState({
			viewList_1,
		})
	}

	// 用户切换案卷列表显示设置时调用
	changeListView_2 = key => {
		let { dossierNameList } = this.state
		let viewList_2 = []
		dossierNameList.forEach(item => {
			if (key === '1') {
				viewList_2.push(item)
			} else if (key === '2' && item.success) {
				viewList_2.push(item)
			} else if (key === '3' && !item.success && !item.isLoading) {
				viewList_2.push(item)
			}
		})
		this.setState({
			viewList_2,
		})
	}

	// 处理案卷导入情况
	handleDossierDetails = () => {
		const { fileNameList, status } = this.state
		let dossierNameList = JSON.parse(JSON.stringify(fileNameList))
		dossierNameList = dossierNameList.map(item => {
			item.path =
				item.path.split('/').length > 2
					? item.path.split('/').slice(0, -1).join('/')
					: item.path.split('/').slice(0, -1).toString()
			return item
		})

		dossierNameList = dossierNameList.filter(
			(currentValue, currentIndex, selfArr) => {
				return (
					selfArr.findIndex(x => x.path === currentValue.path) === currentIndex
				)
			}
		)

		if (status === 1 || status === 2) {
			dossierNameList?.splice(1, dossierNameList.length - 1)
		}

		this.setState({
			dossierNameList,
		})
	}

	// 获取用户选择的导入方式
	getStatus = data => {
		this.setState({
			status: data,
		})
	}

	// 控制文件显示列表
	onchange1 = e => {
		const value = e.target.value
		this.changeListView_1(value)
		this.setState({
			viewKey_1: value,
		})
	}

	// 控制案卷显示列表
	onchange2 = e => {
		const value = e.target.value
		this.changeListView_2(value)
		this.setState({
			viewKey_2: value,
		})
	}

	render() {
		const {
			uploading,
			allAmount,
			successAmount,
			dossierAomunt,
			fileNameList,
			dossierNameList,
			viewList_1,
			viewList_2,
			viewKey_1,
			viewKey_2,
		} = this.state
		return (
			<div className='auto-description iconfont-buttons'>
				<Button
					title='导出Excel模板'
					style={{ marginRight: '8px' }}
					type='primary'
					onClick={() => {
						this.exportModalExcel()
						this.exportDetailExcel()
					}}>
					&#xe623; 导出Excel模板
				</Button>
				<Tag icon={<ExclamationCircleOutlined />} color='warning'>
					上传大文件或网速较慢时导入时间较长，请勿在文件上传完成前离开页面，否则无法成功导入。
				</Tag>
				<FileUploader
					allAmount={allAmount}
					successAmount={successAmount}
					addSuccessAmount={this.addSuccessAmount}
					getDossierAmount={this.getDossierAmount}
					resetAmount={this.resetAmount}
					noDossier={this.noDossier}
					singleDossier={this.singleDossier}
					resetAll={this.resetAll}
					showChart={this.showChart}
					getAllAmount={this.getAllAmount}
					getAllFileInfo={this.getAllFileInfo}
					getDetails={this.getDetails}
					getStatus={this.getStatus}
				/>
				{uploading && (
					<div
						style={{
							marginLeft: '5%',
							marginTop: '0%',
							position: 'relative',
							display: 'flex',
							justifyContent: 'space-around',
						}}>
						<div className='clear-loading loading-effect-2'>
							<span></span>
						</div>
						<Progress
							type='circle'
							percent={(successAmount / allAmount) * 100}
							strokeColor='rgb(93, 131, 219)'
							trailColor='#D3D3D3'
							strokeWidth='4'
							width={280}
							style={{
								transform: 'translate(-50px,85px)',
							}}
							format={() => {
								return (
									<div style={{ lineHeight: '50px', fontSize: '14px' }}>
										<h2>{`案卷总数：${dossierAomunt}`}</h2>
										<h2>{`文件上传进度：${successAmount}/${allAmount}`}</h2>
									</div>
								)
							}}
						/>
						{/* 文件导入情况 */}
						<div
							style={{
								width: '37%',
								left: '33%',
								overflowX: 'auto',
							}}>
							<Divider orientation='left'>
								文件导入情况
								<Radio.Group
									defaultValue={'1'}
									onChange={this.onchange1}
									value={viewKey_1}
									style={{ transform: 'translate(-190px,0)' }}>
									<Radio value={'1'}>显示全部</Radio>
									<Radio value={'2'}>显示成功</Radio>
									<Radio value={'3'}>显示失败</Radio>
								</Radio.Group>
							</Divider>
							<List
								bordered
								style={{
									height: '350px',
									overflow: 'auto',
								}}
								dataSource={viewKey_1 === '1' ? fileNameList : viewList_1}
								renderItem={item => (
									<List.Item
										style={{
											position: 'relative',
											paddingLeft: '10px',
											width: '450px',
										}}>
										<div
											style={{
												width: '40px',
												display: `${item.isLoading ? 'block' : 'none'}`,
												marginRight: '15px',
											}}>
											<Spin
												tip='著录中'
												style={{ fontSize: 12, width: '50px' }}
												indicator={
													<LoadingOutlined
														style={{ fontSize: 15 }}
														spin
													/>
												}
											/>
										</div>
										<div
											style={{
												display: `${!item.isLoading ? 'block' : 'none'}`,
												marginRight: '0px',
											}}>
											{item.error ? (
												<Tag
													icon={<CloseCircleOutlined />}
													color='error'>
													{item.error}
												</Tag>
											) : (
												<Tag
													icon={<CheckCircleOutlined />}
													color='success'>
													导入成功
												</Tag>
											)}
										</div>
										{item.path}
									</List.Item>
								)}
							/>
						</div>
						{/* 案卷导入情况 */}
						<div
							style={{
								width: '37%',
								left: '65%',
							}}>
							<Divider orientation='left'>
								案卷导入情况
								<Radio.Group
									defaultValue={'1'}
									onChange={this.onchange2}
									value={viewKey_2}
									style={{ transform: 'translate(-190px,0)' }}>
									<Radio value={'1'}>显示全部</Radio>
									<Radio value={'2'}>显示成功</Radio>
									<Radio value={'3'}>显示失败</Radio>
								</Radio.Group>
							</Divider>
							<List
								bordered
								style={{
									height: '350px',
									overflow: 'auto',
								}}
								dataSource={
									viewKey_2 === '1' ? dossierNameList : viewList_2
								}
								renderItem={item => (
									<List.Item
										style={{
											position: 'relative',
											paddingLeft: '10px',
										}}>
										<div
											style={{
												width: '40px',
												display: `${item.isLoading ? 'block' : 'none'}`,
												marginRight: '15px',
											}}>
											<Spin
												tip='著录中'
												style={{ fontSize: 12, width: '50px' }}
												indicator={
													<LoadingOutlined
														style={{ fontSize: 15 }}
														spin
													/>
												}
											/>
										</div>
										<div
											style={{
												display: `${!item.isLoading ? 'block' : 'none'}`,
												marginRight: '0px',
											}}>
											{item.error ? (
												<Tag
													icon={<CloseCircleOutlined />}
													color='error'>
													{item.error}
												</Tag>
											) : (
												<Tag
													icon={<CheckCircleOutlined />}
													color='success'>
													导入成功
												</Tag>
											)}
										</div>
										{item.path}
									</List.Item>
								)}
							/>
						</div>
					</div>
				)}
			</div>
		)
	}
}

export default AutomaticDescription
