import React, { Component } from 'react'
import axios from 'axios'

import RViewerJS from 'viewerjs-react'
import 'viewerjs-react/dist/index.css'

// import 'reveal.js/dist/reveal.css'
// import 'reveal.js/dist/theme/white.css'

import { message } from 'antd'
// import APlayer from 'aplayer';
// import 'aplayer/dist/APlayer.min.css';
import { ePhotoAlbum } from '@/Utils/Urls'
import './index.less'

export default class Share extends Component {
	componentDidMount() {
		const filesStr = window.location.search.replace('?files=', '')
		axios
			.get(ePhotoAlbum.getShareStudyingFiles, {
				params: {
					token: filesStr,
				},
			})
			.then(res => {
				this.setState({
					filesData: res.data.data.fileList,
					fileAmount: res.data.data.fileList.length,
				})
				// const { audios, images, videos } = res.data.data
				// this.setState({ audios, images, videos }, () => {
				//   this.ap = new APlayer({
				//     container: document.getElementById('aplayer'),
				//     fixed: true,
				//     autoplay: false,
				//     theme: '#FADFA3',
				//     loop: 'all',
				//     order: 'random',
				//     preload: 'auto',
				//     volume: 0.7,
				//     mutex: true,
				//     listFolded: false,
				//     mini: false,
				//     audio: audios.map(item => ({
				//       url: `/api/file/download/${item.token}`,
				//       name: item.name,
				//       artist: '',
				//       theme: '#ebd0c2',
				//     }))
				//   })

				//   this.ap.on('play', () => {
				//     this.setState({ status: 'play' })
				//   })

				//   this.ap.on('pause', () => {
				//     this.setState({ status: 'pause' })
				//   })
				// })
			})
			.catch(err => {
				message.error('请求失败，请刷新页面重试', 0)
			})
	}

	// 卸载 audio
	componentWillUnmount() {
		this.ap.pause()
		this.ap = null
	}

	// renderAudioPlayer = () => {
	//   const ap = new APlayer({
	//     container: document.getElementById('aplayer'),
	//     fixed: true,
	//     autoplay: false,
	//     theme: '#FADFA3',
	//     loop: 'all',
	//     order: 'random',
	//     preload: 'auto',
	//     volume: 0.7,
	//     mutex: true,
	//     listFolded: false,
	//     mini: false,
	//     audio: this.state.audios.map(item => ({
	//       theme: '#66ccff'
	//     }))
	//   })

	//   ap.on('play', () => {
	//     this.setState({ status: 'play' })
	//   })

	//   ap.on('pause', () => {
	//     this.setState({ status: 'pause' })
	//   })
	// }

	state = {
		audios: [],
		images: [],
		videos: [],
		filesData: [],
		descriptVisible: false,
		nowDescript: {},
		index: 0,
		timer: '',
		fileAmount: 0, // 文件数量
	}

	render() {
		const { filesData, index, descriptVisible } = this.state
		const component = this
		return (
			<div className='share-container'>
				<div className='pictures'>
					<div className='reveal'>
						<div className='slides'>
							<RViewerJS
								options={{
									title: 5,
									interval: 8000,
									viewed() {},
									play() {
										component.setState({
											descriptVisible: true,
										})
										this.timer = setInterval(() => {
											component.setState({
												index:
													component.state.index ===
													component.state.fileAmount - 1
														? 0
														: component.state.index + 1,
											})
										}, 8000)
									},
									stop() {
										clearInterval(this.timer)
										component.setState({
											index: 0,
											descriptVisible: false,
										})
									},
								}}>
								{filesData.map(file => {
									return (
										<img
											key={file.fileToken}
											src={`/api/file/download/${file.fileToken}`}
											alt=''
										/>
									)
								})}
							</RViewerJS>

							{
								<div
									className='descript-box'
									style={{
										visibility: descriptVisible
											? 'visible'
											: 'hidden',
										color: filesData[index]?.fontColor,
									}}>
									<div className='title'>{filesData[index]?.topic}</div>
									<div className='descript'>
										{filesData[index]?.description}
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
