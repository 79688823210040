import React, { Component, Fragment } from 'react'
import SearchForm from '@/Public/PubTable/searchForm'
import BaseTable from '@/Public/PubTable/baseTable'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { borrowEnum } from '@/Utils/Enum'
import { applicationBorrowColumns } from '@/Public/PubTable/pageColumns'
import { borrowUrl } from '@/Utils/Urls.js'
import { Button, Space, Modal, Badge } from 'antd'
import { connect } from 'react-redux'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'
// 更新红点函数
import { updataRedDots } from '@/Components/SlideBar/ActionCreator.js'
import {
	recallUseApplyThunk,
	deleteUseApplyThunk,
	getUseApplyDetailThunk,
	getMyUseResultThunk,
	getUserInfoThunk,
	routeChangeAction,
	pushRoutersAction,
	tips,
} from './ActionCreator'
import { is_my_file_detail } from '@/Redux/ActionTypes/Public/PubTable/publicActionCreator.js'

const markName = 'ApplicationBorrow' // redux的标识
const mapStateToProps = state => {
	return {
		Selections: state.pubTable.pub_rowSelection.ApplicationBorrow, // 获取选中数据
	}
}

const mapDispatchToProps = dispatch => ({
	recallUseApply: (...rest) => dispatch(recallUseApplyThunk(...rest)), // 撤回档案借阅申请
	deleteUseApply: (...rest) => dispatch(deleteUseApplyThunk(...rest)), // 删除借阅申请
	getUseApplyDetail: (...rest) => dispatch(getUseApplyDetailThunk(...rest)), //查看详情
	getMyUseResult: (...rest) => dispatch(getMyUseResultThunk(...rest)), //查看结果
	getUserInfo: (...rest) => dispatch(getUserInfoThunk(...rest)), // 获取已登录用户信息
	onChange(activeKey, history) {
		if (history) {
			history.push(linkTo.to(activeKey.key))
		} else {
			dispatch(routeChangeAction(activeKey.key))
		}
		dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
	},
	pushRouters(link) {
		return dispatch(pushRoutersAction(link))
	},
	isMyFileDetail: (...rest) => dispatch(is_my_file_detail(...rest)),
})
@connect(mapStateToProps, mapDispatchToProps)
@WrappedTable.create(markName)
class ApplicationBorrow extends Component {
	formRef = React.createRef()
	// 搜索表单项的配置
	searchFormConfig = {
		formItems: [
			{
				type: 'Extra',
				name: 'buttonList',
				span: 7,
				render: formRef => {
					return (
						<div>
							<Space style={{ marginTop: '3px' }}>
								<Button
									className='btn-success iconfont-buttons'
									title='申请'
									onClick={this.handleBorrowApply}>
									&#xeb61; 申请
								</Button>
								<Button
									className='btn-warning iconfont-buttons'
									title='撤回'
									onClick={this.handleWithdrawApply}>
									&#xe637; 撤回
								</Button>
								<Button
									className='btn-danger iconfont-buttons'
									title='删除'
									onClick={this.handleDeleteApply}>
									&#xe816; 删除
								</Button>
							</Space>
						</div>
					)
				},
			},
			{
				type: 'Input',
				name: 'applyUser',
				label: '申请人',
				placeholder: '请输入申请人',
			},
			{
				type: 'DatePicker',
				name: 'applyTime',
				label: '申请时间',
				placeholder: '请选择申请时间',
				width: '200px',
			},
			{
				type: 'Select',
				name: 'status',
				label: '处理状态',
				placeholder: '请输入处理状态',
				options: borrowEnum.status.toOptions(), //  申请的处理状态
				minWidth: '165px',
			},
		],
		ajaxConfig: {
			ajaxType: 'post',
			url: borrowUrl.getMyUseApplyList,
			ContentType: 'application/json',
		},
	}
	state = {
		records: [],
	}

	// 借阅申请
	handleBorrowApply = async () => {
		sessionStorage.setItem('formType', 0)
		this.props.isMyFileDetail(false)
		const link = {
			title: '借阅申请',
			key: '/workarea/commonComponents/borrowDetails',
		}
		this.props.getUserInfo().then(res => {
			sessionStorage.setItem('userInfo', JSON.stringify(res))
			this.props.onChange(link, history) // 修改路由
			this.props.pushRouters(link) // 添加分页栏
		})
	}

	// 弹出模态框（至少选择一项）
	showModel = () => {
		Modal.error({
			content: tips.CHOOSE_AT_LEAST_ONE,
			maskClosable: true,
		})
	}

	// 删除借阅申请
	handleDeleteApply = async () => {
		const {
			deleteUseApply, // 删除接口
			pub_getAllData,
			Selections,
		} = this.props
		if (Selections.length) {
			let applyIds = []
			if (!Selections.every(item => borrowEnum.status[item.status] === '申请')) {
				return Modal.error({
					content: tips.ONLY_APPLICATION_STATUS,
					maskClosable: true,
				})
			}
			applyIds = Selections.map(({ id }) => id)
			deleteUseApply(applyIds).then(() => {
				pub_getAllData()
			})
		} else {
			this.showModel()
		}
	}

	// 撤回借阅申请
	handleWithdrawApply = async () => {
		const { recallUseApply, pub_getAllData, Selections } = this.props

		if (Selections.length) {
			let applyIds = []
			if (!Selections.every(item => borrowEnum.status[item.status] === '待审批')) {
				return Modal.error({
					content: tips.ONLY_APPROVAL_STATUS,
					maskClosable: true,
				})
			}
			applyIds = Selections.map(({ id }) => id)
			recallUseApply(applyIds).then(() => {
				pub_getAllData()
			})
		} else {
			this.showModel()
		}
	}

	getStatus = status => {
		switch (status) {
			case 0:
				return '申请'
			case 1:
				return '待审批'
			case 2:
				return '同意'
			case 4:
				return '完成'
			default:
				return '延期' // case 5
		}
	}

	returnButton = record => {
		// 返回按钮数据
		const borrowLink = {
			title: '借阅申请',
			key: '/workarea/commonComponents/borrowDetails',
		}
		return (
			<Fragment>
				<Button
					onClick={() => {
						sessionStorage.setItem('borrowId', JSON.stringify(record.id))
						if (record.status === 4) {
							this.props.getMyUseResult(record.id).then(data => {
								// 说明审批不是拒绝状态，确实有返回借阅的文件
								if (data.length !== 0) {
									sessionStorage.setItem(
										'isExpired',
										JSON.stringify(data[0].expired)
									)
								} else {
									sessionStorage.setItem('isExpired', 0)
								}
							})
						}
						this.props.getUseApplyDetail(record.id).then(data => {
							data.applyTime = record.applyTime.includes('T')
								? record.applyTime.replace(/T(.*)/, '')
								: record.applyTime.replace(/\s(.*)/, '')
							data.statusName = this.getStatus(data.status)
							let storeBefore = JSON.stringify(data)
							sessionStorage.setItem('borrowDetails', storeBefore)
							this.props.onChange(borrowLink, history) // 更改路由
							this.props.pushRouters(borrowLink) // 添加分页
						})
						sessionStorage.setItem('formType', 1)
						// 更新红点
						if (record.statusChange === 1) {
							updataRedDots()
						}
					}}>
					查看
				</Button>
				{record.statusChange === 1 ? (
					<Badge dot={'false'} offset={[-6, -16]}></Badge>
				) : (
					''
				)}
			</Fragment>
		)
	}

	render() {
		return (
			<Fragment>
				<WrappedTable>
					<SearchForm
						name='expiredFiles-search-form'
						formItems={this.searchFormConfig}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						markName={markName}
					/>
					<BaseTable
						tableConfig={applicationBorrowColumns}
						actions={this.actions}
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
						operationButton={this.returnButton} // 自定义操作按钮
						markName={markName}
						withPreview={false}
					/>
				</WrappedTable>
			</Fragment>
		)
	}

	/**
	 * 在此进行对搜索数据的修改，并且将其返回后，会自动调用指定的接口
	 * @param {Object} data 表单收集到的所有字段数据
	 * @returns 修改后的表单数据字段
	 */
	changeSearchLimit = data => {
		return data
	}

	/**
	 * 修改搜索后获取的表格数据并返回
	 * @param {*} data 搜索后从后台接收的数据
	 * @returns 将data转为固定格式返回
	 */
	changeTableData = res => {
		return {
			data: res.records,
			pageSize: res.size,
			current: res.current,
			total: res.total,
		}
	}
}

export default ApplicationBorrow
