import { cloneDeep } from 'lodash'
import { idToRoute } from '@/Components/SlideBar/allMenuConfig'
import { cfUtils } from '@/Utils'
// 给一级路由添加跳转的路径firstRouteLink
let addFirstRouteLink = (slideBarItem, level) => {
	if (slideBarItem.id === 2 || slideBarItem.id === 40) {
		slideBarItem.firstRouteLink = slideBarItem.child[0].path
		slideBarItem.firstRouteLinkTitle = slideBarItem.child[0].title
	} else if (slideBarItem.id === 5 || slideBarItem.id === 52) {
		slideBarItem.firstRouteLink = slideBarItem.child[0].child[0].path
		slideBarItem.firstRouteLinkTitle = slideBarItem.child[0].child[0].title
	} else {
		let route = idToRoute.get(slideBarItem.id)
		slideBarItem.firstRouteLink = level === 1 ? route?.firstRouteLink : undefined
		slideBarItem.firstRouteLinkTitle = level === 1 ? route?.title : undefined
	}
}

// 将后台传的侧边栏的树处理成特定的格式
let filterSiderBarTree = slideBar => {
	slideBar.forEach((item, index) => {
		//TODO delete
		//console.log('running in filterSiderBarTree:', slideBar)
		if (item.display) {
			try {
				let route = idToRoute.get(item.id)
				item.title = item.name
				if (route) {
					item.path = route.path
					item.key = route.path
					item.icon = route.icon
					item.style = route.style
					item.img = route.img
					item.className = route.className
					item.contentClassName = route.contentClassName
				} else {
					// 如果后台数据乱 或者 新加页面，点到就404
					item.path = Math.floor(Math.random() * 100000).toString()
					item.key = Math.floor(Math.random() * 100000).toString()
				}
				if (item.child) {
					filterSiderBarTree(item.child)
				} else {
					item.firstRouteLink = route?.firstRouteLink
				}
			} catch (error) {}
		}
	})
}

// 过滤不显示的侧边栏
let filterNoDisplay = (slideBar, level = 1, result = []) => {
	slideBar = cloneDeep(slideBar)
	slideBar.forEach(item => {
		if (item.display) {
			result.push(item)
			if (item.child) {
				const child = item.child
				item.child = null
				item.child = filterNoDisplay(child, level + 1)
			}
			addFirstRouteLink(item, level)
		}
	})
	return result
}

// 过滤重复的侧边栏
// 本质：多维数组去重
let filterUnique = slideBar => {
	const { flatTree, listToTree } = cfUtils
	// 展成平面书
	let tmpSlideBar = flatTree(slideBar, item => item)
	// 用于判断重复的集合
	let idsArr = new Set()
	tmpSlideBar = tmpSlideBar.filter(item => {
		if (idsArr.has(item.id)) return false
		idsArr.add(item.id)
		return true
	})
	// 转成结构树
	return listToTree(tmpSlideBar)
}

/**
 * 返回处理后的一级路由
 * localStorage不能存放Map对象，故二级交给slideBar模块
 * @param {*} [slideBar=[]]
 * @return {*}
 */
export let newSlideBar = slideBar => {
	//格式化后台数据
	filterSiderBarTree(slideBar)
	//过滤不显示的Route
	let firstRoute = filterNoDisplay(slideBar)
	//去重
	firstRoute = filterUnique(firstRoute)
	localStorage.setItem('firstRoute', JSON.stringify(firstRoute))
	return firstRoute
}
