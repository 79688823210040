import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'

// 模态框表单的配置
const formItems = [
	[
		{
			type: 'TextArea',
			name: 'message',
			label: '回退意见',
			rules: [{ max: 1000 }],
			layout: {
				labelCol: { span: 5 },
				wrapperCol: { span: 19 },
			},
			placeholder: '请输入回退意见',
		},
	],
]

// 档案回退的模态框
export default class RollbackModal extends Component {
	render() {
		const { modalRef, onOk, hideCallback } = this.props
		return (
			<PubModalForm
				title='档案回退'
				ref={modalRef}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				okText='回退'
				onOk={onOk}
				width={500}
				formProps={{
					// 表单的配置项
					name: 'RollbackModalForm',
					formItems,
				}}
				hideCallback={hideCallback}
				zIndex={1001}
			/>
		)
	}
}
