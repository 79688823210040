import React, { Component } from 'react'
import E from 'wangeditor'
import _fetch from '@/Utils/Fetch'
import './index.less'
import Extend135Button from './135Button'
import CompositeSearchButton from '@/Public/CompositeSearch/CompositeSearchButton.jsx'
export default class MyEditor extends Component {
	editor = {}

	// 获取html方法2
	getHtml = () => {}

	componentWillUnmount() {
		this.editor.destroy()
	}

	componentDidMount() {
		this.editor = new E(document.getElementById('wangeditor'))
		this.editor.menus.extend('135', Extend135Button)
		this.editor.config.menus = this.editor.config.menus.concat('135')
		this.editor.config.pasteFilterStyle = false
		//上传视频
		this.editor.config.uploadVideoServer = '/api/richTextStudying/upload'
		this.editor.config.uploadVideoHeaders = {
			Authorization: JSON.parse(localStorage.getItem('userMessage')).token || '',
		}
		this.editor.config.uploadVideoName = 'file'
		this.editor.config.uploadVideoHooks = {
			// 视频上传并返回了结果，视频插入已成功
			success: function (xhr) {
				// //console.log('success', xhr)
			},
			// 视频上传并返回了结果，但视频插入时出错了
			fail: function (xhr, editor, resData) {
				// //console.log('fail', resData)
			},
			// 上传视频出错，一般为 http 请求的错误
			error: function (xhr, editor, resData) {
				// //console.log('error', xhr, resData)
			},
			// 上传视频超时
			timeout: function (xhr) {
				// //console.log('timeout')
			},
			// 视频上传并返回了结果，想要自己把视频插入到编辑器中
			// 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
			customInsert: function (insertVideoFn, result) {
				// result 即服务端返回的接口
				// //console.log('customInsert', result)
				// insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
				insertVideoFn(result.data[0].url)
			},
		}

		// 上传图片
		this.editor.config.uploadImgServer = '/api/richTextStudying/upload'
		this.editor.config.uploadImgHeaders = {
			Authorization: JSON.parse(localStorage.getItem('userMessage')).token || '',
		}
		this.editor.config.uploadFileName = 'file'
		this.editor.config.showFullScreen = false
		this.editor.config.uploadImgTimeout = 60 * 1000
		this.editor.config.height = 400
		this.editor.config.width = 1000
		this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
			// resultFiles 是 input 中选中的文件列表
			// insertImgFn 是获取图片 url 后，插入到编辑器的方法
			resultFiles.forEach(file => {
				const formData = new FormData()
				formData.append('file', file)
				_fetch({
					url: `/api/richTextStudying/upload`,
					type: 'post',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}).then(res => {
					insertImgFn(res.data.data[0].url)
				})
			})
		}
		/**一定要创建 */
		this.editor.create()
	}

	handleCancel = () => {}

	// 获取视频token
	getVideoToken = id => {
		let p = new Promise((resolve, reject) => {
			_fetch({
				url: `/api/archive/getArchiveDetail`,
				type: 'get',
				data: {
					id,
				},
			}).then(res => {
				resolve(res.data.data.fileToken.split('token=')[1])
			})
		})
		return p
	}

	// 获取添加进编辑器的档案内部文件
	getAddData = datarecords => {
		const tokenList = datarecords.map(item => {
			if (item.fileType.includes('video')) {
				let videoToken = this.getVideoToken(item.id)
				return videoToken
			}
			return item.fileToken.split('token=')[1]
		})

		Promise.all(tokenList).then(tokenList => {
			// tokenList包含所有的图片token和视频token
			_fetch({
				url: `/api/richTextStudying/uploadByToken`,
				type: 'post',
				data: {
					tokenList,
				},
				isJson: true,
			}).then(res => {
				const fileData = res.data.data
				fileData.forEach(item => {
					if (item.url.includes('mp4')) {
						this.editor.txt.append(`<p data-we-empty-p>
                        <video src="${item.url}" controls="controls" style="max-width:100%"></video>

                        `)
					} else {
						this.editor.txt.append(`<p data-we-empty-p>
                        <img src="${item.url}">
                        </p>`)
					}
				})
			})
		})

		// resultFiles 是 input 中选中的文件列表
		// insertImgFn 是获取图片 url 后，插入到编辑器的方法
	}

	render() {
		return (
			<div>
				<div id='wangeditor'></div>
				<div className='addFileBtn'>
					<CompositeSearchButton
						hoverTitle={'添加文件'}
						btnTitle={'添加文件'}
						getAddData={this.getAddData}
					/>
				</div>
			</div>
		)
	}
}
