import { Modal, Table } from 'antd'
import React, { Component, Fragment } from 'react'

const columns = [
	{
		title: '序号',
		dataIndex: 'id',
		key: 'id',
		render: (item, records, index) => {
			return (
				<div>
					<span>{index + 1}</span>
				</div>
			)
		},
	},
	{ title: '处理人', dataIndex: 'name', key: 'name' },
	{ title: '处理人部门', dataIndex: 'department', key: 'department' },
	{ title: '处理意见', dataIndex: 'result', key: 'result' },
	{
		title: '完成时间',
		dataIndex: 'createTime',
		key: 'createTime',
		render: text => {
			return <div>{text.split('T')[0]}</div>
		},
	},
]

export default class TableLogModal extends Component {
	state = {
		visible: false,
		records: [],
		total: 0,
		loading: true,
	}

	componentDidMount() {
		const current = 1
		this.getAprovalLogData(current)
	}

	getAprovalLogData = async current => {
		this.setState({
			loading: true,
		})
		const res = await this.props.approvalLog(current)
		const { records, total } = res.data
		this.setState({
			records,
			total,
			loading: false,
		})
	}

	show = () => {
		this.setState({
			visible: true,
		})
	}

	handleCancel = e => {
		this.setState({
			visible: false,
		})
	}

	render() {
		const { records, total, loading } = this.state
		records.forEach(item => {
			item.key = item.id
		})
		return (
			<Fragment>
				<Modal
					title='查看审批日志'
					visible={this.state.visible}
					onCancel={this.handleCancel}
					width={1200}
					footer={
						[] // 设置footer为空，去掉 取消 确定默认按钮
					}>
					<Table
						dataSource={records}
						columns={columns}
						pagination={{
							total,
						}}
						loading={loading}
						onChange={pagination => {
							this.getAprovalLogData(pagination.current)
						}}
					/>
				</Modal>
			</Fragment>
		)
	}
}
