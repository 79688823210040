/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'
import { Checkbox } from 'antd'

const { Group } = Checkbox
/**
 * 穿梭框左侧的多选框组
 * @param {*} props
 * @returns
 */
function CheckGroup(props) {
	const { dataSource, onItemSelect, selectedKeys } = props

	const boxChange = event => {
		const target = event.target
		onItemSelect(target.value, target.checked)
	}

	const CheckBoxs = useMemo(() => {
		return dataSource.map(item => (
			<div key={item.key} className='order-line'>
				<Checkbox value={item.value} onChange={boxChange}>
					<p className='order-group-p'>
						{item.label}
						{item.editable &&
							(!item.number || item.number <= 0) &&
							'（待编辑）'}
						{item.number > 0 && `（${item.number}位）`}
					</p>
				</Checkbox>
			</div>
		))
	}, [dataSource])
	return (
		<Group
			name='order'
			className='order-group'
			value={selectedKeys} // 控制多选框组，哪些被选中
		>
			{CheckBoxs}
		</Group>
	)
}

export default React.memo(CheckGroup)
