import React, { PureComponent } from 'react'
import { Modal, Spin, Tree } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import {
	getFullSidebarTree,
	authorizeThunk,
	getSidebarIdsByRoleId,
} from '../ActionCreators'
import { cloneDeep } from 'lodash'

class Authorization extends PureComponent {
	state = {
		visible: false, // 模块是否显示
		treeSpinning: false, // 树是否加载中
		expandedKeys: [], // 展开的节点
		autoExpandParent: true, // 是否自动展开父节点
		flatTreeData: [], // 树的扁平化数据
		searchValue: '', // 搜索内容
		treeData: [],
		checkedKeys: [], // 复选框选中的节点
		selectionData: {}, // 表格选中的角色
	}

	onHide = () => {
		this.setState({ visible: false, checkedKeys: [] })
	}

	// 确定授权时，触发的函数
	onOk = () => {
		const { reflashTable } = this.props
		const { checkedKeys, selectionData } = this.state
		let sidebarIds = cloneDeep(checkedKeys)
		sidebarIds.push(1) // 添加被隐藏的sidebarIds = 1的“一定要有的权限”
		authorizeThunk(
			{
				roleId: selectionData.id,
				sidebarIds,
			},
			{ reflashTable }
		)
		this.onHide()
	}

	// 授权模态框的显示
	show = async (selectionData, callback) => {
		const sidebarData = (await getSidebarIdsByRoleId(selectionData.id)) || []
		this.setState({ visible: true, checkedKeys: sidebarData, selectionData })
		callback && callback()
	}

	// 处理树节点，以适应<Tree/>组件
	modifyTree = tree => {
		if (!tree) return
		tree = tree.filter(item => {
			if (item.child) {
				this.modifyTree(item.child)
			}
			item.key = item.id
			item.children = item.child
			item.title = item.name
			// 去掉id = 1的“一定要有的”
			return item.id !== 1
		})
		return tree
	}

	// 获取树节点数据
	createTreeData = async () => {
		await this.setState({ treeSpinning: true })
		const data = await getFullSidebarTree()
		if (data.code === 200) {
			const tree = this.modifyTree(data.data)
			localStorage.setItem('fullSidebarTree', JSON.stringify(tree))
			await this.setState({ treeData: tree })
		}
		await this.setState({ treeSpinning: false })
	}

	componentWillMount() {
		const fullSidebarTree = JSON.parse(localStorage.getItem('fullSidebarTree'))
		if (!fullSidebarTree) {
			this.createTreeData()
		} else {
			this.setState({ treeData: fullSidebarTree })
		}
	}

	/**
	 * 扩展节点时触发的函数
	 * @param {Array} expandedKeys 目前展开的所有节点
	 */
	onExpand = expandedKeys => this.setState({ expandedKeys, autoExpandParent: false })

	/**
	 *
	 * 选中复选框时，添加到state中
	 * @param {*} checkedKeys  复选框选中的keys
	 * @param {*} e antd定义的event
	 */
	onCheck = (checkedKeys, e) => {
		this.setState({ checkedKeys })
	}

	render() {
		const {
			treeSpinning,
			expandedKeys,
			checkedKeys,
			autoExpandParent,
			treeData,
			selectionData: { description },
		} = this.state
		const { title, checkable = true } = this.props
		return (
			<Modal
				title={`${title}（${description}）`}
				visible={this.state.visible}
				onCancel={this.onHide}
				onOk={this.onOk}
				mask={false}>
				<Spin spinning={treeSpinning}>
					<Tree
						onExpand={this.onExpand} // 节点扩展调用函数
						onCheck={this.onCheck} // 节点复选框被选中时，触发的函数
						checkedKeys={checkedKeys} // 复选框选中的节点
						expandedKeys={expandedKeys} // 扩展的节点
						autoExpandParent={autoExpandParent} // 是否自动展开父节点
						showLine={false} // 不要对齐线
						showIcon // 展示自己的图标
						switcherIcon={<DownOutlined />} // 节点头的图标
						blockNode // 每个node占据整行
						treeData={treeData} // 树的数据
						checkable={checkable} // 是否启用复选框
					/>
				</Spin>
			</Modal>
		)
	}
}

export default Authorization
