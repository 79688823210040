import { FILECREATOR_REFRESH } from '@/Redux/ActionTypes/FileCreator/index'
import InitialState from '@/Redux/InitialState'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = InitialState.fileCreator, action) => {
	switch (action.type) {
		case FILECREATOR_REFRESH: {
			let newState = { ...state }
			newState['created'] = Symbol('refresh')
			return newState
		}
		default: {
			return state
		}
	}
}
