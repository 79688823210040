import Enum from '@/Utils/Enum'
import { ROUTE_CHANGE, TAB_ADD } from '@/Redux/ActionTypes/Route'
import {
	ADD_BORROW_APPROVAL, // 添加修正表详情
} from '@/Redux/ActionTypes/BorrowApproval'
import { borrowUrl, myApprovalBorrowUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
export const statusEnum = new Enum({
	'-1': '待处理',
	1: '待审批',
	2: '同意',
	// 3: '拒绝',
	4: '完成',
})

export const delayEnum = new Enum({
	0: '未申请延期',
	1: '申请延期',
})

export const routeChangeAction = routeData => {
	return {
		type: ROUTE_CHANGE,
		filter: routeData,
	}
}

/**
 * 给redux管理的state里的route的items添加一个item，对应新增一个tab栏
 *
 * @param {*} data
 */
export const pushRoutersAction = data => {
	return {
		type: TAB_ADD,
		filter: data,
	}
}

// 给申请表添加详情
export const addBorrowApprovalAction = data => {
	return {
		type: ADD_BORROW_APPROVAL,
		data: data,
	}
}

export const approvalLogThunk = current => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: myApprovalBorrowUrl.getUseReview,
			type: 'get',
			data: { current, size: 10 },
			isJSON: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

/**
 * 获取借阅申请审批的详情列表
 * @param {Number} id 申请表id
 */
export const getUseReviewDetailThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: borrowUrl.getUseReviewDetail,
			data: {
				id,
			},
			type: 'get',
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			} else {
				reject()
			}
		})
	})
