import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { getMyThemeThunk, pushRoutersAction } from './ActionCreators'
import EventBus from '@/Utils/pubsub'

import { Menu } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { history } from '@/index'
import { linkTo } from '@/Routes/ManageRouter'

const mapStateToProps = state => ({
	selectedTheme: state.pubTable.pub_rowSelection.ThemeManage,
})
const mapDispatchToProps = dispatch => ({
	getMyTheme: (...rest) => dispatch(getMyThemeThunk(...rest)), // 获取主题
	pushRouters: route => dispatch(pushRoutersAction(route)),
})

@connect(mapStateToProps, mapDispatchToProps)
class CollectedFileTheme extends PureComponent {
	state = {
		theme: [],
		key: [
			(this.props.selectedTheme && this.props.selectedTheme.toString() !== ''
				? this.props.selectedTheme[0].themeId
				: 0) + '',
		],
	}

	componentDidMount() {
		this.props.onRef(this) //将自身绑定在父组件上
		this.props.getMyTheme().then(theme => {
			this.setState({
				theme,
			})
		})
	}

	clickMenuItem = ({ item, key }) => {
		//派发事件，将themeId暴露出去
		EventBus.dispatchEvent('clickMenuItem', { themeId: key })
	}

	themeTitleClick = () => {
		history.push(linkTo.to('/collectedFiles/themeManage'))
		this.props.pushRouters({
			title: '主题管理',
			key: '/collectedFiles/themeManage',
		})
	}

	render() {
		return (
			<>
				<h3
					className='iconfont-buttons'
					style={{ fontWeight: 'bolder' }}
					onClick={this.themeTitleClick}
					title='主题管理'>
					<SettingOutlined />
				</h3>
				<Menu onClick={this.clickMenuItem} defaultSelectedKeys={this.state.key}>
					<Menu.Item key={'0'}>全部</Menu.Item>
					{this.state.theme.map(theme => {
						return (
							<Menu.Item key={theme.themeId}>
								{theme.topic}
								<span style={{ fontWeight: 'bold' }}>
									{' '}
									({theme.count})
								</span>
							</Menu.Item>
						)
					})}
				</Menu>
			</>
		)
	}
}

export default CollectedFileTheme
