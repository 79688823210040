import { userManagerUrl, roleManagerUrl, departmentUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'
import { timeUtil } from '@/Utils'
import { message } from 'antd'
// 删除角色的thunk
export const deleteUserThunk = params => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: userManagerUrl.deleteUser,
			type: 'get',
			data: {
				...params,
			},
			isJson: true,
		}).then(res => {
			res.data.success ? resolve(res) : reject(res)
		})
	})

// 新增用户thunk
export const addUserThunk =
	(formData, { finish, hide, reflashTable }) =>
	() => {
		formData.validTime &&
			(formData.validTime = timeUtil.toTimestamp(formData.validTime))
		_fetch({
			url: userManagerUrl.addUser,
			type: 'post',
			data: {
				...formData,
			},
			isJson: true,
		}).then(res => {
			if (res.data.code === 200) {
				// 重新刷新表格
				reflashTable()
				// 结束按钮loading状态
				finish()
				// 关闭modal
				hide()
				message.success('新增成功')
			}
		})
	}

// 修改用户信息thunk
export const editUserThunk =
	(formData, { finish, hide, reflashTable }) =>
	() => {
		formData.validTime &&
			(formData.validTime = timeUtil.toTimestamp(formData.validTime))
		_fetch({
			url: userManagerUrl.editUser,
			type: 'post',
			data: {
				...formData,
			},
			isJson: true,
		}).then(res => {
			if (res.data.code === 200) {
				// 重新刷新表格
				reflashTable()
				// 结束按钮loading状态
				finish()
				// 关闭modal
				hide()
				message.success('编辑成功')
			}
		})
	}

// 导出excel模板
export const downloadModelThunk = () => () => {
	_fetch({
		url: userManagerUrl.exportUserTemplate,
		type: 'get',
		responseType: 'blob',
	}).then(res => {
		const blob = res.data
		const urlObject = window.URL || window.webkitURL || window
		const export_blob = new Blob([blob], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		})
		const a = document.createElement('a') // 利用a标签特性下载
		a.style.display = 'none'
		const url = urlObject.createObjectURL(export_blob)
		a.href = url
		a.setAttribute('download', '用户模板.xlsx')
		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)
		message.success('成功导出模板！')
	})
}

// 导出用户的excel
export const downloadExcelThunk = data => () => {
	// 要按顺序传入参数噢
	_fetch({
		url: userManagerUrl.exportUser,
		type: 'post',
		headers: {
			'Content-Type': 'application/json',
		},
		responseType: 'blob',
		data,
	}).then(res => {
		const blob = res.data
		const urlObject = window.URL || window.webkitURL || window
		const export_blob = new Blob([blob], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		})
		const a = document.createElement('a') // 利用a标签特性下载
		a.style.display = 'none'
		const url = urlObject.createObjectURL(export_blob)
		a.href = url
		a.setAttribute('download', '用户.xlsx')
		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)
		message.success('成功导出！')
	})
}
// 给用户添加角色
export const addUserRoleThunk =
	({
		userId, // 用户id
		roleIdList, // 角色ids
	}) =>
	() =>
		new Promise((resolve, reject) => {
			_fetch({
				url: userManagerUrl.addUserRole,
				type: 'post',
				isJson: true,
				data: {
					userId,
					roleIdList,
				},
			})
				.then(res => {
					if (res.data.code === 200) {
						resolve()
					} else {
						reject()
					}
				})
				.catch(() => {
					reject()
				})
		})

// 删除用户角色
export const deleteUserRoleThunk =
	({
		userId, // 用户id
		roleIdList, // 角色id
	}) =>
	() =>
		new Promise((resolve, reject) => {
			_fetch({
				url: userManagerUrl.deleteUserRole,
				type: 'post',
				isJson: true,
				data: {
					userId,
					roleIdList,
				},
			})
				.then(res => {
					if (res.data.code === 200) {
						resolve()
					} else {
						reject()
					}
				})
				.catch(() => {
					reject()
				})
		})

// 获取所有角色
export const getAllRoleThunk = () => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: roleManagerUrl.getRole,
			type: 'get',
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 获取用户已有的角色
export const getUserRoleThunk = userId => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: userManagerUrl.getUserRole,
			type: 'get',
			data: { userId },
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

/**
 * 获取部门树
 * @param {Number} id 部门id（不填查询所有的部门）
 */
export const getDepartmentTreeThunk = id => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: departmentUrl.getDepartmentTree,
			data: { id },
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data.data)
			}
		})
	})
