import React from 'react'
import { Radio } from 'antd'

/**
 * 最基本的单选框
 * @param {Object} config formItem的配置对象
 */
export const createRadio = (config, formRef) => (
	<Radio.Group
		options={config.options}
		onChange={config.onChange ? config.onChange : () => {}}
		style={config.style}
		optionType={config.optionType}
	/>
)

/* export default {
  createRadio
} */
