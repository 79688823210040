import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { message } from 'antd'
const formLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 10 },
}
// 表单项的单独配置，优先级高于表单项的统一配置
const formItemLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}

// PubForm 表单项配置数组
let formItems = [
	[
		{
			type: 'Input',
			name: 'columnName',
			label: '列名',
			layout: formItemLayout,
			placeholder: '请输入新增列名',
			style: {
				width: 250,
				display: 'inline',
			},
		},
	],
	[
		{
			type: 'Input',
			name: 'dataType',
			label: '数据类型:',
			layout: formItemLayout,
			placeholder: '请输入列的数据类型',
			style: {
				width: 250,
				display: 'inline',
			},
		},
	],
]

export default class modal extends Component {
	state = {
		visible: false,
		title: '新增列',
	}

	setButton = () => {
		const { addColumn, pub_getAllData } = this.props
		//配置按钮项
		let buttonList = [
			{
				text: '保存',
				buttonProps: {
					type: 'primary',
				},
				onClick: async (formData, { finish, hide }) => {
					// 点击新增事件

					formData.tableName =
						localStorage.getItem('SelectedDataTable') !== ''
							? localStorage.getItem('SelectedDataTable')
							: 'archive'

					addColumn(formData)
						.then(res => {
							hide()
							pub_getAllData() // 刷新表格
						})
						.catch(err => {
							finish()
						})
				},
			},
		]
		return buttonList
	}

	render() {
		const { title } = this.state
		const { modalRef } = this.props
		return (
			<PubModalForm
				ref={modalRef}
				title={title}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				buttonList={this.setButton()} // Modal 底部按钮配置列表,
				width={500} // 默认值900
				formProps={{
					// 表单的配置项
					name: 'PubFormDemo',
					formItems,
					layout: formLayout,
				}}
			/>
		)
	}
}
