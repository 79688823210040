import React, { Component } from 'react'
import PubArchive from '@/Public/PubArchive'
// import { dossierEnum } from '@/Utils/Enum';
import { connect } from 'react-redux'
import { Space, Button, message, Modal, Spin } from 'antd'
import './index.less'
import ButtonList from '@/Public/ButtonList'
import {
	routeChangeAction,
	pushRoutersAction,
	getDetailThunk,
	addPubFileAction,
	updateRecordThunk,
	getArchiveListThunk,
	updateAttachmentThunk,
	setNumForArchiveThunk,
} from './ActionCreator'
import { inititalPubArchiveAction } from '@/Public/PubTable/baseTable/ActionCreator'
import { linkTo } from '@/Routes/ManageRouter'
import { history } from '@/index'
import _ from 'lodash'
import { RedoOutlined } from '@ant-design/icons'
import { openWarcUrl } from '@/Public/FilePreviewer/Warc'

const markName = 'dossierDetails'
// PubForm 表单项配置数组
const formItems = [
	[
		{
			type: 'Input',
			name: 'yearNumber',
			picker: 'year',
			label: '年度',
			placeholder: '无',
			rules: [{ required: false }],
			bordered: false,
		},
	],
	[
		{
			type: 'InputWithPicker',
			name: 'recordUserAndTime',
			keys: ['recordUser', 'recordTime'],
			placeholder: '无',
			label: '著录',
			rules: [{ required: false }],
			bordered: false,
			style: { inputStyle: { width: 70 }, pickerStyle: { width: 120 } },
		},
	],
	[
		{
			type: 'InputWithPicker',
			name: 'archiveUserAndTime',
			keys: ['archiveUser', 'archiveTime'],
			placeholder: '无',
			label: '归档',
			rules: [{ required: false }],
			bordered: false,
			style: { inputStyle: { width: 70 }, pickerStyle: { width: 120 } },
		},
	],
	[
		{
			type: 'InputWithPicker',
			name: 'storageUserAndTime',
			keys: ['storageUser', 'storageTime'],
			placeholder: '无',
			label: '入库',
			rules: [{ required: false }],
			bordered: false,
			style: { inputStyle: { width: 70 }, pickerStyle: { width: 120 } },
		},
	],
]
let pageCurrent = 1
const mapStateToProps = state => {
	return {
		pubArchive: state.pubArchive,
		route: state.route.item,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		getDetail: (...rest) => dispatch(getDetailThunk(...rest)),
		inititalPubArchive: (markName, pubArchive) =>
			dispatch(inititalPubArchiveAction(markName, pubArchive)),
		onChange(activeKey, history) {
			if (history) {
				history.push(linkTo.to(activeKey.key))
			} else {
				dispatch(routeChangeAction(activeKey.key))
			}
			dispatch({ type: 'ROUTE_CHANGE', filter: activeKey.key })
		},
		pushRouters(link) {
			return dispatch(pushRoutersAction(link))
		},
		addPubFileData(data) {
			return dispatch(addPubFileAction(data)) // 添加Action方法，用于添加文件详情分栏
		},
		// 更新案卷信息
		updateRecord: (...rest) => dispatch(updateRecordThunk(...rest)), //更新案卷信息
		getArchiveList: (...rest) => dispatch(getArchiveListThunk(...rest)),
		updateAttachment: (...rest) => dispatch(updateAttachmentThunk(...rest)),
		setNumForArchive: (...rest) => dispatch(setNumForArchiveThunk(...rest)),
	}
}
@connect(mapStateToProps, mapDispatchToProps)
class DossierDetails extends Component {
	state = {
		edit: false, // 用于标识编辑状态
		visible: false, // modal是否显示
		isOpen: false, // 是否展开更多按钮
		notShow: true, // 不显示ButtonList本身的"更多"按钮
		spinning: false,
	}

	componentWillMount() {
		const dossierData = this.props.pubArchive[markName]
		// 判断新旧是否相等，不相等进入编辑状态
		if (!_.isEqual(dossierData?.new, dossierData?.old)) {
			this.setState({ edit: true })
		}
	}

	// 设置案卷详情的表单值
	setFormRecord = pub_dossierDetails => {
		const {
			recordTime,
			recordUser,
			archiveTime,
			archiveUser,
			storageTime,
			storageUser,
		} = pub_dossierDetails
		// 著录人和时间
		pub_dossierDetails['recordUserAndTime'] = { recordUser, recordTime }
		// 归档人和时间
		pub_dossierDetails['archiveUserAndTime'] = { archiveUser, archiveTime }
		// 入库人和时间
		pub_dossierDetails['storageUserAndTime'] = { storageUser, storageTime }
		return pub_dossierDetails
	}

	// 保存时触发的函数
	saveButtonHandle = async () => {
		if (this.formRef?.separateDossierAfterSaveAction) {
			this.formRef.separateDossierAfterSaveAction()
			this.setState({
				edit: false,
			})
			return
		}
		const { inititalPubArchive, getPubArchiveData } = this.formRef
		let pubArchiveData = await getPubArchiveData()
		const filesData = pubArchiveData?.new.filesData // 新信息文件列表
		const oldData = pubArchiveData?.old // 旧版信息
		const { updateRecord, getDetail, setNumForArchive } = this.props
		await this.formRef.validateFields()
		let formData = this.formRef.getFieldValue()
		let formMetadata = this.formRef.props.formItems
			.map(obj => {
				return obj.filter(item => item.pathId === 166) //筛选出pathId的元数据
			})
			.filter(item => item.length !== 0) //以上的筛选结果包含空数组，这里把空数组删除

		//console.log(formMetadata)
		//console.log(formData)
		let i = 0
		let updatedMetadata = formMetadata
			.map(item => {
				let index = item[0].name
				return {
					metadataId: index,
					metadataValue: formData[index],
				}
			})
			.filter(item => item.metadataValue !== undefined)

		//console.log(updatedMetadata)
		// 整理文件列表
		let ids = [],
			numbers = []
		filesData.forEach((item, i) => {
			ids.push(item.id)
			numbers.push(i)
		})
		if (!ids[0]) {
			message.info('文件不能为空')
			return
		}
		// 整理请求数据
		const requestData = {
			id: oldData.archiveData.id,
			archiveIds: ids,
			confidentialLevel: formData.confidentialLevel,
			categoryCodeId: formData.categoryCodeId,
			retentionPeriod: formData.retentionPeriod,
			departmentId: formData.departmentId,
			topic: formData.topic,
			introduction: formData.introduction,
			keyWord: formData.keyWord,
			archiveUserId: formData.archiveUserId,
			platform: formData.platform,
			metadata: updatedMetadata,
		}

		//console.log(requestData)
		await updateRecord(requestData)
		const setNumRequestData = {
			dossierId: oldData.archiveData.id,
			archiveIds: ids,
			numbers,
		}
		await setNumForArchive(setNumRequestData)
		await this.setState({ edit: false })
		// 重新请求刷新
		// 请求详情数据
		const data = await getDetail(oldData.archiveData)
		data['route'] = oldData.archiveData
		data.archiveDetail?.forEach(archiveData => {
			const { fileToken, fileType, fileName } = archiveData
			// 设置预览图
			archiveData['preview'] = {
				type: 1,
				fileData: {
					fileToken,
					fileType,
					fileName,
				},
			}
			// 每个来个key
			archiveData['key'] = archiveData.id
		})
		inititalPubArchive({
			filesData: data.archiveDetail || [],
			archiveData: this.setFormRecord(data),
		})
		//改变行选状态，不可以行选
		this.setState({
			edit: false,
		})
	}

	// 是否展开更多按钮的函数
	handleEmail = Open => {
		this.setState({ isOpen: !Open })
	}
	changSpinning = isSpinning => {
		this.setState({ spinning: isSpinning })
	}

	// 渲染按钮列表的函数
	renderBottomButtons = formRef => {
		const { edit } = this.state
		const oldData = this.props.pubArchive[markName]?.old // 旧版信息
		const waitForVerifyUser =
			this.props.pubArchive.dossierDetails?.old.archiveData.waitForVerifyUser // 当前被点击的文件&&案卷的被指派者id
		const userId = JSON.parse(localStorage.getItem('userMessage')).user.id // 当前操作者id
		return (
			<Space style={{ marginBottom: 20 }}>
				{/* 非编辑状态显示 */}
				{!edit && (
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'center',
						}}>
						{/* 著录 审核状态显示 */}
						{oldData?.archiveData.status === 0 ||
							oldData?.archiveData.status === 3 ||
							(oldData?.archiveData.status === 12 &&
								waitForVerifyUser === userId) ? (
							<Button
								style={{ marginRight: 10, marginTop: '5px' }}
								className='iconfont-buttons'
								type='primary'
								title='编辑'
								onClick={() => {
									this.setState({
										edit: true,
									})
								}}>
								编辑
							</Button>
						) : null}
						<ButtonList
							notShow={this.state.notShow}
							handleEmail={this.handleEmail}
							isEdit={edit}
							style={{ display: 'flex', flexWrap: 'wrap' }}
							changSpinning={this.changSpinning}
						/>
					</div>
				)}
				{/* 编辑状态显示 */}
				{edit && (
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						<Button
							onClick={() => {
								let rollBackData = {
									archiveData: oldData.archiveData,
									filesData: oldData.filesData,
								}
								this.formRef.upgradePubArchive(rollBackData)
								this.setState({
									edit: false,
								})
							}}>
							取消
						</Button>
						<Button
							type='primary'
							onClick={this.saveButtonHandle}
							style={{ marginRight: '5px' }}>
							保存
						</Button>
						<ButtonList isEdit={edit} />
					</div>
				)}
			</Space>
		)
	}

	// 头部左边的按钮
	renderLeftButtons = formRef => {
		return (
			<Button
				className='refresh-button'
				type='primary'
				title='刷新表格'
				onClick={() => {
					if (this.props.pubArchive.dossierDetails?.old.archiveData) {
						this.props
							.getArchiveList(
								this.props.pubArchive.dossierDetails?.old.archiveData
							)
							.then(archiveList => {
								if (archiveList.length !== 0) {
									archiveList.forEach(archiveData => {
										const { fileToken, fileType, fileName } =
											archiveData
										// 设置预览图
										archiveData['preview'] = {
											type: 1,
											fileData: {
												fileToken,
												fileType,
												fileName,
											},
										}
										// 每个来个key
										archiveData['key'] = archiveData.id
									})
									this.formRef.upgradePubArchive({
										filesData: [],
										archiveData:
											this.props.pubArchive.dossierDetails.new
												.archiveData,
									})
									// 重新载入数据
									this.formRef.upgradePubArchive({
										filesData: archiveList || [],
										archiveData:
											this.props.pubArchive.dossierDetails.new
												.archiveData,
									})
								}
							})
					}
				}}>
				<RedoOutlined />
			</Button>
		)
	}

	// 点击查看文件详情
	jumpToFileDetail = async (record, index, route) => {
		const { addPubFileData, getDetail, onChange, pushRouters } = this.props
		const dossierData = this.props.pubArchive[markName].new
		const link = {
			title: '文件详情',
			key: '/workarea/commonComponents/fileDetails',
		}
		let data = await getDetail(record) // 请求详情数据
		data['archiveList'] = dossierData?.filesData
		data['route'] = route || this.props.route // 由此可以知道从哪打开详情，并判断是否添加按钮
		data['startIndex'] = (pageCurrent - 1) * 10 + index // 跳转文件详情第几张图片
		data['fatherDossierId'] = dossierData?.archiveData.id //由此可以知道父级的案卷是哪一个
		dossierData?.archiveData.attachmentList.forEach(item => {
			if (item.fileToken?.includes('pdf')) {
				data['fatherDossierFileToken'] = item.fileToken
			}
		}) // 由于后台做了更改，在此获取附录中的pdf路径，用于warc类型pdf的展示
		addPubFileData(data) // 文件详情传到redux
		onChange(link, history) // 修改路由
		pushRouters(link) // 添加分页栏
	}

	// Table中的按钮
	renderOperation = (text, record, index) => {
		const dossierData = this.props.pubArchive[markName].new
		return this.state.edit ? (
			<div className='edit-button'>
				{(record.status === 0 || record.status === 3) && (
					<Button
						type='primary'
						style={{ color: 'white', width: '45%', textAlign: 'center' }}
						className='iconfont-buttons'
						title='编辑'
						onClick={() => {
							this.jumpToFileDetail(
								record,
								index,
								'/Manager/newConstruction/UrlToArchives'
							)
						}}>
						&#xeabd;{' '}
					</Button>
				)}
				<Button
					className='btn-danger iconfont-buttons'
					style={{ width: '45%', textAlign: 'center' }}
					onClick={() => {
						const filesLength = dossierData.filesData.length
						let tmpFilesData = dossierData.filesData.filter(item => {
							return item.id !== record.id
						})
						let tmpPubArchive = {
							archiveData: dossierData.archiveData,
							filesData: tmpFilesData,
						}
						tmpFilesData.length !== filesLength &&
							this.formRef.upgradePubArchive(tmpPubArchive)
					}}>
					&#xe816;{' '}
				</Button>
			</div>
		) : (
			<Button
				onClick={() => {
					this.jumpToFileDetail(record, index)
				}}>
				查看
			</Button>
		)
	}

	// 点击图片跳转文件详情
	clickPhoto = (record, index) => {
		record.fileType === 'text/html'
			? openWarcUrl(record.fileId)
			: this.jumpToFileDetail(record, index)
	}

	// 获取表单的实例
	getInstance = async formRef => {
		this.formRef = formRef
		if (!formRef) return
	}

	// 判断文本框是否过长
	isTooLong = e => {
		const target = e.target
		const nodeName = target.nodeName.toLowerCase()
		if (nodeName === 'textarea') {
			if (target.disabled) {
				const inputValue = target.value
				if (inputValue.length > 52) {
					return true
				}
			}
		}
		return false
	}

	// 点击文本框
	clickTextArea = e => {
		if (this.isTooLong(e)) {
			let modalTitle = e.target.getAttribute('label')
			let content = e.target.value
			this.setState({
				modalTitle,
				content,
				visible: true,
			})
		}
	}

	// 关闭模态框
	handleCancel = () => {
		this.setState({
			modalTitle: '',
			content: '',
			visible: false,
		})
	}

	// 附件的
	changeAttachments = (attachmentList, documentId, callback) => {
		const { updateAttachment, record } = this.props // 从actionCreators里面引入的更新附件的函数
		// 如果有新的附件再发送请求
		// 过滤值为null的附件。已有的附件值都为null
		attachmentList = attachmentList.filter(item => item !== null)
		updateAttachment({
			fileIds: attachmentList,
			type: 0, // 表示案卷的类型
			documentId,
		})
			.then(() => {
				message.destroy()
				message.success('保存成功')
				this.changeItem(record)
			})
			.catch(() => {
				message.destroy()
				message.error('保存失败')
			})
			.finally(() => {
				callback && callback() // 这个一定要加
			})
	}

	// 改变页面中的项时发送数据到redux
	changeItem = record => {
		const { getDetail, addPubFileData, getArchiveList, inititalPubArchive } =
			this.props
		getDetail(record).then(data => {
			// 请求详情数据
			//console.log(data)
			if (record.type) {
				addPubFileData(data) // 文件详情传到redux
			} else {
				getArchiveList(record).then(archiveList => {
					archiveList.forEach(archiveData => {
						const { fileToken, fileType, fileName } = archiveData
						archiveData['preview'] = {
							type: 1,
							fileData: {
								fileToken,
								fileType,
								fileName,
							},
						}
						archiveData['key'] = archiveData.id
					})
					inititalPubArchive('dossierDetails', {
						filesData: archiveList || [],
						archiveData: this.setFormRecord(data),
					})
				})
			}
		})
	}

	setFormRecord = pub_dossierDetails => {
		const {
			recordTime,
			recordUser,
			archiveTime,
			archiveUser,
			storageTime,
			storageUser,
		} = pub_dossierDetails
		// 著录人和时间
		pub_dossierDetails.recordUserAndTime = { recordUser, recordTime }
		// 归档人和时间
		pub_dossierDetails.archiveUserAndTime = { archiveUser, archiveTime }
		// 入库人和时间
		pub_dossierDetails.storageUserAndTime = { storageUser, storageTime }
		return pub_dossierDetails
	}

	// 页码改变的回调
	pageChange = (page, pageSize) => {
		pageCurrent = page
	}

	// 附件获取ids的函数
	getAttachmentIds = func => {
		this.getAttachmentIds = func || (() => { })
	}

	// 返回附件
	returnAttachFiles = attachmentList => {
		if (!attachmentList) return []
		return attachmentList.map((item, index) => {
			/**
			 * 将fileToken从
			 * "/archive-data/data2/2023-05-26/xxx.html?token=yyy"
			 * 改成
			 * "?token=yyy"
			 */
			if (item.fileType === 'text/html') {
				item.fileToken = item.fileToken.slice(
					item.fileToken.indexOf('token=') - 1
				)
			}
			return {
				//附件的数据名字转换
				uid: item.fileId,
				name: item.fileName,
				status: 'done',
				url: `/api/file/download/${item.fileToken}`,
				thumbUrl: `/api/file/download/${item.thumbnailFileToken ? item.thumbnailFileToken : item.fileToken}`,
			}
		})
	}

	//拆卷时需要用到这个组卷的一些状态
	separateDossier = () => {
		//修改为编辑状态，且可行选
		this.setState({
			edit: true,
		})
	}

	render() {
		// 编辑状态
		const { edit, modalTitle, visible, content, spinning } = this.state
		const { pubArchive, setStyle } = this.props
		let dossierId = pubArchive?.dossierDetails?.new?.archiveData.id
		const defaultFileList = this.returnAttachFiles(
			pubArchive?.dossierDetails?.new.archiveData.attachmentList
		)
		return (
			<React.Fragment>
				<Spin spinning={spinning}>
					<Modal
						title={modalTitle}
						visible={visible}
						onCancel={this.handleCancel}
						footer={null}>
						<p>{content}</p>
					</Modal>
					<PubArchive
						getInstance={this.getInstance}
						markName={markName}
						appendFiles={edit}
						isArchived={true}
						dossierId={dossierId}
						setStyle={setStyle || {}}
						// 左边文件表格
						renderOperation={this.renderOperation}
						renderLeftButtons={this.renderLeftButtons}
						clickPhoto={this.clickPhoto}
						pageChange={this.pageChange}
						hasDragger={true}
						isrowSelection={edit} //是否可以行选
						// 右边案卷详情表单
						contentTile={'案卷详情'}
						clickHandler={this.clickTextArea}
						otherFormItems={formItems}
						renderBottomButtons={this.renderBottomButtons}
						formDisabled={!edit}
						// 附件的参数
						changeAttachments={(ids, callback) => {
							this.changeAttachments(ids, dossierId, callback)
						}}
						defaultFileList={defaultFileList}
						getAttachmentIds={this.getAttachmentIds}
						//拆卷
						separateDossier={this.separateDossier}
					/>
				</Spin>
			</React.Fragment>
		)
	}
}

export default DossierDetails
