import { combineReducers } from 'redux'
import PubFormReducer from './Public/PubForm'
import publicTabledReducers from './Public/PubTable'
import loginReducer from './Login'
import routeReducer from './Routes'
import fileCreatorReducer from './FileCreator/index'
import fixDetailReducer from './FixDetail'
import borrowApprovalReducer from './BorrowApproval'
import AppraisalDetails from './AppraisalDetails'
import packArchive from './PackArchive'
import pubArchive from './PubArchive'
import headerBarDataReducer from './HeaderBar'
import addFaceDataReducer from './AddFace'
import redDotsReducer from './RedDots'
import slideBarReducer from './SlideBar'
import myFilesReducer from './MyFiles'

const reducer = combineReducers({
	pubForm: PubFormReducer,
	login: loginReducer,
	pubTable: publicTabledReducers,
	route: routeReducer,
	fileCreator: fileCreatorReducer,
	appraisalDetails: AppraisalDetails,
	fixDetails: fixDetailReducer,
	packArchive: packArchive,
	borrowApprovalData: borrowApprovalReducer,
	pubArchive: pubArchive,
	headerBarData: headerBarDataReducer,
	addFaceData: addFaceDataReducer,
	redDots: redDotsReducer,
	slideBar: slideBarReducer,
	myFiles: myFilesReducer,
})

export default reducer
