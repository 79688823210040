import React, { Component } from 'react'
import SearchForm from '@/Public/PubTable/searchForm/index'
import BaseTable from '@/Public/PubTable/baseTable/index'
import WrappedTable from '@/Public/PubTable/WrappedTable'
import { roleManagerColumns } from '@/Public/PubTable/pageColumns'
import { roleManagerUrl } from '@/Utils/Urls'
import { deleteRoleThunk, getAllPermissionThunk } from './ActionCreators'
import { connect } from 'react-redux'
import { Button, Space, Modal } from 'antd'
import AddRoleModal from './AddRoleModal' // 添加角色模态框
import AuthorizeModal from './AuthorizationModal'
import DossierTypeModal from './DossierTypeModal'
const markName = 'RoleManager'
// 添加角色的modal的ref
const AddRoleModalRef = React.createRef()
// 授权角色的ref
const AuthorizeModalRef = React.createRef()
//主管的ref
const DossierTypeModalRef = React.createRef()

const mapDispatchToProps = dispatch => {
	return {
		deleteRole: (...rest) => dispatch(deleteRoleThunk(...rest)), // 删除角色
		getAllPermission: (...rest) => dispatch(getAllPermissionThunk(...rest)),
	}
}
@connect(null, mapDispatchToProps)
@WrappedTable.create(markName) // 用create方法包装的组件可以拿到pub方法和数据，注意：这里的()内可以传参数
class RoleManager extends Component {
	formRef = React.createRef()
	state = {
		bottomCenter: 'bottomCenter',
	}

	// searchForm表单项配置
	searchFormConfig = {
		formItems: [
			{
				type: 'Extra',
				name: '操作按钮',
				span: 4,
				render: formRef => {
					return (
						<div>
							<Space>
								<Button
									className='btn-success iconfont-buttons'
									title='新增'
									onClick={this.showAddRoleModal}>
									&#xe634;
								</Button>
							</Space>
						</div>
					)
				},
			},
			{
				type: 'Input',
				name: 'name',
				label: '角色名: ',
				placeholder: '请输入角色名',
				width: '250px',
			},
		],
		ajaxConfig: {
			ajaxType: 'get',
			url: roleManagerUrl.getRole,
			ContentType: 'application/x-www-form-urlencoded',
		},
	}

	// 修改searchform的请求参数
	changeSearchLimit = data => {
		return data
	}

	// 把拿到的表格数据格式改造成符合公共组件格式的数据
	// 这里的data为从后台接收的数据，必须将data转为以下格式返回（data.files为后台返回的示例），即将对应的表格数据作为data.data返回
	changeTableData = data => {
		data = {
			data: data.records,
			pageSize: data.size,
			current: data.current, // 后台给的数据默认是第一页
			total: data.total,
		}
		return data
	}

	// 显示新增角色的模态框
	showAddRoleModal = () => {
		AddRoleModalRef.current.show()
	}

	// 删除角色
	deleteRole = record => {
		const { deleteRole, pub_getAllData } = this.props
		Modal.confirm({
			title: '提示',
			content: '您确定要删除该角色吗？',
			onOk: close => {
				// 调用删除角色的action
				deleteRole(record.id, pub_getAllData)
				close()
			},
			okText: '确定',
			cancelText: '取消',
			onCancel: close => {
				close()
			},
		})
	}

	// 授权按钮的回调函数
	showAuthorizeModal = record => {
		AuthorizeModalRef.current.show(record)
	}
	//主管按钮的回调函数
	showDossierTypeModal = record => {
		DossierTypeModalRef.current.show(record)
	}

	// 操作角色
	returnButton = (record, index) => {
		return (
			<Space>
				<Button
					size='small'
					onClick={e => {
						e.stopPropagation()
						this.showAuthorizeModal(record)
					}}>
					授权
				</Button>
				<Button
					size='small'
					onClick={e => {
						e.stopPropagation()
						this.showDossierTypeModal(record)
					}}>
					主管
				</Button>
				<Button
					size='small'
					className='table-btn-danger'
					onClick={e => {
						e.stopPropagation()
						this.deleteRole(record, index)
					}}>
					删除
				</Button>
			</Space>
		)
	}

	render() {
		const { pub_getAllData } = this.props
		return (
			<div className='subject-management-subjectsetting'>
				{/*  用WrappedTable包装一下SearchForm和BaseTable */}
				<WrappedTable>
					<SearchForm
						name={'searchFormDemo'}
						formItems={this.searchFormConfig}
						getInstance={formRef => {
							this.searchFormRef = formRef
						}}
						markName={markName}
						// searchLimit // 在WrappedTable可以拿到
						searchButtonText='查询'
						changeSearchLimit={this.changeSearchLimit}
						changeTableData={this.changeTableData}
					/>
					<BaseTable
						markName={markName}
						onRowEvent={true}
						tableConfig={roleManagerColumns} // 表格表头设置
						operationButton={this.returnButton} // 自定义操作按钮
						changeTableData={this.changeTableData} // 把拿到的表格数据改造成符合公共组件所需数据的格式
						changeSearchLimit={this.changeSearchLimit} // 这里也传一下，后续开发公共组件可能会用到
						withPreview={false}
						notRowSelection
					/>
					<AddRoleModal
						reflashTable={pub_getAllData}
						modalRef={AddRoleModalRef}
					/>
					<AuthorizeModal
						reflashTable={pub_getAllData}
						ref={AuthorizeModalRef}
						title={'授权权限'}
					/>
					{/* 管理档案类型模态框 */}
					<DossierTypeModal
						reflashTable={pub_getAllData}
						ref={DossierTypeModalRef}
						title={'主管类型'}
					/>
				</WrappedTable>
			</div>
		)
	}
}

export default RoleManager
