// 引入 wangEditor
import E from 'wangeditor' // npm 安装
const { BtnMenu } = E

// 第一，菜单 class ，Button 菜单继承 BtnMenu class
class Extend135Button extends BtnMenu {
	constructor(editor) {
		// data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
		const $elem = E.$(
			`<div class="w-e-menu" style="width:80px;" data-title="135编辑器">
              <button style="background-color:transparent;border:none">135编辑器</button>
          </div>`
		)
		super($elem, editor)
	}
	// 菜单点击事件
	clickHandler(e) {
		// 做任何你想做的事情
		window.open('https://www.135editor.com/simple_editor.html')
	}

	tryChangeActive() {
		// 激活菜单
		// 1. 菜单 DOM 节点会增加一个 .w-e-active 的 css class
		// 2. this.this.isActive === true
		this.active()
	}
}

export default Extend135Button
