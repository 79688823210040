import React, { Component } from 'react'
import { PubModalForm } from '@/Public/PubForm'
import { message } from 'antd'
import { addNewThemeThunk } from './ActionCreator'
import moment from 'moment'

import Enum from '@/Utils/Enum'

import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

const themeStatusType = new Enum({ 0: '待征集', 1: '征集中', 2: '结束' })
class NewThemeModal extends Component {
	formItems = [
		[
			{
				type: 'Input',
				name: 'topic',
				label: '主题名',
				placeholder: '请输入主题名',
				span: 16,
				layout: {
					labelCol: { span: 4 },
					wrapperCol: { span: 18 },
				},
			},
		],
		[
			{
				type: 'DatePicker',
				name: 'begin',
				label: '开始时间',
				initialValue: moment(new Date().getTime()).format('YYYY-MM-DD'),
				disabledDate: current => {
					return current && current < moment().endOf('day')
				},
				span: 8,
			},
			{
				type: 'DatePicker',
				name: 'end',
				label: '截止时间',
				disabledDate: current => {
					return current && current < moment().endOf('day')
				},
				span: 8,
			},
			{
				type: 'Input',
				name: 'collectedObject',
				label: '征集对象',
				initialValue: '不限',
				rules: [{ required: false }],
				span: 8,
			},
		],
		[
			{
				type: 'Rich',
				name: 'content',
				label: (
					<h4>
						主题详情
						<span style={{ color: '#ccc' }}>
							（支持从其他H5编辑器复制粘贴至主题内容）
						</span>
					</h4>
				),
				getRichValue: RichValue => {
					this.content = RichValue
				},
			},
		],
	]

	componentDidMount() {
		this.props.getNewThemeModal(this)
	}

	componentDidUpdate() {
		this.theme = this.props.theme
	}

	//修改主题状态
	release = nextStatus => {
		const formData = {
			end: this.theme.end,
			begin: this.theme.begin,
			content: this.content,
			collectedObject: this.theme.collectedObject,
			id: this.theme.themeId,
			topic: this.theme.topic,
		}

		if (themeStatusType[this.theme.status] !== nextStatus) {
			formData.status = nextStatus //0未发布 1征集中 2已过期
		} else {
			message.error(
				`当前状态已为${this.theme.status ? '发布状态' : '停止征集状态'}`
			)
			return
		}
		//关闭窗口
		this.props.modalRef.current.hide()
		addNewThemeThunk(formData)
			.then(() => {
				message.destroy()
				message.success('状态修改成功')
				//刷新表格
				this.props.refreshTable()
			})
			.catch()
	}

	//新建或修改主题调用函数
	newTheme = (formData, { hide, finish }) => {
		const id = this.props.modalRef.current.formRef.getFieldValue().themeId
		formData.content = this.content
		//如果是点修改就从state拿到themeId给到接口
		if (id) {
			formData.id = id
		}

		formData.begin.includes('T') || (formData.begin += 'T00:00:00')
		formData.end.includes('T') || (formData.end += 'T23:59:59')

		//如果主题详情为空则不允新建或修改
		if (formData.content) {
			//由于富文本编辑器点击之后内容就不是undefined而是html标签及内容，所以需要进一步转化提取看是否内容为空
			const blocksFromHtml = htmlToDraft(formData.content)
			const { contentBlocks, entityMap } = blocksFromHtml
			const contentState = ContentState.createFromBlockArray(
				contentBlocks,
				entityMap
			)
			const editorState = EditorState.createWithContent(contentState)
			if (
				draftToHtml(convertToRaw(editorState.getCurrentContent()))
					.replace(/<[^>]+>|&[^>]+;/g, '')
					.trim() === ''
			) {
				message.error('请输入主题详情', 1)
				finish() //修改loading状态
				return
			}
		} else {
			message.error('请输入主题详情', 1)
			finish() //修改loading状态
			return
		}

		addNewThemeThunk(formData)
			.then(() => {
				hide()
				message.success(id ? '主题已修改' : '成功新建主题')
				//刷新表格
				this.props.refreshTable()
			})
			.catch(() => finish())
	}

	render() {
		const { modalRef } = this.props
		return (
			<PubModalForm
				title={
					<h2 style={{ textAlign: 'center', fontWeight: 'bolder' }}>
						编 辑 主 题
					</h2>
				}
				ref={modalRef}
				destroyOnClose={true} // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				formProps={{
					//表单配置项
					name: 'addNewThemeModalForm',
					formItems: this.formItems,
				}}
				width={1000}
				onOk={this.newTheme}
				okText='提交'
				getInstance={formRef => {
					this.formRef = formRef
				}}
			/>
		)
	}
}

export default NewThemeModal
