/*
 * searchForm公共组件的ActionType
 */
export const PUB_GET_ROWSELECTION = 'public_row_selection' // 获取选中行的数据
export const PUB_GET_ALLTABLEDATA = 'public_all_tabledata' // 获取表格全部数据
export const PUB_IS_LOADING = 'public_is_loading' // 是否拿到表格数据的标识
export const PUB_CURRENT_PAGE = 'public_current_page' // 当前是哪一页
export const PUB_SEARCH_CONDITION = 'public_search_condition' // 搜索条件
export const PUB_CLEAR_SELECTED = 'public_clear_selectd' // 清除某个页面的选中数据
export const PUB_SET_SELECTED_STATUS = 'public_set_selected_status' // 把选中的数据的状态传到redux
export const IS_MYFILEDETAIL = 'is_my_file_detail' //是不是我的档案的详情
