import React, { Component } from 'react'
import { Space, Button, Badge } from 'antd'
import { isEqual } from 'lodash'
import { PubModalForm } from '@/Public/PubForm'

export default class UpdateRoleTypeModal extends Component {
	state = {
		title: '主管角色',
		formItems: null,
		needWithdraw: false,
	}

	componentDidUpdate(preProps) {
		const preRecord = preProps.record // 上次的 record 值
		const nowRecord = this.props.record // 新传入的 record 值
		/**
		 *  preRecord、nowRecord 不一样时，检测 record 改变
		 *  ┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
		 *  ┃ preRecord | nowRecord  |            ┃
		 *  ┃ null      | notNull    | 刚打开模态框 ┃
		 *  ┃ notNull   | notNull    | 打开后改变时 ┃
		 *  ┃ notNull   | null       | 刚关闭模态框 ┃
		 *  ┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
		 */
		if (nowRecord && !isEqual(preRecord, nowRecord)) {
			this.setContent(nowRecord)
		}
	}

	setContent = record => {
		// 设置内容
		const columns = [{ title: '角色描述', dataIndex: 'description' }]
		// 查看主管角色 表单项配置数组
		const formItems = [
			[
				{
					type: 'Table',
					name: 'allowedRole',
					columns,
					isNeedInternalOperation: false,
					disabled: true,
				},
			],
			[
				{
					type: 'DynamicExtra',
					name: 'buttonList',
					render: (formRef, state, changeDynamicStateFun) => {
						const { modalRef } = this.props
						const {
							handleUpdateRoleType, // 更新主管角色
						} = this.props
						return (
							<Space style={{ marginBottom: '20px', marginLeft: '75%' }}>
								{/* 取消按钮 */}
								<Button onClick={() => modalRef.current.hide()}>
									取消
								</Button>
								{
									//编辑功能
									<Button
										className='btn-info'
										onClick={() => handleUpdateRoleType()}>
										保存
									</Button>
								}
							</Space>
						)
					},
				},
			],
		]
		this.setState({ formItems })
	}

	render() {
		const { formItems } = this.state
		const { modalRef } = this.props
		return (
			<PubModalForm
				title={
					<div>
						<span style={{ paddingRight: '40px' }}>编辑主管角色</span>
						<Badge dot='false' color='#38e09a'></Badge>
						<span style={{ fontSize: '12px' }}>已有角色</span>
					</div>
				}
				destroyOnClose // 是否在关闭 modal 的时候摧毁组件，即不保留上次输入的数据
				buttonList={this.buttonList}
				width={600}
				ref={modalRef} // 若隔层由外层通过props传递
				formProps={{
					// 表单的配置项
					name: 'updateRoleTypeModal',
					formItems,
				}}
			/>
		)
	}
}
