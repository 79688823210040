// 高阶函数，为了增强 SearchForm 组件，统一在这边添加方法，方便管理
import React, { Component } from 'react'
import proFormItem from './Item/index.jsx'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
const mapStateToProps = state => {
	return {}
}
const mapDispatchToProps = dispatch => {
	return {}
}
// 高阶函数，为了增强 SearchForm 组件，统一utils添加方法
// eslint-disable-next-line import/no-anonymous-default-export
export default WrappedComponent => {
	@connect(mapStateToProps, mapDispatchToProps)
	@withRouter
	class Utils extends Component {
		static SearchForm = null // 获取 SearchForm 组件的实例
		constructor(props) {
			super(props)
			const { getInstance } = props
			if (typeof getInstance === 'function') {
				// 在这里把 this 暴露给父组件（调用SearchForm的组件）
				// 外部得以用 this.formRef 获取该组件的公共方法
				getInstance(this)
			}
		}
		componentDidMount() {
			// 将 Form 组件实例的所有方法注入到Utils类中以便外部获取
			const formInstance = Utils.searchFormRef.formRef.current
			for (let formFunc in formInstance) {
				this[formFunc] = formInstance[formFunc]
			}
		}
		/**
		 * 该方法用于根据config中的formItems配置生成对应的表单子组件数组
		 */
		static renderFormItems = (
			formItems,
			formRef,
			searchButtonText,
			handleSumbit,
			handlePrint,
			handleRecognitionSearchData,
			withExport
		) => {
			return proFormItem(
				formItems,
				formRef,
				searchButtonText,
				handleSumbit,
				handlePrint,
				handleRecognitionSearchData,
				withExport
			)
		}
		/**
		 * 该方法用于生成按钮
		 */
		// static renderButton = (buttonText) => {
		//   return proButton(buttonText)
		// }
		render() {
			return (
				<WrappedComponent
					{...this.props}
					renderFormItems={Utils.renderFormItems} // 将renderFormItems传给WrappedComponent（即SearchForm）
					getInstance={searchFormRef => {
						Utils.searchFormRef = searchFormRef
					}} // 将searchFormRef(this)赋值给Utils.searchFormRef
				/>
			)
		}
	}
	return Utils
}
