import React, { Component } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Modal, Button, Image, message, Empty, Slider } from 'antd'
import {
	DeleteOutlined,
	ScissorOutlined,
	UploadOutlined,
	MinusCircleOutlined,
	PlusCircleOutlined,
} from '@ant-design/icons'
import { nanoid } from 'nanoid'
import './index.less'

// 最大和最小放缩 每次放缩大小
const [minScale, maxScale, sliderStep] = [1, 4, 0.5]

class FaceRecognitionCrop extends Component {
	state = {
		// height，width：裁剪框初始宽高 x，y裁剪框的坐标
		crop: {
			unit: '%',
			height: 30,
			width: 30,
			x: 0,
			y: 0,
		},
		// 放缩或移动图片时新的裁剪框坐标
		newCropXy: {
			x: 0,
			y: 0,
		},
		// 图片偏移量
		transformXy: {
			x: 0,
			y: 0,
		},
		imgFileList: [],
		scaleVal: 1,
	}

	// 设置放缩
	setZoomVal = scaleVal => {
		this.setState(
			{
				scaleVal,
				transformXy: {
					x: 0,
					y: 0,
				},
			},
			() => {
				const { transformXy, crop } = this.state
				this.setState({
					newCropXy: {
						x:
							this.getOverflowWH(this.imageRef, 'width') -
							transformXy.x +
							crop.x,
						y:
							this.getOverflowWH(this.imageRef, 'height') -
							transformXy.y +
							crop.y,
					},
				})
			}
		)
	}

	// 关闭裁剪框时的回调 重置各项属性
	onCloseCropper = () => {
		this.setState({
			crop: {
				unit: '%',
				height: 30,
				width: 30,
				x: 0,
				y: 0,
			},
			newCropXy: {
				x: 0,
				y: 0,
			},
			transformXy: {
				x: 0,
				y: 0,
			},
			imgFileList: [],
			scaleVal: 1,
		})
		document.onmousewheel = null
		this.props.closeCropper()
	}

	// 确认上传图片
	uploadImgFile = () => {
		const { imgFileList } = this.state
		const { setFaceMsg } = this.props
		if (imgFileList.length === 0) {
			message.destroy()
			message.warning('至少裁取一张图片')
		} else {
			this.onCloseCropper()
			setFaceMsg(imgFileList)
		}
	}

	// 删除已裁剪完成的图片
	deleteCropperedImg = imgMark => {
		let { imgFileList } = this.state
		imgFileList.splice(
			imgFileList.findIndex(imgFile => imgMark === imgFile.key),
			1
		)
		this.setState({
			imgFileList,
		})
	}

	// 图片加载完成调用
	onImageLoaded = image => {
		this.imageRef = image
		document.onmousewheel = e => {
			this.props.imgUrl &&
				this.setState(
					state => {
						return {
							transformXy: {
								x: 0,
								y: 0,
							},
							scaleVal:
								e.wheelDelta > 0
									? state.scaleVal >= maxScale
										? maxScale
										: state.scaleVal + sliderStep
									: state.scaleVal <= minScale
										? minScale
										: state.scaleVal - sliderStep,
						}
					},
					() => {
						const { crop, transformXy } = this.state
						this.setState({
							newCropXy: {
								x:
									this.getOverflowWH(this.imageRef, 'width') -
									transformXy.x +
									crop.x,
								y:
									this.getOverflowWH(this.imageRef, 'height') -
									transformXy.y +
									crop.y,
							},
						})
					}
				)
		}
		let cropperWrap = document.querySelector('.cropper-wrap .ReactCrop')
		cropperWrap.onmousedown = e => {
			let { transformXy, scaleVal } = this.state
			let [x, y] = [e.clientX, e.clientY]
			cropperWrap.style.cursor = 'grabbing'
			cropperWrap.onmousemove = e => {
				let [dragDistanceX, dragDistanceY] = [
					e.clientX - x + transformXy.x,
					e.clientY - y + transformXy.y,
				]
				if (
					Math.abs(dragDistanceX) <
						this.getOverflowWH(this.imageRef, 'width') &&
					Math.abs(dragDistanceY) < this.getOverflowWH(this.imageRef, 'height')
				) {
					cropperWrap.firstElementChild.style.transform = `matrix( ${scaleVal}, 0, 0, ${scaleVal}, ${dragDistanceX}, ${dragDistanceY})`
				}
			}

			document.onmouseup = e => {
				this.setState(
					{
						transformXy: {
							x: Number(
								this.getTranMatrixs(cropperWrap.firstElementChild)[4]
							),
							y: Number(
								this.getTranMatrixs(cropperWrap.firstElementChild)[5]
							),
						},
					},
					() => {
						const { transformXy, crop } = this.state
						this.setState({
							newCropXy: {
								x:
									this.getOverflowWH(this.imageRef, 'width') -
									transformXy.x +
									crop.x,
								y:
									this.getOverflowWH(this.imageRef, 'height') -
									transformXy.y +
									crop.y,
							},
						})
						document.onmouseup = null
					}
				)
				cropperWrap.style.cursor = 'grab'
				cropperWrap.onmousemove = null
			}

			return false
		}
	}

	//获取图片放缩后的溢出宽高
	getOverflowWH = (target, WH) => {
		return (
			(Number(getComputedStyle(target, null)[WH].replace('px', '')) *
				(this.state.scaleVal - 1)) /
			2
		)
	}

	//获取transform数值
	getTranMatrixs = target => {
		let transformMatrix = getComputedStyle(target, null).transform
		let transforms = transformMatrix.slice(7, transformMatrix.length - 1).split(',')
		return transforms
	}

	// 裁剪框状态改变时调用
	onCropChange = (crop, percentCrop) => {
		const { transformXy } = this.state
		this.setState({
			crop,
			newCropXy: {
				x: this.getOverflowWH(this.imageRef, 'width') - transformXy.x + crop.x,
				y: this.getOverflowWH(this.imageRef, 'height') - transformXy.y + crop.y,
			},
		})
	}

	// 在拖动或调整大小后释放光标或触摸时发生的回调
	onCropDragEnd = () => {
		document.querySelector('.cropper-wrap .ReactCrop__crop-selection') ||
			this.setState({
				crop: {
					unit: '%',
					height: 30,
					width: 30,
					x: 0,
					y: 0,
				},
			})
	}

	// 获取裁剪图片
	getCroppedImg(image, crop) {
		if (this.state.imgFileList.length >= 10) {
			message.destroy()
			message.warning('裁剪图片数量最大为10张')
			return
		}
		const { newCropXy, scaleVal } = this.state
		const canvas = document.createElement('canvas')
		const pixelRatio = window.devicePixelRatio
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		const ctx = canvas.getContext('2d')

		canvas.width = crop.width * pixelRatio * scaleX
		canvas.height = crop.height * pixelRatio * scaleY

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
		ctx.imageSmoothingQuality = 'high'

		ctx.drawImage(
			image,
			scaleVal === 1 ? crop.x * scaleX : (newCropXy.x / scaleVal) * scaleX,
			scaleVal === 1 ? crop.y * scaleX : (newCropXy.y / scaleVal) * scaleY,
			(crop.width / scaleVal) * scaleX,
			(crop.height / scaleVal) * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY
		)
		const { type, name, uid } = this.props.file
		canvas.toBlob(blob => {
			if (!blob) {
				//reject(new Error('Canvas is empty'));
				console.error('Canvas is empty')
				return
			}
			let newFile = new File([blob], name, { type })
			newFile.uid = uid
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				this.setState(state => {
					return {
						imgFileList: [
							...state.imgFileList,
							{ file: newFile, imgUrl: reader.result, key: nanoid() },
						],
					}
				})
			})
			reader.readAsDataURL(newFile)
		}, type)
	}

	render() {
		const { crop, scaleVal, imgFileList } = this.state
		const { imgUrl } = this.props
		return (
			<>
				{imgUrl && (
					<Modal
						width={1000}
						title='裁剪图片'
						visible={true}
						maskClosable={false}
						onCancel={this.onCloseCropper}
						className='piccropper-modal-wrap'
						footer={
							<>
								<Button
									onClick={() => {
										this.setState({ imgFileList: [] })
									}}>
									<DeleteOutlined />
									删除全部
								</Button>
								<Button onClick={this.uploadImgFile}>
									<UploadOutlined />
									确认上传
								</Button>
							</>
						}>
						<div className='cropper-wrap'>
							<ReactCrop
								src={imgUrl}
								crop={crop}
								scale={scaleVal}
								keepSelection={true}
								ruleOfThirds
								onImageLoaded={this.onImageLoaded}
								onChange={this.onCropChange}
								onDragEnd={this.onCropDragEnd}
							/>
						</div>
						<div className='cropper-slider-container'>
							<MinusCircleOutlined
								onClick={() => {
									scaleVal.toFixed(1) * 1 - sliderStep >= minScale &&
										this.setZoomVal(scaleVal - sliderStep)
								}}
							/>
							<Slider
								max={maxScale}
								min={minScale}
								step={sliderStep}
								value={scaleVal}
								onChange={this.setZoomVal}
								className='cropper-slider'
							/>
							<PlusCircleOutlined
								onClick={() => {
									scaleVal.toFixed(1) * 1 + sliderStep <= maxScale &&
										this.setZoomVal(scaleVal + sliderStep)
								}}
							/>
							<Button
								onClick={() => {
									this.getCroppedImg(this.imageRef, this.state.crop)
								}}
								className='cropper-btn'>
								<ScissorOutlined />
								裁剪图片
							</Button>
						</div>
						<div className='cropper-image-container'>
							<Image.PreviewGroup>
								{imgFileList.length === 0 ? (
									<Empty
										className='empty-croppered-img'
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										description='暂无裁剪图片'
									/>
								) : (
									imgFileList.map((imgFile, index) => {
										return (
											<div
												key={imgFile.key}
												className='croppered-img-container'>
												<div className='croppered-img'>
													<Image src={imgFile.imgUrl} />
													{/*传入imgFile.key 辨别要删除哪一张图片*/}
													<DeleteOutlined
														title='删除图片'
														onClick={() => {
															this.deleteCropperedImg(
																imgFile.key
															)
														}}
														className='delete-icon'
													/>
												</div>
											</div>
										)
									})
								)}
							</Image.PreviewGroup>
						</div>
					</Modal>
				)}
			</>
		)
	}
}

export default FaceRecognitionCrop
