import React, { useState, useEffect } from 'react'
import { Switch, message } from 'antd'
import { updateAutoRecognition, getAllSystemConfig } from './ActionCreators'

export default function FaceConfigure() {
	const [checked, setChecked] = useState(false)
	useEffect(() => {
		getAllSystemConfig().then(data => {
			setChecked(Number(data.auto_recognition) === 1)
		})
	}, [])

	const onChange = checked => {
		updateAutoRecognition(checked ? '1' : '0').then(() => {
			setChecked(checked)
			message.success(`自动著录${checked ? '开启' : '关闭'}人脸识别`)
		})
	}

	return (
		<div>
			自动著录人脸识别：
			<Switch
				checkedChildren='开启'
				unCheckedChildren='关闭'
				onChange={onChange}
				checked={checked}
			/>
		</div>
	)
}
