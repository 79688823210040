import { mapUrl } from '@/Utils/Urls'
import _fetch from '@/Utils/Fetch'

// 录入人脸及其对应信息
export const faceInformationEntryThunk = data => () =>
	new Promise((resolve, reject) => {
		data.name = data.name.replace(/\s+/g, '') // 名字去空格
		_fetch({
			url: mapUrl.faceInformationEntry,
			type: 'post',
			data,
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})

// 根据id搜人脸
export const getFaceDetailListThunk = ids => () =>
	new Promise((resolve, reject) => {
		_fetch({
			url: mapUrl.getFaceDetailList,
			type: 'post',
			data: { ids },
			isJson: true,
		}).then(res => {
			if (res && res.data.code === 200) {
				resolve(res.data)
			} else {
				reject()
			}
		})
	})
